import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { CMSService } from '../../../services';
import { EnvService } from '../../../../environments/env.service';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
	@Input() content: any;
	@Input() row: any;
    articles: any = [];
	websiteId: string = '';

    constructor(public cmsService : CMSService, public envService: EnvService, public router: Router, 
		public pageFunctions: PageFunctions, private sanitizer: DomSanitizer) {
		this.websiteId = envService.websiteId;
    }

    ngOnInit() {
		if (this.content.class === undefined){
			this.content['class'] = "col-4";
		}
		if (this.content.category === undefined){
			this.content['category'] = "";
		}
		if(this.content['author_styles']['padding-top'] === undefined){
			this.content['author_styles']['padding-top'] = this.content['author_styles']['margin-top'];
			this.content['author_styles']['padding-bottom'] = this.content['author_styles']['margin-bottom'];
			this.content['author_styles']['padding-left'] = this.content['author_styles']['margin-left'];
			this.content['author_styles']['padding-right'] = this.content['author_styles']['margin-right'];
			this.content['author_styles']['margin-top'] = 0;
			this.content['author_styles']['margin-bottom'] = 0;
			this.content['author_styles']['margin-left'] = 0;
			this.content['author_styles']['margin-right'] = 0;
		}
		if(this.content['image_styles']['padding-top'] === undefined){
			this.content['image_styles']['padding-top'] = this.content['image_styles']['margin-top'];
			this.content['image_styles']['padding-bottom'] = this.content['image_styles']['margin-bottom'];
			this.content['image_styles']['padding-left'] = this.content['image_styles']['margin-left'];
			this.content['image_styles']['padding-right'] = this.content['image_styles']['margin-right'];
			this.content['image_styles']['margin-top'] = 0;
			this.content['image_styles']['margin-bottom'] = 0;
			this.content['image_styles']['margin-left'] = 0;
			this.content['image_styles']['margin-right'] = 0;
		}
    	if(this.content['title_styles']['padding-top'] === undefined){
			this.content['title_styles']['padding-top'] = this.content['title_styles']['margin-top'];
			this.content['title_styles']['padding-bottom'] = this.content['title_styles']['margin-bottom'];
			this.content['title_styles']['padding-left'] = this.content['title_styles']['margin-left'];
			this.content['title_styles']['padding-right'] = this.content['title_styles']['margin-right'];
			this.content['title_styles']['margin-top'] = 0;
			this.content['title_styles']['margin-bottom'] = 0;
			this.content['title_styles']['margin-left'] = 0;
			this.content['title_styles']['margin-right'] = 0;
		}
		if(this.content['desc_styles']['height'] === undefined){
			this.content['desc_styles']['height'] = 50;
		}
		this.getArticles();
	}

    getArticles(){
		var limit = 9999;
		if (this.content.limit !== undefined && this.content.limit !== null &&  this.content.limit !== "") {
			if (parseInt(this.content.limit)>0)
				limit = parseInt(this.content.limit);
		}
        this.cmsService.getArticles(this.websiteId, this.content['category'], limit)
		.subscribe( data => {
			this.articles = data.DATA;
		});
    }

	loadPage(item){
		if (item.SLUG_URL !== null && item.SLUG_URL !== "" && this.cmsService.AppData.BlogContentPage !== ""){
			this.router.navigate([this.cmsService.AppData.BlogContentPage,{ ref: item.SLUG_URL}]);
		}
	}

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

    getStylesMain(obj, objShadow){
		var style = {};
		style = this.getStyles(obj);
		this.pageFunctions.setShadow(style, objShadow);
		return style;
	}

	getBorderRadius(obj){
		var style = {};
		this.pageFunctions.serBorderRadius(style, obj);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}
		return style;
	}

    getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		this.pageFunctions.setShadow(style,obj);
 
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			style["background-color"] = obj['background-color'];
		}

		this.pageFunctions.setBorder(style,obj,true);

		this.pageFunctions.serBorderRadius(style, obj);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		this.pageFunctions.setMargin(style, obj);

		this.pageFunctions.setPadding(style, obj);

		if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "") {
			if (parseInt(obj['height']) >= 50)
				style["height"] = (parseInt(obj['height'].toString().replace('px', ''))) + "px";
			else
				style["height"] = "50px";
		}

		return style;
	}
}
