<ng-container *ngIf="props.template==='1'">
    <div class="row mt-2" [id]="info.ID">
        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12 pl-0">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" *ngIf="props.ITEM.TYPE==='Image'"
                    (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave($event)"
                    style="display: block; width: 100%;object-fit: contain;"/>
                    <video [src]="props.ITEM.IMAGE_URL" width="100%" height="300px"
                    autoplay loop playsinline *ngIf="props.ITEM.TYPE==='Video'"></video>
                    <div class="show-discount-item" *ngIf="selectedVariant.PRICE !== selectedVariant.DISCOUNTED_PRICE">
                        Save {{appData.CurrencySymbol}}{{selectedVariant.PRICE - selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}}
                    </div>
                </div>
                <div class="col-12 pl-0" *ngIf="selectedVariant.IMAGES !== undefined && selectedVariant.IMAGES.length>1">
                    <carousel [noWrap]=false [itemsPerSlide]="props.itemsPerSlide"
                    [singleSlideOffset]=true [interval]=false [showIndicators]=false>
                        <slide *ngFor="let image of selectedVariant.IMAGES; let index=index">
                            <div style="border:1px solid #000;margin-left:1px;margin-right:1px;" 
                            (click)="props.ITEM.IMAGE_URL=image.IMAGE_URL;props.ITEM.TYPE='Image';" 
                            *ngIf="image.TYPE==='Image'">
                                <img [src]="image.IMAGE_URL" alt="image slide" height="120px"
                                    style="display: block; width: 100%;object-fit: contain;">
                            </div>
                            <div style="border:1px solid #000;margin-left:1px;margin-right:1px;" 
                            (click)="props.ITEM.IMAGE_URL=image.VIDEO_URL;props.ITEM.TYPE='Video';" 
                            *ngIf="image.TYPE==='Video'">
                                <video [src]="image.VIDEO_URL" width="100%" height="120px"></video>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" 
        style="position: relative;">
            <div id="zoomViewer" #zoomViewer class="row card" style="display: none;z-index:99;background: transparent;position:absolute;top:0;left:0;">
                <div class="mat-elevation-z18" style="background-size: cover;"
                [ngStyle]="{'background-image': 'url(' + props.ITEM.IMAGE_URL + ')'}"></div> 
            </div>
            <div class="row" style="top:0;left:0;margin-bottom:20px;">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.PRODUCT_NAME}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="selectedVariant.PRICE !== selectedVariant.DISCOUNTED_PRICE">
                    {{appData.CurrencySymbol}} {{selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{selectedVariant.PRICE | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="selectedVariant.PRICE === selectedVariant.DISCOUNTED_PRICE">
                    {{appData.CurrencySymbol}} {{selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                style="white-space:pre-wrap;">
                    {{props.ITEM.PRODUCT_DESCRIPTION}}
                </div>
                <div class="col-12 mb-2" *ngIf="props.ITEM.RATING > 0" [ngStyle]="getStyles(props.styles,'')">
                    <ngb-rating [max]="5" [(rate)]="props.ITEM.RATING" [readonly]="true"></ngb-rating>
                    <span class="ml-3">({{props.ITEM.REVIEWS}} Reviews)</span>
                </div>
                <div class="w-100 table-responsive" 
                    [style.marginLeft.px]="props.additional_styles['margin-left']"
                    [style.marginRight.px]="props.additional_styles['margin-right']"
                    [style.marginTop.px]="props.additional_styles['margin-top']"
                    [style.marginBottom.px]="props.additional_styles['margin-bottom']"
                    *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0">
                    <table class="table" [ngStyle]="getTableStyle(props.additional_styles)">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;" [ngStyle]="getTableColumnStyle(props.additional_styles)"
                                [style.color]="props['additional_styles']['header-color']"
                                [style.backgroundColor]="props['additional_styles']['header-bgcolor']">
                                {{info.NAME}}
                            </th>
                                <td style="width:80%;" [ngStyle]="getTableColumnStyle(props.additional_styles)"
                                [style.color]="props['additional_styles']['color']"
                                [style.backgroundColor]="props['additional_styles']['background-color']">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12 p-0': (isMobile() === true) }" 
                *ngIf="props.ITEM.IS_VARIANT_REQUIRED === '1' && props.ITEM.VARIANTS !== undefined && props.ITEM.VARIANTS.length>0">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            {{props.ITEM.VARIANT_NAME}}
                        </div>
                        <select class="input-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')">
                            <option *ngFor="let variant of props.ITEM.VARIANTS" [value]="variant.ID">{{variant.VARIANT}}</option>
                        </select>
                    </div>
                </div>
                <div [ngClass]="{ 'col-4': (isMobile() === false),'col-6 p-0': (isMobile() === true) }" 
                *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1'">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="input-form-control" type="number" [(ngModel)]="cartCount"
                        [style]="getInputStyle(props['input_styles'],'input')">
                    </div>
                </div> 
                <div [ngClass]="{ 'col-2': (isMobile() === false),'col-4': (isMobile() === true) }">
                    <button class="btn"
                    [style.textDecoration]="props['cart_styles']['text-decoration']"
                    [style.fontStyle]="props['cart_styles']['font-style']"
                    [style.fontWeight]="props['cart_styles']['font-weight']"
                    [style.color]="props['cart_styles']['color']"
                    [style.backgroundColor]="props['cart_styles']['background-color']" 
                    style="cursor:pointer;" *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1'"
                    (click)="addToCart()">Add to Cart</button>
                </div>
                <div class="col-2">
                    <a *ngIf="InWishlist()" style="cursor:pointer;float:left;" (click)="removeFromWishlist()">
                        <span class="fas fa-heart float-left" 
                        [style.color]="props['wishlist_styles']['selected-color']"
                        [style.fontSize]="props['wishlist_styles']['font-size']"></span>
                    </a>
                    <a *ngIf="!InWishlist()" style="cursor:pointer;float:left;" (click)="addToWishlist()">
                        <span class="fas fa-heart float-left" 
                        [style.color]="props['wishlist_styles']['unselected-color']"
                        [style.fontSize]="props['wishlist_styles']['font-size']"></span>
                    </a>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" *ngIf="selectedVariant.IS_STOCK_AVAILABLE !== '1'">
                    <span style="color:red">Stock Unavailable !</span>
                </div>

                <div class="col-12 text-left" [ngStyle]="getStyles(props.description_styles,'')"
                *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1' && info.COUPONS.length > 0" >
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let coupon of info.COUPONS">
                                <td>
                                    <span style="color:red;">{{coupon.COUPON_CODE}} : </span>
                                    {{coupon.COUPON_NAME}}.  EXPIRES ON {{coupon.EXPIRY}}.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-12 p-0" *ngIf="props.ITEM.REVIEWS > 0" 
            [style.backgroundColor]="props['review_styles']['body_bg']"
            [style.marginTop.px]="props['review_styles']['margin-top']"
            [style.marginLeft.px]="props['review_styles']['margin-left']"
            [style.marginRight.px]="props['review_styles']['margin-right']"
            [style.marginBottom.px]="props['review_styles']['margin-bottom']">
            
            <div class="w-100" [ngStyle]="getStyles(props.review_caption_styles,'')"
            [style.backgroundColor]="props['review_styles']['header_bg']">
                Customer Reviews
            </div>
            <ng-container *ngFor="let review of props.ITEM.USER_REVIEWS">
                <div class="w-100">
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_username_styles,'')">
                        {{review.CUSTOMER_NAME}}
                    </div>
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_headline_styles,'')">
                        <ngb-rating [max]="5" [(rate)]="review.RATING" [readonly]="true"></ngb-rating>
                        <b class="ml-3">{{review.HEADLINE}}</b><br/>
                    </div>
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_date_styles,'')">
                        <small>Reviewed on {{review.REVIEW_DATE}}</small>
                    </div>
                    <div class="w-100" [ngStyle]="getStyles(props.review_comment_styles,'')">
                        {{review.REVIEW}}
                    </div>
                </div>
                <hr>
            </ng-container>
            <div class="w-100 mt-1 mb-2" style="display:flex;" *ngIf="props.ITEM.REVIEWS > 10" 
                [ngStyle]="getStyles(props.review_paging_styles,'')"
                [style.backgroundColor]="props['review_styles']['footer_bg']">
                <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="props.ITEM.REVIEWS"
                    [itemsPerPage]="10" (pageChanged)="pageChanged($event,content)">
                </pagination>
            </div>
        </div>

        <div class="col-12 p-0 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="text-left text-bold mt-3 mb-3" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div style="position:relative;" [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                            style="display: block; width: 100%;object-fit: fill;">
                            <div class="show-discount-item" *ngIf="item.IS_VARIANT_REQUIRED==='0' && item.PRICE !== item.DISCOUNTED_PRICE">
                                Save {{appData.CurrencySymbol}}{{item.PRICE - item.DISCOUNTED_PRICE | number:'1.2-2'}}
                            </div>
                        </div>
                        
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.PRODUCT_NAME}}
                        </div>
                        <ng-container *ngIf="item.IS_VARIANT_REQUIRED==='1'">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.MIN_PRICE !== item.MAX_PRICE">
                                {{appData.CurrencySymbol}} {{item.MIN_PRICE | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.MAX_PRICE | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.MIN_PRICE === item.MAX_PRICE">
                                {{appData.CurrencySymbol}} {{item.MIN_PRICE | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.IS_VARIANT_REQUIRED==='0'">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.PRICE !== item.DISCOUNTED_PRICE">
                                {{appData.CurrencySymbol}} {{item.DISCOUNTED_PRICE | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.PRICE | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.PRICE === item.DISCOUNTED_PRICE">
                                {{appData.CurrencySymbol}} {{item.PRICE | number:'1.2-2'}}
                            </div>
                        </ng-container>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="props.template==='2'">
    <div class="row mt-2" [id]="info.ID">
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" style="position: relative;">
            <div id="zoomViewer" #zoomViewer class="row card" style="display: none;z-index:99;background: transparent;position:absolute;top:0;left:0;">
                <div class="mat-elevation-z18" style="background-size: cover;"
                [ngStyle]="{'background-image': 'url(' + props.ITEM.IMAGE_URL + ')'}"></div> 
            </div>
            <div class="row" style="top:0;left:0;margin-bottom:20px;">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.PRODUCT_NAME}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="selectedVariant.PRICE !== selectedVariant.DISCOUNTED_PRICE">
                    {{appData.CurrencySymbol}} {{selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{selectedVariant.PRICE | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="selectedVariant.PRICE === selectedVariant.DISCOUNTED_PRICE">
                    {{appData.CurrencySymbol}} {{selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                style="white-space:pre-wrap;">
                    {{props.ITEM.PRODUCT_DESCRIPTION}}
                </div>
                <div class="col-12 mb-2" *ngIf="props.ITEM.RATING > 0" [ngStyle]="getStyles(props.styles,'')">
                    <ngb-rating [max]="5" [(rate)]="props.ITEM.RATING" [readonly]="true"></ngb-rating>
                    <span class="ml-3">({{props.ITEM.REVIEWS}} Reviews)</span>
                </div>
                <div class="w-100 table-responsive" 
                    [style.marginLeft.px]="props.additional_styles['margin-left']"
                    [style.marginRight.px]="props.additional_styles['margin-right']"
                    [style.marginTop.px]="props.additional_styles['margin-top']"
                    [style.marginBottom.px]="props.additional_styles['margin-bottom']"
                    *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0">
                    <table class="table" [ngStyle]="getTableStyle(props.additional_styles)">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;" [ngStyle]="getTableColumnStyle(props.additional_styles)"
                                [style.color]="props['additional_styles']['header-color']"
                                [style.backgroundColor]="props['additional_styles']['header-bgcolor']">
                                {{info.NAME}}
                            </th>
                                <td style="width:80%;" [ngStyle]="getTableColumnStyle(props.additional_styles)"
                                [style.color]="props['additional_styles']['color']"
                                [style.backgroundColor]="props['additional_styles']['background-color']">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12 p-0': (isMobile() === true) }"
                *ngIf="props.ITEM.IS_VARIANT_REQUIRED === '1' && props.ITEM.VARIANTS !== undefined && props.ITEM.VARIANTS.length>1">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            {{props.ITEM.VARIANT_NAME}}
                        </div>
                        <select class="input-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')">
                            <option *ngFor="let variant of props.ITEM.VARIANTS" [value]="variant.ID">{{variant.VARIANT}}</option>
                        </select>
                    </div>
                </div>
                <div [ngClass]="{ 'col-4': (isMobile() === false),'col-6 p-0': (isMobile() === true) }"
                *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1'">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="input-form-control" type="number" [(ngModel)]="cartCount"
                        [style]="getInputStyle(props['input_styles'],'input')">
                    </div>
                </div> 
                <div [ngClass]="{ 'col-2': (isMobile() === false),'col-4': (isMobile() === true) }">
                    <button class="btn"
                    [style.textDecoration]="props['cart_styles']['text-decoration']"
                    [style.fontStyle]="props['cart_styles']['font-style']"
                    [style.fontWeight]="props['cart_styles']['font-weight']"
                    [style.color]="props['cart_styles']['color']"
                    [style.backgroundColor]="props['cart_styles']['background-color']" 
                    style="cursor:pointer;" *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1'"
                    (click)="addToCart()">Add to Cart</button>
                </div>
                <div class="col-2">
                    <a *ngIf="InWishlist()" style="cursor:pointer; float:left;" (click)="removeFromWishlist()">
                        <span class="fas fa-heart float-left" 
                        [style.color]="props['wishlist_styles']['selected-color']"
                        [style.fontSize]="props['wishlist_styles']['font-size']"></span>
                    </a>
                    <a *ngIf="!InWishlist()" style="cursor:pointer; float:left;" (click)="addToWishlist()">
                        <span class="fas fa-heart float-left" 
                        [style.color]="props['wishlist_styles']['unselected-color']"
                        [style.fontSize]="props['wishlist_styles']['font-size']"></span>
                    </a>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" *ngIf="selectedVariant.IS_STOCK_AVAILABLE !== '1'">
                    <span style="color:red">Stock Unavailable !</span>
                </div>
            </div>
        </div>

        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12 pr-0">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" *ngIf="props.ITEM.TYPE==='Image'"
                    (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave($event)"
                    style="display: block; width: 100%;object-fit: contain;"/>
                    <video [src]="props.ITEM.IMAGE_URL" width="100%" height="300px" 
                    autoplay loop playsinline *ngIf="props.ITEM.TYPE==='Video'"></video>
                    <div class="show-discount-item" *ngIf="selectedVariant.PRICE !== selectedVariant.DISCOUNTED_PRICE">
                        Save {{appData.CurrencySymbol}}{{selectedVariant.PRICE - selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}}
                    </div>
                </div>
                <div class="col-12 pr-0" *ngIf="selectedVariant.IMAGES !== undefined && selectedVariant.IMAGES.length>1">
                    <carousel [noWrap]=false [itemsPerSlide]="props.itemsPerSlide"
                    [singleSlideOffset]=true [interval]=false [showIndicators]=false>
                        <slide *ngFor="let image of selectedVariant.IMAGES; let index=index">
                            <div style="border:1px solid #000;margin-left:1px;margin-right:1px;" 
                            (click)="props.ITEM.IMAGE_URL=image.IMAGE_URL;props.ITEM.TYPE='Image';" 
                            *ngIf="image.TYPE==='Image'">
                                <img [src]="image.IMAGE_URL" alt="image slide" height="120px"
                                    style="display: block; width: 100%;object-fit: contain;">
                            </div>
                            <div style="border:1px solid #000;margin-left:1px;margin-right:1px;" 
                            (click)="props.ITEM.IMAGE_URL=image.VIDEO_URL;props.ITEM.TYPE='Video';" 
                            *ngIf="image.TYPE==='Video'">
                                <video [src]="image.VIDEO_URL" width="100%" height="120px"></video>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>

        <div class="col-12 p-0" *ngIf="props.ITEM.REVIEWS > 0" 
            [style.backgroundColor]="props['review_styles']['body_bg']"
            [style.marginTop.px]="props['review_styles']['margin-top']"
            [style.marginLeft.px]="props['review_styles']['margin-left']"
            [style.marginRight.px]="props['review_styles']['margin-right']"
            [style.marginBottom.px]="props['review_styles']['margin-bottom']">
            
            <div class="w-100" [ngStyle]="getStyles(props.review_caption_styles,'')"
            [style.backgroundColor]="props['review_styles']['header_bg']">
                Customer Reviews
            </div>
            <ng-container *ngFor="let review of props.ITEM.USER_REVIEWS">
                <div class="w-100">
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_username_styles,'')">
                        {{review.CUSTOMER_NAME}}
                    </div>
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_headline_styles,'')">
                        <ngb-rating [max]="5" [(rate)]="review.RATING" [readonly]="true"></ngb-rating>
                        <b class="ml-3">{{review.HEADLINE}}</b><br/>
                    </div>
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_date_styles,'')">
                        <small>Reviewed on {{review.REVIEW_DATE}}</small>
                    </div>
                    <div class="w-100" [ngStyle]="getStyles(props.review_comment_styles,'')">
                        {{review.REVIEW}}
                    </div>
                </div>
                <hr>
            </ng-container>
            <div class="w-100 mt-1 mb-2" style="display:flex;" *ngIf="props.ITEM.REVIEWS > 10" 
                [ngStyle]="getStyles(props.review_paging_styles,'')"
                [style.backgroundColor]="props['review_styles']['footer_bg']">
                <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="props.ITEM.REVIEWS"
                    [itemsPerPage]="10" (pageChanged)="pageChanged($event,content)">
                </pagination>
            </div>
        </div>
        
        <div class="col-12 p-0 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="text-left text-bold mt-3 mb-3" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div style="position:relative;" [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                            style="display: block; width: 100%;object-fit: fill;">
                            <div class="show-discount-item" *ngIf="item.IS_VARIANT_REQUIRED==='0' && item.PRICE !== item.DISCOUNTED_PRICE">
                                Save {{appData.CurrencySymbol}}{{item.PRICE - item.DISCOUNTED_PRICE | number:'1.2-2'}}
                            </div>
                        </div>
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.PRODUCT_NAME}}
                        </div>
                        <ng-container *ngIf="item.IS_VARIANT_REQUIRED==='1'">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.MIN_PRICE !== item.MAX_PRICE">
                                {{appData.CurrencySymbol}} {{item.MIN_PRICE | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.MAX_PRICE | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.MIN_PRICE === item.MAX_PRICE">
                                {{appData.CurrencySymbol}} {{item.MIN_PRICE | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.IS_VARIANT_REQUIRED==='0'">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.PRICE !== item.DISCOUNTED_PRICE">
                                {{appData.CurrencySymbol}} {{item.DISCOUNTED_PRICE | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.PRICE | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.PRICE === item.DISCOUNTED_PRICE">
                                {{appData.CurrencySymbol}} {{item.PRICE | number:'1.2-2'}}
                            </div>
                        </ng-container>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="props.template==='3'">
    <div class="row mt-2" [id]="info.ID">
        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12 pl-0">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" *ngIf="props.ITEM.TYPE==='Image'"
                    (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave($event)"
                    style="display: block; width: 100%;object-fit: contain;"/>
                    <video [src]="props.ITEM.IMAGE_URL" width="100%" height="300px" 
                    autoplay loop playsinline *ngIf="props.ITEM.TYPE==='Video'"></video>
                    <div class="show-discount-item" *ngIf="selectedVariant.PRICE !== selectedVariant.DISCOUNTED_PRICE">
                        Save {{appData.CurrencySymbol}}{{selectedVariant.PRICE - selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}}
                    </div>
                </div>
                <div class="col-12 pl-0" *ngIf="selectedVariant.IMAGES !== undefined && selectedVariant.IMAGES.length>1">
                    <carousel [noWrap]=false [itemsPerSlide]="props.itemsPerSlide"
                    [singleSlideOffset]=true [interval]=false [showIndicators]=false>
                        <slide *ngFor="let image of selectedVariant.IMAGES; let index=index">
                            <div style="border:1px solid #000;margin-left:1px;margin-right:1px;" 
                            (click)="props.ITEM.IMAGE_URL=image.IMAGE_URL;props.ITEM.TYPE='Image';" 
                            *ngIf="image.TYPE==='Image'">
                                <img [src]="image.IMAGE_URL" alt="image slide" height="120px"
                                    style="display: block; width: 100%;object-fit: contain;">
                            </div>
                            <div style="border:1px solid #000;margin-left:1px;margin-right:1px;" 
                            (click)="props.ITEM.IMAGE_URL=image.VIDEO_URL;props.ITEM.TYPE='Video';" 
                            *ngIf="image.TYPE==='Video'">
                                <video [src]="image.VIDEO_URL" width="100%" height="120px"></video>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" style="position: relative;">
            <div id="zoomViewer" #zoomViewer class="row card" style="display: none;z-index:99;background: transparent;position:absolute;top:0;left:0;">
                <div class="mat-elevation-z18" style="background-size: cover;"
                [ngStyle]="{'background-image': 'url(' + props.ITEM.IMAGE_URL + ')'}"></div> 
            </div>
            <div class="row" style="top:0;left:0;margin-bottom:20px;">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.PRODUCT_NAME}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="selectedVariant.PRICE !== selectedVariant.DISCOUNTED_PRICE">
                    {{appData.CurrencySymbol}} {{selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{selectedVariant.PRICE | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="selectedVariant.PRICE === selectedVariant.DISCOUNTED_PRICE">
                    {{appData.CurrencySymbol}} {{selectedVariant.DISCOUNTED_PRICE | number:'1.2-2'}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                style="white-space:pre-wrap;">
                    {{props.ITEM.PRODUCT_DESCRIPTION}}
                </div>
                <div class="col-12 mb-2" *ngIf="props.ITEM.RATING > 0" [ngStyle]="getStyles(props.styles,'')">
                    <ngb-rating [max]="5" [(rate)]="props.ITEM.RATING" [readonly]="true"></ngb-rating>
                    <span class="ml-3">({{props.ITEM.REVIEWS}} Reviews)</span>
                </div>
                <div class="w-100 table-responsive" 
                    [style.marginLeft.px]="props.additional_styles['margin-left']"
                    [style.marginRight.px]="props.additional_styles['margin-right']"
                    [style.marginTop.px]="props.additional_styles['margin-top']"
                    [style.marginBottom.px]="props.additional_styles['margin-bottom']"
                    *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0">
                    <table class="table" [ngStyle]="getTableStyle(props.additional_styles)">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;" [ngStyle]="getTableColumnStyle(props.additional_styles)"
                                [style.color]="props['additional_styles']['header-color']"
                                [style.backgroundColor]="props['additional_styles']['header-bgcolor']">
                                {{info.NAME}}
                            </th>
                                <td style="width:80%;" [ngStyle]="getTableColumnStyle(props.additional_styles)"
                                [style.color]="props['additional_styles']['color']"
                                [style.backgroundColor]="props['additional_styles']['background-color']">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12 p-0': (isMobile() === true) }"
                *ngIf="props.ITEM.IS_VARIANT_REQUIRED === '1' && props.ITEM.VARIANTS !== undefined && props.ITEM.VARIANTS.length>1">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            {{props.ITEM.VARIANT_NAME}}
                        </div>
                        <select class="input-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')">
                            <option *ngFor="let variant of props.ITEM.VARIANTS" [value]="variant.ID">{{variant.VARIANT}}</option>
                        </select>
                    </div>
                </div>
                <div [ngClass]="{ 'col-4': (isMobile() === false),'col-6 p-0': (isMobile() === true) }"
                *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1'">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="input-form-control" type="number" [(ngModel)]="cartCount"
                        [style]="getInputStyle(props['input_styles'],'input')">
                    </div>
                </div> 
                <div [ngClass]="{ 'col-2': (isMobile() === false),'col-4': (isMobile() === true) }">
                    <button class="btn"
                    [style.textDecoration]="props['cart_styles']['text-decoration']"
                    [style.fontStyle]="props['cart_styles']['font-style']"
                    [style.fontWeight]="props['cart_styles']['font-weight']"
                    [style.color]="props['cart_styles']['color']"
                    [style.backgroundColor]="props['cart_styles']['background-color']" 
                    style="cursor:pointer;" *ngIf="selectedVariant.IS_STOCK_AVAILABLE === '1'" 
                    (click)="addToCart()">Add to Cart</button>
                    
                </div>
                <div class="col-2">
                    <a *ngIf="InWishlist()" style="cursor:pointer; float:left;" (click)="removeFromWishlist()">
                        <span class="fas fa-heart float-left" 
                        [style.color]="props['wishlist_styles']['selected-color']"
                        [style.fontSize]="props['wishlist_styles']['font-size']"></span>
                    </a>
                    <a *ngIf="!InWishlist()" style="cursor:pointer; float:left;" (click)="addToWishlist()">
                        <span class="fas fa-heart float-left" 
                        [style.color]="props['wishlist_styles']['unselected-color']"
                        [style.fontSize]="props['wishlist_styles']['font-size']"></span>
                    </a>
                </div>
                <div class="col-12 mb-2" style="text-align: left;" *ngIf="selectedVariant.IS_STOCK_AVAILABLE !== '1'">
                    <span style="color:red">Stock Unavailable !</span>
                </div>
            </div>
        </div>

        <div class="col-12 p-0" *ngIf="props.ITEM.REVIEWS > 0" 
            [style.backgroundColor]="props['review_styles']['body_bg']"
            [style.marginTop.px]="props['review_styles']['margin-top']"
            [style.marginLeft.px]="props['review_styles']['margin-left']"
            [style.marginRight.px]="props['review_styles']['margin-right']"
            [style.marginBottom.px]="props['review_styles']['margin-bottom']">
            
            <div class="w-100" [ngStyle]="getStyles(props.review_caption_styles,'')"
            [style.backgroundColor]="props['review_styles']['header_bg']">
                Customer Reviews
            </div>
            <ng-container *ngFor="let review of props.ITEM.USER_REVIEWS">
                <div class="w-100">
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_username_styles,'')">
                        {{review.CUSTOMER_NAME}}
                    </div>
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_headline_styles,'')">
                        <ngb-rating [max]="5" [(rate)]="review.RATING" [readonly]="true"></ngb-rating>
                        <b class="ml-3">{{review.HEADLINE}}</b><br/>
                    </div>
                    <div class="w-100 mb-3" [ngStyle]="getStyles(props.review_date_styles,'')">
                        <small>Reviewed on {{review.REVIEW_DATE}}</small>
                    </div>
                    <div class="w-100" [ngStyle]="getStyles(props.review_comment_styles,'')">
                        {{review.REVIEW}}
                    </div>
                </div>
                <hr>
            </ng-container>
            <div class="w-100 mt-1 mb-2" style="display:flex;" *ngIf="props.ITEM.REVIEWS > 10" 
                [ngStyle]="getStyles(props.review_paging_styles,'')"
                [style.backgroundColor]="props['review_styles']['footer_bg']">
                <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="props.ITEM.REVIEWS"
                    [itemsPerPage]="10" (pageChanged)="pageChanged($event,content)">
                </pagination>
            </div>
        </div>

        <div class="col-12 p-0 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="text-left text-bold mt-3 mb-3" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div style="position:relative;" [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                            style="display: block; width: 100%;object-fit: fill;">
                            <div class="show-discount-item" *ngIf="item.IS_VARIANT_REQUIRED==='0' && item.PRICE !== item.DISCOUNTED_PRICE">
                                Save {{appData.CurrencySymbol}}{{item.PRICE - item.DISCOUNTED_PRICE | number:'1.2-2'}}
                            </div>
                        </div>
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.PRODUCT_NAME}}
                        </div>
                        <ng-container *ngIf="item.IS_VARIANT_REQUIRED==='1'">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.MIN_PRICE !== item.MAX_PRICE">
                                {{appData.CurrencySymbol}} {{item.MIN_PRICE | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.MAX_PRICE | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.MIN_PRICE === item.MAX_PRICE">
                                {{appData.CurrencySymbol}} {{item.MIN_PRICE | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.IS_VARIANT_REQUIRED==='0'">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.PRICE !== item.DISCOUNTED_PRICE">
                                {{appData.CurrencySymbol}} {{item.DISCOUNTED_PRICE | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.PRICE | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')" *ngIf="item.PRICE === item.DISCOUNTED_PRICE">
                                {{appData.CurrencySymbol}} {{item.PRICE | number:'1.2-2'}}
                            </div>
                        </ng-container>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>