import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../../src/app/services';
import { EnvService } from '../../../environments/env.service';
import { ToastrService } from 'ngx-toastr';
import * as markerjs2 from 'markerjs2';
import * as htmlToImage from 'html-to-image';

declare var require: any;
const axios = require('axios');
const Swal = require('sweetalert2');

@Component({
	selector: 'app-attachment',
	templateUrl: './attachment.component.html',
	styleUrls: ['./attachment.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AttachmentComponent implements OnInit, AfterViewInit {
	@ViewChild('attachmentGrid', { static: false }) attachmentGrid: jqxGridComponent;
	@ViewChild('markerimage') markerImage: ElementRef;
	@ViewChild('markedimage') markedImage: ElementRef;
	@ViewChild('file') file: ElementRef;
	@Input() AttachmentInfo: any;
	@Output() Close: EventEmitter<any> = new EventEmitter<any>();

	AttachedCount: number = 0;
	Attachment_Name: string = '';
	gridAdapter: any = [];
	Columns: any = [];
	datasource: any = {};
	loading: boolean = false;
	markingUpload: boolean = false;
	uploadReady: boolean = true;
	apiUrl: string = '';
	clientKey: string = '';
	markerArea;
	imageurl: string = '';
	selectedFileName: string = '';
	selectedFileWidth: any = 0;
	selectedFileHeight: any = 0;
	selectedFileSize: any = 0;
	allowUpload: boolean = false;

	constructor(private environment: EnvService, private dataService: DataService, public toastr: ToastrService, 
		public dialogRef: MatDialogRef<AttachmentComponent>) {
		this.apiUrl = environment.apiUrl;
		this.clientKey = environment.clientKey;
	}

	ngOnInit(): void {
		if (this.AttachmentInfo.DataType !== 'UPLOAD') this.setGridConfig();
	}

	ngAfterViewInit(): void {
		if (this.AttachmentInfo.DataType !== 'UPLOAD') {
			this.attachmentGrid.hideloadelement();
			this.attachmentGrid.beginupdate();
			this.attachmentGrid.setOptions({
				source: this.gridAdapter,
				columns: this.Columns,
			});
			this.attachmentGrid.endupdate();
			this.setGridData();
		}
	}

	setGridConfig() {
		this.Attachment_Name = '';
		this.AttachedCount = 0;
		//this.loading = true;
		this.Columns = [
			{ text: 'Attachment Url', dataField: 'ATTACHMENT_URL', hidden: true },
			{ text: 'Attachment Name', width: '94%', dataField: 'Attachment_Name' },
			{
				text: '',
				dataField: 'view',
				width: '3%',
				filtertype: 'none',
				filterable: false,
				sortable: false,
				menu: false,
				columntype: 'button',
				cellsrenderer: function () {
					return '<span class="fa fa-eye"></span>';
				},
			},
			{
				text: '',
				dataField: 'delete',
				width: '3%',
				filtertype: 'none',
				filterable: false,
				sortable: false,
				menu: false,
				columntype: 'button',
				cellsrenderer: function () {
					return '<span class="fa fa-trash"></span>';
				},
			},
		];

		let Datafields = [
			{ name: 'ID', type: 'string' },
			{ name: 'Attachment_Name', type: 'string' },
			{ name: 'ATTACHMENT_URL', type: 'string' },
		];

		this.datasource = {
			localdata: null,
			datatype: 'json',
			datafields: Datafields,
			totalrecords: 0,
		};

		this.gridAdapter = new jqx.dataAdapter(this.datasource);
	}

	close() {
		if (this.AttachmentInfo.DataType === 'UPLOAD') this.Close.emit('');
		else this.Close.emit(this.AttachedCount);
		this.dialogRef.close();
	}

	handleFileUpload() {
		this.allowUpload = false;
		if (this.markerArea && this.markerArea.isOpen){
			this.markerArea.close(true);
		}
		
		this.selectedFileName = "";
		var file = this.file.nativeElement.files[0];
		this.selectedFileSize = Math.round((file.size / 1024));

		if (this.AttachmentInfo.MaxSize === 0 || this.AttachmentInfo.MaxSize > this.selectedFileSize){
			this.selectedFileName = file.name;
			if (this.AttachmentInfo.DataType === 'MARKING'){
				setTimeout(()=>{
					const img = document.getElementById('markerimage') as HTMLImageElement;
					img.src = URL.createObjectURL(file);
					this.showMarkerArea();
				},300);
			}
			else if (this.AttachmentInfo.DataType === 'UPLOAD'){
				this.readImageFile(file);
			}
			else{
				this.allowUpload = true;
			}
		}
		else{
			this.toastr.info("Max Allowed Size : " + this.AttachmentInfo.MaxSize.toString() + "kb", "Invalid File");
		}
	}

	readImageFile(file: any) {
		if (file) {
			this.selectedFileWidth = 0;
			this.selectedFileHeight = 0;
			var reader = new FileReader();    // Create a new instance.
			var self = this;
			reader.onload = function (e:any) {
				var img = new Image();
				img.src = e.target.result;

				img.onload = (rs:any) => {
					const img_height = rs.currentTarget['height'];
					const img_width = rs.currentTarget['width'];
					self.selectedFileWidth = img_height;
					self.selectedFileHeight = img_width;
					if (self.AttachmentInfo.MaxWidth > 0 && self.AttachmentInfo.MaxHeight > 0
						&& (img_width > self.AttachmentInfo.MaxWidth || img_height > self.AttachmentInfo.MaxHeight)){
						self.allowUpload = false;
						self.toastr.info("Max Allowed Width : " + self.AttachmentInfo.MaxWidth.toString() + "px , Max Allowed Height : "+ self.AttachmentInfo.MaxHeight.toString() + "px", "Invalid Image");
					}
					else {
						self.allowUpload = true;
					}
				}
			};
			reader.readAsDataURL(file);
		}
	}

	uploadFile(event) {
		event.preventDefault();
		var formData: any = new FormData();
		formData.append('OPER', 'ADD');
		formData.append('CK', this.clientKey);
		formData.append('PAGE_ID', this.AttachmentInfo.PageID);
		formData.append('TABLE_ID', this.AttachmentInfo.TableID);
		formData.append('FIELD_ID', this.AttachmentInfo.FieldID);
		formData.append('PRIMARY_ID', this.AttachmentInfo.TransID);
		formData.append('ATTACHMENT_NAME', this.file.nativeElement.files[0].name);
		formData.append('ATTACHMENT', this.file.nativeElement.files[0]);
		
		axios
			.post(this.apiUrl + 'manageattachment', formData, { headers: { 'content-type': 'multipart/form-data' }, withCredentials: true })
			.then((response) => {
				if (response.data['RESULT_CODE'] === 1) {
					this.uploadReady = true;
					if (this.AttachmentInfo.DataType === 'UPLOAD') {
						this.Close.emit(response.data['LOCATION']);
						this.dialogRef.close();
					} else this.setGridData();
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	uploadMarkedFile(blobFile) {
		var formData: any = new FormData();
		formData.append('OPER', 'ADD');
		formData.append('CK', this.clientKey);
		formData.append('PAGE_ID', this.AttachmentInfo.PageID);
		formData.append('TABLE_ID', this.AttachmentInfo.TableID);
		formData.append('FIELD_ID', this.AttachmentInfo.FieldID);
		formData.append('PRIMARY_ID', this.AttachmentInfo.TransID);
		formData.append('ATTACHMENT_NAME', this.file.nativeElement.files[0].name);
		formData.append('ATTACHMENT', blobFile);
		
		axios
		.post(this.apiUrl + 'manageattachment', formData, { headers: { 'content-type': 'multipart/form-data' }, withCredentials: true })
		.then((response) => {
			if (response.data['RESULT_CODE'] === 1) {
				this.uploadReady = true;
				this.markingUpload = false;
				this.selectedFileName = "";
				this.setGridData();
			}
		})
		.catch(function (error) {
			console.log(error);
			this.markingUpload = false;
			this.selectedFileName = "";
		});
	}

	showMarkerArea() {
		this.markerArea = new markerjs2.MarkerArea(
		  document.getElementById('markerimage') as HTMLImageElement
		);
		this.markerArea.targetRoot = document.getElementById('markerdiv');
		this.markerArea.renderAtNaturalSize = true;
		this.markerArea.renderImageQuality = 1;
		this.markerArea.availableMarkerTypes = [
		  markerjs2.ArrowMarker,
		  markerjs2.EllipseFrameMarker,
		  markerjs2.FrameMarker,
		  markerjs2.TextMarker,
		  markerjs2.FreehandMarker
		];
	
		this.markerArea.addCloseEventListener(() => {
			this.selectedFileName = "";
		});
	
		this.markerArea.show();
		this.markerArea.addRenderEventListener(dataUrl => {
			this.imageurl = dataUrl;
			var self = this;
			const img = document.getElementById('markedimage') as HTMLImageElement;
			setTimeout(()=>{
				this.markingUpload = true;
				htmlToImage
				.toBlob(this.markedImage.nativeElement, { cacheBust: true })
				.then(function (blob) {
					self.uploadMarkedFile(blob);
					self.imageurl = "";
				})
				.catch(function (error) {});
			},300);
		});

		document.querySelector('[title="Powered by marker.js"]')?.remove();
	}

	onCellclick(event) {
		const args = event.args;
		const dataField = args.datafield;

		if (dataField === 'delete') {
			var attachmentID = args.row.bounddata['ID'];
			this.openDeleteDialog(attachmentID);
		} else if (dataField === 'view') {
			window.open(args.row.bounddata['ATTACHMENT_URL'], '_blank');
		}
	}

	openDeleteDialog(ID) {
		this.deleteAttachment(ID);
	}

	deleteAttachment(attachmentID) {
		this.loading = true;
		var formData = { OPER: 'DEL', CK: this.clientKey, ATTACHMENT_ID: attachmentID };
		this.dataService.deleteAttachment(formData).subscribe(
			(result) => {
				this.loading = false;
				if (result['RESULT_CODE'] === 1) {
					this.setGridData();
				}
			},
			(error) => {
				this.loading = false;
				console.error(error);
			}
		);
	}

	setGridData() {
		this.loading = true;
		this.markingUpload = false;
		this.attachmentGrid.showloadelement();
		this.dataService.getAttachmentList(this.AttachmentInfo.TransID + this.AttachmentInfo.FieldID).subscribe(
			(result) => {
				this.datasource.localdata = [];
				this.datasource.totalrecords = 0;
				if (result['RESULT_CODE'] === 1) {
					this.datasource.localdata = result['DATA'];
					this.datasource.totalrecords = result['RECORD_COUNT'];
					this.AttachedCount = result['RECORD_COUNT'];
					this.attachmentGrid.updatebounddata();
				}
				this.attachmentGrid.hideloadelement();
				this.loading = false;
			},
			(error) => {
				console.error(error);
				this.attachmentGrid.hideloadelement();
				this.loading = false;
			}
		);
	}
}
