import { Component, OnInit, Input, Injector, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EcommerceService, AuthService, BookingService } from '../../../services';
import { EnvService } from '../../../../environments/env.service';
import { PageFunctions } from '../../directives/PageFunctions';

declare var require: any;
const swal = require('sweetalert2');

@Component({
  selector: 'app-weblogin',
  templateUrl: './weblogin.component.html',
  styleUrls: ['./weblogin.component.scss']
})
export class WebloginComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  @Input() content: any;
  @Input() websiteName: string;

  clientkey: string = '';
  appUrl: string = '';
  loginForm: FormGroup;
  siteName: string = '';

  constructor(private environment:EnvService, private authService:AuthService, 
    public dataService:EcommerceService, public bookingService: BookingService,
    public pageFunctions: PageFunctions, public router: Router, public injector: Injector,
    fb: FormBuilder, private toastr: ToastrService) { 
    localStorage.setItem('appurl', environment.appUrl);
    
    this.appUrl = environment.appUrl;
    this.clientkey = environment.clientKey;
    this.loginForm = fb.group({
        'clientkey': [this.clientkey],
        'email': [null, Validators.compose([Validators.required])],
        'password': [null, Validators.required]
    });
  }

  ngOnInit() {
    this.router = this.injector.get(Router);

    if (this.content.header_styles === undefined){
      this.content['header_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "24px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      };
    }
    if (this.content['input_styles'] === undefined) {
      this.content['input_styles'] = {
        'border-top':0,
        'border-right':0,
        'border-bottom':0,
        'border-left':0,
        'border-style':'',
        'position':'',
        'border-color':'',
        'border-top-left-radius':0,
        'border-top-right-radius':0,
        'border-bottom-left-radius':0,
        'border-bottom-right-radius':0
      };
    }

    if (this.content['btn_styles'] === undefined) {
      this.content['btn_styles'] = {
        'color':'#fff',
        'background-color':'#5d9cec',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'center',
        'padding-top':10,
        'padding-right':10,
        'padding-bottom':10,
        'padding-left':10,
        'border-width':0,
        'border-style':'',
        'border-color':'',
        'border-radius':0          
      };
    }
		if (this.content['btn_styles']['text-align'] === undefined) {
			this.content['btn_styles']['text-align'] = "center";
		}    
  }

  getInputStyle(content, obj){
		var style: any = {};

    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}

    if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    else{
      style["text-decoration"] = "none";
    }
		
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

    if(obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)' ){
      this.pageFunctions.setBorder(style,obj,true);
    }

    this.pageFunctions.serBorderRadius(style, obj);


		return style;
	}

  getFormButtonStyle(obj){
		var style: any = {};

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		this.pageFunctions.setPadding(style,obj);

		if (obj['border-width'] !== null && obj['border-width'] !== undefined) {
			if (obj['border-style'] !== null && obj['border-style'] !== undefined && obj['border-style'] !== "") {
				if (obj['border-color'] !== null && obj['border-color'] !== undefined && obj['border-color'] !== "")
					style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		return style;
	}

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.loginForm.controls) {
        this.loginForm.controls[c].markAsTouched();
    }
    if (this.loginForm.valid) {
      this.authService.customerlogin(value.email, value.password)
      .pipe(first())
      .subscribe(data => {
        if(data['RESULT_CODE'] === 1){
          this.authService.checkSession().subscribe(res=>{
            if(res['RESULT_CODE'] === 1){
              localStorage.setItem('userData','{"ID":"'+data['ID']+'"}');
              this.dataService.AppData.isLoggedin = true;
              this.dataService.AppData.userData = {"ID":data['ID']};
              this.bookingService.AppBookingData.isLoggedin = true;
              this.bookingService.AppBookingData.userData = {"ID":data['ID']};
              this.authService.currentCustomerSubject.next(data);
    
              this.dataService.getWishlist().subscribe(res=>{
                if (res.RESULT_CODE === 1){
                  this.dataService.AppData.WISHLIST = res.DATA;
                }
              });
    
              var ecommerceUpdate = false;
              var bookingUpdate = false;     
                  
              this.dataService.updateCustomerCartlist().subscribe(res=>{
                ecommerceUpdate = true;
                if (res.RESULT_CODE === 1){
                  this.dataService.AppData.TOTAL_PRICE = 0;
                  this.dataService.AppData.TOTAL_CART_COUNT = 0;
                  this.dataService.AppData.CART_LIST = res.DATA;
                  this.dataService.AppData.CART_LIST.forEach(product=>{
                      this.dataService.AppData.TOTAL_PRICE = this.dataService.AppData.TOTAL_PRICE + (product.CART_COUNT * product.PRICE);
                      this.dataService.AppData.TOTAL_CART_COUNT = this.dataService.AppData.TOTAL_CART_COUNT + product.CART_COUNT;
                  });
                }
                if (bookingUpdate === true){
                  this.router.navigate([this.environment.homePage]);
                }
              });
    
              this.bookingService.updateCustomerCartlist().subscribe(res=>{
                bookingUpdate = true;
                if (res.RESULT_CODE === 1){
                  this.bookingService.AppBookingData.TOTAL_AMOUNT = 0;
                  this.bookingService.AppBookingData.TOTAL_CART_COUNT = 0;
                  this.bookingService.AppBookingData.BOOKING_CART_LIST = res.DATA;
                  this.bookingService.AppBookingData.BOOKING_CART_LIST.forEach(product=>{
                      this.bookingService.AppBookingData.TOTAL_AMOUNT = this.bookingService.AppBookingData.TOTAL_AMOUNT + (product.CART_COUNT * product.AMOUNT);
                      this.bookingService.AppBookingData.TOTAL_CART_COUNT = this.bookingService.AppBookingData.TOTAL_CART_COUNT + product.CART_COUNT;
                  });
                }
                if (ecommerceUpdate === true){
                  this.router.navigate([this.environment.homePage]);
                }
              });
            }
            else{
              swal.fire('Session Error', "Oops! It seems that your browser settings are preventing the website from setting a cross-domain cookie. If you need assistance, please don't hesitate to reach out to our support team.", 'error');
            }
          });
        }
        else {
          this.toastr.error('Incorrect username or password','Error');
        }
      },
      error => {
        this.toastr.error('Incorrect username or password','Error');
      });
    }
    else {
      this.toastr.info('Mandatory Fields not entered','Information');
    }
  }

  forgotPassword(){
    if (this.loginForm.controls['email'].value === '' || this.loginForm.controls['email'].value === null) {
      this.toastr.info('Enter your email address and click forgot password!','Forgot Password ?', {
        timeOut: 2500
      });
    }
    else{
      this.authService.forgotPasswordCustomer(this.loginForm.controls['email'].value).subscribe(data => {
        if(data['RESULT_CODE'] === 1){
          this.toastr.success('Password has been sent to your email id.. Please change password once logged in..','Success', {
            timeOut: 3000
          });
        }
        else if(data['RESULT_CODE'] === 0){
          this.toastr.info('Email id entered is not registered..','Information', {
            timeOut: 3000
          });
        }
      });
    }
  }

  gotoRegister(){
    this.router.navigate([this.dataService.AppData.RegistrationPage]);
  }

  getStyles(obj) {
		var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}
 
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}
 
		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    else{
      style["text-decoration"] = "none";
    }

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		return style;
	}
}
