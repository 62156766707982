import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { Meta, DomSanitizer } from '@angular/platform-browser';
import { PageFunctions } from '../shared/directives/PageFunctions';
import { DatePipe } from "@angular/common";
import {
	TravelMarker,
	TravelMarkerOptions,
	TravelData,
	TravelEvents,
	EventType,
  } from 'travel-marker';
declare var $: any;
declare var google: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
	oper: string = 'ADD';
	screenName: string = '';
	Id: string = '';
	TranId: string = '';
	rows: any = [];
	page: any = {};
	formHeight: number = 0;
	loading: boolean = true;
	showOverlay: boolean = true;
	isAppleDevice: boolean = false;
	apiUrl: string = '';
	window_width: any = 0;
	datasources: any = [];
	formFields: any = [];
	staticValues: any = {};
	datasets: any = {};
	filterData: any = {};
	reportData: any = {};
	dsCount: number = 0;
	designType: string = 'DESKTOP';
	currentDate: Date = new Date();

	map: any;
	markers: TravelMarker[] = [];

	public agmStyles: any = {
		default: [],
		silver: [
			{
				elementType: 'geometry',
				stylers: [{ color: '#f5f5f5' }],
			},
			{
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				elementType: 'labels.text.fill',
				stylers: [{ color: '#616161' }],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#f5f5f5' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#bdbdbd' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ color: '#eeeeee' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#757575' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#e5e5e5' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#ffffff' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#757575' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#dadada' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#616161' }],
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ color: '#e5e5e5' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [{ color: '#eeeeee' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#c9c9c9' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
		],
		night: [
			{ elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
			{
				featureType: 'administrative.locality',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#263c3f' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#6b9a76' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#38414e' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#212a37' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9ca5b3' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#746855' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#1f2835' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#f3d19c' }],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [{ color: '#2f3948' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#17263c' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#515c6d' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#17263c' }],
			},
		],
		retro: [
			{ elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
			{ elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
			{ elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#c9b2a6' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#dcd2be' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#ae9e90' }],
			},
			{
				featureType: 'landscape.natural',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#93817c' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry.fill',
				stylers: [{ color: '#a5b076' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#447530' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#f5f1e6' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [{ color: '#fdfcf8' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#f8c967' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#e9bc62' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry',
				stylers: [{ color: '#e98d58' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#db8555' }],
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#806b63' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#8f7d77' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ebe3cd' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry.fill',
				stylers: [{ color: '#b9d3c2' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#92998d' }],
			},
		],
	};

	polycolors : any = ['#0000FF','#800080','#FFFF00','#00FF00','#FF00FF','#FFA500','#A52A2A','#008000','#808000','#FF0000']

	constructor(public pageFunctions: PageFunctions, public router: Router, public dataService: DataService, 
		private route: ActivatedRoute, public toastr: ToastrService, private metaTag: Meta, 
		private datePipe: DatePipe, private sanitizer: DomSanitizer) {
		let id = '';
		this.route.queryParams.subscribe((params) => {
			id = params.id;
			this.TranId = params.tid;
		});

		var ua = navigator.userAgent;
		if (/iPhone|iPad/i.test(ua)) {
			this.isAppleDevice = true;
		}

		//this.currentDate.setDate(1);

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		this.page = {
			styles: {
				'text-align': 'center',
				'justify-content': 'center',
				'background-color': 'transparent',
				background: {
					url: '',
					fill: 1,
					repeat: 0,
					center: 1,
				},
				'padding-right': 0,
				'padding-left': 0,
				'padding-top': 0,
				'padding-bottom': 0,
				'font-family': 'Arial',
			},
		};

		this.window_width = window.innerWidth;

		if (id !== null && id !== undefined) {
			this.getPageData(id);
		}
	}

	onMapReady(map: any, mapData: any, content: any) {
		this.map = map;
		if (content.map.markertype==='Travel'){
			var idx = 0;
			mapData.forEach(groupData=>{
				this.mockDirections(groupData,idx);
				idx++;
			});
		}
		this.recenter(mapData, content);
	}

	recenter(mapData, content){
		var zoom: number = parseInt(content.map.zoom.toString());
		content.map.latitude = 0;
		content.map.longitude = 0;
		content.map.zoom = 0;
		setTimeout(()=>{
			if (content.map.markertype==='Travel'){
				content.map.latitude = mapData[0]['origin']['lat'];
				content.map.longitude = mapData[0]['origin']['lng'];
				content.map.zoom = zoom;
			}
			else{
				content.map.latitude = mapData[0]['lat'];
				content.map.longitude = mapData[0]['lng'];
				content.map.zoom = zoom;
			}
		},500);
	}

	mockDirections(locationData,colorIdx) {
		const locationArray = locationData.waypoints.map(
		  (l) => new google.maps.LatLng(l.location.lat, l.location.lng)
		);
		var line = new google.maps.Polyline({
			strokeColor: this.polycolors[colorIdx],
		  	strokeOpacity: 0.5,
		  	path: [],
		  	map: this.map,
		});
		locationArray.forEach((l) => line.getPath().push(l));
	
		const start = new google.maps.LatLng(locationData.origin.lat, locationData.origin.lng);
		const end = new google.maps.LatLng(locationData.destination.lat, locationData.destination.lng);
	
		const startMarker = new google.maps.Marker({
		  position: start,
		  map: this.map,
		  label: 'A',
		});
		const endMarker = new google.maps.Marker({
		  position: end,
		  map: this.map,
		  label: 'B',
		});
		this.initRoute(locationData.group,line);
	}

	initRoute(groupText, line) {
		const route = line.getPath().getArray();
	
		// options
		const options: TravelMarkerOptions = {
		  map: this.map, // map object
		  speed: 50, // default 10 , animation speed
		  interval: 10, // default 10, marker refresh time
		  speedMultiplier: 1,
		  markerOptions: {
			title: groupText,
			animation: google.maps.Animation.DROP,
			icon: {
			  url: 'https://i.imgur.com/eTYW75M.png',
			  animation: google.maps.Animation.DROP,
			  scaledSize: new google.maps.Size(128, 128),
			  origin: new google.maps.Point(0, 0),
			  anchor: new google.maps.Point(53, 110),
			}
		  },
		};
	
		// define marker
		var marker = new TravelMarker(options);
	
		// add locations from direction service
		marker.addLocation(route);

		this.markers.push(marker);
	
		setTimeout(() => this.play(marker), 2000);
	}

	play(marker) {
		marker.play();
	}

	reset() {
		this.markers.forEach(marker=>{
			marker.reset();
			marker.play()
		});
	}

	getCFHTML(content) {
		content.htmlvalue = this.sanitizer.bypassSecurityTrustHtml(content.value);
		return content.htmlvalue;
	}

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	stripHTML(val) {
		return val.replace(/<.*?>/g, '');
	}

	getPageData(id) {
		if (this.window_width < 700) {
			this.designType = 'MOBILE';
		} else if (this.window_width < 1000) {
			this.designType = 'TABLET';
		}
		this.dataService.getScreen(id, '', this.designType).subscribe((res: any) => {
			this.Id = res['ID'];
			this.screenName = res['Screen_Name'];

			var designConfig = res['DESIGN_CONFIGS'];

			if (this.designType === 'TABLET') {
				if (res['TABLET_DESIGN_CONFIGS'] !== null && res['TABLET_DESIGN_CONFIGS'] !== '') {
					designConfig = res['TABLET_DESIGN_CONFIGS'];
				}
			} else if (this.designType === 'MOBILE') {
				if (res['MOBILE_DESIGN_CONFIGS'] !== null && res['MOBILE_DESIGN_CONFIGS'] !== '') {
					designConfig = res['MOBILE_DESIGN_CONFIGS'];
				}
			}

			if (designConfig) {
				designConfig = designConfig.replaceAll('col-1', 'column-1');
				designConfig = designConfig.replaceAll('col-2', 'column-2');
				designConfig = designConfig.replaceAll('col-3', 'column-3');
				designConfig = designConfig.replaceAll('col-4', 'column-4');
				designConfig = designConfig.replaceAll('col-5', 'column-5');
				designConfig = designConfig.replaceAll('col-6', 'column-6');
				designConfig = designConfig.replaceAll('col-7', 'column-7');
				designConfig = designConfig.replaceAll('col-8', 'column-8');
				designConfig = designConfig.replaceAll('col-9', 'column-9');
				designConfig = designConfig.replaceAll('col-10', 'column-10');
				designConfig = designConfig.replaceAll('col-11', 'column-11');
				designConfig = designConfig.replaceAll('col-12', 'column-12');
				designConfig = designConfig.replaceAll('col-lg-1', 'column-1');
				designConfig = designConfig.replaceAll('col-lg-2', 'column-2');
				designConfig = designConfig.replaceAll('col-lg-3', 'column-3');
				designConfig = designConfig.replaceAll('col-lg-4', 'column-4');
				designConfig = designConfig.replaceAll('col-lg-5', 'column-5');
				designConfig = designConfig.replaceAll('col-lg-6', 'column-6');
				designConfig = designConfig.replaceAll('col-lg-7', 'column-7');
				designConfig = designConfig.replaceAll('col-lg-8', 'column-8');
				designConfig = designConfig.replaceAll('col-lg-9', 'column-9');
				designConfig = designConfig.replaceAll('col-lg-10', 'column-10');
				designConfig = designConfig.replaceAll('col-lg-11', 'column-11');
				designConfig = designConfig.replaceAll('col-lg-12', 'column-12');

				var design_configs = JSON.parse(designConfig);
				this.rows = design_configs['rows'];
				this.page = design_configs['page'];

				if (design_configs['formfields'] !== undefined && design_configs['formfields'] !== null) {
					this.formFields = design_configs['formfields'];
					this.initializeFilter();
					this.setStaticValues();
				}

				if (design_configs['datasources'] !== undefined && design_configs['datasources'] !== null) {
					this.datasources = design_configs['datasources'];
					this.initializeDataset();
				}
			}
		});
	}

	loadPage(obj) {
		this.rowsHidden.forEach((row) => {
			row.styles['hide-row-in-display'] = true;
		});
		if (obj.url !== undefined && obj.url !== '') {
			if (obj.navigateUrl !== undefined && obj.navigateUrl !== null && obj.navigateUrl !== '') {
				this.goToUrl(obj.navigateUrl, obj.target, obj);
			} else if (obj.type !== 'Image') {
				this.goToUrl(obj.url, obj.target, obj);
			}
		} else if (obj.row_id !== undefined && obj.row_id !== '') {
			var elements = document.getElementById(obj.row_id);
			if (obj['menu_action'] === 'visible') {
				this.rows.forEach((r) => {
					if (obj.row_id === r.id) {
						r.styles['hide-row-in-display'] = !r.styles['hide-row-in-display'];
					}
				});
			} else if (obj['menu_action'] === 'scroll') {
				if (elements !== undefined && elements !== null) {
					elements.scrollIntoView();
				}
			}
		}
	}

	goToUrl(url, target, content) {
		if (url !== null && url !== undefined && url !== '') {
			if (target === undefined || target === '' || target === '_self') {
				this.router.navigate([url]);
			} else {
				window.open(url, '_blank');
			}

			if ($('#hamburgerbtn').hasClass('active')) {
				$('#hamburgerbtn').removeClass('active');
				$('#hamburgermenu').toggle('slow', function () {});
			}
		}
	}

	setStaticValues() {
		this.formFields.forEach((field) => {
			if (field['DATA_TYPE'] === 'Dropdown' && field.DROPDOWN_SQL !== '') {
				this.dataService.getSelectOptions(field.DROPDOWN_SQL).subscribe(
					(result) => {
						if (result['RESULT_CODE'] === 1) {
							this.staticValues[field.ID] = result['DATA'];
						}
					},
					(error) => {
						console.error(error);
					}
				);
			} else if (field['DATA_TYPE'] === 'Dropdown' && field.DROPDOWN_JSON !== '') {
				this.staticValues[field.ID] = JSON.parse(field['DROPDOWN_JSON'].replace('\n', ''));
			}
		});
	}

	initializeDataset() {
		this.dsCount = this.datasources.length;
		this.datasources.forEach((ds) => {
			this.datasets[ds.ID] = [];
		});
		this.getReportData();
	}

	initializeFilter() {
		this.formFields.forEach((fld) => {
			if (fld['DATA_TYPE']==='Date')
				this.filterData[fld.FIELD_NAME] = this.currentDate;
			else
				this.filterData[fld.FIELD_NAME] = '';
		});
	}

	parseDate(dateString: string): Date {
		if (dateString) {
			return new Date(dateString);
		}
		return new Date();
	  }

	getChartData(contentId, settings) {
		var data: any = [];
		if (settings.datasource !== undefined && settings.datasource !== '' && this.datasets[settings.datasource] !== undefined && this.datasets[settings.datasource].length > 0) {
			if (settings.textfield !== '' && settings.valuefield !== '') {
				var datasource = this.datasources.filter((ds) => ds.ID === settings.datasource);
				if (datasource.length > 0) {
					var queryTables = datasource[0]['QUERY_FIELDS'];
					var textFieldName = '';
					var valueFieldName = '';
					var groupFieldName = '';

					queryTables.forEach((table) => {
						var flds = table.Fields.filter((fld) => fld.DO_FIELD_ID === settings.textfield);
						if (flds.length > 0) {
							textFieldName = flds[0]['Alias'];
						}

						flds = table.Fields.filter((fld) => fld.DO_FIELD_ID === settings.valuefield);
						if (flds.length > 0) {
							valueFieldName = flds[0]['Alias'];
						}

						flds = table.Fields.filter((fld) => fld.DO_FIELD_ID === settings.groupfield);
						if (flds.length > 0) {
							groupFieldName = flds[0]['Alias'];
						}

						if (table.Functions !== undefined) {
							flds = table.Functions.filter((fld) => fld.ID === settings.textfield);
							if (flds.length > 0) {
								textFieldName = flds[0]['Alias'];
							}

							flds = table.Functions.filter((fld) => fld.ID === settings.valuefield);
							if (flds.length > 0) {
								valueFieldName = flds[0]['Alias'];
							}

							flds = table.Functions.filter((fld) => fld.ID === settings.groupfield);
							if (flds.length > 0) {
								groupFieldName = flds[0]['Alias'];
							}
						}
					});

					var queryExpressions = datasource[0]['EXPRESSION_FIELDS'];
					if (queryExpressions !== undefined && queryExpressions.length > 0) {
						var flds = queryExpressions.filter((fld) => fld.ID === settings.textfield);
						if (flds.length > 0) {
							textFieldName = flds[0]['Alias'];
						}

						flds = queryExpressions.filter((fld) => fld.ID === settings.valuefield);
						if (flds.length > 0) {
							valueFieldName = flds[0]['Alias'];
						}

						flds = queryExpressions.filter((fld) => fld.ID === settings.groupfield);
						if (flds.length > 0) {
							groupFieldName = flds[0]['Alias'];
						}
					}

					if (settings.chart_type === 'Treemap' || settings.chart_type === 'VerticalBar' || settings.chart_type === 'HorizontalBar' || settings.chart_type === 'Pie' || settings.chart_type === 'PieGrid' || settings.chart_type === 'AdvancedPie') {
						this.datasets[settings.datasource].forEach((rec) => {
							if (rec[textFieldName] === null) {
								rec[textFieldName] = '';
							}
							if (rec[valueFieldName] === null) {
								rec[valueFieldName] = 0;
							}
							data.push({ name: rec[textFieldName], value: rec[valueFieldName] });
						});
					} else if (settings.chart_type === 'HorizontalBar2D' || settings.chart_type === 'VerticalBar2D' || settings.chart_type === 'LineChart' || settings.chart_type === 'AreaChart' || settings.chart_type === 'StackedVerticalChart' || settings.chart_type === 'StackedHorizontalChart') {
						var group = {};
						this.datasets[settings.datasource].forEach((rec) => {
							if (rec[textFieldName] === null) {
								rec[textFieldName] = '';
							}
							if (rec[valueFieldName] === null) {
								rec[valueFieldName] = 0;
							}
							if (rec[groupFieldName] === null) {
								rec[groupFieldName] = '';
							}
							var columndata = { name: rec[textFieldName], value: rec[valueFieldName] };

							if (data.filter((dt) => dt.name === rec[groupFieldName]).length > 0) {
								group = data.filter((dt) => dt.name === rec[groupFieldName])[0];
								group['series'].push(columndata);
							} else {
								group = { name: rec[groupFieldName], series: [] };
								group['series'].push(columndata);
								data.push(group);
							}
						});
					}
				}
			}
		}
		this.reportData[contentId] = data;
		return data;
	}

	getMapData(contentId,settings){
		var data: any = [];
		var mapdata: any = [];
		var groupdata: any = [];
		var source: any = {};
		var dest: any = {};
		var way_points: any = [];

		if (settings.datasource !== undefined && settings.datasource !== '' && this.datasets[settings.datasource] !== undefined && this.datasets[settings.datasource].length > 0) {
			if (settings.group_field !== '') {
				var datasource = this.datasources.filter((ds) => ds.ID === settings.datasource);
				if (datasource.length > 0) {
					settings['latitude'] = parseFloat(this.datasets[settings.datasource][0][settings.lat_field_alias]);
					settings['longitude'] = parseFloat(this.datasets[settings.datasource][0][settings.lng_field_alias]);
					
					data = this.datasets[settings.datasource];
					var seq = 0;
					data.forEach(item=>{
						item['RECORD_SEQ'] = seq;
						seq++;
					});
					if (settings.markertype==='Travel'){
						if (settings.group_field_alias !== undefined && settings.group_field_alias !== ""){
							const groups = [...new Set(data.map((item) => item[settings.group_field_alias]))];
							var idx = 0;
							groups.forEach(grp=>{
								if (idx===10) idx=0;
								groupdata = data.filter(item=>item[settings.group_field_alias]===grp);
								groupdata.sort(this.GetSortOrder('RECORD_SEQ'));
								
								source = {lat:parseFloat(groupdata[0][settings.lat_field_alias]),
									lng:parseFloat(groupdata[0][settings.lng_field_alias])};
								dest = {lat:parseFloat(groupdata[groupdata.length-1][settings.lat_field_alias]),
									lng:parseFloat(groupdata[groupdata.length-1][settings.lng_field_alias])};

								way_points = [];
								var point_no = 1;
								groupdata.forEach(record=>{
									way_points.push({location:{lat:parseFloat(record[settings.lat_field_alias]),
										lng:parseFloat(record[settings.lng_field_alias]),
										text: point_no.toString()
									}});
									point_no++;
								});

								mapdata.push({group:grp,origin:source,destination:dest,waypoints:way_points});
								idx++;
							});
						}
						else{
							groupdata = data;
							source = {lat:parseFloat(groupdata[0][settings.lat_field_alias]),
								lng:parseFloat(groupdata[0][settings.lng_field_alias])};
							dest = {lat:parseFloat(groupdata[groupdata.length-1][settings.lat_field_alias]),
								lng:parseFloat(groupdata[groupdata.length-1][settings.lng_field_alias])};

							way_points = [];
							var point_no = 1;
							groupdata.forEach(record=>{
								way_points.push({location:{lat:parseFloat(record[settings.lat_field_alias]),
									lng:parseFloat(record[settings.lng_field_alias]),
									text: point_no.toString()
								}});
								point_no++;
							});
							mapdata.push({group:"",origin:source,destination:dest,waypoints:way_points,
								renderOptions: { suppressMarkers: false, polylineOptions: { strokeColor: this.polycolors[0] } }
							});
						}
					}
					else{
						way_points = [];
						var point_no = 1;
						data.forEach(record=>{
							way_points.push({lat:parseFloat(record[settings.lat_field_alias]),
								lng:parseFloat(record[settings.lng_field_alias]),
								text: record[settings.group_field_alias],
								label: point_no.toString()
							});
							point_no++;
						});
						mapdata = way_points;
					}
				}
			}
		}
		
		this.reportData[contentId] = mapdata;
	}

	getDataFieldData(contentId, settings) {
		var data: any = '';
		if (settings.datasource !== undefined && settings.datasource !== '' && this.datasets[settings.datasource] !== undefined && this.datasets[settings.datasource].length > 0) {
			if (settings.field !== '') {
				var datasource = this.datasources.filter((ds) => ds.ID === settings.datasource);
				if (datasource.length > 0) {
					var queryTables = datasource[0]['QUERY_FIELDS'];
					var textFieldName = '';

					queryTables.forEach((table) => {
						var flds = table.Fields.filter((fld) => fld.DO_FIELD_ID === settings.field);
						if (flds.length > 0) {
							textFieldName = flds[0]['Alias'];
						}

						if (table.Functions !== undefined) {
							flds = table.Functions.filter((fld) => fld.ID === settings.field);
							if (flds.length > 0) {
								textFieldName = flds[0]['Alias'];
							}
						}
					});

					var queryExpressions = datasource[0]['EXPRESSION_FIELDS'];
					if (queryExpressions !== undefined && queryExpressions.length > 0) {
						var flds = queryExpressions.filter((fld) => fld.ID === settings.field);
						if (flds.length > 0) {
							textFieldName = flds[0]['Alias'];
						}
					}

					data = this.datasets[settings.datasource][0][textFieldName];
				}
			}
		}
		this.reportData[contentId] = data;
		return data;
	}

	getDataTableData(contentId, settings) {
		var data: any = [];
		var columns: any = [];
		if (settings.datasource !== undefined && settings.datasource !== '') {
			if (settings.fields.length > 0) {
				settings.fields.forEach((table) => {
					table.Fields.forEach((field) => {
						if (field.Selected === true && field.Width !== '0') {
							columns.push(field);
						}
					});
					if (table.Functions !== undefined) {
						table.Functions.forEach((field) => {
							if (field.Display_Name === undefined) {
								field['Display_Name'] = field.Field_Name;
							}
							columns.push(field);
						});
					}
				});
				if (columns.length > 0) columns.sort(this.GetSortOrder('Sequence'));
			}
			if (settings.expressions !== undefined && settings.expressions.length > 0) {
				settings.expressions.forEach((expr) => {
					if (expr.Width !== '0') {
						columns.push(expr);
					}
				});
				if (columns.length > 0) columns.sort(this.GetSortOrder('Sequence'));
			}
		}
		if (this.datasets[settings.datasource] !== undefined) {
			this.reportData[contentId] = { columns: columns, rows: this.datasets[settings.datasource] };
		} else {
			this.reportData[contentId] = { columns: columns, rows: [] };
		}
		return data;
	}

	GetSortOrder(prop) {
		return function (a, b) {
			if (a[prop] > b[prop]) {
				return 1;
			} else if (a[prop] < b[prop]) {
				return -1;
			}
			return 0;
		};
	}

	applyFilter(): void {
		this.initializeDataset();
	}

	getFilterDateValue(Field_Name){
		var fld = this.formFields.filter(fld=>fld.FIELD_NAME===Field_Name)[0];
		if (fld['DATA_TYPE']==='Date')
			return this.datePipe.transform(this.filterData[Field_Name],"yyyy-MM-dd");
		else
			return this.filterData[Field_Name];
	}

	getReportData() {
		this.dsCount = this.datasources.length;
		this.datasources.forEach((ds) => {
			var paramWhere = '';

			ds.QUERY_PARAMS.forEach((param) => {
				if (param['Field_Name'] === 'ID' || param['Field_Name'] === 'REF_ID') {
					paramWhere += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '" + this.TranId + "'";
				} else {
					if (param.Value !== null && param.Value !== '') {
						var fld = this.formFields.filter(fld=>fld.FIELD_NAME===param.Value)[0];
						if (fld['DATA_TYPE']==='Date'){
							if (this.filterData[param.Value] !== null && this.filterData[param.Value] !== '') paramWhere += ' AND Date(`' + param['Alias'] + '`.`' + param['Field_Name'] + '`) ' + param['Operation'] + " '" + this.datePipe.transform(this.filterData[param.Value],"yyyy-MM-dd") + "'";
						}
						else{
							if (this.filterData[param.Value] !== null && this.filterData[param.Value] !== '') paramWhere += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '" + this.filterData[param.Value] + "'";
						}
					}
				}
			});

			this.dataService.getQueryData(ds.QUERY_ID, paramWhere).subscribe(
				(res) => {
					this.dsCount--;
					if (res.DATA !== undefined) {
						this.datasets[ds.ID] = res.DATA;
					}
					if (this.dsCount === 0) {
						this.processReportContent();
					}
				},
				(error) => {
					this.dsCount--;
					if (this.dsCount === 0) {
						this.processReportContent();
					}
				}
			);
		});
	}

	processReportContent() {
		this.rows.forEach((row) => {
			row.columns.forEach((column) => {
				column.contents.forEach((content) => {
					if (content.type === 'Charts') {
						this.getChartData(content.id, content.chart);
					} else if (content.type === 'DataField') {
						this.getDataFieldData(content.id, content.datafield);
					} else if (content.type === 'DataTable') {
						this.getDataTableData(content.id, content.datatable);
					} else if (content.type === 'Map') {
						this.getMapData(content.id, content.map);
					}
				});
				this.showOverlay = false;
			});
		});
	}

	rowsHidden: any = [];
	//elements visible only on scroll and animation set on scroll
	@HostListener('window:scroll', ['$event'])
	scrollHandler(event) {
		this.setVisibleRows('scroll');
	}
	setVisibleRows(eventType) {
		this.rows.forEach((row) => {
			if (eventType === '') {
				if (row.styles['hide-row-in-display']) {
					this.rowsHidden.push(row);
				}
			}
			var ele_row = document.getElementById(row.id);
			if (ele_row) {
				let rowTop = this.checkInView(ele_row, true);
				if (rowTop) {
					//if animation is already run for that row then do not run the animation
					this.setRowAnimation(row);
					row.columns.forEach((column) => {
						this.setColumnAnimation(column, row);
						column.contents.forEach((content) => {
							if (content.type === 'MenuGroup') {
								let menuidx = 0;
								content.menus.forEach((menu) => {
									var content_ele_row = document.getElementById(content.id + menuidx);
									if (content_ele_row) {
										if (content.styles['hover-available']) {
											content_ele_row.style.setProperty('--hover-bgcolor', content.styles['hover-bgcolor']);
											content_ele_row.style.setProperty('--hover-color', content.styles['hover-color']);
										}
									}
									menuidx++;
								});
							} else {
								var content_ele_row = document.getElementById(content.id);
								if (content_ele_row) {
									if (content.styles['hover-available']) {
										content_ele_row.style.setProperty('--hover-bgcolor', content.styles['hover-bgcolor']);
										content_ele_row.style.setProperty('--hover-color', content.styles['hover-color']);
									}
								}
							}
							if (this.pageFunctions.checkNull(content.styles.animation)) {
								var content_ele_row = document.getElementById(content.id);
								let contentVisible = this.checkInView(content_ele_row, true);
								if (contentVisible) {
									this.setContentAnimation(content, column);
								}
							}
						});
					});
				}
			}
		});
		this.rowsHidden.forEach((row) => {
			if (row.styles['hide-row-in-display']) {
				if (eventType === 'scroll') {
					if (!row.styles['persist-on-scroll']) {
						row.styles['hide-row-in-display'] = true;
					}
				} else {
					row.styles['hide-row-in-display'] = !row.styles['default-visible'];
				}
			}
		});
	}

	checkInView(elem, partial) {
		var contHeight = window.innerHeight;
		var contTop = window.scrollY;
		var contBottom = contTop + contHeight;

		var elemTop = $(elem).offset().top - window.pageYOffset;
		var elemBottom = elemTop + $(elem).height();

		var isTotal = elemTop >= 0 && elemBottom <= contHeight;
		var isPart = ((elemTop <= 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= window.innerHeight)) && partial;

		return isTotal || isPart;
	}

	setOpacity(content, blnSetOpacity, idPrefix) {
		content.styles.opacityAlreadySet = true;
		try {
			var content_ele_row = document.getElementById(idPrefix + content.id);
			if (content_ele_row) {
				if (blnSetOpacity) {
					content_ele_row.style.opacity = content.styles.opacity;
				}
				if (this.pageFunctions.checkNull(content.styles['animation-offset'])) {
					content_ele_row.style.setProperty('--from-width', content.styles['animation-offset'] + 'px');
					content_ele_row.style.setProperty('overflow', 'hidden');
				}
				if (this.pageFunctions.checkNull(content.styles['animation-start-delay'])) {
					content_ele_row.style.setProperty('--animation-delay', content.styles['animation-start-delay'] + 's');
				} else {
					content_ele_row.style.setProperty('--animation-delay', '0s');
				}
				if (this.pageFunctions.checkNull(content.styles['animation-iteration'])) {
					content_ele_row.style.setProperty('--animation-iteration', content.styles['animation-iteration']);
				} else {
					content_ele_row.style.setProperty('--animation-iteration', '1');
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	setRowAnimation(row) {
		var videoHolder = $('#video_' + row.id);
		if (videoHolder.length > 0) {
			try {
				videoHolder[0].play();
			} catch (e) {}
		}

		var dataclass = row.styles.animation;
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = row.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				this.setOpacity(row, false, '');
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + row.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {});
					row.styles.animation = '';
				}
			}, 2000);
		}
	}

	setColumnAnimation(column, row) {
		var videoHolder = $('#video_' + row.id + '_' + column.id);
		if (videoHolder.length > 0) {
			try {
				videoHolder[0].play();
			} catch (e) {}
		}

		var dataclass = column.styles.animation;
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = column.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				this.setOpacity(column, false, '');
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + column.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
							if (animationInterval !== 'animated10 infinite') {
								$(this).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
							}
						});
					$('#' + row.id).addClass('h-scroll-text-container');
					column.styles.animation = '';
				}
			}, 1000);
		}
	}

	setContentAnimation(content, column) {
		var dataclass = content.styles.animation;
		var idPrefix = '';
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = content.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				if (content.type === 'CLIPATH' && dataclass.toLowerCase() === 'gradient') idPrefix = 'animate';
				else idPrefix = '';
				this.setOpacity(content, true, idPrefix);
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + idPrefix + content.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
							if (animationInterval !== 'animated10 infinite') {
								$('#' + idPrefix + content.id).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
							}
						});
					$('#' + content.id + column.id).addClass('h-scroll-text-container');
					content.styles.animation = '';
				}
			}, 500);
		}
	}

	getPageStyles(obj) {
		var style = {};

		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style['font-family'] = obj['font-family'];
		}

		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style['text-align'] = obj['text-align'];
		}

		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style['justify-content'] = obj['justify-content'];
		}

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style['padding'] = obj['padding-top'] + 'px ' + obj['padding-right'] + 'px ' + obj['padding-bottom'] + 'px ' + obj['padding-left'] + 'px';
		}

		if (obj['background'] !== null && obj['background'] !== undefined && obj['background']['url'] !== null && obj['background']['url'] !== undefined && obj['background']['url'] !== '') {
			style['background-image'] = 'url(' + obj['background']['url'] + ')';

			if (obj['background']['center'] === 1) {
				style['background-position'] = 'center';
			}
			if (obj['background']['repeat'] === 1) {
				style['background-repeat'] = 'repeat';
			} else {
				style['background-repeat'] = 'no-repeat';
			}
			if (obj['background']['fill'] === 1) {
				style['background-size'] = 'cover';
			}
		} else if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== '') {
			style['background-color'] = obj['background-color'];
		}

		return style;
	}

	getMainRowStyle(row) {
		var style = {};

		if (row['styles']['row-full-width'] !== undefined && row['styles']['row-full-width'] === true) {
			style['width'] = '100%';
			style['justify-content'] = 'center';
		} else {
			style['width'] = '100%';
			style['max-width'] = '1366px';
			style['justify-content'] = 'center';
		}
		return style;
	}

	getRowStyles(row, obj, idx) {
		if (row['gen-style'] === undefined) {
			row['gen-style'] = this.pageFunctions.getPageRowStyles(row, obj, idx, this.isAppleDevice);
		}
		return row['gen-style'];
	}

	getRowBackgroundColor(obj) {
		return this.pageFunctions.getRowBackgroundColor(obj);
	}

	getColumnBackgroundColor(obj) {
		return this.pageFunctions.getRowBackgroundColor(obj);
	}

	getColumnStyles(column) {
		if (!this.isMobile()) {
			column['gen-style'] = this.pageFunctions.getColumnStyles(column, 1);
		} else {
			column['gen-style'] = { padding: '0px 15px' };
		}
		return column['gen-style'];
	}

	isMobile() {
		return this.window_width < 700;
	}

	getStyles(row, column, obj) {
		return this.getStylesType(row, column, obj, '', obj);
	}

	getImageStyles(content) {
		var style = {};
		if (this.window_width < 800) {
			style['width'] = '100%';
			style['margin-left'] = '10px';
			style['margin-top'] = '10px';
		} else {
			style['width'] = '100%';
			style['height'] = '100%';
		}
		return style;
	}

	getStylesWithShadow(row, column, content, obj, contentType, objShadow) {
		var style: any = {};

		if (content['style-' + contentType + '-' + content.id] === undefined) {
			style = this.getStylesType(row, column, content, obj, contentType);
			this.pageFunctions.setShadow(style, objShadow);

			content['style-' + contentType + '-' + content.id] = style;
		}
		style = null;

		return content['style-' + contentType + '-' + content.id];
	}

	getStylesType(row, column, content, obj, contentType) {
		var style: any = {};

		if (!this.pageFunctions.checkNull(obj.animation) && (obj.opacityAlreadySet === undefined || obj.opacityAlreadySet)) {
			style['opacity'] = obj['opacity'];
		}
		if (contentType === 'Charts') {
			style['stroke'] = obj['color'];
			style['fill'] = obj['color'];
			style['--legend_color'] = obj['color'];
		}
		if (contentType !== 'Text' && contentType !== 'TextImage') {
			if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
				style['font-family'] = obj['font-family'];
			}
			if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
				style['text-align'] = obj['text-align'];
			}
			if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
				style['justify-content'] = obj['justify-content'];
			}
		} else {
			if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
				style['text-align'] = 'left';
			}
			if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
				style['justify-content'] = 'left';
			}
		}

		if (contentType !== 'Forms') {
			this.pageFunctions.setShadow(style, obj);
		}

		if (obj['letter-spacing'] !== null && obj['letter-spacing'] !== undefined) {
			style['letter-spacing'] = obj['letter-spacing'];
		}
		this.pageFunctions.checkNullAndAssign(style, obj, 'clip-path', '');
		if (contentType !== 'MenuGroupPrimary') {
			this.pageFunctions.setPadding(style, obj);
		}
		if (contentType !== 'CLIPATH') {
			if (this.pageFunctions.checkNull(obj['rotate'])) {
				style['rotate'] = obj['rotate'] + 'deg';
			}
		}

		if (contentType !== 'Image' && contentType !== 'TextImage') {
			if (contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Main') {
				if (contentType === 'Shape' || contentType === 'CLIPATH') {
					if (obj['border-color'] !== undefined && obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
						this.pageFunctions.setBorder(style, obj, true);
					} else {
						this.pageFunctions.setBorder(style, obj, false);
					}
				} else {
					this.pageFunctions.setBorder(style, obj, true);
				}

				this.pageFunctions.serBorderRadius(style, obj);
			}
			if (contentType !== 'Filters' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Main' && contentType !== 'BLOG' && contentType !== 'Forms' && contentType !== 'TypeForm' && contentType !== 'Text' && contentType !== 'TextImage' && contentType !== 'MVSection') {
				if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
					style['border-radius'] = obj['border-radius'] + 'px';
				}
			}
			this.pageFunctions.setBackgroundImage(style, obj);
			this.pageFunctions.setBackground(style, obj);
			if (contentType === 'CLIPATH') {
				style['background-color'] = obj['background-color'];
			}
		}

		if (contentType === 'Filters' || contentType === 'Line' || contentType === 'DataField' || contentType === 'Shape' || contentType === 'CLIPATH' || contentType === 'Text' || contentType === 'TextImage' || contentType === 'Image' || contentType === 'Button') {
			style['position'] = obj['position'];
		}

		if (obj['mobile-background-color'] !== null && obj['mobile-background-color'] !== undefined && contentType === 'MobileMenu') {
			style['background-color'] = obj['mobile-background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = 'block';
			style['width'] = '100%';
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style['line-height'] = obj['line-height'];
		}

		if (contentType !== 'TextImage' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub' && contentType !== 'MVSection') {
			if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== '' && obj['height'] !== 0) {
				style['height'] = this.calculateHeightForResolution(obj['height']) + 'px';
			}
			if (contentType === 'DataTable') {
				style['overflow'] = 'auto';
			}
		}

		if (contentType !== 'TextImage' && contentType !== 'CLIPATH' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub') {
			if (contentType === 'Line' || contentType === 'Shape' || contentType === 'Button' || contentType === 'DataField' || contentType === 'DataTable' || contentType === 'Filters' || contentType === 'Charts' || contentType === 'Text' || contentType === 'TextArea' || contentType === 'Carousel') {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					var objWidth = obj['width'];
					if (contentType === 'Text') {
						if (obj['width-type'] !== null && obj['width-type'] !== undefined && obj['width-type'] !== '' && obj['width-type'] === 'px') {
							//cargo cult
						} else {
							objWidth = obj['width'] * 13.8;
							obj['width-type'] = 'px';
						}
					}
					if (this.page.styles['page-width'] === 1366) style['width'] = this.calculateWidthForResolution(column, objWidth) + '%';
					else style['width'] = objWidth + 'px';

					style['width-type'] = 'px';
				}
			} else if (contentType === 'Image') {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					style['width'] = this.calculateWidthForResolution(column, obj['width']) + '%';
				}
			} else {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					style['width'] = obj['width'] + '%';
				}
			}
		}
		if (contentType !== 'MenuGroup' && contentType !== 'CLIPATH' && contentType !== 'MobileMenu' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub') {
			if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== '') {
				style['margin-top'] = obj['margin-top'];
			}
			if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== '') {
				style['margin-left'] = this.getLeftMargin(obj, row);
			}
		}

		//left padding is required only for desktop view and not needed for mobile view
		if (this.window_width > 700 || this.page.styles['page-width'] !== 1366) {
		} else {
			// for image containers in mobile view, if the width of the
			//set image is less than 200(used for icons) then set the width
			//instead of 100%
			if (contentType === 'Shape' || contentType === 'CLIPATH' || contentType === 'Text' || contentType === 'Image' || contentType === 'Button'
				|| contentType === 'DataField' || contentType === 'DataTable' || contentType === 'Filters' || contentType === 'Charts') {
				var wid = obj['width'];
				if (!isNaN(wid)) {
					if (contentType !== 'Image' || wid > 200) {
						wid = (wid * this.window_width) / 357;
						wid = Math.round(wid);
					} else {
						if (parseInt(wid) > this.window_width) {
							wid = this.window_width;
						}
					}
				}
				style['width'] = wid + 'px';
			}
		}
		content['style-' + contentType + '-' + content.id] = style;

		style = null;

		return content['style-' + contentType + '-' + content.id];
	}

	getContentWidth(wid) {
		if (this.window_width > 700 || this.page.styles['page-width'] != 1366) {
		} else {
			if (!isNaN(wid)) {
				if (wid > 200) {
					wid = (wid * this.window_width) / 357;
					wid = Math.round(wid);
				} else {
					if (parseInt(wid) > this.window_width) {
						wid = this.window_width;
					}
				}
				//reduce scroller width 9px
				wid = wid - 9;
			}
		}
		return wid;
	}

	getShadowStyle(obj) {
		var style = {};
		this.pageFunctions.setShadow(style, obj);
		if (obj.type !== 'Video') {
			if (!this.pageFunctions.checkNull(obj.animation) && (obj.opacityAlreadySet === undefined || obj.opacityAlreadySet)) {
				style['opacity'] = obj['opacity'];
			}
		}
		return style;
	}

	getLeftMargin(obj, row) {
		var adjustMargin = parseInt(obj['margin-left'].toString().replace('px', ''));
		if (this.window_width < 700) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 357;
		} else if (this.window_width < 1000) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 768;
		} else if (this.window_width < 1366 || (row['id'] !== undefined && row.styles['row-full-width'] === true)) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 1366;
		}
		adjustMargin = Math.max(0, adjustMargin);
		return adjustMargin + 'px';
	}

	//screen width used in configuration is 1366, calculating the proportion
	//based on configured width and current screen display width
	calculateWidthForResolution(column, width) {
		var ww = 100;
		var col = 0;
		var column_border = 0;

		if (column.class === 'column-1') {
			col = 1;
		} else if (column.class === 'column-2') {
			col = 2;
		} else if (column.class === 'column-3') {
			col = 3;
		} else if (column.class === 'column-4') {
			col = 4;
		} else if (column.class === 'column-5') {
			col = 5;
		} else if (column.class === 'column-6') {
			col = 6;
		} else if (column.class === 'column-7') {
			col = 7;
		} else if (column.class === 'column-8') {
			col = 8;
		} else if (column.class === 'column-9') {
			col = 9;
		} else if (column.class === 'column-10') {
			col = 10;
		} else if (column.class === 'column-11') {
			col = 11;
		} else if (column.class === 'column-12') {
			col = 12;
		}

		if (column.styles['border-left'] !== undefined && column.styles['border-left'] !== '') {
			column_border += parseInt(column.styles['border-left']) || 0;
		}
		if (column.styles['border-right'] !== undefined && column.styles['border-right'] !== '') {
			column_border += parseInt(column.styles['border-right']) || 0;
		}

		if (this.window_width < 700) 
			ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (357 / (12 - col + 1)))) / this.window_width) * (12 / col);
		else if (this.window_width < 1000) 
			ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (768 / (12 - col + 1)))) / this.window_width) * (12 / col);
		else {
			ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (1366 / (12 - col + 1)))) / this.window_width) * (12 / col);
		}

		return ww;
	}

	calculateHeightForResolution(height) {
		return height;
		//return this.window_width * parseInt(height) / 1366;
	}

	switchImage(id, enter, content) {
		var ele = document.getElementById('image' + id);

		if (!this.pageFunctions.checkNull(content.styles['hover-url'])) return;

		if (ele) {
			ele.setAttribute('src', enter ? content.styles['hover-url'] : content['url']);
		}
	}

	getSkewState(obj) {
		var rotate = '';
		if (this.pageFunctions.checkNull(obj['rotate'])) {
			rotate = 'rotate(' + obj['rotate'] + 'deg)';
		}
		if (this.pageFunctions.checkNull(obj['skew']) && obj['skew'] !== '0') {
			rotate += 'skew(' + obj['skew'] + 'deg,0deg)';
		}
		return rotate;
	}

	getMarginClass(size) {
		try {
			if (parseInt(size.replace('px', '')) > 100) {
				return true;
			}
		} catch (e) {}
		return false;
	}

	getFontClass(size) {
		try {
			if (parseInt(size.replace('px', '')) > 30) {
				return true;
			}
		} catch (e) {}
		return false;
	}

	setTextAnimation(content, column) {
		var dataclass = content.styles.animation;
		var animationInterval = content.styles['animation-interval'];
		if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
			animationInterval = 'animated';
		}
		if (dataclass !== undefined && dataclass !== '') {
			$('#' + content.id)
				.addClass(dataclass + ' ' + animationInterval)
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
					$(this).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
				});
			$('#' + content.id + column.id).addClass('h-scroll-text-container');
			content.styles.animation = '';
		}
	}

	setclass(row) {
		var dataclass = row.styles.animation;
		var animationInterval = row.styles['animation-interval'];
		if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
			animationInterval = 'animated';
		}
		if (dataclass !== undefined && dataclass !== '') {
			$('#' + row.id)
				.addClass(dataclass + ' ' + animationInterval)
				.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
					$(this).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
				});
			row.styles.animation = '';
		}
	}

	ngOnInit(): void {
		this.formHeight = window.innerHeight;
	}

	ngAfterViewInit(): void {}

	getInputStyles(obj) {
		var style: any = {};

		if (obj === undefined) return style;

		if (obj['background-color'] !== undefined) {
			style['background-color'] = obj['background-color'];
		}
		if (obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
			style['border-top'] = obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-bottom'] !== null && obj['border-bottom'] !== undefined) {
			style['border-bottom'] = obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-left'] !== null && obj['border-left'] !== undefined) {
			style['border-left'] = obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-right'] !== null && obj['border-right'] !== undefined) {
			style['border-right'] = obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}

		if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
			style['border-top-left-radius'] = obj['border-top-left-radius'] + 'px';
		}
		if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
			style['border-top-right-radius'] = obj['border-top-right-radius'] + 'px';
		}
		if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
			style['border-bottom-left-radius'] = obj['border-bottom-left-radius'] + 'px';
		}
		if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
			style['border-bottom-right-radius'] = obj['border-bottom-right-radius'] + 'px';
		}

		return style;
	}

	getButtonStyles(obj) {
		var style: any = {};

		if (obj === undefined) return style;

		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style['background-color'] = obj['background-color'];
		}
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style['padding-top'] = obj['padding-top'] + 'px';
		}
		if (obj['padding-bottom'] !== null && obj['padding-bottom'] !== undefined) {
			style['padding-bottom'] = obj['padding-bottom'] + 'px';
		}
		if (obj['padding-left'] !== null && obj['padding-left'] !== undefined) {
			style['padding-left'] = obj['padding-left'] + 'px';
		}
		if (obj['padding-right'] !== null && obj['padding-right'] !== undefined) {
			style['padding-right'] = obj['padding-right'] + 'px';
		}

		if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
			style['border-top'] = obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-bottom'] !== null && obj['border-bottom'] !== undefined) {
			style['border-bottom'] = obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-left'] !== null && obj['border-left'] !== undefined) {
			style['border-left'] = obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-right'] !== null && obj['border-right'] !== undefined) {
			style['border-right'] = obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}

		if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
			style['border-top-left-radius'] = obj['border-top-left-radius'] + 'px';
		}
		if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
			style['border-top-right-radius'] = obj['border-top-right-radius'] + 'px';
		}
		if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
			style['border-bottom-left-radius'] = obj['border-bottom-left-radius'] + 'px';
		}
		if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
			style['border-bottom-right-radius'] = obj['border-bottom-right-radius'] + 'px';
		}

		return style;
	}
}
