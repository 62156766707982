import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, DataService } from '../../services';
import { EnvService } from '../../../environments/env.service';

declare var $: any;
declare var require: any;
const Swal = require('sweetalert2');

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	@ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

	appUrl: string = '';
	valForm: FormGroup;
	valFPForm: FormGroup;

	loginform = true;
	recoverform = false;

	constructor(private environment: EnvService, private authService: AuthService, public dataService: DataService, public router: Router, public injector: Injector, fb: FormBuilder, private toastr: ToastrService) {
		localStorage.setItem('appurl', environment.appUrl);

		this.appUrl = environment.appUrl;
		this.valForm = fb.group({
			username: [null, Validators.compose([Validators.required])],
			password: [null, Validators.required],
		});

		this.valFPForm = fb.group({
			email: [null, Validators.compose([Validators.required])],
		});
	}

	ngOnInit() {
		this.router = this.injector.get(Router);
		document.body.classList.add('bodybg');
	}

	showRecoverForm() {
		this.loginform = false;
		this.recoverform = true;
	}

	showLoginForm() {
		this.loginform = true;
		this.recoverform = false;
	}

	forgotPasswordSubmit($ev) {
		$ev.preventDefault();
		if (this.valFPForm.controls['email'].value !== '') {
			this.authService
				.forgotPasswordUser(this.valFPForm.controls['email'].value)
				.pipe(first())
				.subscribe(
					(data) => {
						if (data['RESULT_CODE'] === 1) {
							this.toastr.info('We have emailed you the link to reset your password!', 'Information');
						} else {
							this.toastr.error('Invalid email address', 'Error');
						}
					},
					(error) => {
						this.toastr.error('Invalid email address', 'Error');
					}
				);
		} else {
			this.valFPForm.controls['email'].markAsTouched();
		}
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}

		if (this.valForm.valid) {
			this.authService.login(value.username, value.password).subscribe(
				(data) => {
					console.log(data);
					if (data['RESULT_CODE'] === 1) {
						if (data && data['SESSIONID']) {
							this.dataService.WebAppData.isLoggedin = true;
							this.dataService.WebAppData.isAdmin = data['IsAdmin'] === 'Yes';
							this.dataService.WebAppData.roleMenus = JSON.parse(data['Role_Menus']);
							this.dataService.WebAppData.userId = data['ID'];

							delete data['Role_Menus'];

							localStorage.setItem('currentUser', JSON.stringify(data));
							localStorage.setItem('googleKey', data['GOOGLE_KEY']);
							this.authService.currentUserSubject.next(data);
							document.body.classList.remove('bodybg');
							this.checkSession();
						} else {
							this.toastr.error('Something went wrong.. try after sometime !!', 'Error');
						}
					} else {
						this.toastr.error('Incorrect username or password', 'Error');
					}
				},
				(error) => {
					this.toastr.error('Incorrect username or password', 'Error');
				}
			);
		}
	}

	checkSession(){
		this.authService.checkAppSession()
		.subscribe(
			(data) => {
				if (data['RESULT_CODE'] === 0) {
					Swal.fire('Session Error', "Oops! It seems that your browser settings are preventing the website from setting a cross-domain cookie. If you need assistance, please don't hesitate to reach out to our support team.", 'error');
				}
				else{
					this.router.navigate(['/app']);
				}
			}
		);
	}
}
