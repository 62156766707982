<div class="m-1 border border-primary" style="height:400px;overflow-y: auto;"
    [style.borderWidth.px]="content.filter_styles['border-width']"
    [style.borderStyle]="content.filter_styles['border-style']"
    [style.borderRadius.px]="content.filter_styles['border-radius']"
    [style.backgroundColor]="content.filter_styles['background-color']"
    [style.borderColor]="content.filter_styles['border-color']"
    *ngIf="content['Categories'] !== undefined">
    <div class="col-12 mb-2" [ngStyle]="getStyles(content.filter_header_styles)">Categories</div>
    <div class="col-12 text-left" style="float:left;" *ngFor="let category of content['Categories']">
        <input class="mr-1 text-left" type="checkbox" [(ngModel)]="category.Selected">
        <span class="ml-1 text-left" [ngStyle]="getStyles(content.filter_styles)">{{category.CATEGORY_NAME}}</span>
    </div>
</div>