import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AuthService, DataService } from '../services';
import { EnvService } from '../../environments/env.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
declare var require: any;
const Swal = require('sweetalert2');

@Component({
	selector: 'app-listing',
	templateUrl: './listing.component.html',
	styleUrls: ['./listing.component.scss'],
})
export class ListingComponent implements OnInit, AfterViewInit {
	@ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
	pageid: string;
	pageName: string;
	pageConfig: any = {};
	oprType: string;
	source: any = { localdata: null, totalrecords: 0 };
	dataAdapter: any;
	filter: string = '';
	pageno: number = 1;
	from: number = 0;
	limit: number = 10;
	cond: string = '';
	orderby: string = 'Order By Entry_Date DESC';
	loading: boolean = true;
	showfilter: boolean = true;
	showDropDown: boolean = false;
	counter: number = 1;
	columns: any = [];
	searchFields: any = [];
	gridFields: any = [];
	staticValues: any = {};
	filterValues: any = {};
	profile: any = {};
	configReady: boolean = false;
	window_width: any = 0;
	designType: string = 'DESKTOP';

	constructor(private environment: EnvService, private authService: AuthService, private dataService: DataService, public toastrService: ToastrService, public router: Router, private route: ActivatedRoute) {
		this.window_width = window.innerWidth;
		this.designType = 'DESKTOP';

		if (this.window_width < 700) {
			this.designType = 'MOBILE';
		} else if (this.window_width < 1025) {
			this.designType = 'TABLET';
		}

		this.route.queryParams.subscribe((params) => {
			this.pageid = params.id;
			this.getPageConfig(this.pageid);
		});
	}

	getPageConfig(id) {
		this.loading = true;
		
		this.dataService.getListingConfig(id).subscribe((res: any) => {
			this.pageConfig = res;
			this.pageName = this.pageConfig['Screen_Name'];
			this.searchFields = this.pageConfig['LISTING_CONFIGS'].filter((field) => field.Search === 1);
			this.searchFields.sort(this.GetSortOrder('Search_Sequence'));
			this.gridFields = this.pageConfig['LISTING_CONFIGS'].filter((field) => field.Show_Listing === 1);
			this.setGridConfig();
			this.initiateFilterValues();
			this.setStaticValues();
			this.configReady = true;
			this.loading = false;
			setTimeout(() => {
				this.getGridData();
			}, 1000);
		});
	}

	GetSortOrder(prop) {
		return function (a, b) {
			if (a[prop] > b[prop]) {
				return 1;
			} else if (a[prop] < b[prop]) {
				return -1;
			}
			return 0;
		};
	}

	ngOnInit() {}

	ngAfterViewInit(): void {
		if (this.configReady) {
			this.getGridData();
		}
	}

	initiateFilterValues() {
		this.searchFields.forEach((field) => {
			if (field.DataType === 'DATE' || field.DataType === 'DATETIME') {
				this.filterValues[field['Field_ID'] + '_From'] = null;
				this.filterValues[field['Field_ID'] + '_To'] = null;
			} else if ((field.DataType === 'INTEGER' || field.DataType === 'DECIMAL' || field.DataType === 'PERCENTAGE') && field.is_Static_Value === 0) {
				this.filterValues[field['Field_ID'] + '_From'] = null;
				this.filterValues[field['Field_ID'] + '_To'] = null;
			} else this.filterValues[field.Field_ID] = '';
		});
	}

	setStaticValues() {
		this.searchFields.forEach((field) => {
			if (field.is_Static_Value === 1 && field.Select_SQL !== '') {
				this.dataService.getSelectOptions(field['Select_SQL']).subscribe((result) => {
					if (result['RESULT_CODE'] === 1) {
						this.staticValues[field.Field_ID] = result['DATA'];
					} else {
						this.staticValues[field.Field_ID] = [];
					}
				});
			} else if (field.is_Static_Value === 1 && field.Static_Value_JSON !== '') {
				this.staticValues[field.Field_ID] = JSON.parse(field['Static_Value_JSON']);
			}
		});
	}

	setGridConfig() {
		let columns: any = [];
		let datafields: any = [];

		datafields.push({ name: 'ID', type: 'string' });
		datafields.push({ name: 'RECORD_STATUS', type: 'string' });

		columns.push({
			text: '',
			dataField: 'edit',
			width: 30,
			filtertype: 'none',
			cellclassname: '',
			filterable: false,
			sortable: false,
			menu: false,
			columntype: 'button',
			cellsrenderer: function () {
				return '<span class="fa fa-pencil-alt text-success" title="View"></span>';
			},
		});

		if (this.pageConfig['DeleteMode'] === '1') {
			columns.push({
				text: '',
				dataField: 'delete',
				width: 30,
				filtertype: 'none',
				cellclassname: 'danger',
				filterable: false,
				sortable: false,
				menu: false,
				columntype: 'button',
				cellsrenderer: function () {
					return '<span class="fa fa-trash" style="color:red;" title="Delete"></span>';
				},
			});
		}

		if (this.pageConfig['Report_ID'] !== '') {
			columns.push({
				text: '',
				dataField: 'report',
				width: 30,
				filtertype: 'none',
				cellclassname: 'primary',
				filterable: false,
				sortable: false,
				menu: false,
				columntype: 'button',
				cellsrenderer: function () {
					return '<span class="fa fa-print" style="color:blue;" title="Report"></span>';
				},
			});
		}
		var column: any = {};
		var colWidthMobile = '30%';
		if (this.designType === 'MOBILE'){
			if (this.gridFields.length === 1)
				colWidthMobile = '100%';
			else if (this.gridFields.length === 2)
				colWidthMobile = '50%';
			else if (this.gridFields.length === 3)
				colWidthMobile = '33%';
		}

		this.gridFields.forEach((field) => {
			if (field.DataType === 'DATE') {
				column = { text: field['Display_Name'], dataField: field['Field_Name'], cellsformat: 'dd/MM/yyyy' };
				datafields.push({ name: field['Field_Name'], type: 'date' });
			} else if (field.DataType === 'DATETIME') {
				column = { text: field['Display_Name'], dataField: field['Field_Name'], cellsformat: 'dd/MM/yyyy H:mm:ss' };
				datafields.push({ name: field['Field_Name'], type: 'date' });
			} else if (field.DataType === 'DECIMAL') {
				column = { text: field['Display_Name'], dataField: field['Field_Name'], align: 'right', cellsalign: 'right' };
				datafields.push({ name: field['Field_Name'], type: 'number' });
			} else if (field.DataType === 'INTEGER') {
				column = { text: field['Display_Name'], dataField: field['Field_Name'], align: 'right', cellsalign: 'right' };
				datafields.push({ name: field['Field_Name'], type: 'string' });
			} else if (field.DataType === 'BIT') {
				column = { text: field['Display_Name'], dataField: field['Field_Name'] };
				datafields.push({ name: field['Field_Name'], type: 'bool' });
			} else {
				column = { text: field['Display_Name'], dataField: field['Field_Name'] };
				datafields.push({ name: field['Field_Name'], type: 'string' });
			}

			if ((this.designType === 'DESKTOP' || this.designType === 'TABLET') && field.Column_Width !== undefined && field.Column_Width !== null && field.Column_Width !== 0){
				column['width'] = field.Column_Width + '%';
			}
			else if (this.designType === 'MOBILE'){
				column['width'] = colWidthMobile;
			}
			columns.push(column);
		});

		this.source = {
			localdata: null,
			datatype: 'json',
			datafields: datafields,
			totalrecords: 0,
			id: 'ID',
			sort: (column, direction) => {
				this.applySorting(column, direction);
			},
		};

		this.dataAdapter = new jqx.dataAdapter(this.source);
		this.columns = columns;
	}

	toggleFilter() {
		this.showfilter = !this.showfilter;
	}

	applyFilter() {
		this.cond = '';
		this.pageno = 1;

		this.searchFields.forEach((field) => {
			if (field['DataType'] === 'DATE' || field['DataType'] === 'DATETIME') {
				if (this.filterValues[field['Field_ID'] + '_From'] !== null && this.filterValues[field['Field_ID'] + '_From'] !== '') this.cond = this.cond + ' And `' + field['Field_Name'] + "` >= '" + this.filterValues[field['Field_ID'] + '_From'] + "'";
				if (this.filterValues[field['Field_ID'] + '_To'] !== null && this.filterValues[field['Field_ID'] + '_To'] !== '') this.cond = this.cond + ' And `' + field['Field_Name'] + "` <= '" + this.filterValues[field['Field_ID'] + '_To'] + "'";
			} else if ((field['DataType'] === 'INTEGER' || field['DataType'] === 'DECIMAL' || field['DataType'] === 'PERCENTAGE') && field.is_Static_Value === 0) {
				if (this.filterValues[field['Field_ID'] + '_From'] !== null && this.filterValues[field['Field_ID'] + '_From'] !== '') this.cond = this.cond + ' And `' + field['Field_Name'] + '` >= ' + this.filterValues[field['Field_ID'] + '_From'];
				if (this.filterValues[field['Field_ID'] + '_To'] !== null && this.filterValues[field['Field_ID'] + '_To'] !== '') this.cond = this.cond + ' And `' + field['Field_Name'] + '` <= ' + this.filterValues[field['Field_ID'] + '_To'];
			} else if (this.filterValues[field['Field_ID']] !== null && this.filterValues[field['Field_ID']] !== '' && field.is_Static_Value === 1) {
				this.cond = this.cond + ' And `' + field['Field_Name'] + "` = '" + this.filterValues[field['Field_ID']] + "'";
			} else if (this.filterValues[field['Field_ID']] !== null && this.filterValues[field['Field_ID']] !== '') {
				this.cond = this.cond + ' And `' + field['Field_Name'] + "` LIKE '%" + this.filterValues[field['Field_ID']] + "%'";
			}
		});

		this.myGrid.gotopage(0);

		this.getGridData();
	}

	applySorting(column, direction) {
		if (typeof direction === 'string') {
			this.orderby = ' Order By `' + column + '` ' + (direction == 'ascending' ? 'ASC' : 'DESC');
		} else {
			this.orderby = ' Order By `' + column + '` ' + (direction == true ? 'ASC' : 'DESC');
		}
		this.getGridData();
	}

	getGridData() {
		this.dataAdapter = new jqx.dataAdapter(this.source);
		this.myGrid.showloadelement();
		this.dataService.listingData(this.pageid, this.cond, this.filter, this.pageno - 1, this.limit, this.orderby).subscribe(
			(data) => {
				this.source.localdata = [];
				this.source.totalrecords = 0;
				if (data.RESULT_CODE === 1) {
					this.source.localdata = data;
					this.source.totalrecords = data.RECORD_COUNT;
				} else {
					this.toastrService.error('Your page is missing configuration, please verify the view settings for the datagrid. Contact support for assistance !!', 'Missing Configuration', { timeOut: 5000 });
				}
				this.myGrid.updatebounddata();
				this.myGrid.hideloadelement();
			},
			(error) => {
				this.myGrid.hideloadelement();
				this.toastrService.error('Error', 'Something went wrong, Please try after sometime !!', { timeOut: 5000 });
			}
		);
	}

	export(exportType) {
		if (exportType === 'excel' || exportType === 'csv') {
			this.gridFields
				.filter((gf) => gf.DataType === 'DATE' || gf.DataType === 'DATETIME')
				.forEach((field) => {
					if (field.DataType === 'DATE') {
						this.columns.forEach((column) => {
							if (column.dataField === field.Field_Name) {
								column['cellsformat'] = 'yyyy/MM/dd';
							}
						});
					} else if (field.DataType === 'DATETIME') {
						this.columns.forEach((column) => {
							if (column.dataField === field.Field_Name) {
								column['cellsformat'] = 'yyyy/MM/dd H:mm:ss';
							}
						});
					}
				});

			setTimeout(() => {
				this.getGridDataExport(exportType);
			}, 1000);
		} else {
			this.getGridDataExport(exportType);
		}
	}

	getGridDataExport(exportType) {
		if (this.pageConfig['DeleteMode'] === '1') {
			this.myGrid.hidecolumn('delete');
		}
		this.myGrid.hidecolumn('edit');

		this.myGrid.showloadelement();
		this.dataService.listingData(this.pageid, this.cond, this.filter, 0, 99999, this.orderby).subscribe(
			(data) => {
				this.source.localdata = [];
				this.source.totalrecords = 0;
				if (data.RESULT_CODE === 1) {
					this.source.localdata = data;
					this.source.totalrecords = data.RECORD_COUNT;
				} else {
					this.toastrService.error('Error', data.RESULT_MESSAGE, { timeOut: 3000 });
				}
				this.myGrid.updatebounddata();

				if (exportType === 'pdf') {
					let value = this.myGrid.exportdata('pdf', this.pageName);
				} else if (exportType === 'csv') {
					let value = this.myGrid.exportdata('csv', this.pageName, true, null, false, 'https://jwidget.humblecode.net/dataexport.php');
				} else {
					let value = this.myGrid.exportdata('xls', this.pageName, true, null, false, 'https://jwidget.humblecode.net/dataexport.php');
				}

				setTimeout(() => {
					if (this.pageConfig['DeleteMode'] === 1) {
						this.myGrid.showcolumn('delete');
					}

					this.myGrid.showcolumn('edit');

					this.gridFields
						.filter((gf) => gf.DataType === 'DATE' || gf.DataType === 'DATETIME')
						.forEach((field) => {
							if (field.DataType === 'DATE') {
								this.columns.forEach((column) => {
									if (column.dataField === field.Field_Name) {
										column['cellsformat'] = 'dd/MM/yyyy';
									}
								});
							} else if (field.DataType === 'DATETIME') {
								this.columns.forEach((column) => {
									if (column.dataField === field.Field_Name) {
										column['cellsformat'] = 'dd/MM/yyyy H:mm:ss';
									}
								});
							}
						});
					this.getGridData();
				}, 1500);

				this.myGrid.hideloadelement();
			},
			(error) => {
				this.myGrid.hideloadelement();
				this.toastrService.error('Error', 'Something went wrong, Please try after sometime !!', { timeOut: 3000 });
			}
		);
	}

	onPageChanged(event: any): void {
		let args = event.args;
		this.pageno = args.pagenum + 1;
		this.limit = args.pagesize;
		this.from = this.pageno * this.limit - this.limit;
		this.getGridData();
	}

	onPageSizeChanged(event: any): void {
		let args = event.args;
		this.pageno = args.pagenum + 1;
		this.limit = args.pagesize;
		this.from = this.pageno * this.limit - this.limit;
		this.getGridData();
	}

	onCellclick(event: any): void {
		const args = event.args;
		const dataField = args.datafield;

		if (dataField === 'edit') {
			this.navigate(args.row.bounddata);
		} else if (dataField === 'delete') {
			this.openDeleteDialog(args.row.bounddata.ID);
		} else if (dataField === 'report') {
			this.showReport(args.row.bounddata.ID);
		}
	}

	showReport(primaryId) {
		var url = "report_page?id=" + this.pageConfig['Report_ID'] + "&pkid=" + primaryId;
		window.open(url, '_blank');
	}

	rendergridrows = function (obj) {
		return obj.data;
	};

	navigate(data) {
		let primaryId = data['ID'];

		if (primaryId === '0' && this.pageConfig['NewMode'] === 0) alert('Sorry.. you dont have access to add!!');
		else {
			this.router.navigateByUrl('/app/page?pageid=' + this.pageid + '&id=' + primaryId);
		}
	}

	deleteRecord(ID) {
		this.loading = false;

		this.dataService.deleteRecord(this.pageid, ID).subscribe(
			(data) => {
				if (data.RESULT_CODE === 1) {
					Swal.fire('Deleted!', 'Record deleted successfully.', 'success');
					this.getGridData();
				} else {
					this.toastrService.error('Error', data.RESULT_MESSAGE, { timeOut: 3000 });
				}
			},
			(error) => {
				this.toastrService.error('Error', 'Something went wrong, Please try after sometime !!', { timeOut: 3000 });
			}
		);
	}

	openDeleteDialog(ID) {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Your will not be able to recover this record!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, cancel please!',
		}).then((isConfirm) => {
			if (isConfirm.value) {
				this.deleteRecord(ID);
			} else {
				Swal.fire('Cancelled', 'Operation has been cancelled', 'error');
			}
		});
	}
}
