import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { AuthService, BookingService } from '../../../../services';
import { EnvService } from '../../../../../environments/env.service';
import { PageFunctions } from '../../../directives/PageFunctions';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-bookingserviceinfo',
  templateUrl: './bookingserviceinfo.component.html',
  styleUrls: ['./bookingserviceinfo.component.scss']
})
export class BookingServiceinfoComponent implements OnInit {
  @Input() props: any;
  @Input() info: any;
  @Input() row:any;
  @Input() designPage: string;
  @Input() calendarid: string;
  
  WebsiteId: string = '';
  relevantitems: any = [];
  selectedVariant: any = {};
  selectedVariantId: string = '';
  cartCount: number = 1;
  addedToWishlist: boolean = false;
  appData: any = {};
  showAllCharges: boolean = false;

  constructor(public dataService: BookingService, public environment: EnvService, public router: Router, 
    public authService:AuthService, public pageFunctions: PageFunctions, private route: ActivatedRoute, 
    public dialog: MatDialog, private toastr: ToastrService) { 
    this.WebsiteId = environment.websiteId;
    this.appData = this.dataService.AppBookingData;
  }

  isMobile(){
		return window.innerWidth < 700;
	}

  ngOnInit(): void {

    if (this.props.template===undefined){
      this.props["template"]="1";
    }
    if (this.props.image_styles === undefined){
      this.props['image_styles'] = {
        "margin-right": 0,
        "margin-left": 0,
        "margin-top": 0,
        "margin-bottom": 0
      }
    }
    if (this.props['label_styles'] === undefined) {
      this.props['label_styles'] = {
        'color':'#495057',
        'background-color':'transparent',
        'font-family':'Roboto',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'margin-top':0,
        'margin-bottom':0,
        'margin-left':0,
        'margin-right':0
      };
    }
    if (this.props['input_styles'] === undefined) {
      this.props['input_styles'] = {
        'color':'#495057',
        'background-color':'#fff',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'border-top':0,
        'border-right':0,
        'border-bottom':0,
        'border-left':0,
        'border-style':'solid',
        'position':'',
        'border-color':'#000',
        'border-top-left-radius':0,
        'border-top-right-radius':0,
        'border-bottom-left-radius':0,
        'border-bottom-right-radius':0
      };
    }
    if (this.props['cart_styles'] === undefined) {
      this.props['cart_styles'] = {
        'color':'#fff',
        'background-color':'#5d9cec',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    this.props.ITEM = this.info;

    console.log(this.info);

    if (this.info.CALENDER !== undefined && this.info.CALENDER.length > 0){
      if (this.calendarid !== ""){
        var variants = this.info.CALENDER.filter(variant=>variant.ID===this.calendarid);
        if (variants.length > 0){
          this.selectedVariant = variants[0];
          this.selectedVariantId = this.selectedVariant.ID;
        }
      }
      if (this.selectedVariantId === ""){
        this.selectedVariant = this.info.CALENDER[0];
        this.selectedVariantId = this.selectedVariant.ID;
        console.log(this.selectedVariant);
      }
    }

    if (this.info.ADDITIONAL_CHARGES !== undefined && this.info.ADDITIONAL_CHARGES !== null 
      && this.info.ADDITIONAL_CHARGES !== "" && this.info.ADDITIONAL_CHARGES.length>0) {
      this.info.ADDITIONAL_CHARGES.forEach(charge => {
        if (charge['IS_MANDATORY']==='\u0001'){
          charge['IS_MANDATORY'] = true;
        }
        else{
          charge['IS_MANDATORY'] = false;
        }
        if (charge['IS_MANDATORY']===true){
          charge['Selected'] = true;
          charge['QTY']=1;
          charge['AMOUNT']=charge['SERVICE_CHARGE'];
        }
        else{
          charge['Selected'] = false;
          charge['QTY']=0;
          charge['AMOUNT']=0;
        }
      });
    }
    else{
      this.info.ADDITIONAL_CHARGES = [];
    }

    this.getRelevantItems();

  }

  onChange($event){
    this.selectedVariantId = $event.target.value;
    this.selectedVariant = this.info.CALENDER.filter(variant=> variant.ID===this.selectedVariantId)[0];
    console.log(this.selectedVariant);
  }

  getRelevantItems(){
    this.dataService.getCategoryServicesAll(this.WebsiteId, this.info.CATEGORY_ID)
    .subscribe((res : any)=>{
      this.relevantitems = res.DATA.filter(item=>item.ID !== this.info.ID);
    });
  }

  loadProductInfo(product){
    this.router.navigate([this.appData.ProductPage, { sid: product.ID }]);
  }

  getInputStyle(obj, ctrlType){
		var style = {};

		if (obj===undefined){
			obj = {
				'color':'#495057',
				'background-color':'#fff',
        'font-size':'16px',
				'font-weight':'normal',
				'font-style':'normal',
				'text-decoration':'normal',
				'border-top':0,
				'border-right':0,
				'border-bottom':0,
				'border-left':0,
				'border-style':'',
				'position':'',
				'border-color':'#495057',
				'border-top-left-radius':0,
				'border-top-right-radius':0,
				'border-bottom-left-radius':0,
				'border-bottom-right-radius':0
			};
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if(obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)' ){
			this.pageFunctions.setBorder(style,obj,true);
		}

		this.pageFunctions.serBorderRadius(style, obj);

		return style;
	}

  getStyles(obj,contentType) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setBorder(style,obj,true);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

   
		if(contentType === 'Button' || contentType === 'Text'){
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				var objWidth = obj['width'];
				if(contentType === 'Text'){
					if (obj['width-type'] !== null && obj['width-type'] !== undefined && obj['width-type'] !== "" && obj['width-type'] === 'px') {
					}else{
						objWidth = obj['width'] * 13.8;
						obj['width-type'] = 'px';
					}
				}
				style["width"] =  objWidth + "px";
				style['width-type'] = 'px';
			}
		}else if(contentType === 'Image'){
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				style["width"] = obj['width'] + "px";
			}
		}else{
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				style["width"] = obj['width'] + "%";
			}
		}
    this.pageFunctions.setMargin(style,obj);
    
    return style;
  }

  selectCharges(charge){
    charge['Selected']=!charge['Selected'];
    if (charge['Selected']){
      charge['QTY']=1;
      charge['AMOUNT']=charge['SERVICE_CHARGE'];
    }
  }

  onChargeQtyBlur(charge){
    if (charge['QTY']===null||charge['QTY']===isNaN||charge['QTY']===''||charge['QTY']<=0)
      charge['QTY']=1;
    charge['AMOUNT']=charge['QTY']*charge['SERVICE_CHARGE'];
  }

  addToCart(){
    if (this.appData.isLoggedin === true){
      this.dataService.checkBookingAllowed(this.WebsiteId, this.info.ID, this.selectedVariantId, "")
      .subscribe((res : any)=>{
        if (res.RESULT_CODE===1 && res.IS_BOOKING_ALLOWED===1){
          this.dataService.addToCart({
            "ID":this.info.ID, 
            "CALENDER_ID":this.selectedVariantId, 
            "TITLE": this.info.TITLE,
            "DESCRIPTION": this.info.DESCRIPTION,
            "CART_COUNT": this.cartCount,
            "AMOUNT": this.info.DISCOUNTED_AMOUNT,
            "IMAGE_URL": this.info.IMAGE_URL,
            "ADDITIONAL_SERVICES": JSON.stringify(this.info.ADDITIONAL_CHARGES)
          });
        }
        else{
          var msg = "Cannot Book, Service date should be greater than " + res['ALLOWED_DATE'];
          this.toastr.info(msg,'Information', { timeOut: 3000 });
        }
      });
    }
    else{
      this.gotoLogin();
    }
  }

  gotoLogin(){
    var loginUrl = this.dataService.AppBookingData.LoginPage;
    this.router.navigate([loginUrl]);
  }

  increaseRowHeight(){
    this.row.styles.height = $('#'+ this.info.ID)[0].scrollHeight + 10;
  }

}
