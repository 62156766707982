<form [formGroup]="registerForm" class="form-validate form-horizontal" role="form" name="registerForm" novalidate=""
    (submit)="submitRegForm($event, registerForm.value)" autocomplete="off">
    <div class="card card-default">
        <div class="card-header" [ngStyle]="getStyles(content.header_styles)">
            <label class="col-form-label" [style.textDecoration]="content.header_styles['text-decoration']">
                {{content.header_styles.value === undefined || content.header_styles.value ===
                '' ? 'Register to Continue': content.header_styles.value}}
            </label>
        </div>

        <div class="card-body" [ngStyle]="getStyles(content.styles)">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">First Name *</label>
                        <input type="text" name="firstName" placeholder="Enter first name"
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(registerForm.controls['firstName'].hasError('required') && (registerForm.controls['firstName'].dirty || registerForm.controls['firstName'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" autocomplete="off"
                            formControlName="firstName" required="" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">Last Name *</label>
                        <input type="text" name="lastName" placeholder="Enter last Name"
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(registerForm.controls['lastName'].hasError('required') && (registerForm.controls['lastName'].dirty || registerForm.controls['lastName'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" autocomplete="off"
                            formControlName="lastName" required="" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">Email *</label>
                        <input type="email" name="email" placeholder="Enter email"
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(registerForm.controls['email'].hasError('required') && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" autocomplete="off"
                            formControlName="email" required="" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">Mobile # *</label>
                        <input type="number" name="mobile" placeholder="Enter mobile"
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(registerForm.controls['mobile'].hasError('required') && (registerForm.controls['mobile'].dirty || registerForm.controls['mobile'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" autocomplete="off"
                            formControlName="mobile" required="" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">Password *</label>
                        <input type="password" name="password" placeholder="Enter password"
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(registerForm.controls['password'].hasError('required') && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" autocomplete="off"
                            formControlName="password" required="" />
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12" [style.textAlign]="content['btn_styles']['text-align']">
                    <button class="btn btn-block btn-primary mt-3" type="submit"
                        *ngIf="content['btn_styles']===undefined">Register</button>
                    <button class="mt-3" type="submit" [ngStyle]="getFormButtonStyle(content.btn_styles)"
                        [style.textDecoration]="content['btn_styles']['text-decoration']"
                        [style.fontStyle]="content['btn_styles']['font-style']"
                        [style.fontWeight]="content['btn_styles']['font-weight']"
                        [style.color]="content['btn_styles']['color']"
                        [style.backgroundColor]="content['btn_styles']['background-color']"
                        *ngIf="content['btn_styles']!==undefined">{{content.btn_styles.value === undefined ||
                        content.btn_styles.value ===
                        '' ? 'Register': content.btn_styles.value}}</button>
                </div>
            </div>
        </div>
    </div>
</form>