import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, BookingService } from '../../../../services';
import { EnvService } from '../../../../../environments/env.service';
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { PageFunctions } from '../../../directives/PageFunctions';
import { ExternalLibraryService } from '../../../directives/util';

declare var Razorpay: any;
//declare var bolt: any;

@Component({
  selector: 'app-bookingcheckout',
  templateUrl: './bookingcheckout.component.html',
  styleUrls: ['./bookingcheckout.component.scss']
})
export class BookingCheckoutComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  @Input() props: any;
  @Input() designPage: string;
  @Input() row: any;
  valForm: FormGroup;
  paymentOptions: any = {};
  cartItems: any = [];
  distinctCoupons: any = [];
  selectedCoupon: any = {};
  userData: any = {};
  showDeliveryPage: boolean = false;
  couponsAvailable: boolean = false;
  processing: boolean = false;
  couponDiscount: any = 0;
  selectedPayment: string = "";
  paymentResponse: any;
  website_logo: string = "";
  gateways: any = [];  
  appData: any = {};
  window_width: any = 0;

  constructor(public router: Router, public environment:EnvService, public authService:AuthService, 
    public dataService:BookingService, public dialog: MatDialog, fb: FormBuilder, public extService: ExternalLibraryService,
    public pageFunctions: PageFunctions, public snackBar: MatSnackBar, private toastr: ToastrService) {

    this.window_width = window.innerWidth;
    
    this.valForm = fb.group({
      'firstName': [null, Validators.compose([Validators.required])],
      'lastName': [null, Validators.compose([Validators.required])],
      'mobile': [null, Validators.compose([Validators.required])],
      'email': [''],
      'address1': [null, Validators.compose([Validators.required])],
      'address2': [null, Validators.compose([Validators.required])],
      'city': [null, Validators.compose([Validators.required])],
      'state': [null, Validators.compose([Validators.required])],
      'country': [''],
      'pincode': [null, Validators.compose([Validators.required])],
      'mvolaDebitNo':['']
    });

    this.appData = this.dataService.AppBookingData;
    this.getGateways();
    this.setDeliveryInfo();
  }

  getGateways(){
    this.dataService.getGateways().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.gateways = res['PG'];
      }
    });
  }

  getCheckoutProductsInfo(){
    this.dataService.getCartlist().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        var cartItems = res.DATA;
        cartItems.forEach(item=>{
          item['showAll']=false;
          if (item['ADDITIONAL_SERVICES'] !== undefined && item['ADDITIONAL_SERVICES']!== null && item['ADDITIONAL_SERVICES']!==""){
            item['ADDITIONAL_SERVICES'] = JSON.parse(item['ADDITIONAL_SERVICES']);
            this.getAdditionalCharges(item);
          }
          else{
            item['ADDITIONAL_SERVICES'] = [];
            item['ADDITIONAL_SERVICE_AMOUNT'] = 0;
          }
        });
        this.cartItems = cartItems;
        this.getPaymentOptions();
      }
    });
  }

  onChargeQtyBlur(item){
    this.getAdditionalCharges(item);
  }

  selectCharges(item,charge){
    charge['Selected'] = !charge['Selected'];
    this.getAdditionalCharges(item);
  }

  getAdditionalCharges(item){
    var total = 0;
    item['ADDITIONAL_SERVICES'].forEach(item=>{
      if (item['Selected']===true){
        if (item['QTY']===null||item['QTY']===isNaN||item['QTY']<=0)
          item['QTY']=1;
        item['AMOUNT'] = item['QTY'] * item['SERVICE_CHARGE'];
        total = total + item['AMOUNT'];
      }
      else{
        item['QTY']=0;
        item['AMOUNT'] = 0;
      }
    });
    item['ADDITIONAL_SERVICE_AMOUNT'] = total;
  }

  getPaymentOptions(){
    this.dataService.getPaymentOptions().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.paymentOptions = res;
        var country = this.gateways.filter(pg=>pg.CODE===res['COUNTRY']);
        if (country.length > 0){
          if (!country[0]['PG'].includes("Stripe")){
            this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("RazorPay")){
            this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("PayPal")){
            this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("MVola")){
            this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Airtel")){
            this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Orange")){
            this.paymentOptions['IS_ORANGE_ACTIVE']='0';
          }
        }
        else{
          this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          this.paymentOptions['IS_ORANGE_ACTIVE']='0';
        }        
      }
    });
  }

  setPaymentOption(option){
    this.selectedPayment = option;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {

    if (this.props.template === undefined){
      this.props["template"]="1";
    }
    if (this.props.table_styles === undefined){
      this.props['table_styles'] = {
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0
      }
    }
    if (this.props.header_styles === undefined){
      this.props['header_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props.body_styles === undefined){
      this.props['body_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props.footer_styles === undefined){
      this.props['footer_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props['checkout_styles'] === undefined) {
      this.props['checkout_styles'] = {
        'color':'#fff',
        'background-color':'#21a240',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    if (this.props['shopping_styles'] === undefined) {
      this.props['shopping_styles'] = {
        'color':'#fff',
        'background-color':'#036b80',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    if (this.props['coupon_styles'] === undefined) {
      this.props['coupon_styles'] = {
        'color':'#fff',
        'background-color':'#f05050',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    this.row.styles.height="100%";

    this.extService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js', false)
    .subscribe();

    //this.extService
    //.lazyLoadLibrary('https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js', true)
    //.subscribe();    
  }

  onQtyBlur(item){
    if (item.CART_COUNT === null || item.CART_COUNT === 0){
      item.CART_COUNT = 1;
    }
    //this.getCouponDiscount();
  }

  gotoHome(){
    var homeUrl = this.environment.homePage;
    this.router.navigate([homeUrl]);
  }

  gotoLogin(){
    var loginUrl = this.dataService.AppBookingData.LoginPage;
    this.router.navigate([loginUrl]);
  }

  deleteCartItem(product){
    this.dataService.deleteFromCart(product);
    this.cartItems = this.cartItems.filter(item=>item.CALENDER_ID !== product.CALENDER_ID);
    //this.getDistinctCoupons();
    //this.getCouponDiscount();
  }

  getItemTotal(){
    var total = 0;
    this.cartItems.forEach(item => {
      if (item.ALLOW_BOOKING === '1'){
        total = total + ((item.AMOUNT) * item.CART_COUNT);
      }
      else if (item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '1'){
        item.AMOUNT = item.LC_AMOUNT;
        total = total + ((item.LC_AMOUNT) * item.CART_COUNT);
      }
    });
    return total;
  }

  getTotal(){
    var total = 0;
    this.cartItems.forEach(item => {
      if (item.ALLOW_BOOKING === '1'){
        total = total + ((item.AMOUNT + item['ADDITIONAL_SERVICE_AMOUNT']) * item.CART_COUNT);
      }
      else if (item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '1'){
        item.AMOUNT = item.LC_AMOUNT;
        total = total + ((item.LC_AMOUNT + item['ADDITIONAL_SERVICE_AMOUNT']) * item.CART_COUNT);
      }
    });
    return total;
  }

  getStyles(obj) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setPadding(style, obj);

    this.pageFunctions.setBorder(style,obj,true);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

    if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
      style["width"] = obj['width'] + "%";
    }

    if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== ""){
      style['margin-left'] = (parseInt(obj['margin-left'].replace('px',''))) + "px";
    }
   
    if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== ""){
     style['margin-top'] = obj['margin-top'];
    }
    
    return style;
  }

  continueCheckout(){
    var isvalid: boolean = true;
    this.cartItems.forEach(item => {
      if (item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '0'){
        isvalid = false;
      }
    });
    if (isvalid){
        this.showDeliveryPage = true;
    }
    else{
      this.toastr.info('Please remove unavailable bookings','Cannot proceed');
    }
  }

  setDeliveryInfo(){
    this.dataService.getCustomerProfile().subscribe(res=>{
      if (res.RESULT_CODE===1){
        delete res.RESULT_CODE;
        this.userData = res;  
        this.valForm.controls['firstName'].setValue(this.userData.FIRST_NAME);
        this.valForm.controls['lastName'].setValue(this.userData.LAST_NAME);
        this.valForm.controls['mobile'].setValue(this.userData.MOBILE);
        this.valForm.controls['email'].setValue(this.userData.EMAIL);
        this.valForm.controls['address1'].setValue(this.userData.ADDRESS1);
        this.valForm.controls['address2'].setValue(this.userData.ADDRESS2);
        this.valForm.controls['city'].setValue(this.userData.CITY);
        this.valForm.controls['state'].setValue(this.userData.STATE);
        if (this.userData.COUNTRY !== null)
          this.valForm.controls['country'].setValue(this.userData.COUNTRY);
        this.valForm.controls['pincode'].setValue(this.userData.PINCODE);

        this.getCheckoutProductsInfo();
      }
      else{
        this.authService.customerlogout();
        this.authService.clearCustomerSession();
        this.gotoLogin();
      }
    });  
    // if (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== ""){
    //   this.userData = JSON.parse(localStorage.getItem('userData'))
    //   this.valForm.controls['firstName'].setValue(this.userData.FIRST_NAME);
    //   this.valForm.controls['lastName'].setValue(this.userData.LAST_NAME);
    //   this.valForm.controls['mobile'].setValue(this.userData.MOBILE);
    //   this.valForm.controls['email'].setValue(this.userData.EMAIL);
    //   this.valForm.controls['address1'].setValue(this.userData.ADDRESS1);
    //   this.valForm.controls['address2'].setValue(this.userData.ADDRESS2);
    //   this.valForm.controls['city'].setValue(this.userData.CITY);
    //   this.valForm.controls['state'].setValue(this.userData.STATE);
    //   if (this.userData.COUNTRY !== null)
    //     this.valForm.controls['country'].setValue(this.userData.COUNTRY);
    //   this.valForm.controls['pincode'].setValue(this.userData.PINCODE);
    // }
  }

  makePayment($event){
    $event.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {

      let value = this.valForm.value;
      let couponId = "";
      let cartItems = this.cartItems.filter(item=>item.ALLOW_BOOKING === '1' || item.ALLOW_BOOKING_LC === '1');
      let itemTotal = this.getItemTotal();
      let orderAmt = this.getTotal();
      let currency = this.dataService.AppBookingData.CurrencyCode;
      let successUrl = this.dataService.AppBookingData.SuccessPage;
      let failureUrl = this.dataService.AppBookingData.FailurePage;
      let mvola_debit_number = "0";
      
      if (orderAmt > 0 && this.selectedPayment !== "" && successUrl !== "" && successUrl !== null && mvola_debit_number !== ""){
        this.processing = true;

        this.dataService.createOrder(value.firstName, value.lastName, value.mobile, value.email, 
          value.address1, value.address2, value.city, value.state, value.country, value.pincode, 
          currency, mvola_debit_number, couponId, itemTotal, this.couponDiscount, 
          orderAmt, cartItems, this.selectedPayment, successUrl, failureUrl)
        .subscribe(data => {

          this.processing = false;

          if(data['RESULT_CODE'] === 1){
            this.website_logo = data['SITE_LOGO'];
            data['ORDER_AMT'] = orderAmt;
            if (this.selectedPayment === 'Stripe'){
              if (data['checkout_url'] !== undefined && data['checkout_url'] !== null && data['checkout_url'] !== ""){
                document.location.href = data['checkout_url'];
              }
              else{
                if (data['message'] !== undefined && data['message'] !== null && data['message'] !== ""){
                  this.toastr.error(data['message'],'Error');
                }
                else{
                  this.toastr.error('Something went wrong.. try after sometime !!','Error');
                }
              }
            }
            else if (this.selectedPayment === 'PayPal'){
              if (data['checkout_url'] !== undefined && data['checkout_url'] !== null && data['checkout_url'] !== ""){
                document.location.href = data['checkout_url'];
              }
              else{
                  this.toastr.error('Something went wrong.. try after sometime !!','Error');
              }
            }
            else if (this.selectedPayment === 'RazorPay'){
              var rzp1 = new Razorpay(this.preparePaymentDetails(data, value, orderAmt));
              rzp1.open(); 
            }    
            // else if (this.selectedPayment === 'PayU'){
            //   this.onPayUMoneyPayment(data, value, orderAmt);
            // }                    
            else{
              this.dataService.AppBookingData.BOOKING_CART_LIST=[];
              this.dataService.clearCart();
              this.router.navigate([successUrl]);
            }
          }
          else if(data['RESULT_CODE'] === 2){
            this.toastr.info('Sorry, Unable to process the booking now !!','Information');
          }
          else
          {
            this.toastr.error('Something went wrong.. try after sometime !!','Error');
          }
        },
        error => {
          this.processing = false;
          this.toastr.error('Something went wrong.. try after sometime !!','Error');
        });
      }
      else{
        this.processing = false;
        if (orderAmt === 0){
          this.toastr.info('Order Amount should be greater than zero.. cannot process order','Information');
        }
        else if (this.selectedPayment === ""){
          this.toastr.info('Payment Method not selected.. cannot process order','Information');
        }
        else if (successUrl === "" || successUrl === null){
          this.toastr.info('Checkout Setting not completed.. cannot process order','Information');
        }
      }
    }

  }

  preparePaymentDetails(order, checkoutinfo, orderAmt){
    var ref = this;
    return  {
      "key": this.paymentOptions.RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      "amount": orderAmt * 100, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      "name": this.environment.websiteTitle,
      "currency": 'INR',
      "order_id": order.RAZOR_ORDER_ID,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
      "image": this.website_logo,
      "modal": {
        escape: false,
      },
      "handler": function (response){
        ref.handlePayment(response,'RazorPay',checkoutinfo, order);
      },
      "modal.ondismiss": function(){
        ref.dismissedPayment();
      },
      "prefill": {
          "name": checkoutinfo.firstName + ' ' + checkoutinfo.lastName,
          "email": checkoutinfo.email,
          "contact": checkoutinfo.mobile
      },
      "theme": {
          "color": "#F28500"
      }
    };
  }

  handlePayment(response, paymentGateway, checkoutInfo, orderInfo) {
    var paymentId: string = '';
    var respSignature: string = '';
    var respStatus: string = '';

    if (paymentGateway==='RazorPay'){
      paymentId = response.razorpay_payment_id;
      respSignature = response.razorpay_signature;
      respStatus = 'success';
    }
    // else{
    //   paymentGateway = 'PayU';
    //   paymentId = response.payuMoneyId;
    //   respSignature = response.bank_ref_num + '|' + response.hash;
    //   respStatus = response.txnStatus;
    // }
    this.dataService.verifyPayment(paymentGateway, orderInfo.ORDER_ID, orderInfo.ORDER_NO, orderInfo.ORDER_AMT,
      paymentId, respSignature, checkoutInfo.mobile, checkoutInfo.email, JSON.stringify(response)
    ).subscribe(res => {
      if (res['status'].toLowerCase() === 'success' ){
        this.dataService.AppBookingData.BOOKING_CART_LIST=[];
        this.dataService.clearCart();
        document.location.href = orderInfo['success_url'];
      }
      else{
        this.toastr.error('Order Payment failed.... cannot process order','Error');
      }
    },
    error => {
      this.paymentResponse = error;
    });
  }

  dismissedPayment(){}

}
