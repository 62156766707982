<div class="wrapper login-body">
    <div class="login-container">
        <div class="row w-100 h-100 m-0">
            <div class="col-md-6 col=lg-6 bg-login-left">
                <img src="/assets/images/leftbg.png" width="400px" />
            </div>  
            <div class="col-md-6 bg-white">
                <div class="text-container">
                    <img src="/assets/images/logod.png" height="100px" alt="logo" />
                    <div class="card" *ngIf="loginform">
                        <div class="card-header">
                            <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                    </div>
                                    <input class="form-control" id="exampleInputEmail1" type="text" name="username" placeholder="Enter username" autocomplete="off" formControlName="username" required="" />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                    </div>
                                    <input class="form-control" id="exampleInputPassword1" type="password" name="password" placeholder="Password" formControlName="password" required="" />
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <div class="custom-control custom-checkbox d-flex">
                                            <input type="checkbox" class="custom-control-input" id="customCheck1">
                                            <label class="custom-control-label font-14" for="customCheck1">Remember me</label>
                                            <a (click)="showRecoverForm()" href="javascript:void(0)" id="to-recover" class="text-dark font-14 ml-auto"><i class="fa fa-lock m-r-5"></i> Forgot pwd?</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <div class="col-xs-12 p-b-20">
                                        <button class="btn btn-block btn-lg btn-info" type="submit">Login</button>
                                    </div>
                                </div> 
                            </form>
                        </div>
                    </div>
                    <div class="card" *ngIf="recoverform">
                        <div class="card-header">
                            <form [formGroup]="valFPForm" class="form-validate mb-3" role="form" name="fpForm" novalidate="" (submit)="forgotPasswordSubmit($event)">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"><i class="ti-email"></i></span>
                                    </div>
                                    <input class="form-control" id="exampleInputEmail1" type="text" name="email" placeholder="Enter email" autocomplete="off" formControlName="email" required="" />
                                </div>
                                
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <div class="custom-control custom-checkbox d-flex">
                                            <a (click)="showLoginForm()" href="javascript:void(0)" id="to-recover" class="text-dark font-14 ml-auto"><i class="fa fa-sign-in-alt m-r-5"></i> Show Login</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <div class="col-xs-12 p-b-20">
                                        <button class="btn btn-block btn-lg btn-info" type="submit">Reset</button>
                                    </div>
                                </div> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end of row -->
        
    </div> <!-- end of container -->
</div>
<div toastContainer></div>
