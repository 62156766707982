import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { BookingService } from '../../../../services/booking.service';
import { PageFunctions } from '../../../directives/PageFunctions';

@Component({
	selector: 'app-bookinglisting',
	templateUrl: './bookinglisting.component.html',
	styleUrls: ['./bookinglisting.component.scss']
})
export class BookingListingComponent implements OnInit {

	@Input() content: any;
	@Input() row: any;
	window_width: any = 0;
	appData: any = {};
  
	constructor(public pageFunctions: PageFunctions, public bookingService: BookingService, public router: Router) { 
	  this.window_width = window.innerWidth;
	  this.appData = this.bookingService.AppBookingData;
	}
  
	ngOnInit(): void {
		this.appData = this.bookingService.AppBookingData;

		if (this.content.template === undefined){
			this.content["template"]="1";
		}
		if (this.content.IMAGE_URL === undefined){
			this.content["IMAGE_URL"]="/assets/img/view_more.png";
		}
		
		if (this.content.image_styles === undefined) {
			this.content["image_styles"] = {
				"margin-left":"0",
				"margin-right":"0",
				"margin-top":"0",
				"margin-bottom":"0",
			};
		}
		if (this.content.styles["margin-left"] === undefined) {
			this.content.styles["margin-left"] = 0;
			this.content.styles["margin-right"] = 0;
			this.content.styles["margin-top"] = 0;
			this.content.styles["margin-bottom"] = 0;
		}
		if (this.content.shadow === undefined){
			this.content['shadow'] = {
				"styles":{}
			};
		}
		if (this.content.price_styles === undefined){
			this.content['price_styles'] = {
				"text-align": "center",
				"background-color": "transparent",
				"color": "#000",
				"padding-right": 0,
				"padding-left": 0,
				"padding-top": 0,
				"padding-bottom": 0,
				"border-style": "solid",
				"border-width": 0,
				"border-color": "transparent",
				"border-radius": 0,
				'font-family': 'Roboto',
				"font-size": "16px",
				"font-weight": "normal",
				"font-style": "normal",
				"text-decoration": ""
			}
		}
		if (this.content.qty_styles === undefined){
			this.content['qty_styles'] = {
				"text-align": "center",
				"background-color": "transparent",
				"color": "#000",
				"padding-right": 0,
				"padding-left": 0,
				"padding-top": 0,
				"padding-bottom": 0,
				"border-style": "solid",
				"border-width": 0,
				"border-color": "transparent",
				"border-radius": 0,
				'font-family': 'Roboto',
				"font-size": "16px",
				"font-weight": "normal",
				"font-style": "normal",
				"text-decoration": "",
				"margin-left":"0",
				"margin-right":"0",
				"margin-top":"0",
				"margin-bottom":"0",
			}
		}
		if (this.content.button_styles === undefined) {
			this.content['button_styles'] = {
			'color':'#fff',
			'background-color':'#5d9cec',
			"font-size": "16px",
			'font-weight':'normal',
			'font-style':'normal',
			'text-decoration':'normal',
			'padding-top':10,
			'padding-right':10,
			'padding-bottom':10,
			'padding-left':10,
			'border-width':0,
			'border-style':'',
			'border-color':'',
			'border-radius':0          
			};
		}
		if (this.content.show_styles === undefined) {
			this.content['show_styles'] = {
			'color':'#fff',
			'background-color':'#5d9cec',
			"font-size": "16px",
			'font-weight':'normal',
			'font-style':'normal',
			'text-decoration':'normal',
			'margin-top':0,
			'margin-right':0,
			'margin-bottom':0,
			'margin-left':0,
			'padding-top':10,
			'padding-right':10,
			'padding-bottom':10,
			'padding-left':10,
			'border-width':0,
			'border-style':'',
			'border-color':'',
			'border-radius':0          
			};
		}
		if (this.content.product_styles === undefined){
			this.content['product_styles'] = JSON.parse(JSON.stringify(this.content['styles']));
		}
		if (this.content.slide_styles === undefined){
			this.content['slide_styles'] = JSON.parse(JSON.stringify(this.content['styles']));
		}
	}
  
	showAllProducts(content) {
		this.router.navigate([content["url"], { cid: content["categoryId"] }]);
	}

	showProduct(item) {
		this.router.navigate([item.url, { sid: item.productId }]);
	}

	getFormButtonStyle(obj){
		var style: any = {};

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style["padding-top"] = obj['padding-top'] + "px ";
		}
		if (obj['padding-right'] !== null && obj['padding-right'] !== undefined) {
			style["padding-right"] = obj['padding-right'] + "px ";
		}
		if (obj['padding-bottom'] !== null && obj['padding-bottom'] !== undefined) {
			style["padding-bottom"] = obj['padding-bottom'] + "px ";
		}
		if (obj['padding-left'] !== null && obj['padding-left'] !== undefined) {
			style["padding-left"] = obj['padding-left'] + "px";
		}

		if (obj['border-width'] !== null && obj['border-width'] !== undefined) {
			if (obj['border-style'] !== null && obj['border-style'] !== undefined && obj['border-style'] !== "") {
				if (obj['border-color'] !== null && obj['border-color'] !== undefined && obj['border-color'] !== "")
					style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		return style;
	}

	getStylesMain(obj,objShadow){
		var style = {};
		style = this.getStyles(obj);
		this.pageFunctions.setShadow(style, objShadow);
		return style;
	}

	getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
				style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined){
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
			style["justify-content"] = obj['justify-content'];
		}
	
		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			style["background-color"] = obj['background-color'];
		}

		this.pageFunctions.setBorder(style,obj,true);

		this.pageFunctions.serBorderRadius(style, obj);
		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		if (obj['color'] !== null && obj['color'] !== undefined){
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
		
		if (obj['font-size'] !== null && obj['font-size'] !== undefined){
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined){
			style["line-height"] = obj['line-height'];
		}

		this.pageFunctions.setMargin(style,obj);
		
		return style;
	}

	gotoLogin(){
		var loginUrl = this.appData.LoginPage;
		this.router.navigate([loginUrl]);
	}

	addToCart(item){
		this.bookingService.addToCart({
			"ID":item.productId, 
			"CALENDER_ID":item.calenderId, 
			"PRODUCT_NAME": item.productName,
			"PRODUCT_DESCRIPTION": item.productDescription,
			"CART_COUNT": item.qty,
			"DURATION": item.duration,
			"PRICE": item.discount_Price,
			"IMAGE_URL": item.imageUrl
		});
	}

}
