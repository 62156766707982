<form [formGroup]="valForm" class="form-validate form-horizontal" role="form" name="profileForm" novalidate=""
    autocomplete="off">
    <div class="card card-default mb-2">
        <div class="card-header" [ngStyle]="getStyles(props.header_styles)">
            <label class="col-form-label" [style.textDecoration]="props.header_styles['text-decoration']">
                {{props.header_styles.value === undefined || props.header_styles.value ===
                '' ? 'Account Information': props.header_styles.value}}
            </label>
        </div>
        <div class="card-body mb-2" [ngStyle]="getStyles(props.styles)">
            <div class="row mb-2">
                <div class="col-6 ">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">First Name *</label>
                        <input type="text" name="firstName" placeholder="Enter first name"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['firstName'].hasError('required') && (valForm.controls['firstName'].dirty || valForm.controls['firstName'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="firstName" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">Last Name *</label>
                        <input type="text" name="lastName" placeholder="Enter last Name"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['lastName'].hasError('required') && (valForm.controls['lastName'].dirty || valForm.controls['lastName'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="lastName" required="" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">Address 1 *</label>
                        <input type="text" name="address1" placeholder="Enter address1"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['address1'].hasError('required') && (valForm.controls['address1'].dirty || valForm.controls['address1'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="address1" required="" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">Address 2 *</label>
                        <input type="text" name="address2" placeholder="Enter address2"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['address2'].hasError('required') && (valForm.controls['address2'].dirty || valForm.controls['address2'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="address2" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">City *</label>
                        <input type="text" name="city" placeholder="Enter city"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['city'].hasError('required') && (valForm.controls['city'].dirty || valForm.controls['city'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="city" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">State *</label>
                        <input type="text" name="state" placeholder="Enter state"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['state'].hasError('required') && (valForm.controls['state'].dirty || valForm.controls['state'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="state" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">Postal code *</label>
                        <input type="number" name="pincode" placeholder="Enter postal code"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['pincode'].hasError('required') && (valForm.controls['pincode'].dirty || valForm.controls['pincode'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="pincode" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="props.styles['text-decoration']">Mobile # *</label>
                        <input type="number" name="mobile" placeholder="Enter mobile"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(valForm.controls['mobile'].hasError('required') && (valForm.controls['mobile'].dirty || valForm.controls['mobile'].touched))}"
                            [style]="getInputStyle(props,props['input_styles'])" autocomplete="off"
                            formControlName="mobile" required="" />
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12" [style.textAlign]="props['btn_styles']['text-align']">
                    <button class="btn btn-sm btn-success mt-3" (click)="submitForm($event)"
                        *ngIf="props['btn_styles']===undefined">{{props.btn_styles.value === undefined ||
                        props.btn_styles.value ===
                        '' ? 'Save': props.btn_styles.value}}</button>
                    <button class="mt-3" (click)="submitForm($event)" [ngStyle]="getFormButtonStyle(props.btn_styles)"
                        [style.textDecoration]="props['btn_styles']['text-decoration']"
                        [style.fontStyle]="props['btn_styles']['font-style']"
                        [style.fontWeight]="props['btn_styles']['font-weight']"
                        [style.color]="props['btn_styles']['color']"
                        [style.backgroundColor]="props['btn_styles']['background-color']"
                        *ngIf="props['btn_styles']!==undefined">{{props.btn_styles.value === undefined ||
                        props.btn_styles.value ===
                        '' ? 'Save': props.btn_styles.value}}</button>
                </div>
            </div>
        </div>
    </div>
</form>

<form [formGroup]="pwdForm" class="form-validate form-horizontal" role="form" name="passwordForm" novalidate="">
    <div class="card card-default">
        <div class="card-header" [ngStyle]="getStyles(props.header_styles)">
            <label class="col-form-label">{{props.header_styles.change_password_text === undefined || props.header_styles.change_password_text ===
                '' ? 'Change Password': props.header_styles.change_password_text}}</label>
        </div>
        <div class="card-body" [ngStyle]="getStyles(props.styles)">
            <div class="row mb-2">
                <div class="col-6 ">
                    <div class="form-group mb-1">
                        <label class="col-form-label">New Password *</label>
                        <input type="password" name="newPassword" placeholder="Enter new password"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(
                                (pwdForm.controls['newPassword'].hasError('required') && (pwdForm.controls['newPassword'].dirty || pwdForm.controls['newPassword'].touched))
                                ||(pwdForm.controls['newPassword'].hasError('minlength') && (pwdForm.controls['newPassword'].dirty || pwdForm.controls['newPassword'].touched))
                            )}" [style]="getInputStyle(props,props['input_styles'])" minlength="8" maxlength="12"
                            autocomplete="off" formControlName="newPassword" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label">Confirm Password *</label>
                        <input type="password" name="confirmPassword" placeholder="Enter confirm password"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(
                            (pwdForm.controls['confirmPassword'].hasError('required') && (pwdForm.controls['confirmPassword'].dirty || pwdForm.controls['confirmPassword'].touched)
                            ||(pwdForm.controls['confirmPassword'].hasError('mismatchedPasswords') && (pwdForm.controls['confirmPassword'].dirty || pwdForm.controls['confirmPassword'].touched)))
                            )}" [style]="getInputStyle(props,props['input_styles'])" autocomplete="off" maxlength="12"
                            formControlName="confirmPassword" required="" />
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12" [style.textAlign]="props['btn_styles']['text-align']">
                    <button class="btn btn-sm btn-success mt-3" (click)="submitPwdForm($event)"
                        *ngIf="props['btn_styles']===undefined">Submit</button>
                    <button class="mt-3" (click)="submitPwdForm($event)"
                        [ngStyle]="getFormButtonStyle(props.btn_styles)"
                        [style.textDecoration]="props['btn_styles']['text-decoration']"
                        [style.fontStyle]="props['btn_styles']['font-style']"
                        [style.fontWeight]="props['btn_styles']['font-weight']"
                        [style.color]="props['btn_styles']['color']"
                        [style.backgroundColor]="props['btn_styles']['background-color']"
                        *ngIf="props['btn_styles']!==undefined">Submit</button>
                </div>
            </div>
        </div>
    </div>
</form>