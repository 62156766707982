<div class="table-responsive" [style.height.px]="props.styles.height"
    style="overflow-y:auto;">
    <table id="dashboardtbl" class="table table-bordered" [ngStyle]="getStyles(props.table_styles)">
        <thead [ngStyle]="getStyles(props.header_styles)">
            <tr>
                <th [style]="'width:'+column.Width+'%;font-weight:bold;'" 
                    *ngFor="let column of getDataTableColumns(reportData[props.id]['columns'])">
                    {{column.Display_Name}}</th>
            </tr>
        </thead>
        <tbody [ngStyle]="getStyles(props.body_styles)">
            <tr *ngFor="let row of tableData">
                <td [style]="'width:'+column.Width+'%;white-space:pre;'" 
                    *ngFor="let column of getDataTableColumns(reportData[props.id]['columns'])">
                    <div [innerHTML]="replaceLineBreaks(row[column.Alias])"></div>
                </td>
            </tr>
        </tbody>
        <tfoot [ngStyle]="getStyles(props.footer_styles)" *ngIf="!isExporting">
            <tr>
                <th colspan="100%">
                    <div style="display:flex;" [ngStyle]="getStyles(props.footer_styles)">
                        <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="reportData[props.id]['rows'].length"
                            [itemsPerPage]="10" [maxSize]="5" (pageChanged)="pageChanged($event)"
                            [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                        </pagination>
                        <button class="btn btn-info" style="margin-left:5px;height:40px;font-size:inherit;" 
                        *ngIf="this.tableData.length > 0" (click)="exportExcel()">Export</button>
                    </div>
                </th>
            </tr>
        </tfoot>
    </table>
</div>