import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CMSService } from '../../../services';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
	selector: 'app-site-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class SiteFaqComponent implements OnInit {
	@Input() siteId: string;
	@Input() content: any;

	categories: any = [];
	categoryId: string = '';
	faqs: any = [];

	constructor(public pageFunctions: PageFunctions, public dataService: CMSService, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		if (this.content['CATEGORY_ID'] !== '') {
			this.categoryId = this.content['CATEGORY_ID'];

			this.categories = [{ ID: this.categoryId, CATEGORY: '', FAQ: [] }];

			this.getFAQs();
		} else {
			this.getCategories();
		}
		if (this.content.faq_styles === undefined) {
			this.content['faq_styles'] = {
				categorycolor: '#000',
				'font-family': 'Roboto',
				'font-weight': 'normal',
				'font-size': '16px',
				'text-align': 'left',
				'text-decoration': '',
				'font-style': 'normal',
				panelbgcolor: '#fff',
				'border-style': 'solid',
				'border-width': 0,
				'border-color': 'transparent',
				'border-radius': 0,
				icon_color: '#fff',
				'icon-size': '20px',
				icon_name: 'fas fa-plus-circle',
				icon_name_pause: 'fas fa-minus-circle',
				'icon-padding-top': 10,
				'icon-padding-left': 10,
				'icon-padding-bottom': 10,
				'icon-padding-right': 1,
			};
		}
		if (this.content.faq_styles['icon_name_pause'] === undefined || this.content.faq_styles['icon_name_pause'] === '') {
			this.content.faq_styles['icon-size'] = '20px';
			this.content.faq_styles['icon_color'] = '#fff';
			this.content.faq_styles['icon_name'] = 'fas fa-plus-circle';
			this.content.faq_styles['icon_name_pause'] = 'fas fa-minus-circle';
			this.content.faq_styles['icon-padding-top'] = 10;
			this.content.faq_styles['icon-padding-left'] = 10;
			this.content.faq_styles['icon-padding-bottom'] = 10;
			this.content.faq_styles['icon-padding-right'] = 1;
		}
		if (this.content.faq_styles.answer_bg_colors === undefined) {
			this.content.faq_styles['answer_bg_colors'] = this.content.faq_styles['panelbgcolor'];
		}
		if (this.content.faq_styles['border-top'] === undefined) {
			this.content.faq_styles['border-top'] = this.content.faq_styles['border-width'];
			this.content.faq_styles['border-right'] = this.content.faq_styles['border-width'];
			this.content.faq_styles['border-bottom'] = this.content.faq_styles['border-width'];
			this.content.faq_styles['border-left'] = this.content.faq_styles['border-width'];
		}
		if (this.content.faq_styles['padding-top'] === undefined) {
			this.content.faq_styles['padding-top'] = 0;
			this.content.faq_styles['padding-left'] = 0;
			this.content.faq_styles['padding-right'] = 0;
			this.content.faq_styles['padding-bottom'] = 0;
		}
		if (this.content.shadow === undefined) {
			this.content['shadow'] = { styles: {} };
		}
		if (this.content['category_styles'] === undefined) {
			this.content['category_styles'] = {
				'padding-top': 0,
				'padding-left': 0,
				'padding-right': 0,
				'padding-bottom': 0,
			};
		}
		if (this.content['ans_styles'] === undefined) {
			this.content['ans_styles'] = {
				'padding-top': 0,
				'padding-left': 0,
				'padding-right': 0,
				'padding-bottom': 0,
			};
		}
		if (this.content['qst_styles'] === undefined) {
			this.content['qst_styles'] = {
				'padding-top': 0,
				'padding-left': 0,
				'padding-right': 0,
				'padding-bottom': 0,
			};
		}
	}

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	getCategories() {
		this.dataService.getCategories(this.siteId, '').subscribe((data) => {
			var categories = data.DATA;
			categories.forEach((cat) => (cat['FAQ'] = []));
			this.categories = categories;
			this.getFAQs();
		});
	}

	getFAQs() {
		this.dataService.getFAQs(this.siteId, this.categoryId).subscribe((data) => {
			this.faqs = data.DATA;
			this.categories.forEach((cat) => {
				cat['FAQ'] = this.faqs.filter((faq) => faq.CATEGORY_ID === cat.ID);
			});
		});
	}

	getFAQStyles(obj) {
		var style = {};
		if (obj['panelbgcolor'] !== null && obj['panelbgcolor'] !== undefined) {
			style['background-color'] = obj['panelbgcolor'];
		}

		this.pageFunctions.setBorder(style, obj, true);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style['border-radius'] = obj['border-radius'] + 'px';
		}

		style['margin-bottom'] = '15px';

		//style = this.getShadowStyle(this.content['styles'], style);
		return style;
	}

	getShadowStyle(obj, style) {
		this.pageFunctions.setShadow(style, obj);
		return style;
	}
}
