<div class="row m-2">
    <div class="col-12 mt-2 mb-1">
        <span style="font-weight: bold;">Booking Detail</span>
        <button type="button" class="btn btn-light float-right mr-1" (click)="close()" aria-label="Close" >
            Close</button>        
    </div>

    <div class="col-8 mt-2 mb-1" *ngIf="order.PAYMENT_STATUS === 'Cancelled'">
        Booking No : {{order.ORDER_NO}} / Dated: {{order.ORDER_DATE | date: 'd-MMM-y' }} / Payment: Failed
    </div>

    <div class="col-8 mt-2 mb-1" *ngIf="order.PAYMENT_STATUS === 'Pending'">
        Booking No : {{order.ORDER_NO}} / Dated: {{order.ORDER_DATE | date: 'd-MMM-y' }} / Payment: Pending
    </div>

    <div class="col-8 mt-2 mb-1" *ngIf="order.PAYMENT_STATUS !== 'Cancelled' && order.PAYMENT_STATUS !== 'Pending'">
        Booking No : {{order.ORDER_NO}} / Dated: {{order.ORDER_DATE | date: 'd-MMM-y' }} / Status: {{order.ORDER_STATUS}}
    </div>

    <div class="col-4 mt-2 mb-1">
        <button type="button" class="btn btn-success float-right mr-1" *ngIf="showDownload"
            (click)="download()" aria-label="Download Invoice" > Download Invoice</button>   
    </div>
    <div class="col-8 mt-2 mb-1">
        <p>Address: {{order.ADDRESS1}} {{order.ADDRESS2}} {{order.CITY}} {{order.PINCODE}} {{order.STATE}}</p>
    </div>
    <div class="col-4 mt-2 mb-1">
        <button type="button" class="btn btn-warning float-right mr-1" *ngIf="showCancellation"
            (click)="cancelOrder()" aria-label="Cancel Order" >Cancel Booking</button>
    </div>

    <div class="table-responsive mb-2" *ngIf="!showReviewDiv">
        <table id="table-order-details" class="table table-bordered" style="min-width:500px;overflow-x:scroll;">
            <thead>
                <tr>
                    <th style="width:20%;"></th>
                    <th style="width:46%;">Service Name</th>
                    <th style="width:7%;">Qty</th>
                    <th style="width:9%;">Price</th>
                    <th style="width:9%;">Charges</th>
                    <th style="width:9%;">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of products">
                    <td><img [src]="item.IMAGE_URL" height="100px" 
                        style="display: block; width: 100%;object-fit: contain;"/></td>
                    <td style="text-align: left;" 
                    *ngIf="order.ORDER_STATUS !== 'Delivered' && order.ORDER_STATUS !== 'Completed' 
                        && order.ORDER_STATUS !== 'Booked'">
                        {{item.TITLE}}<br/>
                        {{item.CALENDER_DATE}} ({{item.START_TIME}} - {{item.END_TIME}})<br/>
                        <div class="w-100" *ngIf="item.ADDITIONAL_SERVICES.length > 0">
                            <table class="table table-bordered">
                                <thead *ngIf="showAddlServiceHeader(item)">
                                    <tr>
                                        <td style="width:60%;">Service</td>
                                        <td style="width:20%;">Qty</td>
                                        <td style="width:20%;">Fees</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let charge of item.ADDITIONAL_SERVICES">
                                        <tr *ngIf="charge['Selected']===true">
                                            <td>{{charge.SERVICE_NAME}}</td>
                                            <td>{{charge.QTY}}</td>
                                            <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <a class="btn btn-light mt-1" (click)="addServices(item)">Book Additional Services</a>
                        </div>
                    </td>
                    <td style="text-align: left;" 
                    *ngIf="(order.ORDER_STATUS === 'Delivered' || order.ORDER_STATUS === 'Completed'
                        || order.ORDER_STATUS === 'Booked') && item.ALLOW_RESCHEDULE === '0'">
                        {{item.TITLE}} <br/>
                        {{item.CALENDER_DATE}} ({{item.START_TIME}} - {{item.END_TIME}}) <br/>
                        <div class="w-100" *ngIf="item.ADDITIONAL_SERVICES.length > 0">
                            <table class="table table-bordered">
                                <thead *ngIf="showAddlServiceHeader(item)">
                                    <tr>
                                        <td style="width:60%;">Service</td>
                                        <td style="width:20%;">Qty</td>
                                        <td style="width:20%;">Fees</td>
                                    </tr>
                                </thead>                                
                                <tbody>
                                    <ng-container *ngFor="let charge of item.ADDITIONAL_SERVICES">
                                        <tr *ngIf="charge['Selected']===true">
                                            <td>{{charge.SERVICE_NAME}}</td>
                                            <td>{{charge.QTY}}</td>
                                            <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <a class="btn btn-light mt-1" (click)="addServices(item)">Book Additional Services</a>
                        </div>
                        <a class="btn btn-light mt-1" (click)="showScorePage(item)"
                        *ngIf="item.SCORE_AVAILABLE===1 && showScore">Your Score</a>
                    </td>
                    <td style="text-align: left;" 
                    *ngIf="(order.ORDER_STATUS === 'Delivered' ||  order.ORDER_STATUS === 'Completed' 
                        || order.ORDER_STATUS === 'Booked') && item.ALLOW_RESCHEDULE === '1'">
                        {{item.TITLE}} <br/>
                        {{item.CALENDER_DATE}} ({{item.START_TIME}} - {{item.END_TIME}}) <br/>
                        <div class="w-100" *ngIf="item.ADDITIONAL_SERVICES.length > 0">
                            <table class="table table-bordered">
                                <thead *ngIf="showAddlServiceHeader(item)">
                                    <tr>
                                        <td style="width:60%;">Service</td>
                                        <td style="width:20%;">Qty</td>
                                        <td style="width:20%;">Fees</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let charge of item.ADDITIONAL_SERVICES">
                                        <tr *ngIf="charge['Selected']===true">
                                            <td>{{charge.SERVICE_NAME}}</td>
                                            <td>{{charge.QTY}}</td>
                                            <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <a class="btn btn-light mt-1" (click)="addServices(item)">Book Additional Services</a>
                        </div>
                        
                        <a class="btn btn-light mt-1" (click)="reschedule(item)"
                        *ngIf="item.RESCHEDULE_STATUS!=='PENDING' && (item.RESCHEDULE===undefined 
                        || item.RESCHEDULE===false)">Reschedule</a>
                        <a class="btn btn-light mt-1" (click)="reschedulestatus(item)"
                        *ngIf="item.RESCHEDULE_STATUS==='PENDING'">Reschedule Pending Payment</a>
                        <div class="w-100" *ngIf="item.RESCHEDULE!==undefined && item.RESCHEDULE">
                            <div class="w-100"  *ngIf="item.CALENDAR.length > 0">
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="width:90px!important">Slot</span>
                                    </div>
                                    <select class="form-control" [(ngModel)]="item['NEW_SLOT_ID']" (change)="calcCharge(item)">
                                        <option *ngFor="let variant of item.CALENDAR" [value]="variant.ID">
                                            {{variant.SLOT_DATE}} ({{variant.START_TIME}}-{{variant.END_TIME}}) {{variant.IS_BOOKING_ALLOWED===0?" (with Late Fees)":""}}
                                        </option>
                                    </select>
                                </div>
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="width:90px!important">Pay Option</span>
                                    </div>
                                    <select class="form-control" [(ngModel)]="selectedPayment">
                                        <option value="Stripe" *ngIf="paymentOptions.IS_STRIPE_ACTIVE === '1'">Stripe</option>
                                        <option value="PayPal" *ngIf="paymentOptions.IS_PAYPAL_ACTIVE === '1'">PayPal</option>
                                        <option value="RazorPay" *ngIf="paymentOptions.IS_RAZOR_PAY_ACTIVE === '1'">RazorPay</option>
                                        <option value="MVola" *ngIf="paymentOptions.IS_MVOLA_ACTIVE === '1'">MVola</option>
                                        <option value="Airtel" *ngIf="paymentOptions.IS_AIRTEL_ACTIVE === '1'">Airtel</option>
                                        <option value="Orange" *ngIf="paymentOptions.IS_ORANGE_ACTIVE === '1'">Orange</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button class="btn btn-info" type="button" style="width:150px!important" *ngIf="totalAmount>0"
                                            (click)="updateReschedule($event,item)">Pay {{dataService.AppBookingData.CurrencyCode}} {{totalAmount}}</button>
                                        <button class="btn btn-info" type="button" style="width:150px!important" *ngIf="totalAmount===0"
                                            (click)="updateReschedule($event,item)">Pay</button>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="item['SHOW_NO_SLOTS']">No Slots available.. Cannot Reschedule..</span>
                            <span *ngIf="item['PROCESSING']">Processing..</span>
                        </div>
                    </td>
                    <td style="text-align: center;">{{item.QTY}}</td>
                    <td style="text-align: right;">{{item.PRICE | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{item.ADDITIONAL_CHARGES | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{item.AMOUNT | number:'1.2-2'}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="5" style="text-align: right;">Total</th>
                    <th style="text-align: right;">{{order.ORDER_AMOUNT | number:'1.2-2'}}</th>
                </tr>
            </tfoot>
        </table>
    </div>  

    <div class="table-responsive mb-2" *ngIf="additionalServices.length > 0">
        <table id="table-additional-services" class="table table-bordered" style="min-width:500px;overflow-x:scroll;">
            <thead>
                <tr>
                    <th style="width:12%;">Booked Date</th>
                    <th style="width:24%;">Service Name</th>
                    <th style="width:24%;">Additional Service Name</th>
                    <th style="width:8%;">Qty</th>
                    <th style="width:8%;">Fees</th>
                    <th style="width:24%;">Address / Remarks</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let service of additionalServices">
                    <td>{{service.BOOKED_DATE}}</td>
                    <td>{{service.TITLE}}<br/>
                        {{service.SLOT_DATE}} ({{service.START_TIME}}-{{service.END_TIME}})
                    </td>
                    <td>{{service.SERVICE_NAME}}</td>
                    <td>{{service.QTY}}</td>
                    <td>{{service.AMOUNT | number:'1.2-2'}}</td>
                    <td>{{service.REMARKS}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>