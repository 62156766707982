<div class="row blog-post-style">
    <div [class]="content.class" *ngFor="let article of articles" style="cursor:pointer;" (click)="loadPage(article)">
        <ng-container *ngIf="article.IMAGE_URL!==''">
            <div [ngStyle]="getStylesMain(content.slider_styles, content.styles)">
                <div class="w-100">
                    <div [ngStyle]="getStyles(content.image_styles)">
                        <img [src]="article.IMAGE_URL" alt="image slide" 
                            style="display: block; width: 100%;object-fit: cover;"
                            [ngStyle]="getBorderRadius(content.image_styles)" 
                            height="200">
                    </div>
                </div>
                <div class="w-100">
                    <p class="d-flex" [ngStyle]="getStyles(content.author_styles)">
                        <span>
                            <small class="mr-1">By</small>
                            <strong class="mr-1">{{article.AUTHOR_NAME}}</strong>
                            <small class="mr-1">on</small>
                            <strong class="mr-1">{{article.DATE_OF_CREATION}}</strong>
                        </span>
                        <span class="ml-auto">
                            <small>
                                <strong class="mr-1">{{article.COMMENTS}}</strong>
                                <span>Comments</span>
                            </small>
                        </span>
                    </p>
                </div>
                <div class="w-100">
                    <div [ngStyle]="getStyles(content.title_styles)" 
                        style="line-height: 1.5em; overflow: hidden;">
                        {{article.PUBLISHED_TITLE}}
                    </div>
                </div>
                <div class="w-100" style="display:flex;">
                    <div [ngStyle]="getStyles(content.desc_styles)"
                        style="min-height:50px; max-height:450px; line-height:1.5em; overflow:hidden;">
                        {{article.PUBLISHED_SHORT_DESC}}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="article.IMAGE_URL===''">
            <div [ngStyle]="getStylesMain(content.slider_styles, content.styles)">
                <div class="w-100">
                    <div [ngStyle]="getStyles(content.title_styles)" 
                        style="line-height: 1.5em; overflow: hidden;">
                        {{article.PUBLISHED_TITLE}}
                    </div>
                </div>
                <div class="w-100">
                    <p class="d-flex" [ngStyle]="getStyles(content.author_styles)">
                        <span>
                            <small class="mr-1">By</small>
                            <strong class="mr-1">{{article.AUTHOR_NAME}}</strong>
                            <small class="mr-1">on</small>
                            <strong class="mr-1">{{article.DATE_OF_CREATION}}</strong>
                        </span>
                        <span class="ml-auto">
                            <small>
                                <strong class="mr-1">{{article.COMMENTS}}</strong>
                                <span>Comments</span>
                            </small>
                        </span>
                    </p>
                </div>
                <div class="w-100" style="display:flex;">
                    <div [ngStyle]="getStyles(content.desc_styles)"
                        style="min-height:50px; max-height:450px; line-height:1.5em; overflow:hidden;white-space: pre;">
                        {{article.PUBLISHED_SHORT_DESC}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
