import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { ListingComponent } from './listing/listing.component';
import { RolemenusComponent } from './roles/rolemenus/rolemenus.component';
import { StaticpageComponent } from './staticpage/staticpage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageComponent } from './page/page.component';
import { ReportComponent } from './report/report.component';
import { LoginComponent } from './authentication/login/login.component';


export const Approutes: Routes = [
  
  {
    path: 'app',
    component: FullComponent,
    children: [
      {
        path: 'index',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
      },
      {
        path: 'listing', component:ListingComponent
      },
      {
        path: 'direct_page', 
        loadChildren: () => import('./page/page.module').then(m => m.PageModule)
      },
      {
        path: 'page', 
        loadChildren: () => import('./page/page.module').then(m => m.PageModule)
      },
      {
        path: 'dashboard', 
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'report', 
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'users', 
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'roles', 
        loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'rolemenu', component:RolemenusComponent
      },
      {
        path: 'changepassword', 
        loadChildren: () => import('./changepwd/changepwd.module').then(m => m.ChangePasswordModule)
      },
    ]
  },
  {
		path: '',
		component: BlankComponent,
		children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: StaticpageComponent },
      { path: 'form_particulars', component: PageComponent },
      { path: 'report_page', component: ReportComponent },
      { path: 'dashboard_page', component: DashboardComponent },
      { path: 'applogin', component: LoginComponent },
      { path: '\:name', component: StaticpageComponent },
      { path: '\:name\:ref', component: StaticpageComponent }
		]
	},
  {
    path: '**',
    redirectTo: '/home'
  }
];
