import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { EcommerceService } from '../../../services';
import { ProductZoomComponent } from '../product-zoom/product-zoom.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PageFunctions } from '../../directives/PageFunctions';
declare var $: any;

@Component({
  selector: 'app-productinfo',
  templateUrl: './productinfo.component.html',
  styleUrls: ['./productinfo.component.scss']
})
export class ProductinfoComponent implements OnInit {
  @Input() props: any;
  @Input() info: any;
  @Input() row:any;
  
  relevantitems: any = [];
  selectedVariant: any = {};
  selectedVariantId: string = '';
  cartCount: number = 1;
  addedToWishlist: boolean = false;
  appData: any = {};

  constructor(public pageFunctions: PageFunctions, public ecomService: EcommerceService, 
    public router: Router, private route: ActivatedRoute, public dialog: MatDialog) { 
    this.appData = this.ecomService.AppData;
  }

  ngOnInit(): void {
    if (this.props.template===undefined){
      this.props["template"]="1";
    }

    if (this.props.wishlist_styles === undefined){
      this.props['wishlist_styles'] = {
			  "selected-color":"#FF0000",
			  "unselected-color":"#000",
        "font-size":"30px"
			}
    }

    if (this.props.image_styles === undefined){
      this.props['image_styles'] = {
        "margin-right": 0,
        "margin-left": 0,
        "margin-top": 0,
        "margin-bottom": 0
      }
    }

    if (this.props['label_styles'] === undefined) {
      this.props['label_styles'] = {
        'color':'#495057',
        'background-color':'transparent',
        'font-family':'Roboto',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'margin-top':0,
        'margin-bottom':0,
        'margin-left':0,
        'margin-right':0
      };
    }

    if (this.props.additional_styles === undefined){
      this.props.additional_styles = {
        'header-color':'#495057',
        'header-bgcolor':'#fff',        
        'color':'#495057',
        'background-color':'#fff',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'margin-top':0,
        'margin-right':0,
        'margin-bottom':0,
        'margin-left':0,
        'padding-top':0,
        'padding-right':0,
        'padding-bottom':0,
        'padding-left':0,
        'border-top':0,
        'border-right':0,
        'border-bottom':0,
        'border-left':0,
        'border-style':'solid',
        'border-color':'#000',
        'border-top-left-radius':0,
        'border-top-right-radius':0,
        'border-bottom-left-radius':0,
        'border-bottom-right-radius':0
      }
    }
    
    if (this.props['input_styles'] === undefined) {
      this.props['input_styles'] = {
        'color':'#495057',
        'background-color':'#fff',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'border-top':0,
        'border-right':0,
        'border-bottom':0,
        'border-left':0,
        'border-style':'solid',
        'position':'',
        'border-color':'#000',
        'border-top-left-radius':0,
        'border-top-right-radius':0,
        'border-bottom-left-radius':0,
        'border-bottom-right-radius':0
      };
    }
    if (this.props['cart_styles'] === undefined) {
      this.props['cart_styles'] = {
        'color':'#fff',
        'background-color':'#5d9cec',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }

    if (this.props['review_caption_styles'] === undefined) {
      this.props['review_caption_styles'] = {
        'color':'#000',
        'font-family':'Roboto',
        'font-size':'24px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'left',
        'padding-top':0,
        'padding-right':0,
        'padding-bottom':0,
        'padding-left':0,
      };
    }
    if (this.props['review_headline_styles'] === undefined) {
      this.props['review_headline_styles'] = {
        'color':'#000',
        'font-family':'Roboto',
        'font-size':'22px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'left',
        'padding-top':0,
        'padding-right':0,
        'padding-bottom':0,
        'padding-left':0,
      };
    }
    if (this.props['review_username_styles'] === undefined) {
      this.props['review_username_styles'] = {
        'color':'#000',
        'font-family':'Roboto',
        'font-size':'22px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'left',
        'padding-top':0,
        'padding-right':0,
        'padding-bottom':0,
        'padding-left':0,
      };
    }
    if (this.props['review_date_styles'] === undefined) {
      this.props['review_date_styles'] = {
        'color':'#000',
        'font-family':'Roboto',
        'font-size':'20px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'left',
        'padding-top':0,
        'padding-right':0,
        'padding-bottom':0,
        'padding-left':0,
      };
    }
    if (this.props['review_comment_styles'] === undefined) {
      this.props['review_comment_styles'] = {
        'color':'#000',
        'font-family':'Roboto',
        'font-size':'18px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'left',
        'padding-top':0,
        'padding-right':0,
        'padding-bottom':0,
        'padding-left':0,
      };
    }   
    if (this.props.review_paging_styles === undefined){
      this.props['review_paging_styles'] = {
        "color": "#000",
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "24px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "",
        "justify-content": "left"
      };
    } 
    if (this.props['review_styles'] === undefined) {
      this.props['review_styles'] = {
        'header_bg':'#fff',
        'body_bg':'#fff',
        'footer_bg':'#fff',
        'margin-top':0,
        'margin-bottom':0,
        'margin-left':0,
        'margin-right':0
      };
    }

    this.appData = this.ecomService.AppData;
    
    this.props.ITEM = this.info;
    this.props.ITEM['TYPE'] = "Image";

    if (this.info.VARIANTS !== undefined && this.info.VARIANTS.length > 0){
      this.selectedVariant = this.info.VARIANTS[0];
      this.selectedVariantId = this.selectedVariant.ID;
      this.getImages();
      this.getRelevantItems();
    }

  }

  isMobile(){
		return window.innerWidth < 700;
	}
  
  gotoLogin(){
		var loginUrl = this.appData.LoginPage;
		this.router.navigate([loginUrl]);
	}

  onChange($event){
    this.selectedVariantId = $event.target.value;
    this.selectedVariant = this.info.VARIANTS.filter(variant=> variant.ID===this.selectedVariantId)[0];
    this.getImages();
    setTimeout(()=>{
      //this.row.styles.height = "100%";
      this.increaseRowHeight();
    },1000);
  }

  pageChanged(event: PageChangedEvent, content): void {
		const startItem = (event.page - 1) * event.itemsPerPage;
		//const endItem = event.page * event.itemsPerPage;
		this.getReviews(startItem,10);
	}

  addToCart(){
    this.ecomService.addToCart({
      "ID":this.info.ID, 
      "VARIANT_ID":this.selectedVariantId, 
      "PRODUCT_NAME": this.info.PRODUCT_NAME,
      "PRODUCT_DESCRIPTION": this.info.PRODUCT_DESCRIPTION,
      "CART_COUNT": this.cartCount,
      "PRICE": this.selectedVariant.DISCOUNTED_PRICE,
      "IMAGE_URL": this.selectedVariant.IMAGE_URL
    });
  }

  increaseRowHeight(){
    this.row.styles.height = $('#'+ this.info.ID)[0].scrollHeight + 10;
  }

  InWishlist(){
    return this.ecomService.AppData.WISHLIST.filter(product=>product.VARIANT_ID===this.selectedVariantId).length > 0;
  }

  addToWishlist(){
    if (this.appData.isLoggedin === true){
      this.ecomService.addToWishlist({
        "PRODUCT_ID":this.info.ID,
        "VARIANT_ID":this.selectedVariantId,
        "QTY":1,
        "PRICE": this.selectedVariant.DISCOUNTED_PRICE,
        "IMAGE_URL": this.selectedVariant.IMAGE_URL === null ? this.props.ITEM.IMAGE_URL : this.selectedVariant.IMAGE_URL
      });
    }
    else{
      this.gotoLogin();
    }
  }

  removeFromWishlist(){
    this.ecomService.removeFromWishlist({"VARIANT_ID":this.selectedVariantId});
  }

  getReviews(from, limit){
    this.ecomService.getProductReviews(this.info.ID, from, limit).subscribe(resp=>{
      if (resp.RESULT_CODE === 1){
        this.props.ITEM.USER_REVIEWS = resp.DATA;
      }
    });
  }

  getImages(){
    this.ecomService.getProductImages(this.selectedVariant.ID)
    .subscribe((res : any)=>{
      this.selectedVariant["IMAGES"] = res.DATA;
      this.selectedVariant["IMAGES"].forEach(image=>{
        image['TYPE']="Image";
      });
      this.getVideos();
    });
  }

  getVideos(){
    this.ecomService.getProductVideos(this.selectedVariant.ID)
    .subscribe((res : any)=>{
      var videos = res.DATA;
      videos.forEach(video => {
        video['TYPE']="Video";
        this.selectedVariant["IMAGES"].push(video);
      }); 
    });
  }

  getRelevantItems(){
    this.relevantitems = [];
    this.ecomService.getCategoryProducts(this.info.CATEGORY_ID)
    .subscribe((res : any)=>{
      this.relevantitems = res.DATA.filter(item=>item.ID !== this.info.ID);
      setTimeout(()=>{
        //this.row.styles.height = "100%";
        this.increaseRowHeight();
      },1000);
    });
  }

  public onMouseMove(e){
    if(window.innerWidth >= 1280){
      var image, offsetX, offsetY, x, y, zoomer, zoomerParent;
      image = e.currentTarget; 
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = offsetX/image.offsetWidth*100;
      y = offsetY/image.offsetHeight*100;
      //zoomerParent = this.zoomViewer.nativeElement;
      zoomerParent = document.getElementById("zoomViewer");
      if(zoomerParent){
        zoomerParent.style.display = "block";
        zoomer = zoomerParent.children[0];
        if(zoomer){
          zoomer.style.backgroundPosition = x + '% ' + y + '%';
          zoomer.style.display = "block";
          zoomer.style.height = (image.height * 1.5) + 'px';
          zoomer.style.width = (image.width * 1.5) + 'px';
        }
      }
    }
  }

  public onMouseLeave(event){
    var zoomViewer = document.getElementById("zoomViewer");
    if (zoomViewer){
      zoomViewer.style.display = "none";
    }
    //zoomViewer.children[0].style.display = "none";
    
  }

  public openZoomViewer(){
    this.dialog.open(ProductZoomComponent, {
      data: this.props.ITEM.IMAGE_URL,
      panelClass: 'zoom-dialog'
    });
  }

  loadProductInfo(product){
    if (product.PRODUCT_URL !== null && product.PRODUCT_URL !== "")
      this.router.navigate([product.PRODUCT_URL,{pid:product.ID}]);
    else
      this.router.navigate([this.appData.ProductPage ,{pid:product.ID}]);
  }

  getTableStyle(obj){
		var style = {};

		this.pageFunctions.checkNullAndAssign(style,obj,'font-weight','');
		this.pageFunctions.checkNullAndAssign(style,obj,'font-style','');
    this.pageFunctions.checkNullAndAssign(style,obj,'font-size','');
		this.pageFunctions.checkNullAndAssign(style,obj,'text-decoration','');
    this.pageFunctions.checkNullAndAssign(style,obj,'text-align','');

		return style;
	}

  getTableColumnStyle(obj){
		var style = {};
		
    this.pageFunctions.setPadding(style,obj);
		
		if(obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)' ){
			this.pageFunctions.setBorder(style,obj,true);
		}

		return style;
	}

  getInputStyle(obj, ctrlType){
		var style = {};

		if (obj===undefined){
			obj = {
				'color':'#495057',
				'background-color':'#fff',
        'font-size':'16px',
				'font-weight':'normal',
				'font-style':'normal',
				'text-decoration':'normal',
				'border-top':0,
				'border-right':0,
				'border-bottom':0,
				'border-left':0,
				'border-style':'',
				'position':'',
				'border-color':'#495057',
				'border-top-left-radius':0,
				'border-top-right-radius':0,
				'border-bottom-left-radius':0,
				'border-bottom-right-radius':0
			};
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if(obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)' ){
			this.pageFunctions.setBorder(style,obj,true);
		}

		this.pageFunctions.serBorderRadius(style, obj);

		return style;
	}

  getStyles(obj,contentType) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setBorder(style,obj,true);

    this.pageFunctions.setPadding(style,obj);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'].toString().includes("%")){
      style["height"] = obj['height'];
    }
    else if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

   
		if(contentType === 'Button' || contentType === 'Text'){
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				var objWidth = obj['width'];
				if(contentType === 'Text'){
					if (obj['width-type'] !== null && obj['width-type'] !== undefined && obj['width-type'] !== "" && obj['width-type'] === 'px') {
					}else{
						objWidth = obj['width'] * 13.8;
						obj['width-type'] = 'px';
					}
				}
				style["width"] =  objWidth + "px";
				style['width-type'] = 'px';
			}
		}else if(contentType === 'Image'){
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				style["width"] = obj['width'] + "px";
			}
		}else{
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				style["width"] = obj['width'] + "%";
			}
		}
    this.pageFunctions.setMargin(style,obj);
    
    return style;
  }

}
