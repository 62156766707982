import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { EcommerceService } from '../../../services';

@Component({
  selector: 'app-cartbutton',
  templateUrl: './cartbutton.component.html',
  styleUrls: ['./cartbutton.component.scss']
})
export class CartbuttonComponent implements OnInit {
  @Input() content: any;

  constructor(public router: Router, public dataService: EcommerceService) { }

  ngOnInit(): void {
    if (this.content.styles.iconcolor === undefined){
      this.content['styles']['iconcolor'] = "gray";
    }
    if (this.content.styles.iconsize === undefined){
      this.content['styles']['iconsize'] = "36px";
    }
    if (this.content.styles.countcolor === undefined){
      this.content['styles']['countcolor'] = "white";
    }
    if (this.content.styles.countbgcolor === undefined){
      this.content['styles']['countbgcolor'] = "green";
    }
  }

  getCartCount(){
    var cartCount = 0;
    this.dataService.AppData.CART_LIST.forEach(item=>{
      cartCount += item.CART_COUNT;
    });
    return cartCount;
  }

  checkout(){
      this.router.navigate([this.dataService.AppData.CheckoutPage]);
  }

}
