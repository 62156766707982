import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { BookingService } from '../../../../services';

@Component({
  selector: 'app-bookingcartbutton',
  templateUrl: './bookingcartbutton.component.html',
  styleUrls: ['./bookingcartbutton.component.scss']
})
export class BookingCartbuttonComponent implements OnInit {
  @Input() content: any;

  constructor(public router: Router, public dataService: BookingService) { }

  ngOnInit(): void { 
    if (this.content.styles.iconcolor === undefined){
      this.content['styles']['iconcolor'] = "gray";
    }
    if (this.content.styles.iconsize === undefined){
      this.content['styles']['iconsize'] = "36px";
    }
    if (this.content.styles.countcolor === undefined){
      this.content['styles']['countcolor'] = "white";
    }
    if (this.content.styles.countbgcolor === undefined){
      this.content['styles']['countbgcolor'] = "green";
    }
    if (this.content.styles.position === undefined){
      this.content['styles']['position'] = "absolute";
    }
  }

  getCartCount(){
    var cartCount = 0;
    this.dataService.AppBookingData.BOOKING_CART_LIST.forEach(item=>{
      cartCount += item.CART_COUNT;
    });
    return cartCount;
  }

  checkout(){
      this.router.navigate([this.dataService.AppBookingData.CheckoutPage]);
  }

}
