<ng-container *ngIf="!showDeliveryPage">
    <div class="w-100 mt-2 mb-1" [ngStyle]="getStyles(props.styles)">
        Checkout
    </div>
    <div class="mt-2 mb-2" *ngIf="cartItems.length === 0">No items added to cart</div>
    <div class="table-responsive mb-2" *ngIf="cartItems.length > 0" [ngStyle]="getStyles(props.table_styles)">
        <table class="table table-bordered" style="min-width:650px;overflow-x:scroll;">
            <thead [ngStyle]="getStyles(props.header_styles)">
                <tr *ngIf="window_width>=786">
                    <th style="width:20%!important;" ></th>
                    <th style="width:40%!important;">Service Name</th>
                    <th style="width:8%!important;">Qty</th>
                    <th style="width:8%!important;">Price</th>
                    <th style="width:10%!important;">Charges</th>
                    <th style="width:10%!important;">Total</th>
                    <th style="width:4%!important;">..</th>
                </tr>
                <tr *ngIf="window_width<786">
                    <th style="width:50%!important;">Service Name</th>
                    <th style="width:10%!important;">Qty</th>
                    <th style="width:12%!important;">Price</th>
                    <th style="width:12%!important;">Charges</th>
                    <th style="width:12%!important;">Total</th>
                    <th style="width:4%!important;">..</th>
                </tr>
            </thead>
            <tbody [ngStyle]="getStyles(props.body_styles)">
                <tr *ngFor="let item of cartItems">
                    <td *ngIf="window_width>=786"><img [src]="item.IMAGE_URL" height="100px" 
                        style="display: block; width: 100%;object-fit: contain;"/></td>
                    <td style="text-align: left;">
                        {{item.TITLE}} <br/>
                        {{item.CALENDER_DATE}} ({{item.START_TIME}}-{{item.END_TIME}})
                        <div class="w-100" *ngIf="item.ADDITIONAL_SERVICES !== undefined && item.ADDITIONAL_SERVICES.length > 0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width:10%;">..</th>
                                        <th style="width:50%;">Service</th>
                                        <th style="width:20%;">Fees</th>
                                        <th style="width:20%;">Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let charge of item.ADDITIONAL_SERVICES| slice:0:(item['showAll']?200:3)">
                                        <td *ngIf="charge['IS_MANDATORY']">
                                            <input type="checkbox" [checked]="charge['Selected']" disabled="disabled">
                                        </td>
                                        <td *ngIf="!charge['IS_MANDATORY']">
                                            <input type="checkbox" [checked]="charge['Selected']" (change)="selectCharges(item,charge)">
                                        </td>
                                        <td>{{charge.SERVICE_NAME}}</td>
                                        <td>{{appData.CurrencySymbol}}{{charge.SERVICE_CHARGE}}</td>
                                        <td *ngIf="charge['IS_MANDATORY']">
                                            <input type="number" class="form-control" [(ngModel)]="charge['QTY']" readonly="true"/>
                                        </td>
                                        <td *ngIf="!charge['IS_MANDATORY']">
                                            <input type="number" class="form-control" [(ngModel)]="charge['QTY']" (blur)="onChargeQtyBlur(item)"/>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>Total Additional Services</td>
                                        <td>{{appData.CurrencySymbol}}{{item['ADDITIONAL_SERVICE_AMOUNT']}}</td>
                                    </tr>
                                    <tr *ngIf="item.ADDITIONAL_SERVICES.length > 3 && !item['showAll']">
                                        <td colspan="3">
                                            <a class="btn btn-info" (click)="item['showAll']=true">Show All Optional Services</a>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </td>
                    <td style="text-align: center;" *ngIf="item.ALLOW_BOOKING === '1' || item.ALLOW_BOOKING_LC === '1'">
                        1
                    </td>
                    <td style="text-align: center;" *ngIf="item.ALLOW_BOOKING === '0' &&  item.ALLOW_BOOKING_LC === '0'">
                        <span style="color:red;"> Booking Not Available</span>
                    </td>

                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '1'">{{item.AMOUNT | number:'1.2-2'}}</td>
                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '1'">
                        {{item.LC_AMOUNT | number:'1.2-2'}}</td>
                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '0'"></td>
                    
                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '1' || item.ALLOW_BOOKING_LC === '1'">
                        {{item.ADDITIONAL_SERVICE_AMOUNT | number:'1.2-2'}}</td>
                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '0'"></td>

                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '1'">
                        {{(item.AMOUNT + item.ADDITIONAL_SERVICE_AMOUNT) * item.CART_COUNT | number:'1.2-2'}}</td>
                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '1'">
                        {{(item.LC_AMOUNT + item.ADDITIONAL_SERVICE_AMOUNT) * item.CART_COUNT | number:'1.2-2'}}</td>
                    <td style="text-align: right;" *ngIf="item.ALLOW_BOOKING === '0' && item.ALLOW_BOOKING_LC === '0'"></td>

                    <td><button class="btn" (click)="deleteCartItem(item)">
                        <span class="fa fa-trash-alt text-warning" title="Delete"></span>
                    </button></td>
                </tr>
            </tbody>
            <tfoot [ngStyle]="getStyles(props.footer_styles)">
                <tr *ngIf="window_width>=786">
                    <th colspan="5" style="width:84%;text-align: right;">Total</th>
                    <th style="width:10%;text-align: right;">{{getItemTotal() | number:'1.2-2'}}</th>
                    <th style="width:5%;"></th>
                </tr>
                <tr *ngIf="window_width<786">
                    <th colspan="4" style="text-align: right;">Total</th>
                    <th style="text-align: right;">{{getItemTotal() | number:'1.2-2'}}</th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    </div>  
    <div class="w-100 text-center mt-2 mb-5">
        <button class="btn btn-success mr-2" (click)="continueCheckout()"
        *ngIf="props.checkout_styles===undefined && cartItems.length > 0 && getTotal() > 0">Continue Checkout</button>
        <button class="btn mr-2" (click)="continueCheckout()"
        *ngIf="props.checkout_styles!==undefined && cartItems.length > 0 && getTotal() > 0"
        [style.textDecoration]="props['checkout_styles']['text-decoration']"
        [style.fontStyle]="props['checkout_styles']['font-style']"
        [style.fontWeight]="props['checkout_styles']['font-weight']"
        [style.color]="props['checkout_styles']['color']"
        [style.backgroundColor]="props['checkout_styles']['background-color']">Continue Checkout</button>

        <button class="btn btn-info" (click)="gotoHome()" *ngIf="props.shopping_styles===undefined">Continue Shopping</button>
        <button class="btn btn-info" (click)="gotoHome()" *ngIf="props.shopping_styles!==undefined"
        [style.textDecoration]="props['shopping_styles']['text-decoration']"
        [style.fontStyle]="props['shopping_styles']['font-style']"
        [style.fontWeight]="props['shopping_styles']['font-weight']"
        [style.color]="props['shopping_styles']['color']"
        [style.backgroundColor]="props['shopping_styles']['background-color']">Continue Shopping</button>
    </div>     
</ng-container>
<div class="card card-flat" *ngIf="showDeliveryPage">
    <div class="card-body">
        <p class="text-center py-2">DELIVERY INFORMATION</p>
        <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="deliveryForm" novalidate="" (submit)="submitForm($event, valForm.value)">
            <div class="row pl-2 pr-2">
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">First name</span>
                        </div>
                        <input class="form-control" type="text" name="firstName" placeholder="Enter first name" autocomplete="off" formControlName="firstName" readonly="true" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['firstName'].hasError('required') && (valForm.controls['firstName'].dirty || valForm.controls['firstName'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Last name</span>
                        </div>
                        <input class="form-control" type="text" name="lastName" placeholder="Enter last Name" autocomplete="off" formControlName="lastName" readonly="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['lastName'].hasError('required') && (valForm.controls['lastName'].dirty || valForm.controls['lastName'].touched)">This field is required</div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Address 1</span>
                        </div>
                        <input class="form-control" id="exampleInputEmail1" type="text" name="address1" placeholder="Door No, House / Apartment Name, Road / Street," autocomplete="off" formControlName="address1" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['address1'].hasError('required') && (valForm.controls['address1'].dirty || valForm.controls['address1'].touched)">This field is required</div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Address 2</span>
                        </div>
                        <input class="form-control" id="exampleInputEmail1" type="text" name="address2" placeholder="Locality / Area" autocomplete="off" formControlName="address2" required="" />
                    </div> 
                    <div class="text-danger" *ngIf="valForm.controls['address2'].hasError('required') && (valForm.controls['address2'].dirty || valForm.controls['address2'].touched)">This field is required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">City</span>
                        </div>
                        <input class="form-control" type="text" name="city" placeholder="Enter city" autocomplete="off" formControlName="city" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['city'].hasError('required') && (valForm.controls['city'].dirty || valForm.controls['city'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">State</span>
                        </div>
                        <input class="form-control" type="text" name="state" placeholder="Enter state" autocomplete="off" formControlName="state" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['state'].hasError('required') && (valForm.controls['state'].dirty || valForm.controls['state'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Postal Code</span>
                        </div>
                        <input class="form-control" type="number" name="pincode" placeholder="Enter postal code" autocomplete="off" formControlName="pincode" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['pincode'].hasError('required') && (valForm.controls['pincode'].dirty || valForm.controls['pincode'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Mobile #</span>
                        </div>
                        <input class="form-control" type="number" name="mobile" placeholder="Enter mobile" autocomplete="off" formControlName="mobile" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['mobile'].hasError('required') && (valForm.controls['mobile'].dirty || valForm.controls['mobile'].touched)">This field is required</div>                                            
                </div>
            </div>
            <div class="row mb-2 pl-2 pr-2" style="justify-content: center;align-items: center;">
                <div class="col-12">
                    <p class="text-center py-2">Payment Methods</p>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_STRIPE_ACTIVE === '1'" (click)="selectedPayment='Stripe'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/stripe.png');"
                    [ngClass]="{'selected': selectedPayment==='Stripe','unselected': selectedPayment!=='Stripe'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='Stripe','unselected-text': selectedPayment!=='Stripe'}">Stripe Payment</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_RAZOR_PAY_ACTIVE === '1'" (click)="selectedPayment='RazorPay'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/razorpay.png');"
                    [ngClass]="{'selected': selectedPayment==='RazorPay','unselected': selectedPayment!=='RazorPay'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='RazorPay','unselected-text': selectedPayment!=='RazorPay'}">RazorPay Payment</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_PAYPAL_ACTIVE === '1'" (click)="selectedPayment='PayPal'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/paypal.png');"
                    [ngClass]="{'selected': selectedPayment==='PayPal','unselected': selectedPayment!=='PayPal'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='PayPal','unselected-text': selectedPayment!=='PayPal'}">PayPal Payment</div>
                </div>                
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_MVOLA_ACTIVE === '1'" (click)="selectedPayment='MVola'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/mvola.png');"
                    [ngClass]="{'selected': selectedPayment==='MVola','unselected': selectedPayment!=='MVola'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='MVola','unselected-text': selectedPayment!=='MVola'}">Pay to {{paymentOptions['MVOLA_META'].PHONE_NUMBER}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_AIRTEL_ACTIVE === '1'" (click)="selectedPayment='Airtel'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/airtel.png')"
                    [ngClass]="{'selected': selectedPayment==='Airtel','unselected': selectedPayment!=='Airtel'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='Airtel','unselected-text': selectedPayment!=='Airtel'}">Pay to {{paymentOptions.AIRTEL_ACCOUNT_NUMBER}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 text-center pb-2" *ngIf="paymentOptions.IS_ORANGE_ACTIVE === '1'" (click)="selectedPayment='Orange'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/orange.png')"
                    [ngClass]="{'selected': selectedPayment==='Orange','unselected': selectedPayment!=='Orange'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='Orange','unselected-text': selectedPayment!=='Orange'}">Pay to {{paymentOptions.ORANGE_ACCOUNT_NUMBER}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_COD_ACTIVE === '1'" (click)="selectedPayment='COD'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/cod.png')"
                    [ngClass]="{'selected': selectedPayment==='COD','unselected': selectedPayment!=='COD'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='COD','unselected-text': selectedPayment!=='COD'}">Cash On Delivery</div>
                </div>
            </div>
            <button class="btn btn-success mt-3 mb-3" appNoDblClick [disabled]="processing" (click)="makePayment($event)">Make Payment</button>
        </form>

    </div>
</div>