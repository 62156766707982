<div id="overlay" *ngIf="showOverlay">
    <img src="/assets/img/loading_plain.gif">
</div>

<div id="mainDiv" #mainDiv class="row" [style]="getPageStyles(page.styles)" style="margin:0 !important;">
    <div class="col-lg-12">
        <!-- <div id="hamburgerbtn" class="hamburger" style="z-index: 20001;" (click)="showHamburgerMenu($event)"
            *ngIf="menuContent.menus !== undefined && menuContent.menus.length > 0">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
        </div> -->
        <!-- mobile hamburger menu -->
        <div id="hamburgermenu" class="nav-menu"
            [ngStyle]="getStylesType({},{},menuContent,menuContent.styles,'MobileMenu')"
            style="display: flex;justify-content: center;display:none;z-index:20000;width:100%; height: 100%; text-align: center; "
            *ngIf="window_width < 700 && menuContent.menus !== undefined && menuContent.menus.length > 0">
            <ul style="padding-inline-start: 0px!important;">
                <li class="nav-item" *ngFor="let menu of menuContent.menus; let menuIdx = index">
                    <div [ngStyle]="getStylesType({},{},menuContent,menuContent.styles,'MobileMenu')"
                        *ngIf="menu['has_sub_menu'] !== undefined && !menu['has_sub_menu']" (click)="loadPage(menu)"
                        [innerHtml]="getHTML(menu.value)">
                    </div>
                    <div class="has-dropdown-mobile" *ngIf="menu['has_sub_menu'] !== undefined && menu['has_sub_menu']">
                        <div [ngStyle]="getStylesType({},{},menuContent,menuContent.styles,'MobileMenu')"
                            [innerHtml]="getSubmenuHTML(menu.value)" (click)="toggleSubMenu(menu)"></div>
                        <div [id]="menu.id" class="sub-menu" style="padding-left: 10px!important;"
                            [ngStyle]="getSubMenuStyles(menuContent.sub_menu_styles,'MobileMenu')">
                            <div *ngFor="let submenu of menu['subMenus']" (click)="loadPage(submenu)">{{submenu.value}}
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <div [ngStyle]="getStylesType({},{},menuContent,menuContent.styles,'MobileMenu')"
                        (click)="showHamburgerMenu($event)">
                        <i class="fa fa-times-circle"></i>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngFor="let row of rows; let rowidx = index"
            [style.zIndex]="row.styles['pin-at-bottom'] ? 9999 : 100-rowidx"
            [style]="pageFunctions.getRowPosition(row.styles)">
            <div class="row contentrow" id="{{row.id}}" #rowContainerComponent
                [style.zIndex]="row.styles['pin-at-bottom'] ? 9999 : 9999-(rowidx * 10)"
                [style]="getRowStyles(row, row.styles, rowidx)" style="position: relative;"
                [ngClass]="row['styles']['conic_template_id']"
                [hidden]="row.styles['hide-row-in-display'] || (window_width < 700 && row.styles['hide-row'] !== undefined && row.styles['hide-row'])">
                <div style="display: flex;position: absolute;width: 100%;height: 100%;">
                    <video [id]="'video_' + row.id" class="video_bg"
                        *ngIf="row['styles']['background-type']!==undefined && row['styles']['background-type']==='video'"
                        [style.opacity]="row.styles['video-opacity'] === 'undefined'?'1':row.styles['video-opacity']"
                        [src]="row['styles']['background']['url']" autoplay loop playsinline
                        onloadedmetadata="this.muted = true"></video>
                    <div class="video_bg" [style]="getRowBackgroundColor(row.styles)"></div>
                </div>
                <div class="row contentrow" style="width:100%;" [style.justifyContent]="row.styles['justify-content']"
                    [ngClass]="{'humble-grid-layout': row['styles']['is-grid-layout']}"
                    [style]="{ 'max-width': row['styles']['row-full-width']===true?'100%':this.page.styles['page-width']+'px'}">
                    <div *ngFor="let column of row.columns; let idx = index" style="position: relative;"
                        class="{{column?.hoverGrowClass}} hum-column-hover" id="{{column.id}}"
                        [class]="row.styles['is-grid-layout'] ? 'humble-grid-row humble-grid-column' : column?.class"
                        [ngClass]="column['styles'] !== undefined && column['styles']['conic_template_id'] !== undefined ? column['styles']['conic_template_id']: ''"
                        [style]="getColumnStyles(column,column.contents.length)"
                        [style.zIndex]="window_width < 700 ? 30+idx : 30-idx"
                        [hidden]="window_width < 700 && (column.styles !== undefined && column.styles['hide-column'] !== undefined && column.styles['hide-column'] === true)">
                        <div *ngIf="this.designType!=='MOBILE'"
                            style="display: flex;position: absolute;width:100%;height: 100%;z-index: -1;">
                            <video [id]="'video_' + row.id + '_' + column.id"
                                *ngIf="column['styles']['background-type']!==undefined && column['styles']['background-type']==='video'"
                                [style.opacity]="column.styles['video-opacity'] === 'undefined'?'1':column.styles['video-opacity']"
                                class="video_bg_col" [src]="column['styles']['background']['url']" autoplay loop
                                playsinline onloadedmetadata="this.muted = true"></video>
                            <div class="video_bg" [style]="getRowBackgroundColor(column.styles)"></div>
                        </div>
                        <div class="w-100" *ngFor="let content of column.contents; let i = index">
                            <ng-container *ngIf="content.type==='Button'">
                                <div class="content" id="{{content.id}}" (click)="loadPage(content)"
                                    *ngIf="canShowButton(content)" [ngClass]="{'btn-hover':content.styles['is-gradient-animated'],
                                    'humble-hover-dyn':content.styles['hover-available']}"
                                    [ngStyle]="getStylesWithShadow(row,column,content,content.styles,'Button',content.styles)">
                                    <button class="btn" [style.fontSize]="content.styles['font-size']"
                                        [style.paddingTop.px]="content['styles']['isIcon'] ? content['styles']['padding-top'] : ''"
                                        [style.paddingBottom.px]="content['styles']['isIcon'] ? content['styles']['padding-bottom'] : ''"
                                        [style.paddingLeft.px]="content['styles']['isIcon'] ? content['styles']['padding-left'] : ''"
                                        [style.paddingRight.px]="content['styles']['isIcon'] ? content['styles']['padding-right'] : ''"
                                        style="color:inherit;letter-spacing:inherit;font-family:inherit;
                                        text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;">
                                        <em [class]="content.styles['icon_name']" style="vertical-align:middle;"
                                            [style.fontSize]="content.styles['icon-size']"
                                            [style.Color]="content.styles['icon-color']"
                                            [style.paddingTop.px]="content['styles']['icon-padding-top']"
                                            [style.paddingBottom.px]="content['styles']['icon-padding-bottom']"
                                            [style.paddingLeft.px]="content['styles']['icon-padding-left']"
                                            [style.paddingRight.px]="content['styles']['icon-padding-right']"
                                            [style.display]="content.styles['icon_as_block'] ? 'block':''"
                                            *ngIf="content.styles['icon-align'] === undefined || content.styles['icon-align'] !== 'right'"></em>
                                        {{content.value}}{{content.styles['show_visit_count'] ? WebsiteVisits : ""}}
                                        <em [class]="content.styles['icon_name'].replace('mr','ml')"
                                            style="vertical-align:middle;" [style.Color]="content.styles['icon-color']"
                                            [style.paddingTop.px]="content['styles']['icon-padding-top']"
                                            [style.paddingBottom.px]="content['styles']['icon-padding-bottom']"
                                            [style.paddingLeft.px]="content['styles']['icon-padding-left']"
                                            [style.paddingRight.px]="content['styles']['icon-padding-right']"
                                            [style.display]="content.styles['icon_as_block'] ? 'block':''"
                                            [style.fontSize]="content.styles['icon-size']"
                                            *ngIf="content.styles['icon-align'] !== undefined && content.styles['icon-align'] === 'right'"></em>
                                    </button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Heading'">
                                <div [ngStyle]="getStyles(row,column,content,content.styles)">
                                    {{content.value}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='FAQ'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'FAQ')">
                                    <app-site-faq [siteId]="WebsiteId" [content]="content"></app-site-faq>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='NEWS'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'BLOG')">
                                    <app-site-news [siteId]="WebsiteId" [content]="content"></app-site-news>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Services'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'BLOG')">
                                    <app-site-services [siteId]="WebsiteId" [content]="content" [row]="row">
                                    </app-site-services>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BLOG'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'BLOG')">
                                    <app-articles [siteId]="WebsiteId" [content]="content" [row]="row"></app-articles>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='CKEText'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'FAQ')">
                                    <app-cketext [siteId]="WebsiteId" [content]="content" [slugURL]="slugURL">
                                    </app-cketext>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='CLIPATH'">
                                <div [id]="content.id" style="opacity: 0;position: absolute;"
                                    [style.marginLeft]="getLeftMargin(content['styles'],row)"
                                    [style.marginTop]="content['styles']['margin-top']"
                                    [style.width.px]="getContentWidth(content.styles['width'])"
                                    [style.height.px]="content['styles']['height']"
                                    [style]="getShadowStyle(content['styles'])" (click)="loadPage(content)">
                                    <div [id]="'animate'+content.id" [style.transform]="getSkewState(content.styles)"
                                        class="w-100 h-100"
                                        [ngStyle]="getStylesType(row,column,content, content.styles,content.type)">
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Shape'">
                                <div [ngStyle]="getStylesType(row,column, content, content.styles, content.type)"
                                    [id]="content.id">
                                    <div [style.width.px]="content['styles']['width']"
                                        [style.height.px]="content['styles']['height']">
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Carousel'">
                                <div [style.position]="'absolute'" [id]="content.id"
                                    [style.marginLeft]="getLeftMargin(content['styles'],row)"
                                    [style.marginTop]="content['styles']['margin-top']"
                                    [style.backgroundColor]="content.styles['carousel-opacity-color']"
                                    [style.--indicator-color]="content.styles['carousel-indicator-color']"
                                    [style.width]="calculateWidthForResolutionWithMobile(row,column, content.styles['width'])"
                                    [style.height.px]="content['styles']['height']">

                                    <app-internal-button [componentClass]="'carousel-hc-button-previous'"
                                        [content]="content" [contentStyles]="content['styles-previous']"
                                        (click)="prevSlide(i)"></app-internal-button>

                                    <app-internal-button [componentClass]="'carousel-hc-button-next'"
                                        [content]="content" [contentStyles]="content['styles-next']"
                                        (click)="nextSlide(i)"></app-internal-button>


                                    <carousel [ngClass]="'carousel-' + i"
                                        [itemsPerSlide]="content['styles']['item-count']" [singleSlideOffset]="true"
                                        no-controls [showIndicators]="content['styles']['show-indicator']"
                                        [noWrap]="false" [interval]="content['styles']['carousel-interval'] * 1000"
                                        [startFromIndex]="0">
                                        <slide *ngFor="let item of content.items"
                                            [ngClass]="content.styles['slide-animation-class']"
                                            [style.backgroundColor]="item['background-color']">
                                            <img [src]="item.imageUrl === '' ? '../assets/img/dummy.png':item.imageUrl"
                                                alt="banner image"
                                                [ngStyle]="getStylesType(row,column,content,content.styles,'CarouselImage')"
                                                style="display: block; width: 100%;object-fit: cover;"
                                                [style.opacity]="content.styles['carousel-opacity'] === 'undefined'?'1':content.styles['carousel-opacity']"
                                                [style.height.px]="content['styles']['height']">
                                            <div [ngClass]="{'carousel-caption-top':'top' === content.styles['text-position'],
                                                'carousel-caption-bottom':'bottom'=== content.styles['text-position'],
                                                'center-div':'center'=== content.styles['text-position']}"
                                                [style.textAlign]="content.styles['text-align'] === 'undefined'?'center':content.styles['text-align']"
                                                class="carousel-caption d-md-block w-100"
                                                style="left:0% !important;right:0% !important;padding:20px;">
                                                <div class="carousel-hc-container">
                                                    <div *ngIf="content.styles['button-position'] !== undefined && content.styles['button-position'] !==  'NO'"
                                                        [style.order]="content.styles['button-position'] === undefined ? '1':content.styles['button-position']">
                                                        <div [style.width.%]="content.styles['carousel-main-width'] === 'undefined'?'100':content.styles['carousel-main-width']"
                                                            [style.marginTop.px]="content['sub_styles']['margin-top']"
                                                            [style.marginBottom.px]="content['sub_styles']['margin-bottom']"
                                                            [style.textAlign]="content.styles['text-align'] === 'undefined'?'center':content.styles['text-align']"
                                                            [ngStyle]="getStylesType(row,column,content,content.styles,'Carousel-Main')">
                                                            <button class="btn btn-info" (click)="loadPage(item)"
                                                                [style.borderTopLeftRadius.px]="item['border-top-left-radius'] === undefined ? '0' : item['border-top-left-radius']"
                                                                [style.borderTopRightRadius.px]="item['border-top-right-radius'] === undefined ? '0' : item['border-top-right-radius']"
                                                                [style.borderBottomLeftRadius.px]="item['border-bottom-left-radius'] === undefined ? '0' : item['border-bottom-left-radius']"
                                                                [style.borderBottomRightRadius.px]="item['border-bottom-right-radius'] === undefined ? '0' : item['border-bottom-right-radius']"
                                                                [style.borderWidth.px]="(item['border-width']===null||item['border-width']==='')?0:item['border-width']"
                                                                [style.fontSize]="item['button-font-size']"
                                                                [style.fontFamily]="item['font-family']"
                                                                [style.borderStyle]="item['border-style']"
                                                                [style.borderColor]="item['border-color']"
                                                                [style.Color]="item['button-color']"
                                                                [style.backgroundColor]="item['button-background-color']"
                                                                style="letter-spacing:inherit;text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;">
                                                                <em [class]="item['icon_name']"
                                                                    [style.Color]="item['icon-color']"
                                                                    [style.paddingTop.px]="item['icon-padding-top']"
                                                                    [style.paddingBottom.px]="item['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="item['icon-padding-left']"
                                                                    [style.paddingRight.px]="item['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="item['icon-size']"
                                                                    *ngIf="item['icon-align'] === undefined || item['icon-align'] !== 'right'"></em>
                                                                {{item.value}}
                                                                <em [class]="item['icon_name'].replace('mr','ml')"
                                                                    [style.Color]="item['icon-color']"
                                                                    [style.paddingTop.px]="item['icon-padding-top']"
                                                                    [style.paddingBottom.px]="item['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="item['icon-padding-left']"
                                                                    [style.paddingRight.px]="item['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="item['icon-size']"
                                                                    *ngIf="item['icon-align'] !== undefined && item['icon-align'] === 'right'"></em>
                                                            </button>
                                                        </div><br>
                                                    </div>
                                                    <div class="carousel-hc-container_second"
                                                        [style.marginTop.px]="content['sub_styles']['margin-top']"
                                                        [style.marginBottom.px]="content['sub_styles']['margin-bottom']">
                                                        <div [style.width.%]="content.styles['carousel-main-width'] === 'undefined'?'100':content.styles['carousel-main-width']"
                                                            [style.textAlign]="content.styles['text-align'] === 'undefined'?'center':content.styles['text-align']"
                                                            [ngStyle]="getStylesType(row,column,content,content.styles,'Carousel-Main')">
                                                            {{item.mainText}}</div><br>
                                                    </div>
                                                    <div class="carousel-hc-container_third"
                                                        [style.marginTop.px]="content['sub_styles']['margin-top']"
                                                        [style.marginBottom.px]="content['sub_styles']['margin-bottom']">
                                                        <div [style.width.%]="content.styles['carousel-sub-width'] === 'undefined'?'100':content.styles['carousel-sub-width']"
                                                            [style.textAlign]="content.styles['text-align'] === 'undefined'?'center':content.styles['text-align']"
                                                            [ngStyle]="getStylesType(row,column,content,content.sub_styles,'Carousel-Sub')">
                                                            {{item.subText}}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </slide>
                                    </carousel>

                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Cart'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Button')"
                                    style="position:absolute;">
                                    <app-cartbutton [content]="content"></app-cartbutton>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingCart'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Button')"
                                    style="position:absolute;">
                                    <app-bookingcartbutton [content]="content"></app-bookingcartbutton>
                                </div>
                            </ng-container>
                            <!-- Account Button control for ecommerce -->
                            <ng-container *ngIf="content.type==='Account'">
                                <div [ngStyle]="getMarginTopLeft(row,content.styles)" style="position:absolute;"
                                    class="p-0">
                                    <app-accountbutton [content]="content"></app-accountbutton>
                                </div>
                            </ng-container>
                            <!-- Login control for ecommerce -->
                            <ng-container *ngIf="content.type==='Login'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                    style="overflow:hidden;">
                                    <app-weblogin [content]="content" [websiteName]="WebsiteName"></app-weblogin>
                                </div>
                            </ng-container>

                            <!-- Registration control for ecommerce -->
                            <ng-container *ngIf="content.type==='Registration'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                    style="overflow:hidden;">
                                    <app-webregister [content]="content" [websiteName]="WebsiteName"></app-webregister>
                                </div>
                            </ng-container>
                            <!-- Profile control for ecommerce -->
                            <ng-container *ngIf="content.type==='Profile'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                    style="overflow:hidden;">
                                    <app-customerprofile [props]="content" [row]="row"></app-customerprofile>
                                </div>
                            </ng-container>
                            <!-- Change Password control for ecommerce -->
                            <ng-container *ngIf="content.type==='ChangePassword'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                    style="overflow:hidden;">
                                    <app-changepassword [props]="content" [row]="row"></app-changepassword>
                                </div>
                            </ng-container>
                            <!-- Search Button control for ecommerce -->
                            <ng-container *ngIf="content.type==='Search'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Button')"
                                    style="text-align: left" class="p-0">
                                    <app-searchbutton [content]="content"></app-searchbutton>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingSearch'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Button')"
                                    style="text-align: left" class="p-0">
                                    <app-bookingsearchbutton [content]="content"></app-bookingsearchbutton>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Listing'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                    style="text-align: left" class="p-0">
                                    <app-product-listing [content]="content" [row]="row"></app-product-listing>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingListing'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                    style="text-align: left" class="p-0">
                                    <app-bookinglisting [content]="content" [row]="row"></app-bookinglisting>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='SearchProducts'">
                                <app-all-products [row]="row" [content]="content"></app-all-products>
                            </ng-container>
                            <ng-container *ngIf="content.type==='SearchServices'">
                                <app-all-services [row]="row" [content]="content"></app-all-services>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Products' && content.items.length > 0">
                                <app-categoryproducts [row]="row" [content]="content"></app-categoryproducts>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingServices' && content.items.length > 0">
                                <app-categoryservices [row]="row" [content]="content"></app-categoryservices>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BrandProducts' && content.items.length > 0">
                                <app-brandproducts [row]="row" [content]="content"></app-brandproducts>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Product' && productInfo.ID !== undefined">
                                <app-productinfo [row]="row" [props]="content" [info]="productInfo"
                                    [designPage]="designPreview"></app-productinfo>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Product' && productInfo.ID === undefined">
                                <app-productinfo [row]="row" [props]="content" [info]="productInfo"
                                    [designPage]="designPreview"></app-productinfo>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingService' && productInfo.ID !== undefined">
                                <app-bookingserviceinfo [row]="row" [props]="content" [info]="productInfo"
                                    [calendarid]="calendarId" [designPage]="designPreview"></app-bookingserviceinfo>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingService' && productInfo.ID === undefined">
                                <app-bookingserviceinfo [row]="row" [props]="content" [info]="productInfo"
                                    [designPage]="designPreview"></app-bookingserviceinfo>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Checkout'">
                                <app-checkout [row]="row" [props]="content" [designPage]="0"></app-checkout>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingCheckout'">
                                <app-bookingcheckout [row]="row" [props]="content" [designPage]="0">
                                </app-bookingcheckout>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingCalender'">
                                <app-bookingcalender [row]="row" [content]="content"></app-bookingcalender>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Orders'">
                                <app-customerorders [row]="row" [props]="content"></app-customerorders>
                            </ng-container>
                            <ng-container *ngIf="content.type==='BookingOrders'">
                                <app-bookingorders [row]="row" [props]="content"></app-bookingorders>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Wishlist'">
                                <app-wishlist [row]="row" [props]="content"></app-wishlist>
                            </ng-container>
                            <ng-container *ngIf="content.type==='MenuGroup'">
                                <div id="hamburgerbtn"
                                    style="z-index: 20001;position: absolute;padding: 0px !important;"
                                    [style.marginLeft]="getLeftMargin(menuContent.styles,row)"
                                    [style.marginTop]="menuContent.styles['margin-top']"
                                    (click)="showHamburgerMenu($event)"
                                    [ngStyle]="getStylesType({},{},menuContent,menuContent['hamburger-styles'],'MobileMenuGroup')"
                                    *ngIf="window_width < 700 && menuContent.menus !== undefined && menuContent.menus.length > 0">
                                    <i
                                        [class]="menuContent['hamburger-styles'] === undefined ? 'fas fa-bars' : menuContent['hamburger-styles']['icon_name']"></i>
                                </div>
                                <!-- desktop menu -->
                                <div class="ds-row menu-group humble-pb-menu-group " *ngIf="window_width > 700"
                                    style="position: absolute;width:fit-content;padding: 0px !important;"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,'MenuGroupPrimary')">
                                    <div *ngFor="let menu of content.menus; let menuIdx = index">
                                        <div (click)="loadPage(menu)"
                                            *ngIf="menu['has_sub_menu'] !== undefined && !menu['has_sub_menu']">
                                            <li [ngClass]="{'humble-hover-dyn':content.styles['hover-available']}"
                                                style="list-style-type: none;cursor:pointer;"
                                                id="{{content.id}}{{menuIdx}}"
                                                [ngStyle]="getStylesType(row,column,content,content.styles,'MenuGroup')">
                                                <a [style.textDecoration]="content.styles['text-decoration']">
                                                    <em [class]="menu['icon_name'].replace('mr','ml')"
                                                        style="vertical-align:middle;"
                                                        [style.fontSize]="menu['icon-size']"
                                                        [style.Color]="menu['icon-color']"
                                                        [style.paddingTop.px]="menu['icon-padding-top']"
                                                        [style.paddingBottom.px]="menu['icon-padding-bottom']"
                                                        [style.paddingLeft.px]="menu['icon-padding-left']"
                                                        [style.paddingRight.px]="menu['icon-padding-right']"
                                                        *ngIf="menu['icon_name'] !== undefined && (menu['icon-align'] === undefined || menu['icon-align'] === 'left')"></em>
                                                    <label *ngIf="menu['color']!==undefined && menu['color']!==''"
                                                        id="lbl_{{content.id}}{{menuIdx}}"
                                                        [style.color]="menu['color']">{{stripHTML(menu.value)}}</label>
                                                    <label style="cursor:pointer;"
                                                        *ngIf="menu['color']===undefined || (menu['color']!==undefined && menu['color']==='')"
                                                        id="lbl_{{content.id}}{{menuIdx}}"
                                                        [style.color]="content.styles['color']">{{stripHTML(menu.value)}}</label>
                                                    <em [class]="menu['icon_name'].replace('mr','ml')"
                                                        style="vertical-align:middle;"
                                                        [style.fontSize]="menu['icon-size']"
                                                        [style.Color]="menu['icon-color']"
                                                        [style.paddingTop.px]="menu['icon-padding-top']"
                                                        [style.paddingBottom.px]="menu['icon-padding-bottom']"
                                                        [style.paddingLeft.px]="menu['icon-padding-left']"
                                                        [style.paddingRight.px]="menu['icon-padding-right']"
                                                        *ngIf="menu['icon_name'] !== undefined && menu['icon-align'] !== undefined && menu['icon-align'] === 'right'"></em>
                                                </a>
                                            </li>
                                        </div>
                                        <div class="has-dropdown" id="{{content.id}}{{menuIdx}}"
                                            [ngClass]="{'humble-hover-dyn':content.styles['hover-available']}"
                                            [ngStyle]="getStylesType(row,column,content,content.styles,'MenuGroup')"
                                            *ngIf="menu['has_sub_menu'] !== undefined && menu['has_sub_menu']">
                                            <em [class]="menu['icon_name'].replace('mr','ml')"
                                                style="vertical-align:middle;" [style.fontSize]="menu['icon-size']"
                                                [style.Color]="menu['icon-color']"
                                                [style.paddingTop.px]="menu['icon-padding-top']"
                                                [style.paddingBottom.px]="menu['icon-padding-bottom']"
                                                [style.paddingLeft.px]="menu['icon-padding-left']"
                                                [style.paddingRight.px]="menu['icon-padding-right']"
                                                *ngIf="menu['icon_name'] !== undefined && (menu['icon-align'] === undefined || menu['icon-align'] === 'left')"></em>
                                            <label *ngIf="menu['color']!==undefined && menu['color']!==''"
                                                id="lbl_{{content.id}}{{menuIdx}}"
                                                [style.color]="menu['color']">{{stripHTML(menu.value)}}</label>
                                            <label
                                                *ngIf="menu['color']===undefined || (menu['color']!==undefined && menu['color']==='')"
                                                id="lbl_{{content.id}}{{menuIdx}}"
                                                [style.color]="content.styles['color']">{{stripHTML(menu.value)}}</label>
                                            <em [class]="menu['icon_name'].replace('mr','ml')"
                                                style="vertical-align:middle;" [style.fontSize]="menu['icon-size']"
                                                [style.Color]="menu['icon-color']"
                                                [style.paddingTop.px]="menu['icon-padding-top']"
                                                [style.paddingBottom.px]="menu['icon-padding-bottom']"
                                                [style.paddingLeft.px]="menu['icon-padding-left']"
                                                [style.paddingRight.px]="menu['icon-padding-right']"
                                                *ngIf="menu['icon_name'] !== undefined && menu['icon-align'] !== undefined && menu['icon-align'] === 'right'"></em>
                                            <div class="sub-menu"
                                                [ngStyle]="getSubMenuStyles(content.sub_menu_styles,'')">
                                                <div *ngFor="let submenu of menu['subMenus']"
                                                    (click)="loadPage(submenu)">
                                                    {{submenu.value}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Map'">
                                <agm-map #agmmap *ngIf="window_width < 700"
                                    [style.height.px]="row.styles.height ==='100%' ? 250 : (row.styles.height > 600 ? 600: row.styles.height)"
                                    [latitude]="content['markers']!==undefined?content['markers'][0]['latitude']:content['map']['latitude']"
                                    [longitude]="content['markers']!==undefined?content['markers'][0]['longitude']:content['map']['longitude']"
                                    [zoom]="content['map']['zoom']" [fitBounds]="true"
                                    [mapTypeId]="content['map']['type'] !== undefined ? content['map']['type'] : 'roadmap'"
                                    [mapDraggable]="true" [disableDoubleClickZoom]="false">
                                    <ng-container *ngIf="content['markers']!==undefined">
                                        <ng-container *ngFor="let marker of content['markers']">
                                            <agm-marker [latitude]="marker['latitude']"
                                                [iconUrl]="'../assets/img/dummy.png'" [longitude]="marker['longitude']"
                                                [agmFitBounds]="true"
                                                *ngIf="marker['longitude'] !== undefined && marker['longitude'] !== null">
                                                <agm-info-window>
                                                    <strong>{{marker.label}}</strong>
                                                </agm-info-window>
                                                <agm-overlay [latitude]="marker['latitude']"
                                                    [longitude]="marker['longitude']"
                                                    *ngIf="marker['longitude'] !== undefined && marker['longitude'] !== null">
                                                    <i [class]="marker['icon_name'] === undefined || marker['icon_name'] === '' ? 'fas fa-map-marker' : marker['icon_name']"
                                                        [style.Color]="marker['icon-color'] === undefined ? 'red' : marker['icon-color']"
                                                        [style.fontSize]="marker['icon-size']"
                                                        style="height: 32px; width: 32px;"></i>
                                                    <agm-info-window>
                                                        <strong>{{marker.label}}</strong>
                                                    </agm-info-window>
                                                </agm-overlay>
                                            </agm-marker>
                                        </ng-container>
                                    </ng-container>
                                </agm-map>
                                <agm-map #agmmap *ngIf="window_width > 700"
                                    [style.height.px]="rowContainerComponent.offsetHeight"
                                    [latitude]="content['markers']!==undefined?content['markers'][0]['latitude']:content['map']['latitude']"
                                    [longitude]="content['markers']!==undefined?content['markers'][0]['longitude']:content['map']['longitude']"
                                    [zoom]="content['map']['zoom']" [fitBounds]="true"
                                    [styles]="content['map']['style'] !== undefined ? agmStyles[content['map']['style']] : agmStyles['default']"
                                    [mapTypeId]="content['map']['type'] !== undefined ? content['map']['type'] : 'roadmap'"
                                    [mapDraggable]="true" [disableDoubleClickZoom]="false">
                                    <ng-container *ngIf="content['markers']!==undefined">
                                        <ng-container *ngFor="let marker of content['markers']">
                                            <agm-marker [latitude]="marker['latitude']"
                                                [iconUrl]="'../assets/img/dummy.png'" [longitude]="marker['longitude']"
                                                [agmFitBounds]="true"
                                                *ngIf="marker['longitude'] !== undefined && marker['longitude'] !== null">
                                                <agm-info-window>
                                                    <strong>{{marker.label}}</strong>
                                                </agm-info-window>
                                                <agm-overlay [latitude]="marker['latitude']"
                                                    [longitude]="marker['longitude']"
                                                    *ngIf="marker['longitude'] !== undefined && marker['longitude'] !== null">
                                                    <i [class]="marker['icon_name'] === undefined || marker['icon_name'] === '' ? 'fas fa-map-marker' : marker['icon_name']"
                                                        [style.Color]="marker['icon-color'] === undefined ? 'red' : marker['icon-color']"
                                                        [style.fontSize]="marker['icon-size']"
                                                        style="height: 32px; width: 32px;"></i>
                                                    <agm-info-window>
                                                        <strong>{{marker.label}}</strong>
                                                    </agm-info-window>
                                                </agm-overlay>
                                            </agm-marker>
                                        </ng-container>
                                    </ng-container>
                                </agm-map>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Forms'">
                                <div style="position:absolute;"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                    <form [id]="content.id">
                                        <div class="p-1">
                                            <div class="row mr-0 ml-0">
                                                <ng-container *ngFor="let field of content['form']['fields']">
                                                    <div [id]="field.ID" class="drop-item"
                                                        [class]="designType==='MOBILE' ? 'column-12':
                                                    (designType==='DESKTOP' ? field['Class'] : (field['Tablet_Class'] !== undefined ? field['Tablet_Class'] : 'column-12'))"
                                                        [style.paddingLeft.px]="content['styles']['padding-left']"
                                                        [style.paddingRight.px]="content['styles']['padding-right']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                        *ngIf="field['DataType']!=='STATEMENT'">
                                                        <div [id]="field.ID" class="text-left"
                                                            *ngIf="content['form']['show_label']">
                                                            <label
                                                                [style.paddingTop.px]="content['label_styles']['padding-top']"
                                                                [style.paddingBottom.px]="content['label_styles']['padding-bottom']">
                                                                {{field['Question']}}<mark
                                                                    class="red">{{field['IsMandatory']==='1' ? ' *' :
                                                                    ''}}</mark></label>
                                                            <br />
                                                        </div>

                                                        <ngb-rating class="float-left" [max]="5"
                                                            [(rate)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined 
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            *ngIf="field['DataType']==='RATING'"></ngb-rating>

                                                        <div class="w-100 pt-1 pb-1" style="height:50px;"
                                                            *ngIf="field['DataType']==='SCALE'">
                                                            <ng-container *ngFor="let scale of opinionScale;">
                                                                <div class="opnscale"
                                                                    (click)="setOpinionScale(content,field,scale)"
                                                                    [ngClass]="{'bg-info':pageValues[content['form']['ID']][field['Field_Name']]!==scale,
                                                                'bg-success':pageValues[content['form']['ID']][field['Field_Name']]===scale}">
                                                                    {{scale}}</div>
                                                            </ng-container>
                                                        </div>

                                                        <input type="text"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['Description'] : ''"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined 
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='TINY_STRING' || field['DataType']==='EMAIL'" />
                                                        <input type="number"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['Description'] : ''"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined 
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DECIMAL' || field['DataType']==='INTEGER'">
                                                        <input type="date"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined 
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DATE'">
                                                        <input type="datetime-local"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined 
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DATETIME'">
                                                        <div class="d-flex align-items-left"
                                                            *ngIf="field['DataType']==='BIT'">
                                                            <div class="">
                                                                <label class="switch switch-lg switch-form">
                                                                    <input type="checkbox" [name]="field['Field_Name']"
                                                                        checked="checked"
                                                                        [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]" />
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <select type="text"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DROPDOWN' &&
                                                            (field['Dropdown_Type']===undefined || field['Dropdown_Type']==='Dropdown')">
                                                            <option value="">{{field['Question']}}</option>
                                                            <option [value]="obj.value"
                                                                *ngFor="let obj of staticValues[field['ID']]">
                                                                {{obj.value}}</option>
                                                        </select>
                                                        <ng-container
                                                            *ngIf="field['DataType']==='DROPDOWN' && 
                                                        (field['Dropdown_Type'] !== undefined && field['Dropdown_Type']==='Button')">
                                                            <button class="float-left mb-1 mr-1" appNoDblClick
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'].replace('input','button') : 'button-form-control'"
                                                                [style]="getFormButtonStyle(content['input_styles'], pageValues[content['form']['ID']][field['Field_Name']]===obj.value)"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                *ngFor="let obj of staticValues[field['ID']]"
                                                                (click)="pageValues[content['form']['ID']][field['Field_Name']]=obj.value">
                                                                {{obj.value}}
                                                            </button>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="field['DataType']==='DROPDOWN' && 
                                                        (field['Dropdown_Type'] !== undefined && field['Dropdown_Type']==='Radio')">
                                                            <label class="float-left mb-1 mr-2"
                                                                style="text-align: left!important;justify-content:left !important;"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [style.marginTop.px]="10"
                                                                *ngFor="let obj of staticValues[field['ID']]">
                                                                <input type="radio" name="field['Field_Name']"
                                                                    class="mr-1"
                                                                    [checked]="obj.value === pageValues[content['form']['ID']][field['Field_Name']]"
                                                                    (click)="pageValues[content['form']['ID']][field['Field_Name']]=obj.value">{{obj.value}}
                                                            </label>
                                                        </ng-container>
                                                        <textarea rows="3"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['Description'] : ''"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && content['form']['submitted'] !== undefined 
                                                            && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT'"></textarea>
                                                    </div>
                                                </ng-container>
                                                <div class="w-100 pt-3 pb-1"
                                                    *ngIf="content['form']['fields'].length > 0" [style.fontSize]="content['submit_styles'] !== undefined && content['submit_styles']['font-size'] !== undefined ? 
                                                    content['submit_styles']['font-size'] : '16px'"
                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                    [style.textAlign]="content['submit_styles'] !== undefined ? content['submit_styles']['text-align'] : 'left'">
                                                    <button class="btn btn-form" [ngStyle]="getSubmitStyles(content)"
                                                        (click)="formSubmit($event,content['form']['ID'],content['form']['fields'],content)"
                                                        [ngClass]="{'btn-sm': (content['styles']['input-control-class']==='input-form-control-sm'),
                                                        'btn-lg': (content['styles']['input-control-class']==='input-form-control-lg')}">
                                                        {{content['submit_styles']['value'] !== undefined ?
                                                        content['submit_styles']['value'] : 'Submit'}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='TypeForm'">
                                <div style="position:absolute;"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                    <form [id]="content.id">
                                        <div class="row mr-0 ml-0" *ngIf="content['form']['fields'].length > 0">
                                            <ng-container
                                                *ngFor="let field of content['form']['fields']; let fieldIdx = index">
                                                <ng-template
                                                    [ngIf]="content['form']['status']==='Pending' && field.selected">
                                                    <div [id]="field.ID" class="drop-item" [class]="field['class']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                        <div [id]="field.ID" class="text-left"
                                                            *ngIf="content['form']['show_label']">
                                                            <label
                                                                [style.paddingTop.px]="content['label_styles']['padding-top']"
                                                                [style.paddingBottom.px]="content['label_styles']['padding-bottom']">
                                                                {{field['Question']}}<mark
                                                                    class="red">{{field['IsMandatory']==='1' ? ' *' :
                                                                    ''}}</mark></label>
                                                            <br />
                                                        </div>
                                                        <div class="text-left"
                                                            [style.paddingTop.px]="content['label_styles']['padding-top']"
                                                            [style.paddingBottom.px]="content['label_styles']['padding-bottom']"
                                                            *ngIf="content['form']['show_helptext'] && field['Description']!==''">
                                                            <small>{{field['Description']}}</small>
                                                        </div>

                                                        <ngb-rating class="float-left" [max]="5"
                                                            [(rate)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                        || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            *ngIf="field['DataType']==='RATING'"></ngb-rating>

                                                        <div class="w-100 pt-1 pb-1" style="height:50px;"
                                                            *ngIf="field['DataType']==='SCALE'">
                                                            <ng-container *ngFor="let scale of opinionScale;">
                                                                <div class="opnscale"
                                                                    (click)="setOpinionScale(content,field,scale)"
                                                                    [ngClass]="{'bg-info':pageValues[content['form']['ID']][field['Field_Name']]!==scale,
                                                                'bg-success':pageValues[content['form']['ID']][field['Field_Name']]===scale}">
                                                                    {{scale}}</div>
                                                            </ng-container>
                                                        </div>

                                                        <input type="text"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='TINY_STRING' || field['DataType']==='EMAIL'" />
                                                        <input type="number"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DECIMAL' || field['DataType']==='INTEGER'">
                                                        <input type="date"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DATE'">
                                                        <input type="datetime-local"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DATETIME'">
                                                        <div class="d-flex align-items-left"
                                                            *ngIf="field['DataType']==='BIT'">
                                                            <div class="">
                                                                <label class="switch switch-lg switch-form">
                                                                    <input type="checkbox" [name]="field['Field_Name']"
                                                                        checked="checked"
                                                                        [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]" />
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <select type="text"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='DROPDOWN' &&
                                                            (field['Dropdown_Type']===undefined || field['Dropdown_Type']==='Dropdown')">
                                                            <option [value]="obj.value"
                                                                *ngFor="let obj of staticValues[field['ID']]">
                                                                {{obj.value}}</option>
                                                        </select>
                                                        <div class="row mr-0 ml-0"
                                                            *ngIf="field['DataType']==='DROPDOWN' && 
                                                        (field['Dropdown_Type'] !== undefined && field['Dropdown_Type']==='Button')">
                                                            <div class="column-12">
                                                                <button class="float-left mb-1 mr-1" appNoDblClick
                                                                    [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'].replace('input','button') : 'button-form-control'"
                                                                    [style]="getFormButtonStyle(content['input_styles'], pageValues[content['form']['ID']][field['Field_Name']]===obj.value)"
                                                                    [style.fontSize]="content['styles']['input-font-size']"
                                                                    *ngFor="let obj of staticValues[field['ID']]"
                                                                    (click)="pageValues[content['form']['ID']][field['Field_Name']]=obj.value">
                                                                    {{obj.value}}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="row mr-0 ml-0"
                                                            *ngIf="field['DataType']==='DROPDOWN' && 
                                                        (field['Dropdown_Type'] !== undefined && field['Dropdown_Type']==='Radio')">
                                                            <div class="column-12">
                                                                <label class="float-left mr-2"
                                                                    style="text-align: left!important;justify-content:left !important;"
                                                                    [style.fontSize]="content['styles']['input-font-size']"
                                                                    [style.marginTop.px]="10"
                                                                    *ngFor="let obj of staticValues[field['ID']]">
                                                                    <input type="radio" name="field['Field_Name']"
                                                                        class="mr-1"
                                                                        [checked]="obj.value === pageValues[content['form']['ID']][field['Field_Name']]"
                                                                        (click)="pageValues[content['form']['ID']][field['Field_Name']]=obj.value">{{obj.value}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <textarea rows="3"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [required]="field['IsMandatory']"
                                                            [ngClass]="{'is-invalid':(field['IsMandatory'] && (pageValues[content['form']['ID']][field['Field_Name']]===null 
                                                            || pageValues[content['form']['ID']][field['Field_Name']]===''))}"
                                                            [(ngModel)]="pageValues[content['form']['ID']][field['Field_Name']]"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            *ngIf="field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT'"></textarea>


                                                        <div class="w-100 pt-1 pb-1" [style.fontSize]="content['submit_styles'] !== undefined && content['submit_styles']['font-size'] !== undefined ? 
                                                        content['submit_styles']['font-size'] : '16px'"
                                                            [style.textAlign]="content['submit_styles']['text-align']"
                                                            *ngIf="showTypeFormButton(content['form']['no_of_questions'],fieldIdx+1, content['form']['fields'].length)">
                                                            <button class="btn mt-3 btn-form"
                                                                *ngIf="field['slno']===field['maxslno']"
                                                                [style.textDecoration]="content['submit_styles']['text-decoration']"
                                                                [style.fontStyle]="content['submit_styles']['font-style']"
                                                                [style.fontSize]="content['submit_styles']['font-size']!==undefined?content['submit_styles']['font-size']:'16px'"
                                                                [style.fontFamily]="content['submit_styles']['font-family']!==undefined?content['submit_styles']['font-family']:'inherit'"
                                                                [style.fontWeight]="content['submit_styles']['font-weight']"
                                                                [style.color]="content['submit_styles']['color']"
                                                                [style.backgroundColor]="content['submit_styles']['background-color']"
                                                                [ngClass]="{'btn-sm': (content['styles']['input-control-class']==='input-form-control-sm'),
                                                            'btn-lg': (content['styles']['input-control-class']==='input-form-control-lg')}"
                                                                (click)="formSubmit($event,content['form']['ID'],content['form']['fields'],content)">Submit</button>
                                                            <button class="btn mt-3 btn-form"
                                                                *ngIf="field['slno']!==field['maxslno']"
                                                                [style.textDecoration]="content['ok_styles']['text-decoration']"
                                                                [style.fontStyle]="content['ok_styles']['font-style']"
                                                                [style.fontSize]="content['submit_styles']['font-size']!==undefined?content['submit_styles']['font-size']:'16px'"
                                                                [style.fontFamily]="content['submit_styles']['font-family']!==undefined?content['submit_styles']['font-family']:'inherit'"
                                                                [style.fontWeight]="content['ok_styles']['font-weight']"
                                                                [style.color]="content['ok_styles']['color']"
                                                                [style.backgroundColor]="content['ok_styles']['background-color']"
                                                                [ngClass]="{'btn-sm': (content['styles']['input-control-class']==='input-form-control-sm'),
                                                            'btn-lg': (content['styles']['input-control-class']==='input-form-control-lg')}"
                                                                (click)="showNext(content['form']['ID'],content['form']['fields'],content['form']['no_of_questions'])">Ok
                                                                <i class="ml-2 fas fa-check"></i></button>
                                                        </div>

                                                        <div class="w-100 pt-1 pb-1"
                                                            *ngIf="content['form']['status']==='Pending'">
                                                            <button class="btn float-right" title="Next Question"
                                                                (click)="showNext(content['form']['ID'],content['form']['fields'],content['form']['no_of_questions'])"
                                                                [style.color]="content['navig_styles']['color']"
                                                                [style.backgroundColor]="content['navig_styles']['background-color']"
                                                                [ngClass]="{'btn-sm': (content['styles']['input-control-class']==='input-form-control-sm'),
                                                            'btn-lg': (content['styles']['input-control-class']==='input-form-control-lg')}">
                                                                <i class="fas fa-angle-down"></i>
                                                            </button>
                                                            <button class="btn float-right mr-1"
                                                                title="Previous Question"
                                                                (click)="showPrev(content['form']['ID'],content['form']['fields'],content['form']['no_of_questions'])"
                                                                [style.color]="content['navig_styles']['color']"
                                                                [style.backgroundColor]="content['navig_styles']['background-color']"
                                                                [ngClass]="{'btn-sm': (content['styles']['input-control-class']==='input-form-control-sm'),
                                                            'btn-lg': (content['styles']['input-control-class']==='input-form-control-lg')}">
                                                                <i class="fas fa-angle-up"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-container>

                                        </div>
                                    </form>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Section'">
                                <div class="card" [ngClass]="content['form']['theme']">
                                    <div class="card-header">
                                        <div
                                            [ngStyle]="getStyles(row,column,content,content['form']['title']['styles'])">
                                            {{content['form']['title']['value']}}
                                            <ng-template ngFor let-field [ngForOf]="content['form']['fields']"
                                                let-index="attindex">
                                                <button class="btn btn-xs float-right ml-1" type="button" appNoDblClick
                                                    [id]="'imgAttach'+field.Field_ID" (click)="loadAttachment(field)"
                                                    [ngClass]="{ 'btn-light': (attachments[field.Field_ID] === 0),'btn-success': (attachments[field.Field_ID] === 1)}"
                                                    *ngIf="field.DataType === 'ATTACHMENT'"
                                                    style="margin-left:5px;float:right;">
                                                    {{field.Display_Name}} ({{ attachments[field.Field_ID] }})
                                                </button>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row" *ngIf="!loading">
                                            <ng-container *ngFor="let field of content['form']['fields']">
                                                <div [class]="field['class']" *ngIf="field['DataType']!=='ATTACHMENT'"
                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                    <div class="text-left" *ngIf="content['form']['show_label']">
                                                        <label
                                                            [ngStyle]="getStyles(row,column,content,field['styles'])">{{field['Display_Name']}}{{field['is_Mandatory']?'
                                                            *':''}}</label>
                                                    </div>

                                                    <select type="text" class="form-control"
                                                        [name]="field['Field_Name']"
                                                        [ngClass]="{'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                        [required]="field['is_Mandatory']"
                                                        [(ngModel)]="pageValues[field['Field_ID']]"
                                                        *ngIf="field['is_Static_Value']===true">
                                                        <option [value]="obj.value"
                                                            *ngFor="let obj of staticValues[field['Field_ID']]">
                                                            {{obj.text}}</option>
                                                    </select>

                                                    <span class="inputContainer" *ngIf="field['is_Lookup']===true">
                                                        <input type="text" class="form-control"
                                                            [name]="field['Field_Name']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['Display_Name'] : ''"
                                                            [ngClass]="{'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            [required]="field['is_Mandatory']"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [placeholder]="field['value']" />
                                                        <a class="linkInsideInput" (click)="showLookup(field)">
                                                            <i class="fas fa-search"></i>
                                                        </a>
                                                    </span>

                                                    <input type="text" class="form-control" [name]="field['Field_Name']"
                                                        [placeholder]="field['value']"
                                                        [(ngModel)]="pageValues[field['Field_ID']]"
                                                        [ngClass]="{'form-control':true, 
                                                    'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                        [required]="field['is_Mandatory']"
                                                        [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                    && content['form']['show_placeholder'] === true ? field['Display_Name'] : ''" *ngIf="(field['is_Static_Value']!==true && field['is_Lookup']!==true && field['is_Hidden']!==true) 
                                                    && (field['DataType']==='TINY_STRING')" />

                                                    <input type="email" class="form-control"
                                                        [name]="field['Field_Name']" [placeholder]="field['value']"
                                                        [(ngModel)]="pageValues[field['Field_ID']]"
                                                        [ngClass]="{'form-control':true, 
                                                    'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                        [required]="field['is_Mandatory']"
                                                        [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                    && content['form']['show_placeholder'] === true ? field['Display_Name'] : ''" *ngIf="(field['is_Static_Value']!==true && field['is_Lookup']!==true && field['is_Hidden']!==true) 
                                                    && (field['DataType']==='EMAIL')" />

                                                    <input type="number" class="form-control"
                                                        [name]="field['Field_Name']"
                                                        [ngClass]="{'form-control':true, 
                                                    'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                        [required]="field['is_Mandatory']"
                                                        [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                    && content['form']['show_placeholder'] === true ? field['Display_Name'] : ''"
                                                        [(ngModel)]="pageValues[field['Field_ID']]"
                                                        [placeholder]="field['value']"
                                                        *ngIf="field['DataType']==='DECIMAL' || field['DataType']==='INTEGER'" />

                                                    <input type="date" class="form-control" [name]="field['Field_Name']"
                                                        [ngClass]="{'form-control':true, 
                                                    'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                        [required]="field['is_Mandatory']"
                                                        [(ngModel)]="pageValues[field['Field_ID']]"
                                                        [placeholder]="field['value']"
                                                        *ngIf="field['DataType']==='DATE'" />

                                                    <textarea rows="3" class="form-control" [name]="field['Field_Name']"
                                                        [placeholder]="field['value']"
                                                        [(ngModel)]="pageValues[field['Field_ID']]"
                                                        [ngClass]="{'form-control':true, 
                                                    'is-invalid':(field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                        [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['Display_Name'] : ''"
                                                        *ngIf="field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT'"></textarea>

                                                    <input *ngIf="field['is_Hidden']===true"
                                                        [name]="field['Field_Name']" type="hidden"
                                                        [(ngModel)]="pageValues[field['Field_ID']]" />
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='StaticSection'">
                                <div [ngStyle]="getStylesType(row, column, content, content.styles, 'Forms')"
                                    style="text-align: left" class="jaiganesh p-0">
                                    <div class="pl-0 pt-1 pb-2">
                                        <div class="row mr-0 ml-0">
                                            <div class="col-md-12"
                                                [style.paddingLeft.px]="content['styles']['padding-left']"
                                                [style.paddingRight.px]="content['styles']['padding-right']"
                                                [style.paddingTop.px]="content['styles']['padding-top']"
                                                [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                <div class="float-left"
                                                    [style.fontSize]="content['header_styles']['font-size']"
                                                    [style.fontWeight]="content['header_styles']['font-weight']"
                                                    [style.fontFamily]="content['header_styles']['font-family']"
                                                    [style.fontStyle]="content['header_styles']['font-style']"
                                                    *ngIf="content['form']['show_header'] === undefined || content['form']['show_header']">
                                                    {{content['form']['header_text']}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pb-0 pt-0">
                                        <div class="row mr-0 ml-0" *ngIf="!loading">
                                            <ng-container *ngFor="let field of content['form']['fields']">
                                                <div [class]="field['class']" *ngIf="field['DataType']!=='ATTACHMENT' && field['DataType']!=='MARKING' && 
                                                    field['DataType']!=='DIGISIGN' && field['is_Hidden']!==true"
                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                    <div>
                                                        <div class="text-left" *ngIf="content['form']['show_label']">
                                                            <label
                                                                [style.paddingLeft.px]="content['label_styles']['padding-left']"
                                                                [style.paddingRight.px]="content['label_styles']['padding-right']"
                                                                [style.paddingTop.px]="content['label_styles']['padding-top']"
                                                                [style.paddingBottom.px]="content['label_styles']['padding-bottom']"
                                                                [ngClass]="{'input-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                    && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}">
                                                                {{field['value']}}{{field['is_Mandatory']?'
                                                                *':''}}</label>
                                                        </div>

                                                        <select [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            [required]="field['is_Mandatory']"
                                                            [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            (change)="onValueChange(field)"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            *ngIf="field['is_Static_Value']===true && (field['is_Radio_Button'] === undefined 
                                                                || field['is_Radio_Button']===false || field['is_Radio_Button']===null) && (field['is_Button'] === undefined 
                                                                || field['is_Button']===false || field['is_Button']===null)">
                                                            <option [value]="obj.value"
                                                                *ngFor="let obj of staticValues[field['Field_ID']]">
                                                                {{obj.text}}</option>
                                                        </select>

                                                        <ng-container
                                                            *ngIf="field['is_Static_Value']===true && field['is_Button']===true">
                                                            <button [name]="field['Field_Name']"
                                                                *ngFor="let obj of staticValues[field['Field_ID']]"
                                                                [ngStyle]="getStylesStaticType(column, content.styles,'StaticButton',obj.value === pageValues[field['Field_ID']])"
                                                                (click)="setPageValue(field,obj.value)"
                                                                [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                class="btn mr-2 mb-2"
                                                                [class]="content['styles']['static_button-style']"
                                                                appNoDblClick>
                                                                {{obj.text}}
                                                            </button>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="field['is_Static_Value']===true && field['is_Radio_Button']===true">
                                                            <label *ngFor="let obj of staticValues[field['Field_ID']]"
                                                                class="mr-2">
                                                                <input type="radio" [name]="field['Field_Name']"
                                                                    class="mr-1 mb-1"
                                                                    (click)="setPageValue(field,obj.value)"
                                                                    [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                    [checked]="obj.value === pageValues[field['Field_ID']]">{{obj.text}}
                                                            </label>
                                                        </ng-container>

                                                        <div class="input-group form-group"
                                                            *ngIf="field['is_Lookup']===true && field['DataType']==='TINY_STRING'">
                                                            <input type="text" [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content, content['input_styles'])"
                                                                [required]="field['is_Mandatory']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)" readonly="true"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                            <div class="input-group-append"
                                                                *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                <button class="btn btn-info btn-pill-right"
                                                                    type="button" (click)="showLookup(field)"
                                                                    [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <i [style.fontSize]="content['styles']['input-font-size']"
                                                                        class="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div class="input-group form-group"
                                                            *ngIf="field['is_Lookup']===true && (field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT')">
                                                            <textarea rows="3"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [style]="getInputStyle(content, content['input_styles'])"
                                                                [name]="field['Field_Name']"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                    && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [ngClass]="{'form-control':true, 
                                                                    'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"></textarea>
                                                            <div class="input-group-append"
                                                                *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                <button class="btn btn-info btn-pill-right"
                                                                    type="button" (click)="showLookup(field)">
                                                                    <i [style.fontSize]="content['styles']['input-font-size']"
                                                                        class="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div class="input-group form-group"
                                                            *ngIf="field['is_External_Value']===true 
                                                                && (field['DataType']==='TINY_STRING' || field['DataType']==='INTEGER' || field['DataType']==='DECIMAL')">
                                                            <input type="text" [name]="field['Field_Name']"
                                                                [required]="field['is_Mandatory']"
                                                                [style]="getInputStyle(content, content['input_styles'])"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)" readonly="true"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                    && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                            <div class="input-group-append"
                                                                *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                <button class="btn btn-info btn-pill-right"
                                                                    type="button" (click)="getAPIData(field)"
                                                                    [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <i [style.fontSize]="content['styles']['input-font-size']"
                                                                        class="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <input type="text" [name]="field['Field_Name']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                                && (field['DataType']==='TINY_STRING')" />


                                                        <input type="email" [email]="true"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [name]="field['Field_Name']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)" [pattern]="emailPattern"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                                && (field['DataType']==='EMAIL')" />

                                                        <input type="password"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [name]="field['Field_Name']"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                                && (field['DataType']==='PASSWORD')" />

                                                        <input type="number"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='INTEGER'" />

                                                        <input type="number"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='DECIMAL'" />

                                                        <input type="number"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            (blur)="validatePercent(field['Field_ID'])"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='PERCENTAGE'" />

                                                        <input type="date"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            *ngIf="field['DataType']==='DATE'" />

                                                        <input type="datetime-local"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            (change)="onValueChange(field)"
                                                            [required]="field['is_Mandatory']"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            *ngIf="field['DataType']==='DATETIME'" />

                                                        <textarea rows="3"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'form-control'"
                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                            [name]="field['Field_Name']"
                                                            [style]="getInputStyle(content, content['input_styles'])"
                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                            [(ngModel)]="pageValues[field['Field_ID']]"
                                                            [ngClass]="{'form-control':true, 
                                                                'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                            *ngIf="field['is_Lookup']!==true && (field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT')"
                                                            (change)="onValueChange(field)"
                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"></textarea>

                                                        <label class="switch switch-lg m-0 pl-2"
                                                            *ngIf="field['DataType']==='BIT'">
                                                            <input type="checkbox" [name]="field['Field_Name']"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                (change)="onValueChange(field)" checked="checked" />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <div class="text-left" *ngIf="content['form']['show_helptext']">
                                                        <small>{{field['help_text']}}</small>
                                                    </div>
                                                </div>
                                                <input *ngIf="field['is_Hidden']===true" [name]="field['Field_Name']"
                                                    type="hidden" [(ngModel)]="pageValues[field['Field_ID']]" />
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Table'">
                                <div class="card" [ngClass]="content['form']['theme']">
                                    <div class="card-header">
                                        <div
                                            [ngStyle]="getStyles(row,column,content,content['form']['title']['styles'])">
                                            {{content['form']['title']['value']}}
                                            <button class="btn btn-primary btn-small float-right ml-1"
                                                *ngIf="showSelect(content['form'])"
                                                (click)="selectMVLookup(content['form'],content['index'])">
                                                Select
                                            </button>
                                            <button class="btn btn-primary btn-small float-right"
                                                (click)="addRowClick(content['index'])">
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body"
                                        *ngIf="content['index'] !== undefined && content['index'] !== null 
                                    && grid['gridAdapter'+content['index']] !== undefined && grid['gridAdapter'+content['index']] !== null">
                                        <jqxGrid #myGrid [id]="content.index" [editable]="true" [width]="'100%'"
                                            [height]="'350px'" [source]="grid['gridAdapter'+content.index]"
                                            [columns]="grid['columns'+content.index]"
                                            [everpresentrowposition]="'bottom'" (onCellclick)="onCellclick($event)"
                                            (onCellvaluechanged)="onCellvaluechanged($event,content.index)"></jqxGrid>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Text'">
                                <div [id]="content.id + column.id">
                                    <div [id]="content.id"
                                        [ngStyle]="getStylesType(row,column,content,content.styles,'Text')"
                                        [ngClass]="{'humble-hover-dyn':content.styles['hover-available']}"
                                        style="opacity: 0;padding: 10px;">
                                        <em [class]="content.styles['icon_name']"
                                            [style.order]="content.styles['icon-align'] === 'right' ? 2 : 1"
                                            [style.fontSize]="content.styles['icon-size']"
                                            [style.Color]="content.styles['icon-color']"
                                            [style.paddingTop.px]="content.styles['icon-padding-top']"
                                            [style.paddingBottom.px]="content.styles['icon-padding-bottom']"
                                            [style.paddingLeft.px]="content.styles['icon-padding-left']"
                                            [style.paddingRight.px]="content.styles['icon-padding-right']"
                                            *ngIf="content.styles['icon_name'] !== undefined"></em>
                                        <div class="content mobile-text-size"
                                            [ngStyle]="getStylesType(row,column,content,content.styles,'TextArea')"
                                            [style.order]="content.styles['icon-align'] === 'right' ? 1 : 2"
                                            [ngClass]="{ 'text-max-size': getFontClass(content.styles['font-size']),'text-max-margin': getMarginClass(content.styles['margin-left'])}">
                                            <div class="text-content"
                                                [innerHtml]="content.htmlvalue === undefined ? getCFHTML(content) : content.htmlvalue">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='TextImage'">
                                <div [id]="content.id + column.id"
                                    [style.borderRadius.px]="content.styles['border-radius']"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,'TextImage')">
                                    <div class="content mobile-text-size" [id]="content.id"
                                        [ngStyle]="{'height':window_width < 700 ? content['styles']['height'] + 'px' : ''}"
                                        [ngClass]="{ 'text-max-size': getFontClass(content.styles['font-size']),'text-max-margin': getMarginClass(content.styles['margin-left'])}">

                                        <div class="row m-0"
                                            [style.borderTopLeftRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                            (content['text-styles'] === undefined || content['text-styles']['border-top-left-radius'] === undefined ? '0': content['text-styles']['border-top-left-radius']) : 
                                            (content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius'])"
                                            [style.borderTopRightRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                            (content['text-styles'] === undefined || content['text-styles']['border-top-right-radius'] === undefined ? '0' : content['text-styles']['border-top-right-radius']) : 
                                            (content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius'])"
                                            [style.borderBottomLeftRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                            (content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']) : 
                                            (content['text-styles'] === undefined || content['text-styles']['border-bottom-left-radius'] === undefined ? '0' : content['text-styles']['border-bottom-left-radius'])"
                                            [style.borderBottomRightRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                            (content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']) : 
                                            (content['text-styles'] === undefined || content['text-styles']['border-bottom-right-radius'] === undefined ? '0' : content['text-styles']['border-bottom-right-radius'])"
                                            [style.borderImageSource]="'url(' + content['border-image-url'] + ')'"
                                            [style.borderImageSlice]="content.styles['border-width']"
                                            [style.borderImageWidth.px]="content.styles['border-width']"
                                            [style.borderStyle]="content.styles['border-style']"
                                            [style.borderColor]="content.styles['border-color']"
                                            [style.borderWidth.px]="content.styles['border-width'] === undefined ? '0' : content.styles['border-width']"
                                            [ngStyle]="{'height':window_width > 700 ? content['styles']['height'] + 'px' : ''}"
                                            [style.width.px]="content['styles']['width']">
                                            <div class="col-12" style="padding-bottom:20px;"
                                                [style.borderTopLeftRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-top-left-radius'] === undefined ? '0' : content['text-styles']['border-top-left-radius']"
                                                [style.borderTopRightRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-top-right-radius'] === undefined ? '0' : content['text-styles']['border-top-right-radius']"
                                                [style.background]="content.styles['text-background-color']"
                                                *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'TOP'">
                                                <div [innerHtml]="getHTML(content.value)">
                                                </div>
                                            </div>
                                            <div class="col-12"
                                                *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'TOP'"
                                                [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                                [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                                [style.background]="content.styles['background-color']">
                                                <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                                    (mouseenter)="switchImage(content.id,true,content)"
                                                    (mouseleave)="switchImage(content.id,false,content)"
                                                    [style.height.%]="100"
                                                    [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                                    [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                                    [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                                    [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                                    [style.padding.px]="content['styles']['padding']"
                                                    [style.width.%]="100">
                                            </div>

                                            <div class="col-12"
                                                *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'BOTTOM'"
                                                [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                                [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                                [style.background]="content.styles['background-color']">
                                                <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                                    (mouseenter)="switchImage(content.id,true,content)"
                                                    (mouseleave)="switchImage(content.id,false,content)"
                                                    [style.height.%]="100"
                                                    [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                                    [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                                    [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                                    [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                                    [style.padding.px]="content['styles']['padding']"
                                                    [style.width.%]="100">
                                            </div>
                                            <div class="col-12"
                                                [style.borderBottomLeftRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-bottom-left-radius'] === undefined ? '0' : content['text-styles']['border-bottom-left-radius']"
                                                [style.borderBottomRightRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-bottom-right-radius'] === undefined ? '0' : content['text-styles']['border-bottom-right-radius']"
                                                [style.background]="content.styles['text-background-color']"
                                                *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'BOTTOM'">
                                                <div [innerHtml]="getHTML(content.value)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Lottie'">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                    <ng-lottie width="100%" height="100%" [options]="{path: content.url}"></ng-lottie>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Image'">
                                <div class="content" [id]="content.id"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,'Image')"
                                    [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                    [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                    [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                    [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                    [style.borderImageSource]="'url(' + content['border-image-url'] + ')'"
                                    [style.borderImageSlice]="content.styles['border-width']"
                                    [style.borderImageWidth.px]="content.styles['border-width']"
                                    [style.backgroundColor]="content.styles['image-opacity-color']"
                                    [style.borderStyle]="content.styles['border-style']"
                                    [style.borderColor]="content.styles['border-color']"
                                    [style.borderWidth.px]="(content.styles['border-width']===undefined||content.styles['border-width']===null||content.styles['border-width']==='')? '0' : content.styles['border-width']"
                                    [style.cursor]="(content.navigateUrl !== undefined && content.navigateUrl !== null && content.navigateUrl !== '')?'pointer':'default'"
                                    style="opacity:0;" (click)="loadPage(content)">
                                    <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                        (mouseenter)="switchImage(content.id,true,content)"
                                        (mouseleave)="switchImage(content.id,false,content)"
                                        [style.padding.px]="content['styles']['padding']"
                                        [style.objectFit]="content['styles']['image-cover'] ? 'cover':content['styles']['image-contain'] ? 'contain':''"
                                        [style.objectPosition]="content['styles']['image-center'] ? 'center' : ''"
                                        [style.opacity]="content.styles['image-opacity'] === 'undefined'?'1':content.styles['image-opacity']"
                                        [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                        [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                        [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                        [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                        [ngStyle]="getImageStyles(content)">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Video'">
                                <div class="content" [id]="content.id"
                                    [style.backgroundColor]="content.styles['video-opacity-color']"
                                    [ngStyle]="getStylesType(row, column, content, content.styles, 'Image')"
                                    [style.height.px]="content['styles']['height']"
                                    [style.width.px]="content['styles']['width']"
                                    [style.borderTopLeftRadius.px]="content['styles']['border-top-left-radius'] === undefined ? '0' : content['styles']['border-top-left-radius']"
                                    [style.borderTopRightRadius.px]="content['styles']['border-top-right-radius'] === undefined ? '0' : content['styles']['border-top-right-radius']"
                                    [style.borderBottomLeftRadius.px]="content['styles']['border-bottom-left-radius'] === undefined ? '0' : content['styles']['border-bottom-left-radius']"
                                    [style.borderBottomRightRadius.px]="content['styles']['border-bottom-right-radius'] === undefined ? '0' : content['styles']['border-bottom-right-radius']"
                                    style="position: relative;opacity:0;">
                                    <video [src]="content.url" [style.height.%]="100" [style.width.%]="100"
                                        [id]="'video_'+content.id" [style.padding.px]="content['styles']['padding']"
                                        [style.borderTopLeftRadius.px]="content['styles']['border-top-left-radius'] === undefined ? '0' : content['styles']['border-top-left-radius']"
                                        [style.borderTopRightRadius.px]="content['styles']['border-top-right-radius'] === undefined ? '0' : content['styles']['border-top-right-radius']"
                                        [style.borderBottomLeftRadius.px]="content['styles']['border-bottom-left-radius'] === undefined ? '0' : content['styles']['border-bottom-left-radius']"
                                        [style.borderBottomRightRadius.px]="content['styles']['border-bottom-right-radius'] === undefined ? '0' : content['styles']['border-bottom-right-radius']"
                                        [style.borderStyle]="content.styles['border-style']"
                                        [style.borderColor]="content.styles['border-color']"
                                        [style.borderWidth.px]="(content.styles['border-width']===null || content.styles['border-width']==='')?0:content.styles['border-width']"
                                        [style.objectFit]="'cover'" [style]="getShadowStyle(content['styles'])"
                                        [style.opacity]="content.styles['video-opacity'] === 'undefined'?'1':content.styles['video-opacity']"
                                        controls disablePictureInPicture controlsList="nodownload">
                                    </video>
                                    <app-internal-button [componentClass]="'carousel-hc-button-next'"
                                        [content]="content" [contentStyles]="content['styles-next']"
                                        (videoplayclick)="videoPlayClick($event)"></app-internal-button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fab-container" (click)="loadHumblecode()" *ngIf="planName==='Starter' && !isModalWindow">
            <img src="/assets/img/poweredby.png" alt="Powered by HumbleCode" />
        </div>
    </div>
</div>