import { Component, OnInit, AfterViewInit, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService, DataService, EcommerceService, CMSService, BookingService } from '../services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from '../../environments/env.service';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { IframeLookupComponent } from '../shared/components/iframelookup/iframelookup.component';
import { PageFunctions } from '../shared/directives/PageFunctions';
import { CarouselComponent } from 'ngx-bootstrap/carousel';

declare var $: any;

@Component({
	selector: 'app-staticpage',
	templateUrl: './staticpage.component.html',
	styleUrls: ['./staticpage.component.scss'],
})
export class StaticpageComponent implements OnInit, AfterViewInit {
	@ViewChild('agmmap') agmmap: object;
	screenName: string = '';
	dataobjects: any = [];
	Id: string = '';
	productId: string = '';
	categoryId: string = '';
	brandId: string = '';
	primaryId: string = '';
	calendarId: string = '';
	WebsiteId: string = '';
	WebsiteName: string = '';
	WebsiteVisits: string = '';
	rows: any = [];
	page: any = {};
	formHeight: number = 0;
	loading: boolean = true;
	showOverlay: boolean = true;
	apiUrl: string = '';
	slugURL: string = '';
	staticValues: any = {};

	productInfo: any = {};
	menuContent: any = {};
	window_width: any = 0;
	pageValues: any = {};

	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription;
	isLoggedIn: boolean = false;
	isAppleDevice: boolean = false;

	currentUrl: string = '';
	expressions: any = [];
	monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	opinionScale = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
	designType = 'DESKTOP';
	planName: string = 'Starter';
	isModalWindow: boolean = false;

	public agmStyles: any = {
		default: [],
		silver: [
			{
				elementType: 'geometry',
				stylers: [{ color: '#f5f5f5' }],
			},
			{
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				elementType: 'labels.text.fill',
				stylers: [{ color: '#616161' }],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#f5f5f5' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#bdbdbd' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ color: '#eeeeee' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#757575' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#e5e5e5' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#ffffff' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#757575' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#dadada' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#616161' }],
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ color: '#e5e5e5' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [{ color: '#eeeeee' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#c9c9c9' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
		],
		night: [
			{ elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
			{
				featureType: 'administrative.locality',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#263c3f' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#6b9a76' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#38414e' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#212a37' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9ca5b3' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#746855' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#1f2835' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#f3d19c' }],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [{ color: '#2f3948' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#17263c' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#515c6d' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#17263c' }],
			},
		],
		retro: [
			{ elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
			{ elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
			{ elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#c9b2a6' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#dcd2be' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#ae9e90' }],
			},
			{
				featureType: 'landscape.natural',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#93817c' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry.fill',
				stylers: [{ color: '#a5b076' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#447530' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#f5f1e6' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [{ color: '#fdfcf8' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#f8c967' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#e9bc62' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry',
				stylers: [{ color: '#e98d58' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#db8555' }],
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#806b63' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#8f7d77' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ebe3cd' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [{ color: '#dfd2ae' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry.fill',
				stylers: [{ color: '#b9d3c2' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#92998d' }],
			},
		],
	};

	constructor(public router: Router, public environment: EnvService, public dataService: DataService, public ecomService: EcommerceService, public cmsService: CMSService, public bookingService: BookingService, public authService: AuthService, public dialog: MatDialog, private route: ActivatedRoute, private cookieService: CookieService, public toastr: ToastrService, private pageTitle: Title, private metaTag: Meta, public pageFunctions: PageFunctions, private sanitizer: DomSanitizer) {
		this.apiUrl = environment.apiUrl;
		this.WebsiteId = environment.websiteId;
		document.title = environment.websiteTitle;
		this.isLoggedIn = this.ecomService.AppData.isLoggedin;

		var ua = navigator.userAgent;
		if (/iPhone|iPad/i.test(ua)) {
			this.isAppleDevice = true;
		}

		this.router.routeReuseStrategy.shouldReuseRoute = () => true;

		var websiteToken = this.cookieService.get('WebsiteToken');
		if (websiteToken === undefined || websiteToken === null || websiteToken === '') {
			this.cookieService.set('WebsiteToken', this.WebsiteId, 1, '/', environment.appUrl, false, 'Strict');
			this.updateWebsiteVisit();
		} else {
			if (websiteToken !== this.WebsiteId) {
				this.cookieService.set('WebsiteToken', this.WebsiteId, 1, '/', environment.appUrl, false, 'Strict');
				this.updateWebsiteVisit();
			}
		}

		this.page = {
			styles: {
				'text-align': 'center',
				'justify-content': 'center',
				'background-color': 'transparent',
				background: {
					url: '',
					fill: 1,
					repeat: 0,
					center: 1,
				},
				'padding-right': 0,
				'padding-left': 0,
				'padding-top': 0,
				'padding-bottom': 0,
				'font-family': 'Arial',
			},
		};

		if (environment.desktopSite) this.window_width = 1366;
		else this.window_width = window.innerWidth;

		this.route.queryParamMap.subscribe((params) => {
			if (params.get('modal') !== null && params.get('modal') !== undefined && params.get('modal') !== '') {
				this.isModalWindow = params.get('modal') === '1' ? true : false;
			}
		});

		this.route.params.subscribe((params) => {
			if (params['pid'] !== null && params['pid'] !== undefined && params['pid'] !== '') {
				this.productId = params['pid'];
				this.getProductData(this.productId);
			} else if (params['sid'] !== null && params['sid'] !== undefined && params['sid'] !== '') {
				this.productId = params['sid'];
				if (params['calid'] !== null && params['calid'] !== undefined && params['calid'] !== '') {
					this.calendarId = params['calid'];
				}
				this.getBookingServiceData(this.productId);
			} else {
				this.productInfo = {};
			}

			if (params['cid'] !== null && params['cid'] !== undefined && params['cid'] !== '') {
				this.categoryId = params['cid'];
			} else if (params['bid'] !== null && params['bid'] !== undefined && params['bid'] !== '') {
				this.brandId = params['bid'];
			} else if (params['ref'] !== null && params['ref'] !== undefined && params['ref'] !== '') {
				this.slugURL = params['ref'];
			}

			this.screenName = '';

			if (params['name'] !== null && params['name'] !== undefined) {
				this.screenName = params['name'];
				this.getPageData(params['name']);
			} else {
				if (this.screenName === null || this.screenName === '') {
					this.WebsiteId = environment.websiteId;
					this.screenName = environment.homePage;

					if (window.location.hostname.includes('blog.')) {
						this.screenName = environment.blogPage;
					}

					this.getPageData(this.screenName);
				}
			}

			window.scrollTo(0, 0);
		});

		if (this.ecomService.AppData.HomePage === '') {
			this.getWebsiteData();
		}

		this.router.events.subscribe((routerData) => {
			if (routerData instanceof NavigationEnd) {
				this.currentUrl = routerData.url.replace('/', '');
				if (this.ecomService.AppData.SearchProductsPage !== this.currentUrl) {
					this.ecomService.AppData.searchText = '';
				}
				if (this.bookingService.AppBookingData.SearchProductsPage !== this.currentUrl) {
					this.bookingService.AppBookingData.searchText = '';
				}
			}
		});

		this.primaryId = Guid.create().toString();

		if (!this.isStorageExists()) {
			this.toastr.error('Please enable Storage / Cookie support..', 'Error');
		}
	}

	isStorageExists() {
		try {
			if (window.localStorage !== undefined && window.localStorage !== null) return true;
		} catch (e) {
			return false;
		}
	}

	updateWebsiteVisit() {
		this.dataService.visitWebsite(this.WebsiteId).subscribe((res: any) => {});
	}

	getCFHTML(content) {
		content.htmlvalue = this.sanitizer.bypassSecurityTrustHtml(content.value);
		return content.htmlvalue;
	}

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	toggleSubMenu(menu) {
		var obj = document.getElementById(menu.id);
		if (obj) {
			if (obj['style']['display'] === undefined || obj['style']['display'] === 'none' || obj['style']['display'] === '' || obj['style']['display'] === null) {
				obj.style.display = 'block';
			} else {
				obj.style.display = 'none';
			}
		}
	}

	getSubmenuHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content.replace('</p>', '<em class="fa fa-caret-right ml-2"></em></p>'));
	}

	getProductData(id) {
		this.productInfo = {};
		this.ecomService.getProduct(this.productId).subscribe((res: any) => {
			this.productInfo = res;
		});
	}

	getBookingServiceData(id) {
		this.productInfo = {};
		this.bookingService.getService(this.WebsiteId, id).subscribe((res: any) => {
			this.productInfo = res;
		});
	}

	pageChanged(event: PageChangedEvent, content): void {
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;
		this.getPagedItems(content, startItem, endItem);
	}

	getPagedItems(content, startItem, endItem) {
		content['paged_items'] = content.items.slice(startItem, endItem);
	}

	getWebsiteData() {
		this.dataService.getWebsite().subscribe((res: any) => {
			if (res.RESULT_CODE === 1) {
				//Ecommerce
				this.ecomService.AppData.CurrencyCode = res['CURRENCY_CODE'];
				this.ecomService.AppData.CurrencySymbol = res['CURRENCY_SYMBOL'];
				this.ecomService.AppData.HomePage = res['HOME_URL_PATH'];
				this.ecomService.AppData.LoginPage = res['LOGIN_URL_PATH'];
				this.ecomService.AppData.RegistrationPage = res['REGISTER_URL_PATH'];
				this.ecomService.AppData.CheckoutPage = res['CHECKOUT_URL_PATH'];
				this.ecomService.AppData.SuccessPage = res['SUCCESS_URL_PATH'];
				this.ecomService.AppData.FailurePage = res['FAILURE_URL_PATH'];
				this.ecomService.AppData.ProfilePage = res['PROFILE_URL_PATH'];
				this.ecomService.AppData.OrdersPage = res['ORDERS_URL_PATH'];
				this.ecomService.AppData.WishlistPage = res['WISHLIST_URL_PATH'];
				this.ecomService.AppData.ProductsPage = res['PRODUCTS_URL_PATH'];
				this.ecomService.AppData.BrandProductsPage = res['BRAND_PRODUCTS_URL_PATH'];
				this.ecomService.AppData.SearchProductsPage = res['SEARCH_PRODUCTS_URL_PATH'];
				this.ecomService.AppData.ProductPage = res['PRODUCT_URL_PATH'];

				//CMS
				this.cmsService.AppData.ProductPage = res['CMS_PRODUCT_URL_PATH'];
				this.cmsService.AppData.ServicePage = res['CMS_SERVICE_URL_PATH'];
				this.cmsService.AppData.NewsPage = res['CMS_NEWS_URL_PATH'];
				this.cmsService.AppData.BlogPage = res['BLOG_URL_PATH'];
				this.cmsService.AppData.BlogContentPage = res['CMS_BLOG_URL_PATH'];

				if (this.cmsService.AppData.BlogPage !== null && this.cmsService.AppData.BlogPage !== '') {
					if (window.location.hostname.includes('blog.')) {
						this.ecomService.AppData.HomePage = this.cmsService.AppData.BlogPage;
					}
				}

				//Booking
				this.bookingService.AppBookingData.CurrencyCode = res['CURRENCY_CODE'];
				this.bookingService.AppBookingData.CurrencySymbol = res['CURRENCY_SYMBOL'];
				this.bookingService.AppBookingData.HomePage = res['HOME_URL_PATH'];
				this.bookingService.AppBookingData.LoginPage = res['LOGIN_URL_PATH'];
				this.bookingService.AppBookingData.RegistrationPage = res['REGISTER_URL_PATH'];
				this.bookingService.AppBookingData.CheckoutPage = res['CHECKOUT_URL_PATH'];
				this.bookingService.AppBookingData.SuccessPage = res['SUCCESS_URL_PATH'];
				this.bookingService.AppBookingData.FailurePage = res['FAILURE_URL_PATH'];
				this.bookingService.AppBookingData.ProfilePage = res['PROFILE_URL_PATH'];
				this.bookingService.AppBookingData.OrdersPage = res['ORDERS_URL_PATH'];
				this.bookingService.AppBookingData.WishlistPage = res['WISHLIST_URL_PATH'];
				this.bookingService.AppBookingData.ProductsPage = res['PRODUCTS_URL_PATH'];
				this.bookingService.AppBookingData.SearchProductsPage = res['SEARCH_PRODUCTS_URL_PATH'];
				this.bookingService.AppBookingData.ProductPage = res['PRODUCT_URL_PATH'];
			}
		});
	}

	isMobile() {
		return this.window_width < 700;
	}

	getPageData(id) {
		this.designType = 'DESKTOP';

		if (this.window_width < 700) {
			this.designType = 'MOBILE';
		} else if (this.window_width < 1000) {
			this.designType = 'TABLET';
		}

		this.dataService.getStaticPage(id, this.WebsiteId, this.designType).subscribe((res: any) => {
			if (res.RESULT_CODE === 1) {
				this.Id = res['ID'];
				this.WebsiteName = res['Website_Name'];
				this.screenName = res['Screen_Name'];
				this.WebsiteVisits = (res['TOTAL_VISITS'] === null ? 1 : res['TOTAL_VISITS']).toString();
				var pageTitle = res['Page_Title'];
				var pageDescription = res['Page_Description'];
				var pageKeywords = res['Page_Keywords'];
				this.planName = res['PLAN_NAME'];

				if (pageTitle !== null && pageTitle !== '') {
					this.pageTitle.setTitle(this.environment.websiteTitle + ' | ' + pageTitle);
					this.metaTag.updateTag({
						name: 'og:title',
						content: this.environment.websiteTitle + ' | ' + pageTitle,
					});
					this.metaTag.updateTag({
						name: 'twitter:title',
						content: this.environment.websiteTitle + ' | ' + pageTitle,
					});
				}

				if (pageDescription !== null && pageDescription !== '') {
					this.metaTag.updateTag({
						name: 'description',
						content: pageDescription,
					});
					this.metaTag.updateTag({
						name: 'og:description',
						content: pageDescription,
					});
					this.metaTag.updateTag({
						name: 'twitter:description',
						content: pageDescription,
					});
				}

				if (pageKeywords !== null && pageKeywords !== '') {
					this.metaTag.updateTag({ name: 'keywords', content: pageKeywords });
				}

				this.isLoggedIn = res['isLoggedIn'] === 1 ? true : false;

				if (!this.ecomService.AppData.loginCheckDone) {
					this.ecomService.AppData.loginCheckDone = true;
					this.ecomService.AppData.isLoggedin = this.isLoggedIn;
					this.ecomService.setDataFromStorage();
				}

				if (!this.bookingService.AppBookingData.loginCheckDone) {
					this.bookingService.AppBookingData.loginCheckDone = true;
					this.bookingService.AppBookingData.isLoggedin = this.isLoggedIn;
					this.bookingService.setDataFromStorage();
				}

				if (res['PROD_DESIGN_CONFIGS']) {
					var designConfig = res['PROD_DESIGN_CONFIGS'];

					designConfig = designConfig.replaceAll('col-1', 'column-1');
					designConfig = designConfig.replaceAll('col-2', 'column-2');
					designConfig = designConfig.replaceAll('col-3', 'column-3');
					designConfig = designConfig.replaceAll('col-4', 'column-4');
					designConfig = designConfig.replaceAll('col-5', 'column-5');
					designConfig = designConfig.replaceAll('col-6', 'column-6');
					designConfig = designConfig.replaceAll('col-7', 'column-7');
					designConfig = designConfig.replaceAll('col-8', 'column-8');
					designConfig = designConfig.replaceAll('col-9', 'column-9');
					designConfig = designConfig.replaceAll('col-10', 'column-10');
					designConfig = designConfig.replaceAll('col-11', 'column-11');
					designConfig = designConfig.replaceAll('col-12', 'column-12');
					designConfig = designConfig.replaceAll('col-lg-1', 'column-1');
					designConfig = designConfig.replaceAll('col-lg-2', 'column-2');
					designConfig = designConfig.replaceAll('col-lg-3', 'column-3');
					designConfig = designConfig.replaceAll('col-lg-4', 'column-4');
					designConfig = designConfig.replaceAll('col-lg-5', 'column-5');
					designConfig = designConfig.replaceAll('col-lg-6', 'column-6');
					designConfig = designConfig.replaceAll('col-lg-7', 'column-7');
					designConfig = designConfig.replaceAll('col-lg-8', 'column-8');
					designConfig = designConfig.replaceAll('col-lg-9', 'column-9');
					designConfig = designConfig.replaceAll('col-lg-10', 'column-10');
					designConfig = designConfig.replaceAll('col-lg-11', 'column-11');
					designConfig = designConfig.replaceAll('col-lg-12', 'column-12');

					var design_configs = JSON.parse(designConfig);
					this.rows = design_configs['rows'];
					this.page = design_configs['page'];

					if (this.page.styles['page-width'] === undefined || this.page.styles['page-width'] === null || this.page.styles['page-width'] === '' || parseInt(this.page.styles['page-width']) === 0) {
						this.page.styles['page-width'] = 1366;
					}

					setTimeout(() => {
						this.setVisibleRows('');
					}, 1000);

					if (designConfig.includes('Listing') || designConfig.includes('Products') || designConfig.includes('Services') || designConfig.includes('BookingCalender') || designConfig.includes('Forms') || designConfig.includes('TypeForm') || designConfig.includes('StaticSection')) {
						this.processListingContent();
					}
					if (designConfig.includes('MenuGroup')) {
						this.processMenuContent();
					}
					if (design_configs['expressions'] !== undefined) {
						this.expressions = design_configs['expressions'];
					}
				}
				this.showOverlay = false;
				this.loading = false;

				if (localStorage.getItem('googleKey') === null || localStorage.getItem('googleKey') === '') {
					localStorage.setItem('googleKey', res['GOOGLE_KEY']);
				}
			} else {
				this.toastr.error('Error showing this page.. Please try later..', 'Error');
			}
		});
	}
	rowsHidden: any = [];
	//elements visible only on scroll and animation set on scroll
	@HostListener('window:scroll', ['$event'])
	scrollHandler(event) {
		this.setVisibleRows('scroll');
	}

	setVisibleRows(eventType) {
		this.rows.forEach((row) => {
			if (eventType === '') {
				if (row.styles['hide-row-in-display']) {
					this.rowsHidden.push(row);
				}
			}
			var ele_row = document.getElementById(row.id);
			if (ele_row) {
				let rowTop = this.checkInView(ele_row, true);
				if (rowTop) {
					//if animation is already run for that row then do not run the animation
					this.setRowAnimation(row);
					row.columns.forEach((column) => {
						this.setColumnAnimation(column, row);
						column.contents.forEach((content) => {
							if (content.type === 'MenuGroup') {
								let menuidx = 0;
								content.menus.forEach((menu) => {
									var content_ele_row = document.getElementById(content.id + menuidx);
									if (content_ele_row) {
										if (content.styles['hover-available']) {
											content_ele_row.style.setProperty('--hover-bgcolor', content.styles['hover-bgcolor']);
											content_ele_row.style.setProperty('--hover-color', content.styles['hover-color']);
										}
									}
									menuidx++;
								});
							} else {
								var content_ele_row = document.getElementById(content.id);
								if (content_ele_row) {
									if (content.styles['hover-available']) {
										content_ele_row.style.setProperty('--hover-bgcolor', content.styles['hover-bgcolor']);
										content_ele_row.style.setProperty('--hover-color', content.styles['hover-color']);
									}
								}
							}
							if (this.pageFunctions.checkNull(content.styles.animation)) {
								var content_ele_row = document.getElementById(content.id);
								let contentVisible = this.checkInView(content_ele_row, true);
								if (contentVisible) {
									this.setContentAnimation(content, column);
								}
							}
						});
					});
				}
			}
		});

		this.rowsHidden.forEach((row) => {
			if (row.styles['hide-row-in-display']) {
				if (eventType === 'scroll') {
					if (!row.styles['persist-on-scroll']) {
						row.styles['hide-row-in-display'] = true;
					}
				} else {
					row.styles['hide-row-in-display'] = !row.styles['default-visible'];
				}
			}
		});
	}

	checkInView(elem, partial) {
		var contHeight = window.innerHeight;
		var contTop = window.scrollY;
		var contBottom = contTop + contHeight;

		var elemTop = $(elem).offset().top - window.pageYOffset;
		var elemBottom = elemTop + $(elem).height();

		var isTotal = elemTop >= 0 && elemBottom <= contHeight;
		var isPart = ((elemTop <= 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= window.innerHeight)) && partial;

		return isTotal || isPart;
	}

	setRowAnimation(row) {
		var videoHolder = $('#video_' + row.id);
		if (videoHolder.length > 0) {
			try {
				videoHolder[0].play();
			} catch (e) {}
		}

		var dataclass = row.styles.animation;
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = row.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				this.setOpacity(row, false, '');
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + row.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {});
					row.styles.animation = '';
				}
			}, 2000);
		}
	}

	setColumnAnimation(column, row) {
		var videoHolder = $('#video_' + row.id + '_' + column.id);
		if (videoHolder.length > 0) {
			try {
				videoHolder[0].play();
			} catch (e) {}
		}

		if (column.styles !== undefined) {
			var dataclass = column.styles.animation;
			if (dataclass !== undefined && dataclass !== '') {
				var animationInterval = column.styles['animation-interval'];
				if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
					animationInterval = 'animated';
				}
				setTimeout(() => {
					this.setOpacity(column, false, '');
					if (dataclass !== undefined && dataclass !== '') {
						$('#' + column.id)
							.addClass(dataclass + ' ' + animationInterval)
							.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
								if (animationInterval !== 'animated10 infinite') {
									$(this).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
								}
							});
						$('#' + row.id).addClass('h-scroll-text-container');
						column.styles.animation = '';
					}
				}, 1000);
			}
		}
	}

	setContentAnimation(content, column) {
		var dataclass = content.styles.animation;
		var idPrefix = '';
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = content.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				if (content.type === 'CLIPATH' && dataclass.toLowerCase() === 'gradient') idPrefix = 'animate';
				else idPrefix = '';
				this.setOpacity(content, true, idPrefix);
				if (dataclass !== undefined && dataclass !== '') {
					if (content.styles['onhover-animation']) {
						$('#' + idPrefix + content.id).addClass(dataclass);
					} else {
						$('#' + idPrefix + content.id)
							.addClass(dataclass + ' ' + animationInterval)
							.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
								if (animationInterval !== 'animated10 infinite') {
									$('#' + idPrefix + content.id).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
								}
							});
						$('#' + content.id + column.id).addClass('h-scroll-text-container');
					}
					content.styles.animation = '';
				}
			}, 500);
		}
	}

	processMenuContent() {
		this.rows.forEach((row) => {
			row.columns.forEach((column) => {
				column.contents.forEach((content) => {
					if (content.type === 'MenuGroup') {
						this.menuContent = content;
					}
				});
			});
		});
	}

	processListingContent() {
		this.rows.forEach((row) => {
			row.columns.forEach((column) => {
				column.contents.forEach((content) => {
					if (content.type === 'Listing') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];
						this.updateCategoryProducts(content);
					} else if (content.type === 'BookingListing') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];
						this.updateCategoryServices(content);
					} else if (content.type === 'Products') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];
						if (content['categoryId'] !== undefined && content['categoryId'] !== '') {
							this.updateCategoryProductsAll(content);
						} else if (this.categoryId !== undefined && this.categoryId !== '') {
							content['categoryId'] = this.categoryId;
							this.updateCategoryProductsAll(content);
						} else if (this.ecomService.AppData.searchText !== '') {
							this.updateSearchProducts(content);
						}
					} else if (content.type === 'BookingServices') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];
						if (content['categoryId'] !== undefined && content['categoryId'] !== '') {
							this.updateCategoryServicesAll(content);
						} else if (this.categoryId !== undefined && this.categoryId !== '') {
							content['categoryId'] = this.categoryId;
							this.updateCategoryServicesAll(content);
						} else if (this.bookingService.AppBookingData.searchText !== '') {
							this.updateSearchServices(content);
						}
					} else if (content.type === 'BrandProducts') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];
						if (content['brandId'] !== undefined && content['brandId'] !== '') {
							this.updateBrandProductsAll(content);
						} else if (this.brandId !== undefined && this.brandId !== '') {
							content['brandId'] = this.brandId;
							this.updateBrandProductsAll(content);
						} else if (this.ecomService.AppData.searchText !== '') {
							this.updateSearchProducts(content);
						}
					} else if (content.type === 'SearchProducts') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];

						this.updateSearchProducts(content);
					} else if (content.type === 'SearchServices') {
						content['url'] = '';
						content['items'] = [];
						content['paged_items'] = [];

						this.updateSearchServices(content);
					} else if (content.type === 'Forms') {
						this.pageValues[content['form']['ID']] = {};
						content['form']['fields'] = [];
						if (content['form']['show_placeholder'] === undefined) {
							content['form']['show_placeholder'] === false;
						}
						this.initiateFormData(content);
					} else if (content.type === 'TypeForm') {
						this.pageValues[content['form']['ID']] = {};
						content['form']['fields'] = [];
						content['form']['status'] = 'Pending';
						this.initiateTypeFormData(content);
					} else if (content.type === 'StaticSection') {
						this.initiateStaticFormData(content);
					}
				});
			});
		});
	}

	showAllProducts(content) {
		if (content['type'] === 'Category' || content['type'] === 'BookingCategory') {
			if (content['categoryId'] !== undefined && content['categoryId'] !== '' && content['categoryId'] !== null) {
				if (content['url'] !== undefined && content['url'] !== '' && content['url'] !== null) {
					this.router.navigate([content['url'], { cid: content['categoryId'] }]);
				} else if (content['type'] === 'Category' && this.ecomService.AppData.ProductsPage !== '') {
					this.router.navigate([this.ecomService.AppData.ProductsPage, { cid: content['categoryId'] }]);
				} else if (content['type'] === 'BookingCategory' && this.bookingService.AppBookingData.ProductsPage !== '') {
					this.router.navigate([this.bookingService.AppBookingData.ProductsPage, { cid: content['categoryId'] }]);
				}
			}
		} else if (content['type'] === 'Product' || content['type'] === 'BookingProduct') {
			if (content['pid'] !== undefined && content['pid'] !== '' && content['pid'] !== null) {
				if (content['type'] === 'Product') {
					if (content['url'] !== undefined && content['url'] !== '' && content['url'] !== null) {
						this.router.navigate([content['url'], { pid: content['pid'] }]);
					} else if (content['type'] === 'Product' && this.ecomService.AppData.ProductPage !== '') {
						this.router.navigate([this.ecomService.AppData.ProductPage, { pid: content['pid'] }]);
					}
				} else {
					if (content['url'] !== undefined && content['url'] !== '' && content['url'] !== null) {
						this.router.navigate([content['url'], { sid: content['pid'] }]);
					} else if (content['type'] === 'BookingProduct' && this.bookingService.AppBookingData.ProductPage !== '') {
						this.router.navigate([this.bookingService.AppBookingData.ProductPage, { sid: content['pid'] }]);
					}
				}
			}
		} else if (content['brandId'] !== undefined && content['brandId'] !== '' && content['brandId'] !== null) {
			if (content['url'] !== undefined && content['url'] !== '' && content['url'] !== null) {
				this.router.navigate([content['url'], { bid: content['brandId'] }]);
			} else if (this.ecomService.AppData.BrandProductsPage !== '') {
				this.router.navigate([this.ecomService.AppData.BrandProductsPage, { cid: content['brandId'] }]);
			}
		}
	}

	updateSearchProducts(content) {
		content['items'] = [];
		this.ecomService.getSearchProducts(this.WebsiteId, this.ecomService.AppData.searchText, '', '').subscribe((data) => {
			content['items'] = [];
			if (data.RESULT_CODE === 1) {
				var products = data.DATA;
				content['TOTAL_RECORDS'] = data.RECORD_COUNT;

				if (products.length > 0) content['url'] = this.ecomService.AppData.ProductsPage;

				products.forEach((product) => {
					var item = {
						imageUrl: product['IMAGE_URL'],
						productId: product['ID'],
						variantId: product['VARIANT_ID'],
						brandId: product['BRAND_ID'],
						categoryId: product['CATEGORY_ID'],
						isVariant: product['IS_VARIANT_REQUIRED'] === '1' ? true : false,
						productName: product['PRODUCT_NAME'],
						productDescription: product['PRODUCT_DESCRIPTION'],
						qty: 1,
						price: product['PRICE'],
						discount_Price: product['DISCOUNTED_PRICE'],
						min_price: product['MIN_PRICE'],
						max_price: product['MAX_PRICE'],
						url: this.ecomService.AppData.ProductPage,
					};
					content.items.push(item);
				});
				this.getPagedItems(content, 0, 12);
			}
		});
	}

	updateSearchServices(content) {
		content['items'] = [];

		this.bookingService.getSearchServices(this.WebsiteId, this.bookingService.AppBookingData.searchText, '').subscribe((data) => {
			content['items'] = [];
			if (data.RESULT_CODE === 1) {
				var products = data.DATA;
				content['TOTAL_RECORDS'] = data.RECORD_COUNT;

				if (products.length > 0) content['url'] = this.bookingService.AppBookingData.ProductsPage;

				products.forEach((product) => {
					var item = {
						imageUrl: product['IMAGE_URL'],
						productId: product['ID'],
						calenderId: product['CALENDER_ID'],
						categoryId: product['CATEGORY_ID'],
						productName: product['TITLE'],
						productDescription: product['DESCRIPTION'],
						qty: 1,
						duration: product['DURATION'],
						price: product['AMOUNT'],
						discount_Price: product['DISCOUNTED_AMOUNT'],
						url: this.bookingService.AppBookingData.ProductPage,
					};
					content.items.push(item);
				});
				this.getPagedItems(content, 0, 12);
			}
		});
	}

	initiateFormData(content) {
		if (content['form']['ID'] !== undefined) {
			var fields = {
				CLIENT_KEY: this.environment.clientKey,
				TABLE_NAME: 'hc_forms_' + content['form']['ID'].toString(),
				WEBSITE_ID: this.WebsiteId,
			};

			this.dataService.getTypeFormFields(content['form']['ID'].toString()).subscribe((data) => {
				var form_fields = JSON.parse(data['PUBLISHED_CONFIG']);

				form_fields = form_fields.filter((fld) => fld.OPER !== 'DEL');

				content['form']['fields'] = form_fields;
				content['form']['fields'].forEach((field) => {
					if (field['DataType'] === 'BIT') {
						fields[field['Field_Name']] = true;
					} else if (field['DataType'] !== 'STATEMENT') {
						fields[field['Field_Name']] = '';

						if (field['DataType'] === 'DROPDOWN') {
							var listArray = field.List?.split('|');
							var listJson: any = [];
							if (listArray && listArray?.length > 0) {
								listArray.forEach((element) => {
									listJson.push({ value: element });
								});
							}
							this.staticValues[field['ID']] = listJson;
						}
					}

					if (this.designType === 'TABLET') {
						field['Tablet_Class'] = field['Tablet_Class'].replace('col-lg', 'column');
					} else if (this.designType === 'DESKTOP') {
						field['Class'] = field['Class'].replace('col-lg', 'column');
					}
				});
				this.pageValues[content['form']['ID']] = fields;
			});
		}
	}

	initiateTypeFormData(content) {
		var fields = {
			CLIENT_KEY: this.environment.clientKey,
			TABLE_NAME: 'hc_forms_' + content['form']['ID'].toString(),
			WEBSITE_ID: this.WebsiteId,
		};

		this.dataService.getTypeFormFields(content['form']['ID'].toString()).subscribe((data) => {
			var form_fields = JSON.parse(data['PUBLISHED_CONFIG']);

			form_fields = form_fields.filter((fld) => fld.OPER !== 'DEL');
			var slno = 1;
			form_fields.forEach((fld) => {
				if (slno === 1) {
					fld['selected'] = true;
					fld['Flow_SlNo'] = 1;
				} else {
					fld['selected'] = false;
					fld['Flow_SlNo'] = 0;
				}
				fld['class'] = 'col-lg-12';
				fld['slno'] = slno;
				fld['maxslno'] = form_fields.length;
				slno++;
			});
			content['form']['fields'] = form_fields;
			content['form']['fields'].forEach((field) => {
				if (field['DataType'] === 'BIT') fields[field['Field_Name']] = true;
				else if (field['DataType'] !== 'STATEMENT') {
					if (field['DataType'] === 'RATING' || field['DataType'] === 'SCALE') fields[field['Field_Name']] = 0;
					else fields[field['Field_Name']] = '';

					if (field['DataType'] === 'DROPDOWN') {
						var listArray = field.List?.split('|');
						var listJson: any = [];
						if (listArray && listArray?.length > 0) {
							listArray.forEach((element) => {
								listJson.push({ value: element });
							});
						}
						this.staticValues[field['ID']] = listJson;
					}
				}
			});

			this.pageValues[content['form']['ID']] = fields;
		});
	}

	showPrev(formId, fields, no_of_questions) {
		var currentFields = fields.filter((fld) => fld.selected === true);
		no_of_questions = 1;

		if (currentFields.length > 0) {
			if (currentFields[0].Flow_SlNo > 1) {
				var prevFields = fields.filter((fld) => fld.Flow_SlNo === currentFields[0].Flow_SlNo - no_of_questions);
				if (prevFields.length > 0) {
					currentFields.forEach((fld) => {
						fld.selected = false;
					});
					currentFields[0]['Flow_SlNo'] = 0;
					prevFields.forEach((fld) => {
						fld.selected = true;
					});
				}
			}
		}
	}

	showNext(formId, fields, no_of_questions) {
		var currentFields = fields.filter((fld) => fld.selected === true);
		no_of_questions = 1;
		if (currentFields.length > 0) {
			var maxSlno = currentFields[0]['maxslno'];
			if (currentFields[currentFields.length - 1].slno < maxSlno) {
				var validData: boolean = true;

				currentFields.forEach((currentField) => {
					if (currentField['IsMandatory'] === '1' && currentField['DataType'] !== 'STATEMENT' && currentField['DataType'] !== 'BIT' && (this.pageValues[formId][currentField['Field_Name']] === null || this.pageValues[formId][currentField['Field_Name']] === '' || ((currentField['DataType'] === 'RATING' || currentField['DataType'] === 'SCALE') && this.pageValues[formId][currentField['Field_Name']] === 0))) {
						validData = false;
					}
				});

				if (validData) {
					var nextFieldCount = 0;
					var ruleValue: any;
					if (currentFields[0].Rules !== undefined && currentFields[0].Rules.length > 0) {
						currentFields[0].Rules.forEach((rule) => {
							if (rule['CONDITION'] === 'EQ') {
								if (currentFields[0]['DataType'] === 'BIT') {
									ruleValue = rule['VALUE'] === '1' ? true : false;
								} else {
									ruleValue = rule['VALUE'];
								}
								if (currentFields[0]['DataType'] === 'INTEGER' || currentFields[0]['DataType'] === 'RATING' || currentFields[0]['DataType'] === 'SCALE') {
									if (parseInt(this.pageValues[formId][currentFields[0]['Field_Name']]) === parseInt(ruleValue)) {
										var nextFields = fields.filter((fld) => fld.ID === rule['GOTO']);
										if (nextFields.length > 0) {
											currentFields.forEach((fld) => {
												fld.selected = false;
											});
											nextFields.forEach((fld) => {
												fld.selected = true;
											});
											nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
										}
										nextFieldCount = nextFields.length;
										return;
									}
								} else {
									if (this.pageValues[formId][currentFields[0]['Field_Name']] === ruleValue) {
										var nextFields = fields.filter((fld) => fld.ID === rule['GOTO']);
										if (nextFields.length > 0) {
											currentFields.forEach((fld) => {
												fld.selected = false;
											});
											nextFields.forEach((fld) => {
												fld.selected = true;
											});
											nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
										}
										nextFieldCount = nextFields.length;
										return;
									}
								}
							} else if (rule['CONDITION'] === 'NEQ') {
								if (currentFields[0]['DataType'] === 'BIT') {
									ruleValue = rule['VALUE'] === '1' ? true : false;
								} else {
									ruleValue = rule['VALUE'];
								}
								if (currentFields[0]['DataType'] === 'INTEGER' || currentFields[0]['DataType'] === 'RATING' || currentFields[0]['DataType'] === 'SCALE') {
									if (parseInt(this.pageValues[formId][currentFields[0]['Field_Name']]) !== parseInt(ruleValue)) {
										var nextFields = fields.filter((fld) => fld.ID === rule['GOTO']);
										if (nextFields.length > 0) {
											currentFields.forEach((fld) => {
												fld.selected = false;
											});
											nextFields.forEach((fld) => {
												fld.selected = true;
											});
											nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
										}
										nextFieldCount = nextFields.length;
										return;
									}
								} else {
									if (this.pageValues[formId][currentFields[0]['Field_Name']] !== ruleValue) {
										var nextFields = fields.filter((fld) => fld.ID === rule['GOTO']);
										if (nextFields.length > 0) {
											currentFields.forEach((fld) => {
												fld.selected = false;
											});
											nextFields.forEach((fld) => {
												fld.selected = true;
											});
											nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
										}
										nextFieldCount = nextFields.length;
										return;
									}
								}
							} else if (rule['CONDITION'] === 'LTE') {
								if (parseInt(this.pageValues[formId][currentFields[0]['Field_Name']]) <= parseInt(rule['VALUE'])) {
									var nextFields = fields.filter((fld) => fld.ID === rule['GOTO']);
									if (nextFields.length > 0) {
										currentFields.forEach((fld) => {
											fld.selected = false;
										});
										nextFields.forEach((fld) => {
											fld.selected = true;
										});
										nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
									}
									nextFieldCount = nextFields.length;
									return;
								}
							} else if (rule['CONDITION'] === 'GTE') {
								if (parseInt(this.pageValues[formId][currentFields[0]['Field_Name']]) >= parseInt(rule['VALUE'])) {
									var nextFields = fields.filter((fld) => fld.ID === rule['GOTO']);
									if (nextFields.length > 0) {
										currentFields.forEach((fld) => {
											fld.selected = false;
										});
										nextFields.forEach((fld) => {
											fld.selected = true;
										});
										nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
									}
									nextFieldCount = nextFields.length;
									return;
								}
							}
						});
					} else {
						nextFieldCount = 1;
						var nextFields = fields.filter((fld) => fld.slno > currentFields[currentFields.length - 1].slno && fld.slno <= currentFields[currentFields.length - 1].slno + no_of_questions);
						if (nextFields.length > 0) {
							currentFields.forEach((fld) => {
								fld.selected = false;
							});

							nextFields.forEach((fld) => {
								fld.selected = true;
							});
							nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
						}
					}

					if (nextFieldCount === 0) {
						var nextFields = fields.filter((fld) => fld.slno > currentFields[currentFields.length - 1].slno && fld.slno <= currentFields[currentFields.length - 1].slno + no_of_questions);
						if (nextFields.length > 0) {
							currentFields.forEach((fld) => {
								fld.selected = false;
							});

							nextFields.forEach((fld) => {
								fld.selected = true;
							});
							nextFields[0]['Flow_SlNo'] = this.getMaxFlowSlNo(fields) + 1;
						}
					}
				}
			}
		}
	}

	showTypeFormButton(no_of_questions, fieldIndex, totalFields) {
		no_of_questions = 1;
		if (fieldIndex === totalFields || parseInt(no_of_questions) === fieldIndex || fieldIndex % no_of_questions === 0) return true;
		else return false;
	}

	getMaxFlowSlNo(fields) {
		var maxSlno = 1;
		fields.forEach((field) => {
			if (field['Flow_SlNo'] !== undefined && field['Flow_SlNo'] > maxSlno) {
				maxSlno = field['Flow_SlNo'];
			}
		});
		return maxSlno;
	}

	initiateStaticFormData(content) {
		content['form']['fields'].forEach((field) => {
			if (field['DataType'] === 'TINY_STRING') {
				if (field.DefaultValue !== undefined && field.DefaultValue !== null && field.DefaultValue !== '') {
					this.pageValues[field['Field_ID']] = field.DefaultValue;
				} else {
					this.pageValues[field['Field_ID']] = '';
				}
			} else if (field['DataType'] === 'BIT') {
				this.pageValues[field['Field_ID']] = true;
			} else if (field['DataType'] === 'INTEGER' || field['DataType'] === 'DECIMAL' || field['DataType'] === 'PERCENTAGE') {
				if (field.DefaultValue !== undefined && field.DefaultValue !== null && field.DefaultValue !== '') {
					this.pageValues[field['Field_ID']] = field.DefaultValue;
				} else {
					this.pageValues[field['Field_ID']] = null;
				}
			}
		});
	}

	updateCategoryProducts(content) {
		content['items'] = [];
		if (content['categoryId'] !== undefined && content['categoryId'] !== '' && content['categoryId'] !== null) {
			this.ecomService.getCategoryProducts(content['categoryId']).subscribe((data) => {
				content['items'] = [];

				if (data.RESULT_CODE === 1) {
					var products = data.DATA;
					content['TOTAL_RECORDS'] = data.RECORD_COUNT;

					if (products.length > 0) {
						content['categoryName'] = products[0]['CATEGORY_NAME'];
						if (products[0]['PRODUCTS_URL'] !== null && products[0]['PRODUCTS_URL'] !== '') content['url'] = products[0]['PRODUCTS_URL'];
						else content['url'] = this.ecomService.AppData.ProductsPage;
					}

					products.forEach((product) => {
						var item = {
							imageUrl: product['IMAGE_URL'],
							productId: product['ID'],
							variantId: product['VARIANT_ID'],
							isVariant: product['IS_VARIANT_REQUIRED'] === '1' ? true : false,
							productName: product['PRODUCT_NAME'],
							productDescription: product['PRODUCT_DESCRIPTION'],
							qty: 1,
							price: product['PRICE'],
							discount_Price: product['DISCOUNTED_PRICE'],
							min_price: product['MIN_PRICE'],
							max_price: product['MAX_PRICE'],
						};
						if (product['PRODUCT_URL'] !== null && product['PRODUCT_URL'] !== '') item['url'] = product['PRODUCT_URL'];
						else item['url'] = this.ecomService.AppData.ProductPage;

						content.items.push(item);
					});
				}
			});
		}
	}

	updateCategoryServices(content) {
		content['items'] = [];
		if (content['categoryId'] !== undefined && content['categoryId'] !== '' && content['categoryId'] !== null) {
			this.bookingService.getCategoryServices(this.WebsiteId, content['categoryId']).subscribe((data) => {
				content['items'] = [];

				if (data.RESULT_CODE === 1) {
					var products = data.DATA;
					content['TOTAL_RECORDS'] = data.RECORD_COUNT;

					if (products.length > 0) {
						content['categoryName'] = products[0]['CATEGORY_NAME'];
						content['url'] = this.bookingService.AppBookingData.ProductsPage;
					}

					products.forEach((product) => {
						var item = {
							imageUrl: product['IMAGE_URL'],
							productId: product['ID'],
							calenderId: product['CALENDER_ID'],
							productName: product['TITLE'],
							productDescription: product['DESCRIPTION'],
							qty: 1,
							duration: product['DURATION'],
							price: product['AMOUNT'],
							discount_Price: product['DISCOUNTED_AMOUNT'],
						};

						item['url'] = this.bookingService.AppBookingData.ProductPage;

						content.items.push(item);
					});
				}
			});
		}
	}

	loadPage(obj) {
		this.rowsHidden.forEach((row) => {
			row.styles['hide-row-in-display'] = true;
		});
		if (obj.url !== undefined && obj.url !== '') {
			if (obj.screen !== undefined && obj.screen.type !== undefined && obj.screen.type !== '') {
				if ((obj.screen.type === 'Category' && obj.screen.categoryId !== '') || (obj.screen.type === 'BookingCategory' && obj.screen.categoryId !== '') || (obj.screen.type === 'Brand' && obj.screen.brandId !== '') || (obj.screen.type === 'Product' && obj.screen.pid !== '') || (obj.screen.type === 'BookingProduct' && obj.screen.pid !== '')) {
					this.showAllProducts(obj.screen);
					if ($('#hamburgerbtn').hasClass('active')) {
						$('#hamburgerbtn').removeClass('active');
						$('#hamburgermenu').toggle('slow', function () {});
					}
				}
			} else {
				if (obj.navigateUrl !== undefined && obj.navigateUrl !== null && obj.navigateUrl !== '') {
					this.goToUrl(obj.navigateUrl, obj.target, obj);
				} else if (obj.type !== 'Image') {
					this.goToUrl(obj.url, obj.target, obj);
				}
			}
		} else if (obj.row_id !== undefined && obj.row_id !== '') {
			var elements = document.getElementById(obj.row_id);
			if (obj['menu_action'] === 'visible') {
				this.rows.forEach((r) => {
					if (obj.row_id === r.id) {
						r.styles['hide-row-in-display'] = !r.styles['hide-row-in-display'];
					}
				});
			} else if (obj['menu_action'] === 'scroll') {
				if (elements !== undefined && elements !== null) {
					elements.scrollIntoView();
				}
			}
		}
	}

	updateCategoryProductsAll(content) {
		content['items'] = [];
		if (this.categoryId !== undefined && this.categoryId !== '') {
			this.ecomService.getCategoryProductsAll(this.categoryId).subscribe((data) => {
				content['items'] = [];

				if (data.RESULT_CODE === 1) {
					var products = data.DATA;
					content['TOTAL_RECORDS'] = data.RECORD_COUNT;

					if (products.length > 0) {
						if (products[0]['PRODUCTS_URL'] !== null && products[0]['PRODUCTS_URL'] !== '') content['url'] = products[0]['PRODUCTS_URL'];
						else content['url'] = this.ecomService.AppData.ProductsPage;
					}

					products.forEach((product) => {
						var item = {
							imageUrl: product['IMAGE_URL'],
							productId: product['ID'],
							variantId: product['VARIANT_ID'],
							brandId: product['BRAND_ID'],
							isVariant: product['IS_VARIANT_REQUIRED'] === '1' ? true : false,
							productName: product['PRODUCT_NAME'],
							productDescription: product['PRODUCT_DESCRIPTION'],
							qty: 1,
							price: product['PRICE'],
							discount_Price: product['DISCOUNTED_PRICE'],
							min_price: product['MIN_PRICE'],
							max_price: product['MAX_PRICE'],
						};
						if (product['PRODUCT_URL'] !== null && product['PRODUCT_URL'] !== '') item['url'] = product['PRODUCT_URL'];
						else item['url'] = this.ecomService.AppData.ProductPage;

						content.items.push(item);
					});

					this.getPagedItems(content, 0, 12);

					if (products.length > 0) {
						content['categoryId'] = products[0]['CATEGORY_ID'];
						content['categoryName'] = products[0]['CATEGORY_NAME'];
					}
				}
			});
		}
	}

	updateCategoryServicesAll(content) {
		content['items'] = [];
		if (this.categoryId !== undefined && this.categoryId !== '') {
			this.bookingService.getCategoryServicesAll(this.WebsiteId, this.categoryId).subscribe((data) => {
				content['items'] = [];

				if (data.RESULT_CODE === 1) {
					var products = data.DATA;
					content['TOTAL_RECORDS'] = data.RECORD_COUNT;

					if (products.length > 0) {
						if (products[0]['PRODUCTS_URL'] !== undefined && products[0]['PRODUCTS_URL'] !== null && products[0]['PRODUCTS_URL'] !== '') content['url'] = products[0]['PRODUCTS_URL'];
						else content['url'] = this.bookingService.AppBookingData.ProductsPage;
					}

					products.forEach((product) => {
						var item = {
							imageUrl: product['IMAGE_URL'],
							productId: product['ID'],
							calenderId: product['CALENDER_ID'],
							productName: product['TITLE'],
							productDescription: product['DESCRIPTION'],
							qty: 1,
							duration: product['DURATION'],
							price: product['AMOUNT'],
							discount_Price: product['DISCOUNTED_AMOUNT'],
						};
						item['url'] = this.bookingService.AppBookingData.ProductPage;

						content.items.push(item);
					});

					this.getPagedItems(content, 0, 12);

					if (products.length > 0) {
						content['categoryId'] = products[0]['CATEGORY_ID'];
						content['categoryName'] = products[0]['CATEGORY_NAME'];
					}
				}
			});
		}
	}

	updateBrandProductsAll(content) {
		content['items'] = [];
		if (this.brandId !== undefined && this.brandId !== '') {
			this.ecomService.getBrandProductsAll(this.brandId).subscribe((data) => {
				content['items'] = [];

				if (data.RESULT_CODE === 1) {
					var products = data.DATA;
					content['TOTAL_RECORDS'] = data.RECORD_COUNT;

					if (products.length > 0) content['url'] = this.ecomService.AppData.BrandProductsPage;

					products.forEach((product) => {
						var item = {
							imageUrl: product['IMAGE_URL'],
							productId: product['ID'],
							variantId: product['VARIANT_ID'],
							categoryId: product['CATEGORY_ID'],
							isVariant: product['IS_VARIANT_REQUIRED'] === '1' ? true : false,
							productName: product['PRODUCT_NAME'],
							productDescription: product['PRODUCT_DESCRIPTION'],
							qty: 1,
							price: product['PRICE'],
							discount_Price: product['DISCOUNTED_PRICE'],
							min_price: product['MIN_PRICE'],
							max_price: product['MAX_PRICE'],
							url: this.ecomService.AppData.ProductPage,
						};
						content.items.push(item);
					});

					this.getPagedItems(content, 0, 12);

					if (products.length > 0) {
						content['brandId'] = products[0]['BRAND_ID'];
						content['brandName'] = products[0]['BRAND_NAME'];
					}
				}
			});
		}
	}

	showHamburgerMenu($event) {
		$event.preventDefault();
		$event.stopPropagation();
		if ($('#hamburgerbtn').hasClass('active')) {
			$('#hamburgerbtn').removeClass('active');
		} else {
			$('#hamburgerbtn').addClass('active');
		}

		// show hide paragraph on button click
		$('#hamburgermenu').toggle('slow', function () {});
	}

	getPX(obj) {
		var value = '';
		if (obj !== undefined && obj !== null && obj !== 0 && obj !== '') {
			value = obj.toString().replace('%', '0') + 'px';
		} else {
			value = '600px';
		}
		return value;
	}

	modalPage(url, width, height) {
		const dialogConfig = new MatDialogConfig();

		if (url.includes('?')) url = url + '&modal=1';
		else url = url + '?modal=1';

		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
		dialogConfig.hasBackdrop = true;
		dialogConfig.backdropClass = 'bg-color-mobile-bg';
		dialogConfig.height = this.getPX(height);
		dialogConfig.width = this.getPX(width);
		dialogConfig.data = { URL: url, HEIGHT: this.getPX(height) };
		dialogConfig.panelClass = 'icon-outside';

		const dialogRef = this.dialog.open(IframeLookupComponent, dialogConfig);
	}

	canShowButton(content) {
		if (content['styles']['show_if_logged_in'] === undefined || !content['styles']['show_if_logged_in']) {
			if (content['styles']['show_if_logged_out'] !== undefined && content['styles']['show_if_logged_out']) {
				return !this.ecomService.AppData.isLoggedin;
			}
			return true;
		} else {
			return this.ecomService.AppData.isLoggedin;
		}
	}

	loadHumblecode() {
		window.open('https://gethumblecode.com/home', '_blank');
	}

	goToUrl(url, target, content) {
		if (url !== null && url !== undefined && url !== '') {
			if (url.includes('#custid#')) {
				if (this.bookingService.AppBookingData.isLoggedin === true || this.isLoggedIn) {
					url = url.replace('#custid#', this.bookingService.AppBookingData.userData['ID']);
					window.open(url, '_self');
				} else {
					var loginUrl = this.bookingService.AppBookingData.LoginPage;
					this.router.navigate([loginUrl]);
				}
			} else {
				if (target === undefined || target === '' || target === '_self') {
					this.router.navigate([url]);
				} else if (target === 'modal') {
					this.modalPage(url, content['modal-width'], content['modal-height']);
				} else {
					window.open(url, '_blank');
				}
			}

			if ($('#hamburgerbtn').hasClass('active')) {
				$('#hamburgerbtn').removeClass('active');
				$('#hamburgermenu').toggle('slow', function () {});
			}
		}
	}

	stripHTML(val) {
		return val.replace(/<.*?>/g, '');
	}

	getPageStyles(obj) {
		if (obj['modal-window-radius'] !== undefined && obj['modal-window-radius'] !== null && obj['modal-window-radius'] !== '') {
			document.body.style.setProperty('--modal-window-radius', obj['modal-window-radius'] + 'px');
		} else {
			document.body.style.setProperty('--modal-window-radius', '0px');
		}
		return this.pageFunctions.getPageStyles(obj);
	}

	getRowStyles(row, obj, idx) {
		if (row['gen-style'] === undefined) {
			row['gen-style'] = this.pageFunctions.getRowStyles(row, obj, idx, this.isAppleDevice);
		}
		return row['gen-style'];
	}

	getRowBackgroundColor(obj) {
		return this.pageFunctions.getRowBackgroundColor(obj);
	}

	getColumnBackgroundColor(obj) {
		return this.pageFunctions.getRowBackgroundColor(obj);
	}

	getColumnStyles(column, rowObj) {
		if (this.designType !== 'MOBILE') {
			column['gen-style'] = this.pageFunctions.getColumnStyles(column, 1);
		} else {
			column['gen-style'] = { padding: '0px' };
		}
		//commented on 17-Mar-2022, row height is less than column height in mobile
		// and column gets hidden, click event of contents in the next row is not working.
		// if (this.isMobile() && column.contents.length === 0) {
		// 	style["min-height"] = '250px';
		// }

		return column['gen-style'];
	}

	getStyles(row, column, content, obj) {
		return this.getStylesType(row, column, content, obj, '');
	}

	getImageStyles(content) {
		var style = {};
		style['width'] = '100%';
		style['height'] = '100%';
		return style;
	}

	getInputStyle(content, obj) {
		var style: any = {};

		if (content['input-style'] === undefined) {
			if (obj === undefined) {
				obj = {
					color: '#495057',
					'background-color': '#fff',
					'font-weight': 'normal',
					'font-style': 'normal',
					'text-decoration': 'normal',
					'border-top': 0,
					'border-right': 0,
					'border-bottom': 0,
					'border-left': 0,
					'border-style': '',
					position: '',
					'border-color': '#495057',
					'border-top-left-radius': 0,
					'border-top-right-radius': 0,
					'border-bottom-left-radius': 0,
					'border-bottom-right-radius': 0,
				};
			}

			if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
				style['font-weight'] = obj['font-weight'];
			}

			if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
				style['font-style'] = obj['font-style'];
			}

			if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
				style['text-decoration'] = obj['text-decoration'];
			}

			if (obj['color'] !== null && obj['color'] !== undefined) {
				style['color'] = obj['color'];
			}

			if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
				style['background-color'] = obj['background-color'];
			}

			if (obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
				this.pageFunctions.setBorder(style, obj, true);
			} else {
				this.pageFunctions.setBorder(style, obj, false);
			}

			this.pageFunctions.serBorderRadius(style, obj);

			content['input-style'] = style;
		}
		style = null;
		return content['input-style'];
	}

	getFormButtonStyle(obj, selected) {
		var style: any = {};

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		if (selected) {
			style['background-color'] = '#0495B2';
			style['color'] = '#fff';
		} else {
			if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
				style['background-color'] = obj['background-color'];
			}
			if (obj['color'] !== null && obj['color'] !== undefined) {
				style['color'] = obj['color'];
			}
		}

		if (obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
			this.pageFunctions.setBorder(style, obj, true);
		} else {
			this.pageFunctions.setBorder(style, obj, false);
		}

		this.pageFunctions.serBorderRadius(style, obj);

		return style;
	}

	getSubmitStyles(content) {
		var style = {};
		if (content['submit_styles'] !== undefined) {
			var obj = content['submit_styles'];
			style = this.pageFunctions.getFormButtonStyle(obj);
			this.pageFunctions.setPadding(style, obj);
			style['text-decoration'] = obj['text-decoration'];
			style['font-size'] = obj['font-size'];
			if (obj['font-family'] !== undefined) style['font-family'] = obj['font-family'];
		}
		return style;
	}

	getStylesWithShadow(row, column, content, obj, contentType, objShadow) {
		var style: any = {};

		if (content['style-' + contentType + '-' + content.id] === undefined) {
			style = this.getStylesType(row, column, content, obj, contentType);
			this.pageFunctions.setShadow(style, objShadow);

			content['style-' + contentType + '-' + content.id] = style;
		}
		style = null;

		return content['style-' + contentType + '-' + content.id];
	}

	getSkewState(obj) {
		var rotate = '';
		if (this.pageFunctions.checkNull(obj['rotate'])) {
			rotate = 'rotate(' + obj['rotate'] + 'deg)';
		}
		if (this.pageFunctions.checkNull(obj['skew']) && obj['skew'] !== '0') {
			rotate += 'skew(' + obj['skew'] + 'deg,0deg)';
		}
		return rotate;
	}

	setHamburgerDefault() {
		var obj = {
			icon_name: 'fas fa-bars',
			'text-align': 'center',
			'background-color': '#fff',
			color: '#000',
			'padding-right': 0,
			'padding-left': 0,
			'padding-top': 0,
			'padding-bottom': 0,
			'font-size': '16px',
			'border-style': 'solid',
			'border-width': 1,
			'border-color': '#000',
			'border-radius': 0,
			'border-top': 1,
			'border-right': 1,
			'border-bottom': 1,
			'border-left': 1,
		};
		return obj;
	}

	getStylesType(row, column, content, obj, contentType) {
		var style: any = {};
		var column_border = 0;

		if (contentType === 'MobileMenuGroup' && obj === undefined) {
			content['hamburger-styles'] = this.setHamburgerDefault();
			obj = content['hamburger-styles'];
		}
		if(contentType === 'Spline'){
			style['overflow'] = 'hidden';
		}
		if (contentType !== 'CarouselImage') {
			if (!this.pageFunctions.checkNull(obj.animation) && (obj.opacityAlreadySet === undefined || obj.opacityAlreadySet)) {
				style['opacity'] = obj['opacity'];
			}
		}
		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = 'block';
			style['width'] = '100%';
		}
		if (contentType === 'Text') {
			style['display'] = 'flex';
			if (obj['icon_name']) {
				style['align-items'] = obj['icon-v-align'];
				style['flex-direction'] = obj['icon-flex-direction'] ? obj['icon-flex-direction'] : 'row';
			} else {
				style['flex-direction'] = 'column';
			}
		}
		if (contentType !== 'Text' && contentType !== 'TextArea') {
			if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
				style['font-family'] = obj['font-family'];
			}
			if (contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub') {
				if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
					style['text-align'] = obj['text-align'];
				}
			} else {
				style['display'] = 'inline-block';
			}

			if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
				style['justify-content'] = obj['justify-content'];
			}
		} else {
			style['text-align'] = 'left';
			style['justify-content'] = 'left';
		}

		if (contentType !== 'TextArea' && contentType !== 'BLOG' && contentType !== 'Forms') {
			this.pageFunctions.setShadow(style, obj);
		}

		if (obj['letter-spacing'] !== null && obj['letter-spacing'] !== undefined) {
			style['letter-spacing'] = obj['letter-spacing'];
		}
		this.pageFunctions.checkNullAndAssign(style, obj, 'clip-path', '');
		if (contentType !== 'TextArea' && contentType !== 'MenuGroupPrimary') {
			this.pageFunctions.setPadding(style, obj);
		}
		if (contentType !== 'CLIPATH' && contentType !== 'TextArea') {
			if (this.pageFunctions.checkNull(obj['rotate'])) {
				style['rotate'] = obj['rotate'] + 'deg';
			}
		}

		if (contentType !== 'TextArea' && contentType !== 'Image' && contentType !== 'Carousel') {
			if (contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Main') {
				if (contentType === 'Shape' || contentType === 'CLIPATH') {
					if (obj['border-color'] !== undefined && obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
						this.pageFunctions.setBorder(style, obj, true);
					} else {
						this.pageFunctions.setBorder(style, obj, false);
					}
				} else {
					this.pageFunctions.setBorder(style, obj, true);
				}

				this.pageFunctions.serBorderRadius(style, obj);
			}
			if (contentType !== 'CarouselImage' && contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Main' && contentType !== 'BLOG' && contentType !== 'Forms' && contentType !== 'TypeForm' && contentType !== 'Text') {
				if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
					style['border-radius'] = obj['border-radius'] + 'px';
				}
			}
			this.pageFunctions.setBackgroundImage(style, obj);
			this.pageFunctions.setBackground(style, obj);
			if (contentType === 'CLIPATH') {
				style['background-color'] = obj['background-color'];
			}
		}

		if (contentType === 'MenuGroup' && obj['menu-border-style'] !== null && obj['menu-border-style'] !== undefined) {
			if (obj['menu-spacing'] !== null && obj['menu-spacing'] !== undefined) {
				style['margin-right'] = obj['menu-spacing'] + 'px';
			}
			if (obj['menu-border-top'] !== null && obj['menu-border-top'] !== undefined) {
				style['border-top'] = obj['menu-border-top'] + 'px ' + obj['menu-border-style'] + ' ' + obj['menu-border-color'];
			}
			if (obj['menu-border-bottom'] !== null && obj['menu-border-bottom'] !== undefined) {
				style['border-bottom'] = obj['menu-border-bottom'] + 'px ' + obj['menu-border-style'] + ' ' + obj['menu-border-color'];
			}
			if (obj['menu-border-left'] !== null && obj['menu-border-left'] !== undefined) {
				style['border-left'] = obj['menu-border-left'] + 'px ' + obj['menu-border-style'] + ' ' + obj['menu-border-color'];
			}
			if (obj['menu-border-right'] !== null && obj['menu-border-right'] !== undefined) {
				style['border-right'] = obj['menu-border-right'] + 'px ' + obj['menu-border-style'] + ' ' + obj['menu-border-color'];
			}
		}
		if (contentType === 'Line' || contentType === 'Lottie' || contentType === 'Spline' || contentType === 'Shape' || contentType === 'CLIPATH' || contentType === 'Text' || contentType === 'Image' || contentType === 'Button') {
			style['position'] = obj['position'];
		} else if (contentType === 'Forms' || contentType === 'TypeForm') {
			this.pageFunctions.checkNullAndAssign(style, obj, 'position', '');
		}

		if (obj['mobile-background-color'] !== null && obj['mobile-background-color'] !== undefined && contentType === 'MobileMenu') {
			style['background-color'] = obj['mobile-background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style['line-height'] = obj['line-height'];
		}

		if (contentType !== 'TextArea' && contentType !== 'FAQ' && contentType !== 'BLOG' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub') {
			if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== '' && obj['height'] !== 0) {
				style['height'] = this.calculateHeightForResolution(obj['height']) + 'px';
			}
		}

		if (contentType !== 'TextArea' && contentType !== 'CLIPATH' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub') {
			if (contentType === 'Lottie' ||  contentType === 'Spline' || contentType === 'FAQ' || contentType === 'BLOG' || contentType === 'Forms' || contentType === 'TypeForm' || contentType === 'Line' || contentType === 'Shape' || contentType === 'Button' || contentType === 'Text' || contentType === 'TextArea' || contentType === 'Carousel') {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					var objWidth = obj['width'];
					if (contentType === 'Text') {
						if (obj['width-type'] !== null && obj['width-type'] !== undefined && obj['width-type'] !== '' && obj['width-type'] === 'px') {
							//cargo cult
						} else {
							objWidth = obj['width'] * 13.8;
							obj['width-type'] = 'px';
						}
					}
					if (this.page.styles['page-width'] === 1366) style['width'] = this.calculateWidthForResolution(row, column, objWidth) + '%';
					else style['width'] = objWidth + 'px';

					style['width-type'] = 'px';
				}
			} else if (contentType === 'Image') {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					style['width'] = this.calculateWidthForResolution(row, column, obj['width']) + '%';
				}
			} else {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					style['width'] = obj['width'] + '%';
				}
			}
		}
		if (contentType !== 'TextArea' && contentType !== 'MenuGroup' && contentType !== 'CLIPATH' && contentType !== 'MobileMenu' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub') {
			if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== '') {
				style['margin-top'] = obj['margin-top'];
			}
			if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== '') {
				style['margin-left'] = this.getLeftMargin(obj, row);
			}
		}

		//left padding is required only for desktop view and not needed for mobile view
		if (this.window_width > 700 || this.page.styles['page-width'] !== 1366) {
		} else {
			// for image containers in mobile view, if the width of the
			//set image is less than 200(used for icons) then set the width
			//instead of 100%
			if (contentType === 'Lottie' || contentType === 'Spline' || contentType === 'Shape' || contentType === 'CLIPATH' || contentType === 'Text' || contentType === 'Image' || contentType === 'FAQ' || contentType === 'BLOG' || contentType === 'Forms' || contentType === 'TypeForm' || contentType === 'Button') {
				var wid = obj['width'];
				if (!isNaN(wid)) {
					// width cannot be adjusted based on res for lower resolutions, since it will
					//not be proportional to the image
					//image doesnt appear centered

					if (contentType !== 'Image' || wid > 200) {
						wid = (wid * this.window_width) / 357;
						wid = Math.round(wid);
					} else {
						if (parseInt(wid) > this.window_width) {
							wid = this.window_width;
						}
					}
				}
				style['width'] = wid + 'px';
			}
		}
		content['style-' + contentType + '-' + content.id] = style;

		style = null;

		return content['style-' + contentType + '-' + content.id];
	}

	getContentWidth(wid) {
		if (this.window_width > 700 || this.page.styles['page-width'] != 1366) {
		} else {
			if (!isNaN(wid)) {
				if (wid > 200) {
					wid = (wid * this.window_width) / 357;
					wid = Math.round(wid);
				} else {
					if (parseInt(wid) > this.window_width) {
						wid = this.window_width;
					}
				}
				//reduce scroller width 9px
				wid = wid - 9;
			}
		}
		return wid;
	}

	getMarginTopLeft(row, obj) {
		var style = {};
		if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== '') {
			style['margin-top'] = obj['margin-top'];
		}
		if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== '') {
			style['margin-left'] = this.getLeftMargin(obj, row);
		}
		return style;
	}

	getLeftMargin(obj, row) {
		var adjustMargin = parseInt(obj['margin-left'].toString().replace('px', ''));

		if (this.window_width < 700) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 357;
		} else if (this.window_width < 1000) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 768;
		} else if (this.window_width < 1366 || (row['id'] !== undefined && row.styles['row-full-width'] === true)) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 1366;
		}
		adjustMargin = Math.max(0, adjustMargin);
		return adjustMargin + 'px';
	}

	calculateWidthForResolutionWithMobile(row, column, width) {
		if (this.window_width > 700 || this.page.styles['page-width'] != 1366) {
			return this.calculateWidthForResolution(row, column, width) + '%';
		} else {
			// for image containers in mobile view, if the width of the
			//set image is less than 200(used for icons) then set the width
			var wid = width;
			if (!isNaN(wid)) {
				wid = (wid * this.window_width) / 357;
				wid = Math.round(wid);
				//reduce scroller width 9px
				wid = wid - 9;
			}
			return wid + 'px';
		}
	}
	//screen width used in configuration is 1366, calculating the proportion
	//based on configured width and current screen display width
	calculateWidthForResolution(row, column, width) {
		var ww = 100;
		var col = 0;
		var column_border = 0;
		if (row.styles['is-grid-layout']) {
			if (column.styles['column-value']) {
				col = this.getTotalColumnsForGrid(column.styles['column-value']);
			} else {
				col = 1;
			}
		} else {
			if (column.class === 'column-1') {
				col = 1;
			} else if (column.class === 'column-2') {
				col = 2;
			} else if (column.class === 'column-3') {
				col = 3;
			} else if (column.class === 'column-4') {
				col = 4;
			} else if (column.class === 'column-5') {
				col = 5;
			} else if (column.class === 'column-6') {
				col = 6;
			} else if (column.class === 'column-7') {
				col = 7;
			} else if (column.class === 'column-8') {
				col = 8;
			} else if (column.class === 'column-9') {
				col = 9;
			} else if (column.class === 'column-10') {
				col = 10;
			} else if (column.class === 'column-11') {
				col = 11;
			} else if (column.class === 'column-12') {
				col = 12;
			}
		}
		if (column.styles !== undefined && column.styles['border-left'] !== undefined && column.styles['border-left'] !== '') {
			column_border += parseInt(column.styles['border-left']) || 0;
		}
		if (column.styles !== undefined && column.styles['border-right'] !== undefined && column.styles['border-right'] !== '') {
			column_border += parseInt(column.styles['border-right']) || 0;
		}

		if (this.window_width < 1000) ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (768 / (12 - col + 1)))) / this.window_width) * (12 / col);
		else {
			ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (1336 / (12 - col + 1)))) / this.window_width) * (12 / col);
		}

		return ww;
	}

	getTotalColumnsForGrid(fraction) {
		return fraction;
	}

	calculateHeightForResolution(height) {
		return height;
	}

	getMarginClass(size) {
		try {
			if (parseInt(size.replace('px', '')) > 100) {
				return true;
			}
		} catch (e) {}
		return false;
	}

	getFontClass(size) {
		try {
			if (parseInt(size.replace('px', '')) > 30) {
				return true;
			}
		} catch (e) {}
		return false;
	}

	switchImage(id, enter, content) {
		var ele = document.getElementById('image' + id);

		if (!this.pageFunctions.checkNull(content.styles['hover-url'])) return;

		if (ele) {
			ele.setAttribute('src', enter ? content.styles['hover-url'] : content['url']);
		}
	}

	setOpacity(content, blnSetOpacity, idPrefix) {
		content.styles.opacityAlreadySet = true;
		try {
			var content_ele_row = document.getElementById(idPrefix + content.id);
			if (content_ele_row) {
				if (blnSetOpacity) {
					content_ele_row.style.opacity = content.styles.opacity;
				}
				if (this.pageFunctions.checkNull(content.styles['animation-offset'])) {
					var animationOffsetWidth = content.styles['animation-offset'];
					if (content.styles.animation === 'expandFromRight' || content.styles.animation === 'expandFromLeft') {
						animationOffsetWidth = this.getContentWidth(animationOffsetWidth);
					}
					content_ele_row.style.setProperty('--from-width', animationOffsetWidth + 'px');
					content_ele_row.style.setProperty('overflow', 'hidden');
				}
				if (content.styles['onhover-animation'] && content.type === 'Button') {
					content_ele_row.style.setProperty('--border-top-left-radius', content.styles['border-top-left-radius'] + 'px');
					content_ele_row.style.setProperty('--border-top-right-radius', content.styles['border-top-right-radius'] + 'px');
					content_ele_row.style.setProperty('--border-bottom-left-radius', content.styles['border-bottom-left-radius'] + 'px');
					content_ele_row.style.setProperty('--border-bottom-right-radius', content.styles['border-bottom-right-radius'] + 'px');
				}

				if (this.pageFunctions.checkNull(content.styles['animation-start-delay'])) {
					content_ele_row.style.setProperty('--animation-delay', content.styles['animation-start-delay'] + 's');
				} else {
					content_ele_row.style.setProperty('--animation-delay', '0s');
				}
				if (this.pageFunctions.checkNull(content.styles['animation-iteration'])) {
					content_ele_row.style.setProperty('--animation-iteration', content.styles['animation-iteration']);
				} else {
					content_ele_row.style.setProperty('--animation-iteration', '1');
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	getShadowStyle(obj) {
		var style = {};
		this.pageFunctions.setShadow(style, obj);
		if (obj.type !== 'Video') {
			if (!this.pageFunctions.checkNull(obj.animation) && (obj.opacityAlreadySet === undefined || obj.opacityAlreadySet)) {
				style['opacity'] = obj['opacity'];
			}
		}
		return style;
	}

	getSubMenuStyles(obj, contentType) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style['font-family'] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style['text-align'] = obj['text-align'];
		}
		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}
		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}
		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}
		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		this.pageFunctions.setBorder(style, obj, true);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style['border-radius'] = obj['border-radius'] + 'px';
		}

		if (contentType !== 'MobileMenu') {
			this.pageFunctions.setPadding(style, obj);
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style['background-color'] = obj['background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		return style;
	}

	ngOnInit(): void {
		this.formHeight = window.innerHeight;
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
			this.window_width = window.innerWidth;
		});
	}

	ngAfterViewInit(): void {}

	formSubmit($ev: any, formId: any, fields: any, content: any) {
		$ev.preventDefault();

		var validData: boolean = false;

		content['form']['submitted'] = true;

		if (fields.length > 0) {
			validData = true;
			fields.forEach((currentField) => {
				if (content.type === 'TypeForm') {
					if (currentField['IsMandatory'] === '1' && currentField['Flow_SlNo'] > 0 && currentField['DataType'] !== 'STATEMENT' && currentField['DataType'] !== 'BIT' && (this.pageValues[formId][currentField['Field_Name']] === null || this.pageValues[formId][currentField['Field_Name']] === '' || ((currentField['DataType'] === 'RATING' || currentField['DataType'] === 'SCALE') && this.pageValues[formId][currentField['Field_Name']] === 0))) {
						validData = false;
					}
				} else {
					if (currentField['IsMandatory'] === '1' && currentField['DataType'] !== 'STATEMENT' && currentField['DataType'] !== 'BIT' && (this.pageValues[formId][currentField['Field_Name']] === null || this.pageValues[formId][currentField['Field_Name']] === '' || ((currentField['DataType'] === 'RATING' || currentField['DataType'] === 'SCALE') && this.pageValues[formId][currentField['Field_Name']] === 0))) {
						validData = false;
					}
				}
			});
		}

		if (validData) {
			fields.forEach((currentField) => {
				if (currentField['DataType'] === 'BIT') {
					this.pageValues[formId][currentField['Field_Name']] = this.pageValues[formId][currentField['Field_Name']] === true ? '1' : '0';
				}
			});
			this.dataService.manageFormData(this.pageValues[formId]).subscribe(
				(res) => {
					if (res.RESULT_CODE === 1) {
						this.toastr.success('Success', 'Submitted Successfully !!', {
							timeOut: 3000,
						});
						this.clearFormData(formId);
						content['form']['submitted'] = false;
						if (content.type === 'TypeForm') this.initiateTypeFormData(content);
					} else if (res.RESULT_CODE === 2) {
						this.toastr.info('Information', 'This form is no longer active !!', {
							timeOut: 3000,
						});
					} else {
						this.toastr.error('Error', 'Error while saving !!', {
							timeOut: 3000,
						});
					}
				},
				(error) => {
					this.toastr.error('Error', 'Error while saving !!', {
						timeOut: 3000,
					});
					console.error(error);
				}
			);
		} else {
			this.toastr.error('Cannot Save', 'Mandatory Fields not filled !!', {
				timeOut: 3000,
			});
		}
	}

	setOpinionScale(content, field, scale) {
		this.pageValues[content['form']['ID']][field['Field_Name']] = scale;
	}

	pageSubmit($ev: any, formkey: any) {
		$ev.preventDefault();
		this.dataService.manageFormData(this.pageValues[formkey]).subscribe(
			(res) => {
				if (res.RESULT_CODE === 1) {
					this.toastr.success('Success', 'Submitted Successfully !!', {
						timeOut: 3000,
					});
					this.clearFormData(formkey);
				} else {
					this.toastr.error('Error', 'Error while saving !!', {
						timeOut: 3000,
					});
				}
			},
			(error) => {
				this.toastr.error('Error', 'Error while saving !!', { timeOut: 3000 });
				console.error(error);
			}
		);
	}

	clearFormData(formkey) {
		for (var key in this.pageValues[formkey]) {
			if (key !== 'TABLE_NAME' && key !== 'CLIENT_KEY' && key !== 'WEBSITE_ID') this.pageValues[formkey][key] = '';
		}
	}

	loadPageExpression() {
		var pageExpressions = [];
		var variableValues = {};
		var validCondition = false;

		pageExpressions = this.expressions.filter((expr) => expr.Type !== undefined && expr.Type === 'On Load');
		pageExpressions.forEach((pageExpression) => {
			variableValues = {};
			validCondition = this.evaluateCondition(pageExpression, {});
			if (validCondition) {
				pageExpression['Steps'].forEach((step) => {
					if (step['Field_Type'] === 'Field') {
						this.setFieldValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Variable') {
						this.setVariableValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Expression') {
						this.executeExpression(step['Field_ID'], {});
					} else if (step['Field_Type'] === 'Message') {
						this.displayMessage(step['Field_ID']);
					}
				});
			}
		});
	}

	onValueChange(field) {
		if (field['Expression'] !== undefined && field['Expression'] !== null && field['Expression'] !== '') {
			this.executeExpression(field['Expression'], {});
		}
	}

	executeExpression(expressionID, rowData) {
		var expression = [];
		var fldExpression = {};
		var variableValues = {};
		var validCondition = false;
		if (expressionID !== undefined) {
			expression = this.expressions.filter((expr) => expr.ID === expressionID);
			if (expression.length > 0) {
				fldExpression = expression[0];

				validCondition = this.evaluateCondition(fldExpression, rowData);
				if (validCondition) {
					fldExpression['Steps'].forEach((step) => {
						if (step['Field_Type'] === 'Field') {
							this.setFieldValue(step, variableValues, rowData);
						} else if (step['Field_Type'] === 'Variable') {
							this.setVariableValue(step, variableValues, rowData);
						} else if (step['Field_Type'] === 'Expression') {
							this.executeExpression(step['Field_ID'], rowData);
						} else if (step['Field_Type'] === 'Message') {
							this.displayMessage(step['Field_ID']);
						}
					});
				}
			}
		}
	}

	evaluateCondition(expression, rowData) {
		var isValid = false;
		if (expression.Conditions === undefined || expression.Conditions.length === 0) {
			isValid = true;
		} else {
			var condOperator = expression.Condition_Operator;
			var conditions = JSON.parse(JSON.stringify(expression.Conditions));
			conditions.forEach((cond) => {
				if (cond.DataType === 'TINY_STRING') cond['Status'] = this.checkConditionValue(cond.Operator, this.pageValues[cond.Field_ID], cond.Value_Type === 'Field' ? this.pageValues[cond.Value] : cond.Value);
				else if (cond.DataType === 'INTEGER') cond['Status'] = this.checkConditionValue(cond.Operator, parseInt(this.pageValues[cond.Field_ID]), cond.Value_Type === 'Field' ? parseInt(this.pageValues[cond.Value]) : parseInt(cond.Value));
				else if (cond.DataType === 'DECIMAL') cond['Status'] = this.checkConditionValue(cond.Operator, parseFloat(this.pageValues[cond.Field_ID]), cond.Value_Type === 'Field' ? parseFloat(this.pageValues[cond.Value]) : parseFloat(cond.Value));
				else if (cond.DataType === 'DATE') cond['Status'] = this.checkDateValue(cond.Operator, this.pageValues[cond.Field_ID], this.pageValues[cond.Value]);
			});
			if (condOperator === 'AND') {
				var failedConditions = conditions.filter((cond) => cond.Status === false);
				if (failedConditions.length === 0) {
					isValid = true;
				}
			} else if (condOperator === 'OR') {
				var successConditions = conditions.filter((cond) => cond.Status === true);
				if (successConditions.length > 0) {
					isValid = true;
				}
			}
		}
		return isValid;
	}

	checkConditionValue(operator, value1, value2) {
		var retValue = false;
		if (operator === 'LIKE') {
			retValue = value1.includes(value2);
		} else if (operator === 'EQ') {
			retValue = value1 === value2;
		} else if (operator === 'NEQ') {
			retValue = value1 !== value2;
		} else if (operator === 'GT') {
			retValue = value1 > value2;
		} else if (operator === 'GTE') {
			retValue = value1 >= value2;
		} else if (operator === 'LT') {
			retValue = value1 < value2;
		} else if (operator === 'LTE') {
			retValue = value1 <= value2;
		}
		return retValue;
	}

	checkDateValue(operator, val1, val2) {
		var retValue = false;

		if (val1 === null || val1 === '' || val2 === null || val2 === '') {
			return retValue;
		}

		var value1 = new Date(val1);
		var value2 = new Date(val2);

		if (operator === 'EQ') {
			retValue = value1 === value2;
		} else if (operator === 'NEQ') {
			retValue = value1 !== value2;
		} else if (operator === 'GT') {
			retValue = value1 > value2;
		} else if (operator === 'GTE') {
			retValue = value1 >= value2;
		} else if (operator === 'LT') {
			retValue = value1 < value2;
		} else if (operator === 'LTE') {
			retValue = value1 <= value2;
		}
		return retValue;
	}

	setVariableValue(step, variableValues, rowData) {
		if (step.Value_Type === 'Field') {
			variableValues[step['Field_ID']] = this.pageValues[step['Field_Value']];
		} else if (step.Value_Type === 'Variable') {
			variableValues[step['Field_ID']] = variableValues[step['Field_Value']];
		} else if (step.Value_Type === 'Value') {
			variableValues[step['Field_ID']] = step['Field_Value'];
		} else if (step.Value_Type === 'Function') {
			variableValues[step['Field_ID']] = this.getFunctionValue(step['Function'], variableValues, rowData);
		}
	}

	setFieldValue(step, variableValues, rowData) {
		if (step.Value_Type === 'Field') {
			this.pageValues[step['Field_ID']] = this.pageValues[step['Field_Value']];
		} else if (step.Value_Type === 'Variable') {
			this.pageValues[step['Field_ID']] = variableValues[step['Field_Value']];
		} else if (step.Value_Type === 'Value') {
			this.pageValues[step['Field_ID']] = step['Field_Value'];
		} else if (step.Value_Type === 'Function') {
			this.pageValues[step['Field_ID']] = this.getFunctionValue(step['Function'], variableValues, rowData);
		} else if (step.Value_Type === 'Property') {
			this.setProperty(step['Field_ID'], step['Field_Value']);
		}
	}

	getFunctionValue(fn, variableValues, rowData) {
		var steps = fn['Steps'];
		var retValue: any = null;

		if (fn['Name'] === 'Concat') {
			retValue = '';
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = retValue + this.pageValues[step['Field_ID']];
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = retValue + variableValues[step['Field_ID']];
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') retValue = retValue + step['Field_ID'];
				}
			});
		} else if (fn['Name'] === 'Add') {
			retValue = 0;
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = retValue + parseFloat(this.pageValues[step['Field_ID']]);
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = retValue + parseFloat(variableValues[step['Field_ID']]);
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') retValue = retValue + parseFloat(step['Field_ID']);
				}
			});

			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Subtract') {
			retValue = 0;
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(this.pageValues[step['Field_ID']]);
						} else {
							retValue = parseFloat(this.pageValues[step['Field_ID']]);
						}
					}
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(variableValues[step['Field_ID']]);
						} else {
							retValue = parseFloat(variableValues[step['Field_ID']]);
						}
					}
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(step['Field_ID']);
						} else {
							retValue = parseFloat(step['Field_ID']);
						}
					}
				}
			});
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Multiply') {
			retValue = 1;
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = retValue * parseFloat(this.pageValues[step['Field_ID']]);
					else retValue = 0;
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = retValue * parseFloat(variableValues[step['Field_ID']]);
					else retValue = 0;
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') retValue = retValue * parseFloat(step['Field_ID']);
					else retValue = 0;
				}
			});
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Divide') {
			retValue = 0;
			var dividend = 0;
			var divisor = 1;
			var step = steps[0];
			if (step['Field_Type'] === 'Field') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') dividend = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') dividend = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') dividend = parseFloat(step['Field_ID']);
			}

			step = steps[1];
			if (step['Field_Type'] === 'Field') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') divisor = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') divisor = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') divisor = parseFloat(step['Field_ID']);
			}

			retValue = dividend / (divisor === 0 ? 1 : divisor);
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Percent') {
			retValue = 0;
			var mainValue = 0;
			var percent = 0;
			var step = steps[0];
			if (step['Field_Type'] === 'Field') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') mainValue = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') mainValue = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') mainValue = parseFloat(step['Field_ID']);
			}

			step = steps[1];
			if (step['Field_Type'] === 'Field') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') percent = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') percent = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') percent = parseFloat(step['Field_ID']);
			}

			retValue = (mainValue * percent) / 100;
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Round') {
			retValue = 0;
			var mainValue = 0;
			var round = 0;
			var step = steps[0];

			if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') {
				mainValue = parseFloat(variableValues[step['Field_ID']]);
			}

			step = steps[1];
			if (step['Field_ID'] !== null && step['Field_ID'] !== '') {
				round = parseFloat(step['Field_ID']);
			}

			retValue = mainValue.toFixed(round);
		} else if (fn['Name'] === 'Power') {
			retValue = 0;
			var mainValue = 0;
			var powerno = 1;
			var step = steps[0];

			if (step['Field_Type'] === 'Field') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') mainValue = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') mainValue = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') mainValue = parseFloat(step['Field_ID']);
			}

			step = steps[1];
			if (step['Field_Type'] === 'Field') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') powerno = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') powerno = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') powerno = parseFloat(step['Field_ID']);
			}

			retValue = Math.pow(mainValue, powerno);
		} else if (fn['Name'] === 'Sum') {
			retValue = 0;
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Min') {
			retValue = 0;

			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Max') {
			retValue = 0;
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'CurrDate') {
			var today = new Date();
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();

			var date_day = '';
			var date_month = '';

			if (dd < 10) {
				date_day = '0' + dd;
			} else {
				date_day = dd.toString();
			}
			if (mm < 10) {
				date_month = '0' + mm;
			} else {
				date_month = mm.toString();
			}

			retValue = yyyy + '-' + date_month + '-' + date_day;
		} else if (fn['Name'] === 'CurrMonth') {
			var currDate = new Date();
			var mm = currDate.getMonth() + 1;

			if (mm < 10) {
				retValue = '0' + mm;
			} else {
				retValue = mm.toString();
			}
		} else if (fn['Name'] === 'CurrMonthName') {
			var currDate = new Date();
			retValue = this.monthNames[currDate.getMonth()];
		} else if (fn['Name'] === 'CurrYear') {
			var currDate = new Date();
			retValue = currDate.getFullYear();
		} else if (fn['Name'] === 'Age') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];
			var dob = new Date(dateValue);
			var month_diff = Date.now() - dob.getTime();
			var age_dt = new Date(month_diff);
			var year = age_dt.getUTCFullYear();
			var age = Math.abs(year - 1970);

			retValue = age.toString();
		} else if (fn['Name'] === 'Day') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];
			var dd = 1;

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				dd = currDate.getDate();
			} else {
				var dateVal = new Date(dateValue);
				dd = dateVal.getDate();
			}

			if (dd < 10) {
				retValue = '0' + dd;
			} else {
				retValue = dd.toString();
			}
		} else if (fn['Name'] === 'Month') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];
			var mm = 1;

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				mm = currDate.getMonth() + 1;
			} else {
				var dateVal = new Date(dateValue);
				mm = dateVal.getMonth() + 1;
			}

			if (mm < 10) {
				retValue = '0' + mm;
			} else {
				retValue = mm.toString();
			}
		} else if (fn['Name'] === 'MonthName') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				retValue = this.monthNames[currDate.getMonth()];
			} else {
				var dateVal = new Date(dateValue);
				retValue = this.monthNames[dateVal.getMonth()];
			}
		} else if (fn['Name'] === 'Year') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				retValue = currDate.getFullYear();
			} else {
				var dateVal = new Date(dateValue);
				retValue = dateVal.getFullYear();
			}
		}

		return retValue;
	}

	getFormattedDate(format, date_day, date_month, date_year) {
		var value = '';
		if (format === 'dd/MM/yyyy') {
			value = date_day + '/' + date_month + '/' + date_year;
		} else if (format === 'dd-MM-yyyy') {
			value = date_day + '-' + date_month + '-' + date_year;
		} else if (format === 'MM/dd/yyyy') {
			value = date_month + '/' + date_day + '/' + date_year;
		} else if (format === 'MM-dd-yyyy') {
			value = date_month + '-' + date_day + '-' + date_year;
		} else if (format === 'yyyy/MM/dd') {
			value = date_year + '/' + date_month + '/' + date_day;
		} else if (format === 'yyyy-MM-dd') {
			value = date_year + '-' + date_month + '-' + date_day;
		}
		return value;
	}

	displayMessage(message) {
		this.toastr.info('Information', message, { timeOut: 3000 });
	}

	setProperty(field, property) {
		this.rows.forEach((row) => {
			row.columns.forEach((column) => {
				column.contents.forEach((content) => {
					if (content.type === 'StaticSection') {
						content['form']['fields'].forEach((fieldAttr) => {
							if (fieldAttr.Field_ID.toString() === field.toString()) {
								if (property === 'Show') {
									fieldAttr['is_Hidden'] = false;
								} else if (property === 'Hide') {
									fieldAttr['is_Hidden'] = true;
								} else if (property === 'Mandatory') {
									fieldAttr['is_Mandatory'] = true;
								} else if (property === 'Optional') {
									fieldAttr['is_Mandatory'] = false;
								} else if (property === 'Readonly') {
									fieldAttr['is_Read_Only'] = true;
								} else if (property === 'Editable') {
									fieldAttr['is_Read_Only'] = false;
								}
							}
						});
					}
				});
			});
		});
	}
	@ViewChild('carousel') carousel: CarouselComponent;
	@ViewChildren('carousel') carousels: QueryList<CarouselComponent>;

	prevSlide(index: number) {
		const carousel = document.querySelector(`carousel:nth-of-type(${index + 1})`);
		const prevButton = carousel.querySelector('.carousel-control-prev');
		prevButton.dispatchEvent(new Event('click'));
	}

	nextSlide(index: number) {
		const carousel = document.querySelector(`carousel:nth-of-type(${index + 1})`);
		const nextButton = carousel.querySelector('.carousel-control-next');
		nextButton.dispatchEvent(new Event('click'));
	}

	videoPlayClick(content: any) {
		let videoId = 'video_' + content.id;
		const videoElement = document.getElementById(videoId) as HTMLVideoElement;
		content['styles-next']['pause'] = !content['styles-next']['pause'];
		if (content['styles-next']['pause']) {
			videoElement.pause();
		} else {
			videoElement.play();
		}
	}
}
