<ng-container *ngIf="props.template==='1'">
    <div class="row mt-2">
        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" 
                    style="display: block; width: 100%;object-fit: contain;"/>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" style="position: relative;">
            <div class="row">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.TITLE}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT !== props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{props.ITEM.AMOUNT | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT === props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                    style="white-space:pre-wrap;">{{props.ITEM.DESCRIPTION}}</div>

                <div class="col-12 mb-2 table-responsive" *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0" [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;">{{info.NAME}}</th>
                                <td style="width:80%;">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 mb-2 table-responsive" 
                    *ngIf="info.ADDITIONAL_CHARGES !== undefined && info.ADDITIONAL_CHARGES.length>0
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')" 
                    [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width:5%;">..</th>
                                <th style="width:50%;">Optional Services</th>
                                <th style="width:15%;">Fees</th>
                                <th style="width:15%;">Qty</th>
                                <th style="width:15%;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let charge of info.ADDITIONAL_CHARGES | slice:0:(showAllCharges?200:3)">
                                <td *ngIf="!charge['IS_MANDATORY']">
                                    <input type="checkbox" (change)="selectCharges(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY']">
                                    <input type="checkbox" [(ngModel)]="charge['Selected']" disabled="disabled">
                                </td>
                                
                                <td>{{charge.SERVICE_NAME}}</td>
                                
                                <td>{{appData.CurrencySymbol}}{{charge.SERVICE_CHARGE}}</td>
                                
                                <td *ngIf="!charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" (blur)="onChargeQtyBlur(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" readonly="true">
                                </td>
                                <td *ngIf="!charge['Selected']"></td>
                                
                                <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="info.ADDITIONAL_CHARGES.length > 3 && !showAllCharges">
                            <tr><td colspan="5">
                                <a class="btn btn-info" (click)="showAllCharges=true">Show All Optional Charges</a>
                            </td></tr>
                        </tfoot>
                    </table>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                [ngStyle]="getStyles(props.description_styles,'')"
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0">
                Date: {{selectedVariant.SLOT_DATE}} ({{selectedVariant.START_TIME}}-{{selectedVariant.END_TIME}}) <br/>
                Registration Closes On : {{selectedVariant.BOOKING_ALLOWED_TILL}} <br/>
                    <div style="color:red" *ngIf="selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES'">
                        Late fees : {{appData.CurrencySymbol}}{{props.ITEM.LATE_BOOKING_CHARGES}} will be charged after {{selectedVariant.BOOKING_ALLOWED_TILL}} till {{selectedVariant.BOOKING_WITH_LC_TILL}}    
                    </div>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0 && 
                (selectedVariant.TOTAL_BOOKING >= selectedVariant.MAX_BOOKING || (selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'NO'))">
                    <span style="color:red">Booking Closed !</span>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER === undefined || props.ITEM.CALENDER.length===0">
                    <span style="color:red">Booking Not Available !</span>
                </div>
                
                <div class="col-6" *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>1">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Dates
                        </div>
                        <select class="service-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')" >
                            <option *ngFor="let variant of props.ITEM.CALENDER" [value]="variant.ID">{{variant.SLOT_DATE}} ({{variant.START_TIME}}-{{variant.END_TIME}})</option>
                        </select>
                    </div>
                </div>
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="service-form-control" type="number" [style]="getInputStyle(props['input_styles'],'input')"
                        [(ngModel)]="cartCount" readonly="true"/>
                    </div>
                </div> 
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                <button class="btn"
                [style.textDecoration]="props['cart_styles']['text-decoration']"
                [style.fontStyle]="props['cart_styles']['font-style']"
                [style.fontWeight]="props['cart_styles']['font-weight']"
                [style.color]="props['cart_styles']['color']"
                [style.backgroundColor]="props['cart_styles']['background-color']" 
                (click)="addToCart()">Add to Cart</button>
                </div>
            </div>
        </div>

        <div class="col-12 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="w-100 float-left mb-1" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                                style="display: block; width: 100%;object-fit: fill;">
                        </div>
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.TITLE}}
                        </div>
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')">
                            Price : {{appData.CurrencySymbol}} {{item.DISCOUNTED_AMOUNT}}
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="props.template==='2'">
    <div class="row mt-2">
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" 
        style="position: relative;">
            <div class="row">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.TITLE}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT !== props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{props.ITEM.AMOUNT | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT === props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                style="white-space:pre-wrap;">{{props.ITEM.DESCRIPTION}}</div>

                <div class="col-12 mb-2 table-responsive" *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0" [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;">{{info.NAME}}</th>
                                <td style="width:80%;">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 mb-2 table-responsive" 
                    *ngIf="info.ADDITIONAL_CHARGES !== undefined && info.ADDITIONAL_CHARGES.length>0
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')" 
                    [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width:5%;">..</th>
                                <th style="width:50%;">Optional Services</th>
                                <th style="width:15%;">Fees</th>
                                <th style="width:15%;">Qty</th>
                                <th style="width:15%;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let charge of info.ADDITIONAL_CHARGES | slice:0:(showAllCharges?200:3)">
                                <td *ngIf="!charge['IS_MANDATORY']">
                                    <input type="checkbox" (change)="selectCharges(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY']">
                                    <input type="checkbox" [(ngModel)]="charge['Selected']" disabled="disabled">
                                </td>
                                
                                <td>{{charge.SERVICE_NAME}}</td>
                                
                                <td>{{appData.CurrencySymbol}}{{charge.SERVICE_CHARGE}}</td>
                                
                                <td *ngIf="!charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" (blur)="onChargeQtyBlur(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" readonly="true">
                                </td>
                                <td *ngIf="!charge['Selected']"></td>
                                
                                <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="info.ADDITIONAL_CHARGES.length > 3 && !showAllCharges">
                            <tr><td colspan="5">
                                <a class="btn btn-info" (click)="showAllCharges=true">Show All Optional Charges</a>
                            </td></tr>
                        </tfoot>
                    </table>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                [ngStyle]="getStyles(props.description_styles,'')"
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0">
                Date: {{selectedVariant.SLOT_DATE}} ({{selectedVariant.START_TIME}}-{{selectedVariant.END_TIME}}) <br/>
                Registration Closes On : {{selectedVariant.BOOKING_ALLOWED_TILL}} <br/>
                    <div style="color:red" *ngIf="selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES'">
                        Late fees : {{appData.CurrencySymbol}}{{props.ITEM.LATE_BOOKING_CHARGES}} will be charged after {{selectedVariant.BOOKING_ALLOWED_TILL}} till {{selectedVariant.BOOKING_WITH_LC_TILL}}    
                    </div>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0 && 
                (selectedVariant.TOTAL_BOOKING >= selectedVariant.MAX_BOOKING || (selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'NO'))">
                    <span style="color:red">Booking Closed !</span>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER === undefined || props.ITEM.CALENDER.length===0">
                    <span style="color:red">Booking Not Available !</span>
                </div>
                
                <div class="col-6" *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>1">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Dates
                        </div>
                        <select class="service-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')">
                            <option *ngFor="let variant of props.ITEM.CALENDER" [value]="variant.ID">{{variant.SLOT_DATE}} ({{variant.START_TIME}}-{{variant.END_TIME}})</option>
                        </select>
                    </div>
                </div>
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="service-form-control" type="number" [style]="getInputStyle(props['input_styles'],'input')"
                        [(ngModel)]="cartCount" readonly="true"/>
                    </div>
                </div> 
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                <button class="btn"
                [style.textDecoration]="props['cart_styles']['text-decoration']"
                [style.fontStyle]="props['cart_styles']['font-style']"
                [style.fontWeight]="props['cart_styles']['font-weight']"
                [style.color]="props['cart_styles']['color']"
                [style.backgroundColor]="props['cart_styles']['background-color']" 
                (click)="addToCart()">Add to Cart</button>
                </div>
            </div>
        </div>

        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" 
                    style="display: block; width: 100%;object-fit: contain;"/>
                </div>
            </div>
        </div>
        
        <div class="col-12 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="w-100 float-left mb-1" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                            style="display: block; width: 100%;object-fit: fill;">
                        </div>  
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.TITLE}}
                        </div>
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')">
                            Price : {{appData.CurrencySymbol}} {{item.DISCOUNTED_AMOUNT}}
                        </div>
                        <!-- <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT !== props.ITEM.DISCOUNTED_AMOUNT">
                            Price : {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{props.ITEM.AMOUNT | number:'1.2-2'}}</span>  
                        </div>
                        <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT === props.ITEM.DISCOUNTED_AMOUNT">
                            Price : {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}}
                        </div> -->
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="props.template==='3'">
    <div class="row mt-2">
        
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" style="position: relative;">
            <div class="row">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.TITLE}}
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT !== props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{props.ITEM.AMOUNT | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT === props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}}
                </div>                
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                style="white-space:pre-wrap;">{{props.ITEM.DESCRIPTION}}</div>

                <div class="col-12 mb-2 table-responsive" 
                    *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0" 
                    [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;">{{info.NAME}}</th>
                                <td style="width:80%;">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 mb-2 table-responsive" 
                    *ngIf="props.ITEM.ADDITIONAL_CHARGES !== undefined && info.ADDITIONAL_CHARGES !== undefined && info.ADDITIONAL_CHARGES.length>0
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')" 
                    [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width:5%;">..</th>
                                <th style="width:50%;">Optional Services</th>
                                <th style="width:15%;">Fees</th>
                                <th style="width:15%;">Qty</th>
                                <th style="width:15%;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let charge of info.ADDITIONAL_CHARGES | slice:0:(showAllCharges?200:3)">
                                <td *ngIf="!charge['IS_MANDATORY']">
                                    <input type="checkbox" (change)="selectCharges(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY']">
                                    <input type="checkbox" [(ngModel)]="charge['Selected']" disabled="disabled">
                                </td>
                                
                                <td>{{charge.SERVICE_NAME}}</td>
                                
                                <td>{{appData.CurrencySymbol}}{{charge.SERVICE_CHARGE}}</td>
                                
                                <td *ngIf="!charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" (blur)="onChargeQtyBlur(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" readonly="true">
                                </td>
                                <td *ngIf="!charge['Selected']"></td>
                                
                                <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="info.ADDITIONAL_CHARGES.length > 3 && !showAllCharges">
                            <tr><td colspan="5">
                                <a class="btn btn-info" (click)="showAllCharges=true">Show All Optional Charges</a>
                            </td></tr>
                        </tfoot>
                    </table>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                [ngStyle]="getStyles(props.description_styles,'')"
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0">
                Date: {{selectedVariant.SLOT_DATE}} ({{selectedVariant.START_TIME}}-{{selectedVariant.END_TIME}}) <br/>
                Registration Closes On : {{selectedVariant.BOOKING_ALLOWED_TILL}} <br/>
                    <div style="color:red" *ngIf="selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES'">
                        Late fees : {{appData.CurrencySymbol}}{{props.ITEM.LATE_BOOKING_CHARGES}} will be charged after {{selectedVariant.BOOKING_ALLOWED_TILL}} till {{selectedVariant.BOOKING_WITH_LC_TILL}}    
                    </div>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0 && 
                (selectedVariant.TOTAL_BOOKING >= selectedVariant.MAX_BOOKING || (selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'NO'))">
                    <span style="color:red">Booking Closed !</span>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER === undefined || props.ITEM.CALENDER.length===0">
                    <span style="color:red">Booking Not Available !</span>
                </div>
                
                <div class="col-6" *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>1">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Dates
                        </div>
                        <select class="service-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')">
                            <option *ngFor="let variant of props.ITEM.CALENDER" [value]="variant.ID">{{variant.SLOT_DATE}} ({{variant.START_TIME}}-{{variant.END_TIME}})</option>
                        </select>
                    </div>
                </div>
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="service-form-control" type="number" [style]="getInputStyle(props['input_styles'],'input')"
                        [(ngModel)]="cartCount" readonly="true"/>
                    </div>
                </div> 
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                <button class="btn"
                [style.textDecoration]="props['cart_styles']['text-decoration']"
                [style.fontStyle]="props['cart_styles']['font-style']"
                [style.fontWeight]="props['cart_styles']['font-weight']"
                [style.color]="props['cart_styles']['color']"
                [style.backgroundColor]="props['cart_styles']['background-color']" 
                (click)="addToCart()">Add to Cart</button>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" 
                    style="display: block; width: 100%;object-fit: contain;"/>
                </div>
            </div>
        </div>

        <div class="col-12 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="w-100 float-left mb-1" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                                style="display: block; width: 100%;object-fit: fill;">
                        </div>
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.TITLE}}
                        </div>
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')">
                            Price : {{appData.CurrencySymbol}} {{item.DISCOUNTED_AMOUNT}}
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="props.template==='4'">
    <div class="row mt-2">
        <div [ngClass]="{ 'col-4': (isMobile() === false),'col-12': (isMobile() === true) }">
            <div class="row">
                <div class="col-12">
                    <img [src]="props.ITEM.IMAGE_URL" height="300px" 
                    style="display: block; width: 100%;object-fit: contain;"/>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'col-8': (isMobile() === false),'col-12': (isMobile() === true) }" style="position: relative;">
            <div class="row">
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.styles,'')">
                    {{props.ITEM.TITLE}}
                </div>
               
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.description_styles,'')"
                style="white-space:pre-wrap;">{{props.ITEM.DESCRIPTION}}</div>

                <div class="col-12 mb-2 table-responsive" 
                    *ngIf="props.ITEM.INFO !== undefined && props.ITEM.INFO.length>0" 
                    [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let info of props.ITEM.INFO">
                                <th style="width:20%;">{{info.NAME}}</th>
                                <td style="width:80%;">{{info.DESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12 mb-2 table-responsive" 
                    *ngIf="props.ITEM.ADDITIONAL_CHARGES !== undefined && info.ADDITIONAL_CHARGES !== undefined && info.ADDITIONAL_CHARGES.length>0
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')" 
                    [ngStyle]="getStyles(props.description_styles,'')">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width:5%;">..</th>
                                <th style="width:50%;">Optional Services</th>
                                <th style="width:15%;">Fees</th>
                                <th style="width:15%;">Qty</th>
                                <th style="width:15%;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let charge of info.ADDITIONAL_CHARGES | slice:0:(showAllCharges?200:3)">
                                <td *ngIf="!charge['IS_MANDATORY']">
                                    <input type="checkbox" (change)="selectCharges(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY']">
                                    <input type="checkbox" [(ngModel)]="charge['Selected']" disabled="disabled">
                                </td>
                                
                                <td>{{charge.SERVICE_NAME}}</td>
                                
                                <td>{{appData.CurrencySymbol}}{{charge.SERVICE_CHARGE}}</td>
                                
                                <td *ngIf="!charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" (blur)="onChargeQtyBlur(charge)">
                                </td>
                                <td *ngIf="charge['IS_MANDATORY'] && charge['Selected']">
                                    <input type="number" class="form-control" [(ngModel)]="charge.QTY" readonly="true">
                                </td>
                                <td *ngIf="!charge['Selected']"></td>
                                
                                <td>{{charge.AMOUNT | number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="info.ADDITIONAL_CHARGES.length > 3 && !showAllCharges">
                            <tr><td colspan="5">
                                <a class="btn btn-info" (click)="showAllCharges=true">Show All Optional Charges</a>
                            </td></tr>
                        </tfoot>
                    </table>
                </div>

                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT !== props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{props.ITEM.AMOUNT | number:'1.2-2'}}</span>  
                </div>
                <div class="col-12 mb-2" [ngStyle]="getStyles(props.price_styles,'')" *ngIf="props.ITEM.AMOUNT === props.ITEM.DISCOUNTED_AMOUNT">
                    {{appData.CurrencySymbol}} {{props.ITEM.DISCOUNTED_AMOUNT | number:'1.2-2'}}
                </div> 

                <div class="col-12 mb-2" style="text-align: left;" 
                [ngStyle]="getStyles(props.description_styles,'')"
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0">
                Date: {{selectedVariant.SLOT_DATE}} ({{selectedVariant.START_TIME}}-{{selectedVariant.END_TIME}}) <br/>
                Registration Closes On : {{selectedVariant.BOOKING_ALLOWED_TILL}} <br/>
                    <div style="color:red" *ngIf="selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES'">
                        Late fees : {{appData.CurrencySymbol}}{{props.ITEM.LATE_BOOKING_CHARGES}} will be charged after {{selectedVariant.BOOKING_ALLOWED_TILL}} till {{selectedVariant.BOOKING_WITH_LC_TILL}}    
                    </div>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>0 && 
                (selectedVariant.TOTAL_BOOKING >= selectedVariant.MAX_BOOKING || (selectedVariant.IS_BOOKING_ALLOWED === 'NO' && selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'NO'))">
                    <span style="color:red">Booking Closed !</span>
                </div>

                <div class="col-12 mb-2" style="text-align: left;" 
                *ngIf="props.ITEM.CALENDER === undefined || props.ITEM.CALENDER.length===0">
                    <span style="color:red">Booking Not Available !</span>
                </div>
                
                <div class="col-6" *ngIf="props.ITEM.CALENDER !== undefined && props.ITEM.CALENDER.length>1">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Dates
                        </div>
                        <select class="service-form-control" (change)="onChange($event)" [(ngModel)]="selectedVariantId"
                        [style]="getInputStyle(props['input_styles'],'input')">
                            <option *ngFor="let variant of props.ITEM.CALENDER" [value]="variant.ID">{{variant.SLOT_DATE}} ({{variant.START_TIME}}-{{variant.END_TIME}})</option>
                        </select>
                    </div>
                </div>
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                    && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                    <div class="input-group form-group">
                        <div class="input-group-prepend" [ngStyle]="getStyles(props.label_styles,'')">
                            Qty
                        </div>
                        <input class="service-form-control" type="number" [style]="getInputStyle(props['input_styles'],'input')"
                        [(ngModel)]="cartCount" readonly="true"/>
                    </div>
                </div> 
                <div class="col-3" 
                *ngIf="selectedVariant.MAX_BOOKING > selectedVariant.TOTAL_BOOKING 
                && (selectedVariant.IS_BOOKING_ALLOWED === 'YES' || selectedVariant.IS_BOOKING_WITH_LC_ALLOWED === 'YES')">
                <button class="btn"
                [style.textDecoration]="props['cart_styles']['text-decoration']"
                [style.fontStyle]="props['cart_styles']['font-style']"
                [style.fontWeight]="props['cart_styles']['font-weight']"
                [style.color]="props['cart_styles']['color']"
                [style.backgroundColor]="props['cart_styles']['background-color']" 
                (click)="addToCart()">Add to Cart</button>
                </div>
            </div>
        </div>

        <div class="col-12 mt-2 mb-2" *ngIf="relevantitems.length>0 && props.showRelevantItems !== undefined && props.showRelevantItems === true">
            <div class="w-100 float-left mb-1" [ngStyle]="getStyles(props.styles,'')">Relevant Products</div>
            <carousel noWrap=false [itemsPerSlide]="props.relevantitemsPerSlide"
            [singleSlideOffset]="true" [interval]="false">
                <slide *ngFor="let item of relevantitems; let index=index">
                    <div class="relavent-products" style="border:1px solid #000;margin-left:1px;margin-right:1px;z-index:100;"
                    (click)="loadProductInfo(item)">
                        <div [ngStyle]="getStyles(props.image_styles,'')">
                            <img [src]="item.IMAGE_URL" height="250px" alt="image slide" 
                                style="display: block; width: 100%;object-fit: fill;">
                        </div>
                        <div [ngStyle]="getStyles(props.rpname_styles,'')" style="line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.TITLE}}
                        </div>
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(props.rpprice_styles,'')">
                            Price : {{appData.CurrencySymbol}} {{item.DISCOUNTED_AMOUNT}}
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-container>