import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from '../../environments/env.service';

export class WebAppData {
    constructor(
        public isLoggedin: boolean,
        public isAdmin: boolean,
        public userId: string,
        public roleMenus: any,
        public customFonts: any,
        public fontsProcessed: boolean
    ) { }
}

@Injectable({ providedIn: 'root' })
export class DataService {
    httpOptions : any;
    httpOptionsAlternate : any;
    apiUrl : string;
    s3Url : string;
    public WebAppData = new WebAppData(false,false,"",[],[],false);

    constructor(private environment:EnvService, private http: HttpClient) {
        
        this.httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json'}),withCredentials: true};
        this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        this.httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.httpOptions.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        this.httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

        this.httpOptionsAlternate = {headers: new HttpHeaders()};
        this.httpOptionsAlternate.headers.append('Access-Control-Allow-Origin', '*');
        this.httpOptionsAlternate.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.httpOptionsAlternate.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        this.httpOptionsAlternate.headers.append('Access-Control-Allow-Credentials', 'true');

        this.apiUrl = environment.apiUrl; 
        this.s3Url = environment.s3Url;
    }
    
    getSettings():Observable<any>{
        
        return this.http.get(this.apiUrl + 'settings' , this.httpOptions);

    }

    getSessionInfo(){
        return this.http.get(`${this.apiUrl}sessioninfo`,this.httpOptions).pipe(map((response: any) => response)); 
    }

    getFonts(){
        var key = this.environment.clientKey;
        return this.http.get(`${this.apiUrl}fonts?key=`+key,this.httpOptions).pipe(map((response: any) => response)); 
    }

    getScripts(){
        var key = this.environment.clientKey;
        var Id = this.environment.websiteId;
        return this.http.post(`${this.apiUrl}scripts`, {WEBSITE_ID: Id, key:key}, this.httpOptions).pipe(map((response: any) => response));
    }

    getWebsite():Observable<any> {
        var key = this.environment.clientKey;
        var Id = this.environment.websiteId;
        return this.http.get(this.apiUrl + 'website?ID='+Id+'&key='+key,this.httpOptions)
    }

    getReport(Id):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'report?ID='+Id,this.httpOptions)
    }

    getQueryData(ID,COND){
        return this.http.post(`${this.apiUrl}querydata`, 
        { ID, COND }, this.httpOptions).pipe(map((response: any) => response));
    }

    getScreen(Id,Website_ID,Mode):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'screen?WEBSITE_ID='+Website_ID+'&ID='+Id+'&key='+key+'&mode='+Mode,this.httpOptions)
    }

    getStaticPage(Id,Website_ID,DesignType):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'staticpage?WEBSITE_ID='+Website_ID+'&TYPE=' +DesignType+'&ID='+Id+'&key='+key,this.httpOptions)
    }

    visitWebsite(Website_ID):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'visitwebsite?SITEID='+Website_ID+'&key='+key,this.httpOptions)
    }

    getScreenAttributes(Id,Mode):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'screen_fields?ID='+Id+'&key='+key+'&mode='+Mode,this.httpOptions)
    }

    getDBScreenRules(Id):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'dbrules?ID='+Id+'&key='+key,this.httpOptions)
    }

    getListingConfig(Id):Observable<any> {
        return this.http.get(this.apiUrl + 'listingconfig?ID='+Id,this.httpOptions)
    }

    listingData(page_id, condition, page_filter, pageno, page_size, page_order) {
        if (page_filter === null)
            page_filter = '';
        return this.http.post(`${this.apiUrl}pagelisting`, 
          {pageid: page_id, cond:page_filter+condition, pagenum:pageno, pagesize:page_size, orderby:page_order}, 
          this.httpOptions).pipe(map((response: any) => response));
        
    }

    viewOnlyCheck(qry):Observable<any> {
        var Ckey = this.environment.clientKey;
        return this.http.post(this.apiUrl + 'viewonlycheck',{key: Ckey,query:qry}, this.httpOptions);
    }

    getPageData(pageId, id):Observable<any> {

        return this.http.get(this.apiUrl + 'admin/page_data?page_id='+pageId+'&id='+id, this.httpOptions);

    }

    deleteRecord(pageid,primaryId){

        return this.http.get(`${this.apiUrl}deleterecord?page_id=`+pageid+'&primary_id='+primaryId,
        this.httpOptions).pipe(map((response: any) => response));
    
    }

    getAPIData(ID, Input){
        var key = this.environment.clientKey;
        var formParams = { "key": key, "API_ID": ID, "INPUT_FIELDS": Input };
        return this.http.post(`${this.apiUrl}apidata`, formParams, this.httpOptions).pipe(map((response: any) => response)); 
    }

    getLookupInfo(Id):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'lookup?ID='+Id+'&key='+key,this.httpOptions)
    }

    getLookupData(ID, Cond, From, To, OrderBy){
        var key = this.environment.clientKey;
        var formParams = { "key": key, "LOOKUP_ID": ID, "WHERE_CLAUSE": Cond, "pagenum": From, "pagesize": To, "orderby": OrderBy };
        return this.http.post(`${this.apiUrl}lookupdata`, formParams, this.httpOptions).pipe(map((response: any) => response)); 
    }

    getSVData(tableName, primaryId, isPrimaryTable):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'svdata?table_name='+ tableName +'&ID='+primaryId+'&is_primary_table='+isPrimaryTable+'&key='+key,this.httpOptions);
    }

    getMVData(tableName, primaryId, orderBy):Observable<any> {
        var key = this.environment.clientKey;
        return this.http.get(this.apiUrl + 'mvdata?table_name='+ tableName +'&ID='+primaryId+'&key='+key+'&orderby='+orderBy,this.httpOptions);
    }

    getSelectOptions(query){
        var key = this.environment.clientKey;
        return this.http.post(`${this.apiUrl}selectoptions`, 
        { key, query }, this.httpOptions).pipe(map((response: any) => response));
    }

    manageDM(oper, pageId, primaryId, primaryTableName, autoNumberFields, pageDatas, encryptedFields, primarykeyFields, pk_error_message, apiID, inputParams, externalFunctions) {
        var key = this.environment.clientKey;
        return this.http.post(`${this.apiUrl}managedm`, { 
                clientKey: key, OPER: oper, PAGE_ID:pageId, 
                PRIMARY_ID:primaryId, PRIMARY_TABLE_NAME:primaryTableName, 
                AUTO_NUMBER_ATTRIBUTES:autoNumberFields, FORM_DATA:pageDatas,
                PRIMARY_KEY_FIELDS: primarykeyFields, ENCRYPTED_FIELDS: encryptedFields,
                PRIMARY_KEY_ERROR_MESSAGE: pk_error_message,
                API_ID: apiID, INPUT_FIELDS: inputParams, EXTERNAL_FUNCTION: externalFunctions
            }, 
            this.httpOptions).pipe(map((response: any) => response));
    }
    
    getAttachmentCount(primary_id,field_id){
        var key = this.environment.clientKey;
        return this.http.post(`${this.apiUrl}attachmentcount`,
        {primary_id,field_id,key},this.httpOptions).pipe(map((response: any) => response));  
    }

    getImageAttachments(primary_id){
        var key = this.environment.clientKey;
        return this.http.post(`${this.apiUrl}imageattachments`,{primary_id, key},
        this.httpOptions).pipe(map((response: any) => response));  
    }
      
    getAttachmentList(primary_id){
        var key = this.environment.clientKey;
        return this.http.post(`${this.apiUrl}attachmentlist`,{primary_id, key},
        this.httpOptions).pipe(map((response: any) => response));  
    }
    
    uploadDigisign(primary_id, field_id, SIGNATURE_BLOB){
        var key = this.environment.clientKey;
        return this.http.post(`${this.apiUrl}uploaddigisign`,{primary_id, field_id, key, SIGNATURE_BLOB},
        this.httpOptions).pipe(map((response: any) => response)); 
    }

    manageAttachment(formData){
        var httpOptions : any;
    
        httpOptions = {headers: new HttpHeaders({'Content-Type':'multipart/form-data'}),withCredentials: true};
    
        httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        httpOptions.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');    
    
        return this.http.post(`${this.apiUrl}manageattachment`, formData,
        {"headers": { "content-type": 'multipart/form-data',"enctype": 'multipart/form-data' }, withCredentials: true}).pipe(map((response: any) => response));
    
    }
      
    deleteAttachment(formData){
        return this.http.post(`${this.apiUrl}manageattachment`, formData, this.httpOptions).pipe(map((response: any) => response));
    }

    getUsers(username, from, page_size, page_order) {
        return this.http.post(`${this.apiUrl}users`, 
          {Name:username, From:from, To:page_size, OrderBy:page_order}, 
          this.httpOptions).pipe(map((response: any) => response));
    }

    manageUser(oper, userId, userName, firstName, lastName, emailid, roleId, isAdmin, isActive) {
        return this.http.post(`${this.apiUrl}manageuser`, 
          { OPER: oper, ID:userId, USER_NAME:userName, FIRST_NAME:firstName,
            LAST_NAME: lastName, EMAIL_ADDRESS:emailid, ROLE_ID:roleId, IS_ADMIN:isAdmin, IS_ACTIVE: isActive}, 
          this.httpOptions).pipe(map((response: any) => response));
    }

    getUserInfo(userId){
        return this.http.get(`${this.apiUrl}user?ID=`+userId,
        this.httpOptions).pipe(map((response: any) => response));  
    }

    getRoles(condition, from_record, limit, page_order) {

        return this.http.post(`${this.apiUrl}roles`, 
          {cond:condition, from:from_record, to:limit, orderby:page_order}, 
          this.httpOptions).pipe(map((response: any) => response));
        
    }

    manageRole(oper, roleId, roleName, menus) {
        return this.http.post(`${this.apiUrl}managerole`, 
          { OPER: oper, ID:roleId, ROLE_NAME:roleName, MENUS:menus}, 
          this.httpOptions).pipe(map((response: any) => response));
    }

    getRoleInfo(roleId){
        return this.http.get(`${this.apiUrl}role?ID=`+roleId,
        this.httpOptions).pipe(map((response: any) => response));  
    }

    roleMenuHeaders(){
        return this.http.get(`${this.apiUrl}rolemenuheaders`,
        this.httpOptions).pipe(map((response: any) => response)); 
    }

    userMenus(){
        return this.http.get(`${this.apiUrl}usermenus`,
        this.httpOptions).pipe(map((response: any) => response)); 
    }

    menuDatas(roleId){
        return this.http.get(`${this.apiUrl}menurights?ROLE_ID=`+roleId,
        this.httpOptions).pipe(map((response: any) => response)); 
    }
    
    manageMenuRights(roleID,menus){
        return this.http.post(`${this.apiUrl}managemenurights`, 
          {ROLE_ID: roleID, MENUS:menus}, this.httpOptions).pipe(map((response: any) => response));
    }

    getTypeFormFields(id) {
        var key = this.environment.clientKey;
        return this.http.get(`${this.apiUrl}form_fields?CK=` + key + `&ID=`+id,
        this.httpOptions).pipe(map((response: any) => response)); 
    }

    manageFormData(pageDatas) {
        return this.http.post(`${this.apiUrl}saveform`, pageDatas, 
          this.httpOptions).pipe(map((response: any) => response));
    }

    getImageBlob(url){
        return this.http.post(this.apiUrl + 'getblob',{url},this.httpOptions).pipe(map((response: any) => response));
    }

}