import { Component, OnInit, Input } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Router } from "@angular/router";
import { BookingService } from '../../../../services';
import { EnvService } from '../../../../../environments/env.service';
import { PageFunctions } from '../../../directives/PageFunctions';

@Component({
	selector: 'app-categoryservices',
	templateUrl: './categoryservices.component.html',
	styleUrls: ['./categoryservices.component.scss']
})
export class CategoryServicesComponent implements OnInit {
	@Input() content: any;
	@Input() row: any;
	websiteId: string = '';
	appData: any = {};
  	brands: any = [];
	allBrands: any = [];
	filterClass: string = "col-3";
	listClass: string = "col-9";
	showFilter: boolean = true;

	constructor(public pageFunctions: PageFunctions, public bookingService : BookingService, 
		public envService: EnvService, public router: Router) { 
		this.appData = this.bookingService.AppBookingData;
		this.websiteId = envService.websiteId;

		if (this.isMobile() === true){
			this.filterClass = "col-12";
			this.listClass = "col-12";
			this.showFilter = false;
		}
	}

	isMobile(){
		return window.innerWidth < 700;
	}

	gotoLogin(){
		var loginUrl = this.appData.LoginPage;
		this.router.navigate([loginUrl]);
	}

	ngOnInit(): void {
		if (this.content.template === undefined) {
			this.content["template"] = "1";
		}
		if (this.content.paging_styles === undefined){
			this.content['paging_styles'] = {
			  "color": "#000",
			  "border-style": "solid",
			  "border-width": 0,
			  "border-color": "transparent",
			  "border-radius": 0,
			  'font-family': 'Roboto',
			  "font-size": "24px",
			  "font-weight": "normal",
			  "font-style": "normal",
			  "text-decoration": "",
			  "justify-content": "left"
			};
		}
		if (this.content.show_styles === undefined) {
			this.content['show_styles'] = {
			  'color':'#fff',
			  'background-color':'#5d9cec',
			  "font-size": "16px",
			  'font-weight':'normal',
			  'font-style':'normal',
			  'text-decoration':'normal',
			  'margin-top':0,
			  'margin-right':0,
			  'margin-bottom':0,
			  'margin-left':0,
			  'padding-top':10,
			  'padding-right':10,
			  'padding-bottom':10,
			  'padding-left':10,
			  'border-width':0,
			  'border-style':'',
			  'border-color':'',
			  'border-radius':0          
			};
		}
		if (this.content['image_styles']['height'] === undefined){
			this.content['image_styles']['height'] = "250";
		}
	}

	ngOnChanges(changes: any) {}

	pageChanged(event: PageChangedEvent, content): void {
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;
		content["paged_items"] = content.items.slice(startItem, endItem);
	}

	showProduct(item) {
		this.router.navigate([item.url, { sid: item.productId }]);
	}

	getFormButtonStyle(obj){
		var style: any = {};

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['margin-top'] !== null && obj['margin-top'] !== undefined) {
			style["margin-top"] = obj['margin-top'] + "px ";
		}
		if (obj['margin-right'] !== null && obj['margin-right'] !== undefined) {
			style["margin-right"] = obj['margin-right'] + "px ";
		}
		if (obj['margin-bottom'] !== null && obj['margin-bottom'] !== undefined) {
			style["margin-bottom"] = obj['margin-bottom'] + "px ";
		}
		if (obj['margin-left'] !== null && obj['margin-left'] !== undefined) {
			style["margin-left"] = obj['margin-left'] + "px";
		}		

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style["padding-top"] = obj['padding-top'] + "px ";
		}
		if (obj['padding-right'] !== null && obj['padding-right'] !== undefined) {
			style["padding-right"] = obj['padding-right'] + "px ";
		}
		if (obj['padding-bottom'] !== null && obj['padding-bottom'] !== undefined) {
			style["padding-bottom"] = obj['padding-bottom'] + "px ";
		}
		if (obj['padding-left'] !== null && obj['padding-left'] !== undefined) {
			style["padding-left"] = obj['padding-left'] + "px";
		}

		if (obj['border-width'] !== null && obj['border-width'] !== undefined) {
			if (obj['border-style'] !== null && obj['border-style'] !== undefined && obj['border-style'] !== "") {
				if (obj['border-color'] !== null && obj['border-color'] !== undefined && obj['border-color'] !== "")
					style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		return style;
	}

	getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		this.pageFunctions.setBackgroundImage(style,obj);

		this.pageFunctions.setShadow(style, obj);

		this.pageFunctions.setBorder(style,obj,true);

		this.pageFunctions.serBorderRadius(style, obj);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0) {
			style["height"] = obj['height'] + "px";
		}


		if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
			style["width"] = obj['width'] + "%";
		}

		this.pageFunctions.setMargin(style,obj);

		return style;
	}

	getPagedItems(content, startItem, endItem) {
		content["paged_items"] = content.items.slice(startItem, endItem);
	}

	addToCart(item){
		this.bookingService.addToCart({
			"ID":item.productId, 
			"CALENDER_ID":item.calenderId, 
			"PRODUCT_NAME": item.productName,
			"PRODUCT_DESCRIPTION": item.productDescription,
			"CART_COUNT": item.qty,
			"PRICE": item.discount_Price,
			"IMAGE_URL": item.imageUrl
		});
	}
	
	addToWishlist(item){
		if (this.appData.isLoggedin === true){
			this.bookingService.addToWishlist({
				"PRODUCT_ID":item.productId,
				"CALENDER_ID":item.calenderId, 
				"QTY":1,
				"PRICE": item.discount_Price,
				"IMAGE_URL": item.imageUrl
			});
		}
		else{
			this.gotoLogin();
		}
	}

	InWishlist(calenderId){
		return this.appData.WISHLIST.filter(product=>product.CALENDER_ID===calenderId).length > 0;
	}

	removeFromWishlist(calenderId){
		this.bookingService.removeFromWishlist({"CALENDER_ID":calenderId});
	}
}
