<div class="row">
    <div class="column-12">
        <div [innerHTML]="getHTML(contentText)" *ngIf="contentText !== ''"[ngStyle]="getStyles(content.content_styles)"></div>
    </div>
</div>
<ng-container *ngIf="this.content.infotype === 'Blog'">
    <div class="row">
        <div [class]="content.comment_display_styles.class">
            <div [ngStyle]="getStyles(content.comment_header_styles)">Comments ( {{comments.length}} )</div>
            <hr [style.marginLeft.px]="content.comment_header_styles['margin-left']"
            [style.marginRight.px]="content.comment_header_styles['margin-right']"
            [style.color]="content.comment_header_styles.color" 
            [style.backgroundColor]="content.comment_header_styles['background-color']">
        </div>
    </div>
    <ng-container *ngIf="comments.length > 0">
        <div class="row">
            <div [class]="content.comment_display_styles.class">
                <ng-container *ngFor="let comment of comments">
                    <div class="card mt-2 mb-2" [ngStyle]="getStyles(content.comment_display_styles)"
                    [style.backgroundColor]="content.comment_display_styles['background-color']">
                        <div class="card-body" *ngIf="comments.length>0">
                            <div class="media">
                                <div class="media-body">
                                    <div id="media-heading" [ngStyle]="getStyles(content.comment_user_styles)">
                                        {{comment.FIRST_NAME}} {{comment.LAST_NAME}}
                                    </div>
                                    <div id="media-heading" [ngStyle]="getStyles(content.comment_date_styles)">
                                        {{comment.COMMENTED_DATE}}
                                    </div>
                                    <div [ngStyle]="getStyles(content.comment_styles)">{{comment.COMMENTS}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="row">
        <div [class]="content.comment_create_styles.class">
            <div class="card" class="mt-3" [ngStyle]="getStyles(content.comment_create_styles)"
            [style.backgroundColor]="content.comment_create_styles['background-color']">
                <div class="card-header">
                    <em class="fas fa-pencil-alt mr-2"></em>
                    Add your Comment
                </div>
                <div class="card-body">
                    <form [formGroup]="commentForm" role="form" name="commentForm" novalidate="" 
                    class="form-horizontal ng-untouched ng-pristine ng-valid">
                        <div class="form-group row mt-1">
                            <div [class]="isMobile() ? 'col-12' : 'col-4'">
                                <input class="form-control" type="text" name="FIRST_NAME" placeholder="Enter first name"
                                    autocomplete="off" formControlName="FIRST_NAME" required="" />
                                <div class="text-danger"
                                    *ngIf="commentForm.controls['FIRST_NAME'].hasError('required') && (commentForm.controls['FIRST_NAME'].dirty || commentForm.controls['FIRST_NAME'].touched)">
                                    This field is required</div>
                            </div>
                            <div [class]="isMobile() ? 'col-12' : 'col-4'">
                                <input class="form-control" type="text" name="LAST_NAME" placeholder="Enter last name"
                                    autocomplete="off" formControlName="LAST_NAME" required="" />
                                <div class="text-danger"
                                    *ngIf="commentForm.controls['LAST_NAME'].hasError('required') && (commentForm.controls['LAST_NAME'].dirty || commentForm.controls['LAST_NAME'].touched)">
                                    This field is required</div>
                            </div>
                            <div [class]="isMobile() ? 'col-12' : 'col-4'">
                                <input class="form-control" type="email" name="EMAIL_ID" placeholder="Enter email id"
                                    autocomplete="off" formControlName="EMAIL_ID" required="" />
                                <div class="text-danger"
                                    *ngIf="commentForm.controls['EMAIL_ID'].hasError('required') && (commentForm.controls['EMAIL_ID'].dirty || commentForm.controls['EMAIL_ID'].touched)">
                                    This field is required</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <textarea class="form-control" type="text" name="COMMENT" placeholder="Enter comment"
                                    autocomplete="off" formControlName="COMMENT" required="" ></textarea>
                                <div class="text-danger"
                                    *ngIf="commentForm.controls['COMMENT'].hasError('required') && (commentForm.controls['COMMENT'].dirty || commentForm.controls['COMMENT'].touched)">
                                    This field is required</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <button type="button" class="btn btn-primary"
                                (click)="submitForm($event, commentForm.value)">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-container>