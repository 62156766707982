<div class="row">
    <div class="col-lg-12" *ngIf="isConfigReady && !showSubmit">
        <div class="card card-page">
            <div class="card-header">
                <button class="btn btn-circle btn-info" appNoDblClick type="button" (click)="gotoList()">
                    <i class="fa fa-arrow-left"></i>
                </button>
                {{screenName}}
                <button id="btnSubmit" class="btn btn-success float-right" *ngIf="screen_props['allowSave']"
                    appNoDblClick (click)="submitForm($event, {}, '', [])">Save</button>
                <button id="btnSubmit" class="btn btn-info float-right" *ngIf="oper==='EDIT' && ReportId!==''"
                    appNoDblClick (click)="showReport()">Report</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" id="page-parent-div">
    <div *ngIf="loading" [ngStyle]="{'margin-top':'auto','margin-bottom':'auto','text-align':'center'}"><img
            src="/assets/images/loading.gif"></div>

    <div class="row" [style]="getPageStyles(page.styles)">
        <div class="col-lg-12">

            <div *ngFor="let row of rows; let rowidx = index" [style.zIndex]="100-rowidx"
                [ngClass]="row['styles']['conic_template_id']" [style]="pageFunctions.getRowPosition(row.styles)">
                <div class="row contentrow" id="{{row.id}}" #rowContainerComponent [style.zIndex]="9999-(rowidx*10)"
                    [style]="getRowStyles(row, row.styles, rowidx)" style="position: relative;"
                    [hidden]="row.styles['hide-row-in-display'] || (window_width < 700 && row.styles['hide-row'] !== undefined && row.styles['hide-row'])">
                    <div style="display: flex;position: absolute;width: 100%;height: 100%;">
                        <video [id]="'video_' + row.id" class="video_bg"
                            *ngIf="row['styles']['background-type']!==undefined && row['styles']['background-type']==='video'"
                            [style.opacity]="row.styles['video-opacity'] === 'undefined'?'1':row.styles['video-opacity']"
                            [src]="row['styles']['background']['url']" autoplay loop playsinline
                            onloadedmetadata="this.muted = true"></video>
                        <div class="video_bg" [style]="getRowBackgroundColor(row.styles)"></div>
                    </div>
                    <div class="row contentrow m-0" style="width:100%;"
                        [style.justifyContent]="row.styles['justify-content']"
                        [ngClass]="{'humble-grid-layout': row['styles']['is-grid-layout']}"
                        [style]="{ 'max-width': row['styles']['row-full-width']===true?'100%':this.page.styles['page-width']+'px'}">
                        <div class="{{column?.hoverGrowClass}} hum-column-hover" id="{{column.id}}"
                            [class]="row.styles['is-grid-layout'] ? 'humble-grid-row humble-grid-column' : column?.class"
                            [ngClass]="column['styles'] !== undefined && column['styles']['conic_template_id'] !== undefined ?
                            column['styles']['conic_template_id']: ''" [style]="getColumnStyles(column)"
                            *ngFor="let column of row.columns; let idx = index" style="position: relative;"
                            [style.zIndex]="window_width < 700 ? 30+idx : 30-idx"
                            [hidden]="window_width < 700 && column.styles['hide-column'] !== undefined && column.styles['hide-column'] === true">
                            <div *ngIf="this.designType!=='MOBILE'"
                                style="display: flex;position: absolute;width:100%;height: 100%;z-index: -1;">
                                <video [id]="'video_' + row.id + '_' + column.id"
                                    *ngIf="column['styles']['background-type']!==undefined && column['styles']['background-type']==='video'"
                                    [style.opacity]="column.styles['video-opacity'] === 'undefined'?'1':column.styles['video-opacity']"
                                    class="video_bg_col" [src]="column['styles']['background']['url']" autoplay loop
                                    playsinline onloadedmetadata="this.muted = true"></video>
                                <div class="video_bg" [style]="getRowBackgroundColor(column.styles)"></div>
                            </div>
                            <div class="w-100" *ngFor="let content of column.contents">
                                <ng-container *ngIf="content.type==='Button'">
                                    <div class="content" id="{{content.id}}" (click)="loadPage(content)" [ngClass]="{'btn-hover':content.styles['is-gradient-animated'],
                                        'humble-hover-dyn':content.styles['hover-available']}"
                                        [ngStyle]="getStylesWithShadow(row,column,content,content.styles,'Button',content.styles)">
                                        <button class="btn" [style.fontSize]="content.styles['font-size']"
                                            [style.paddingTop.px]="content['styles']['isIcon'] ? content['styles']['padding-top'] : ''"
                                            [style.paddingBottom.px]="content['styles']['isIcon'] ? content['styles']['padding-bottom'] : ''"
                                            [style.paddingLeft.px]="content['styles']['isIcon'] ? content['styles']['padding-left'] : ''"
                                            [style.paddingRight.px]="content['styles']['isIcon'] ? content['styles']['padding-right'] : ''"
                                            style="color:inherit;letter-spacing:inherit;font-family:inherit;
                                            text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;">
                                            <em [class]="content.styles['icon_name']" style="vertical-align:middle;"
                                                [style.fontSize]="content.styles['icon-size']"
                                                *ngIf="content.styles['icon-align'] === undefined || content.styles['icon-align'] !== 'right'"></em>
                                            {{content.value}}{{content.styles['show_visit_count'] ? WebsiteVisits : ""}}
                                            <em [class]="content.styles['icon_name'].replace('mr','ml')"
                                                style="vertical-align:middle;"
                                                [style.fontSize]="content.styles['icon-size']"
                                                *ngIf="content.styles['icon-align'] !== undefined && content.styles['icon-align'] === 'right'"></em>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='SaveButton'">
                                    <div class="content" id="{{content.id}}"
                                        (click)="submitForm($event,content,content['API_ID'],content['Input_Params'])"
                                        *ngIf="screen_props['allowSave']" appNoDblClick [ngClass]="{'btn-hover':content.styles['is-gradient-animated'],
                                        'humble-hover-dyn':content.styles['hover-available']}"
                                        [ngStyle]="getStylesWithShadow(row,column,content,content.styles,'Button',content.styles)">
                                        <button id="btnSubmit" class="btn"
                                            [style.paddingTop.px]="content['styles']['isIcon'] ? content['styles']['padding-top'] : ''"
                                            [style.paddingBottom.px]="content['styles']['isIcon'] ? content['styles']['padding-bottom'] : ''"
                                            [style.paddingLeft.px]="content['styles']['isIcon'] ? content['styles']['padding-left'] : ''"
                                            [style.paddingRight.px]="content['styles']['isIcon'] ? content['styles']['padding-right'] : ''"
                                            [style.fontSize]="content.styles['font-size']"
                                            style="color:inherit;letter-spacing:inherit;font-family:inherit;
                                            text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;" *ngIf="screen_props['allowSave']">
                                            <em [class]="content.styles['icon_name']" style="vertical-align:middle;"
                                                [style.fontSize]="content.styles['icon-size']"
                                                [style.Color]="content.styles['icon-color']"
                                                [style.paddingTop.px]="content['styles']['icon-padding-top']"
                                                [style.paddingBottom.px]="content['styles']['icon-padding-bottom']"
                                                [style.paddingLeft.px]="content['styles']['icon-padding-left']"
                                                [style.paddingRight.px]="content['styles']['icon-padding-right']"
                                                *ngIf="content.styles['icon-align'] === undefined || content.styles['icon-align'] !== 'right'"></em>
                                            {{content.value}}
                                            <em [class]="content.styles['icon_name'].replace('mr','ml')"
                                                style="vertical-align:middle;"
                                                [style.Color]="content.styles['icon-color']"
                                                [style.paddingTop.px]="content['styles']['icon-padding-top']"
                                                [style.paddingBottom.px]="content['styles']['icon-padding-bottom']"
                                                [style.paddingLeft.px]="content['styles']['icon-padding-left']"
                                                [style.paddingRight.px]="content['styles']['icon-padding-right']"
                                                [style.fontSize]="content.styles['icon-size']"
                                                *ngIf="content.styles['icon-align'] !== undefined && content.styles['icon-align'] === 'right'"></em>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='ReportButton'">
                                    <div class="content" id="{{content.id}}" (click)="showReport()"
                                        *ngIf="oper==='EDIT' && ReportId!==''" appNoDblClick [ngClass]="{'btn-hover':content.styles['is-gradient-animated'],
                                        'humble-hover-dyn':content.styles['hover-available']}"
                                        [ngStyle]="getStylesWithShadow(row,column,content,content.styles,'Button',content.styles)">
                                        <button id="btnReport" class="btn"
                                            [style.fontSize]="content.styles['font-size']"
                                            [style.paddingTop.px]="content['styles']['isIcon'] ? content['styles']['padding-top'] : ''"
                                            [style.paddingBottom.px]="content['styles']['isIcon'] ? content['styles']['padding-bottom'] : ''"
                                            [style.paddingLeft.px]="content['styles']['isIcon'] ? content['styles']['padding-left'] : ''"
                                            [style.paddingRight.px]="content['styles']['isIcon'] ? content['styles']['padding-right'] : ''"
                                            style="color:inherit;letter-spacing:inherit;font-family:inherit;
                                            text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;"
                                            *ngIf="screen_props['allowSave']">
                                            <em [class]="content.styles['icon_name']" style="vertical-align:middle;"
                                                [style.fontSize]="content.styles['icon-size']"
                                                [style.Color]="content.styles['icon-color']"
                                                [style.paddingTop.px]="content['styles']['icon-padding-top']"
                                                [style.paddingBottom.px]="content['styles']['icon-padding-bottom']"
                                                [style.paddingLeft.px]="content['styles']['icon-padding-left']"
                                                [style.paddingRight.px]="content['styles']['icon-padding-right']"
                                                *ngIf="content.styles['icon-align'] === undefined || content.styles['icon-align'] !== 'right'"></em>
                                            {{content.value}}
                                            <em [class]="content.styles['icon_name'].replace('mr','ml')"
                                                style="vertical-align:middle;"
                                                [style.Color]="content.styles['icon-color']"
                                                [style.paddingTop.px]="content['styles']['icon-padding-top']"
                                                [style.paddingBottom.px]="content['styles']['icon-padding-bottom']"
                                                [style.paddingLeft.px]="content['styles']['icon-padding-left']"
                                                [style.paddingRight.px]="content['styles']['icon-padding-right']"
                                                [style.fontSize]="content.styles['icon-size']"
                                                *ngIf="content.styles['icon-align'] !== undefined && content.styles['icon-align'] === 'right'"></em>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Section'">
                                    <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                        style="position:absolute;text-align: left;" class="p-0">
                                        <div class="pt-1 pb-2">
                                            <div class="row mr-0 ml-0">
                                                <div class="col-md-12"
                                                    [style.paddingLeft.px]="content['header_styles'] ? content['header_styles']['padding-left'] : 0"
                                                    [style.paddingRight.px]="content['header_styles'] ? content['header_styles']['padding-right'] : 0"
                                                    [style.paddingTop.px]="content['header_styles'] ? content['header_styles']['padding-top'] : 0"
                                                    [style.paddingBottom.px]="content['header_styles'] ? content['header_styles']['padding-bottom'] : 0">
                                                    <div class="float-left"
                                                        [style.fontSize]="content['header_styles'] ? content['header_styles']['font-size'] : '16px'"
                                                        [style.fontWeight]="content['header_styles'] ? content['header_styles']['font-weight'] : 'normal'"
                                                        [style.fontStyle]="content['header_styles'] ? content['header_styles']['font-style'] : 'normal'"
                                                        [style.fontFamily]="content['header_styles'] ? content['header_styles']['font-family'] : 'inherit'"
                                                        *ngIf="content['form']['show_header'] === undefined || content['form']['show_header']">
                                                        {{content['form']['header_text']}}
                                                    </div>
                                                    <ng-template ngFor let-field [ngForOf]="content['form']['fields']"
                                                        let-index="attindex">
                                                        <button class="btn btn-xs float-right ml-1" type="button"
                                                            appNoDblClick [id]="'imgAttach'+field.Field_ID"
                                                            (click)="loadAttachment(field)"
                                                            [ngClass]="{ 'btn-light': (attachments[field.Field_ID] === 0),'btn-success': (attachments[field.Field_ID] > 0)}"
                                                            *ngIf="field.DataType === 'ATTACHMENT'"
                                                            style="margin-left:5px;float:right;">
                                                            {{field.value}} ({{ attachments[field.Field_ID] }})
                                                        </button>
                                                        <button class="btn btn-xs float-right ml-1" type="button"
                                                            appNoDblClick [id]="'imgMarking'+field.Field_ID"
                                                            (click)="loadAttachment(field)"
                                                            [ngClass]="{ 'btn-light': (attachments[field.Field_ID] === 0),'btn-success': (attachments[field.Field_ID] > 0)}"
                                                            *ngIf="field.DataType === 'MARKING'"
                                                            style="margin-left:5px;float:right;">
                                                            {{field.value}} ({{ attachments[field.Field_ID] }})
                                                        </button>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pb-0 pt-0">
                                            <div class="row mr-0 ml-0" *ngIf="!loading">
                                                <ng-container *ngFor="let field of content['form']['fields']">
                                                    <div [class]="field['class']"
                                                        [style.paddingLeft.px]="content['styles']['padding-left']"
                                                        [style.paddingRight.px]="content['styles']['padding-right']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                        *ngIf="(field['DataType']==='ATTACHMENT' || field['DataType']==='MARKING') && field['Display_Image']===true">
                                                        <div>
                                                            <div class="text-left"
                                                                *ngIf="content['form']['show_label']">
                                                                <label>{{field['value']}}{{field['is_Mandatory']?'
                                                                    *':''}}</label>
                                                            </div>
                                                            <div style="border:1px solid #000;"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
                                                            && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}">
                                                                <carousel>
                                                                    <slide
                                                                        *ngFor="let item of image_attachments[field.Field_ID]">
                                                                        <img [src]="item.ATTACHMENT_URL"
                                                                            alt="banner image"
                                                                            style="display: block; width: 100%; height:200px; object-fit: contain;">
                                                                    </slide>
                                                                </carousel>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [class]="field['class']"
                                                        [style.paddingLeft.px]="content['styles']['padding-left']"
                                                        [style.paddingRight.px]="content['styles']['padding-right']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                        *ngIf="field['DataType']==='UPLOAD'">
                                                        <div>
                                                            <div class="text-left"
                                                                *ngIf="content['form']['show_label']">
                                                                <label>{{field['value']}}</label>
                                                            </div>
                                                            <div class="input-group form-group mb-0"
                                                                style="border:1px solid #000;"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                *ngIf="field['Display_Image']===false">
                                                                <input type="text" readonly="true"
                                                                    [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                    [style.fontSize]="content['styles']['input-font-size']"
                                                                    [name]="field['Field_Name']"
                                                                    [style]="getInputStyle(content['input_styles'],'input')"
                                                                    [(ngModel)]="pageValues[field['Field_ID']]"
                                                                    [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                    && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                                <div class="input-group-append"
                                                                    *ngIf="!field['is_Read_Only'] && !(field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <button class="btn btn-info btn-pill-right"
                                                                        type="button"
                                                                        (click)="loadAttachment(field)">Upload</button>
                                                                </div>
                                                            </div>
                                                            <div class="display-image"
                                                                style="position:relative;border:1px solid #000;"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                *ngIf="field['Display_Image']===true">
                                                                <img [src]="getImageSrc(field)" alt="banner image"
                                                                    style="display: block; width: 100%; height:200px; object-fit: contain;">
                                                                <div class="center-div btn-div"
                                                                    *ngIf="!field['is_Read_Only'] && !(field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <button class="btn btn-small bg-primary"
                                                                        [id]="'imgUpload'+field.Field_ID"
                                                                        (click)="loadAttachment(field)">
                                                                        {{content['form']['show_placeholder'] !==
                                                                        undefined
                                                                        && content['form']['show_placeholder'] === true
                                                                        ? field['help_text'] :'Upload'}}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [class]="field['class']"
                                                        [style.paddingLeft.px]="content['styles']['padding-left']"
                                                        [style.paddingRight.px]="content['styles']['padding-right']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                        *ngIf="field['DataType']==='DIGISIGN'">
                                                        <div>
                                                            <div class="text-left"
                                                                *ngIf="content['form']['show_label']">
                                                                <label>{{field['value']}}</label>
                                                            </div>
                                                            <app-signpad [primaryId]="primaryId" [oper]="oper"
                                                                [saveTried]="saveTried" [field]="field"
                                                                [pageValues]="pageValues"></app-signpad>
                                                        </div>
                                                    </div>
                                                    <div [class]="field['class']"
                                                        *ngIf="field['DataType']!=='ATTACHMENT' && field['DataType']!=='MARKING' && 
                                                        field['DataType']!=='DIGISIGN' && field['DataType']!=='UPLOAD' && field['is_Hidden']!==true"
                                                        [style.paddingLeft.px]="content['styles']['padding-left']"
                                                        [style.paddingRight.px]="content['styles']['padding-right']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                        <div class="text-left">
                                                            <div class="text-left"
                                                                [style.paddingLeft.px]="content['label_styles'] ? content['label_styles']['padding-left'] : 0"
                                                                [style.paddingRight.px]="content['label_styles'] ? content['label_styles']['padding-right'] : 0"
                                                                [style.paddingTop.px]="content['label_styles'] ? content['label_styles']['padding-top'] : 0"
                                                                [style.paddingBottom.px]="content['label_styles'] ? content['label_styles']['padding-bottom'] : 0"
                                                                *ngIf="content['form']['show_label']">
                                                                <label
                                                                    [ngClass]="{'input-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}">
                                                                    {{field['value']}}{{field['is_Mandatory']?'
                                                                    *':''}}</label>
                                                            </div>

                                                            <select [name]="field['Field_Name']"
                                                                [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                [required]="field['is_Mandatory']"
                                                                [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                (change)="onValueChange(field)"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                *ngIf="field['is_Static_Value']===true 
                                                                    && (field['is_Radio_Button']===undefined || field['is_Radio_Button']===false || field['is_Radio_Button']===null) 
                                                                    && (field['is_Button']===undefined || field['is_Button']===false || field['is_Button']===null)
                                                                    && (field['is_MultiSelect']===undefined || field['is_MultiSelect']===false)">
                                                                <option [value]="obj.value"
                                                                    *ngFor="let obj of staticValues[field['Field_ID']]">
                                                                    {{obj.text}}
                                                                </option>
                                                            </select>

                                                            <ng-multiselect-dropdown class="ms-dropdown"
                                                                [id]="field['Field_ID']"
                                                                [ngStyle]="getMultiSelectStyle(content,field['Field_ID'])"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                    && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                [settings]="multiSelectSettings"
                                                                [data]="staticValues[field['Field_ID']]"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (onSelect)="onItemSelect($event)"
                                                                (onSelectAll)="onSelectAll($event)"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                *ngIf="field['is_Static_Value']===true 
                                                                    && (field['is_MultiSelect']!==undefined && field['is_MultiSelect']===true)">
                                                            </ng-multiselect-dropdown>

                                                            <ng-container
                                                                *ngIf="field['is_Static_Value']===true && field['is_Button']===true
                                                                    && (field['is_MultiSelect']===undefined || field['is_MultiSelect']===false)">
                                                                <button [name]="field['Field_Name']"
                                                                    *ngFor="let obj of staticValues[field['Field_ID']]"
                                                                    [ngStyle]="getStylesStaticType(column, content.styles,'StaticButton',obj.value === pageValues[field['Field_ID']])"
                                                                    (click)="setPageValue(field,obj.value)"
                                                                    [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                    class="float-left btn mr-2 mb-2"
                                                                    [class]="content['styles']['static_button-style']"
                                                                    appNoDblClick>
                                                                    {{obj.text}}
                                                                </button>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="field['is_Static_Value']===true && field['is_Radio_Button']===true
                                                                    && (field['is_MultiSelect']===undefined || field['is_MultiSelect']===false)">
                                                                <label
                                                                    *ngFor="let obj of staticValues[field['Field_ID']]"
                                                                    class="mr-2">
                                                                    <input type="radio" [name]="field['Field_Name']"
                                                                        class="mr-1 mb-1"
                                                                        (click)="setPageValue(field,obj.value)"
                                                                        [id]="field['Field_ID']"
                                                                        [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                        [checked]="obj.value === pageValues[field['Field_ID']]">{{obj.text}}
                                                                </label>
                                                            </ng-container>

                                                            <div class="input-group"
                                                                *ngIf="field['is_Lookup']===true && field['DataType']==='TINY_STRING'">
                                                                <input type="text" [id]="field['Field_ID']"
                                                                    [name]="field['Field_Name']"
                                                                    [required]="field['is_Mandatory']"
                                                                    [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                    [style.fontSize]="content['styles']['input-font-size']"
                                                                    [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                    (change)="onValueChange(field)" readonly="true"
                                                                    [style]="getInputStyle(content['input_styles'],'lookup')"
                                                                    [(ngModel)]="pageValues[field['Field_ID']]"
                                                                    [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                                <div class="input-group-append"
                                                                    *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <button class="btn btn-info btn-pill-right"
                                                                        type="button"
                                                                        (click)="showLookup(content['form']['fields'], field)"
                                                                        [id]="'lkup'+field.Field_ID"
                                                                        [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')">
                                                                        <i [style.fontSize]="content['styles']['input-font-size']"
                                                                            class="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="input-group"
                                                                *ngIf="field['is_Lookup']===true && (field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT')">
                                                                <textarea rows="3" [id]="field['Field_ID']"
                                                                    [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                    [style.fontSize]="content['styles']['input-font-size']"
                                                                    [name]="field['Field_Name']"
                                                                    [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                    [(ngModel)]="pageValues[field['Field_ID']]"
                                                                    [style]="getInputStyle(content['input_styles'],'lookup')"
                                                                    [ngClass]="{'input-form-control':true, 'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                    (change)="onValueChange(field)"
                                                                    [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"></textarea>
                                                                <div class="input-group-append"
                                                                    *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <button class="btn btn-info btn-pill-right"
                                                                        type="button"
                                                                        (click)="showLookup(content['form']['fields'], field)"
                                                                        [id]="'lkup'+field.Field_ID">
                                                                        <i [style.fontSize]="content['styles']['input-font-size']"
                                                                            class="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="input-group"
                                                                *ngIf="field['is_External_Value']===true 
                                                            && (field['DataType']==='TINY_STRING' || field['DataType']==='INTEGER' || field['DataType']==='DECIMAL')">
                                                                <input type="text" [id]="field['Field_ID']"
                                                                    [name]="field['Field_Name']"
                                                                    [required]="field['is_Mandatory']"
                                                                    [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                    [style.fontSize]="content['styles']['input-font-size']"
                                                                    [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                    [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                    (change)="onValueChange(field)"
                                                                    [style]="getInputStyle(content['input_styles'],'api')"
                                                                    [(ngModel)]="pageValues[field['Field_ID']]"
                                                                    [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                    && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                                <div class="input-group-append"
                                                                    *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                    <button class="btn btn-info btn-pill-right"
                                                                        type="button"
                                                                        (click)="getAPIData(field, content['form']['fields'])"
                                                                        [id]="'lkup'+field.Field_ID"
                                                                        [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')">
                                                                        <i [style.fontSize]="content['styles']['input-font-size']"
                                                                            class="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <input type="text" [id]="field['Field_ID']"
                                                                [name]="field['Field_Name']"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                maxlength="100"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                            && (field['DataType']==='TINY_STRING')" />


                                                            <input type="email" [id]="field['Field_ID']" [email]="true"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''" maxlength="100"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)" [pattern]="emailPattern"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                            && (field['DataType']==='EMAIL')" />

                                                            <input type="password" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''" maxlength="20"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                            && (field['DataType']==='PASSWORD'||field['DataType']==='ENCRYPTED')" />

                                                            <input type="number" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                onkeydown="if(event.key==='.'){event.preventDefault();}"
                                                                oninput="javascript:if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                max="99999999" maxlength="9"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='INTEGER'" />

                                                            <input type="number" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                oninput="javascript:if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                max="999999999.99" maxlength="12"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                    && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='DECIMAL'" />

                                                            <input type="number" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                (blur)="validatePercent(field['Field_ID'])"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                oninput="javascript:if(this.value > 999){this.value = null;}"
                                                                [(ngModel)]="pageValues[field['Field_ID']]" max="999"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='PERCENTAGE'" />

                                                            <!-- (input)="pageValues[field['Field_ID']]=parseDate($event.target.value)" -->
                                                            <input type="date" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                [value]="pageValues[field['Field_ID']] | date:'yyyy-MM-dd'"
                                                                (blur)="setDate($event.target.value, field['Field_ID'])"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['DataType']==='DATE' && field['is_Read_Only'] !== true" />

                                                            <input type="datetime-local" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                [value]="pageValues[field['Field_ID']] | date:'yyyy-MM-dd hh:mm:ss'"
                                                                (blur)="setDateTime($event.target.value,field['Field_ID'])"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['DataType']==='DATETIME' && field['is_Read_Only'] !== true" />

                                                            <input type="text" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                [value]="pageValues[field['Field_ID']] | date:'dd-MM-yyyy'"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['DataType']==='DATE' && field['is_Read_Only'] === true" />

                                                            <input type="text" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                (change)="onValueChange(field)"
                                                                [required]="field['is_Mandatory']"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                [value]="pageValues[field['Field_ID']] | date:'dd-MM-yyyy hh:mm:ss'"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                *ngIf="field['DataType']==='DATETIME' && field['is_Read_Only'] === true" />

                                                            <textarea rows="3" [id]="field['Field_ID']"
                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                [name]="field['Field_Name']"
                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                [(ngModel)]="pageValues[field['Field_ID']]"
                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                [ngClass]="{'input-form-control':true,'is-invalid':(saveTried===true && field['is_Mandatory'] && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}"
                                                                *ngIf="field['is_Lookup']!==true && (field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT')"
                                                                (change)="onValueChange(field)"
                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"></textarea>

                                                            <label class="switch switch-lg m-0 pl-2"
                                                                *ngIf="field['DataType']==='BIT'">
                                                                <input type="checkbox" [id]="field['Field_ID']"
                                                                    [name]="field['Field_Name']"
                                                                    [(ngModel)]="pageValues[field['Field_ID']]"
                                                                    [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                    (change)="onValueChange(field)" checked="checked" />
                                                                <span [id]="'span'+field['Field_ID']"></span>
                                                            </label>
                                                        </div>
                                                        <div class="text-left" *ngIf="content['form']['show_helptext']">
                                                            <small>{{field['help_text']}}</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12" *ngIf="field['section_break']"></div>
                                                    <input *ngIf="field['is_Hidden']===true" [id]="field['Field_ID']"
                                                        [name]="field['Field_Name']" type="hidden"
                                                        [(ngModel)]="pageValues[field['Field_ID']]" />
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='MVSection'">
                                    <div style="position:relative;text-align:left;display:flex;flex-direction: column;"
                                        [style.width]="calculateWidthForResolutionWithMobile(row,column, content['styles']['width'])"
                                        [style.marginLeft]="getLeftMargin(content['styles'],row)"
                                        [style.marginTop]="content.styles['margin-top']" class="p-0">
                                        <div *ngIf="screen_props['allowSave'] && (content['form']['show_add'] === undefined || content['form']['show_add'])"
                                            [style.order]="content.add_btn_styles['vertical-align']"
                                            [style.textAlign]="content.add_btn_styles['text-align']"
                                            [style.marginLeft.px]="content['add_btn_styles']['margin-left']"
                                            [style.marginRight.px]="content['add_btn_styles']['margin-right']"
                                            [style.marginTop.px]="content['add_btn_styles']['margin-top']"
                                            [style.marginBottom.px]="content['add_btn_styles']['margin-bottom']">
                                            <button class="btn" [id]="'btnAdd'+content.id"
                                                [ngStyle]="getMVSectionBtnStyles(content.add_btn_styles)"
                                                (click)="addMVRowClick(content['index'])">
                                                <em [class]="content['add_btn_styles']['icon_name']"
                                                    [style.Color]="content['add_btn_styles']['icon-color']"
                                                    [style.paddingTop.px]="content['add_btn_styles']['icon-padding-top']"
                                                    [style.paddingBottom.px]="content['add_btn_styles']['icon-padding-bottom']"
                                                    [style.paddingLeft.px]="content['add_btn_styles']['icon-padding-left']"
                                                    [style.paddingRight.px]="content['add_btn_styles']['icon-padding-right']"
                                                    style="vertical-align:middle;"
                                                    [style.fontSize]="content['add_btn_styles']['font-size']"
                                                    *ngIf="content['add_btn_styles']['icon-align'] === undefined || content['add_btn_styles']['icon-align'] !== 'right'"></em>
                                                {{content['form']['add_button_text']}}
                                                <em [class]="content['add_btn_styles']['icon_name'].replace('mr','ml')"
                                                    [style.Color]="content['add_btn_styles']['icon-color']"
                                                    [style.paddingTop.px]="content['add_btn_styles']['icon-padding-top']"
                                                    [style.paddingBottom.px]="content['add_btn_styles']['icon-padding-bottom']"
                                                    [style.paddingLeft.px]="content['add_btn_styles']['icon-padding-left']"
                                                    [style.paddingRight.px]="content['add_btn_styles']['icon-padding-right']"
                                                    style="vertical-align:middle;"
                                                    [style.fontSize]="content['add_btn_styles']['font-size']"
                                                    *ngIf="content['add_btn_styles']['icon-align'] !== undefined && content['add_btn_styles']['icon-align'] === 'right'"></em>
                                            </button>
                                        </div>
                                        <div style="order:2;"
                                            [style.marginLeft.px]="content['section_styles']['margin-left']"
                                            [style.marginRight.px]="content['section_styles']['margin-right']"
                                            [style.marginTop.px]="content['section_styles']['margin-top']"
                                            [style.marginBottom.px]="content['section_styles']['margin-bottom']">
                                            <ng-container *ngFor="let dataValues of mvSectionDatas[content['index']]">
                                                <ng-container
                                                    *ngIf="dataValues['RECORD_STATUS']==='1'||dataValues['RECORD_STATUS']==='A'">
                                                    <div class="pt-1 pb-2"
                                                        [style.background]="content.styles['background-color']"
                                                        style="display:flex;flex-direction: column;"
                                                        [ngStyle]="getStylesType(row,column,content,content.styles,'MVSection')"
                                                        [style.marginBottom.px]="content['section_styles']['margin-bottom']">
                                                        <div class="row mr-0 ml-0" style="order:0;"
                                                            *ngIf="content['form']['show_header'] !== undefined && content['form']['show_header']">
                                                            <div class="col-md-12"
                                                                [style.paddingLeft.px]="content['header_styles'] ? content['header_styles']['padding-left'] : 0"
                                                                [style.paddingRight.px]="content['header_styles'] ? content['header_styles']['padding-right'] : 0"
                                                                [style.paddingTop.px]="content['header_styles'] ? content['header_styles']['padding-top'] : 0"
                                                                [style.paddingBottom.px]="content['header_styles'] ? content['header_styles']['padding-bottom'] : 0">
                                                                <div class="float-left"
                                                                    [style.fontSize]="content['header_styles'] ? content['header_styles']['font-size'] : '16px'"
                                                                    [style.fontWeight]="content['header_styles'] ? content['header_styles']['font-weight'] : 'normal'"
                                                                    [style.fontStyle]="content['header_styles'] ? content['header_styles']['font-style'] : 'normal'"
                                                                    [style.fontFamily]="content['header_styles'] ? content['header_styles']['font-family'] : 'inherit'">
                                                                    {{content['form']['header_text']}}
                                                                </div>

                                                                <div class="float-right"
                                                                    [style.marginLeft.px]="content['del_btn_styles']['margin-left']"
                                                                    [style.marginRight.px]="content['del_btn_styles']['margin-right']"
                                                                    [style.marginTop.px]="content['del_btn_styles']['margin-top']"
                                                                    [style.marginBottom.px]="content['del_btn_styles']['margin-bottom']"
                                                                    *ngIf="!allowResize && content.del_btn_styles['text-align'] === 'right'
                                                                        && content.del_btn_styles['vertical-align']==='1'
                                                                        && screen_props['allowSave']">
                                                                    <button class="btn btn-xs btn-light" type="button"
                                                                        [id]="'btnDel'"
                                                                        [ngStyle]="getMVSectionBtnStyles(content.del_btn_styles)"
                                                                        (click)="delMVRowClick(content['index'],dataValues)">
                                                                        <em [class]="content['del_btn_styles']['icon_name']"
                                                                            [style.Color]="content['del_btn_styles']['icon-color']"
                                                                            [style.paddingTop.px]="content['del_btn_styles']['icon-padding-top']"
                                                                            [style.paddingBottom.px]="content['del_btn_styles']['icon-padding-bottom']"
                                                                            [style.paddingLeft.px]="content['del_btn_styles']['icon-padding-left']"
                                                                            [style.paddingRight.px]="content['del_btn_styles']['icon-padding-right']"
                                                                            style="vertical-align:middle;"
                                                                            [style.fontSize]="content['del_btn_styles']['font-size']"
                                                                            *ngIf="content['del_btn_styles']['icon-align'] === undefined || content['del_btn_styles']['icon-align'] !== 'right'"></em>
                                                                        {{content['form']['del_button_text']}}
                                                                        <em [class]="content['del_btn_styles']['icon_name'].replace('mr','ml')"
                                                                            [style.Color]="content['del_btn_styles']['icon-color']"
                                                                            [style.paddingTop.px]="content['del_btn_styles']['icon-padding-top']"
                                                                            [style.paddingBottom.px]="content['del_btn_styles']['icon-padding-bottom']"
                                                                            [style.paddingLeft.px]="content['del_btn_styles']['icon-padding-left']"
                                                                            [style.paddingRight.px]="content['del_btn_styles']['icon-padding-right']"
                                                                            style="vertical-align:middle;"
                                                                            [style.fontSize]="content['del_btn_styles']['font-size']"
                                                                            *ngIf="content['del_btn_styles']['icon-align'] !== undefined && content['del_btn_styles']['icon-align'] === 'right'"></em>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div [style.order]="content.del_btn_styles['vertical-align']"
                                                            [style.textAlign]="content.del_btn_styles['text-align']"
                                                            [style.marginLeft.px]="content['del_btn_styles']['margin-left']"
                                                            [style.marginRight.px]="content['del_btn_styles']['margin-right']"
                                                            [style.marginTop.px]="content['del_btn_styles']['margin-top']"
                                                            [style.marginBottom.px]="content['del_btn_styles']['margin-bottom']"
                                                            *ngIf="!allowResize && (content.del_btn_styles['text-align'] !== 'right'
                                                            || content.del_btn_styles['vertical-align']!=='1') && screen_props['allowSave']">
                                                            <button class="btn btn-xs btn-light" type="button"
                                                                [id]="'btnDel'"
                                                                [ngStyle]="getMVSectionBtnStyles(content.del_btn_styles)"
                                                                (click)="delMVRowClick(content['index'],dataValues)">
                                                                <em [class]="content['del_btn_styles']['icon_name']"
                                                                    [style.Color]="content['del_btn_styles']['icon-color']"
                                                                    [style.paddingTop.px]="content['del_btn_styles']['icon-padding-top']"
                                                                    [style.paddingBottom.px]="content['del_btn_styles']['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="content['del_btn_styles']['icon-padding-left']"
                                                                    [style.paddingRight.px]="content['del_btn_styles']['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="content['del_btn_styles']['font-size']"
                                                                    *ngIf="content['del_btn_styles']['icon-align'] === undefined || content['del_btn_styles']['icon-align'] !== 'right'"></em>
                                                                {{content['form']['del_button_text']}}
                                                                <em [class]="content['del_btn_styles']['icon_name'].replace('mr','ml')"
                                                                    [style.Color]="content['del_btn_styles']['icon-color']"
                                                                    [style.paddingTop.px]="content['del_btn_styles']['icon-padding-top']"
                                                                    [style.paddingBottom.px]="content['del_btn_styles']['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="content['del_btn_styles']['icon-padding-left']"
                                                                    [style.paddingRight.px]="content['del_btn_styles']['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="content['del_btn_styles']['font-size']"
                                                                    *ngIf="content['del_btn_styles']['icon-align'] !== undefined && content['del_btn_styles']['icon-align'] === 'right'"></em>
                                                            </button>
                                                        </div>
                                                        <div class="row mr-0 ml-0" style="order:2;">
                                                            <ng-container
                                                                *ngFor="let field of content['form']['fields']">
                                                                <div [class]="field['class']"
                                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                                    *ngIf="field['DataType']==='ATTACHMENT' || field['DataType']==='MARKING'">
                                                                    <div>
                                                                        <div class="text-left"
                                                                            [style.paddingLeft.px]="content['label_styles'] ? content['label_styles']['padding-left'] : 0"
                                                                            [style.paddingRight.px]="content['label_styles'] ? content['label_styles']['padding-right'] : 0"
                                                                            [style.paddingTop.px]="content['label_styles'] ? content['label_styles']['padding-top'] : 0"
                                                                            [style.paddingBottom.px]="content['label_styles'] ? content['label_styles']['padding-bottom'] : 0"
                                                                            *ngIf="content['form']['show_label']">
                                                                            <label>{{field['value']}}</label>
                                                                        </div>
                                                                        <button class="btn btn-xs float-left"
                                                                            type="button" appNoDblClick
                                                                            [id]="'imgAttach'+field.Field_ID"
                                                                            (click)="loadMVAttachment(content['index'],dataValues['ID'], field)"
                                                                            [ngClass]="{ 'btn-info': (dataValues[field.Field_Name] === 0),'btn-success': (dataValues[field.Field_Name] > 0)}"
                                                                            *ngIf="field.DataType === 'ATTACHMENT'">
                                                                            {{field.value}} ({{
                                                                            dataValues[field.Field_Name] }})
                                                                        </button>
                                                                        <button class="btn btn-xs float-left"
                                                                            type="button" appNoDblClick
                                                                            [id]="'imgMarking'+field.Field_ID"
                                                                            (click)="loadMVAttachment(content['index'],dataValues['ID'], field)"
                                                                            [ngClass]="{ 'btn-info': (dataValues[field.Field_Name] === 0),'btn-success': (dataValues[field.Field_Name]> 0)}"
                                                                            *ngIf="field.DataType === 'MARKING'">
                                                                            {{field.value}} ({{
                                                                            dataValues[field.Field_Name] }})
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div [class]="field['class']"
                                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                                    *ngIf="field['DataType']==='UPLOAD'">
                                                                    <div>
                                                                        <div class="text-left"
                                                                            [style.paddingLeft.px]="content['label_styles'] ? content['label_styles']['padding-left'] : 0"
                                                                            [style.paddingRight.px]="content['label_styles'] ? content['label_styles']['padding-right'] : 0"
                                                                            [style.paddingTop.px]="content['label_styles'] ? content['label_styles']['padding-top'] : 0"
                                                                            [style.paddingBottom.px]="content['label_styles'] ? content['label_styles']['padding-bottom'] : 0"
                                                                            *ngIf="content['form']['show_label']">
                                                                            <label>{{field['value']}}</label>
                                                                        </div>
                                                                        <div class="input-group form-group mb-0"
                                                                            style="border:1px solid transparent;"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                            && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            *ngIf="field['Display_Image'] === undefined || field['Display_Image']===false">
                                                                            <input type="text" readonly="true"
                                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                                [name]="field['Field_Name']"
                                                                                [style]="getInputStyle(content['input_styles'],'input')"
                                                                                [(ngModel)]="dataValues[field['Field_Name']]"
                                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                                            <div class="input-group-append">
                                                                                <button
                                                                                    [id]="'imgUpload'+field.Field_ID"
                                                                                    class="btn btn-info btn-pill-right"
                                                                                    type="button"
                                                                                    (click)="loadMVAttachment(content['index'],dataValues['ID'], field)">Upload</button>
                                                                            </div>
                                                                        </div>
                                                                        <div style="position:relative;border:1px solid transparent;"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                            && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            class="display-image"
                                                                            *ngIf="field['Display_Image']===true">
                                                                            <img [src]="getMVImageSrc(field,dataValues)"
                                                                                alt="banner image"
                                                                                style="display: block; width: 100%; height:200px; object-fit: contain;">
                                                                            <div class="center-div btn-div">
                                                                                <button class="btn btn-small bg-primary"
                                                                                    [id]="'imgUpload'+field.Field_ID"
                                                                                    (click)="loadMVAttachment(content['index'],dataValues['ID'], field)">
                                                                                    {{content['form']['show_placeholder']
                                                                                    !==
                                                                                    undefined
                                                                                    &&
                                                                                    content['form']['show_placeholder']
                                                                                    === true
                                                                                    ? field['help_text'] :'Upload'}}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div [class]="field['class']"
                                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']"
                                                                    *ngIf="field['DataType']==='DIGISIGN'">
                                                                    <div>
                                                                        <div class="text-left"
                                                                            [style.paddingLeft.px]="content['label_styles'] ? content['label_styles']['padding-left'] : 0"
                                                                            [style.paddingRight.px]="content['label_styles'] ? content['label_styles']['padding-right'] : 0"
                                                                            [style.paddingTop.px]="content['label_styles'] ? content['label_styles']['padding-top'] : 0"
                                                                            [style.paddingBottom.px]="content['label_styles'] ? content['label_styles']['padding-bottom'] : 0"
                                                                            *ngIf="content['form']['show_label']">
                                                                            <label>{{field['value']}}</label>
                                                                        </div>
                                                                        <app-signpad [primaryId]="primaryId"
                                                                            [oper]="oper" [saveTried]="saveTried"
                                                                            [field]="field"
                                                                            [dataValues]="dataValues"></app-signpad>
                                                                    </div>
                                                                </div>
                                                                <div [class]="field['class']"
                                                                    *ngIf="field['DataType']!=='ATTACHMENT' && field['DataType']!=='MARKING' && 
                                                                    field['DataType']!=='DIGISIGN' && field['DataType']!=='UPLOAD' && field['is_Hidden']!==true"
                                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                                    <div>
                                                                        <div class="text-left"
                                                                            [style.paddingLeft.px]="content['label_styles'] ? content['label_styles']['padding-left'] : 0"
                                                                            [style.paddingRight.px]="content['label_styles'] ? content['label_styles']['padding-right'] : 0"
                                                                            [style.paddingTop.px]="content['label_styles'] ? content['label_styles']['padding-top'] : 0"
                                                                            [style.paddingBottom.px]="content['label_styles'] ? content['label_styles']['padding-bottom'] : 0"
                                                                            *ngIf="content['form']['show_label']">
                                                                            <label
                                                                                [ngClass]="{'input-invalid':(saveTried===true && field['is_Mandatory'] 
                                                                            && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}">
                                                                                {{field['value']}}{{field['is_Mandatory']?'
                                                                                *':''}}
                                                                                {{field['is_Static_Value']===true &&
                                                                                field['is_MultiSelect']===true?staticValues[field['Field_ID']].length:''}}</label>
                                                                        </div>

                                                                        <select [name]="field['Field_Name']"
                                                                            [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            [required]="field['is_Mandatory']"
                                                                            [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            *ngIf="field['is_Static_Value']===true 
                                                                                && (field['is_Radio_Button'] === undefined || field['is_Radio_Button']===false || field['is_Radio_Button']===null) 
                                                                                && (field['is_Button'] === undefined || field['is_Button']===false || field['is_Button']===null)
                                                                                && (field['is_MultiSelect']===undefined || field['is_MultiSelect']===false)">
                                                                            <option [value]="obj.value"
                                                                                *ngFor="let obj of staticValues[field['Field_ID']]">
                                                                                {{obj.text}}</option>
                                                                        </select>

                                                                        <ng-multiselect-dropdown class="ms-dropdown"
                                                                            [id]="'mvs-'+dataValues['ID']+'-'+field['Field_ID']"
                                                                            [ngStyle]="getMultiSelectStyle(content,dataValues['ID']+'-'+field['Field_ID'])"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            [settings]="multiSelectSettings"
                                                                            [data]="staticValues[field['Field_ID']]"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            (onSelect)="onItemSelect($event)"
                                                                            (onSelectAll)="onSelectAll($event)"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            *ngIf="field['is_Static_Value']===true 
                                                                                && (field['is_MultiSelect']!==undefined && field['is_MultiSelect']===true)">
                                                                        </ng-multiselect-dropdown>

                                                                        <ng-container
                                                                            *ngIf="field['is_Static_Value']===true && field['is_Button']===true
                                                                            && (field['is_MultiSelect']===undefined || field['is_MultiSelect']===false)">
                                                                            <button [name]="field['Field_Name']"
                                                                                *ngFor="let obj of staticValues[field['Field_ID']]"
                                                                                [ngStyle]="getStylesStaticType(column, content.styles,'StaticButton',obj.value === dataValues[field['Field_Name']])"
                                                                                (click)="setPageValue(field,obj.value)"
                                                                                [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                                class="float-left btn mr-2 mb-2"
                                                                                [class]="content['styles']['static_button-style']"
                                                                                appNoDblClick>
                                                                                {{obj.text}}
                                                                            </button>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="field['is_Static_Value']===true && field['is_Radio_Button']===true
                                                                                && (field['is_MultiSelect']===undefined || field['is_MultiSelect']===false)">
                                                                            <label
                                                                                *ngFor="let obj of staticValues[field['Field_ID']]"
                                                                                class="mr-2">
                                                                                <input type="radio"
                                                                                    [name]="field['Field_Name']"
                                                                                    class="mr-1 mb-1"
                                                                                    (click)="setPageValue(field,obj.value)"
                                                                                    [id]="field['Field_ID']"
                                                                                    [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                                    [checked]="obj.value === dataValues[field['Field_Name']]">{{obj.text}}
                                                                            </label>
                                                                        </ng-container>

                                                                        <div class="input-group"
                                                                            *ngIf="field['is_Lookup']===true && field['DataType']==='TINY_STRING'">
                                                                            <input type="text" [id]="field['Field_ID']"
                                                                                [name]="field['Field_Name']"
                                                                                [required]="field['is_Mandatory']"
                                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                                (change)="onMVSectionValueChange(field, dataValues)"
                                                                                readonly="true"
                                                                                [style]="getInputStyle(content['input_styles'],'lookup')"
                                                                                [(ngModel)]="dataValues[field['Field_Name']]"
                                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                                            <div class="input-group-append"
                                                                                *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                                <button
                                                                                    class="btn btn-info btn-pill-right"
                                                                                    type="button"
                                                                                    (click)="selectMVSectionLookup(field, content['index'], dataValues.ID)"
                                                                                    [id]="'lkup'+field.Field_ID"
                                                                                    [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')">
                                                                                    <i [style.fontSize]="content['styles']['input-font-size']"
                                                                                        class="fas fa-search"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div class="input-group"
                                                                            *ngIf="field['is_Lookup']===true && (field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT')">
                                                                            <textarea rows="3" [id]="field['Field_ID']"
                                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                                [name]="field['Field_Name']"
                                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                                [(ngModel)]="dataValues[field['Field_Name']]"
                                                                                [style]="getInputStyle(content['input_styles'],'lookup')"
                                                                                [ngClass]="{'input-form-control':true, 'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                                (change)="onMVSectionValueChange(field, dataValues)"
                                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"></textarea>
                                                                            <div class="input-group-append"
                                                                                *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                                <button
                                                                                    class="btn btn-info btn-pill-right"
                                                                                    type="button"
                                                                                    (click)="selectMVSectionLookup(field, content['index'], dataValues.ID)"
                                                                                    [id]="'lkup'+field.Field_ID">
                                                                                    <i [style.fontSize]="content['styles']['input-font-size']"
                                                                                        class="fas fa-search"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div class="input-group"
                                                                            *ngIf="field['is_External_Value']===true 
                                                                        && (field['DataType']==='TINY_STRING' || field['DataType']==='INTEGER' || field['DataType']==='DECIMAL')">
                                                                            <input type="text" [id]="field['Field_ID']"
                                                                                [name]="field['Field_Name']"
                                                                                [required]="field['is_Mandatory']"
                                                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                                [style.fontSize]="content['styles']['input-font-size']"
                                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                                [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                                (change)="onMVSectionValueChange(field, dataValues)"
                                                                                [style]="getInputStyle(content['input_styles'],'api')"
                                                                                [(ngModel)]="dataValues[field['Field_Name']]"
                                                                                [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                                && content['form']['show_placeholder'] === true ? field['value'] : ''" />
                                                                            <div class="input-group-append"
                                                                                *ngIf="oper==='ADD' || (!field['Disable_On_Edit'] && oper==='EDIT')">
                                                                                <button
                                                                                    class="btn btn-info btn-pill-right"
                                                                                    type="button"
                                                                                    (click)="getAPIData(field, content['form']['fields'])"
                                                                                    [id]="'lkup'+field.Field_ID"
                                                                                    [disabled]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')">
                                                                                    <i [style.fontSize]="content['styles']['input-font-size']"
                                                                                        class="fas fa-search"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <input type="text" [id]="field['Field_ID']"
                                                                            [name]="field['Field_Name']"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                            && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            maxlength="100"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [required]="field['is_Mandatory']"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                                        && (field['DataType']==='TINY_STRING')" />


                                                                        <input type="email" [id]="field['Field_ID']"
                                                                            [email]="true"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            maxlength="100"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [pattern]="emailPattern"
                                                                            [required]="field['is_Mandatory']"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                                        && (field['DataType']==='EMAIL')" />

                                                                        <input type="password" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            maxlength="20"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [required]="field['is_Mandatory']"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            *ngIf="(field['is_Static_Value']!==true && field['is_External_Value']!==true && field['is_Lookup']!==true) 
                                                                        && (field['DataType']==='PASSWORD'||field['DataType']==='ENCRYPTED')" />

                                                                        <input type="number" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                                                            oninput="javascript:if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                            [required]="field['is_Mandatory']"
                                                                            max="999999999" maxlength="9"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='INTEGER'" />

                                                                        <input type="number" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            oninput="javascript:if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                            [required]="field['is_Mandatory']"
                                                                            max="999999999.99" maxlength="12"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='DECIMAL'" />

                                                                        <input type="number" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            (blur)="validatePercent(field['Field_Name'])"
                                                                            oninput="javascript:if(this.value > 999){this.value = null;}"
                                                                            [required]="field['is_Mandatory']" max="999"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            *ngIf="field['is_Static_Value']!==true && field['is_External_Value']!==true && field['DataType']==='PERCENTAGE'" />

                                                                        <input type="date" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [required]="field['is_Mandatory']"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            *ngIf="field['DataType']==='DATE' && field['is_Read_Only'] !== true" />

                                                                        <input type="datetime-local"
                                                                            [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [required]="field['is_Mandatory']"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            *ngIf="field['DataType']==='DATETIME' && field['is_Read_Only'] !== true" />

                                                                        <input type="text" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [required]="field['is_Mandatory']"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            *ngIf="(field['DataType']==='DATE' || field['DataType']==='DATETIME') && field['is_Read_Only'] === true" />

                                                                        <textarea rows="3" [id]="field['Field_ID']"
                                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                                            [style.fontSize]="content['styles']['input-font-size']"
                                                                            [name]="field['Field_Name']"
                                                                            [placeholder]="content['form']['show_placeholder'] !== undefined 
                                                                        && content['form']['show_placeholder'] === true ? field['value'] : ''"
                                                                            [(ngModel)]="dataValues[field['Field_Name']]"
                                                                            [style]="getInputStyle(content['input_styles'],'input')"
                                                                            [ngClass]="{'input-form-control':true,'is-invalid':(saveTried===true && field['is_Mandatory'] && (dataValues[field['Field_Name']]===null || dataValues[field['Field_Name']]===''))}"
                                                                            *ngIf="field['is_Lookup']!==true && (field['DataType']==='MEDIUM_STRING' || field['DataType']==='TEXT')"
                                                                            (change)="onMVSectionValueChange(field, dataValues)"
                                                                            [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"></textarea>

                                                                        <label class="switch switch-lg m-0 pl-2"
                                                                            *ngIf="field['DataType']==='BIT'">
                                                                            <input type="checkbox"
                                                                                [id]="field['Field_ID']"
                                                                                [name]="field['Field_Name']"
                                                                                [(ngModel)]="dataValues[field['Field_Name']]"
                                                                                [readonly]="field['is_Read_Only'] || (field['Disable_On_Edit'] && oper==='EDIT')"
                                                                                (change)="onMVSectionValueChange(field, dataValues)"
                                                                                checked="checked" />
                                                                            <span
                                                                                [id]="'span'+field['Field_ID']"></span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="text-left"
                                                                        *ngIf="content['form']['show_helptext']">
                                                                        <small>{{field['help_text']}}</small>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12" *ngIf="field['section_break']">

                                                                </div>
                                                                <input *ngIf="field['is_Hidden']===true"
                                                                    [id]="field['Field_ID']"
                                                                    [name]="field['Field_Name']" type="hidden"
                                                                    [(ngModel)]="dataValues[field['Field_Name']]" />
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Table'">
                                    <div [ngStyle]="getStylesType(row,column,content,content.styles,'Forms')"
                                        style="position:absolute;text-align: left;overflow:hidden;" class="p-0">
                                        <div class="p-0">
                                            <div class="row m-0">
                                                <div class="col-md-12"
                                                    [style.paddingLeft.px]="content['header_styles'] ? content['header_styles']['padding-left'] : 0"
                                                    [style.paddingRight.px]="content['header_styles'] ? content['header_styles']['padding-right'] : 0"
                                                    [style.paddingTop.px]="content['header_styles'] ? content['header_styles']['padding-top'] : 0"
                                                    [style.paddingBottom.px]="content['header_styles'] ? content['header_styles']['padding-bottom'] : 0">
                                                    <div *ngIf="content['form']['show_header'] === undefined || content['form']['show_header']"
                                                        [style.fontSize]="content['header_styles'] ? content['header_styles']['font-size'] : '16px'"
                                                        [style.fontWeight]="content['header_styles'] ? content['header_styles']['font-weight'] : 'normal'"
                                                        [style.fontStyle]="content['header_styles'] ? content['header_styles']['font-style'] : 'normal'"
                                                        [style.fontFamily]="content['header_styles'] ? content['header_styles']['font-family']: 'inherit'"
                                                        class="float-left">
                                                        {{content['form']['header_text']}}
                                                    </div>
                                                    <ng-container *ngIf="screen_props['allowSave'] ">
                                                        <button class="btn btn-primary btn-small float-right mr-2"
                                                            *ngIf="showSelect(content['form']) &&  content['select_btn_styles'] === undefined"
                                                            [id]="'btnSelect'+content.id"
                                                            (click)="selectMVTableLookup(content['form'],content['index'])">
                                                            Select
                                                        </button>
                                                        <button class="btn btn-primary btn-small float-right mr-2"
                                                            *ngIf="(content['form']['show_add'] === undefined || content['form']['show_add']) &&  content['add_btn_styles'] === undefined"
                                                            [id]="'btnAdd'+content.id"
                                                            (click)="addRowClick(content['index'])">
                                                            Add
                                                        </button>
                                                        <div *ngIf="content['add_btn_styles'] !== undefined"
                                                            [style.order]="content.add_btn_styles['vertical-align']"
                                                            [style.textAlign]="content.add_btn_styles['text-align']"
                                                            [style.marginLeft.px]="content['add_btn_styles']['margin-left']"
                                                            [style.marginRight.px]="content['add_btn_styles']['margin-right']"
                                                            [style.marginTop.px]="content['add_btn_styles']['margin-top']"
                                                            [style.marginBottom.px]="content['add_btn_styles']['margin-bottom']">
                                                            <button class="btn btn-xs btn-light mr-2"
                                                                (click)="selectMVTableLookup(content['form'],content['index'])"
                                                                *ngIf="showSelect(content['form']) && content['select_btn_styles'] !== undefined"
                                                                type="button" [id]="'btnSelect'"
                                                                [ngStyle]="getMVSectionBtnStyles(content.add_btn_styles)">
                                                                <em [class]="content['select_btn_styles']['icon_name']"
                                                                    [style.Color]="content['select_btn_styles']['icon-color']"
                                                                    [style.paddingTop.px]="content['add_btn_styles']['icon-padding-top']"
                                                                    [style.paddingBottom.px]="content['add_btn_styles']['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="content['add_btn_styles']['icon-padding-left']"
                                                                    [style.paddingRight.px]="content['add_btn_styles']['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="content['add_btn_styles']['font-size']"
                                                                    *ngIf="content['select_btn_styles']['icon_name'] !== undefined && (content['select_btn_styles']['icon-align'] === undefined || content['select_btn_styles']['icon-align'] !== 'right')"></em>
                                                                {{content['form']['select_button_text']
                                                                === undefined ? 'Select' :
                                                                content['form']['select_button_text']}}
                                                                <em [class]="content['select_btn_styles']['icon_name'].replace('mr','ml')"
                                                                    [style.Color]="content['select_btn_styles']['icon-color']"
                                                                    [style.paddingTop.px]="content['add_btn_styles']['icon-padding-top']"
                                                                    [style.paddingBottom.px]="content['add_btn_styles']['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="content['add_btn_styles']['icon-padding-left']"
                                                                    [style.paddingRight.px]="content['add_btn_styles']['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="content['add_btn_styles']['font-size']"
                                                                    *ngIf="content['select_btn_styles']['icon_name'] !== undefined && content['select_btn_styles']['icon-align'] !== undefined && content['select_btn_styles']['icon-align'] === 'right'"></em>
                                                            </button>
                                                            <button class="btn btn-xs btn-light" type="button"
                                                                *ngIf="(content['form']['show_add'] === undefined || content['form']['show_add'])"
                                                                [id]="'btnAdd'" (click)="addRowClick(content['index'])"
                                                                [ngStyle]="getMVSectionBtnStyles(content.add_btn_styles)">
                                                                <em [class]="content['add_btn_styles']['icon_name']"
                                                                    [style.Color]="content['add_btn_styles']['icon-color']"
                                                                    [style.paddingTop.px]="content['add_btn_styles']['icon-padding-top']"
                                                                    [style.paddingBottom.px]="content['add_btn_styles']['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="content['add_btn_styles']['icon-padding-left']"
                                                                    [style.paddingRight.px]="content['add_btn_styles']['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="content['add_btn_styles']['font-size']"
                                                                    *ngIf="content['add_btn_styles']['icon_name'] !== undefined && (content['add_btn_styles']['icon-align'] === undefined || content['add_btn_styles']['icon-align'] !== 'right')"></em>
                                                                {{content['form']['add_button_text']
                                                                === undefined ? 'Add' :
                                                                content['form']['add_button_text']}}
                                                                <em [class]="content['add_btn_styles']['icon_name'].replace('mr','ml')"
                                                                    [style.Color]="content['add_btn_styles']['icon-color']"
                                                                    [style.paddingTop.px]="content['add_btn_styles']['icon-padding-top']"
                                                                    [style.paddingBottom.px]="content['add_btn_styles']['icon-padding-bottom']"
                                                                    [style.paddingLeft.px]="content['add_btn_styles']['icon-padding-left']"
                                                                    [style.paddingRight.px]="content['add_btn_styles']['icon-padding-right']"
                                                                    style="vertical-align:middle;"
                                                                    [style.fontSize]="content['add_btn_styles']['font-size']"
                                                                    *ngIf="content['add_btn_styles']['icon_name'] !== undefined && content['add_btn_styles']['icon-align'] !== undefined && content['add_btn_styles']['icon-align'] === 'right'"></em>
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pb-0 pt-0"
                                            *ngIf="content['index'] !== undefined && content['index'] !== null 
                                        && grid['gridAdapter'+content['index']] !== undefined && grid['gridAdapter'+content['index']] !== null">
                                            <div class="row mr-0 ml-0">
                                                <div class="col-12 mr-0 ml-0">
                                                    <jqxGrid #myGrid [id]="content.index" [editable]="true"
                                                        [width]="'100%'" [height]="'350px'"
                                                        [source]="grid['gridAdapter'+content.index]"
                                                        [columns]="grid['columns'+content.index]"
                                                        [everpresentrowposition]="'bottom'"
                                                        (onCellclick)="onCellclick($event,content['form']['fields'],content.index)"
                                                        (onCellvaluechanged)="onCellvaluechanged($event,content.index,content['form']['fields'])">
                                                    </jqxGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Line'">
                                    <div [style.marginLeft]="content['styles']['margin-left']"
                                        [style.marginTop]="content['styles']['margin-top']"
                                        [style.width.px]="content['styles']['width']" [style.position]="'absolute'"
                                        [style.height.px]="content['styles']['height']">

                                        <hr [style.width.px]="content['styles']['width']"
                                            [style.borderWidth.px]="content.styles['border-width']"
                                            [style.borderStyle]="content.styles['border-style']"
                                            [style.borderColor]="content.styles['border-color']">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='VLine'">
                                    <div [style.marginLeft]="content['styles']['margin-left']"
                                        [style.marginTop]="content['styles']['margin-top']" [style.width.px]="10"
                                        [ngStyle]="getStylesType(row,column,content, content.styles,content.type)"
                                        [style.height.px]="content['styles']['height']">
                                        <div [style.width.px]="10" [style.height.px]="content['styles']['height']">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='CLIPATH'">
                                    <div [id]="content.id" style="opacity: 0;"
                                        [style.marginLeft]="getLeftMargin(content['styles'],row)"
                                        [style.marginTop]="content['styles']['margin-top']"
                                        [style.width.px]="content['styles']['width']" [style.position]="'absolute'"
                                        [style.height.px]="content['styles']['height']"
                                        [style]="getShadowStyle(content['styles'])" (click)="loadPage(content)">
                                        <div [id]="'animate'+content.id"
                                            [style.transform]="getSkewState(content.styles)" class="w-100 h-100"
                                            [ngStyle]="getStylesType(row,column,content, content.styles,content.type)">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Shape'">
                                    <div [ngStyle]="getStylesType(row,column, content, content.styles, content.type)"
                                        [id]="content.id">
                                        <div [style.width.px]="content['styles']['width']"
                                            [style.height.px]="content['styles']['height']">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Carousel'">
                                    <div style="position:absolute;" [id]="content.id"
                                        [ngStyle]="getStylesType(row,column,content,content.styles,'Carousel')">
                                        <carousel [itemsPerSlide]="content['styles']['item-count']"
                                            [singleSlideOffset]="true"
                                            [showIndicators]="content['styles']['show-indicator']" [noWrap]="false"
                                            [interval]="content['styles']['carousel-interval'] * 1000"
                                            [startFromIndex]="0">
                                            <slide *ngFor="let item of content.items">
                                                <img [src]="item.imageUrl" alt="banner image"
                                                    [ngStyle]="getStylesType(row,column,content,content.styles,'CarouselImage')"
                                                    style="display: block; width: 100%;object-fit: cover;"
                                                    [style.height.px]="content.styles.height">
                                                <div class="carousel-caption d-none d-md-block">
                                                    <div
                                                        [ngStyle]="getStylesType(row,column,content,content.styles,'Carousel-Main')">
                                                        {{item.mainText}}</div>
                                                    <div
                                                        [ngStyle]="getStylesType(row,column,content,content.sub_styles,'Carousel-Sub')">
                                                        {{item.subText}}</div>
                                                </div>
                                            </slide>
                                        </carousel>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Map'">
                                    <agm-map #agmmap *ngIf="window_width < 700"
                                        [style.height.px]="row.styles.height ==='100%' ? 250 : (row.styles.height > 600 ? 600: row.styles.height)"
                                        [latitude]="content['markers']!==undefined?content['markers'][0]['latitude']:content['map']['latitude']"
                                        [longitude]="content['markers']!==undefined?content['markers'][0]['longitude']:content['map']['longitude']"
                                        [zoom]="content['map']['zoom']" [fitBounds]="true"
                                        [mapTypeId]="content['map']['type'] !== undefined ? content['map']['type'] : 'roadmap'"
                                        [mapDraggable]="true" [disableDoubleClickZoom]="false">
                                        <ng-container *ngIf="content['markers']!==undefined">
                                            <ng-container *ngFor="let marker of content['markers']">
                                                <agm-marker [latitude]="marker['latitude']"
                                                    [iconUrl]="'../assets/img/dummy.png'"
                                                    [longitude]="marker['longitude']" [agmFitBounds]="true"
                                                    *ngIf="marker['longitude'] !== undefined && marker['longitude'] !== null">
                                                    <agm-overlay [latitude]="marker['latitude']"
                                                        [longitude]="marker['longitude']">
                                                        <i [class]="marker['icon_name'] === undefined || marker['icon_name'] === '' ? 'fas fa-map-marker' : marker['icon_name']"
                                                            [style.Color]="marker['icon-color'] === undefined ? 'red' : marker['icon-color']"
                                                            [style.fontSize]="marker['icon-size']"
                                                            style="height: 32px; width: 32px;"></i>
                                                    </agm-overlay>
                                                </agm-marker>
                                            </ng-container>
                                        </ng-container>
                                    </agm-map>
                                    <agm-map #agmmap *ngIf="window_width > 700"
                                        [style.height.px]="rowContainerComponent.offsetHeight"
                                        [latitude]="content['markers']!==undefined?content['markers'][0]['latitude']:content['map']['latitude']"
                                        [longitude]="content['markers']!==undefined?content['markers'][0]['longitude']:content['map']['longitude']"
                                        [zoom]="content['map']['zoom']" [fitBounds]="true"
                                        [styles]="content['map']['style'] !== undefined ? agmStyles[content['map']['style']] : agmStyles['default']"
                                        [mapTypeId]="content['map']['type'] !== undefined ? content['map']['type'] : 'roadmap'"
                                        [mapDraggable]="true" [disableDoubleClickZoom]="false">
                                        <ng-container *ngIf="content['markers']!==undefined">
                                            <ng-container *ngFor="let marker of content['markers']">
                                                <agm-marker [latitude]="marker['latitude']"
                                                    [iconUrl]="'../assets/img/dummy.png'"
                                                    [longitude]="marker['longitude']" [agmFitBounds]="true"
                                                    *ngIf="marker['longitude'] !== undefined && marker['longitude'] !== null">
                                                    <agm-overlay [latitude]="marker['latitude']"
                                                        [longitude]="marker['longitude']">
                                                        <i [class]="marker['icon_name'] === undefined || marker['icon_name'] === '' ? 'fas fa-map-marker' : marker['icon_name']"
                                                            [style.Color]="marker['icon-color'] === undefined ? 'red' : marker['icon-color']"
                                                            [style.fontSize]="marker['icon-size']"
                                                            style="height: 32px; width: 32px;"></i>
                                                    </agm-overlay>
                                                </agm-marker>
                                            </ng-container>
                                        </ng-container>
                                    </agm-map>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Text'">
                                    <div [id]="content.id + column.id">
                                        <div [id]="content.id"
                                            [ngStyle]="getStylesType(row,column,content,content.styles,'Text')"
                                            [ngClass]="{'humble-hover-dyn':content.styles['hover-available']}"
                                            style="opacity: 0;padding: 10px;">
                                            <em [class]="content.styles['icon_name']"
                                                [style.order]="content.styles['icon-align'] === 'right' ? 2 : 1"
                                                [style.fontSize]="content.styles['icon-size']"
                                                [style.Color]="content.styles['icon-color']"
                                                [style.paddingTop.px]="content.styles['icon-padding-top']"
                                                [style.paddingBottom.px]="content.styles['icon-padding-bottom']"
                                                [style.paddingLeft.px]="content.styles['icon-padding-left']"
                                                [style.paddingRight.px]="content.styles['icon-padding-right']"
                                                *ngIf="content.styles['icon_name'] !== undefined"></em>
                                            <div class="content mobile-text-size"
                                                [style.order]="content.styles['icon-align'] === 'right' ? 1 : 2"
                                                [ngClass]="{ 'text-max-size': getFontClass(content.styles['font-size']),'text-max-margin': getMarginClass(content.styles['margin-left'])}">
                                                <div class="text-content"
                                                    [innerHtml]="content.htmlvalue === undefined ? getCFHTML(content) : content.htmlvalue">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='TextImage'">
                                    <div [id]="content.id + column.id"
                                        [style.borderRadius.px]="content.styles['border-radius']"
                                        [ngStyle]="getStylesType(row,column,content,content.styles,'TextImage')">
                                        <div class="content mobile-text-size" [id]="content.id"
                                            [ngStyle]="{'height':window_width < 700 ? content['styles']['height'] + 'px' : ''}"
                                            [ngClass]="{ 'text-max-size': getFontClass(content.styles['font-size']),'text-max-margin': getMarginClass(content.styles['margin-left'])}">

                                            <div class="row m-0"
                                                [style.borderTopLeftRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                                (content['text-styles'] === undefined || content['text-styles']['border-top-left-radius'] === undefined ? '0': content['text-styles']['border-top-left-radius']) : 
                                                (content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius'])"
                                                [style.borderTopRightRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                                (content['text-styles'] === undefined || content['text-styles']['border-top-right-radius'] === undefined ? '0' : content['text-styles']['border-top-right-radius']) : 
                                                (content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius'])"
                                                [style.borderBottomLeftRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                                (content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']) : 
                                                (content['text-styles'] === undefined || content['text-styles']['border-bottom-left-radius'] === undefined ? '0' : content['text-styles']['border-bottom-left-radius'])"
                                                [style.borderBottomRightRadius.px]="content.styles['text-position'] === 'TOP' ? 
                                                (content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']) : 
                                                (content['text-styles'] === undefined || content['text-styles']['border-bottom-right-radius'] === undefined ? '0' : content['text-styles']['border-bottom-right-radius'])"
                                                [style.borderImageSource]="'url(' + content['border-image-url'] + ')'"
                                                [style.borderImageSlice]="content.styles['border-width']"
                                                [style.borderImageWidth.px]="content.styles['border-width']"
                                                [style.borderStyle]="content.styles['border-style']"
                                                [style.borderColor]="content.styles['border-color']"
                                                [style.borderWidth.px]="content.styles['border-width'] === undefined ? '0' : content.styles['border-width']"
                                                [ngStyle]="{'height':window_width > 700 ? content['styles']['height'] + 'px' : ''}"
                                                [style.width.px]="content['styles']['width']">
                                                <div class="col-12" style="padding-bottom:20px;"
                                                    [style.borderTopLeftRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-top-left-radius'] === undefined ? '0' : content['text-styles']['border-top-left-radius']"
                                                    [style.borderTopRightRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-top-right-radius'] === undefined ? '0' : content['text-styles']['border-top-right-radius']"
                                                    [style.background]="content.styles['text-background-color']"
                                                    *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'TOP'">
                                                    <div [innerHtml]="getHTML(content.value)">
                                                    </div>
                                                </div>
                                                <div class="col-12"
                                                    *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'TOP'"
                                                    [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                                    [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                                    [style.background]="content.styles['background-color']">
                                                    <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                                        (mouseenter)="switchImage(content.id,true,content)"
                                                        (mouseleave)="switchImage(content.id,false,content)"
                                                        [style.height.%]="100"
                                                        [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                                        [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                                        [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                                        [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                                        [style.padding.px]="content['styles']['padding']"
                                                        [style.width.%]="100">
                                                </div>

                                                <div class="col-12"
                                                    *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'BOTTOM'"
                                                    [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                                    [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                                    [style.background]="content.styles['background-color']">
                                                    <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                                        (mouseenter)="switchImage(content.id,true,content)"
                                                        (mouseleave)="switchImage(content.id,false,content)"
                                                        [style.height.%]="100"
                                                        [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                                        [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                                        [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                                        [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                                        [style.padding.px]="content['styles']['padding']"
                                                        [style.width.%]="100">
                                                </div>
                                                <div class="col-12"
                                                    [style.borderBottomLeftRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-bottom-left-radius'] === undefined ? '0' : content['text-styles']['border-bottom-left-radius']"
                                                    [style.borderBottomRightRadius.px]="content['text-styles'] === undefined || content['text-styles']['border-bottom-right-radius'] === undefined ? '0' : content['text-styles']['border-bottom-right-radius']"
                                                    [style.background]="content.styles['text-background-color']"
                                                    *ngIf="content.styles['text-position'] !== undefined && content.styles['text-position'] === 'BOTTOM'">
                                                    <div [innerHtml]="getHTML(content.value)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Lottie'">
                                    <div [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                        <ng-lottie width="100%" height="100%"
                                            [options]="{path: content.url}"></ng-lottie>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Image'">
                                    <div class="content" [id]="content.id"
                                        [ngStyle]="getStylesType(row,column,content,content.styles,'Image')"
                                        [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                        [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                        [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                        [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                        [style.borderImageSource]="'url(' + content['border-image-url'] + ')'"
                                        [style.borderImageSlice]="content.styles['border-width']"
                                        [style.borderImageWidth.px]="content.styles['border-width']"
                                        [style.backgroundColor]="content.styles['image-opacity-color']"
                                        [style.borderStyle]="content.styles['border-style']"
                                        [style.borderColor]="content.styles['border-color']"
                                        [style.borderWidth.px]="(content.styles['border-width']===undefined||content.styles['border-width']===null||content.styles['border-width']==='')? '0' : content.styles['border-width']"
                                        style="opacity:0;" (click)="loadPage(content)">
                                        <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                            (mouseenter)="switchImage(content.id,true,content)"
                                            (mouseleave)="switchImage(content.id,false,content)"
                                            [style.padding.px]="content['styles']['padding']"
                                            [style.objectFit]="content['styles']['image-cover'] ? 'cover':content['styles']['image-contain'] ? 'contain':''"
                                            [style.objectPosition]="content['styles']['image-center'] ? 'center' : ''"
                                            [style.opacity]="content.styles['image-opacity'] === 'undefined'?'1':content.styles['image-opacity']"
                                            [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                            [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                            [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                            [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                            [ngStyle]="getImageStyles(content)">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type==='Video'">
                                    <div class="content" [id]="content.id"
                                        [ngStyle]="getStylesType(row,column,content,content.styles,'Image')"
                                        [style.borderTopLeftRadius.px]="content['styles']['border-top-left-radius'] === undefined ? '0' : content['styles']['border-top-left-radius']"
                                        [style.borderTopRightRadius.px]="content['styles']['border-top-right-radius'] === undefined ? '0' : content['styles']['border-top-right-radius']"
                                        [style.borderBottomLeftRadius.px]="content['styles']['border-bottom-left-radius'] === undefined ? '0' : content['styles']['border-bottom-left-radius']"
                                        [style.borderBottomRightRadius.px]="content['styles']['border-bottom-right-radius'] === undefined ? '0' : content['styles']['border-bottom-right-radius']"
                                        [style.borderStyle]="content.styles['border-style']"
                                        [style.borderColor]="content.styles['border-color']"
                                        [style.backgroundColor]="content.styles['video-opacity-color']"
                                        [style.borderWidth.px]="(content.styles['border-width']===null || content.styles['border-width']==='')?0:content.styles['border-width']"
                                        style="opacity:0;">
                                        <video [src]="content.url" [style.height.%]="100" [style.width.%]="100"
                                            [style.objectFit]="'cover'"
                                            [style.opacity]="content.styles['video-opacity'] === 'undefined'?'1':content.styles['video-opacity']"
                                            [ngStyle]="getShadowStyle(content)" controls disablePictureInPicture
                                            controlsList="nodownload"></video>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>