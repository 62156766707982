<ng-container *ngIf="content.template==='1'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="mb-1" [class]="content.class" *ngFor="let item of items">
                <div [ngStyle]="getStylesMain(content.slider_styles, content.styles)">
                    <div [ngStyle]="getStyles(content.image_styles)">
                        <img [src]="item.IMAGE_URL" alt="image slide" 
                            style="display: block; width: 100%;object-fit: fill;" 
                            height="250">
                    </div>
                    <div [ngStyle]="getStyles(content.title_styles)" 
                        style="line-height: 1.5em; overflow: hidden;">
                        {{item.PUBLISHED_TITLE}}
                    </div>
                    <div [ngStyle]="getStyles(content.desc_styles)"
                        style="line-height: 1.5em; overflow: hidden;white-space: pre;">
                        {{item.PUBLISHED_DESCRIPTION}}
                    </div>
                    <div [ngStyle]="getStyles(content.button_styles)">
                        <button class="btn btn-lg" (click)="loadPage(item)"
                        style="color:inherit;font-size:inherit;letter-spacing:inherit;font-family:inherit;text-align:inherit;font-weight:inherit;font-style:inherit;">
                        Read More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='2'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="mb-1" [class]="content.class" *ngFor="let item of items">
                <div [ngStyle]="getStylesMain(content.slider_styles, content.styles)">
                    <div [ngStyle]="getStyles(content.title_styles)" 
                        style="line-height: 1.5em; overflow: hidden;">
                        {{item.PUBLISHED_TITLE}}
                    </div>
                    <div [ngStyle]="getStyles(content.image_styles)">
                        <img [src]="item.IMAGE_URL" alt="image slide" 
                            style="display: block; width: 100%;object-fit: fill;" 
                            height="250">
                    </div>
                    <div [ngStyle]="getStyles(content.desc_styles)"
                        style="line-height: 1.5em; overflow: hidden;white-space: pre;">
                        {{item.PUBLISHED_DESCRIPTION}}
                    </div>
                    <div [ngStyle]="getStyles(content.button_styles)">
                        <button class="btn btn-lg" (click)="loadPage(item)"
                        style="color:inherit;font-size:inherit;letter-spacing:inherit;font-family:inherit;text-align:inherit;font-weight:inherit;font-style:inherit;">
                        Read More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='3'">
    <div class="mt-2 mb-2">
        <div class="row" *ngFor="let item of items" 
            [ngStyle]="getStylesMain(content.slider_styles, content.styles)">
            <div class="col-xs-12 col-sm-12 col-lg-4">
                <div [ngStyle]="getStyles(content.image_styles)">
                    <img [src]="item.IMAGE_URL" alt="image slide" 
                    style="display: block; width: 100%;object-fit: fill;" 
                    height="250">  
                </div>
                <div [ngStyle]="getStyles(content.button_styles)">
                    <button class="btn btn-lg" (click)="loadPage(item)"
                    style="color:inherit;font-size:inherit;letter-spacing:inherit;font-family:inherit;text-align:inherit;font-weight:inherit;font-style:inherit;">
                    Read More</button>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-8">
                <div [ngStyle]="getStyles(content.title_styles)" 
                    style="line-height: 1.5em; overflow: hidden;">
                    {{item.PUBLISHED_TITLE}}
                </div>
                <div [ngStyle]="getStyles(content.desc_styles)"
                    style="line-height: 1.5em; overflow: hidden;white-space: pre;">
                    {{item.PUBLISHED_DESCRIPTION}}
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='4'">
    <div class="mt-2 mb-2">
        <div class="row" *ngFor="let item of items" 
            [ngStyle]="getStylesMain(content.slider_styles, content.styles)">
            <div class="col-xs-12 col-sm-12 col-lg-8">
                <div [ngStyle]="getStyles(content.title_styles)" 
                    style="line-height: 1.5em; overflow: hidden;">
                    {{item.PUBLISHED_TITLE}}
                </div>
                <div [ngStyle]="getStyles(content.desc_styles)"
                    style="line-height: 1.5em; overflow: hidden;white-space: pre;">
                    {{item.PUBLISHED_DESCRIPTION}}
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-4">
                <div [ngStyle]="getStyles(content.image_styles)">
                    <img [src]="item.IMAGE_URL" alt="image slide" 
                    style="display: block; width: 100%;object-fit: fill;" 
                    height="250">  
                </div>
                <div [ngStyle]="getStyles(content.button_styles)">
                    <button class="btn btn-lg" (click)="loadPage(item)"
                    style="color:inherit;font-size:inherit;letter-spacing:inherit;font-family:inherit;text-align:inherit;font-weight:inherit;font-style:inherit;">
                    Read More</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>