<form [formGroup]="loginForm" class="form-validate form-horizontal" role="form" name="loginForm" novalidate=""
    autocomplete="off">
    <div class="card card-default">
        <div class="card-header" [ngStyle]="getStyles(content.header_styles)">
            <label class="col-form-label" [style.textDecoration]="content.header_styles['text-decoration']">
                {{content.header_styles.value === undefined || content.header_styles.value ===
                '' ? 'Sign in': content.header_styles.value}}
            </label>
        </div>
        <div class="card-body" [ngStyle]="getStyles(content.styles)">
            <div class="row mb-2">
                <div class="col-12 ">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">Username *</label>
                        <input id="exampleInputEmail1" type="text" name="email" placeholder="Enter username"
                            autocomplete="off" formControlName="email" required=""
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(loginForm.controls['email'].hasError('required') && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-1">
                        <label class="col-form-label" [style.textDecoration]="content.styles['text-decoration']">Password *</label>
                        <input id="exampleInputPassword1" type="password" name="password" placeholder="Password"
                            formControlName="password" required=""
                            [class]="content['input_styles']['input-control-class'] !== undefined ? content['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched))}"
                            [style]="getInputStyle(content,content['input_styles'])" />
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12">
                    <label class="float-right mt-2">
                        <a (click)="forgotPassword()" style="cursor: pointer;">
                            Forgot password ?
                        </a>
                    </label>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12" [style.textAlign]="content['btn_styles']['text-align']">
                    <button class="btn btn-block btn-primary mt-3" type="button"
                        *ngIf="content['btn_styles']===undefined"
                        (click)="submitForm($event, loginForm.value)">Login</button>

                    <button class="mt-3" (click)="submitForm($event, loginForm.value)"
                        [ngStyle]="getFormButtonStyle(content.btn_styles)"
                        [style.textDecoration]="content['btn_styles']['text-decoration']"
                        [style.fontStyle]="content['btn_styles']['font-style']"
                        [style.fontWeight]="content['btn_styles']['font-weight']"
                        [style.color]="content['btn_styles']['color']"
                        [style.backgroundColor]="content['btn_styles']['background-color']"
                        *ngIf="content['btn_styles']!==undefined">{{content.btn_styles.value === undefined ||
                        content.btn_styles.value ===
                        '' ? 'Login': content.btn_styles.value}}</button>
                </div>
            </div>
        </div>
    </div>
</form>