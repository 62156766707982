import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, EcommerceService } from '../../../services';
import { EnvService } from '../../../../environments/env.service';
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CouponsComponent } from '../coupons/coupons.component';
import { PageFunctions } from '../../directives/PageFunctions';
import { ExternalLibraryService } from '../../directives/util';

declare var Razorpay: any;
//declare var bolt: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  @Input() props: any;
  @Input() designPage: string;
  @Input() row: any;
  valForm: FormGroup;
  paymentOptions: any = {};
  cartItems: any = [];
  distinctCoupons: any = [];
  selectedCoupon: any = {};
  userData: any = {};
  showDeliveryPage: boolean = false;
  couponsAvailable: boolean = false;
  processing: boolean = false;
  couponDiscount: any = 0;
  selectedPayment: string = "";
  paymentResponse: any;
  website_logo: string = "";
  gateways: any = [];

  constructor(public pageFunctions: PageFunctions, public router: Router, 
    public environment:EnvService, public authService:AuthService, public extService: ExternalLibraryService,
    public dataService:EcommerceService, public dialog: MatDialog, fb: FormBuilder, 
    public snackBar: MatSnackBar, private toastr: ToastrService) {
    
    this.valForm = fb.group({
      'firstName': [null, Validators.compose([Validators.required])],
      'lastName': [null, Validators.compose([Validators.required])],
      'mobile': [null, Validators.compose([Validators.required])],
      'email': [''],
      'address1': [null, Validators.compose([Validators.required])],
      'address2': [null, Validators.compose([Validators.required])],
      'city': [null, Validators.compose([Validators.required])],
      'state': [null, Validators.compose([Validators.required])],
      'country': [''],
      'pincode': [null, Validators.compose([Validators.required])],
      'mvolaDebitNo':[''],
      'referenceNo':['']
    });
    this.getGateways();
    this.setDeliveryInfo();
  }

  getGateways(){
    this.dataService.getGateways().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.gateways = res['PG'];
      }
    });
  }

  getCheckoutProductsInfo(){
    this.dataService.getCartlist().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.cartItems = res.DATA;
        this.getDistinctCoupons();
        this.getPaymentOptions();
      }
    });
  }

  getPaymentOptions(){
    this.dataService.getPaymentOptions().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.paymentOptions = res;
        var country = this.gateways.filter(pg=>pg.CODE===res['COUNTRY']);
        if (country.length > 0){
          if (!country[0]['PG'].includes("Stripe")){
            this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("RazorPay")){
            this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("PayPal")){
            this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("MVola")){
            this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Airtel")){
            this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Orange")){
            this.paymentOptions['IS_ORANGE_ACTIVE']='0';
          }
        }
        else{
          this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          this.paymentOptions['IS_ORANGE_ACTIVE']='0';
        }
      }
    });
  }

  setPaymentOption(option){
    this.selectedPayment = option;
  }

  getDistinctCoupons(){
    this.distinctCoupons = [];
    this.cartItems.forEach(item => {
      item['DISCOUNT'] = 0;
      item['COUPON_CODE'] = '';
      item['COUPONS'] = JSON.parse(item['COUPONS']);
      if (item['COUPONS'].length > 0){
        this.couponsAvailable = true;
        
        item.COUPONS.forEach(coupon => {
          if (this.distinctCoupons.filter(cp=>cp.CODE===coupon.CODE).length === 0){
            this.distinctCoupons.push(coupon);
          }
        });
      }
    });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  applyCoupon(){
    const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.hasBackdrop = true;
		dialogConfig.height = '50%';
		dialogConfig.width = '50%';

		const dialogRef = this.dialog.open(CouponsComponent, dialogConfig);
		dialogRef.componentInstance.Coupons = this.distinctCoupons;
    dialogRef.componentInstance.Apply = true;
		const closeDialog = dialogRef.componentInstance.SelectCoupon.subscribe((data: any) => {
      this.selectedCoupon = data;
      this.getCouponDiscount();
		});
  }

  getCouponDiscount(){
    this.couponDiscount = 0;
    if (this.selectedCoupon['ID']!== undefined && this.selectedCoupon['ID'] !== ""){
      if (this.distinctCoupons.filter(cp=>cp.ID===this.selectedCoupon['ID']).length === 0){
        this.selectedCoupon = {};
      }
      else{
        this.dataService.getCoupon(this.selectedCoupon['ID']).subscribe((res : any)=>{
          if (res.RESULT_CODE === 1){
            var selectedCoupon = res;
            if (selectedCoupon['APPLIES_TO'] !== "MIN_AMOUNT"){
              this.cartItems.forEach(item => {
                
                item['COUPON_CODE'] = '';
                item['DISCOUNT'] = 0;

                if (item['COUPONS'].length > 0 && item.IS_STOCK_AVAILABLE === '1'){
                  this.couponsAvailable = true;
                  
                  item.COUPONS.forEach(coupon => {
                    if (coupon['ID']===selectedCoupon['ID']){
                      
                      item['COUPON_CODE'] = selectedCoupon['COUPON_CODE'];

                      if (selectedCoupon['COUPON_TYPE']==="PERCENTAGE"){
                        var discPercent = selectedCoupon['COUPON_PERCENT'];
                        item['DISCOUNT'] = (item.PRICE * item.CART_COUNT * discPercent)/100;
                      }
                      else if (selectedCoupon['COUPON_TYPE']==="VALUE"){
                        item['DISCOUNT'] = selectedCoupon['COUPON_VALUE'] * item.CART_COUNT;
                      }

                      this.couponDiscount += item['DISCOUNT'];
                    }
                  });
                }
              });
            }
            else{
              var itemTotal = this.getItemTotal();

              this.cartItems.forEach(item => {
                item['COUPON_CODE'] = '';
                item['DISCOUNT'] = 0;
              });

              if (itemTotal >= selectedCoupon['MINIMUM_ORDER_AMOUNT']){
                if (selectedCoupon['COUPON_TYPE']==="PERCENTAGE"){
                  this.couponDiscount = (itemTotal * selectedCoupon['COUPON_PERCENT'])/100;
                }
                else{
                  this.couponDiscount = selectedCoupon['COUPON_VALUE'];
                }
              }
              else{
                var msg = "Coupon not applicable as order value below "+selectedCoupon['MINIMUM_ORDER_AMOUNT'];
                this.toastr.error(msg,'Error');
              }
            }
          }
        });
      }
    }
  }

  ngOnInit(): void {

    if (this.props.template === undefined){
      this.props["template"]="1";
    }
    if (this.props.table_styles === undefined){
      this.props['table_styles'] = {
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0
      }
    }
    if (this.props.header_styles === undefined){
      this.props['header_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props.body_styles === undefined){
      this.props['body_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props.footer_styles === undefined){
      this.props['footer_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props['checkout_styles'] === undefined) {
      this.props['checkout_styles'] = {
        'color':'#fff',
        'background-color':'#21a240',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    if (this.props['shopping_styles'] === undefined) {
      this.props['shopping_styles'] = {
        'color':'#fff',
        'background-color':'#036b80',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    if (this.props['coupon_styles'] === undefined) {
      this.props['coupon_styles'] = {
        'color':'#fff',
        'background-color':'#f05050',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    this.row.styles.height="100%";

    this.extService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js', false)
    .subscribe();

    //this.extService
    //.lazyLoadLibrary('https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js', true)
    //.subscribe();
  }

  showCoupons(item){
    const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.hasBackdrop = true;
		dialogConfig.height = '50%';
		dialogConfig.width = '50%';

		const dialogRef = this.dialog.open(CouponsComponent, dialogConfig);
		dialogRef.componentInstance.Coupons = item.COUPONS;
    dialogRef.componentInstance.Apply = false;
		const closeDialog = dialogRef.componentInstance.SelectCoupon.subscribe((data: any) => {

		});
  }

  onQtyBlur(item){
    if (item.CART_COUNT === null || item.CART_COUNT === 0){
      item.CART_COUNT = 1;
    }
    this.getCouponDiscount();
  }

  gotoHome(){
    var homeUrl = this.environment.homePage;
    this.router.navigate([homeUrl]);
  }

  gotoLogin(){
    var loginUrl = this.dataService.AppData.LoginPage;
    this.router.navigate([loginUrl]);
  }

  deleteCartItem(product){
    this.dataService.deleteFromCart(product);
    this.cartItems = this.cartItems.filter(item=>item.VARIANT_ID !== product.VARIANT_ID);
    this.getDistinctCoupons();
    this.getCouponDiscount();
  }

  getItemTotal(){
    var total = 0;
    this.cartItems.forEach(item => {
      if (item.IS_STOCK_AVAILABLE === '1'){
        total = total + (item.PRICE * item.CART_COUNT);
      }
    });
    return total;
  }

  getTotal(){
    var total = this.getItemTotal();
    return total - this.couponDiscount;
  }

  getStyles(obj) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setPadding(style, obj);

    this.pageFunctions.setBorder(style,obj,true);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

    if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
      style["width"] = obj['width'] + "%";
    }

    if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== ""){
      style['margin-left'] = (parseInt(obj['margin-left'].replace('px',''))) + "px";
    }
   
    if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== ""){
     style['margin-top'] = obj['margin-top'];
    }
    
    return style;
  }

  continueCheckout(){
    this.showDeliveryPage = true;
  }

  setDeliveryInfo(){
    this.dataService.getCustomerProfile().subscribe(res=>{
      if (res.RESULT_CODE===1){
        delete res.RESULT_CODE;
        this.userData = res;
        this.valForm.controls['firstName'].setValue(this.userData.FIRST_NAME);
        this.valForm.controls['lastName'].setValue(this.userData.LAST_NAME);
        this.valForm.controls['mobile'].setValue(this.userData.MOBILE);
        this.valForm.controls['email'].setValue(this.userData.EMAIL);
        this.valForm.controls['address1'].setValue(this.userData.ADDRESS1);
        this.valForm.controls['address2'].setValue(this.userData.ADDRESS2);
        this.valForm.controls['city'].setValue(this.userData.CITY);
        this.valForm.controls['state'].setValue(this.userData.STATE);
        if (this.userData.COUNTRY !== null)
          this.valForm.controls['country'].setValue(this.userData.COUNTRY);
        this.valForm.controls['pincode'].setValue(this.userData.PINCODE);
        
        this.getCheckoutProductsInfo();
      }
      else{
        this.authService.customerlogout();
        this.authService.clearCustomerSession();
        this.gotoLogin();
      }
    });

  }

  makePayment($event){
    $event.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {

      let value = this.valForm.value;
      let couponId = "";
      if (this.selectedCoupon['ID'] !== undefined && this.selectedCoupon['ID'] !== ""){
        couponId = this.selectedCoupon['ID'];
      }
      let cartItems = this.cartItems.filter(item=>item.IS_STOCK_AVAILABLE === '1');
      let itemTotal = this.getItemTotal();
      let orderAmt = this.getTotal();
      let currency = this.dataService.AppData.CurrencyCode;
      let successUrl = this.dataService.AppData.SuccessPage;
      let failureUrl = this.dataService.AppData.FailurePage;
      let mvola_debit_number = "0";
      
      if (orderAmt > 0 && this.selectedPayment !== "" && successUrl !== "" && successUrl !== null && mvola_debit_number !== ""){
        this.processing = true;

        this.dataService.createOrder(value.firstName, value.lastName, value.mobile, value.email, 
          value.address1, value.address2, value.city, value.state, value.country, value.pincode, 
          currency, mvola_debit_number, couponId, itemTotal, this.couponDiscount, 
          orderAmt, value.referenceNo ,cartItems, this.selectedPayment, successUrl, failureUrl)
        .subscribe(data => {

          this.processing = false;

          if(data['RESULT_CODE'] === 1){
            this.website_logo = data['SITE_LOGO'];
            if (this.selectedPayment === 'Stripe'){
              if (data['checkout_url'] !== undefined && data['checkout_url'] !== null && data['checkout_url'] !== ""){
                document.location.href = data['checkout_url'];
              }
              else{
                if (data['message'] !== undefined && data['message'] !== null && data['message'] !== ""){
                  this.toastr.error(data['message'],'Error');
                }
                else{
                  this.toastr.error('Something went wrong.. try after sometime !!','Error');
                }
              }
            }
            else if (this.selectedPayment === 'PayPal'){
              if (data['checkout_url'] !== undefined && data['checkout_url'] !== null && data['checkout_url'] !== ""){
                document.location.href = data['checkout_url'];
              }
              else{
                  this.toastr.error('Something went wrong.. try after sometime !!','Error');
              }
            }
            else if (this.selectedPayment === 'RazorPay'){
              var rzp1 = new Razorpay(this.preparePaymentDetails(data, value, orderAmt));
              rzp1.open(); 
            }
            // else if (this.selectedPayment === 'PayU'){
            //   this.onPayUMoneyPayment(data, value, orderAmt);
            // }
            else{
              this.dataService.AppData.CART_LIST=[];
              this.dataService.clearCart();
              this.router.navigate([successUrl]);
            }
          }
          else if(data['RESULT_CODE'] === 2){
            this.toastr.info('Sorry, Unable to process the order now !!','Information');
          }
          else
          {
            this.toastr.error('Something went wrong.. try after sometime !!','Error');
          }
        },
        error => {
          this.processing = false;
          this.toastr.error('Something went wrong.. try after sometime !!','Error');
        });
      }
      else{
        this.processing = false;
        if (orderAmt === 0){
          this.toastr.info('Order Amount should be greater than zero.. cannot process order','Information');
        }
        else if (this.selectedPayment === ""){
          this.toastr.info('Payment Method not selected.. cannot process order','Information');
        }
        else if (successUrl === "" || successUrl === null){
          this.toastr.info('Checkout Setting not completed.. cannot process order','Information');
        }
      }
    }

  }

  // async onPayUMoneyPayment(data, checkoutinfo, orderAmt){
  //   let value = this.valForm.value;
  //   var camount: any = value.orderAmt;
  //   var cfirstname: any = value.firstName;
  //   var cemail: any = value.email;
  //   var cphone: any = value.mobile;
  //   var ref = this;
  //   var payuBolt = new bolt.launch({
  //     key: this.paymentOptions['PAYU_KEY'],
  //     txnid: data['ORDER_NO'], 
  //     hash: data['hashh'],
  //     udf5: 'BOLT_KIT_PHP7',
  //     amount: camount.toString(),
  //     firstname: cfirstname,
  //     email: cemail,
  //     phone: cphone.toString(),
  //     productinfo: this.environment.websiteTitle + ' Products',
  //     surl: data[''],
  //     furl: data['']
  //   },
  //   { responseHandler: function(BOLT){
  //       if(BOLT.response.txnStatus != 'CANCEL'){
  //         ref.handlePayment(BOLT.response,'PayU',value,data);
  //       }
  //       else{
  //         ref.dismissedPayment();
  //       }
  //     },
  //     catchException: function(BOLT){
  //       alert( BOLT.message );
  //     }
  //   });
  // }

  preparePaymentDetails(order, checkoutinfo, orderAmt){
    var ref = this;
    return  {
      "key": this.paymentOptions.RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      "amount": orderAmt * 100, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      "name": this.environment.websiteTitle,
      "currency": 'INR',
      "order_id": order.RAZOR_ORDER_ID,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
      "image": this.website_logo,
      "modal": {
        escape: false,
      },
      "handler": function (response){
        ref.handlePayment(response,'RazorPay',checkoutinfo, order);
      },
      "modal.ondismiss": function(){
        ref.dismissedPayment();
      },
      "prefill": {
          "name": checkoutinfo.firstName + ' ' + checkoutinfo.lastName,
          "email": checkoutinfo.email,
          "contact": checkoutinfo.mobile
      },
      "theme": {
          "color": "#F28500"
      }
    };
  }

  handlePayment(response, paymentGateway, checkoutInfo, orderInfo) {
    var paymentId: string = '';
    var respSignature: string = '';
    var respStatus: string = '';

    if (paymentGateway==='RazorPay'){
      paymentId = response.razorpay_payment_id;
      respSignature = response.razorpay_signature;
      respStatus = 'success';
    }
    // else{
    //   paymentGateway = 'PayU';
    //   paymentId = response.payuMoneyId;
    //   respSignature = response.bank_ref_num + '|' + response.hash;
    //   respStatus = response.txnStatus;
    // }
    this.dataService.verifyPayment(paymentGateway, orderInfo.ORDER_ID, orderInfo.ORDER_NO, checkoutInfo.orderAmt,
      paymentId, respSignature, checkoutInfo.mobile, checkoutInfo.email, JSON.stringify(response)
    ).subscribe(res => {
      if (res['status'].toLowerCase() === 'success' ){
        this.dataService.AppData.CART_LIST=[];
        this.dataService.clearCart();
        document.location.href = orderInfo['success_url'];
      }
      else{
        this.toastr.error('Order Payment failed.... cannot process order','Error');
      }
    },
    error => {
      this.paymentResponse = error;
    });
  }

  dismissedPayment(){}
}
