import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-categoryfilter',
  templateUrl: './categoryfilter.component.html',
  styleUrls: ['./categoryfilter.component.scss']
})
export class CategoryfilterComponent implements OnInit {
  @Input() content: any;

  	constructor(public router: Router) {}

  	ngOnInit(): void {}

	getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0) {
			style["height"] = obj['height'] + "px";
		}


		if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
			style["width"] = obj['width'] + "%";
		}

		if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== "") {
			style['margin-left'] = (parseInt(obj['margin-left'].replace('px', ''))) + "px";
		}

		if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== "") {
			style['margin-top'] = obj['margin-top'];
		}

		return style;
	}
}
