<div class="row m-0">
    <div class="col-12 mt-2 mb-1" [ngStyle]="getStyles(props.styles)">
        Your Orders
    </div>
    <div class="col-12 mt-2 mb-2" *ngIf="orders.length === 0">No orders made yet.</div>

    <div class="table-responsive mb-2" *ngIf="orders.length > 0" [ngStyle]="getStyles(props.table_styles)">
        <table class="table table-bordered" style="min-width:500px;overflow-x:scroll;">
            <thead [ngStyle]="getStyles(props.header_styles)">
                <tr>
                    <th style="width:20%;">Order No</th>
                    <th style="width:15%;">Order Date</th>
                    <th style="width:15%;">Amount</th>
                    <th style="width:20%;">Payment Status</th>
                    <th style="width:20%;">Order Status</th>
                    <th style="width:10%;">..</th>
                </tr>
            </thead>
            <tbody [ngStyle]="getStyles(props.body_styles)">
                <tr *ngFor="let order of paged_orders">
                    <td style="text-align: left;">{{order.ORDER_NO}}</td>
                    <td style="text-align: left;">{{order.ORDER_DATE | date: 'd-MMM-y'}}</td>
                    <td style="text-align: right;">{{order.ORDER_AMOUNT | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{order.PAY_STATUS}}</td>
                    <td style="text-align: right;">{{order.ORDER_STATUS}}</td>
                    <td>
                        <button class="btn" (click)="viewOrder(order)" *ngIf="props.view_styles===undefined">
                            <span class="fa fa-eye text-primary" title="View"></span>
                        </button>
                        <button class="btn" (click)="viewOrder(order)" *ngIf="props.view_styles!==undefined"
                        [style.color]="props['view_styles']['color']"
                        [style.backgroundColor]="props['view_styles']['background-color']">
                            <span class="fa fa-eye" 
                            [style.color]="props['view_styles']['color']" title="View Order"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot [ngStyle]="getStyles(props.footer_styles)">
                <tr>
                    <th colspan="6" style="text-align: right;" *ngIf="orders.length>10">
                        <div style="display:flex;" [ngStyle]="getStyles(props.paging_styles)">
                            <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" 
                                [totalItems]="orders.length" [itemsPerPage]="10"
                                (pageChanged)="pageChanged($event)">
                            </pagination>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
    </div> 
</div>