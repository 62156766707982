import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { PageFunctions } from '../../../../shared/directives/PageFunctions';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { BookingService } from '../../../../services';
import { EnvService } from '../../../../../environments/env.service';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
	selector: 'app-bookingcalender',
	templateUrl: './bookingcalender.component.html',
	styleUrls: ['./bookingcalender.component.scss']
})
export class BookingCalenderComponent implements OnInit {
	@ViewChild('fullcalendar') calendar: FullCalendarComponent;
	@Input() content: any;
	@Input() row: any;

	WebsiteId: string = "";
	categories: any = [];
	services: any = [];
	window_width: any = 0;
	calendarPlugins = [dayGridPlugin];
	selectedCategory = "";
	selectedperiod = "";
	selectedMonth: number = 1;
	selectedYear: number = 2022;

	headerOption: any = {left:'',center:'',right:''};

	constructor(public router: Router, public pageFunctions:PageFunctions, public environment: EnvService, 
		public bookingService:BookingService) {
		this.window_width = window.innerWidth;
		this.WebsiteId = environment.websiteId;
	}

	ngOnInit(): void {
		var date = new Date();
		this.pageFunctions.setRowAutoHeight(this.row);
		this.setDefaultValue();
		this.getBookingCategories();
		this.setSelectedPeriod(date);
		this.updateBookingCalender();
	}

	setDefaultValue(){
		if (this.content.styles === undefined){
			this.content['styles'] = {
				"background-color": "transparent",
				"margin-right": 0,
				"margin-left": 0,
				"margin-top": 0,
				"margin-bottom": 0,	
				"style_type": "styles"
			};
		}
		if (this.content.category_styles === undefined){
			this.content['category_styles'] = {
				"background-color": "transparent",
				"padding-right": 0,
				"padding-left": 0,
				"padding-top": 0,
				"padding-bottom": 0,
				'font-family': 'Roboto',
				"font-size": "16px",
				"font-weight": "normal",
				"font-style": "normal",
				"text-align":"left",
				"margin-right": 0,
				"margin-left": 0,
				"margin-top": 0,
				"margin-bottom": 0
			};
		}
		if (this.content.navig_styles === undefined){
			this.content['navig_styles'] = {
				"background-color": "#000",
				"border-color": "#000",
				"color": "#fff"
			};
		}		
		if (this.content.month_styles === undefined){
			this.content['month_styles'] = {
			  "color": "#fff",
			  'font-family': 'Roboto',
			  "font-size": "24px",
			  "font-weight": "normal",
			  "font-style": "normal"
			};
		}
		if (this.content.header_styles === undefined){
			this.content['header_styles'] = {
				"border-color": "#fff",
				"background-color": "#000",
				"color": "#fff",
				"padding-right": 0,
				"padding-left": 0,
				"padding-top": 0,
				"padding-bottom": 0,
				'font-family': 'Roboto',
				"font-size": "20px",
				"font-weight": "normal",
				"font-style": "normal"
			};
		}
		if (this.content.body_styles === undefined){
			this.content['body_styles'] = {
				"border-color": "#fff",
				"background-color": "transparent",
				"color": "#000",
				'font-family': 'Roboto',
				"font-size": "20px",
				"font-weight": "normal",
				"font-style": "normal"
			};
		}

		if (this.content.event_styles === undefined){
			this.content['event_styles'] = {
				'font-family': 'Roboto',
				"font-size": "16px",
				"font-weight": "normal",
				"font-style": "normal"
			};
		}  
	}

	getBookingCategories(){
		this.bookingService.getCategories(this.WebsiteId).subscribe(data => {
			if (data.RESULT_CODE===1){
				this.categories = data.DATA;
			}
		});
	}

	changeCategory(category){
		this.selectedCategory = category;
		this.updateBookingCalender();
	}

	updateBookingCalender() {
		this.content["items"] = [];
		this.bookingService.getBookingMonthlyCalender(this.WebsiteId, this.selectedCategory, this.selectedMonth, this.selectedYear).subscribe(data => {
			this.content["items"] = [];
			if (data.RESULT_CODE === 1) {
				this.content["items"] = data.DATA;
			}
		});
	}

	getNextMonth(){
		let calendarApi = this.calendar.getApi();
		calendarApi.next();
		this.setSelectedPeriod(calendarApi.getDate());
		this.updateBookingCalender();
	}

	getPrevMonth(){
		let calendarApi = this.calendar.getApi();
		calendarApi.prev();
		this.setSelectedPeriod(calendarApi.getDate());
		this.updateBookingCalender();
	}

	setSelectedPeriod(obj){
		var selectedMonth = obj.toLocaleString('default', { month: 'long' });
		var selectedYear = obj.getFullYear();
		this.selectedperiod = selectedMonth + " " + selectedYear;
		this.selectedMonth = obj.getMonth() + 1;
		this.selectedYear = obj.getFullYear();
	}

	eventClick($event){
		var info = $event.event.extendedProps;
		var url = this.bookingService.AppBookingData.ProductPage;
		this.router.navigate([url, { sid: info.SERVICE_ID, calid: info.ID }]);
	}

	getCategoryStyles(){
		var style = {};
		var obj = this.content.category_styles;

		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			style["background-color"] = obj['background-color'];
		}

		if (obj['margin-top'] !== null && obj['margin-top'] !== undefined ) {
			style["margin-top"] = obj['margin-top'] + "px";
		}

		if (obj['margin-left'] !== null && obj['margin-left'] !== undefined ) {
			style["margin-left"] = obj['margin-left'] + "px";
		}

		if (obj['margin-right'] !== null && obj['margin-right'] !== undefined ) {
			style["margin-right"] = obj['margin-right'] + "px";
		}

		if (obj['margin-bottom'] !== null && obj['margin-bottom'] !== undefined ) {
			style["margin-bottom"] = obj['margin-bottom'] + "px";
		}

		return style;
	}

	getMonthStyles(){
		var style = {};
		var obj = this.content.month_styles;
		
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined ) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined ) {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined ) {
			style["font-style"] = obj['font-style'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined ) {
			style["color"] = obj['color'];
		}
		
		return style;
	}

	getCalenderStyles(){
		var styles = {};
		var obj = this.content.styles;

		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			styles["font-family"] = obj['font-family'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			styles["background-color"] = obj['background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined ) {
			styles["color"] = obj['color'];
		}

		//this.setHeaderStyles(styles);
		//this.setBodyStyles(styles);

		return styles;
	}

	setHeaderStyles(styles){
		styles['--header-border-color'] = this.content.header_styles['border-color'];
		styles['--header-bg-color'] = this.content.header_styles['background-color'];
		styles['--header-color'] = this.content.header_styles['color'];
		styles['--header-font-family'] = this.content.header_styles['font-family'];
		styles['--header-font-size'] = this.content.header_styles['font-size'];
		styles['--header-font-weight'] = this.content.header_styles['font-weight'];
		styles['--header-font-style'] = this.content.header_styles['font-style'];
	}

	setBodyStyles(styles){
		styles['--body-border-color'] = this.content.body_styles['border-color'];
		styles['--body-bg-color'] = this.content.body_styles['background-color'];
		styles['--body-color'] = this.content.body_styles['color'];
		styles['--body-font-family'] = this.content.body_styles['font-family'];
		styles['--body-font-size'] = this.content.body_styles['font-size'];
		styles['--body-font-weight'] = this.content.body_styles['font-weight'];
		styles['--body-font-style'] = this.content.body_styles['font-style'];
		styles['--category-color'] = this.content.category_styles['color'];
	}

}
