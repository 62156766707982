<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="ti-menu"></i>
        </a>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">

    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <i-feather name="Users" class="Users text-dark"></i-feather> -->
            <i class="fa fa-user-circle fa-2x" style="margin-top:15px;" [ngClass]="{'text-white':(options['navbarbg']!=='skin6'),
            'text-dark':(options['navbarbg']==='skin6')}"></i>
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <a class="dropdown-item" href="javascript:void(0)" (click)="changePassword()">
                <i class="ti-user m-r-5 m-l-5"></i> Change Password</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="toggleSettings.emit()">
                <i class="fa fa-cog m-r-5 m-l-5"></i> Settings</a>
            <div class="dropdown-divider"></div>
            <ng-container *ngIf="isAdmin">
                <a class="dropdown-item" href="javascript:void(0)" (click)="openUsers()">
                    <i class="fa fa-user m-r-5 m-l-5"></i> Users</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openRoles()">
                    <i class="fa fa-users m-r-5 m-l-5"></i> Roles</a>
                <div class="dropdown-divider"></div>
            </ng-container>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>