import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { BookingService } from '../../../../services/booking.service';
import { PageFunctions } from '../../../directives/PageFunctions';

@Component({
  selector: 'app-bookingsearchbutton',
  templateUrl: './bookingsearchbutton.component.html',
  styleUrls: ['./bookingsearchbutton.component.scss']
})
export class BookingSearchbuttonComponent implements OnInit {
  @Input() content: any;
  searchtext: string = '';

  constructor(public pageFunctions: PageFunctions, public router: Router, public dataService: BookingService) { }

  ngOnInit(): void {
    this.searchtext = this.dataService.AppBookingData.searchText;

    if (this.content.styles.position === undefined){
      this.content['styles']['position'] = "absolute";
    }
    if (this.content.text_styles === undefined){
			this.content['text_styles'] = {
        "placeholder": "Search Service",
			  "background-color": "#fff",
			  "color": "#000",
			  "border-style": "solid",
			  "border-width": 1,
			  "border-color": "gray",
			  "border-radius": 0,
			  'font-family': 'Roboto',
			  "font-size": "16px",
			  "font-weight": "normal",
			  "font-style": "normal",
			  "text-decoration": ""
			}
		}
    if (this.content.button_styles === undefined){
			this.content['button_styles'] = {
        "background-color": "#fff",
        "color": "#000",
			}
		}
  }

  keyDown($event){
    if ($event.keyCode === 13){
        $event.preventDefault();
        this.searchServices();
    }
  }

  searchServices(){
    if (this.searchtext !== '' && this.searchtext !== null){
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.dataService.AppBookingData.searchText = this.searchtext;
      this.router.navigate([this.dataService.AppBookingData.SearchProductsPage]);
    }
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.dataService.AppBookingData.searchText = "";
      this.router.navigate([this.dataService.AppBookingData.SearchProductsPage]);
    }
    // else{
    //   this.dataService.AppBookingData.searchText = "";
    // }
  }

  getStyles(obj) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setBorder(style,obj,true);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

    if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
      style["width"] = obj['width'] + "%";
    }

    if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== ""){
      style['margin-left'] = (parseInt(obj['margin-left'].replace('px',''))) + "px";
    }
   
    if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== ""){
     style['margin-top'] = (parseInt(obj['margin-top'].replace('px',''))) + "px";
    }
    
    return style;
  }
}
