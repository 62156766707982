<ng-container *ngIf="!showDeliveryPage">
    <div class="w-100 mt-2 mb-1" [ngStyle]="getStyles(props.styles)">
        Checkout
    </div>
    <div class="mt-2 mb-2" *ngIf="cartItems.length === 0">No items added to cart</div>
    <div class="table-responsive mb-2" *ngIf="cartItems.length > 0" [ngStyle]="getStyles(props.table_styles)">
        <table class="table table-bordered" style="min-width:550px;overflow-x:scroll;">
            <thead [ngStyle]="getStyles(props.header_styles)">
                <tr>
                    <th style="width:20%!important;"></th>
                    <th style="width:40%!important;">Product Name</th>
                    <th style="width:12%!important;">Qty</th>
                    <th style="width:12%!important;">Price</th>
                    <th style="width:12%!important;">Amount</th>
                    <th style="width:4%!important;">..</th>
                </tr>
            </thead>
            <tbody [ngStyle]="getStyles(props.body_styles)">
                <tr *ngFor="let item of cartItems">
                    <td><img [src]="item.IMAGE_URL" height="100px" 
                        style="display: block; width: 100%;object-fit: contain;"/></td>
                    <td style="text-align: left;">
                        {{item.PRODUCT_NAME}} 
                        <div class="w-100" *ngIf="item.COUPONS.length > 0">
                            <a style="cursor:pointer;" (click)="showCoupons(item,false)">Show Coupons</a>
                        </div>
                    </td>
                    <td style="text-align: center;">
                        <input type="number" class="form-control" [(ngModel)]="item.CART_COUNT" 
                        (blur)="onQtyBlur(item)" *ngIf="item.IS_STOCK_AVAILABLE === '1'"/>
                        <span style="color:red;" *ngIf="item.IS_STOCK_AVAILABLE === '0'"> Stock Not Available</span>
                    </td>
                    <td style="text-align: right;">{{item.PRICE | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{item.PRICE * item.CART_COUNT | number:'1.2-2'}}</td>
                    <td><button class="btn" (click)="deleteCartItem(item)">
                        <span class="fa fa-trash-alt text-warning" title="Delete"></span>
                    </button></td>
                </tr>
            </tbody>
            <tfoot [ngStyle]="getStyles(props.footer_styles)">
                <tr>
                    <th colspan="4" style="width:84%;text-align: right;">Total</th>
                    <th style="width:10%;text-align: right;">{{getItemTotal() | number:'1.2-2'}}</th>
                    <th style="width:5%;"></th>
                </tr>
                <tr *ngIf="couponsAvailable===false">
                    <th colspan="4" style="width:84%;text-align: right;">
                        <span style="color:red;float:left;text-align:left;">{{selectedCoupon.CODE !== undefined ? selectedCoupon.CODE + " Applied": ""}}</span>
                        <span style="float:right;text-align:right;">Coupon Discount</span></th>
                    <th style="width:12%;text-align: right;">
                        {{couponDiscount | number:'1.2-2'}}
                    </th>
                    <th style="width:4%;"></th>
                </tr>
                <tr *ngIf="couponsAvailable===true" style="text-decoration:inherit;">
                    <th style="width:20%;">
                        <button class="btn btn-success" *ngIf="props.coupon_styles===undefined"
                        (click)="applyCoupon()">Apply Coupon</button>
                        <button class="btn" *ngIf="props.coupon_styles!==undefined"
                        [style.textDecoration]="props['coupon_styles']['text-decoration']"
                        [style.fontStyle]="props['coupon_styles']['font-style']"
                        [style.fontWeight]="props['coupon_styles']['font-weight']"
                        [style.color]="props['coupon_styles']['color']"
                        [style.backgroundColor]="props['coupon_styles']['background-color']"
                        (click)="applyCoupon()">Apply Coupon</button>
                    </th>
                    <th colspan="3" style="width:64%;text-align: right;text-decoration:inherit;">
                        <span style="color:red;float:left;text-align:left;text-decoration:inherit;">{{selectedCoupon.CODE !== undefined ? selectedCoupon.CODE + " Applied": ""}}</span>
                        <span style="float:right;text-align:right;text-decoration:inherit;">Coupon Discount</span></th>
                    <th style="width:12%;text-align: right;">
                        {{couponDiscount | number:'1.2-2'}}
                    </th>
                    <th style="width:4%;"></th>
                </tr>
                <tr>
                    <th colspan="4" style="width:84%;text-align: right;">Total</th>
                    <th style="width:12%;text-align: right;">{{getTotal() | number:'1.2-2'}}</th>
                    <th style="width:4%;"></th>
                </tr>
            </tfoot>
        </table>
    </div>  
    <div class="w-100 text-center mt-2 mb-5">
        <button class="btn btn-success mr-2" (click)="continueCheckout()"
        *ngIf="props.checkout_styles===undefined && cartItems.length > 0 && getTotal() > 0">Continue Checkout</button>

        <button class="btn mr-2" (click)="continueCheckout()"
        *ngIf="props.checkout_styles!==undefined && cartItems.length > 0 && getTotal() > 0"
        [style.textDecoration]="props['checkout_styles']['text-decoration']"
        [style.fontStyle]="props['checkout_styles']['font-style']"
        [style.fontWeight]="props['checkout_styles']['font-weight']"
        [style.color]="props['checkout_styles']['color']"
        [style.backgroundColor]="props['checkout_styles']['background-color']">Continue Checkout</button>        

        <button class="btn btn-info" (click)="gotoHome()" *ngIf="props.shopping_styles===undefined">Continue Shopping</button>
        <button class="btn btn-info" (click)="gotoHome()" *ngIf="props.shopping_styles!==undefined"
        [style.textDecoration]="props['shopping_styles']['text-decoration']"
        [style.fontStyle]="props['shopping_styles']['font-style']"
        [style.fontWeight]="props['shopping_styles']['font-weight']"
        [style.color]="props['shopping_styles']['color']"
        [style.backgroundColor]="props['shopping_styles']['background-color']">Continue Shopping</button>
    </div>     
</ng-container>
<div class="card card-flat" *ngIf="showDeliveryPage">
    <div class="card-body">
        <p class="text-center py-2">DELIVERY INFORMATION</p>
        <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="deliveryForm" novalidate="" (submit)="submitForm($event, valForm.value)">
            <div class="row pl-2 pr-2">
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">First name</span>
                        </div>
                        <input class="form-control" type="text" name="firstName" placeholder="Enter first name" autocomplete="off" formControlName="firstName" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['firstName'].hasError('required') && (valForm.controls['firstName'].dirty || valForm.controls['firstName'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Last name</span>
                        </div>
                        <input class="form-control" type="text" name="lastName" placeholder="Enter last Name" autocomplete="off" formControlName="lastName" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['lastName'].hasError('required') && (valForm.controls['lastName'].dirty || valForm.controls['lastName'].touched)">This field is required</div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Address 1</span>
                        </div>
                        <input class="form-control" id="exampleInputEmail1" type="text" name="address1" placeholder="Door No, House / Apartment Name, Road / Street," autocomplete="off" formControlName="address1" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['address1'].hasError('required') && (valForm.controls['address1'].dirty || valForm.controls['address1'].touched)">This field is required</div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Address 2</span>
                        </div>
                        <input class="form-control" id="exampleInputEmail1" type="text" name="address2" placeholder="Locality / Area" autocomplete="off" formControlName="address2" required="" />
                    </div> 
                    <div class="text-danger" *ngIf="valForm.controls['address2'].hasError('required') && (valForm.controls['address2'].dirty || valForm.controls['address2'].touched)">This field is required</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">City</span>
                        </div>
                        <input class="form-control" type="text" name="city" placeholder="Enter city" autocomplete="off" formControlName="city" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['city'].hasError('required') && (valForm.controls['city'].dirty || valForm.controls['city'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">State</span>
                        </div>
                        <input class="form-control" type="text" name="state" placeholder="Enter state" autocomplete="off" formControlName="state" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['state'].hasError('required') && (valForm.controls['state'].dirty || valForm.controls['state'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Postal Code</span>
                        </div>
                        <input class="form-control" type="number" name="pincode" placeholder="Enter postal code" autocomplete="off" formControlName="pincode" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['pincode'].hasError('required') && (valForm.controls['pincode'].dirty || valForm.controls['pincode'].touched)">This field is required</div>                                            
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Mobile #</span>
                        </div>
                        <input class="form-control" type="number" name="mobile" placeholder="Enter mobile" autocomplete="off" formControlName="mobile" required="" />
                    </div>
                    <div class="text-danger" *ngIf="valForm.controls['mobile'].hasError('required') && (valForm.controls['mobile'].dirty || valForm.controls['mobile'].touched)">This field is required</div>                                            
                </div>
            </div>
            <div class="row mb-2 pl-2 pr-2" style="justify-content: center;align-items: center;">
                <div class="col-12">
                    <p class="text-center py-2">Payment Methods</p>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_STRIPE_ACTIVE === '1'" (click)="selectedPayment='Stripe'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/stripe.png');"
                    [ngClass]="{'selected': selectedPayment==='Stripe','unselected': selectedPayment!=='Stripe'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='Stripe','unselected-text': selectedPayment!=='Stripe'}">Stripe Payment</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_RAZOR_PAY_ACTIVE === '1'" (click)="selectedPayment='RazorPay'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/razorpay.png');"
                    [ngClass]="{'selected': selectedPayment==='RazorPay','unselected': selectedPayment!=='RazorPay'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='RazorPay','unselected-text': selectedPayment!=='RazorPay'}">RazorPay Payment</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_PAYPAL_ACTIVE === '1'" (click)="selectedPayment='PayPal'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/paypal.png');"
                    [ngClass]="{'selected': selectedPayment==='PayPal','unselected': selectedPayment!=='PayPal'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='PayPal','unselected-text': selectedPayment!=='PayPal'}">PayPal Payment</div>
                </div>
                <!-- <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_MVOLA_ACTIVE === '1'" (click)="selectedPayment='MVola'">
                    <div class="input-group payoption text-center" 
                    style="background-image: url('/assets/img/mvola.png');"
                    [ngClass]="{'selected': selectedPayment==='MVola','unselected': selectedPayment!=='MVola'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='MVola','unselected-text': selectedPayment!=='MVola'}">MVola Payment</div>
                </div> -->
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_MVOLA_ACTIVE === '1'" (click)="selectedPayment='MVola'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/mvola.png')"
                    [ngClass]="{'selected': selectedPayment==='MVola','unselected': selectedPayment!=='MVola'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='MVola','unselected-text': selectedPayment!=='MVola'}">Pay to {{paymentOptions['MVOLA_META'].PHONE_NUMBER}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_AIRTEL_ACTIVE === '1'" (click)="selectedPayment='Airtel'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/airtel.png')"
                    [ngClass]="{'selected': selectedPayment==='Airtel','unselected': selectedPayment!=='Airtel'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='Airtel','unselected-text': selectedPayment!=='Airtel'}">Pay to {{paymentOptions.AIRTEL_ACCOUNT_NUMBER}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 text-center pb-2" *ngIf="paymentOptions.IS_ORANGE_ACTIVE === '1'" (click)="selectedPayment='Orange'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/orange.png')"
                    [ngClass]="{'selected': selectedPayment==='Orange','unselected': selectedPayment!=='Orange'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='Orange','unselected-text': selectedPayment!=='Orange'}">Pay to {{paymentOptions.ORANGE_ACCOUNT_NUMBER}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3 pb-2" *ngIf="paymentOptions.IS_COD_ACTIVE === '1'" (click)="selectedPayment='COD'">
                    <div class="input-group payoption text-center" style="background-image: url('/assets/img/cod.png')"
                    [ngClass]="{'selected': selectedPayment==='COD','unselected': selectedPayment!=='COD'}">
                    </div>
                    <div class="payoption-text" [ngClass]="{'selected-text': selectedPayment==='COD','unselected-text': selectedPayment!=='COD'}">Cash On Delivery</div>
                </div>
            </div>

            <!-- <input type="text" name="mvolaDebitNo" placeholder="Enter MVola Number" autocomplete="off" 
                    (keypress)="keyPressNumbers($event)"
                    style="width:200px;padding: 0.375rem 0.5rem; height: 2.1875rem; font-size: 1rem;
                    font-weight: 300; line-height: 1.528571429; color: #212529; background-color: #f3f2f2;
                    background-clip: padding-box; border: 0px solid gray; border-radius: 5px;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"  
                    formControlName="mvolaDebitNo" *ngIf="selectedPayment==='MVola'"/> -->
            <input type="text" name="referenceNo" placeholder="Enter Reference Number" autocomplete="off" 
                style="width:200px;padding: 0.375rem 0.5rem; height: 2.1875rem; font-size: 1rem;
                font-weight: 300; line-height: 1.528571429; color: #212529; background-color: #f3f2f2;
                background-clip: padding-box; border: 0px solid gray; border-radius: 5px;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"  
                formControlName="referenceNo" 
                *ngIf="selectedPayment!=='COD' && selectedPayment!=='Stripe'" maxlength="20"/>
            <button class="btn btn-success mt-3 mb-3" appNoDblClick [disabled]="processing" (click)="makePayment($event)">Make Payment</button>
        </form>

    </div>
</div>