import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageFunctions } from '../../../shared/directives/PageFunctions';

@Component({
	selector: 'app-internal-button',
	templateUrl: './internalbutton.component.html',
})
export class InternalButtonComponent implements OnInit {
	@Output() click: EventEmitter<any> = new EventEmitter();
	@Output() videoplayclick: EventEmitter<any> = new EventEmitter();
	@Input() content: any;
	@Input() componentClass: String;
	@Input() contentStyles: any;
	constructor(public pageFunctions: PageFunctions) {}

	ngOnInit() {}

	videoPlayClick(content) {
		if (this.videoplayclick) {
			this.videoplayclick.emit(content);
		}
	}
}
