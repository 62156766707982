import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnvService } from '../../environments/env.service';

export class AppBookingData {
    constructor(
        public BOOKING_CART_LIST: any[],
        public WISHLIST: any[],
        public TOTAL_AMOUNT: number,
        public TOTAL_CART_COUNT: number,
        public HomePage: string,
        public LoginPage: string,
        public RegistrationPage: string,
        public ProfilePage: string,
        public ProductsPage: string,
        public SearchProductsPage: string,
        public ProductPage: string,
        public OrdersPage: string,
        public WishlistPage: string,
        public CheckoutPage: string,
        public SuccessPage: string,
        public FailurePage: string,
        public CurrencyCode: string,
        public CurrencySymbol: string,
        public isLoggedin:boolean,
        public userData: any,
        public searchText: string,
        public loginCheckDone: boolean
    ) { }
}

@Injectable({ providedIn: 'root' })
export class BookingService {
    httpOptions : any;
    apiUrl : string;
    data: any = {};
    clientKey: string;

    public AppBookingData = new AppBookingData([],[],0,0,"","","","","","","","","","","","","","",false,{},"",false);

    constructor(public environment:EnvService, private http: HttpClient, public snackBar: MatSnackBar) {
        
        this.httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json'}),withCredentials: true};
        this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        this.httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.httpOptions.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        this.httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

        this.apiUrl = environment.apiUrl;
        this.clientKey = environment.clientKey;

        if (this.isStorageExists() && localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== ""){
            this.AppBookingData.userData = JSON.parse(localStorage.getItem('userData'));
        }
        else{
            this.AppBookingData.userData = [];
        }
    }

    isStorageExists(){
		try{
			if (window.localStorage !== undefined && window.localStorage !== null)
				return true;
		}
		catch(e){
			return false;
		}
	}

    getCustomerProfile():Observable<any> {
        return this.http.get(this.apiUrl + 'customerprofile',this.httpOptions).pipe(map((response: any) => response));
    }

    getCategories(WEBSITE_ID):Observable<any> {
        var params = "?CK=" + this.clientKey + "&WEBSITE_ID=" + WEBSITE_ID;
        return this.http.get(this.apiUrl + 'booking_active_categories' + params,this.httpOptions)
    }

    getService(WEBSITE_ID, id):Observable<any> {
        var params = "?CK=" + this.clientKey + "&WEBSITE_ID=" + WEBSITE_ID + "&ID=" + id;
        return this.http.get(this.apiUrl + 'booking_service' + params,this.httpOptions)
    }

    getServiceDates(WEBSITE_ID, SERVICE_ID, CALENDER_ID):Observable<any> {
        return this.http.post(`${this.apiUrl}booking_service_dates`, { WEBSITE_ID, SERVICE_ID, CALENDER_ID }, 
            this.httpOptions).pipe(map((response: any) => response));
    }

    getServiceReviews(PRODUCT_ID, From, To):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}productreviews`, 
        { PRODUCT_ID, From, To }, this.httpOptions).pipe(map((response: any) => response));
    }

    getSearchServices(WEBSITE_ID, SEARCH_TEXT, CATEGORIES):Observable<any> {
        let CK = this.clientKey;
        return this.http.post(`${this.apiUrl}search_services`, { CK, WEBSITE_ID, SEARCH_TEXT, CATEGORIES }, 
            this.httpOptions).pipe(map((response: any) => response));
    }

    getBookingMonthlyCalender(WEBSITE_ID, CATEGORY_ID, MONTH, YEAR):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(this.apiUrl + 'booking_calender', 
        { CK, WEBSITE_ID, CATEGORY_ID, MONTH, YEAR },this.httpOptions)
    }

    getCategoryServices(WEBSITE_ID, categoryId):Observable<any> {
        var params = "?CK=" + this.clientKey + "&WEBSITE_ID=" + WEBSITE_ID + "&CATEGORY_ID=" + categoryId;
        return this.http.get(this.apiUrl + 'category_services_listing' + params,this.httpOptions)
    }

    getCategoryServicesAll(WEBSITE_ID, categoryId):Observable<any> {
        var params = "?CK=" + this.clientKey + "&WEBSITE_ID=" + WEBSITE_ID + "&CATEGORY_ID=" + categoryId;
        return this.http.get(this.apiUrl + 'category_services' + params,this.httpOptions)
    }

    checkBookingAllowed(WEBSITE_ID, SERVICE_ID, VARIANT_ID, ORDER_ID):Observable<any> {
        return this.http.post(this.apiUrl + 'check_booking_allowed', 
        { WEBSITE_ID, SERVICE_ID, VARIANT_ID, ORDER_ID },this.httpOptions)
    }

    setLoginPage(loginPage){
        this.AppBookingData.LoginPage = loginPage;
    }

    setRegistrationPage(registerPage){
        this.AppBookingData.RegistrationPage = registerPage;
    }

    public setDataFromStorage(){
        if (this.AppBookingData.isLoggedin === true){
            this.getCartlist().subscribe(res=>{
                if (res.RESULT_CODE === 1){
                    this.AppBookingData.TOTAL_AMOUNT = 0;
                    this.AppBookingData.TOTAL_CART_COUNT = 0;
                    this.AppBookingData.BOOKING_CART_LIST = res.DATA;
                    this.AppBookingData.BOOKING_CART_LIST.forEach(product=>{
                        this.AppBookingData.TOTAL_AMOUNT = this.AppBookingData.TOTAL_AMOUNT + (product.CART_COUNT * product.AMOUNT);
                        this.AppBookingData.TOTAL_CART_COUNT = this.AppBookingData.TOTAL_CART_COUNT + product.CART_COUNT;
                    });
                }
            });
            this.getWishlist().subscribe(res=>{
                if (res.RESULT_CODE === 1){
                    this.AppBookingData.WISHLIST = res.DATA;
                }
            });
        }
        else if (localStorage.getItem('LOCAL_BOOKING_DATA')){
            let localData = JSON.parse(localStorage.getItem('LOCAL_BOOKING_DATA'));
            if (localData['BOOKING_CART_LIST'] && localData['BOOKING_CART_LIST'].length > 0 && this.AppBookingData.BOOKING_CART_LIST.length === 0){
                this.AppBookingData.BOOKING_CART_LIST = localData['BOOKING_CART_LIST'];
                this.AppBookingData.TOTAL_AMOUNT = localData['TOTAL_AMOUNT'];
                this.AppBookingData.TOTAL_CART_COUNT = localData['TOTAL_CART_COUNT'];
            }
            if (localData['WISHLIST'] && localData['WISHLIST'].length > 0 && this.AppBookingData.WISHLIST.length === 0){
                this.AppBookingData.WISHLIST = localData['WISHLIST'];
            }
        }
    }

    public updateToStorage(){
        var localData = {
            "BOOKING_CART_LIST": this.AppBookingData.BOOKING_CART_LIST,
            "TOTAL_AMOUNT": this.AppBookingData.TOTAL_AMOUNT,
            "TOTAL_CART_COUNT": this.AppBookingData.TOTAL_CART_COUNT
        };

        localStorage.setItem('LOCAL_BOOKING_DATA',JSON.stringify(localData));
    }

    public addToWishlist(product:any){
        if(this.AppBookingData.WISHLIST.filter(item=>item.CALENDER_ID === product.CALENDER_ID).length === 0){ 
            this.AppBookingData.WISHLIST.push(product);
            this.updateCustomerWishlist().subscribe(res=>{});
        }
    }

    public removeFromWishlist(product:any){
        let items = this.AppBookingData.WISHLIST.filter(item=>item.CALENDER_ID !== product.CALENDER_ID);
        this.AppBookingData.WISHLIST = items;
        this.deleteWishlist(product.PRODUCT_ID,product.CALENDER_ID).subscribe(res=>{});
    }

    public addToCart(product:any){
        let message, status;        
       
        this.AppBookingData.TOTAL_AMOUNT = 0;
        this.AppBookingData.TOTAL_CART_COUNT = 0;

        if(this.AppBookingData.BOOKING_CART_LIST.filter(item=>item.ID === product.ID && item.CALENDER_ID === product.CALENDER_ID)[0]){ 
            let item = this.AppBookingData.BOOKING_CART_LIST.filter(item=>item.ID == product.ID && item.CALENDER_ID === product.CALENDER_ID)[0];
            item.ADDITIONAL_SERVICES = product.ADDITIONAL_SERVICES;
            item.CART_COUNT = product.CART_COUNT;  
        }
        else if (this.AppBookingData.BOOKING_CART_LIST.filter(item=>item.ID === product.ID)[0]){
            this.AppBookingData.BOOKING_CART_LIST = this.AppBookingData.BOOKING_CART_LIST.filter(item=>item.ID !== product.ID);
            this.AppBookingData.BOOKING_CART_LIST.push(product);
        }
        else{           
            this.AppBookingData.BOOKING_CART_LIST.push(product);
        }        
        this.AppBookingData.BOOKING_CART_LIST.forEach(product=>{
            this.AppBookingData.TOTAL_AMOUNT = this.AppBookingData.TOTAL_AMOUNT + (product.CART_COUNT * product.AMOUNT);
            this.AppBookingData.TOTAL_CART_COUNT = this.AppBookingData.TOTAL_CART_COUNT + product.CART_COUNT;
        });

        this.updateToStorage();

        if (this.AppBookingData.isLoggedin === true){
            this.updateCustomerCartlist().subscribe(res=>{});
        }

        message = 'The service ' + product["TITLE"] + ' has been added to cart.'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
    }

    public deleteFromCart(product:any){
        let message, status;        
       
        this.AppBookingData.TOTAL_AMOUNT = 0;
        this.AppBookingData.TOTAL_CART_COUNT = 0;

        let cartList = this.AppBookingData.BOOKING_CART_LIST.filter(item=>item.CALENDER_ID !== product.CALENDER_ID);
          
        this.AppBookingData.BOOKING_CART_LIST = cartList;
     
        this.AppBookingData.BOOKING_CART_LIST.forEach(product=>{
            this.AppBookingData.TOTAL_AMOUNT = this.AppBookingData.TOTAL_AMOUNT + (product.CART_COUNT * product.AMOUNT);
            this.AppBookingData.TOTAL_CART_COUNT = this.AppBookingData.TOTAL_CART_COUNT + product.CART_COUNT;
        });

        this.updateToStorage();

        if (this.AppBookingData.isLoggedin === true){
            this.deleteCartlist(product.ID, product.CALENDER_ID).subscribe(res=> {});
        }
        
        message = 'The service ' + product["TITLE"] + ' has been removed from cart.'; 
        status = 'danger';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
    }

    public clearCart(){
        this.AppBookingData.TOTAL_AMOUNT = 0;
        this.AppBookingData.TOTAL_CART_COUNT = 0;
        this.AppBookingData.BOOKING_CART_LIST = [];
        this.updateToStorage();
    }

    getCoupon(id):Observable<any> {
        var params = "?CK=" + this.clientKey + "&ID=" + id;
        return this.http.get(this.apiUrl + 'coupon' + params,this.httpOptions)
    }

    checkoutItems(VARIANTS) {
        return this.http.post(`${this.apiUrl}checkoutproducts`, { VARIANTS }, this.httpOptions).pipe(map((response: any) => response));
    }

    createOrder(firstName:string, lastName: string, mobile: string, email: string, address1: string, 
        address2:string, city: string, state: string, country: string, pincode: string, currency: string,
        debit_number:string, coupon_id:string, item_total:number, coupon_discount:number, order_amount: number,  
        items: any, payment_method: string, success_url: string, failure_url: string) {
        return this.http.post(`${this.apiUrl}createbookingorder`, 
            { firstName, lastName, mobile, email, address1, address2, city, state, country, pincode, currency, 
            debit_number, coupon_id, item_total, coupon_discount, order_amount, items, payment_method, success_url, failure_url}, 
            this.httpOptions).pipe(map((response: any) => response));
    }

    verifyPayment(pg: string,order_id: string,order_no:string,order_value:number,payment_id:string,signature:string,mobile_no:string,email:string,payResponse:string){
        return this.http.post(`${this.apiUrl}verify_payment`, 
        {pg,order_id,order_no,order_value,payment_id,signature,mobile_no,email,payResponse},
        this.httpOptions).pipe(map((response: any) => response));
    }

    cancelOrder(ORDER_ID){
        return this.http.post(`${this.apiUrl}cancelorder`, { ORDER_ID }, this.httpOptions).pipe(map((response: any) => response));
    }

    insertReview(ORDER_ID,PRODUCT_ID,RATING,HEADLINE,REVIEW):Observable<any> {
        return this.http.post(`${this.apiUrl}insertreview`, 
        { ORDER_ID,PRODUCT_ID,RATING,HEADLINE,REVIEW }, this.httpOptions).pipe(map((response: any) => response));
    }

    rescheduleBookingSlot(WEBSITE_ID,ORDER_ID,DETAIL_ID,OLD_SLOT_ID,NEW_SLOT_ID,RESCHEDULE_CHARGE,LATE_BOOKING_CHARGE,AMOUNT,PAYMENT_TYPE,Currency,SUrl,FUrl):Observable<any> {
        return this.http.post(`${this.apiUrl}rescheduleslot`, 
        { WEBSITE_ID,ORDER_ID,DETAIL_ID,OLD_SLOT_ID,NEW_SLOT_ID,RESCHEDULE_CHARGE,LATE_BOOKING_CHARGE,AMOUNT,PAYMENT_TYPE,Currency,SUrl,FUrl }, 
        this.httpOptions).pipe(map((response: any) => response));
    }

    bookAddlServices(WEBSITE_ID,ORDER_ID,DETAIL_ID,TRANSACTION_ID,PRODUCT_ID,VARIANT_ID,ADDITIONAL_SERVICES,AMOUNT,PAYMENT_TYPE,Currency,SUrl,FUrl):Observable<any> {
        return this.http.post(`${this.apiUrl}bookaddlservices`, 
        { WEBSITE_ID,ORDER_ID,DETAIL_ID,TRANSACTION_ID,PRODUCT_ID,VARIANT_ID,ADDITIONAL_SERVICES,AMOUNT,PAYMENT_TYPE,Currency,SUrl,FUrl }, 
        this.httpOptions).pipe(map((response: any) => response));
    }

    getCustomerOrders(ORDER_TYPE):Observable<any> {
        return this.http.get(this.apiUrl + 'customerorders?TYPE='+ORDER_TYPE,this.httpOptions)
    }

    getOrderDetails(orderid):Observable<any> {
        return this.http.get(this.apiUrl + 'bookingorderdetails?ID='+orderid,this.httpOptions)
    }

    getAllAdditionalServices(WEBSITE_ID, SERVICE_NAME, From, To, OrderBy, OrderByDir):Observable<any> {
        return this.http.post(`${this.apiUrl}additional_services`, 
        { WEBSITE_ID, SERVICE_NAME, From, To, OrderBy, OrderByDir }, this.httpOptions).pipe(map((response: any) => response));
    }

    getServiceAdditionalCharges(serviceid):Observable<any> {
        return this.http.get(this.apiUrl + 'booking_service_charges?SERVICE_ID='+serviceid,this.httpOptions)
    }

    getOrderAdditionalServices(orderid):Observable<any> {
        return this.http.get(this.apiUrl + 'bookingadditionalservices?ORDER_ID='+orderid,this.httpOptions)
    }

    getInvoicePDF(orderid):Observable<any> {
        return this.http.get(this.apiUrl + 'invoice?ID='+orderid,this.httpOptions)
    }

    getCartlist():Observable<any> {
        return this.http.get(this.apiUrl + 'bookingcartlist', this.httpOptions)
    }

    updateCustomerCartlist():Observable<any> {
        let localData = JSON.parse(localStorage.getItem('LOCAL_BOOKING_DATA'));
        if (localData && localData['BOOKING_CART_LIST'] !== undefined && localData['BOOKING_CART_LIST'] !== null && localData['BOOKING_CART_LIST'].length > 0){ 
            if (this.AppBookingData.BOOKING_CART_LIST.length === 0){
                this.AppBookingData.BOOKING_CART_LIST = localData['BOOKING_CART_LIST'];
                this.AppBookingData.TOTAL_AMOUNT = localData['TOTAL_AMOUNT'];
                this.AppBookingData.TOTAL_CART_COUNT = localData['TOTAL_CART_COUNT'];
            }
        }
        return this.http.post(`${this.apiUrl}updatebookingcart`, {"items":this.AppBookingData.BOOKING_CART_LIST}, this.httpOptions).pipe(map((response: any) => response));
    }

    deleteCartlist(PRODUCT_ID, CALENDER_ID):Observable<any> {
        return this.http.post(`${this.apiUrl}deletebookingcart`, {"PRODUCT_ID":PRODUCT_ID, "CALENDER_ID":CALENDER_ID}, this.httpOptions).pipe(map((response: any) => response));
    }

    getWishlist():Observable<any> {
        return this.http.get(this.apiUrl + 'wishlist',this.httpOptions)
    }

    updateCustomerWishlist():Observable<any> {
        return this.http.post(`${this.apiUrl}updatewish`, {"items":this.AppBookingData.WISHLIST}, this.httpOptions).pipe(map((response: any) => response));
    }

    deleteWishlist(PRODUCT_ID, CALENDER_ID):Observable<any> {
        return this.http.post(`${this.apiUrl}deletewish`, {"PRODUCT_ID":PRODUCT_ID, "CALENDER_ID":CALENDER_ID}, this.httpOptions).pipe(map((response: any) => response));
    }

    getPaymentOptions():Observable<any> {
        return this.http.get(`${this.apiUrl}getpgoptions`, this.httpOptions)
    }

    getGateways():Observable<any> {
        return this.http.get(`${this.apiUrl}gateways`, this.httpOptions)
    }
}