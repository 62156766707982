<div class="btn-group mb-1 mr-1" dropdown *ngIf="dataService.AppData.isLoggedin">
    <button [ngClass]="{'dropdown-toggle':!content.styles['icon_name']}" type="button" dropdownToggle
        [ngStyle]="getButtonStyle(content.styles, content.btn_styles)">
        <ng-container *ngIf="!content.styles['icon_name']">
            {{content.AccountCaption}}
        </ng-container>
        <em [class]="content.styles['icon_name']"
            [style.Color]="content.styles['icon-color']"
            style="vertical-align:middle;"
            [style.fontSize]="content.styles['icon-size']"
            *ngIf="content.styles['icon_name']"></em>
    </button>
    <div *dropdownMenu class="dropdown-menu" role="menu" [style.backgroundColor]="content.styles.linkbgcolor">
        <a class="dropdown-item" (click)="gotoUrl('orders')"
            [style.color]="content.styles.linkcolor">{{content.OrdersCaption}}</a>
        <a class="dropdown-item" (click)="gotoUrl('wishlist')"
            *ngIf="content.ShowWishlist" [style.color]="content.styles.linkcolor">{{content.WishlistCaption}}</a>
        <a class="dropdown-item" (click)="gotoUrl('profile')"
        *ngIf="content.ShowProfile" [style.color]="content.styles.linkcolor">{{content.ProfileCaption}}</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="logout()"
            [style.color]="content.styles.linkcolor">{{content.LogoutCaption}}</a>
    </div>
</div>
<button class="mb-1 mr-1" type="button" (click)="gotoUrl('login')" 
[ngStyle]="getButtonStyle(content.styles, content.btn_styles)"
*ngIf="!dataService.AppData.isLoggedin">{{content.LoginCaption}}
</button>
