import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxSelectModule } from 'ngx-select-ex';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LottieModule } from 'ngx-lottie';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { PaginationModule, PaginationConfig } from 'ngx-bootstrap/pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SignaturePadModule } from 'angular2-signaturepad';
import { GoogleMapsConfig } from './google-maps-config.interceptor';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { VerticalNavigationComponent } from './shared/vertical-header/vertical-navigation.component';
import { VerticalSidebarComponent } from './shared/vertical-sidebar/vertical-sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { HorizontalNavigationComponent } from './shared/horizontal-header/horizontal-navigation.component';
import { HorizontalSidebarComponent } from './shared/horizontal-sidebar/horizontal-sidebar.component';
import { AttachmentComponent } from './shared/attachment/attachment.component';
import { LookupComponent } from './shared/lookup/lookup.component';
import { TextDialogComponent } from './shared/textdialog/textdialog.component';
import { ListingComponent } from './listing/listing.component';
import { RolemenusComponent } from './roles/rolemenus/rolemenus.component';

import { LoginComponent } from './authentication/login/login.component';
import { StaticpageComponent } from './staticpage/staticpage.component';
import { ProductListingComponent } from './shared/components/listing/listing.component';
import { BookingListingComponent } from './shared/components/booking/bookinglisting/bookinglisting.component';
import { CheckoutComponent } from './shared/components/checkout/checkout.component';
import { BookingCheckoutComponent } from './shared/components/booking/bookingcheckout/bookingcheckout.component';
import { BookingCalenderComponent } from './shared/components/booking/bookingcalender/bookingcalender.component';
import { ProductinfoComponent } from './shared/components/productinfo/productinfo.component';
import { BookingServiceinfoComponent } from './shared/components/booking/bookingserviceinfo/bookingserviceinfo.component';
import { ProductZoomComponent } from './shared/components/product-zoom/product-zoom.component';
import { AllProductsComponent } from './shared/components/all-products/all-products.component';
import { AllServicesComponent } from './shared/components/booking/all-services/all-services.component';
import { CategoryProductsComponent } from './shared/components/categoryproducts/categoryproducts.component';
import { CategoryServicesComponent } from './shared/components/booking/categoryservices/categoryservices.component';
import { BrandProductsComponent } from './shared/components/brandproducts/brandproducts.component';
import { WebloginComponent } from './shared/components/weblogin/weblogin.component';
import { WebregisterComponent } from './shared/components/webregister/webregister.component';
import { WishlistComponent } from './shared/components/wishlist/wishlist.component';
import { CustomerprofileComponent } from './shared/components/customerprofile/customerprofile.component';
import { ChangePasswordComponent } from './shared/components/changepassword/changepassword.component';
import { CustomerordersComponent } from './shared/components/customerorders/customerorders.component';
import { BookingordersComponent } from './shared/components/booking/bookingorders/bookingorders.component';
import { BookingorderComponent } from './shared/components/booking/bookingorder/bookingorder.component';
import { BookingChargesComponent } from './shared/components/booking/bookingcharges/bookingcharges.component';
import { AccountbuttonComponent } from './shared/components/accountbutton/accountbutton.component';
import { InternalButtonComponent } from './shared/components/internalbutton/internalbutton.component';
import { CartbuttonComponent } from './shared/components/cartbutton/cartbutton.component';
import { BookingCartbuttonComponent } from './shared/components/booking/bookingcartbutton/bookingcartbutton.component';
import { SearchbuttonComponent } from './shared/components/searchbutton/searchbutton.component';
import { BookingSearchbuttonComponent } from './shared/components/booking/bookingsearchbutton/bookingsearchbutton.component';
import { BrandfilterComponent } from './shared/components/brandfilter/brandfilter.component';
import { CategoryfilterComponent } from './shared/components/categoryfilter/categoryfilter.component';
import { BookingCategoryfilterComponent } from './shared/components/booking/bookingcategoryfilter/bookingcategoryfilter.component';
import { CouponsComponent } from './shared/components/coupons/coupons.component';
import { SiteFaqComponent } from './shared/components/faq/faq.component';
import { SiteNEWSComponent } from './shared/components/news/news.component';
import { ArticlesComponent } from './shared/components/articles/articles.component';
import { ServiceProductComponent } from './shared/components/service/service.component';
import { CKETextComponent } from './shared/components/cketext/cketext.component';
import { IframeLookupComponent } from './shared/components/iframelookup/iframelookup.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { FeatherModule } from 'angular-feather';
import {
	Camera,
	Heart,
	Github,
	Activity,
	Airplay,
	AlertCircle,
	AlertOctagon,
	AlertTriangle,
	AlignCenter,
	AlignJustify,
	AlignLeft,
	AlignRight,
	Anchor,
	Aperture,
	Archive,
	ArrowDown,
	ArrowDownCircle,
	ArrowDownLeft,
	ArrowDownRight,
	ArrowLeftCircle,
	ArrowLeft,
	ArrowRight,
	ArrowRightCircle,
	ArrowUp,
	ArrowUpCircle,
	ArrowUpLeft,
	ArrowUpRight,
	AtSign,
	Award,
	BarChart2,
	BarChart,
	BatteryCharging,
	Battery,
	BellOff,
	Bell,
	Bluetooth,
	Bold,
	BookOpen,
	Book,
	Bookmark,
	Box,
	Briefcase,
	Calendar,
	CameraOff,
	Cast,
	CheckCircle,
	CheckSquare,
	Check,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	ChevronsDown,
	ChevronsLeft,
	ChevronsRight,
	ChevronsUp,
	Chrome,
	Circle,
	Clipboard,
	Clock,
	CloudDrizzle,
	CloudLightning,
	CloudOff,
	CloudRain,
	Cloud,
	CloudSnow,
	Code,
	Codepen,
	Codesandbox,
	Coffee,
	Columns,
	Command,
	Compass,
	Copy,
	CornerDownLeft,
	CornerDownRight,
	CornerLeftDown,
	CornerLeftUp,
	CornerRightDown,
	CornerRightUp,
	CornerUpLeft,
	CornerUpRight,
	Cpu,
	CreditCard,
	Crop,
	Crosshair,
	Database,
	Delete,
	Disc,
	DollarSign,
	DownloadCloud,
	Download,
	Droplet,
	Edit,
	Edit2,
	Edit3,
	ExternalLink,
	EyeOff,
	Eye,
	Facebook,
	FastForward,
	Feather,
	Figma,
	FileMinus,
	FilePlus,
	FileText,
	File,
	Film,
	Filter,
	Flag,
	Folder,
	FolderMinus,
	FolderPlus,
	Framer,
	Frown,
	Gift,
	GitBranch,
	GitCommit,
	GitMerge,
	GitPullRequest,
	Gitlab,
	Globe,
	Grid,
	HardDrive,
	Hash,
	Headphones,
	HelpCircle,
	Hexagon,
	Home,
	MoreHorizontal,
	Image,
	Inbox,
	Info,
	Instagram,
	Italic,
	Key,
	Layers,
	Layout,
	LifeBuoy,
	Link,
	Link2,
	Linkedin,
	List,
	Loader,
	Lock,
	LogIn,
	LogOut,
	Mail,
	MapPin,
	Map,
	Maximize,
	Maximize2,
	Meh,
	Menu,
	MessageCircle,
	MessageSquare,
	Mic,
	MicOff,
	Minimize,
	Minimize2,
	MinusCircle,
	MinusSquare,
	Minus,
	Monitor,
	Moon,
	MoreVertical,
	MousePointer,
	Move,
	Music,
	Navigation,
	Navigation2,
	Octagon,
	Package,
	Paperclip,
	PauseCircle,
	Pause,
	PenTool,
	Percent,
	PhoneCall,
	PhoneForwarded,
	PhoneIncoming,
	PhoneMissed,
	Phone,
	PhoneOff,
	PhoneOutgoing,
	PieChart,
	Play,
	PlayCircle,
	Plus,
	PlusCircle,
	PlusSquare,
	Pocket,
	Power,
	Printer,
	Radio,
	RefreshCcw,
	RefreshCw,
	Repeat,
	Rewind,
	RotateCcw,
	RotateCw,
	Rss,
	Save,
	Scissors,
	Search,
	Send,
	Server,
	Settings,
	Share,
	Share2,
	Shield,
	ShieldOff,
	ShoppingBag,
	ShoppingCart,
	Shuffle,
	Sidebar,
	SkipBack,
	SkipForward,
	Slack,
	Slash,
	Sliders,
	Smartphone,
	Smile,
	Speaker,
	Square,
	Star,
	StopCircle,
	Sun,
	Sunrise,
	Sunset,
	Tablet,
	Tag,
	Target,
	Terminal,
	Thermometer,
	ThumbsDown,
	ThumbsUp,
	ToggleLeft,
	ToggleRight,
	Tool,
	Trash,
	Trash2,
	Trello,
	TrendingDown,
	TrendingUp,
	Triangle,
	Truck,
	Tv,
	Twitch,
	Twitter,
	Type,
	Umbrella,
	Underline,
	Unlock,
	Upload,
	UploadCloud,
	User,
	UserCheck,
	UserMinus,
	UserPlus,
	UserX,
	Users,
	Video,
	VideoOff,
	Voicemail,
	Volume,
	Volume1,
	Volume2,
	VolumeX,
	Watch,
	Wifi,
	WifiOff,
	Wind,
	XCircle,
	XOctagon,
	XSquare,
	X,
	Youtube,
	Zap,
	ZapOff,
	ZoomIn,
	ZoomOut,
} from 'angular-feather/icons';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { jqxTreeGridModule } from 'jqwidgets-ng/jqxtreegrid';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { CustomerorderComponent } from './shared/components/customerorder/customerorder.component';

import { EnvServiceProvider } from '../environments/env.service.provider';
import player from 'lottie-web';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function playerFactory() {
	return player;
}

jqx.credits = '75CE8878-FCD1-4EC7-9249-BA0F153A5DE8';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelSpeed: 2,
	wheelPropagation: true,
	minScrollbarLength: 20,
};

// Select some icons (use an object, not an array)
const icons = {
	Camera,
	Heart,
	Github,
	Activity,
	Airplay,
	AlertCircle,
	AlertOctagon,
	AlertTriangle,
	AlignCenter,
	AlignJustify,
	AlignLeft,
	AlignRight,
	Anchor,
	Aperture,
	Archive,
	ArrowDown,
	ArrowDownCircle,
	ArrowDownLeft,
	ArrowDownRight,
	ArrowLeftCircle,
	ArrowLeft,
	ArrowRight,
	ArrowRightCircle,
	ArrowUp,
	ArrowUpCircle,
	ArrowUpLeft,
	ArrowUpRight,
	AtSign,
	Award,
	BarChart2,
	BarChart,
	BatteryCharging,
	Battery,
	BellOff,
	Bell,
	Bluetooth,
	Bold,
	BookOpen,
	Book,
	Bookmark,
	Box,
	Briefcase,
	Calendar,
	CameraOff,
	Cast,
	CheckCircle,
	CheckSquare,
	Check,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	ChevronsDown,
	ChevronsLeft,
	ChevronsRight,
	ChevronsUp,
	Chrome,
	Circle,
	Clipboard,
	Clock,
	CloudDrizzle,
	CloudLightning,
	CloudOff,
	CloudRain,
	Cloud,
	CloudSnow,
	Code,
	Codepen,
	Codesandbox,
	Coffee,
	Columns,
	Command,
	Compass,
	Copy,
	CornerDownLeft,
	CornerDownRight,
	CornerLeftDown,
	CornerLeftUp,
	CornerRightDown,
	CornerRightUp,
	CornerUpLeft,
	CornerUpRight,
	Cpu,
	CreditCard,
	Crop,
	Crosshair,
	Database,
	Delete,
	Disc,
	DollarSign,
	DownloadCloud,
	Download,
	Droplet,
	Edit,
	Edit2,
	Edit3,
	ExternalLink,
	EyeOff,
	Eye,
	Facebook,
	FastForward,
	Feather,
	Figma,
	FileMinus,
	FilePlus,
	FileText,
	File,
	Film,
	Filter,
	Flag,
	Folder,
	FolderMinus,
	FolderPlus,
	Framer,
	Frown,
	Gift,
	GitBranch,
	GitCommit,
	GitMerge,
	GitPullRequest,
	Gitlab,
	Globe,
	Grid,
	HardDrive,
	Hash,
	Headphones,
	HelpCircle,
	Hexagon,
	Home,
	MoreHorizontal,
	Image,
	Inbox,
	Info,
	Instagram,
	Italic,
	Key,
	Layers,
	Layout,
	LifeBuoy,
	Link,
	Link2,
	Linkedin,
	List,
	Loader,
	Lock,
	LogIn,
	LogOut,
	Mail,
	MapPin,
	Map,
	Maximize,
	Maximize2,
	Meh,
	Menu,
	MessageCircle,
	MessageSquare,
	Mic,
	MicOff,
	Minimize,
	Minimize2,
	MinusCircle,
	MinusSquare,
	Minus,
	Monitor,
	Moon,
	MoreVertical,
	MousePointer,
	Move,
	Music,
	Navigation,
	Navigation2,
	Octagon,
	Package,
	Paperclip,
	PauseCircle,
	Pause,
	PenTool,
	Percent,
	PhoneCall,
	PhoneForwarded,
	PhoneIncoming,
	PhoneMissed,
	Phone,
	PhoneOff,
	PhoneOutgoing,
	PieChart,
	Play,
	PlayCircle,
	Plus,
	PlusCircle,
	PlusSquare,
	Pocket,
	Power,
	Printer,
	Radio,
	RefreshCcw,
	RefreshCw,
	Repeat,
	Rewind,
	RotateCcw,
	RotateCw,
	Rss,
	Save,
	Scissors,
	Search,
	Send,
	Server,
	Settings,
	Share,
	Share2,
	Shield,
	ShieldOff,
	ShoppingBag,
	ShoppingCart,
	Shuffle,
	Sidebar,
	SkipBack,
	SkipForward,
	Slack,
	Slash,
	Sliders,
	Smartphone,
	Smile,
	Speaker,
	Square,
	Star,
	StopCircle,
	Sun,
	Sunrise,
	Sunset,
	Tablet,
	Tag,
	Target,
	Terminal,
	Thermometer,
	ThumbsDown,
	ThumbsUp,
	ToggleLeft,
	ToggleRight,
	Tool,
	Trash,
	Trash2,
	Trello,
	TrendingDown,
	TrendingUp,
	Triangle,
	Truck,
	Tv,
	Twitch,
	Twitter,
	Type,
	Umbrella,
	Underline,
	Unlock,
	Upload,
	UploadCloud,
	User,
	UserCheck,
	UserMinus,
	UserPlus,
	UserX,
	Users,
	Video,
	VideoOff,
	Voicemail,
	Volume,
	Volume1,
	Volume2,
	VolumeX,
	Watch,
	Wifi,
	WifiOff,
	Wind,
	XCircle,
	XOctagon,
	XSquare,
	X,
	Youtube,
	Zap,
	ZapOff,
	ZoomIn,
	ZoomOut,
};

@NgModule({
	declarations: [
		AppComponent,
		SpinnerComponent,
		FullComponent,
		BlankComponent,
		VerticalNavigationComponent,
		BreadcrumbComponent,
		VerticalSidebarComponent,
		HorizontalNavigationComponent,
		HorizontalSidebarComponent,
		AttachmentComponent,
		LookupComponent,
		TextDialogComponent,
		ListingComponent,
		RolemenusComponent,
		LoginComponent,
		StaticpageComponent,
		ProductListingComponent,
		CheckoutComponent,
		ProductinfoComponent,
		ProductZoomComponent,
		AllProductsComponent,
		CategoryProductsComponent,
		BrandProductsComponent,
		WebloginComponent,
		WebregisterComponent,
		CustomerordersComponent,
		AccountbuttonComponent,
		InternalButtonComponent,
		SearchbuttonComponent,
		BrandfilterComponent,
		CategoryfilterComponent,
		WishlistComponent,
		CustomerprofileComponent,
		ChangePasswordComponent,
		CustomerorderComponent,
		CouponsComponent,
		CartbuttonComponent,
		SiteFaqComponent,
		SiteNEWSComponent,
		ServiceProductComponent,
		ArticlesComponent,
		CKETextComponent,
		BookingListingComponent,
		BookingCheckoutComponent,
		BookingCalenderComponent,
		BookingServiceinfoComponent,
		AllServicesComponent,
		CategoryServicesComponent,
		BookingordersComponent,
		BookingorderComponent,
		BookingChargesComponent,
		BookingCartbuttonComponent,
		BookingSearchbuttonComponent,
		BookingCategoryfilterComponent,
		IframeLookupComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgbModule,
		RouterModule.forRoot(Approutes, { onSameUrlNavigation: 'reload' }),
		PerfectScrollbarModule,
		HttpClientModule,
		FeatherModule,
		FeatherModule.pick(icons),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		jqxGridModule,
		NgxChartsModule,
		jqxTreeGridModule,
		ToastrModule.forRoot({
			timeOut: 2000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}),
		ToastContainerModule,
		NgxSelectModule,
		MatDialogModule,
		MatSnackBarModule,
		SignaturePadModule,
		CarouselModule.forRoot(),
		CollapseModule.forRoot(),
		ButtonsModule.forRoot(),
		BsDropdownModule.forRoot(),
		AgmCoreModule.forRoot({ apiKey: '' }),
		AgmOverlays,
		PaginationModule.forRoot(),
		AccordionModule.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		LottieModule.forRoot({ player: playerFactory }),
		FullCalendarModule,
	],
	exports: [CarouselModule, ButtonsModule, BsDropdownModule, CollapseModule, PaginationModule, AccordionModule],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{ provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
		// {
		//   provide: PERFECT_SCROLLBAR_CONFIG,
		//   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		// },
		EnvServiceProvider,
		CookieService,
		MatDialog,
		MatSnackBarModule,
		BsDropdownConfig,
		PaginationConfig,
		DatePipe
	],
	entryComponents: [LookupComponent, AttachmentComponent, TextDialogComponent, CustomerorderComponent, BookingorderComponent, BookingChargesComponent, CouponsComponent, IframeLookupComponent],
	bootstrap: [AppComponent],
})
export class AppModule {}
