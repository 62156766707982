import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService, EcommerceService, BookingService } from '../../../services';
import { PageFunctions } from '../../../shared/directives/PageFunctions';

@Component({
  selector: 'app-accountbutton',
  templateUrl: './accountbutton.component.html',
  styleUrls: ['./accountbutton.component.scss']
})
export class AccountbuttonComponent implements OnInit {
  @Input() content: any;
  isLoggedin: boolean = false;

  constructor(public pageFunctions: PageFunctions, public router: Router, public authService:AuthService, 
    public dataService: EcommerceService, public bookingService: BookingService) { 
    this.isLoggedin = this.dataService.AppData.isLoggedin;
  }

  ngOnInit(): void {
    this.setDefaultValue();
  }

  setDefaultValue(){
    if (this.content.btn_styles === undefined){
      this.content['btn_styles']={
        'padding-top':0,
        'padding-bottom':0,
        'padding-left':0,
        'padding-right':0
      }
    }
    if (this.content.styles.position === undefined){
      this.content['styles']['position'] = "absolute";
    }
    if (this.content.styles.buttoncolor === undefined){
      this.content['styles']['buttoncolor'] = "#000";
    }
    if (this.content.styles.buttonbgcolor === undefined){
      this.content['styles']['buttonbgcolor'] = "#fff";
    }
    if (this.content.styles.linkcolor === undefined){
      this.content['styles']['linkcolor'] = "#fff";
    }
    if (this.content.styles.linkbgcolor === undefined){
      this.content['styles']['linkbgcolor'] = "#8bb8f1";
    }
    if (this.content.styles['font-family'] === undefined){
      this.content['styles']['font-family'] = "Roboto";
    }
    if (this.content.styles['font-size'] === undefined){
      this.content['styles']['font-size'] = "16px";
    }
    if (this.content.styles['button-border-color'] === undefined){
      this.content['styles']['button-border-color'] = "transparent";
    }
    if (this.content.AccountCaption===undefined || this.content.AccountCaption===''){
      this.content['AccountCaption']="Account";
    }
    if (this.content.OrdersCaption===undefined || this.content.OrdersCaption===''){
      this.content['OrdersCaption']="Your Orders";
    }
    if (this.content.WishlistCaption===undefined || this.content.WishlistCaption===''){
      this.content['WishlistCaption']="Your Wishlist";
    }
    if (this.content.ProfileCaption===undefined || this.content.ProfileCaption===''){
      this.content['ProfileCaption']="Your Profile";
    }
    if (this.content.LoginCaption===undefined || this.content.LoginCaption===''){
      this.content['LoginCaption']="Login";
    }
    if (this.content.LogoutCaption===undefined || this.content.LogoutCaption===''){
      this.content['LogoutCaption']="Logout";
    }
    if (this.content.ShowWishlist===undefined){
      this.content['ShowWishlist']=true;
    }
    if (this.content.ShowProfile===undefined){
      this.content['ShowProfile']=true;
    }
  }

  gotoUrl(page){
    if (page==='login'){
      this.router.navigate([this.dataService.AppData.LoginPage]);
    }
    else if (page==='orders'){
      this.router.navigate([this.dataService.AppData.OrdersPage]);
    }
    else if (page==='wishlist'){
      this.router.navigate([this.dataService.AppData.WishlistPage]);
    }
    else if (page==='profile'){
      this.router.navigate([this.dataService.AppData.ProfilePage]);
    }
      
  }

  logout(){
    this.authService.customerlogout().subscribe((res: any) => {
      this.authService.clearCustomerSession();
      this.dataService.AppData.isLoggedin = false;
      this.dataService.AppData.userData = {};
      this.dataService.AppData.WISHLIST = [];
      this.bookingService.AppBookingData.isLoggedin = false;
      this.bookingService.AppBookingData.userData = {};
      this.bookingService.AppBookingData.WISHLIST = [];
      this.dataService.clearCart();
      this.bookingService.clearCart();
      
      this.router.navigate([this.dataService.AppData.HomePage]);
    });
  }

  getButtonStyle(obj, btnStyles){
		var style: any = {};
    
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}
    if (obj['buttonbgcolor'] !== null && obj['buttonbgcolor'] !== undefined){
      style["background-color"] = obj['buttonbgcolor'];
    }
    if (obj['buttoncolor'] !== null && obj['buttoncolor'] !== undefined){
      style["color"] = obj['buttoncolor'];
    }
    if (obj['border-style'] !== null && obj['border-style'] !== undefined){
      style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['button-border-color'];
    }
    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }
    this.pageFunctions.setPadding(style,btnStyles);
    return style;
  }

}
