import { Component, Renderer2 } from '@angular/core';
import { DataService } from './services';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	title = 'app';
	fonts: any = [];
	scripts: any = [];

	constructor(private renderer: Renderer2, public dataService: DataService) {
		if (!this.dataService.WebAppData.fontsProcessed) {
			this.generateFontCss();
			this.loadScripts();
		}
	}

	generateFontCss() {
		this.dataService.getFonts().subscribe((data) => {
			this.fonts = data.DATA;
			this.dataService.WebAppData.customFonts = this.fonts;
			this.dataService.WebAppData.fontsProcessed = true;
			if (this.fonts.length > 0) {
				let result = '';
				var myEle = document.getElementById('fontStylesheet');
				if (myEle) {
					document.getElementById('fontStylesheet')?.remove();
				}
				this.fonts.forEach((element) => {
					if (element.FONT_TYPE !== 'Custom') {
						result = result + '@font-face {';
						result = result + "font-family: '" + element.FONT_NAME + "';";
						result = result + "src: url('" + element.FONT_PATH + "') format('" + element.FONT_TYPE + "');";
						result = result + 'font-weight: ' + element.FONT_WEIGHT + ';';
						result = result + 'font-style: ' + element.FONT_STYLE + ';';
						result = result + '}';
					}
				});
				const style = this.renderer.createText(result);
				const styleElement = this.renderer.createElement('style');
				styleElement.id = 'fontStylesheet';
				this.renderer.appendChild(styleElement, style);
				this.renderer.appendChild(document.head, styleElement);
				let i = 0;
				this.fonts.forEach((element) => {
					if (element.FONT_TYPE === 'Custom') {
						var myEle1 = document.getElementById('stylesheet' + i);
						if (myEle1) {
							document.getElementById('stylesheet' + i)?.remove();
						}
						const link = this.renderer.createElement('link');
						this.renderer.setAttribute(link, 'rel', 'stylesheet');
						this.renderer.setAttribute(link, 'id', 'stylesheet' + i);
						this.renderer.setAttribute(link, 'type', 'text/css');
						this.renderer.setAttribute(link, 'href', 'https://fonts.googleapis.com/css2?family=' + element.FONT_NAME + ':ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,700;1,800&subset=latin');
						this.renderer.appendChild(document.head, link);
						i++;
					}
				});
			}
		});
	}

	loadScripts(){
		this.dataService.getScripts().subscribe((data) => {
			this.scripts = data.DATA;
			if (this.scripts.length > 0) {
				this.scripts.forEach((script) => {
					if (script.IS_ACTIVE==="1"){
						var ele = document.getElementById('script' + script.ID);
						if (ele) {
							document.getElementById('script' + script.ID)?.remove();
						}
						let element = this.renderer.createElement('script');
						element.type = 'text/javascript';
						element.id = 'script' + script.ID;
						if (script.TYPE==="INTERNAL")
							element.text = script.CONTENT.replaceAll("<script>","").replaceAll("</script>","");
						else
							element.src = script.SOURCE_URL;
						this.renderer.appendChild(document.body, element);
					}
				});
			}
		});
	}
}
