import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../services/data.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-signpad',
  templateUrl: './signpad.component.html',
  styleUrls: ['./signpad.component.scss']
})
export class SignPadComponent implements OnInit {
  @Input() primaryId: any;
  @Input() oper: any;
  @Input() field: any;
  @Input() pageValues: any;
  @Input() saveTried: any;
  @ViewChild("signatureCanvas") signaturePad: SignaturePad;
  signaturePadOptions: Object = { 
    'minWidth': 1,
    'canvasHeight': 160,
    'canvasWidth': 300
  };
  signatureImg: string;
  showButtons: boolean = true;

  constructor(private dataService:DataService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.showButtons = !(this.field['is_Read_Only'] || (this.field['Disable_On_Edit'] && this.oper==='EDIT'));
  }

  ngAfterViewInit() {
    if (this.signaturePad && this.signaturePadOptions['canvasWidth'] !== 300){
      this.signaturePad.set('width', this.signaturePadOptions['canvasWidth']);
      this.signaturePad.clear();
    }
  }

  ngAfterContentInit() {
    var ele: any = document.getElementsByClassName("signature-container");
    this.signaturePadOptions['canvasWidth'] = parseInt(ele[0].offsetWidth.toString());
  }

  getGuid(){
    return '?ver=' + Guid.create().toString();
  }

  drawComplete() {}

  drawStart() {}

  clearSignature() {
    this.pageValues[this.field.Field_ID] = null;
    setTimeout(() => {
      this.signaturePad.clear();
    }, 1000);
  }

  savePad() {
    if (!this.signaturePad.isEmpty()){
      const base64Data = this.signaturePad.toDataURL();
      this.signatureImg = base64Data;
      this.dataService.uploadDigisign(this.primaryId,this.field.Field_ID,this.signatureImg).subscribe(res=>{
          if (res.RESULT_CODE===1){
            this.pageValues[this.field.Field_ID] = res.LOCATION + this.getGuid();
          }
          else{
            this.toastr.error("Unable to set signature..","Error");
          }
      });
    }
    else{
      this.toastr.info("Signature not available..","Info");
    }
  }
}
