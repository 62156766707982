<div class="row m-2">
    <div class="col-12 mt-2 mb-1">
        <span style="font-size: 1.3125rem;font-weight: bold;">Book Additional Services</span>
        <button type="button" class="btn btn-light float-right mr-1" (click)="close()" aria-label="Close" >
            Close</button>        
    </div>

    <div class="table-responsive mb-2">
        <table id="table-additional-services" class="table table-bordered" style="min-width:500px;overflow-x:scroll;">
            <thead>
                <tr>
                    <th style="width:8%;"></th>
                    <th style="width:32%;">Service Name</th>
                    <th style="width:8%;">Charge</th>
                    <th style="width:8%;">Qty</th>
                    <th style="width:8%;">Fees</th>
                    <th style="width:36%;">Address / Remarks</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let charge of additionalServices">
                    <td>
                        <input type="checkbox" [checked]="charge['Selected']" (change)="selectCharges(charge)">
                    </td>
                    <td>{{charge.SERVICE_NAME}}</td>
                    <td style="text-align: right;">{{charge.SERVICE_CHARGE | number:'1.2-2'}}</td>
                    <td *ngIf="charge['Selected']">
                        <input type="number" [(ngModel)]="charge['QTY']" class="form-control" (blur)="onQtyBlur()"/>
                    </td>
                    <td *ngIf="!charge['Selected']"></td>
                    <td style="text-align: right;">{{charge['AMOUNT'] | number:'1.2-2'}}</td>               
                    <td *ngIf="charge['Selected']">
                        <textarea rows="3" [(ngModel)]="charge['Remarks']" 
                        class="form-control" placeholder="Enter Address / Remarks and Mobile No"></textarea>
                    </td>
                    <td *ngIf="!charge['Selected']"></td>

                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="4" style="text-align: right;">Selected Service Total</th>
                    <th style="text-align: right;">{{totalAmount | number:'1.2-2'}}</th>
                    <th *ngIf="!processing && totalAmount>0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="font-weight: bold;width:100px!important">Pay Option</span>
                            </div>
                            <select class="form-control" [(ngModel)]="selectedPayment">
                                <option value="Stripe" *ngIf="paymentOptions.IS_STRIPE_ACTIVE === '1'">Stripe</option>
                                <option value="PayPal" *ngIf="paymentOptions.IS_PAYPAL_ACTIVE === '1'">PayPal</option>
                                <option value="RazorPay" *ngIf="paymentOptions.IS_RAZOR_PAY_ACTIVE === '1'">RazorPay</option>
                                <option value="MVola" *ngIf="paymentOptions.IS_MVOLA_ACTIVE === '1'">MVola</option>
                                <option value="Airtel" *ngIf="paymentOptions.IS_AIRTEL_ACTIVE === '1'">Airtel</option>
                                <option value="Orange" *ngIf="paymentOptions.IS_ORANGE_ACTIVE === '1'">Orange</option>
                            </select>
                            <div class="input-group-append">
                                <button class="btn btn-info" type="button" style="width:100px!important"
                                    (click)="bookAdditionalCharges($event)">Pay</button>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="processing && totalAmount>0">
                        <span>Processing..</span>
                    </th>
                    <th *ngIf="totalAmount===0"></th>
                </tr>
            </tfoot>
        </table>
    </div>  
</div>