<div class="row">
    <div class="col-md-12">
        <div class="input-group form-group" [ngStyle]="getStyles(content.text_styles)" >
            <input class="form-control" [placeholder]="content['text_styles']['placeholder']" 
            type="text" name="searchtext" [(ngModel)]="searchtext" (keydown)="keyDown($event)"/>
            <div class="input-group-append search-btn-div ml-auto" [ngStyle]="getStyles(content.button_styles)">
                <button class="btn btn-info btn-pill-right" type="button" (click)="searchProducts()">
                    <em class="icon-magnifier"></em>
                </button>
            </div>
        </div>
    </div>
</div>