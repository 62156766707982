import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CMSService } from '../../../services';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
    selector: 'app-cketext',
    templateUrl: './cketext.component.html',
    styleUrls: ['./cketext.component.scss']
})
export class CKETextComponent implements OnInit {
	@Input() content: any;
    @Input() siteId: any;
	@Input() slugURL: any;
	articleId: string = '';
    contentText: string = '';
	comments: any = [];
	commentForm: FormGroup;
	window_width: any = 0;

    constructor(public pageFunctions: PageFunctions, public cmsService:CMSService, 
		fb: FormBuilder, private sanitizer: DomSanitizer, private toastr: ToastrService) {
		this.commentForm = fb.group({
			'FIRST_NAME': [null, Validators.compose([Validators.required])],
			'LAST_NAME': [null, Validators.compose([Validators.required])],
			'EMAIL_ID': [null, Validators.compose([Validators.required])],
			'COMMENT': [null, Validators.compose([Validators.required])]
		});
		this.window_width = window.innerWidth;
	}

    ngOnInit() {
		this.getContent();
	}

	isMobile(){
		return this.window_width < 700;
	}

	getContent(){
		if (this.content.infotype === 'Services' || this.content.infotype === 'Products'){
			this.getPSContent();
		}
		else if (this.content.infotype === 'News'){
			this.getNEWSContent();
		}
		else if (this.content.infotype === 'Blog'){
			this.getBlogContent();
		}
	}

	getPSContent(){
		this.cmsService.getPSContent(this.siteId,this.slugURL, this.content.infotype)
		.subscribe( data => {
			if (data.RESULT_CODE===1){
				this.contentText = data['PUBLISHED_CONTENT'];
			}
		});
	}

	getNEWSContent(){
		this.cmsService.getNEWSContent(this.siteId,this.slugURL)
		.subscribe( data => {
			if (data.RESULT_CODE===1){
				this.contentText = data['PUBLISHED_CONTENT'];
			}
		});
	}

	getBlogContent(){
		this.cmsService.getArticleContent(this.siteId,this.slugURL)
		.subscribe( data => {
			if (data.RESULT_CODE===1){
				this.articleId = data['ID'];
				this.contentText = data['PUBLISHED_CONTENT'];
				this.getBlogComments();
			}
		});
	}

	getBlogComments(){
		this.comments = [];
		this.cmsService.getArticleComments(this.articleId)
		.subscribe( data => {
			if (data.RESULT_CODE===1){
				this.comments = data.DATA;
			}
		});
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.commentForm.controls) {
			this.commentForm.controls[c].markAsTouched();
		}
		if (this.commentForm.valid) {
		  this.cmsService.insertArticleComment(this.articleId, value.FIRST_NAME, value.LAST_NAME, value.EMAIL_ID, value.COMMENT)
		  .subscribe(data => {
			if(data['RESULT_CODE'] === 1){
				this.toastr.success('Your comment is submitted successfully!!','Success');
				this.commentForm.reset();
			}
		  },
		  error => {
			this.toastr.error('Something went wrong.. try after sometime !!','Error');
		  });
		}
	  }

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		if (obj['shadow-color'] !== undefined && obj['shadow-color'] !== 'transparent' && obj['shadow--color'] !== 'rgba(255,255,255,0)' && obj['shadow--color'] !== 'rgba(255, 255, 255, 0)' ) {
			style["box-shadow"] =  obj['h-size'] + 'px ' + obj['v-size'] + 'px '  + obj['blur-radius'] + 'px ' + obj['spread-radius'] + 'px ' + obj['shadow-color'];
			style["-webkit-box-shadow"] =  obj['h-size'] + 'px ' + obj['v-size'] + 'px ' + obj['blur-radius'] + 'px ' + obj['spread-radius'] + 'px ' + obj['shadow-color'];
			style["-moz-box-shadow"] =  obj['h-size'] + 'px ' + obj['v-size'] + 'px ' + obj['blur-radius'] + 'px ' + obj['spread-radius'] + 'px ' + obj['shadow-color'];
		}
		this.pageFunctions.setShadow(style,obj);
 
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			style["background-color"] = obj['background-color'];
		}

		this.pageFunctions.setBorder(style,obj,true);

		this.pageFunctions.serBorderRadius(style, obj);
		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		this.pageFunctions.setMargin(style, obj);

		return style;
	}
}
