<ng-container *ngIf="content.template==='1'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">{{content.categoryName}}</div>
        <div class="col-12">
            <div class="row">
                <div [class]="content.class" style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.product_styles)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <div [ngStyle]="getStyles(content.image_styles)">
                            <img [src]="item.imageUrl" alt="product image"
                            [style.height.px]="content.image_styles.height"
                            style="display: block; width: 100%;object-fit: contain;" />
                        </div>
                        <!-- <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div> -->
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                        *ngIf="item.price !== item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                        </div>
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                        *ngIf="item.price === item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='2'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">{{content.categoryName}}</div>
        <div class="col-12">
            <div class="row">
                <div [class]="content.class" style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div style="cursor:pointer;" [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.image_styles)">
                            <img [src]="item.imageUrl" height="200px" alt="product image"
                            [style.height.px]="content.image_styles.height"
                            style="display: block; width: 100%;object-fit: contain;" />
                        </div>
                        <div [ngStyle]="getStyles(content.product_styles)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <!-- <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div>    -->
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                        *ngIf="item.price !== item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                        </div>
                        <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                        *ngIf="item.price === item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div>                                         
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='3'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">{{content.categoryName}}</div>
        <div [class]="content.class" style="padding:10px 15px 10px 15px!important;"
            *ngFor="let item of content.paged_items; let index=index">
            <div [style.borderWidth.px]="content.styles['border-width']"
                [style.borderStyle]="content.styles['border-style']"
                [style.borderRadius.px]="content.styles['border-radius']"
                [style.backgroundColor]="content.styles['background-color']"
                [style.borderColor]="content.styles['border-color']" 
                [ngStyle]="getStyles(content.styles)"
                (click)="showProduct(item)">
                <div [ngStyle]="getStyles(content.image_styles)">
                    <img [src]="item.imageUrl" alt="product image"
                    [style.height.px]="content.image_styles.height"
                    style="display: block; width: 100%;object-fit: contain;" />
                </div>
                <div [ngStyle]="getStyles(content.product_styles)"
                    style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                    {{item.productName}}
                </div>
                <!-- <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)">
                    Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                </div> -->
                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                *ngIf="item.price !== item.discount_Price">
                    Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                </div>
                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                *ngIf="item.price === item.discount_Price">
                    Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                </div>
            </div>
        </div>
        <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
            <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
            </pagination>
        </div>
        <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
            <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
            </pagination>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='4'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">{{content.categoryName}}</div>
        <div class="col-12">
            <div class="row">
                <div [class]="content.class" style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)"
                        style="display:flex;flex-direction: column;"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.product_styles)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <div [ngStyle]="getStyles(content.image_styles)">
                            <img [src]="item.imageUrl" alt="product image"
                            [style.height.px]="content.image_styles.height"
                            style="display: block; width: 100%;object-fit: contain;" />
                        </div>
                        <!-- <div [ngStyle]="getStyles(content.price_styles)">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div> -->
                        <div [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                        </div>
                        <div [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div>
                        <div style="display:flex;">
                            <button class="btn btn-info mr-2" style="cursor:pointer;"
                            *ngIf="content.show_styles===undefined">Show Item</button>
                            <button class="mr-2" *ngIf="content.show_styles!==undefined"
                            [ngStyle]="getFormButtonStyle(content.show_styles)"
                            [style.textDecoration]="content['show_styles']['text-decoration']"
                            [style.fontStyle]="content['show_styles']['font-style']"
                            [style.fontWeight]="content['show_styles']['font-weight']"
                            [style.color]="content['show_styles']['color']"
                            [style.backgroundColor]="content['show_styles']['background-color']"
                            style="cursor:pointer;">Show Item</button>                            
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='5'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">{{content.categoryName}}</div>
        <div class="col-12">
            <div class="row">
                <div [class]="content.class" style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)"
                        style="display:flex;flex-direction: column;"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.product_styles)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <div [ngStyle]="getStyles(content.image_styles)">
                            <img [src]="item.imageUrl" alt="product image"
                            [style.height.px]="content.image_styles.height"
                            style="display: block; width: 100%;object-fit: contain;" />
                        </div>
                        <!-- <div [ngStyle]="getStyles(content.price_styles)">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div> -->
                        <div [ngStyle]="getStyles(content.price_styles)" 
                        *ngIf="item.price !== item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                        </div>
                        <div [ngStyle]="getStyles(content.price_styles)" 
                        *ngIf="item.price === item.discount_Price">
                            Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                        </div>
                        <div style="display:flex;">
                            <button class="btn btn-info mr-2" style="cursor:pointer;"
                            *ngIf="content.show_styles===undefined">Show Item</button>
                            <button class="mr-2" *ngIf="content.show_styles!==undefined"
                            [ngStyle]="getFormButtonStyle(content.show_styles)"
                            [style.textDecoration]="content['show_styles']['text-decoration']"
                            [style.fontStyle]="content['show_styles']['font-style']"
                            [style.fontWeight]="content['show_styles']['font-weight']"
                            [style.color]="content['show_styles']['color']"
                            [style.backgroundColor]="content['show_styles']['background-color']"
                            style="cursor:pointer;">Show Item</button>                               
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>