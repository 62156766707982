<div id="mainDiv" #mainDiv class="row" [style]="getPageStyles(page.styles)" style="margin:0 !important;">
    <div *ngIf="showOverlay" [ngStyle]="{'margin-top':'auto','margin-bottom':'auto','text-align':'center'}"><img
            src="/assets/images/loading.gif"></div>
    <div class="col-lg-12">
        <div *ngFor="let row of rows; let rowidx = index" [style.zIndex]="100-rowidx"
            [ngClass]="row['styles']['conic_template_id']" [style]="pageFunctions.getRowPosition(row.styles)">
            <div class="row contentrow" id="{{row.id}}" #rowContainerComponent [style.zIndex]="9999-(rowidx*10)"
                [style]="getRowStyles(row, row.styles, rowidx)" style="position: relative;"
                [hidden]="row.styles['hide-row-in-display'] || (window_width < 700 && row.styles['hide-row'] !== undefined && row.styles['hide-row'])">
                <div class="row contentrow m-0" style="width:100%;"
                    [style.justifyContent]="row.styles['justify-content']"
                    [style]="{ 'max-width': row['styles']['row-full-width']===true?'100%':this.page.styles['page-width']+'px'}">
                    <div style="display: flex;position: absolute;width: 100%;height: 100%;">
                        <video [id]="'video_' + row.id" class="video_bg"
                            *ngIf="row['styles']['background-type']!==undefined && row['styles']['background-type']==='video'"
                            [style.opacity]="row.styles['video-opacity'] === 'undefined'?'1':row.styles['video-opacity']"
                            [src]="row['styles']['background']['url']" autoplay loop playsinline
                            onloadedmetadata="this.muted = true"></video>
                        <div class="video_bg" [style]="getRowBackgroundColor(row.styles)"></div>
                    </div>
                    <div class="{{column?.class}} {{column?.hoverGrowClass}} hum-column-hover" id="{{column.id}}"
                        [ngClass]="column['styles'] !== undefined && column['styles']['conic_template_id'] !== undefined ?
                        column['styles']['conic_template_id']: ''" [style]="getColumnStyles(column)"
                        *ngFor="let column of row.columns; let idx = index" style="position: relative;"
                        [style.zIndex]="window_width < 700 ? 30+idx : 30-idx"
                        [hidden]="window_width < 700 && column.styles['hide-column'] !== undefined && column.styles['hide-column'] === true">
                        <div *ngIf="this.designType!=='MOBILE'"
                            style="display: flex;position: absolute;width:100%;height: 100%;z-index: -1;">
                            <video [id]="'video_' + row.id + '_' + column.id"
                                *ngIf="column['styles']['background-type']!==undefined && column['styles']['background-type']==='video'"
                                [style.opacity]="column.styles['video-opacity'] === 'undefined'?'1':column.styles['video-opacity']"
                                class="video_bg_col" [src]="column['styles']['background']['url']" autoplay loop
                                playsinline onloadedmetadata="this.muted = true"></video>
                            <div class="video_bg" [style]="getRowBackgroundColor(column.styles)"></div>
                        </div>
                        <div class="w-100" *ngFor="let content of column.contents">
                            <ng-container *ngIf="content.type==='Button'">
                                <div class="content" id="{{content.id}}" (click)="loadPage(content)" [ngClass]="{'btn-hover':content.styles['is-gradient-animated'],
                                    'humble-hover-dyn':content.styles['hover-available']}"
                                    [ngStyle]="getStylesWithShadow(row,column,content,content.styles,'Button',content.styles)">
                                    <button class="btn" [style.fontSize]="content.styles['font-size']"
                                        [style.paddingTop.px]="content['styles']['isIcon'] ? content['styles']['padding-top'] : ''"
                                        [style.paddingBottom.px]="content['styles']['isIcon'] ? content['styles']['padding-bottom'] : ''"
                                        [style.paddingLeft.px]="content['styles']['isIcon'] ? content['styles']['padding-left'] : ''"
                                        [style.paddingRight.px]="content['styles']['isIcon'] ? content['styles']['padding-right'] : ''"
                                        style="color:inherit;letter-spacing:inherit;font-family:inherit;
                                        text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;">
                                        <em [class]="content.styles['icon_name']" style="vertical-align:middle;"
                                            [style.fontSize]="content.styles['icon-size']"
                                            *ngIf="content.styles['icon-align'] === undefined || content.styles['icon-align'] !== 'right'"></em>
                                        {{content.value}}{{content.styles['show_visit_count'] ? WebsiteVisits : ""}}
                                        <em [class]="content.styles['icon_name'].replace('mr','ml')"
                                            style="vertical-align:middle;"
                                            [style.fontSize]="content.styles['icon-size']"
                                            *ngIf="content.styles['icon-align'] !== undefined && content.styles['icon-align'] === 'right'"></em>
                                    </button>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="content.type==='Map' && dsCount === 0 && reportData[content.id].length > 0">
                                <agm-map *ngIf="designType==='DESKTOP'"
                                    [style.height.px]="rowContainerComponent.offsetHeight"
                                    [latitude]="content.map.latitude" [longitude]="content.map.longitude"
                                    [zoom]="content['map']['zoom']" [mapDraggable]="true" [fitBounds]="true"
                                    [styles]="content['map']['style'] !== undefined ? agmStyles[content['map']['style']] : agmStyles['default']"
                                    [mapTypeId]="content['map']['type'] !== undefined ? content['map']['type'] : 'roadmap'"
                                    [disableDoubleClickZoom]="false"
                                    (mapReady)="onMapReady($event, reportData[content.id], content)">
                                    <ng-container *ngIf="content.map.markertype==='Location'">
                                        <agm-marker *ngFor="let marker of reportData[content.id]"
                                            [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"
                                            [title]="marker.text" [agmFitBounds]="true">
                                            <agm-info-window>
                                                <strong>{{marker.text}}</strong>
                                            </agm-info-window>
                                        </agm-marker>
                                    </ng-container>
                                </agm-map>
                                <agm-map *ngIf="designType!=='DESKTOP'"
                                    [style.height.px]="row.styles.height ==='100%' ? 250 : (row.styles.height > 600 ? 600: row.styles.height)"
                                    [latitude]="content['map']['latitude']" [longitude]="content['map']['longitude']"
                                    [zoom]="content['map']['zoom']" [mapDraggable]="false" [fitBounds]="true"
                                    [styles]="content['map']['style'] !== undefined ? agmStyles[content['map']['style']] : agmStyles['default']"
                                    [mapTypeId]="content['map']['type'] !== undefined ? content['map']['type'] : 'roadmap'"
                                    [disableDoubleClickZoom]="false"
                                    (mapReady)="onMapReady($event, reportData[content.id])">
                                    <ng-container *ngIf="content.map.markertype==='Location'">
                                        <agm-marker *ngFor="let marker of reportData[content.id]"
                                            [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"
                                            [title]="marker.text" [agmFitBounds]="true">
                                            <agm-info-window>
                                                <strong>{{marker.text}}</strong>
                                            </agm-info-window>
                                        </agm-marker>
                                    </ng-container>
                                </agm-map>
                                <div class="controls" *ngIf="content.map.markertype==='Travel'">
                                    <button (click)="reset()">Reset</button>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="content.type==='Charts' && dsCount === 0 && reportData[content.id].length > 0">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,content.type)"
                                    style="position: absolute;">

                                    <ng-container *ngIf="content.chart.chart_type === 'Pie'">
                                        <ngx-charts-pie-chart [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [labels]="content.chart.showLabel" [doughnut]="content.chart.isDoughnut">
                                        </ngx-charts-pie-chart>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'PieGrid'">
                                        <ngx-charts-pie-grid [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme">
                                        </ngx-charts-pie-grid>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'AdvancedPie'">
                                        <ngx-charts-advanced-pie-chart [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient">
                                        </ngx-charts-advanced-pie-chart>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'VerticalBar'">
                                        <ngx-charts-bar-vertical [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-bar-vertical>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'HorizontalBar'">
                                        <ngx-charts-bar-horizontal [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-bar-horizontal>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'HorizontalBar2D'">
                                        <ngx-charts-bar-horizontal-2d [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-bar-horizontal-2d>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'VerticalBar2D'">
                                        <ngx-charts-bar-vertical-2d [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-bar-vertical-2d>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'LineChart'">
                                        <ngx-charts-line-chart [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-line-chart>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'AreaChart'">
                                        <ngx-charts-area-chart [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-area-chart>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'StackedVerticalChart'">
                                        <ngx-charts-bar-vertical-stacked [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-bar-vertical-stacked>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'StackedHorizontalChart'">
                                        <ngx-charts-bar-horizontal-stacked [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient" [legend]="content.chart.showLegend"
                                            [legendTitle]="content.chart.legend_text"
                                            [legendPosition]="content.chart.legend_position"
                                            [xAxis]="content.chart.showXAxis" [yAxis]="content.chart.showYAxis"
                                            [showXAxisLabel]="content.chart.showXAxisLabel"
                                            [showYAxisLabel]="content.chart.showYAxisLabel"
                                            [xAxisLabel]="content.chart.xAxisLabel"
                                            [yAxisLabel]="content.chart.yAxisLabel">
                                        </ngx-charts-bar-horizontal-stacked>
                                    </ng-container>
                                    <ng-container *ngIf="content.chart.chart_type === 'Treemap'">
                                        <ngx-charts-tree-map [results]="reportData[content.id]"
                                            [scheme]="content.chart.chart_color_scheme === null ? 'natural':content.chart.chart_color_scheme"
                                            [gradient]="content.chart.isGradient">
                                        </ngx-charts-tree-map>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <!-- Datafield control -->
                            <ng-container *ngIf="content.type==='DataField' && dsCount === 0">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                    {{reportData[content.id]}}
                                </div>
                            </ng-container>

                            <!-- Datatable control -->
                            <ng-container *ngIf="content.type==='DataTable' && dsCount === 0">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                    <app-dashboard-table [props]="content" [row]="row" [reportData]="reportData">
                                    </app-dashboard-table>
                                </div>
                            </ng-container>

                            <!-- Filter controls -->
                            <ng-container *ngIf="content.type==='Filters' && dsCount === 0">
                                <div [ngStyle]="getStylesType(row,column,content,content.styles,content.type)">
                                    <form [id]="content.id">
                                        <div class="p-1">
                                            <div class="row mr-0 ml-0">
                                                <ng-container *ngFor="let field of formFields">
                                                    <div [id]="field.ID" class="drop-item" [class]="field.class"
                                                        [style.paddingLeft.px]="content['styles']['padding-left']"
                                                        [style.paddingRight.px]="content['styles']['padding-right']"
                                                        [style.paddingTop.px]="content['styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                        <div [id]="field.ID" class="text-left"
                                                            [style.paddingLeft.px]="content['input_styles']['padding-left']"
                                                            [style.paddingRight.px]="content['input_styles']['padding-right']"
                                                            [style.paddingTop.px]="content['input_styles']['padding-top']"
                                                            [style.paddingBottom.px]="content['input_styles']['padding-bottom']">
                                                            <label>{{field['DISPLAY_NAME']}}</label>
                                                        </div>
                                                        <input type="text"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [name]="field['FIELD_NAME']"
                                                            [placeholder]="field['DISPLAY_NAME']"
                                                            [(ngModel)]="filterData[field['FIELD_NAME']]"
                                                            [ngStyle]="getInputStyles(content['input_styles'])"
                                                            *ngIf="(field['DATA_TYPE']==='Text' || field['DATA_TYPE']==='EMAIL')" />
                                                        <input type="number"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [name]="field['FIELD_NAME']"
                                                            [(ngModel)]="filterData[field['FIELD_NAME']]"
                                                            [ngStyle]="getInputStyles(content['input_styles'])"
                                                            *ngIf="field['DATA_TYPE']==='DECIMAL' || field['DATA_TYPE']==='Number'">
                                                        <input type="date"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [name]="field['FIELD_NAME']"
                                                            [placeholder]="field['DISPLAY_NAME']"
                                                            [value]="filterData[field['FIELD_NAME']] | date:'yyyy-MM-dd'"
                                                            (input)="filterData[field['FIELD_NAME']]=parseDate($event.target.value)"
                                                            [ngStyle]="getInputStyles(content['input_styles'])"
                                                            *ngIf="field['DATA_TYPE']==='Date'">
                                                        <div class="d-flex align-items-left"
                                                            *ngIf="field['DATA_TYPE']==='BIT'">
                                                            <div class="">
                                                                <label class="switch switch-lg m-0">
                                                                    <input type="checkbox" disabled="true"
                                                                        [name]="field['FIELD_NAME']"
                                                                        [(ngModel)]="filterData[field['ID']]" />
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <select type="text"
                                                            [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                            [name]="field['FIELD_NAME']"
                                                            [(ngModel)]="filterData[field['FIELD_NAME']]"
                                                            [ngStyle]="getInputStyles(content['input_styles'])"
                                                            *ngIf="field['DATA_TYPE']==='Dropdown'">
                                                            <option value="">All</option>
                                                            <ng-template ngFor let-option
                                                                [ngForOf]="staticValues[field.ID]" let-i="index">
                                                                <option [value]="option.value">{{option.text}}</option>
                                                            </ng-template>
                                                        </select>
                                                    </div>
                                                </ng-container>
                                                <div [class]="content['button_styles'].class !== undefined ? 
                                                    content['button_styles'].class.replace('col-lg','column') :'column-3'"
                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                    <div class="text-left"
                                                        [style.paddingLeft.px]="content['input_styles']['padding-left']"
                                                        [style.paddingRight.px]="content['input_styles']['padding-right']"
                                                        [style.paddingTop.px]="content['input_styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['input_styles']['padding-bottom']">
                                                        <label></label>
                                                    </div>
                                                    <button class="float-left" style="width:100%;"
                                                        [ngStyle]="getButtonStyles(content['button_styles'])"
                                                        [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'].replace('input','button').replace('input','button') : 'button-form-control'"
                                                        (click)="applyFilter()">Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="content.type==='Line'">
                                <div [style.marginLeft]="content['styles']['margin-left']"
                                    [style.marginTop]="content['styles']['margin-top']"
                                    [style.width.px]="content['styles']['width']"
                                    [style.height.px]="content['styles']['height']">

                                    <hr [style.width.px]="content['styles']['width']"
                                        [style.borderWidth.px]="content.styles['border-width']"
                                        [style.borderStyle]="content.styles['border-style']"
                                        [style.borderColor]="content.styles['border-color']">
                                </div>
                            </ng-container>

                            <ng-container *ngIf="content.type==='CLIPATH'">
                                <div [id]="content.id" style="opacity: 0;"
                                    [style.marginLeft]="getLeftMargin(content['styles'],row)"
                                    [style.marginTop]="content['styles']['margin-top']"
                                    [style.width.px]="content['styles']['width']" [style.position]="'absolute'"
                                    [style.height.px]="content['styles']['height']"
                                    [style]="getShadowStyle(content['styles'])" (click)="loadPage(content)">
                                    <div [id]="'animate'+content.id" [style.transform]="getSkewState(content.styles)"
                                        class="w-100 h-100"
                                        [ngStyle]="getStylesType(row,column,content, content.styles,content.type)">
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="content.type==='Text'">
                                <div [id]="content.id + column.id">
                                    <div [id]="content.id"
                                        [ngStyle]="getStylesType(row,column,content,content.styles,'Text')"
                                        style="opacity: 0;padding: 10px;">
                                        <div class="content mobile-text-size"
                                            [ngClass]="{ 'text-max-size': getFontClass(content.styles['font-size']),'text-max-margin': getMarginClass(content.styles['margin-left'])}">
                                            <div
                                                [innerHtml]="content.htmlvalue === undefined ? getCFHTML(content) : content.htmlvalue">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="content.type==='Image'">
                                <div class="content" [id]="content.id"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,'Image')"
                                    [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                    [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                    [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                    [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                    [style.borderImageSource]="'url(' + content['border-image-url'] + ')'"
                                    [style.borderImageSlice]="content.styles['border-width']"
                                    [style.borderImageWidth.px]="content.styles['border-width']"
                                    [style.backgroundColor]="content.styles['image-opacity-color']"
                                    [style.borderStyle]="content.styles['border-style']"
                                    [style.borderColor]="content.styles['border-color']"
                                    [style.borderWidth.px]="(content.styles['border-width']===undefined||content.styles['border-width']===null||content.styles['border-width']==='')? '0' : content.styles['border-width']"
                                    style="opacity:0;" (click)="loadPage(content)">
                                    <img [id]="'image'+content.id" [src]="content.url" alt="image"
                                        (mouseenter)="switchImage(content.id,true,content)"
                                        (mouseleave)="switchImage(content.id,false,content)"
                                        [style.padding.px]="content['styles']['padding']"
                                        [style.objectFit]="content['styles']['image-cover'] ? 'cover':content['styles']['image-contain'] ? 'contain':''"
                                        [style.objectPosition]="content['styles']['image-center'] ? 'center' : ''"
                                        [style.opacity]="content.styles['image-opacity'] === 'undefined'?'1':content.styles['image-opacity']"
                                        [style.borderTopLeftRadius.px]="content.styles['border-top-left-radius'] === undefined ? '0' : content.styles['border-top-left-radius']"
                                        [style.borderTopRightRadius.px]="content.styles['border-top-right-radius'] === undefined ? '0' : content.styles['border-top-right-radius']"
                                        [style.borderBottomLeftRadius.px]="content.styles['border-bottom-left-radius'] === undefined ? '0' : content.styles['border-bottom-left-radius']"
                                        [style.borderBottomRightRadius.px]="content.styles['border-bottom-right-radius'] === undefined ? '0' : content.styles['border-bottom-right-radius']"
                                        [ngStyle]="getImageStyles(content)">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="content.type==='Video'">
                                <div class="content" [id]="content.id"
                                    [ngStyle]="getStylesType(row,column,content,content.styles,'Image')"
                                    [style.borderTopLeftRadius.px]="content['styles']['border-top-left-radius'] === undefined ? '0' : content['styles']['border-top-left-radius']"
                                    [style.borderTopRightRadius.px]="content['styles']['border-top-right-radius'] === undefined ? '0' : content['styles']['border-top-right-radius']"
                                    [style.borderBottomLeftRadius.px]="content['styles']['border-bottom-left-radius'] === undefined ? '0' : content['styles']['border-bottom-left-radius']"
                                    [style.borderBottomRightRadius.px]="content['styles']['border-bottom-right-radius'] === undefined ? '0' : content['styles']['border-bottom-right-radius']"
                                    [style.borderStyle]="content.styles['border-style']"
                                    [style.borderColor]="content.styles['border-color']"
                                    [style.backgroundColor]="content.styles['video-opacity-color']"
                                    [style.borderWidth.px]="(content.styles['border-width']===null || content.styles['border-width']==='')?0:content.styles['border-width']"
                                    style="opacity:0;">
                                    <video [src]="content.url" [style.height.%]="100" [style.width.%]="100"
                                        [style.objectFit]="'cover'"
                                        [style.opacity]="content.styles['video-opacity'] === 'undefined'?'1':content.styles['video-opacity']"
                                        [ngStyle]="getShadowStyle(content)" controls disablePictureInPicture
                                        controlsList="nodownload"></video>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>