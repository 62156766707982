import { Component, OnInit, Inject } from '@angular/core';
import { BookingService } from '../../../../services';
import { EnvService } from '../../../../../environments/env.service';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { ExternalLibraryService } from '../../../directives/util';

declare var Razorpay: any;
//declare var bolt: any;

@Component({
  selector: 'app-bookingcharges',
  templateUrl: './bookingcharges.component.html',
  styleUrls: ['./bookingcharges.component.scss']
})
export class BookingChargesComponent implements OnInit {
  additionalServices: any = [];
  paymentOptions: any = [];
  totalAmount: number = 0;
  serviceId: string = "";
  websiteId: string = "";
  orderId: string = "";
  detailId: string = "";
  variantId: string = "";
  selectedPayment: string = "";
  paymentResponse: any;
  website_logo: string = "";
  gateways: any = [];  
  userData: any = {};
  processing: boolean = false;

  constructor(public dataService: BookingService, public environment:EnvService, 
    private toastr: ToastrService, public extService: ExternalLibraryService,
    public dialogRef: MatDialogRef<BookingChargesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.websiteId = data['WEBSITE_ID'];
      this.orderId = data['ORDER_ID'];
      this.detailId = data['DETAIL_ID'];
      this.variantId = data['VARIANT_ID'];
      this.serviceId = data['SERVICE_ID'];

      this.getGateways();
      this.getUserInfo();
      this.getAdditionalServices();
      this.getPaymentOptions();
  }

  getUserInfo(){
    this.dataService.getCustomerProfile().subscribe(res=>{
      if (res.RESULT_CODE===1){
        delete res.RESULT_CODE;
        this.userData = res;  
      }
    });
  }

  getGateways(){
    this.dataService.getGateways().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.gateways = res['PG'];
      }
    });
  }

  getPaymentOptions(){
    this.dataService.getPaymentOptions().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.paymentOptions = res;
        var country = this.gateways.filter(pg=>pg.CODE===res['COUNTRY']);
        if (country.length > 0){
          if (!country[0]['PG'].includes("Stripe")){
            this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("RazorPay")){
            this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("PayPal")){
            this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("MVola")){
            this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Airtel")){
            this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Orange")){
            this.paymentOptions['IS_ORANGE_ACTIVE']='0';
          }
        }
        else{
          this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          this.paymentOptions['IS_ORANGE_ACTIVE']='0';
        }         
      }
    });
  }

  getAdditionalServices(){
    this.dataService.getAllAdditionalServices(this.websiteId, '', 0, 999, 'SLNO', 'ASC').subscribe((data) => {
			if (data.RESULT_CODE === 1) {
				this.additionalServices = data.DATA.filter(charge=>charge.IS_ACTIVE==='1');
        this.additionalServices.forEach(item=>{
          item['Selected']=false;
          item['QTY']=0;
          item['AMOUNT']=0;
          item['Remarks']="";
        });
			}
		});
  }

  selectCharges(charge){
    charge['Selected'] = !charge['Selected'];
    this.getTotal();
  }

  onQtyBlur(){
    this.getTotal();
  }

  getTotal(){
    var total = 0;
    this.additionalServices.forEach(item=>{
      if (item['Selected']===true){
        if (item['QTY']===null||item['QTY']===isNaN||item['QTY']<=0)
          item['QTY']=1;
        item['AMOUNT'] = item['QTY'] * item['SERVICE_CHARGE'];
        total = total + item['AMOUNT'];
      }
      else{
        item['QTY']=0;
        item['AMOUNT']=0;
        item['Remarks']="";
      }
    });
    this.totalAmount = total;
  }

  checkRemarks(){
    var retValue = true;
    this.additionalServices.forEach(item=>{
      if (item['Selected']===true && item['Remarks']===""){
        retValue = false;
      }
    });
    return retValue;
  }

  bookAdditionalCharges($event){
    $event.preventDefault();

    var remarksFilled = this.checkRemarks();

    if (this.totalAmount > 0 && this.selectedPayment!=="" && remarksFilled){
      let transactionId = Guid.create().toString();
      let currency = this.dataService.AppBookingData.CurrencyCode;
      let successUrl = this.dataService.AppBookingData.SuccessPage;
      let failureUrl = this.dataService.AppBookingData.FailurePage;

      this.processing = true;
      this.dataService.bookAddlServices(this.websiteId,this.orderId,this.detailId,transactionId,this.serviceId,this.variantId,this.additionalServices,this.totalAmount,this.selectedPayment,currency,successUrl,failureUrl)
      .subscribe((res1 : any)=>{
        this.processing = false;
        if (res1.RESULT_CODE===1){
          this.website_logo = res1['SITE_LOGO'];
          res1['TRAN_ID']=transactionId;
          res1['ORDER_ID']=this.orderId;
          res1['ORDER_AMT']=this.totalAmount;

          if (this.selectedPayment === 'Stripe'){
            if (res1['checkout_url'] !== undefined && res1['checkout_url'] !== null && res1['checkout_url'] !== ""){
              document.location.href = res1['checkout_url'];
            }
            else{
              if (res1['message'] !== undefined && res1['message'] !== null && res1['message'] !== ""){
                this.toastr.error(res1['message'],'Error');
              }
              else{
                this.toastr.error('Something went wrong.. try after sometime !!','Error');
              }
            }
          }
          else if (this.selectedPayment === 'PayPal'){
            if (res1['checkout_url'] !== undefined && res1['checkout_url'] !== null && res1['checkout_url'] !== ""){
              document.location.href = res1['checkout_url'];
            }
            else{
                this.toastr.error('Something went wrong.. try after sometime !!','Error');
            }
          }
          else if (this.selectedPayment === 'RazorPay'){
            var rzp1 = new Razorpay(this.preparePaymentDetails(res1, this.userData, this.totalAmount));
            rzp1.open(); 
          }    
          // else if (this.selectedPayment === 'PayU'){
          //   this.onPayUMoneyPayment(data, value, orderAmt);
          // }           
          else{
            this.toastr.success("Booking Additional services successfull..","Success");
          }
        }
        else{
          this.toastr.error("Unable to book. Please try again later..",'Error');
        }
      });
    }
    else{
      if (this.totalAmount===0){
        this.toastr.info("Services not selected.",'Cannot Book');
      }
      else if (this.selectedPayment===""){
        this.toastr.info("Pay option not selected.",'Cannot Book');
      }
      else if (!remarksFilled){
        this.toastr.info("Remarks not entered for some selected services.",'Cannot Book');
      }
    }
  }

  preparePaymentDetails(order, userinfo, orderAmt){
    var ref = this;
    return  {
      "key": this.paymentOptions.RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      "amount": orderAmt * 100, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      "name": this.environment.websiteTitle,
      "currency": 'INR',
      "order_id": order.RAZOR_ORDER_ID,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
      "image": this.website_logo,
      "modal": {
        escape: false,
      },
      "handler": function (response){
        ref.handlePayment(response,'RazorPay',userinfo, order);
      },
      "modal.ondismiss": function(){
        ref.dismissedPayment();
      },
      "prefill": {
          "name": userinfo.FIRST_NAME + ' ' + userinfo.LAST_NAME,
          "email": userinfo.EMAIL,
          "contact": userinfo.MOBILE
      },
      "theme": {
          "color": "#F28500"
      }
    };
  }

  handlePayment(response, paymentGateway, userInfo, orderInfo) {
    var paymentId: string = '';
    var respSignature: string = '';
    var respStatus: string = '';

    if (paymentGateway==='RazorPay'){
      paymentId = response.razorpay_payment_id;
      respSignature = response.razorpay_signature;
      respStatus = 'success';
    }
    // else{
    //   paymentGateway = 'PayU';
    //   paymentId = response.payuMoneyId;
    //   respSignature = response.bank_ref_num + '|' + response.hash;
    //   respStatus = response.txnStatus;
    // }
    this.dataService.verifyPayment(paymentGateway, orderInfo.TRAN_ID, 'ADDSERVICE', orderInfo.ORDER_AMT,
      paymentId, respSignature, userInfo.MOBILE, userInfo.EMAIL, JSON.stringify(response)
    ).subscribe(res => {
      if (res['status'].toLowerCase() === 'success' ){
        document.location.href = orderInfo['success_url'];
      }
      else{
        this.toastr.error('Order Payment failed.... cannot process order','Error');
      }
    },
    error => {
      this.paymentResponse = error;
    });
  }

  dismissedPayment(){}

  ngOnInit(): void {
    this.extService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js', false)
    .subscribe();

    //this.extService
    //.lazyLoadLibrary('https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js', true)
    //.subscribe();  
  }

  close() {
    this.dialogRef.close();
  }
}
