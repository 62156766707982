import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
	selector: 'app-full-layout',
	templateUrl: './full.component.html',
	styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
	public config: PerfectScrollbarConfigInterface = {};
	@ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

	constructor(public router: Router, private authService: AuthService, private toastr: ToastrService) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		// this.authService.validateToken().subscribe((res : any)=>{
		//   if(res.code !== 200){
		//       this.toastr.error("Error", "Session expired, please relogin");
		//       this.router.navigate(['login']);
		//   }
		// });
	}

	tabStatus = 'justified';

	public isCollapsed = false;

	public innerWidth: any;
	public defaultSidebar: any;
	public showSettings = false;
	public showMobileMenu = false;
	public expandLogo = false;

	options = {
		theme: 'light', // two possible values: light, dark
		dir: 'ltr', // two possible values: ltr, rtl
		layout: 'vertical', // fixed value. shouldn't be changed.
		sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
		sidebarpos: 'absolute', // two possible values: fixed, absolute
		headerpos: 'absolute', // two possible values: fixed, absolute
		boxed: 'full', // two possible values: full, boxed
		navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
		sidebarbg: 'skin4', // six possible values: skin(1/2/3/4/5/6)
		logobg: 'skin4' // six possible values: skin(1/2/3/4/5/6)
	};

	Logo() {
		this.expandLogo = !this.expandLogo;
	}

	ngOnInit() {
		
		if (this.isStorageExists()){
			if(localStorage.getItem('sidebaroptions') === null){
				localStorage.setItem('sidebaroptions', JSON.stringify(this.options));
			}
			else{
				this.options = JSON.parse(localStorage.getItem('sidebaroptions'));
			}
		}
		else{
			this.toastr.error("Please Enable Storage / Cookie support","Error");
		}
		//this.options.sidebarbg = 'skin4';
		//this.options.logobg = 'skin4';
		//this.options.navbarbg = 'skin6';

		if (this.router.url === '/') {
			this.router.navigate(['/app/index']);
		}
		this.toastr.overlayContainer = this.toastContainer;
		this.defaultSidebar = this.options.sidebartype;
		this.handleSidebar();
	}

	isStorageExists(){
		try{
			if (window.localStorage !== undefined && window.localStorage !== null)
				return true;
		}
		catch(e){
			return false;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: string) {
		this.handleSidebar();
	}

	handleSidebar() {
		
		this.innerWidth = window.innerWidth;
		switch (this.defaultSidebar) {
			case 'full':
			case 'iconbar':
				if (this.innerWidth < 1170) {
					this.options.sidebartype = 'mini-sidebar';
				} else {
					this.options.sidebartype = this.defaultSidebar;
				}
				break;

			case 'overlay':
				if (this.innerWidth < 767) {
					this.options.sidebartype = 'mini-sidebar';
				} else {
					this.options.sidebartype = this.defaultSidebar;
				}
				break;

			default:
		}
	}

	setBGSettings(option, value){
		this.options[option] = value;
		localStorage.setItem('sidebaroptions', JSON.stringify(this.options));
	}

	toggleSettings() {
		this.showSettings = !this.showSettings;
	}

	toggleSidebarType() {
		switch (this.options.sidebartype) {
			case 'full':
			case 'iconbar':
				this.options.sidebartype = 'mini-sidebar';
				break;

			case 'overlay':
				this.showMobileMenu = !this.showMobileMenu;
				break;

			case 'mini-sidebar':
				if (this.defaultSidebar === 'mini-sidebar') {
					this.options.sidebartype = 'full';
				} else {
					this.options.sidebartype = this.defaultSidebar;
				}
				break;

			default:
		}
		
		localStorage.setItem('sidebaroptions', JSON.stringify(this.options));
	}
	handleClick(event: boolean) {
		this.showMobileMenu = event;
	}

}
