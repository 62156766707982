<form [formGroup]="pwdForm" class="form-validate form-horizontal" role="form" name="passwordForm" novalidate="">
    <div class="card card-default">
        <div class="card-header" [ngStyle]="getStyles(props.header_styles)">
            <label class="col-form-label" [style.textDecoration]="props.header_styles['text-decoration']">
                {{props.header_styles.value === undefined || props.header_styles.value ===
                '' ? 'Change Password': props.header_styles.value}}
            </label>
        </div>
        <div class="card-body" [ngStyle]="getStyles(props.styles)">
            <div class="row mb-2">
                <div class="col-6 ">
                    <div class="form-group mb-1">
                        <label class="col-form-label">New Password *</label>
                        <input type="password" name="newPassword" placeholder="Enter new password"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(
                                (pwdForm.controls['newPassword'].hasError('required') && (pwdForm.controls['newPassword'].dirty || pwdForm.controls['newPassword'].touched))
                                ||(pwdForm.controls['newPassword'].hasError('minlength') && (pwdForm.controls['newPassword'].dirty || pwdForm.controls['newPassword'].touched))
                            )}" [style]="getInputStyle(props,props['input_styles'])" minlength="8" maxlength="12"
                            autocomplete="off" formControlName="newPassword" required="" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-1">
                        <label class="col-form-label">Confirm Password *</label>
                        <input type="password" name="confirmPassword" placeholder="Enter confirm password"
                            [class]="props['input_styles']['input-control-class'] !== undefined ? props['input_styles']['input-control-class'] : 'input-form-control'"
                            [ngClass]="{'is-invalid':(
                            (pwdForm.controls['confirmPassword'].hasError('required') && (pwdForm.controls['confirmPassword'].dirty || pwdForm.controls['confirmPassword'].touched)
                            ||(pwdForm.controls['confirmPassword'].hasError('mismatchedPasswords') && (pwdForm.controls['confirmPassword'].dirty || pwdForm.controls['confirmPassword'].touched)))
                            )}" [style]="getInputStyle(props,props['input_styles'])" autocomplete="off" maxlength="12"
                            formControlName="confirmPassword" required="" />
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12" [style.textAlign]="props['btn_styles']['text-align']">
                    <button class="btn btn-sm btn-success mt-3" (click)="submitPwdForm($event)"
                        *ngIf="props['btn_styles']===undefined">Submit</button>
                    <button class="mt-3" (click)="submitPwdForm($event)"
                        [ngStyle]="getFormButtonStyle(props.btn_styles)"
                        [style.textDecoration]="props['btn_styles']['text-decoration']"
                        [style.fontStyle]="props['btn_styles']['font-style']"
                        [style.fontWeight]="props['btn_styles']['font-weight']"
                        [style.color]="props['btn_styles']['color']"
                        [style.backgroundColor]="props['btn_styles']['background-color']"
                        *ngIf="props['btn_styles']!==undefined">{{props.btn_styles.value === undefined ||
                            props.btn_styles.value ===
                        '' ? 'Save': props.btn_styles.value}}</button>
                </div>
            </div>
        </div>
    </div>
</form>