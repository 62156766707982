import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services';
declare var $: any;

@Component({
	selector: 'app-vertical-sidebar',
	templateUrl: './vertical-sidebar.component.html',
})
export class VerticalSidebarComponent {
	showMenu = '';
	showSubMenu = '';
	public sidebarnavItems: any = [];
	path = '';

	@Input() showClass: boolean = false;
	@Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

	handleNotify() {
		this.notify.emit(!this.showClass);
	}

	constructor(private router: Router, private dataServices: DataService) {
		var currUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currUser === null || currUser['ID'] === 'undefined') {
			router.navigate(['/applogin']);
		} else {
			if (dataServices.WebAppData.isLoggedin === undefined || !dataServices.WebAppData.isLoggedin) {
				this.setAppData(currUser);
			} else {
				this.setMenus();
			}
		}
	}

	setAppData(currUser) {
		this.dataServices.WebAppData.isLoggedin = true;
		this.dataServices.WebAppData.userId = currUser['ID'];
		this.dataServices.WebAppData.isAdmin = currUser['IsAdmin'] === 'Yes';
		this.getUserMenus();
	}

	getUserMenus() {
		this.dataServices.userMenus().subscribe((res: any) => {
			if (res.RESULT_CODE === 1) {
				this.dataServices.WebAppData.roleMenus = JSON.parse(res['ROLE_MENUS']);
				this.setMenus();
			} else {
				this.router.navigate(['/applogin']);
			}
		});
	}

	setMenus() {
		this.addMenu(this.dataServices.WebAppData.roleMenus);

		this.addExpandClass(this.path);

		this.sidebarnavItems.filter((m) =>
			m.submenu.filter((s) => {
				if (s.path === this.router.url) {
					this.path = m.title;
				}
			})
		);
		this.addExpandClass(this.path);
	}

	getIconName(icon) {
		var icon_name = '';
		if (icon === 'pie-chart') {
			icon_name = 'fa-pie-chart';
		} else if (icon === 'activity') {
			icon_name = 'fa-bolt';
		} else if (icon === 'layers') {
			icon_name = 'fa-graduation-cap';
		} else if (icon === 'grid') {
			icon_name = 'fa-cog';
		} else if (icon === 'file-text') {
			icon_name = 'fa-file';
		} else if (icon === 'note') {
			icon_name = 'fa-sticky-note';
		} else if (icon === 'Users') {
			icon_name = 'fa-users';
		} else {
			icon_name = 'fa-' + icon;
		}
		return icon_name;
	}

	menuClick(menu) {
		this.router.navigateByUrl(menu.path);
	}

	addMenu(items: Array<any>) {
		this.sidebarnavItems = [];
		items.forEach((item) => {
			this.sidebarnavItems.push({
				id: item.id,
				path: item.path,
				title: item.title,
				icon: item.icon,
				icon_color: item.icon_color,
				color: item.color,
				class: item.submenu !== undefined && item.submenu.length > 0 ? 'has-arrow' : '',
				extralink: item.extralink,
				submenu: item.submenu !== undefined ? this.getSubMenu(item.submenu) : [],
			});
		});
	}

	getSubMenu(items: Array<any>) {
		var subItems: any = [];
		items.forEach((item) => {
			subItems.push({
				id: item.id,
				pageid: item.submenu !== undefined && item.submenu.length > 0 ? '' : item.pageid,
				path: item.path,
				title: item.title,
				icon: item.icon,
				class: item.submenu !== undefined && item.submenu.length > 0 ? 'has-arrow' : '',
				extralink: item.extralink,
				submenu: item.submenu !== undefined ? this.getSubMenu(item.submenu) : [],
			});
		});
		return subItems;
	}

	addExpandClass(element: any) {
		if (element === this.showMenu) {
			this.showMenu = '0';
		} else {
			this.showMenu = element;
		}
	}

	addActiveClass(element: any) {
		if (element === this.showSubMenu) {
			this.showSubMenu = '0';
		} else {
			this.showSubMenu = element;
		}
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}
}
