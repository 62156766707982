import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from '../../environments/env.service';

import { Customer, User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentCustomerSubject: BehaviorSubject<Customer>;
    public currentUser: Observable<User>;
    public currentCustomer: Observable<Customer>;
    httpOptions : any;
    apiUrl : string;
    clientKey: string;
    siteid: string;

    constructor(public environment:EnvService, private cookieService: CookieService, private http: HttpClient, public toastr: ToastrService) {

        if (this.isStorageExists()){
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
            this.currentUser = this.currentUserSubject.asObservable();

            this.currentCustomerSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('userData')));
            this.currentCustomer = this.currentCustomerSubject.asObservable();
        }

        this.httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json'}),withCredentials: true};

        this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        this.httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.httpOptions.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        this.httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

        this.apiUrl = environment.apiUrl; 
        this.clientKey = environment.clientKey;
        this.siteid = environment.websiteId;
    }

    isStorageExists(){
		try{
			if (window.localStorage !== undefined && window.localStorage !== null)
				return true;
		}
		catch(e){
			return false;
		}
	}

    public get currentUserValue(): User {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        return this.currentUserSubject.value;
    }

    public get currentCustomerValue(): Customer {
        this.currentCustomerSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('userData')));
        return this.currentCustomerSubject.value;
    }

    validateToken():Observable<any>{

        return this.http.get(`${this.apiUrl}admin/validatetoken`,this.httpOptions)

    }

    login(username: string, password: string) {
        var clientkey = this.clientKey;
        return this.http.post(`${this.apiUrl}authenticate`, 
            { clientkey, username, password }, this.httpOptions).pipe(map((response: any) => response));
    }

    checkAppSession():Observable<any>{
        return this.http.get(`${this.apiUrl}checkappsession`,this.httpOptions).pipe(map((response: any) => response));
    }

    forgotPasswordUser(email:string) {
        var ck = this.clientKey;
        return this.http.post(`${this.apiUrl}forgotpwd`, { ck, email }, this.httpOptions).pipe(map((response: any) => response));
    }

    updatePasswordUser(OLD_PASSWORD:string, NEW_PASSWORD:string) {
        return this.http.post(`${this.apiUrl}changepwd`, { OLD_PASSWORD, NEW_PASSWORD }, this.httpOptions).pipe(map((response: any) => response));
    }

    logout() {
        // remove user from local storage to log customer out
        return this.http.post(`${this.apiUrl}logout`, 
            { }, this.httpOptions).pipe(map((response: any) => response));
    }

    register(firstName:string, lastName: string, mobile: string, email: string, password: string) {
        var clientkey = this.clientKey;
        var siteid = this.siteid;
        return this.http.post(`${this.apiUrl}register`, 
            { siteid, clientkey, firstName, lastName, mobile, email, password }, this.httpOptions).pipe(map((response: any) => response));
    }

    customerlogin(username: string, password: string) {
        var clientkey = this.clientKey;
        var siteid = this.siteid;
        return this.http.post(`${this.apiUrl}authcustomer`, 
            { siteid, clientkey, username, password }, this.httpOptions).pipe(map((response: any) => response));
    }

    checkSession():Observable<any>{
        return this.http.get(`${this.apiUrl}checksession`,this.httpOptions).pipe(map((response: any) => response));
    }

    updateProfile(firstName:string, lastName: string, mobile: string, email: string, address1: string, 
        address2:string, city: string, state: string, country: string, pincode: string) {
        var clientkey = this.clientKey;
        return this.http.post(`${this.apiUrl}updatecustomer`, 
            { clientkey, firstName, lastName, mobile, email, address1, address2, city, state, country, pincode}, 
            this.httpOptions).pipe(map((response: any) => response));
    }

    forgotPasswordCustomer(email:string) {
        var clientkey = this.clientKey;
        var siteid = this.siteid;
        return this.http.post(`${this.apiUrl}fpcustomer`, { clientkey, siteid, email }, this.httpOptions).pipe(map((response: any) => response));
    }

    updatePasswordCustomer(password:string) {
        return this.http.post(`${this.apiUrl}changecustomerpwd`, { password }, this.httpOptions).pipe(map((response: any) => response));
    }

    customerlogout() {
        // remove user from local storage to log customer out
        return this.http.post(`${this.apiUrl}logoutcustomer`, 
            { }, this.httpOptions).pipe(map((response: any) => response));
    }

    clearCustomerSession(){
        localStorage.removeItem('userData');
        this.currentCustomerSubject.next(null);
    }

    clearLocalSession(){
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}