<div class="row" *ngIf="news.length > 0">
    <div [class]="content.class" *ngFor="let newsinfo of news">
        <div [ngStyle]="getStylesMain(content.slider_styles, content.styles)" 
            style="cursor:pointer;" (click)="loadPage(newsinfo)">
            <div [ngStyle]="getStyles(content.title_styles)"
                style="line-height: 1.5em; overflow: hidden;">
                {{newsinfo.PUBLISHED_TITLE}}
            </div>
            <div [ngStyle]="getStyles(content.date_styles)" style="line-height: 1.5em; overflow: hidden;">
                {{newsinfo.DATE_OF_NEWS}}
            </div>
            <div [ngStyle]="getStyles(content.desc_styles)" style="line-height: 1.5em; overflow: hidden;white-space: pre;">
                {{newsinfo.PUBLISHED_NEWS}}
            </div>
        </div>
    </div>
</div>
