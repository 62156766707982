<div class="container-fluid">

    <div *ngIf="loading" [ngStyle]="{'margin-top':'auto','margin-bottom':'auto','text-align':'center'}"><img
            src="/assets/images/loading.gif"></div>

    <div [width]="'100%'" [height]="'99%'" *ngIf="configReady" class="pt-2">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-6 col-sm-12 align-self-center hdr text-18 text-bold">{{pageName}}</div>
                    <div class="col-md-6 col-sm-12 text-right">
                        <div class="ml-auto">
                            <button [hidden]="pageConfig['NewMode'] === '0'"
                                class="btn btn-info btn-rounded ml-auto mr-1" type="button" appNoDblClick
                                (click)="navigate({ID:'0'})">
                                <em class="fa fa-plus-circle fa-fw mr-1"></em>
                                ADD
                            </button>
                            <div class="btn-group ml-auto mr-1" dropdown>
                                <button id="button-basic" dropdownToggle type="button"
                                    class="btn btn-info btn-rounded dropdown-toggle" aria-controls="dropdown-basic">
                                    EXPORT <span class="caret"></span>
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                    aria-labelledby="button-basic">
                                    <li role="menuitem"><a class="dropdown-item" (click)="export('pdf')">PDF</a></li>
                                    <li role="menuitem"><a class="dropdown-item" (click)="export('xls')">Excel</a></li>
                                    <li role="menuitem"><a class="dropdown-item" (click)="export('csv')">CSV</a></li>
                                </ul>
                            </div>
                            <button [hidden]="!showfilter || searchFields.length === 0"
                                class="btn btn-dark btn-rounded ml-auto mr-1" type="button" appNoDblClick
                                (click)="toggleFilter()">
                                <em class="fa fa-filter fa-fw mr-1"></em>
                                Hide Filter
                            </button>
                            <button [hidden]="showfilter || searchFields.length === 0"
                                class="btn btn-dark btn-rounded ml-auto mr-1" type="button" appNoDblClick
                                (click)="toggleFilter()">
                                <em class="fa fa-filter fa-fw mr-1"></em>
                                Show Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="showfilter && searchFields.length > 0">
                <form>
                    <div class="form-row">
                        <ng-template ngFor let-field [ngForOf]="searchFields" let-i="index">
                            <div style="padding-bottom:5px;" class="col-md-3" *ngIf="field.is_Static_Value === 1 || (field.DataType === 'TINY_STRING' 
                                || field.DataType === 'EMAIL')">
                                <label style="font-size:11px;margin-bottom:0px;width:100%;"
                                    [for]="field.Field_ID">{{field.Display_Name}}</label>

                                <input [id]="field.Field_ID" [name]="field.Field_ID" type="text" class="form-control"
                                    [placeholder]="field.Display_Name" *ngIf="field.is_Static_Value === 0"
                                    [value]="filterValues[field.Field_ID]"
                                    (input)="filterValues[field.Field_ID]=$event.target.value" />

                                <select [id]="field.Field_ID" [name]="field.Field_ID"
                                    *ngIf="field.is_Static_Value === 1" class="form-control" type="text"
                                    [name]="field.Field_ID" [placeholder]="field.Display_Name"
                                    [value]="filterValues[field.Field_ID]"
                                    (input)="filterValues[field.Field_ID]=$event.target.value">
                                    <option value="">All</option>
                                    <ng-template ngFor let-option [ngForOf]="staticValues[field.Field_ID]"
                                        let-i="index">
                                        <option [value]="option.value">{{option.text}}</option>
                                    </ng-template>
                                </select>


                            </div>
                            <div style="padding-bottom:5px;" class="col-md-3" *ngIf="(field.DataType === 'DATE' || field.DataType === 'DATETIME' ||
                                field.DataType === 'INTEGER' || field.DataType === 'DECIMAL' || field.DataType === 'PERCENTAGE')
                                && field.is_Static_Value === 0">
                                <label style="font-size:11px;margin-bottom:0px;width:100%;"
                                    [for]="field.Field_ID">{{field.Display_Name}} Range</label>

                                <input [id]="field.Field_ID+'_From'"
                                    *ngIf="field.DataType === 'DATE' || field.DataType === 'DATETIME'"
                                    class="form-control" type="date" style="float:left;width:50%!important"
                                    [value]="filterValues[field.Field_ID+'_From']"
                                    (input)="filterValues[field.Field_ID+'_From']=$event.target.value" />

                                <input [id]="field.Field_ID+'_To'"
                                    *ngIf="field.DataType === 'DATE' || field.DataType === 'DATETIME'"
                                    class="form-control" type="date" style="float:right;width:50%!important"
                                    [value]="filterValues[field.Field_ID+'_To']"
                                    (input)="filterValues[field.Field_ID+'_To']=$event.target.value" />

                                <input [id]="field.Field_ID+'_From'"
                                    *ngIf="field.DataType !== 'DATE' && field.DataType !== 'DATETIME'"
                                    class="form-control" type="number" style="float:left;width:50%!important"
                                    [value]="filterValues[field.Field_ID+'_From']"
                                    (input)="filterValues[field.Field_ID+'_From']=$event.target.value" />

                                <input [id]="field.Field_ID+'_To'"
                                    *ngIf="field.DataType !== 'DATE' && field.DataType !== 'DATETIME'"
                                    class="form-control" type="number" style="float:right;width:50%!important"
                                    [value]="filterValues[field.Field_ID+'_To']"
                                    (input)="filterValues[field.Field_ID+'_To']=$event.target.value" />
                            </div>
                        </ng-template>
                        <div style="padding-bottom:10px;" class="col-md-3">
                            <label style="font-size:11px;margin-bottom:0px;width:100%;"></label>
                            <button class="btn btn-info btn-rounded ml-auto" type="button" (click)="applyFilter()">
                                <em class="fa fa-filter fa-fw mr-1"></em>Apply Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <jqxGrid #myGrid [width]="'100%'" [height]="'400px'" [source]="dataAdapter" [columns]="columns"
            [pageable]="true" [sortable]="true" [filterable]="true" [columnsresize]="true" [autoheight]="true"
            [showtoolbar]="false" [pagesizeoptions]="[ '10', '25', '50']" [virtualmode]="true" [altRows]="true"
            [rowsheight]="40" [enablebrowserselection]="true" [rendergridrows]="rendergridrows"
            (onPagechanged)="onPageChanged($event)" (onFilter)="Filter($event)"
            (onPagesizechanged)="onPageSizeChanged($event)" (onCellclick)="onCellclick($event)">
        </jqxGrid>
    </div>
</div>