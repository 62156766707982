<div id="overlay" *ngIf="showOverlay">
    <img src="/assets/img/loading_plain.gif">
</div>
<div class="row" [style]="getPageStyles(page.styles)" style="margin:0 !important;">
    <div class="col-lg-12">
        <div class="row contentrow" id="{{filterRow.id}}" *ngIf="filterRow.id !== undefined"
        [style.zIndex] = "9999" [style]="getRowStyles(filterRow.styles)" style="position: relative;" >
            <div class="row contentrow" style="width:100%; justify-content: center;" 
            [style]="{ 'max-width': filterRow['styles']['row-full-width']===true?'100%':'1366px'}">
                <div class="{{column?.class}} {{column?.hoverClass}}" id="{{column.id}}"  [style]="getColumnStyles(column,filterRow.styles)"
                    *ngFor="let column of filterRow.columns; let idx = index" style="position: relative; height:100%!important;" [style.zIndex]="window_width < 800 ? 30+idx : 30-idx" >
                    <div class="w-100" *ngFor="let content of column.contents">
                        <ng-container *ngIf="content.type==='Line'">
                            <hr [style.width.px]="content['styles']['width']" 
                                [style.borderWidth.px]="content.styles['border-width']" 
                                [style.borderStyle]="content.styles['border-style']" 
                                [style.marginLeft]="content['styles']['margin-left']"
                                [style.marginTop]="content['styles']['margin-top']"
                                [style.borderColor]="content.styles['border-color']">
                                
                        </ng-container>
                        <ng-container *ngIf="content.type==='Text'">
                            <div [id]="content.id + column.id" [ngStyle]="getStylesType(column,content.styles,'Text')" style="padding: 10px;">
                                <div class="content mobile-text-size" 
                                [id]="content.id"
                                (mouseenter)="setTextAnimation(content,column)" 
                                [ngClass]="{ 'text-max-size': getFontClass(content.styles['font-size']),'text-max-margin': getMarginClass(content.styles['margin-left'])}"  
                                >
                                    <div [innerHtml]="content.htmlvalue === undefined ? getCFHTML(content) : content.htmlvalue">
                                    </div>   
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="content.type==='Image'">
                            <div class="content" [ngStyle]="getStylesType(column,content.styles,'Image')"
                                [style.borderRadius.px]="content.styles['border-radius']" 
                                [style.borderStyle]="content.styles['border-style']" 
                                [style.borderColor]="content.styles['border-color']"
                                style="z-index: 112 !important;">
                                <img [src]="content.url" [style.padding.px]="content['styles']['padding']"  [ngStyle]="getImageStyles(content)">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="content.type==='Filters'">
                            <div [ngStyle]="getStylesType(content.styles,content.type)">
                                <form [id]="content.id">
                                    <div class="p-1">
                                        <div class="row">
                                            <ng-container *ngFor="let field of formFields">
                                                <div [id]="field.ID" class="drop-item" [class]="field.class+' '+field.class.replace('lg','md')"
                                                    [style.paddingLeft.px]="content['styles']['padding-left']"
                                                    [style.paddingRight.px]="content['styles']['padding-right']"
                                                    [style.paddingTop.px]="content['styles']['padding-top']"
                                                    [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                    <div [id]="field.ID" class="text-left"
                                                        [style.paddingLeft.px]="content['input_styles']['padding-left']"
                                                        [style.paddingRight.px]="content['input_styles']['padding-right']"
                                                        [style.paddingTop.px]="content['input_styles']['padding-top']"
                                                        [style.paddingBottom.px]="content['input_styles']['padding-bottom']">
                                                        <label>{{field['DISPLAY_NAME']}}{{field['Is_Mandatory']?'*':''}}</label>
                                                    </div>
                                                    <input type="text"
                                                        [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                        [name]="field['FIELD_NAME']"
                                                        [placeholder]="field['DISPLAY_NAME']"
                                                        [(ngModel)]="filterData[field['FIELD_NAME']]"
                                                        [ngStyle]="getInputStyles(content['input_styles'])"
                                                        *ngIf="(field['DATA_TYPE']==='Text' || field['DATA_TYPE']==='EMAIL')" />
                                                    <input type="number" 
                                                        [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                        [name]="field['FIELD_NAME']"
                                                        [(ngModel)]="filterData[field['FIELD_NAME']]"
                                                        [ngStyle]="getInputStyles(content['input_styles'])"
                                                        *ngIf="field['DATA_TYPE']==='DECIMAL' || field['DATA_TYPE']==='Number'">
                                                    <input type="date" 
                                                        [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                        [name]="field['FIELD_NAME']"
                                                        [placeholder]="field['DISPLAY_NAME']"
                                                        [value]="filterData[field['FIELD_NAME']] | date:'yyyy-MM-dd'"
                                                        (input)="filterData[field['FIELD_NAME']]=parseDate($event.target.value)"
                                                        [ngStyle]="getInputStyles(content['input_styles'])"
                                                        *ngIf="field['DATA_TYPE']==='Date'">
                                                    <div class="d-flex align-items-left"
                                                        *ngIf="field['DATA_TYPE']==='BIT'">
                                                        <div class="">
                                                            <label class="switch switch-lg m-0">
                                                                <input type="checkbox" disabled="true"
                                                                    [name]="field['FIELD_NAME']"
                                                                    [(ngModel)]="filterData[field['ID']]" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <select type="text" 
                                                        [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'] : 'input-form-control'"
                                                        [name]="field['FIELD_NAME']"
                                                        [(ngModel)]="filterData[field['FIELD_NAME']]"
                                                        [ngStyle]="getInputStyles(content['input_styles'])"
                                                        *ngIf="field['DATA_TYPE']==='Dropdown'">
                                                        <option value="">All</option>
                                                        <ng-template ngFor let-option [ngForOf]="staticValues[field.ID]"
                                                            let-i="index">
                                                            <option [value]="option.value">{{option.text}}</option>
                                                        </ng-template>
                                                    </select>
                                                </div>
                                            </ng-container>
                                            <div [class]="content['button_styles'].class !== undefined ? 
                                                content['button_styles'].class.replace('col-lg','column') :'column-3'"
                                                [style.paddingLeft.px]="content['styles']['padding-left']"
                                                [style.paddingRight.px]="content['styles']['padding-right']"
                                                [style.paddingTop.px]="content['styles']['padding-top']"
                                                [style.paddingBottom.px]="content['styles']['padding-bottom']">
                                                <div class="text-left"
                                                    [style.paddingLeft.px]="content['input_styles']['padding-left']"
                                                    [style.paddingRight.px]="content['input_styles']['padding-right']"
                                                    [style.paddingTop.px]="content['input_styles']['padding-top']"
                                                    [style.paddingBottom.px]="content['input_styles']['padding-bottom']">
                                                    <label></label>
                                                </div>
                                                <button class="float-left" style="width:100%;" 
                                                [ngStyle]="getButtonStyles(content['button_styles'])"
                                                [class]="content['styles']['input-control-class'] !== undefined ? content['styles']['input-control-class'].replace('input','button').replace('input','button') : 'button-form-control'"
                                                (click)="applyFilter($event)">Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 mt-3" *ngIf="!loading && containsData">
        <iframe [src]="pdfData" frameborder="0" style="width:100%;min-height:600px;"></iframe>
    </div>
</div>