<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            (click)="sidebarnavItem.submenu.length != 0 ? '' : handleNotify()"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
                <i [ngClass]="[sidebarnavItem.icon]" [style.color]="sidebarnavItem.icon_color"></i>
                <span class="hide-menu" [style.color]="sidebarnavItem.color">{{sidebarnavItem.title | translate}}</span>
            </div>

            <a class="sidebar-link waves-effect waves-dark" [ngClass]="[sidebarnavItem.class]"
                *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">

                <i class="fa" [class]="getIconName(sidebarnavItem.icon)" [style.color]="sidebarnavItem.icon_color"></i>
                <!-- <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather> -->
                <span class="hide-menu" [style.color]="sidebarnavItem.color">{{sidebarnavItem.title | translate}}
                    <span *ngIf="sidebarnavItem.label != undefined && sidebarnavItem.label != '' "
                        [ngClass]="[sidebarnavItem.labelClass]"
                        [style.color]="sidebarnavItem.color">{{sidebarnavItem.label | translate}}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    (click)="sidebarnavSubItem.submenu.length != 0 ? '' : handleNotify()"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link"
                        *ngIf="!sidebarnavSubItem.extralink && sidebarnavSubItem.submenu.length == 0"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" (click)="menuClick(sidebarnavSubItem)">
                        <!-- <i [ngClass]="[sidebarnavSubItem.icon]"></i> -->
                        <span class="hide-menu" [style.color]="sidebarnavItem.color">{{sidebarnavSubItem.title |
                            translate}}</span>
                    </a>
                    <a class="sidebar-link" *ngIf="!sidebarnavSubItem.extralink && sidebarnavSubItem.submenu.length > 0"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" (click)="addActiveClass(sidebarnavSubItem.title)">
                        <!-- <i [ngClass]="[sidebarnavSubItem.icon]"></i> -->
                        <span class="hide-menu" [style.color]="sidebarnavItem.color">{{sidebarnavSubItem.title |
                            translate}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                            routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" (click)="menuClick(sidebarnavSubsubItem)"
                                *ngIf="!sidebarnavSubsubItem.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <!-- <i [ngClass]="[sidebarnavSubsubItem.icon]"></i> -->
                                <span class="hide-menu"
                                    [style.color]="sidebarnavItem.color">{{sidebarnavSubsubItem.title |
                                    translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>