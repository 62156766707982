<div class="row m-0">
    <div class="col-12 mt-2 mb-1" [ngStyle]="getStyles(props.styles)">
        Your Wishlist
    </div>
    <div class="col-12 mt-2 mb-2" *ngIf="wishlists.length === 0">No Product in wishlist yet.</div>

    <div class="table-responsive mb-2" *ngIf="wishlists.length > 0" [ngStyle]="getStyles(props.table_styles)">
        <table class="table table-bordered" style="min-width:500px;overflow-x:scroll;">
            <thead [ngStyle]="getStyles(props.header_styles)">
                <tr>
                    <th style="width:25%;"></th>
                    <th style="width:30%;">Product Name</th>
                    <th style="width:9%;">Qty</th>
                    <th style="width:12%;">Price</th>
                    <th style="width:12%;">Amount</th>
                    <th style="width:12%;" colspan="2">..</th>
                </tr>
            </thead>
            <tbody [ngStyle]="getStyles(props.body_styles)">
                <tr *ngFor="let item of paged_wishlists">
                    <td><img [src]="item.IMAGE_URL" height="100px" 
                        style="display: block; width: 100%;object-fit: contain;"/></td>
                    <td style="text-align: left;">{{item.PRODUCT_NAME}}</td>
                    <td style="text-align: center;">{{item.QTY}}</td>
                    <td style="text-align: right;">{{item.PRICE | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{item.QTY * item.PRICE | number:'1.2-2'}}</td>
                    <td style="width:7%;text-align: center;" *ngIf="item.IS_STOCK_AVAILABLE==='1'">
                        <button class="btn" (click)="addToCart(item)" *ngIf="props.cart_styles===undefined">
                            <span class="fas fa-shopping-cart text-success" title="Add to Cart"></span>
                        </button>
                        <button class="btn" (click)="addToCart(item)" *ngIf="props.cart_styles!==undefined"
                        [style.color]="props['cart_styles']['color']"
                        [style.backgroundColor]="props['cart_styles']['background-color']">
                            <span class="fas fa-shopping-cart" title="Add to Cart"
                            [style.color]="props['cart_styles']['color']"></span>
                        </button>
                    </td>
                    <td style="width:7%;text-align: center;" *ngIf="item.IS_STOCK_AVAILABLE!=='1'"><span >Out of stock</span></td>
                    <td style="width:5%;text-align: center;">
                        <button class="btn" (click)="removeFromWishlist(item)">
                            <span class="fa fa-trash-alt text-warning" title="Delete"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot  [ngStyle]="getStyles(props.footer_styles)">
                <tr>
                    <th colspan="7" style="text-align: right;" *ngIf="wishlists.length > 10">
                        <div style="display:flex;" [ngStyle]="getStyles(props.paging_styles)">
                            <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" 
                                [totalItems]="wishlists.length" [itemsPerPage]="10"
                                (pageChanged)="pageChanged($event)">
                            </pagination>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
    </div>  
</div>