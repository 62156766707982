<div class="row m-2">
    <div class="col-12 mt-2 mb-1">
        Order Detail
        <button type="button" class="btn btn-light float-right mr-1" (click)="close()" aria-label="Close" >
            Close</button>        
    </div>

    <div class="col-8 mt-2 mb-1" *ngIf="order.PAYMENT_STATUS === 'Cancelled'">
        Order No : {{order.ORDER_NO}} / Dated: {{order.ORDER_DATE | date: 'd-MMM-y'}} / Payment: Failed / Ref. No: {{order.REFERENCE_NUMBER===''||order.REFERENCE_NUMBER===null ? '-':order.REFERENCE_NUMBER}}
    </div>

    <div class="col-8 mt-2 mb-1" *ngIf="order.PAYMENT_STATUS === 'Pending'">
        Order No : {{order.ORDER_NO}} / Dated: {{order.ORDER_DATE | date: 'd-MMM-y'}} / Payment: Pending / Ref. No: {{order.REFERENCE_NUMBER===''||order.REFERENCE_NUMBER===null ? '-':order.REFERENCE_NUMBER}}
    </div>

    <div class="col-8 mt-2 mb-1" *ngIf="order.PAYMENT_STATUS !== 'Cancelled' && order.PAYMENT_STATUS !== 'Pending'">
        Order No : {{order.ORDER_NO}} / Dated: {{order.ORDER_DATE | date: 'd-MMM-y'}} / Status: {{order.ORDER_STATUS}} / Ref. No: {{order.REFERENCE_NUMBER===''||order.REFERENCE_NUMBER===null ? '-':order.REFERENCE_NUMBER}}
    </div>

    <div class="col-4 mt-2 mb-1">
        <button type="button" class="btn btn-success float-right mr-1" *ngIf="showDownload"
            (click)="download()" aria-label="Download Invoice" > Download Invoice</button>   
    </div>
    <div class="col-8 mt-2 mb-1">
        <p>Address: {{order.ADDRESS1}} {{order.ADDRESS2}} {{order.CITY}} {{order.PINCODE}} {{order.STATE}}</p>
    </div>
    <div class="col-4 mt-2 mb-1">
        <button type="button" class="btn btn-warning float-right mr-1" *ngIf="showCancellation"
            (click)="cancelOrder()" aria-label="Cancel Order" >Cancel Order</button>
    </div>

    <div class="table-responsive mb-2" *ngIf="!showReviewDiv">
        <table class="table table-bordered table-striped" style="min-width:500px;overflow-x:scroll;">
            <thead>
                <tr>
                    <th style="width:25%;"></th>
                    <th style="width:30%;">Product Name</th>
                    <th style="width:9%;">Qty</th>
                    <th style="width:9%;">Price</th>
                    <th style="width:9%;">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of products">
                    <td><img [src]="item.IMAGE_URL" height="100px" 
                        style="display: block; width: 100%;object-fit: contain;"/></td>
                    <td style="text-align: left;" 
                    *ngIf="order.ORDER_STATUS !== 'Delivered' && order.ORDER_STATUS !== 'Completed'">
                        {{item.PRODUCT_NAME}}
                    </td>
                    <td style="text-align: left;" 
                    *ngIf="(order.ORDER_STATUS === 'Delivered' || order.ORDER_STATUS === 'Completed') 
                    && item.RATING === 0">
                        {{item.PRODUCT_NAME}} <br/>
                        <a class="btn btn-light mt-1" (click)="showReview(item)">Write a Product Review</a><br/>
                        <a class="btn btn-success mt-1" (click)="downloadFile(item)"
                        *ngIf="item.PRODUCT_TYPE==='DIGITAL' && item.DIGITAL_FILE_URL!==''">Download Content</a>
                    </td>
                    <td style="text-align: left;" 
                    *ngIf="(order.ORDER_STATUS === 'Delivered' ||  order.ORDER_STATUS === 'Completed') 
                    && item.RATING > 0">
                        {{item.PRODUCT_NAME}} <br/>
                        <ngb-rating [max]="5" [(rate)]="item.RATING" [readonly]="true"></ngb-rating><br/>
                        <a class="btn btn-success mt-1" (click)="downloadFile(item)"
                        *ngIf="item.PRODUCT_TYPE==='DIGITAL' && item.DIGITAL_FILE_URL!==''">Download Content</a>
                    </td>
                    <td style="text-align: center;">{{item.QTY}}</td>
                    <td style="text-align: right;">{{item.PRICE | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{item.QTY * item.PRICE | number:'1.2-2'}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="4" style="text-align: right;">Sub Total</th>
                    <th style="text-align: right;">{{order.ITEM_TOTAL | number:'1.2-2'}}</th>
                </tr>
                <tr>
                    <th colspan="4" style="text-align: right;">Coupon Discount</th>
                    <th style="text-align: right;">{{order.COUPON_DISCOUNT | number:'1.2-2'}}</th>
                </tr>
                <tr>
                    <th colspan="4" style="text-align: right;">Grand Total</th>
                    <th style="text-align: right;">{{order.ORDER_AMOUNT | number:'1.2-2'}}</th>
                </tr>
            </tfoot>
        </table>
    </div>  

    <div class="col-12 mt-2 mb-2" *ngIf="showReviewDiv">
        <div class="card card-flat">
            <div class="card-body">
                <p class="text-bold text-center py-2">PRODUCT REVIEW</p>
                <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="reviewForm" novalidate="">
                    <div class="row pl-2 pr-2">
                        <div class="col-xs-12 col-sm-12 col-lg-12 form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">Product</span>
                                </div>
                                <input class="form-control" type="text" name="PRODUCT_NAME" 
                                formControlName="PRODUCT_NAME" [readonly]="true"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-12 form-group">
                            <div class="input-group">
                                <div class="input-group-prepend mr-3">
                                    <span class="input-group-text" id="basic-addon1">Rating</span>
                                </div>
                                <ngb-rating [max]="5" [(rate)]="rating" [readonly]="false"></ngb-rating>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-12 form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">Headline</span>
                                </div>
                                <input class="form-control" type="text" name="HEADLINE" placeholder="Enter headline" 
                                autocomplete="off" formControlName="HEADLINE" maxlength="450" required="" />
                            </div>
                            <div class="text-danger" *ngIf="valForm.controls['HEADLINE'].hasError('required') && (valForm.controls['HEADLINE'].dirty || valForm.controls['HEADLINE'].touched)">This field is required</div>                                            
                        </div>
                        <div class="col-xs-12 col-sm-12 col-lg-12 form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">Comments</span>
                                </div>
                                <textarea class="form-control" name="REVIEW" placeholder="Enter your review" 
                                autocomplete="off" formControlName="REVIEW" required="" ></textarea>
                            </div>
                            <div class="text-danger" *ngIf="valForm.controls['REVIEW'].hasError('required') && (valForm.controls['REVIEW'].dirty || valForm.controls['REVIEW'].touched)">This field is required</div>                                            
                        </div>
                    </div>
                    <button class="btn btn-success mt-3 mb-3 mr-3" (click)="updateReview($event)">Submit Review</button>
                    <button class="btn btn-light mt-3 mb-3" (click)="showReviewDiv=false;">Close</button>
                </form>  
            </div>
        </div>      
    </div>
</div>