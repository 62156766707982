import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PageFunctions {
	checkNullAndAssign(style, obj, key, suffix) {
		if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
			style[key] = obj[key] + suffix;
		}
	}

	checkNullLabel(obj, key) {
		return obj[key] !== null && obj[key] !== undefined;
	}

	setDefaultValue(props, key, value) {
		if (props[key] === undefined) {
			props[key] = value;
		}
	}

	setRowAutoHeight(row) {
		row['styles']['auto-height'] = true;
		row.styles.height = '100%';
		var ele = document.getElementById('row_' + row.id);
		if (ele) {
			ele.style.setProperty('height', '100%');
		}
	}

	setElementStyle(ele, style, value) {
		ele.style.setProperty(style, value);
	}

	setValueByKey(props, key, value) {
		props[key] = value;
	}

	setShadow(style, obj) {
		var gStyle = '';
		if (this.checArrayNull(obj['shadow-list'])) {
			if (obj['is-text-shadow']) {
				obj['shadow-list'].forEach((element) => {
					gStyle += element['h-size'] + 'px ' + element['v-size'] + 'px ' + element['blur-radius'] + 'px ' + element['shadow-color'] + ',';
				});
				if (gStyle !== '') {
					gStyle = gStyle.substring(0, gStyle.length - 1);
					style['text-shadow'] = gStyle;
				}
			} else if (obj['is-filter-shadow']) {
				obj['shadow-list'].forEach((element) => {
					gStyle += 'drop-shadow(' + element['h-size'] + 'px ' + element['v-size'] + 'px ' + element['blur-radius'] + 'px ' + element['shadow-color'] + ') ';
				});
				if (gStyle !== '') {
					style['filter'] = gStyle;
				}
			} else {
				obj['shadow-list'].forEach((element) => {
					gStyle += (element['shadow-inset'] ? 'inset ' : '') + element['h-size'] + 'px ' + element['v-size'] + 'px ' + element['blur-radius'] + 'px ' + element['spread-radius'] + 'px ' + element['shadow-color'] + ',';
				});
				if (gStyle !== '') {
					gStyle = gStyle.substring(0, gStyle.length - 1);
					style['box-shadow'] = gStyle;
					style['-webkit-box-shadow'] = gStyle;
					style['-moz-box-shadow'] = gStyle;
				}
			}
		}
	}

	checkNull(val) {
		return val !== null && val !== undefined && val !== '';
	}

	checArrayNull(val) {
		return val !== null && val !== undefined && val.length > 0;
	}

	getPageStyles(obj) {
		var style = {};

		this.checkNullAndAssign(style, obj, 'page-width', '');
		if (style['page-width'] === undefined) {
			style['page-width'] = 1366;
		}
		if (this.checkNullLabel(obj, 'background') && this.checkNull(obj['background']['url'])) {
			style['background-image'] = 'url(' + obj['background']['url'] + ')';

			if (obj['background']['center'] === 1 || obj['background']['center'] === true) {
				style['background-position'] = 'center';
			} else {
				style['background-position'] = 'top';
			}
			if (obj['background']['repeat'] === 1 || obj['background']['repeat'] === true) {
				style['background-repeat'] = 'repeat';
			} else {
				style['background-repeat'] = 'no-repeat';
			}
			style['background-size'] = 'auto';
			if (obj['background']['fill'] === 1 || obj['background']['fill'] === true) {
				style['background-size'] = 'cover';
			}
			if (obj['background']['contain'] === 1 || obj['background']['contain'] === true) {
				style['background-size'] = 'contain';
			}
			this.checkNullAndAssign(style, obj, 'background-color', '');
		} else {
			this.checkNullAndAssign(style, obj, 'background-color', '');
		}

		return style;
	}

	setPadding(style, obj) {
		if (this.checkNullLabel(obj, 'padding-top')) {
			style['padding-top'] = obj['padding-top'] + 'px';
		}
		if (this.checkNullLabel(obj, 'padding-right')) {
			style['padding-right'] = obj['padding-right'] + 'px';
		}
		if (this.checkNullLabel(obj, 'padding-bottom')) {
			style['padding-bottom'] = obj['padding-bottom'] + 'px';
		}
		if (this.checkNullLabel(obj, 'padding-left')) {
			style['padding-left'] = obj['padding-left'] + 'px';
		}
	}

	setMargin(style, obj) {
		if (this.checkNullLabel(obj, 'margin-top')) {
			style['margin-top'] = obj['margin-top'] + 'px';
		}
		if (this.checkNullLabel(obj, 'margin-right')) {
			style['margin-right'] = obj['margin-right'] + 'px';
		}
		if (this.checkNullLabel(obj, 'margin-bottom')) {
			style['margin-bottom'] = obj['margin-bottom'] + 'px';
		}
		if (this.checkNullLabel(obj, 'margin-left')) {
			style['margin-left'] = obj['margin-left'] + 'px';
		}
	}

	setBackground(style, obj) {
		let gStyle = '';
		let gStyleSize = '';
		if (this.checArrayNull(obj['gradient-list'])) {
			if (obj['is-gradient-grouped']) {
				let deg = '';
				obj['gradient-list'].forEach((element) => {
					if (deg === '') {
						deg = element['gradient-degree'] + 'deg,';
					}
					if (element['gradient-opacity'] !== null) {
						gStyle += element['start-background-color'] + ' ' + element['gradient-opacity'] + '%,';
					} else {
						gStyle += element['start-background-color'] + ',';
					}
					if (element['background-size'] !== null) {
						gStyleSize += element['background-size'] + '% ';
					} else {
						gStyleSize += '100% ';
					}
				});
				if (gStyle !== '') {
					gStyle = 'linear-gradient(' + deg + gStyle.substring(0, gStyle.length - 1) + '),';
				}
			} else {
				obj['gradient-list'].forEach((element) => {
					gStyle += 'linear-gradient(' + element['gradient-degree'] + 'deg, ' + element['start-background-color'] + ', ' + element['end-background-color'] + ' ' + element['gradient-opacity'] + '% ),';

					if (element['background-size'] !== null) {
						gStyleSize += element['background-size'] + '% ';
					} else {
						gStyleSize += '100% ';
					}
				});
			}
			if (gStyle !== '') {
				gStyle = gStyle.substring(0, gStyle.length - 1);
			}
		} else if (obj['use_template']) {
			style['conic-gradient-size'] = obj['conic-gradient-size'];
			style['conic_color_1'] = obj['conic_color_1'];
			style['conic_color_2'] = obj['conic_color_2'];
			style['conic_color_3'] = obj['conic_color_3'];

			if (obj['conic-gradient-size'] !== undefined) {
				style['--s'] = obj['conic-gradient-size'];
			}
			if (obj['conic_color_1'] !== undefined) {
				style['--c1'] = obj['conic_color_1'];
			}
			if (obj['conic_color_2'] !== undefined) {
				style['--c2'] = obj['conic_color_2'];
			}
			if (obj['conic_color_3'] !== undefined) {
				style['--c3'] = obj['conic_color_3'];
			}
		}
		if (gStyle !== '') {
			style['background-image'] = gStyle;
			style['background-size'] = gStyleSize;
		}
	}

	serBorderRadius(style, obj) {
		if (this.checkNullLabel(obj, 'border-top-left-radius')) {
			style['border-top-left-radius'] = obj['border-top-left-radius'] + 'px';
		}
		if (this.checkNullLabel(obj, 'border-top-right-radius')) {
			style['border-top-right-radius'] = obj['border-top-right-radius'] + 'px';
		}
		if (this.checkNullLabel(obj, 'border-bottom-left-radius')) {
			style['border-bottom-left-radius'] = obj['border-bottom-left-radius'] + 'px';
		}
		if (this.checkNullLabel(obj, 'border-bottom-right-radius')) {
			style['border-bottom-right-radius'] = obj['border-bottom-right-radius'] + 'px';
		}
	}

	getRowPosition(obj) {
		var style: any = {};
		if (obj['fixed-position']) {
			if (obj['fixed-position-bottom']) {
				style['position'] = 'sticky';
				style['width'] = '100%';
				style['bottom'] = '0';
			}
		}
		return style;
	}

	setBorder(style, obj, samebordercolor) {
		if (samebordercolor) {
			if (this.checkNull(obj['border-style'])) {
				if (!this.checkNullLabel(obj, 'border-top') && this.checkNull(obj['border-width']) && parseInt(obj['border-width']) !== 0) {
					style['border'] = obj['border-width'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
				} else {
					if (this.checkNull(obj['border-top'])) {
						style['border-top'] = obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
					}
					if (this.checkNull(obj['border-bottom'])) {
						style['border-bottom'] = obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
					}
					if (this.checkNull(obj['border-left'])) {
						style['border-left'] = obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
					}
					if (this.checkNull(obj['border-right'])) {
						style['border-right'] = obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
					}
				}
			}
		} else {
			style['border-top'] = obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-top-color'];
			style['border-bottom'] = obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-bottom-color'];
			style['border-left'] = obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-left-color'];
			style['border-right'] = obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-right-color'];
		}
	}

	setBackgroundImage(style, obj) {
		if (obj['bg-multiple']) {
			style['background-image'] = '';
			style['background-repeat'] = '';
			style['background-position'] = '';
			style['background-size'] = '';
			let bgimage = '',
				bgposition = '',
				bgrepeat = '',
				bgsize = '';

			if (this.checArrayNull(obj['bg-multiple-list'])) {
				obj['bg-multiple-list'].forEach((element) => {
					if (this.checkNull(element['background']['url'])) {
						bgimage += 'url(' + element['background']['url'] + '),';
					} else if (this.checkNull(element['background']['svgContent'])) {
						bgimage += element['background']['svgContent']['changingThisBreaksApplicationSecurity'] + ',';
					}
					if (element['background-position'] !== '') {
						bgposition += element['background-position'] + ',';
					} else {
						bgposition += element['position-x'] + 'px ' + element['position-y'] + 'px,';
					}
					if (element['fill']) bgsize += 'cover,';
					else bgsize += element['background-size'] + '%,';

					if (element['repeat']) {
						bgrepeat += 'repeat,';
					} else {
						bgrepeat += 'no-repeat,';
					}
				});
				bgimage = bgimage.substring(0, bgimage.length - 1);
				bgposition = bgposition.substring(0, bgposition.length - 1);
				bgrepeat = bgrepeat.substring(0, bgrepeat.length - 1);
				bgsize = bgsize.substring(0, bgsize.length - 1);
				style['background-image'] = bgimage;
				style['background-repeat'] = bgrepeat;
				style['background-position'] = bgposition;
				style['background-size'] = bgsize;
			}
		} else {
			if (this.checkNullLabel(obj, 'background') && this.checkNull(obj['background']['url'])) {
				style['background-image'] = 'url(' + obj['background']['url'] + ')';

				if (obj['background']['center'] === 1 || obj['background']['center'] === true) {
					style['background-position'] = 'center';
				}
				if (obj['background']['repeat'] === 1 || obj['background']['repeat'] === true) {
					style['background-repeat'] = 'repeat';
				} else {
					style['background-repeat'] = 'no-repeat';
				}
				if (obj['background']['fill'] === 1 || obj['background']['fill'] === true) {
					style['background-size'] = 'cover';
				} else if (this.checkNull(obj['background-size'])) {
					style['background-size'] = obj['background-size'] + '%';
				}
			}
		}
		//this.checkNullAndAssign(style, obj, 'background-color', '');
		if (!this.checkNull(obj['video-opacity-color'])) {
			this.checkNullAndAssign(style, obj, 'background-color', '');
		}
	}

	getRowBackgroundColor(obj) {
		var style = {};
		if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== '') {
			style['background-color'] = obj['background-color'];
			style['opacity'] = obj['opacity'];
		}
		return style;
	}

	getPageRowStyles(row, obj, idx, isAppleDevice) {
		var style: any = {};

		style['z-index'] = 1000 - idx;

		if (row.styles['auto-height']) {
			obj['height'] = '100%';
		}

		if (obj['gridGap']) {
			style['--gridGap'] = obj['gridGap'] + 'px';
		} else {
			style['--gridGap'] = '0px';
		}
		if (obj['grid-height'] && obj['grid-height'] !== 0 && obj['grid-height'] !== '0') {
			style['--gridHeight'] = obj['grid-height'] + 'px';
		} else {
			style['--gridHeight'] = 'auto';
		}
		if (obj['grid-columns']) {
			if (row['styles']['row-full-width']) {
				style['--gridColumns'] = 100 / obj['grid-columns'] + '%';
			} else {
				style['--gridColumns'] = 100 / obj['grid-columns'] + '%';
			}
		} else {
			style['--gridColumns'] = 100 / 12 + '%';
		}
		this.checkNullAndAssign(style, obj, 'border-top-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-top-right-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-right-radius', 'px');

		this.setPadding(style, obj);
		this.setBackground(style, obj);
		this.setBorder(style, obj, true);
		if (obj['background-type'] !== 'video') this.setBackgroundImage(style, obj);

		if (this.checkNullLabel(obj, 'height') && obj['height'] !== 0) {
			if (obj['height'] == '100%') {
				style['height'] = '100%';
				style['min-height'] = '100%';
			} else {
				style['height'] = obj['height'] + 'px';
				this.checkNullAndAssign(style, obj, 'min-height', 'px');
			}
		} else {
			this.checkNullAndAssign(style, obj, 'min-height', 'px');
		}

		if (obj['fixed-position']) {
			if (!obj['fixed-position-bottom']) {
				style['position'] = 'fixed';
				style['width'] = '100%';
			}
		}
		if (!isAppleDevice) {
			if (obj['background-type'] !== 'video') {
				if (obj['background']['attachment'] === 1 || obj['background']['attachment'] === true) {
					style['background-attachment'] = 'fixed';
				}
			}
		}
		this.checkNullAndAssign(style, obj, 'margin-top', 'px');

		return style;
	}

	getRowStyles(row, obj, idx, isAppleDevice) {
		var style: any = {};

		style['z-index'] = 1000 - idx;

		if (obj['pin-at-bottom']) {
			style['z-index'] = 9999;
		} else {
			style['z-index'] = 1000 - idx;
		}

		if (obj['gridGap']) {
			style['--gridGap'] = obj['gridGap'] + 'px';
		} else {
			style['--gridGap'] = '0px';
		}
		if (obj['grid-height'] && obj['grid-height'] !== 0 && obj['grid-height'] !== '0') {
			style['--gridHeight'] = obj['grid-height'] + 'px';
		} else {
			style['--gridHeight'] = 'auto';
		}
		if (obj['grid-columns']) {
			if (row['styles']['row-full-width']) {
				style['--gridColumns'] = 100 / obj['grid-columns'] + '%';
			} else {
				style['--gridColumns'] = 100 / obj['grid-columns'] + '%';
			}
		} else {
			style['--gridColumns'] = 100 / 12 + '%';
		}

		this.checkNullAndAssign(style, obj, 'justify-content', '');
		this.checkNullAndAssign(style, obj, 'text-align', '');
		this.checkNullAndAssign(style, obj, 'border-top-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-top-right-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-right-radius', 'px');
		this.checkNullAndAssign(style, obj, 'min-height', 'px');

		this.setPadding(style, obj);
		this.setBackground(style, obj);
		this.setBorder(style, obj, true);
		if (obj['background-type'] !== 'video') this.setBackgroundImage(style, obj);

		if (this.checkNullLabel(obj, 'height') && obj['height'] !== 0) {
			if (obj['height'] == '100%') {
				style['height'] = '100%';
			} else {
				style['height'] = obj['height'] + 'px';
			}
		}

		if (obj['fixed-position']) {
			if (!obj['fixed-position-bottom']) {
				style['position'] = 'fixed';
				style['width'] = '100%';
			}
		}
		if (!isAppleDevice) {
			if (obj['background-type'] !== 'video') {
				if (obj['background']['attachment'] === 1 || obj['background']['attachment'] === true) {
					style['background-attachment'] = 'fixed';
				}
			}
		}
		this.checkNullAndAssign(style, obj, 'margin-top', 'px');

		return style;
	}

	getColumnStyles(column, contentLength) {
		var window_width = window.innerWidth;
		var obj: any = JSON.parse(JSON.stringify(column.styles));
		var style: any = {};

		this.checkNullAndAssign(style, obj, 'justify-content', '');
		this.checkNullAndAssign(style, obj, 'border-top-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-top-right-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-right-radius', 'px');
		this.setPadding(style, obj);
		this.setBackground(style, obj);
		this.setBorder(style, obj, true);
		if (obj['column-value']) {
			style['--colValue'] = `span ${obj['column-value']}`;
		} else {
			style['--colValue'] = '';
		}

		if (obj['row-value']) {
			style['--rowValue'] = `span ${obj['row-value']}`;
		} else {
			style['--rowValue'] = '';
		}
		if (obj['hover-available'] && this.checkNull(obj['hover-background-color'])) {
			style['--hover-background-color'] = obj['hover-background-color'];
		} else {
			style['--hover-background-color'] = obj['background-color'];
		}

		if (obj['background-type'] !== 'video') this.setBackgroundImage(style, obj);

		if (obj['background-type'] !== 'video' && this.checkNullLabel(obj, 'background') && this.checkNull(obj['background']['url'])) {
			if (window_width < 800 && contentLength === 0) {
				style['min-height'] = '250px';
			}
		}

		return style;
	}

	getInputStyle(obj) {
		var style: any = {};

		if (obj === undefined) {
			obj = {
				color: '#495057',
				'background-color': '#fff',
				'font-weight': 'normal',
				'font-style': 'normal',
				'text-decoration': 'normal',
				'border-top': 0,
				'border-right': 0,
				'border-bottom': 0,
				'border-left': 0,
				'border-style': 'solid',
				position: '',
				'border-color': '#495057',
				'border-top-left-radius': 0,
				'border-top-right-radius': 0,
				'border-bottom-left-radius': 0,
				'border-bottom-right-radius': 0,
			};
		}

		this.checkNullAndAssign(style, obj, 'font-weight', '');
		this.checkNullAndAssign(style, obj, 'font-style', '');
		this.checkNullAndAssign(style, obj, 'text-decoration', '');
		this.checkNullAndAssign(style, obj, 'color', '');
		this.checkNullAndAssign(style, obj, 'background-color', '');

		if (obj['border-style'] !== undefined && (obj['border-style'] === null || obj['border-style'] === '')) {
			obj['border-style'] = 'none';
		}

		if (obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
			this.setBorder(style, obj, true);
		}

		this.checkNullAndAssign(style, obj, 'border-top-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-top-right-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-left-radius', 'px');
		this.checkNullAndAssign(style, obj, 'border-bottom-right-radius', 'px');

		return style;
	}

	getFormButtonStyle(obj) {
		var style: any = {};
		this.checkNullAndAssign(style, obj, 'font-weight', '');
		this.checkNullAndAssign(style, obj, 'font-style', '');
		this.checkNullAndAssign(style, obj, 'color', '');
		this.checkNullAndAssign(style, obj, 'background-color', '');

		if (obj['border-style'] !== undefined && (obj['border-style'] === null || obj['border-style'] === '')) {
			obj['border-style'] = 'none';
		}

		if (obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
			this.setBorder(style, obj, true);
		}

		this.serBorderRadius(style, obj);

		return style;
	}

	setTransparentBorder(style, obj) {
		style['border-top-width'] = obj['border-top'] + 'px';
		style['border-bottom-width'] = obj['border-bottom'] + 'px';
		style['border-right-width'] = obj['border-right'] + 'px';
		style['border-left-width'] = obj['border-left'] + 'px';
		style['border-style'] = obj['border-style'];
		style['border-color'] = obj['border-color'];
	}
}
