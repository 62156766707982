import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { EnvService } from '../../environments/env.service';

export class CMSAppData {
    constructor(
        public NewsPage: string,
        public ProductPage: string,
        public ServicePage: string,
        public BlogPage: string,
        public BlogContentPage: string
    ) { }
}

@Injectable({ providedIn: 'root' })
export class CMSService {
    httpOptions : any;
    apiUrl : string;
    clientKey: string;
    public AppData = new CMSAppData("","","","","");

    constructor(private http: HttpClient,private environment:EnvService) {
        
        this.httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json'}),withCredentials: true};
        this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        this.httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.httpOptions.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        this.httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

        this.apiUrl = environment.apiUrl;
        this.clientKey = environment.clientKey;
    }

    getCategories(WEBSITE_ID, CATEGORY):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}faq_categories`, { CK, WEBSITE_ID, CATEGORY }, this.httpOptions).pipe(map((response: any) => response));
    }

    getFAQs(WEBSITE_ID, CATEGORY_ID):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}faqs`, { CK, WEBSITE_ID, CATEGORY_ID }, this.httpOptions).pipe(map((response: any) => response));
    }

    getNEWS(WEBSITE_ID):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}news`, { CK, WEBSITE_ID }, this.httpOptions).pipe(map((response: any) => response));
    }

    getArticles(WEBSITE_ID,CATEGORY, LIMIT):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}articles`, { CK, WEBSITE_ID, CATEGORY, LIMIT }, this.httpOptions).pipe(map((response: any) => response));
    }

    getPS(WEBSITE_ID, TYPE):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}ps`, { CK, WEBSITE_ID, TYPE }, this.httpOptions).pipe(map((response: any) => response));
    }

    getNEWSContent(WEBSITE_ID, URL):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}newscontent`, { CK, WEBSITE_ID, URL }, this.httpOptions).pipe(map((response: any) => response));
    }

    getArticleContent(WEBSITE_ID, URL):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}blogcontent`, { CK, WEBSITE_ID, URL }, this.httpOptions).pipe(map((response: any) => response));
    }

    insertArticleComment(ARTICLE_ID, FIRST_NAME, LAST_NAME, EMAIL_ID, COMMENT):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}insertcomment`, { CK, ARTICLE_ID, FIRST_NAME, LAST_NAME, EMAIL_ID, COMMENT }, this.httpOptions).pipe(map((response: any) => response));
    }

    getArticleComments(ARTICLE_ID):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}approvedcomments`, { CK, ARTICLE_ID }, this.httpOptions).pipe(map((response: any) => response));
    }

    getPSContent(WEBSITE_ID, URL, TYPE):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}pscontent`, { CK, WEBSITE_ID, URL, TYPE }, this.httpOptions).pipe(map((response: any) => response));
    }
}