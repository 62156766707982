<ng-container *ngIf="content.template==='1'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">
            {{content.brandName}}
            <a class="btn btn-sm btn-info float-right text-sm m-1" (click)="showFilter = !showFilter" role="button"
            *ngIf="isMobile()">
                Filter
            </a>
        </div>
        <div [class]="filterClass" *ngIf="showFilter">
            <div class="mb-2" *ngIf="categories.length > 0">
                <app-categoryfilter [content]="content"></app-categoryfilter>
            </div>
            <div class="mt-2 mb-2" *ngIf="categories.length > 0">
                <button class="btn btn-primary" (click)="filterProducts()"
                *ngIf="content.filter_button_styles===undefined">Filter</button>
                <button *ngIf="content.filter_button_styles!==undefined"
                [ngStyle]="getFormButtonStyle(content.filter_button_styles)"
                [style.textDecoration]="content['filter_button_styles']['text-decoration']"
                [style.fontStyle]="content['filter_button_styles']['font-style']"
                [style.fontWeight]="content['filter_button_styles']['font-weight']"
                [style.color]="content['filter_button_styles']['color']"
                [style.backgroundColor]="content['filter_button_styles']['background-color']"
                style="cursor:pointer;" (click)="filterProducts()">Filter</button> 
            </div>
        </div>
        <div [class]="listClass">
            <div class="row">
                <div class="hovercontainer" [class]="content.class" 
                    [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}"
                    style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div class="show-item-options" *ngIf="item.isVariant===false">
                        <a (click)="addToCart(item)" style="cursor:pointer;" class="mr-2" title="Add to Cart">
                            <span class="fas fa-shopping-cart fa-2x text-success"></span>
                        </a>
                        <a (click)="showProduct(item)" style="cursor:pointer;" class="mr-2" title="View Product Info">
                            <span class="fas fa-eye fa-2x text-info"></span>
                        </a>
                        <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                            <span class="fas fa-heart fa-2x" 
                            [style.color]="content['wishlist_styles']['selected-color']"></span>
                        </a>
                        <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                            <span class="fas fa-heart fa-2x" 
                            [style.color]="content['wishlist_styles']['unselected-color']"></span>
                        </a>
                    </div>
                    <div class="productdiv" style="cursor:pointer;" 
                        [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)" style="display:flex;flex-direction: column;"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.product_styles)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <div style="position:relative;" [ngStyle]="getStyles(content.image_styles)">
                            <img [src]="item.imageUrl" alt="product image"
                            style="display: block;width: 100%;height:100%;object-fit: contain;" />
                            <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                            </div>
                        </div>
                        <ng-container *ngIf="item.isVariant===true">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.isVariant===false">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='2'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">
            {{content.brandName}}
            <a class="btn btn-sm btn-info float-right text-sm m-1" (click)="showFilter = !showFilter" role="button"
            *ngIf="isMobile()">
                Filter
            </a>
        </div>
        <div [class]="filterClass" *ngIf="isMobile() && showFilter">
            <div class="mb-2">
                <app-categoryfilter [content]="content" *ngIf="categories.length > 0"></app-categoryfilter>
            </div>
            <div class="mt-2 mb-2">
                <button class="btn btn-primary" (click)="filterProducts()"
                *ngIf="content.filter_button_styles===undefined && categories.length > 0">Filter</button>
                <button *ngIf="content.filter_button_styles!==undefined && categories.length > 0"
                [ngStyle]="getFormButtonStyle(content.filter_button_styles)"
                [style.textDecoration]="content['filter_button_styles']['text-decoration']"
                [style.fontStyle]="content['filter_button_styles']['font-style']"
                [style.fontWeight]="content['filter_button_styles']['font-weight']"
                [style.color]="content['filter_button_styles']['color']"
                [style.backgroundColor]="content['filter_button_styles']['background-color']"
                style="cursor:pointer;" (click)="filterProducts()">Filter</button> 
            </div>
        </div>
        <div [class]="listClass">
            <div class="row">
                <div class="hovercontainer" [class]="content.class" 
                    [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}"
                    style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div class="show-item-options" *ngIf="item.isVariant===false">
                        <a (click)="addToCart(item)" style="cursor:pointer;" class="mr-2" title="Add to Cart">
                            <span class="fas fa-shopping-cart fa-2x text-success"></span>
                        </a>
                        <a (click)="showProduct(item)" style="cursor:pointer;" class="mr-2" title="View Product Info">
                            <span class="fas fa-eye fa-2x text-info"></span>
                        </a>
                        <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                            <span class="fas fa-heart fa-2x" 
                            [style.color]="content['wishlist_styles']['selected-color']"></span>
                        </a>
                        <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                            <span class="fas fa-heart fa-2x" 
                            [style.color]="content['wishlist_styles']['unselected-color']"></span>
                        </a>
                    </div>
                    <div class="productdiv" style="cursor:pointer;" 
                        [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)" style="display:flex;flex-direction: column;"
                        (click)="showProduct(item)">
                        <div style="position:relative;" [ngStyle]="getStyles(content.image_styles)">
                            <img [src]="item.imageUrl" alt="product image"
                            style="display: block;width: 100%;height:100%;object-fit: contain;" />
                            <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                            </div>
                        </div>
                        <div [ngStyle]="getStyles(content.product_styles)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <ng-container *ngIf="item.isVariant===true">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.isVariant===false">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
        <div [class]="filterClass" *ngIf="!isMobile()">
            <div class="mb-2">
                <app-categoryfilter [content]="content" *ngIf="categories.length > 0"></app-categoryfilter>
            </div>
            <div class="mt-2 mb-2">
                <button class="btn btn-primary" (click)="filterProducts()"
                *ngIf="content.filter_button_styles===undefined && categories.length > 0">Filter</button>
                <button *ngIf="content.filter_button_styles!==undefined && categories.length > 0"
                [ngStyle]="getFormButtonStyle(content.filter_button_styles)"
                [style.textDecoration]="content['filter_button_styles']['text-decoration']"
                [style.fontStyle]="content['filter_button_styles']['font-style']"
                [style.fontWeight]="content['filter_button_styles']['font-weight']"
                [style.color]="content['filter_button_styles']['color']"
                [style.backgroundColor]="content['filter_button_styles']['background-color']"
                style="cursor:pointer;" (click)="filterProducts()">Filter</button> 
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='3'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">{{content.brandName}}</div>
        <div class="hovercontainer" [class]="content.class" 
            [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}"
            style="padding:10px 15px 10px 15px!important;"
            *ngFor="let item of content.paged_items; let index=index">
            <div class="show-item-options" *ngIf="item.isVariant===false">
                <a (click)="addToCart(item)" class="mr-2" style="cursor:pointer;" title="Add to Cart">
                    <span class="fas fa-shopping-cart fa-2x text-success"></span>
                </a>
                <a (click)="showProduct(item)" class="mr-2" style="cursor:pointer;" title="View Product Info">
                    <span class="fas fa-eye fa-2x text-info"></span>
                </a>
                <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                    <span class="fas fa-heart fa-2x" 
                    [style.color]="content['wishlist_styles']['selected-color']"></span>
                </a>
                <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                    <span class="fas fa-heart fa-2x" 
                    [style.color]="content['wishlist_styles']['unselected-color']"></span>
                </a>
            </div>
            <div class="productdiv" style="cursor:pointer;" 
                [style.borderWidth.px]="content.styles['border-width']"
                [style.borderStyle]="content.styles['border-style']"
                [style.borderRadius.px]="content.styles['border-radius']"
                [style.backgroundColor]="content.styles['background-color']"
                [style.borderColor]="content.styles['border-color']" 
                [ngStyle]="getStyles(content.styles)" style="display:flex;flex-direction: column;"
                (click)="showProduct(item)">
                <div style="position:relative;" [ngStyle]="getStyles(content.image_styles)">
                    <img [src]="item.imageUrl" alt="product image"
                    style="display: block;width: 100%;height:100%;object-fit: contain;" />
                    <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                        Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                    </div>
                </div>
                <div [ngStyle]="getStyles(content.product_styles)"
                    style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                    {{item.productName}}
                </div>
                <ng-container *ngIf="item.isVariant===true">
                    <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                        {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                    </div>
                    <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                        {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                    </div>
                </ng-container>
                <ng-container *ngIf="item.isVariant===false">
                    <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                        {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                    </div>
                    <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                        {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
            <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
            </pagination>
        </div>
        <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
            <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
            </pagination>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='4'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">
            {{content.brandName}}
            <a class="btn btn-sm btn-info float-right text-sm m-1" (click)="showFilter = !showFilter" role="button"
            *ngIf="isMobile()">
                Filter
            </a>
        </div>
        <div [class]="filterClass" *ngIf="showFilter">
            <div class="mb-2" *ngIf="categories.length > 0">
                <app-categoryfilter [content]="content"></app-categoryfilter>
            </div>
            <div class="mt-2 mb-2" *ngIf="categories.length > 0">
                <button class="btn btn-primary" (click)="filterProducts()"
                *ngIf="content.filter_button_styles===undefined">Filter</button>
                <button *ngIf="content.filter_button_styles!==undefined"
                [ngStyle]="getFormButtonStyle(content.filter_button_styles)"
                [style.textDecoration]="content['filter_button_styles']['text-decoration']"
                [style.fontStyle]="content['filter_button_styles']['font-style']"
                [style.fontWeight]="content['filter_button_styles']['font-weight']"
                [style.color]="content['filter_button_styles']['color']"
                [style.backgroundColor]="content['filter_button_styles']['background-color']"
                style="cursor:pointer;" (click)="filterProducts()">Filter</button> 
            </div>
        </div>
        <div [class]="listClass">
            <div class="row">
                <div [class]="content.class" 
                    [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}"
                    style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div class="productdiv" style="cursor:pointer;" 
                        [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)" style="display:flex;flex-direction: column;"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.product_styles)" (click)="showProduct(item)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <div style="position:relative;" [ngStyle]="getStyles(content.image_styles)" (click)="showProduct(item)">
                            <img [src]="item.imageUrl" alt="product image"
                            style="cursor:pointer;display: block;width: 100%;height:100%;object-fit: contain;" />
                            <!-- <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                            </div> -->
                        </div>
                        <ng-container *ngIf="item.isVariant===true">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.isVariant===false">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <div *ngIf="item.isVariant===false" style="display:flex;"
                        [style.marginTop.px]="content.qty_styles['margin-top']"
                        [style.marginBottom.px]="content.qty_styles['margin-bottom']"
                        [style.marginLeft.px]="content.qty_styles['margin-left']"
                        [style.marginRight.px]="content.qty_styles['margin-right']">
                            <input class="input-qty mr-2" type="number" placeholder="Qty" [(ngModel)]="item.qty"
                            [style.color]="content['qty_styles']['color']"
                            [style.backgroundColor]="content['qty_styles']['background-color']"
                            [style.borderRadius.px]="content['qty_styles']['border-radius']"
                            [style.borderWidth.px]="content['qty_styles']['border-width']"
                            [style.borderColor]="content['qty_styles']['border-color']"                            
                            [style.fontFamily]="content.qty_styles['font-family']"
                            [style.fontSize]="content.qty_styles['font-size']"/>
                            <button class="btn btn-danger mr-2" style="cursor:pointer;" (click)="addToCart(item)"
                            *ngIf="content.cart_styles===undefined">Add to Cart</button>
                            <button class="mr-2" *ngIf="content.cart_styles!==undefined"
                            [ngStyle]="getFormButtonStyle(content.cart_styles)"
                            [style.textDecoration]="content['cart_styles']['text-decoration']"
                            [style.fontStyle]="content['cart_styles']['font-style']"
                            [style.fontWeight]="content['cart_styles']['font-weight']"
                            [style.color]="content['cart_styles']['color']"
                            [style.backgroundColor]="content['cart_styles']['background-color']"
                            style="cursor:pointer;" (click)="addToCart(item)">Add to Cart</button>
                            <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                                <span class="fas fa-heart" 
                                [style.color]="content['wishlist_styles']['selected-color']"
                                [style.fontSize]="content['wishlist_styles']['font-size']"></span>
                            </a>
                            <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                                <span class="fas fa-heart" 
                                [style.color]="content['wishlist_styles']['unselected-color']"
                                [style.fontSize]="content['wishlist_styles']['font-size']"></span>
                            </a>
                        </div>
                        <div *ngIf="item.isVariant===true" style="display:flex;"
                        [style.marginTop.px]="content.qty_styles['margin-top']"
                        [style.marginBottom.px]="content.qty_styles['margin-bottom']"
                        [style.marginLeft.px]="content.show_styles['margin-left']"
                        [style.marginRight.px]="content.show_styles['margin-right']">
                            <button class="btn btn-info mr-2" style="cursor:pointer;" (click)="showProduct(item)"
                            *ngIf="content.show_styles===undefined">
                                Show Item</button>
                            <button class="mr-2" *ngIf="content.show_styles!==undefined"
                            [ngStyle]="getFormButtonStyle(content.show_styles)"
                            [style.textDecoration]="content['show_styles']['text-decoration']"
                            [style.fontStyle]="content['show_styles']['font-style']"
                            [style.fontWeight]="content['show_styles']['font-weight']"
                            [style.color]="content['show_styles']['color']"
                            [style.backgroundColor]="content['show_styles']['background-color']"
                            style="cursor:pointer;" (click)="showProduct(item)">Show Item</button> 
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='5'">
    <div class="row m-0">
        <div class="col-12 mt-1 mb-2" [ngStyle]="getStyles(content.header_styles)">
            {{content.brandName}}
        </div>
        <div class="col-12">
            <div class="row">
                <div [class]="content.class" 
                    [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}"
                    style="padding:10px 15px 10px 15px!important;"
                    *ngFor="let item of content.paged_items; let index=index">
                    <div class="productdiv" style="cursor:pointer;" 
                        [style.borderWidth.px]="content.styles['border-width']"
                        [style.borderStyle]="content.styles['border-style']"
                        [style.borderRadius.px]="content.styles['border-radius']"
                        [style.backgroundColor]="content.styles['background-color']"
                        [style.borderColor]="content.styles['border-color']" 
                        [ngStyle]="getStyles(content.styles)" style="display:flex;flex-direction: column;"
                        (click)="showProduct(item)">
                        <div [ngStyle]="getStyles(content.product_styles)" (click)="showProduct(item)"
                            style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                            {{item.productName}}
                        </div>
                        <div style="position:relative;" [ngStyle]="getStyles(content.image_styles)" (click)="showProduct(item)">
                            <img [src]="item.imageUrl" alt="product image"
                            style="cursor:pointer;display: block;width: 100%;height:100%;object-fit: contain;" />
                            <!-- <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                            </div> -->
                        </div>
                        <ng-container *ngIf="item.isVariant===true">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.isVariant===false">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                        </ng-container>
                        <div *ngIf="item.isVariant===false" style="display:flex;"
                        [style.marginTop.px]="content.qty_styles['margin-top']"
                        [style.marginBottom.px]="content.qty_styles['margin-bottom']"
                        [style.marginLeft.px]="content.qty_styles['margin-left']"
                        [style.marginRight.px]="content.qty_styles['margin-right']">
                            <input class="input-qty mr-2" type="number" placeholder="Qty" [(ngModel)]="item.qty"
                            [style.color]="content['qty_styles']['color']"
                            [style.backgroundColor]="content['qty_styles']['background-color']"
                            [style.borderRadius.px]="content['qty_styles']['border-radius']"
                            [style.borderWidth.px]="content['qty_styles']['border-width']"
                            [style.borderColor]="content['qty_styles']['border-color']"                            
                            [style.fontFamily]="content.qty_styles['font-family']"
                            [style.fontSize]="content.qty_styles['font-size']"/>
                            <button class="btn btn-danger mr-2" style="cursor:pointer;" (click)="addToCart(item)"
                            *ngIf="content.cart_styles===undefined">Add to Cart</button>
                            <button class="mr-2" *ngIf="content.cart_styles!==undefined"
                            [ngStyle]="getFormButtonStyle(content.cart_styles)"
                            [style.textDecoration]="content['cart_styles']['text-decoration']"
                            [style.fontStyle]="content['cart_styles']['font-style']"
                            [style.fontWeight]="content['cart_styles']['font-weight']"
                            [style.color]="content['cart_styles']['color']"
                            [style.backgroundColor]="content['cart_styles']['background-color']"
                            style="cursor:pointer;" (click)="addToCart(item)">Add to Cart</button>
                            <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                                <span class="fas fa-heart" 
                                [style.color]="content['wishlist_styles']['selected-color']"
                                [style.fontSize]="content['wishlist_styles']['font-size']"></span>
                            </a>
                            <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                                <span class="fas fa-heart" 
                                [style.color]="content['wishlist_styles']['unselected-color']"
                                [style.fontSize]="content['wishlist_styles']['font-size']"></span>
                            </a>
                        </div>
                        <div *ngIf="item.isVariant===true" style="display:flex;"
                        [style.marginTop.px]="content.qty_styles['margin-top']"
                        [style.marginBottom.px]="content.qty_styles['margin-bottom']"
                        [style.marginLeft.px]="content.show_styles['margin-left']"
                        [style.marginRight.px]="content.show_styles['margin-right']">
                            <button class="btn btn-info mr-2" style="cursor:pointer;" (click)="showProduct(item)"
                            *ngIf="content.show_styles===undefined">
                                Show Item</button>
                            <button class="mr-2" *ngIf="content.show_styles!==undefined"
                            [ngStyle]="getFormButtonStyle(content.show_styles)"
                            [style.textDecoration]="content['show_styles']['text-decoration']"
                            [style.fontStyle]="content['show_styles']['font-style']"
                            [style.fontWeight]="content['show_styles']['font-weight']"
                            [style.color]="content['show_styles']['color']"
                            [style.backgroundColor]="content['show_styles']['background-color']"
                            style="cursor:pointer;" (click)="showProduct(item)">Show Item</button> 
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'false'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)"
                        [firstText]="'<<'" [previousText]="'<'" [nextText]="'>'" [lastText]="'>>'">
                    </pagination>
                </div>
                <div class="col-12 mt-1 mb-2" *ngIf="!isMobile() && content.TOTAL_RECORDS > 0" style="display:flex;" [ngStyle]="getStyles(content.paging_styles)">
                    <pagination [boundaryLinks]="'true'" [directionLinks]="'true'" [totalItems]="content.TOTAL_RECORDS"
                        [itemsPerPage]="12" [maxSize]="5" (pageChanged)="pageChanged($event,content)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</ng-container>