import { Component, OnInit, AfterViewInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from '../../../services';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CouponsComponent implements OnInit, AfterViewInit {

    @Input() Coupons: any;
    @Input() Apply: any;
    @Output() SelectCoupon: EventEmitter<any> = new EventEmitter<any>();

    constructor(private dataService : DataService, public dialogRef: MatDialogRef<CouponsComponent>) {
          
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {

    }

    applyCoupon(coupon){
        this.SelectCoupon.emit(coupon);
        this.close();
    }

    close() {
      this.dialogRef.close();
    }

}
