import { Component, AfterViewInit, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services';

declare var $: any;

@Component({
	selector: 'app-vertical-navigation',
	templateUrl: './vertical-navigation.component.html',
})
export class VerticalNavigationComponent implements AfterViewInit {
	@Input() options: any;
	@Output() toggleSidebar = new EventEmitter<void>();
	@Output() toggleSettings = new EventEmitter<void>();
	isAdmin: Boolean = false;

	constructor(public router: Router, private modalService: NgbModal, private translate: TranslateService, public authService: AuthService) {
		translate.setDefaultLang('en');
		this.isAdmin = this.authService.currentUserValue?.IsAdmin === 'Yes';
	}

	ngAfterViewInit() {}

	logout() {
		this.authService.logout().subscribe((res: any) => {
			if (res.RESULT_CODE === 1) {
				this.authService.clearLocalSession();
				localStorage.removeItem('filters');
				this.router.navigate(['applogin']);
			}
		});
	}
	changePassword() {
		this.router.navigate(['app/changepassword']);
	}
	openUsers() {
		this.router.navigate(['app/users']);
	}
	openRoles() {
		this.router.navigate(['app/roles']);
	}
}
