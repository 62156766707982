<ng-container *ngIf="content.template==='1'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-xs-12 col-sm-12 col-md-12 float-left mb-1" [ngStyle]="getStyles(content.category_styles)">
                <span class="float-left ml-2">{{content.categoryName}}</span>
                <a class="btn btn-info float-right text-sm mr-2" (click)="showAllProducts(content)" role="button"
                *ngIf="content['button_styles']===undefined">
                    View More <i class="fas fa-arrow-circle-right"></i></a>
                <button class="float-right mr-2" (click)="showAllProducts(content)"
                    [ngStyle]="getFormButtonStyle(content.button_styles)"
                    [style.textDecoration]="content['button_styles']['text-decoration']"
                    [style.fontStyle]="content['button_styles']['font-style']"
                    [style.fontWeight]="content['button_styles']['font-weight']"
                    [style.color]="content['button_styles']['color']"
                    [style.backgroundColor]="content['button_styles']['background-color']"
                    *ngIf="content['button_styles']!==undefined">
                    View More <i class="fas fa-arrow-circle-right"></i></button>
            </div>
            <div class="col-12 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide class="hovercontainer" [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}" 
                    *ngFor="let item of content.items; let index=index">
                        <div class="show-item-options" *ngIf="item.isVariant===false">
                            <a (click)="addToCart(item)" style="cursor:pointer;" class="mr-2" title="Add to Cart">
                                <span class="fas fa-shopping-cart fa-2x text-success"></span>
                            </a>

                            <a (click)="showProduct(item)" style="cursor:pointer;" class="mr-2" title="View Product Info">
                                <span class="fas fa-eye fa-2x text-info"></span>
                            </a>

                            <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                                <span class="fas fa-heart fa-2x" 
                                [style.color]="content['wishlist_styles']['selected-color']"></span>
                            </a>
                            <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                                <span class="fas fa-heart fa-2x" 
                                [style.color]="content['wishlist_styles']['unselected-color']"></span>
                            </a>
                        </div>
                        <div style="cursor:pointer;display:flex;flex-direction: column;" 
                        [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                        (click)="showProduct(item)">
                            <div [ngStyle]="getStyles(content.image_styles)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: cover;" 
                                    [style.height.px]="content.image_styles.height"/>
                                <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                    Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                                </div>
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.product_styles)" 
                                style="cursor:pointer;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                                {{item.productName}}
                            </div>
                            <ng-container *ngIf="item.isVariant===true">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                                *ngIf="item.min_price !== item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                                *ngIf="item.min_price === item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="item.isVariant===false">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                                *ngIf="item.price !== item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                                *ngIf="item.price === item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='2'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-xs-12 col-sm-12 col-md-12 float-left" [ngStyle]="getStyles(content.category_styles)">
                {{content.categoryName}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 mb-1">
                <div style="cursor:pointer;border:0px solid transparent;margin-left:1px;margin-right:1px;"
                    (click)="showAllProducts(content)">
                    <img [src]="content.IMAGE_URL" alt="image slide" 
                        style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                        [style.height.px]="row.styles.height - 170"/>
                    <div class="mt-1 mb-1">
                        <a class="btn btn-info text-white" (click)="showAllProducts(content)" role="button"
                        *ngIf="content['button_styles']===undefined">
                            View More <i class="fas fa-arrow-circle-right"></i></a>
                        <button (click)="showAllProducts(content)"
                            [ngStyle]="getFormButtonStyle(content.button_styles)"
                            [style.textDecoration]="content['button_styles']['text-decoration']"
                            [style.fontStyle]="content['button_styles']['font-style']"
                            [style.fontWeight]="content['button_styles']['font-weight']"
                            [style.color]="content['button_styles']['color']"
                            [style.backgroundColor]="content['button_styles']['background-color']"
                            *ngIf="content['button_styles']!==undefined">
                            View More <i class="fas fa-arrow-circle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-9 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide class="hovercontainer" [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}" *ngFor="let item of content.items; let index=index">
                        <div class="show-item-options" *ngIf="item.isVariant===false">
                            <a (click)="addToCart(item)" style="cursor:pointer;" class="mr-2" title="Add to Cart">
                                <span class="fas fa-shopping-cart fa-2x text-success"></span>
                            </a>
                            
                            <a (click)="showProduct(item)" style="cursor:pointer;" class="mr-2" title="View Product Info">
                                <span class="fas fa-eye fa-2x text-info"></span>
                            </a>

                            <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                                <span class="fas fa-heart fa-2x" 
                                [style.color]="content['wishlist_styles']['selected-color']"></span>
                            </a>
                            <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                                <span class="fas fa-heart fa-2x" 
                                [style.color]="content['wishlist_styles']['unselected-color']"></span>
                            </a>
                        </div>
                        <div style="cursor:pointer;display:flex;flex-direction: column;" 
                        [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                        (click)="showProduct(item)">
                            <div [ngStyle]="getStyles(content.image_styles)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: cover;" 
                                    [style.height.px]="content.image_styles.height"/>
                                <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                    Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                                </div>
                            </div>
                            <ng-container *ngIf="item.isVariant===true">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="item.isVariant===false">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.product_styles)" 
                            style="cursor:pointer;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                            {{item.productName}}
                        </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='3'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-xs-12 col-sm-12 col-md-12 float-left" [ngStyle]="getStyles(content.category_styles)">
                {{content.categoryName}}
            </div>
        </div>
        <div class="row">
            <div class="col-9 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide class="hovercontainer" [ngClass]="{'grow':!item.isVariant,'nohovergrow':item.isVariant}" *ngFor="let item of content.items; let index=index">
                        <div class="show-item-options" *ngIf="item.isVariant===false">
                            <a (click)="addToCart(item)" style="cursor:pointer;" class="mr-2" title="Add to Cart">
                                <span class="fas fa-shopping-cart fa-2x text-success"></span>
                            </a>
                            
                            <a (click)="showProduct(item)" style="cursor:pointer;" class="mr-2" title="View Product Info">
                                <span class="fas fa-eye fa-2x text-info"></span>
                            </a>

                            <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist">
                                <span class="fas fa-heart fa-2x" 
                                [style.color]="content['wishlist_styles']['selected-color']"></span>
                            </a>
                            <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" (click)="addToWishlist(item)" title="Add to Wishlist">
                                <span class="fas fa-heart fa-2x" 
                                [style.color]="content['wishlist_styles']['unselected-color']"></span>
                            </a>
                        </div>
                        <div style="cursor:pointer;display:flex;flex-direction: column;" 
                        [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                        (click)="showProduct(item)">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.product_styles)" 
                                style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                                {{item.productName}}
                            </div>
                            <div [ngStyle]="getStyles(content.image_styles)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                style="cursor:pointer;display: block; width: 100%;object-fit: cover;" 
                                [style.height.px]="content.image_styles.height">
                                <div class="show-discount-item" *ngIf="item.isVariant===false && item.price !== item.discount_Price">
                                    Save {{appData.CurrencySymbol}}{{item.price - item.discount_Price | number:'1.2-2'}}
                                </div>
                            </div>
                            <ng-container *ngIf="item.isVariant===true">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="item.isVariant===false">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="col-3 mb-1">
                <div style="cursor:pointer;border:0px solid transparent;margin-left:1px;margin-right:1px;"
                    (click)="showAllProducts(content)">
                    <img [src]="content.IMAGE_URL" alt="image slide" 
                        style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                        [style.height.px]="row.styles.height - 170"/>
                    <div class="mt-1 mb-1">
                        <a class="btn btn-info text-white" (click)="showAllProducts(content)" role="button"
                        *ngIf="content['button_styles']===undefined">
                            View More <i class="fas fa-arrow-circle-right"></i></a>
                        <button (click)="showAllProducts(content)"
                            [ngStyle]="getFormButtonStyle(content.button_styles)"
                            [style.textDecoration]="content['button_styles']['text-decoration']"
                            [style.fontStyle]="content['button_styles']['font-style']"
                            [style.fontWeight]="content['button_styles']['font-weight']"
                            [style.color]="content['button_styles']['color']"
                            [style.backgroundColor]="content['button_styles']['background-color']"
                            *ngIf="content['button_styles']!==undefined">
                            View More <i class="fas fa-arrow-circle-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='4'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-12 mb-1" [ngStyle]="getStyles(content.category_styles)">
                <span class="float-left ml-2">{{content.categoryName}}</span>
                <a class="btn btn-info float-right text-sm mr-2" (click)="showAllProducts(content)" role="button"
                *ngIf="content['button_styles']===undefined">
                    View More <i class="fas fa-arrow-circle-right"></i></a>
                <button class="float-right mr-2" (click)="showAllProducts(content)"
                    [ngStyle]="getFormButtonStyle(content.button_styles)"
                    [style.textDecoration]="content['button_styles']['text-decoration']"
                    [style.fontStyle]="content['button_styles']['font-style']"
                    [style.fontWeight]="content['button_styles']['font-weight']"
                    [style.color]="content['button_styles']['color']"
                    [style.backgroundColor]="content['button_styles']['background-color']"
                    *ngIf="content['button_styles']!==undefined">
                    View More <i class="fas fa-arrow-circle-right"></i></button>
            </div>
            <div class="col-12 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide *ngFor="let item of content.items; let index=index">
                        <div style="cursor:pointer;display:flex;flex-direction: column;" 
                        [ngStyle]="getStylesMain(content.slide_styles,content.styles)">
                            <div [ngStyle]="getStyles(content.image_styles)" (click)="showProduct(item)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: cover;" 
                                    [style.height.px]="content.image_styles.height">
                            </div>
                            <div [ngStyle]="getStyles(content.product_styles)" (click)="showProduct(item)"
                                style="cursor:pointer;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                                {{item.productName}}
                            </div>
                            <ng-container *ngIf="item.isVariant===true">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price !== item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}} - {{appData.CurrencySymbol}} {{item.max_price | number:'1.2-2'}}
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.min_price === item.max_price">
                                    {{appData.CurrencySymbol}} {{item.min_price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="item.isVariant===false">
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price !== item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" *ngIf="item.price === item.discount_Price">
                                    {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                                </div>
                            </ng-container>
                            <div *ngIf="item.isVariant===false" style="display: flex;"
                            [style.marginTop.px]="content.qty_styles['margin-top']"
                            [style.marginBottom.px]="content.qty_styles['margin-bottom']"
                            [style.marginLeft.px]="content.qty_styles['margin-left']"
                            [style.marginRight.px]="content.qty_styles['margin-right']">
                                <input class="input-qty mr-2" type="number" placeholder="Qty" [(ngModel)]="item.qty"
                                [style.color]="content['qty_styles']['color']"
                                [style.backgroundColor]="content['qty_styles']['background-color']"
                                [style.borderRadius.px]="content['qty_styles']['border-radius']"
                                [style.borderWidth.px]="content['qty_styles']['border-width']"
                                [style.borderColor]="content['qty_styles']['border-color']"                                
                                [style.fontFamily]="content.qty_styles['font-family']"
                                [style.fontSize]="content.qty_styles['font-size']"/>
                                <button class="btn btn-danger mr-2" style="cursor:pointer;" (click)="addToCart(item)"
                                *ngIf="content.cart_styles===undefined">Add to Cart</button>
                                <button class="mr-2" *ngIf="content.cart_styles!==undefined" (click)="addToCart(item)"
                                    [ngStyle]="getFormButtonStyle(content.cart_styles)"
                                    [style.textDecoration]="content['cart_styles']['text-decoration']"
                                    [style.fontStyle]="content['cart_styles']['font-style']"
                                    [style.fontWeight]="content['cart_styles']['font-weight']"
                                    [style.color]="content['cart_styles']['color']"
                                    [style.backgroundColor]="content['cart_styles']['background-color']"
                                    style="cursor:pointer;">Add to Cart</button>                                    
                                <a *ngIf="InWishlist(item.variantId)" style="cursor:pointer;" 
                                (click)="removeFromWishlist(item.variantId)" title="Remove from Wishlist"
                                [style.paddingTop.px]="content['cart_styles']['padding-top']">
                                    <span class="fas fa-heart" 
                                    [style.color]="content['wishlist_styles']['selected-color']"
                                    [style.fontSize]="content['wishlist_styles']['font-size']"></span>
                                </a>
                                <a *ngIf="!InWishlist(item.variantId)" style="cursor:pointer;" 
                                (click)="addToWishlist(item)" title="Add to Wishlist"
                                [style.paddingTop.px]="content['cart_styles']['padding-top']">
                                    <span class="fas fa-heart" 
                                    [style.color]="content['wishlist_styles']['unselected-color']"
                                    [style.fontSize]="content['wishlist_styles']['font-size']"></span>
                                </a>
                            </div>
                            <div *ngIf="item.isVariant===true" style="display: flex;" 
                            [style.marginTop.px]="content.qty_styles['margin-top']"
                            [style.marginBottom.px]="content.qty_styles['margin-bottom']"
                            [style.marginLeft.px]="content.show_styles['margin-left']"
                            [style.marginRight.px]="content.show_styles['margin-right']" >
                                <button class="btn btn-info" style="cursor:pointer;" (click)="showProduct(item)"
                                *ngIf="content.show_styles===undefined">Show Item</button>
                                <button *ngIf="content.show_styles!==undefined" (click)="showProduct(item)"
                                    [ngStyle]="getFormButtonStyle(content.show_styles)"
                                    [style.textDecoration]="content['show_styles']['text-decoration']"
                                    [style.fontStyle]="content['show_styles']['font-style']"
                                    [style.fontWeight]="content['show_styles']['font-weight']"
                                    [style.color]="content['show_styles']['color']"
                                    [style.backgroundColor]="content['show_styles']['background-color']"
                                    style="cursor:pointer;">Show Item</button>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-container>