import { Component, OnInit, Input, Injector, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EcommerceService, AuthService } from '../../../services';
import { EnvService } from '../../../../environments/env.service';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
  selector: 'app-webregister',
  templateUrl: './webregister.component.html',
  styleUrls: ['./webregister.component.scss']
})
export class WebregisterComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  clientkey: string = '';
  appUrl: string = '';
  registerForm: FormGroup;
  @Input() content: any;
  @Input() websiteName: string;

  constructor(private environment:EnvService, private authService:AuthService, 
    private dataService:EcommerceService, public router: Router, public injector: Injector,
    public pageFunctions: PageFunctions, fb: FormBuilder, private toastr: ToastrService) { 
    localStorage.setItem('appurl', environment.appUrl);
    
    this.appUrl = environment.appUrl;
    this.clientkey = environment.clientKey;
    this.registerForm = fb.group({
        'clientkey': [this.clientkey],
        'firstName': [null, Validators.compose([Validators.required])],
        'lastName': [null, Validators.compose([Validators.required])],
        'mobile': [null, Validators.compose([Validators.required])],
        'email': [null, Validators.compose([Validators.required])],
        'password': [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.router = this.injector.get(Router);

    if (this.content.header_styles === undefined){
      this.content['header_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "24px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none"
      };
    }
    if (this.content['input_styles'] === undefined) {
      this.content['input_styles'] = {
        'border-top':0,
        'border-right':0,
        'border-bottom':0,
        'border-left':0,
        'border-style':'',
        'position':'',
        'border-color':'',
        'border-top-left-radius':0,
        'border-top-right-radius':0,
        'border-bottom-left-radius':0,
        'border-bottom-right-radius':0
      };
    }
    if (this.content['btn_styles'] === undefined) {
      this.content['btn_styles'] = {
        'color':'#fff',
        'background-color':'#5d9cec',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'center',
        'padding-top':10,
        'padding-right':10,
        'padding-bottom':10,
        'padding-left':10,
        'border-width':0,
        'border-style':'',
        'border-color':'',
        'border-radius':0          
      };
    }
    if (this.content['btn_styles']['text-align'] === undefined) {
			this.content['btn_styles']['text-align'] = "center";
		}   
  }

  getInputStyle(content, obj){
		var style: any = {};

    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}

    if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    else{
      style["text-decoration"] = "none";
    }
		
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

    if(obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)' ){
      this.pageFunctions.setBorder(style,obj,true);
    }
    
    this.pageFunctions.serBorderRadius(style, obj);

		return style;
	}

  getFormButtonStyle(obj){
		var style: any = {};

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		this.pageFunctions.setPadding(style,obj);

		if (obj['border-width'] !== null && obj['border-width'] !== undefined) {
			if (obj['border-style'] !== null && obj['border-style'] !== undefined && obj['border-style'] !== "") {
				if (obj['border-color'] !== null && obj['border-color'] !== undefined && obj['border-color'] !== "")
					style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		return style;
	}

  submitRegForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.registerForm.controls) {
        this.registerForm.controls[c].markAsTouched();
    }
    if (this.registerForm.valid) {
      this.authService.register(value.firstName, value.lastName, value.mobile, value.email, value.password)
      .pipe(first())
      .subscribe(data => {
        if(data['RESULT_CODE'] === 1){
          this.registerForm.reset();
          if (data['VERIFICATION_REQUIRED']==='0'){
            this.toastr.success('Registration successfull. You can login and continue..','Success');
            setTimeout(() => 
            {
              this.router.navigate([this.dataService.AppData.LoginPage]);
            }, 1500);
          }
          else{
            this.toastr.success('Verification Email has been sent. Kindly verify.','Success');
          }
          //this.router.navigate([this.dataService.AppData.LoginPage]);
        }
        else if(data['RESULT_CODE'] === 0){
          this.toastr.error('Mobile No / Email Id already in use.','Error');
        }
        else
        {
          this.toastr.error('Something went wrong.. try after sometime !!','Error');
        }
      },
      error => {
        this.toastr.error('Incorrect username or password','Error');
      });
    }
    else {
      this.toastr.info('Mandatory Fields not entered','Information');
    }
  }

  getStyles(obj) {
		var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}
 
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}
 
		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    else{
      style["text-decoration"] = "none";
    }

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}
    
		return style;
	}

}
