import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BookingService } from '../../../../services';
import { BookingChargesComponent } from '../bookingcharges/bookingcharges.component';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { EnvService } from '../../../../../environments/env.service';
import { ExternalLibraryService } from '../../../directives/util';

declare var Razorpay: any;
//declare var bolt: any;

@Component({
  selector: 'app-bookingorder',
  templateUrl: './bookingorder.component.html',
  styleUrls: ['./bookingorder.component.scss']
})
export class BookingorderComponent implements OnInit {
  order: any = {};
  products: any = [];
  additionalServices: any = [];
  scoreUrl: string = "";
  showDownload: boolean = false;
  showCancellation: boolean = false;
  showReviewDiv: boolean = false;
  showScore: boolean = false;
  valForm: FormGroup;
  rating: number = 1;
  reviewedProduct: any = {};
  paymentOptions: any = {};
  selectedPayment: string = "";
  resheduleAmount: number = 0;
  latebookingAmount: number = 0;
  totalAmount: number = 0;
  website_logo: string = "";
  gateways: any = [];
  userData: any = {};
  paymentResponse: any = {};

  constructor(public dataService: BookingService, fb: FormBuilder, private toastr: ToastrService, 
    public environment:EnvService, public extService: ExternalLibraryService,
    public dialog: MatDialog, public dialogRef: MatDialogRef<BookingorderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.order = data;
      if (this.order['INVOICE_URL'] !== "" && this.order['INVOICE_URL'] !== null){
        this.showDownload = true;
      }

      if (this.order['SCORE_URL'] !== "" && this.order['SCORE_URL'] !== null){
        this.scoreUrl = this.order['SCORE_URL'];
        this.showScore = true;
      }

      //SCORE_AVAILABLE

      this.getGateways();
      this.getUserInfo();
      this.getOrderDetails();
      this.getPaymentOptions();
      this.getAdditionalServicesBooked()
      
      this.valForm = fb.group({
        ORDER_ID: [this.order.ID],
        PRODUCT_ID: [""],
        PRODUCT_NAME: [""],
        RATING: [1],
        HEADLINE: ["", Validators.required],
        REVIEW: ["", Validators.required]
      });
  }

  getGateways(){
    this.dataService.getGateways().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.gateways = res['PG'];
      }
    });
  }

  getUserInfo(){
    this.dataService.getCustomerProfile().subscribe(res=>{
      if (res.RESULT_CODE===1){
        delete res.RESULT_CODE;
        this.userData = res;  
      }
    });
  }

  getPaymentOptions(){
    this.dataService.getPaymentOptions().subscribe(res=>{
      if (res.RESULT_CODE === 1){
        this.paymentOptions = res;
        var country = this.gateways.filter(pg=>pg.CODE===res['COUNTRY']);
        if (country.length > 0){
          if (!country[0]['PG'].includes("Stripe")){
            this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("RazorPay")){
            this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("PayPal")){
            this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("MVola")){
            this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Airtel")){
            this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          }
          if (!country[0]['PG'].includes("Orange")){
            this.paymentOptions['IS_ORANGE_ACTIVE']='0';
          }
        }
        else{
          this.paymentOptions['IS_STRIPE_ACTIVE']='0';
          this.paymentOptions['IS_RAZOR_PAY_ACTIVE']='0';
          this.paymentOptions['IS_PAYPAL_ACTIVE']='0';
          this.paymentOptions['IS_MVOLA_ACTIVE']='0';
          this.paymentOptions['IS_AIRTEL_ACTIVE']='0';
          this.paymentOptions['IS_ORANGE_ACTIVE']='0';
        }         
      }
    });
  }

  showAddlServiceHeader(item){
    return item.ADDITIONAL_SERVICES.filter(service=>service['Selected']===true).length > 0;
  }

  showScorePage(item){
    var url = this.scoreUrl.replace('#testid#', item['ID']);
    window.open(url, '_blank');
  }

  getOrderDetails(){
    this.dataService.getOrderDetails(this.order.ID).subscribe((res : any)=>{
      if (res.RESULT_CODE===1){
        this.products = res.DATA;
        this.products.forEach(product => {
          if (product.ADDITIONAL_SERVICES!==undefined){
            product.ADDITIONAL_SERVICES = JSON.parse(product.ADDITIONAL_SERVICES);
            product.ADDITIONAL_SERVICES.forEach(item=>{
              if (item.QTY===undefined){
                item['QTY']=1;
                item['AMOUNT']=item['SERVICE_CHARGE'];
              }
            });
          }
          else
          product.ADDITIONAL_SERVICES = [];
        });
      }
    });
  }

  getAdditionalServicesBooked(){
    this.dataService.getOrderAdditionalServices(this.order.ID).subscribe((res : any)=>{
      if (res.RESULT_CODE===1){
        this.additionalServices = res.DATA;
      }
    });
  }

  addServices(item){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    dialogConfig.data = {"WEBSITE_ID":this.order['WEBSITE_ID'],"ORDER_ID":this.order['ID'],
      "DETAIL_ID":item['ID'],"SERVICE_ID":item['PRODUCT_ID'],"VARIANT_ID":item['VARIANT_ID']};

    const dialogRef = this.dialog.open(BookingChargesComponent, dialogConfig);
  }

  cancelOrder(){
    this.dataService.cancelOrder(this.order.ID).subscribe((res : any)=>{
      if (res.RESULT_CODE===1){
        this.order['ORDER_STATUS'] = "Cancelled";
        this.showCancellation = false;
        this.toastr.success("Order cancelled successfully..","Success");
      }
      else{
        this.toastr.info("Unable to cancel the Order..","Information");
      }
    });
  }

  reschedulestatus(item){
    this.toastr.info("Your Reschedule payment status is pending..","Information");
  }

  reschedule(item){
    item['SHOW_NO_SLOTS'] = false;
    item['CALENDAR']=[];
    item['NEW_SLOT_ID']="";
    item['RESCHEDULE'] = true;
    item['PROCESSING'] = true;
    this.getServiceDates(item);
  }

  getServiceDates(item){
    this.dataService.getServiceDates(this.order['WEBSITE_ID'],item['PRODUCT_ID'],item['VARIANT_ID'])
    .subscribe((res : any)=>{
      item['PROCESSING'] = false;
      if (res.RESULT_CODE===1){
        item['CALENDAR'] = res.DATA;
        if (item['CALENDAR'].length === 0){
          item['SHOW_NO_SLOTS'] = true;
        }
      };
    });
  }

  calcCharge(item){
    this.resheduleAmount = 0;
    this.latebookingAmount = 0;
    this.totalAmount = 0;

    var selectedItem = item.CALENDAR.filter(slot=>slot.ID===item['NEW_SLOT_ID'])
    if (selectedItem.length > 0){
      if (selectedItem[0].IS_BOOKING_ALLOWED===1){
        this.resheduleAmount = item.RESCHEDULE_CHARGE;
        this.latebookingAmount = 0;
      }
      else if (selectedItem[0].IS_BOOKING_ALLOWED===0 && selectedItem[0].IS_BOOKING_ALLOWED_LC===1){
        this.resheduleAmount = item.RESCHEDULE_CHARGE;
        this.latebookingAmount = item.LATE_BOOKING_CHARGES;
      }
      this.totalAmount = this.resheduleAmount + this.latebookingAmount;
    }
  }

  updateReschedule($event,item){
    $event.preventDefault();
    if (item['NEW_SLOT_ID']!=="" && this.selectedPayment!==""){
      let currency = this.dataService.AppBookingData.CurrencyCode;
      let successUrl = this.dataService.AppBookingData.SuccessPage;
      let failureUrl = this.dataService.AppBookingData.FailurePage;

      item['PROCESSING'] = true;
      this.dataService.checkBookingAllowed(this.order['WEBSITE_ID'], item.ID, item['VARIANT_ID'],this.order.ID)
      .subscribe((res : any)=>{
        if (res.RESULT_CODE===1 && res.IS_BOOKING_ALLOWED===1){
          this.dataService.rescheduleBookingSlot(this.order['WEBSITE_ID'],this.order.ID,item.ID,item['VARIANT_ID'],item['NEW_SLOT_ID'],this.resheduleAmount,this.latebookingAmount,this.totalAmount, this.selectedPayment, currency,successUrl,failureUrl)
          .subscribe((res1 : any)=>{
            item['PROCESSING'] = false;
            item['RESCHEDULE'] = false;
            if (res1.RESULT_CODE===1){
              
              this.website_logo = res1['SITE_LOGO'];
              res1['ORDER_ID']=this.order.ID;
              res1['ORDER_AMT']=this.totalAmount;

              if (this.selectedPayment === 'Stripe'){
                if (res1['checkout_url'] !== undefined && res1['checkout_url'] !== null && res1['checkout_url'] !== ""){
                  document.location.href = res1['checkout_url'];
                }
                else{
                  if (res1['message'] !== undefined && res1['message'] !== null && res1['message'] !== ""){
                    this.toastr.error(res1['message'],'Error');
                  }
                  else{
                    this.toastr.error('Something went wrong.. try after sometime !!','Error');
                  }
                }
              }
              else if (this.selectedPayment === 'PayPal'){
                if (res1['checkout_url'] !== undefined && res1['checkout_url'] !== null && res1['checkout_url'] !== ""){
                  document.location.href = res1['checkout_url'];
                }
                else{
                    this.toastr.error('Something went wrong.. try after sometime !!','Error');
                }
              }
              else if (this.selectedPayment === 'RazorPay'){
                var rzp1 = new Razorpay(this.preparePaymentDetails(res1, this.userData, this.totalAmount));
                rzp1.open(); 
              }                
              else{
                this.toastr.success("Booking rescheduled successfully..","Success");
                this.getOrderDetails();
              }
            }
            else{
              this.toastr.error("Unable to reschedule. Please try again later..",'Error');
            }
          });
        }
        else{
          item['PROCESSING'] = false;
          var msg = "Service date should be greater than " + res['ALLOWED_DATE'];
          this.toastr.info(msg,'Cannot Reschedule');
        }
      });
    }
    else{
      if (item['NEW_SLOT_ID']===undefined || item['NEW_SLOT_ID']===""){
        this.toastr.info("Slot not selected.",'Cannot Reschedule');
      }
      else if (this.selectedPayment===""){
        this.toastr.info("Pay option not selected.",'Cannot Reschedule');
      }
    }
  }

  showReview(item){
    this.showReviewDiv = true;
    this.valForm.controls['ORDER_ID'].setValue(this.order.ID);
    this.valForm.controls['PRODUCT_ID'].setValue(item.PRODUCT_ID);
    this.valForm.controls['PRODUCT_NAME'].setValue(item.PRODUCT_NAME);
    this.valForm.controls['RATING'].setValue(1);
    this.reviewedProduct = item;
  }

  updateReview($event){
    $event.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.valForm.controls['RATING'].setValue(this.rating);
      this.dataService.insertReview(
        this.valForm.controls['ORDER_ID'].value,
        this.valForm.controls['PRODUCT_ID'].value,
        this.valForm.controls['RATING'].value,
        this.valForm.controls['HEADLINE'].value,
        this.valForm.controls['REVIEW'].value
      ).subscribe(resp=>{
        if (resp.RESULT_CODE===1){
          this.toastr.success("Review updated successfully.. Thank you for your review.","Success");
          this.reviewedProduct['RATING'] = this.rating;
          this.valForm.reset();
          this.showReviewDiv = false;
        }
        else{
          this.toastr.error("Something went wrong.. Please try again later.",'Error');
        }
      })
    }
  }

  download(){
    window.open("https://" + this.order['INVOICE_URL'],"_blank");
  }

  preparePaymentDetails(order, userinfo, orderAmt){
    var ref = this;
    return  {
      "key": this.paymentOptions.RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      "amount": orderAmt * 100, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      "name": this.environment.websiteTitle,
      "currency": 'INR',
      "order_id": order.RAZOR_ORDER_ID,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
      "image": this.website_logo,
      "modal": {
        escape: false,
      },
      "handler": function (response){
        ref.handlePayment(response,'RazorPay',userinfo, order);
      },
      "modal.ondismiss": function(){
        ref.dismissedPayment();
      },
      "prefill": {
          "name": userinfo.FIRST_NAME + ' ' + userinfo.LAST_NAME,
          "email": userinfo.EMAIL,
          "contact": userinfo.MOBILE
      },
      "theme": {
          "color": "#F28500"
      }
    };
  }

  handlePayment(response, paymentGateway, userInfo, orderInfo) {
    var paymentId: string = '';
    var respSignature: string = '';
    var respStatus: string = '';

    if (paymentGateway==='RazorPay'){
      paymentId = response.razorpay_payment_id;
      respSignature = response.razorpay_signature;
      respStatus = 'success';
    }
    // else{
    //   paymentGateway = 'PayU';
    //   paymentId = response.payuMoneyId;
    //   respSignature = response.bank_ref_num + '|' + response.hash;
    //   respStatus = response.txnStatus;
    // }
    this.dataService.verifyPayment(paymentGateway, orderInfo.ID, 'RESCHEDULE', orderInfo.ORDER_AMT,
      paymentId, respSignature, userInfo.MOBILE, userInfo.EMAIL, JSON.stringify(response)
    ).subscribe(res => {
      if (res['status'].toLowerCase() === 'success' ){
        document.location.href = orderInfo['success_url'];
      }
      else{
        this.toastr.error('Reschedule Payment failed.... cannot process order','Error');
      }
    },
    error => {
      this.paymentResponse = error;
    });
  }

  dismissedPayment(){}

  ngOnInit(): void {
    this.extService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js', false)
    .subscribe();

    //this.extService
    //.lazyLoadLibrary('https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js', true)
    //.subscribe();  
  }

  close() {
    this.dialogRef.close();
  }
}
