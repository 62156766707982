import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EcommerceService } from '../../../services';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customerorder',
  templateUrl: './customerorder.component.html',
  styleUrls: ['./customerorder.component.scss']
})
export class CustomerorderComponent implements OnInit {
  order: any = {};
  products: any = [];
  showDownload: boolean = false;
  showCancellation: boolean = false;
  showReviewDiv: boolean = false;
  valForm: FormGroup;
  rating: number = 1;
  reviewedProduct: any = {};

  constructor(public dataService: EcommerceService, fb: FormBuilder, private toastr: ToastrService,
    public dialogRef: MatDialogRef<CustomerorderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.order = data;
      if (this.order['INVOICE_URL'] !== "" && this.order['INVOICE_URL'] !== null){
        this.showDownload = true;
      }

      if (this.order['PAYMENT_STATUS'] !== "Cancelled"
          && (this.order['ORDER_STATUS'] === "Pending" 
          || this.order['ORDER_STATUS'] === "Processing" 
          || this.order['ORDER_STATUS'] === "Ready For Packing")){
        this.showCancellation = true;
      }

      this.getOrderDetails();
      
      this.valForm = fb.group({
        ORDER_ID: [this.order.ID],
        PRODUCT_ID: [""],
        PRODUCT_NAME: [""],
        RATING: [1],
        HEADLINE: ["", Validators.required],
        REVIEW: ["", Validators.required]
      });
  }

  getOrderDetails(){
    this.dataService.getOrderDetails(this.order.ID).subscribe((res : any)=>{
      if (res.RESULT_CODE===1){
        this.products = res.DATA;
      }
    });
  }

  cancelOrder(){
    this.dataService.cancelOrder(this.order.ID).subscribe((res : any)=>{
      if (res.RESULT_CODE===1){
        this.order['ORDER_STATUS'] = "Cancelled";
        this.showCancellation = false;
        this.toastr.success("Order cancelled successfully..","Success");
      }
      else{
        this.toastr.info("Unable to cancel the Order..","Information");
      }
    });
  }

  showReview(item){
    this.showReviewDiv = true;
    this.valForm.controls['ORDER_ID'].setValue(this.order.ID);
    this.valForm.controls['PRODUCT_ID'].setValue(item.PRODUCT_ID);
    this.valForm.controls['PRODUCT_NAME'].setValue(item.PRODUCT_NAME);
    this.valForm.controls['RATING'].setValue(1);
    this.reviewedProduct = item;
  }

  updateReview($event){
    $event.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.valForm.controls['RATING'].setValue(this.rating);
      this.dataService.insertReview(
        this.valForm.controls['ORDER_ID'].value,
        this.valForm.controls['PRODUCT_ID'].value,
        this.valForm.controls['RATING'].value,
        this.valForm.controls['HEADLINE'].value,
        this.valForm.controls['REVIEW'].value
      ).subscribe(resp=>{
        if (resp.RESULT_CODE===1){
          this.toastr.success("Review updated successfully.. Thank you for your review.","Success");
          this.reviewedProduct['RATING'] = this.rating;
          this.valForm.reset();
          this.showReviewDiv = false;
        }
        else{
          this.toastr.error("Something went wrong.. Please try again later.",'Error');
        }
      })
    }
  }

  download(){
    window.open("https://" + this.order['INVOICE_URL'],"_blank");
  }

  downloadFile(item){
    window.open(item['DIGITAL_FILE_URL'],"_blank");
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
