import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService, AuthService } from '../services';
import { EnvService } from '../../environments/env.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { LookupComponent } from '../shared/lookup/lookup.component';
import { AttachmentComponent } from '../shared/attachment/attachment.component';
import { TextDialogComponent } from '../shared/textdialog/textdialog.component';
import { PageFunctions } from '../shared/directives/PageFunctions';
// import SignaturePad from 'signature_pad';
declare var $: any;

@Component({
	selector: 'app-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, AfterViewInit {
	@ViewChildren('myGrid') myGrid: QueryList<jqxGridComponent>;
	oper: string = 'ADD';
	TABLE_KEY: string = 'Table';
	screenName: string = '';
	publishName: string = '';
	screen_sections: any = [];
	section_attributes: any = [];
	externalFunctions: string = '';
	staticValues: any = {};
	lookups: any = {};
	dataobjects: any = [];
	Id: string = '';
	primaryId: string = '';
	WebsiteId: string = '';
	ReportId: string = '';
	rows: any = [];
	page: any = {};
	pageValues: any = {};
	mvSectionValues: any = {};
	attachments: any = {};
	image_attachments: any = {};
	grid: any = {};
	dd: any = {};
	addMVRow: any = {};
	addGridRow: any = {};
	gridColumns: any = {};
	mvSectionColumns: any = {};
	mvSectionDatas: any = {};
	formHeight: number = 0;
	is_Static_Page: boolean = true;
	loading: boolean = true;
	isSaving: boolean = false;
	isConfigReady: boolean = false;
	autoNumberFields: any = [];
	lookup: any = { Lookup_ID: '', Lookup_Name: '', Lookup_Type: '', InputParams: '', OutfillParams: '', GridRef: '', ExpressionID: '', dataRowID: '', Section_Fields: [] };
	attachment: any = { HeaderName: '', PageID: '', TableID: '', FieldID: '', TransID: '', CtrlName: '', DataType: '', Display_Image: false, MVSectionID: -1, MaxWidth: 0, MaxHeight: 0, MaxSize: 0 };
	window_width: any = 0;
	screen_rules: any = [];
	expressions: any = [];
	showSubmit: boolean = false;
	screen_props: any = { allowSave: true, NewMode: true, EditMode: true, DeleteMode: true };
	monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
	saveTried: boolean = false;
	isAppleDevice: boolean = false;
	currentUser: any = {};
	currentDate: string = '';
	sessionInfo: any = {};
	pk_error_message: string = '';
	multiSelectSettings: any = {
		singleSelection: false,
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		itemsShowLimit: 3,
		allowSearchFilter: true,
	};

	constructor(public pageFunctions: PageFunctions, public router: Router, public dataService: DataService, public envService: EnvService, public authService: AuthService, public dialog: MatDialog, private route: ActivatedRoute, public toastr: ToastrService, private sanitizer: DomSanitizer) {
		this.window_width = window.innerWidth;

		var ua = navigator.userAgent;
		if (/iPhone|iPad/i.test(ua)) {
			this.isAppleDevice = true;
		}

		this.currentUser = this.authService.currentUserValue;

		this.route.queryParams.subscribe((params) => {
			this.Id = params.pageid;
			this.primaryId = params.id;

			if (this.primaryId !== '0') {
				this.oper = 'EDIT';
			} else {
				this.oper = 'ADD';
				this.primaryId = Guid.create().toString();
			}
			this.getSessionInfo();
			this.getPageData(this.Id);
		});

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		this.page = {
			styles: {
				'text-align': 'center',
				'justify-content': 'center',
				'background-color': 'transparent',
				background: {
					url: '',
					fill: 1,
					repeat: 0,
					center: 1,
				},
				'padding-right': 0,
				'padding-left': 0,
				'padding-top': 0,
				'padding-bottom': 0,
				'font-family': 'Roboto',
			},
		};

		if (!this.isStorageExists()) {
			this.toastr.error('Please enable Storage / Cookie support..', 'Error');
		}
	}

	isStorageExists() {
		try {
			if (window.localStorage !== undefined && window.localStorage !== null) return true;
		} catch (e) {
			return false;
		}
	}

	onItemSelect(item: any) {}
	onSelectAll(items: any) {}

	getSessionInfo() {
		this.dataService.getSessionInfo().subscribe((res: any) => {
			this.sessionInfo = res;
		});
	}

	getPageData(id) {
		this.loading = true;

		var designType = 'DESKTOP';

		if (this.window_width < 700) {
			designType = 'MOBILE';
		} else if (this.window_width < 1025) {
			designType = 'TABLET';
		}

		document.title = this.envService.websiteTitle;

		this.dataService.getScreen(id, '', designType).subscribe((res: any) => {
			this.Id = res['ID'];
			this.ReportId = res['Report_ID'];
			this.WebsiteId = res['Website_ID'];
			this.screenName = res['Screen_Name'];
			this.publishName = res['URL'];
			this.screen_sections = res['SECTIONS'];
			this.is_Static_Page = res['Is_Static'] === '1';
			this.pk_error_message = res['PK_ERROR_MESSAGE'];
			this.externalFunctions = res['External_Functions'];

			if (res['Page_Title'] !== undefined && res['Page_Title'] !== '' && res['Page_Title'] !== null) {
				document.title = this.envService.websiteTitle + ' | ' + res['Page_Title'];
			}

			var designConfig: any;
			var designAvailable: boolean = false;

			if (designType === 'DESKTOP') {
				if (res['DESIGN_CONFIGS']) {
					designConfig = res['DESIGN_CONFIGS'];
					designAvailable = true;
				}
			} else if (designType === 'MOBILE') {
				if (res['MOBILE_DESIGN_CONFIGS']) {
					designConfig = res['MOBILE_DESIGN_CONFIGS'];
					designAvailable = true;
				} else if (res['DESIGN_CONFIGS']) {
					designConfig = res['DESIGN_CONFIGS'];
					designAvailable = true;
				}
			} else if (designType === 'TABLET') {
				if (res['TABLET_DESIGN_CONFIGS']) {
					designConfig = res['TABLET_DESIGN_CONFIGS'];
					designAvailable = true;
				} else if (res['DESIGN_CONFIGS']) {
					designConfig = res['DESIGN_CONFIGS'];
					designAvailable = true;
				}
			}

			if (designAvailable) {
				designConfig = designConfig.replaceAll('col-lg-', 'column-');
				designConfig = designConfig.replaceAll('col-', 'column-');

				var design_configs = JSON.parse(designConfig);
				this.rows = design_configs['rows'];
				this.page = design_configs['page'];

				if (this.page.styles['page-width'] === undefined || this.page.styles['page-width'] === null || this.page.styles['page-width'] === '' || parseInt(this.page.styles['page-width']) === 0) {
					this.page.styles['page-width'] = 1366;
				}

				setTimeout(() => {
					this.setVisibleRows('');
				}, 1000);

				if (design_configs['expressions'] !== undefined) {
					this.expressions = design_configs['expressions'];
				}

				this.showSubmit = designConfig.includes('SaveButton');
			}

			this.getPageRules(this.Id);

			this.initializeData(res['ID']);

			this.isConfigReady = true;
			this.loading = false;
		});
	}

	setScreenProps(screen) {
		this.screen_props.allowSave = !(screen.EditMode === '0' && this.oper === 'EDIT');
		this.screen_props.EditMode = screen.EditMode !== '0';
		this.screen_props.NewMode = screen.NewMode !== '0';
		this.screen_props.DeleteMode = screen.DeleteMode !== '0';
	}

	getPageRules(id) {
		this.dataService.getDBScreenRules(id).subscribe((res: any) => {
			this.screen_rules = res.DATA;
			var disableSaveRule = this.screen_rules.filter((rule) => rule.ACTION === 'DISABLE_SAVE');
			if (disableSaveRule.length > 0 && this.oper === 'EDIT') {
				this.checkDisableSave(disableSaveRule[0].RULE_SQL.replaceAll('#ID#', this.primaryId));
			}
		});
	}

	checkDisableSave(sql) {
		this.dataService.viewOnlyCheck(sql).subscribe((res) => {
			if (res.RESULT_CODE === 1 && res.DATA.length > 0) {
				this.screen_props.allowSave = false;
			}
		});
	}

	reloadPage() {
		this.router.navigate(['/page?pageid=' + this.Id + '&id=' + this.primaryId]);
	}

	updateSVSectionData() {
		var tables: any = [];
		this.screen_sections.forEach((section) => {
			if (section.Section_Type === 'Single') {
				var tableExist = tables.filter((table) => table === section.Table_Name).length;
				if (tableExist === 0) {
					tables.push(section.Table_Name);
					this.dataService.getSVData(section.Table_Name, this.primaryId, section.is_Primary_Table).subscribe((result) => {
						var SVFields = this.section_attributes.filter((attr) => attr['Table_Name'] === section.Table_Name);
						SVFields.forEach((field) => {
							if (field.DataType === 'BIT') {
								this.pageValues[field['Field_ID']] = result[field['Data_Field_Name']] === 1 ? true : false;
							} else {
								this.pageValues[field['Field_ID']] = result[field['Data_Field_Name']];
							}

							if ((this.pageValues[field['Field_ID']] === null || this.pageValues[field['Field_ID']] === '') && field.DefaultValue !== null && field.DefaultValue !== '') {
								if (field.DefaultValue === 'USER_NAME') {
									this.pageValues[field['Field_ID']] = this.sessionInfo['USER_NAME'];
								} else if (field.DefaultValue === 'USER_EMAIL' || field.DefaultValue === 'EMAIL_ADDRESS') {
									this.pageValues[field['Field_ID']] = this.sessionInfo['EMAIL_ADDRESS'];
								} else if (field.DefaultValue === 'USER_ID') {
									this.pageValues[field['Field_ID']] = this.sessionInfo['USER_ID'];
								} else if (field.DefaultValue === 'TODAY') {
									if (field.DataType === 'DATE') this.pageValues[field['Field_ID']] = this.getCurrentDate();
									else this.pageValues[field['Field_ID']] = this.getCurrentDateTime();
								} else {
									if (this.sessionInfo[field.DefaultValue] !== undefined) {
										this.pageValues[field['Field_ID']] = this.sessionInfo[field.DefaultValue];
									} else {
										this.pageValues[field['Field_ID']] = field.DefaultValue;
									}
								}
							}

							if (field['is_Static_Value'] === 1 && field['is_MultiSelect'] === 1) {
								if (this.pageValues[field['Field_ID']] !== null && this.pageValues[field['Field_ID']] !== '') {
									this.pageValues[field['Field_ID']] = this.pageValues[field['Field_ID']].split(',');
								} else {
									this.pageValues[field['Field_ID']] = [];
								}
							}
						});
					});
				}
			}
		});
	}

	updateMVSectionData() {
		let idx = 0;
		const tables = this.rows.reduce((acc, row) => {
			return acc.concat(
				row.columns.reduce((innerAcc, column) => {
					return innerAcc.concat(column.contents.filter((content) => content.type === 'MVSection'));
				}, [])
			);
		}, []);

		tables.forEach((content) => {
			content['index'] = idx;
			this.mvSectionColumns[idx] = { fields: content['form']['fields'] };

			if (this.addMVRow[idx] === undefined) {
				this.addMVRow[idx] = { ID: '', RECORD_STATUS: 'A' };
			}

			content['form']['fields'].forEach((field) => {
				if (field['Field_Name'] === 'RECORD_STATUS') this.addMVRow[idx][field['Field_Name']] = 'A';
				else if (field['type'] === 'bool') this.addMVRow[idx][field['Field_Name']] = false;
				else if (field['is_Static_Value'] === true && field['is_MultiSelect'] === true) {
					this.addMVRow[idx][field['Field_Name']] = [];
				} else this.addMVRow[idx][field['Field_Name']] = null;
			});

			var tableName = '';
			this.screen_sections.forEach((section) => {
				if (section.ID === content.id || section.ID.includes(content.id)) {
					tableName = section['Table_Name'];
					section['Fields'] = content['form']['fields'];
					section['index'] = idx;
					section['Display_Type'] = 'MVSection';
				}
			});
			if (this.oper === 'EDIT') {
				this.setMVSectionData(idx, tableName, content['form']['order_by']);
			} else {
				this.mvSectionDatas[idx] = [];
			}
			idx = idx + 1;
		});
	}

	updateMVSectionIndex() {
		let idx = 0;
		const tables = this.rows.reduce((acc, row) => {
			return acc.concat(
				row.columns.reduce((innerAcc, column) => {
					return innerAcc.concat(column.contents.filter((content) => content.type === this.TABLE_KEY));
				}, [])
			);
		}, []);

		tables.forEach((content) => {
			content['index'] = idx;
			this.gridColumns[idx] = { fields: content['form']['fields'] };
			this.setGridConfig(idx, content['form']);
			var tableName = '';
			this.screen_sections.forEach((section) => {
				if (section.ID === content.id || section.ID.includes(content.id)) {
					tableName = section['Table_Name'];
					section['Fields'] = content['form']['fields'];
					section['index'] = idx;
					section['Display_Type'] = 'Table';
				}
			});
			if (this.oper === 'EDIT') {
				this.setGridData(idx, tableName, content['form']['order_by']);
			}
			idx = idx + 1;
		});
	}

	loadPageExpression() {
		var pageExpressions: any = [];
		var variableValues = {};
		var validCondition = false;

		pageExpressions = this.expressions.filter((expr) => expr.Type !== undefined && expr.Type === 'On Load');
		pageExpressions.forEach((pageExpression) => {
			variableValues = {};
			validCondition = this.evaluateCondition(pageExpression, {});
			if (validCondition) {
				pageExpression['Steps'].forEach((step) => {
					if (step['Field_Type'] === 'Field') {
						this.setFieldValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Variable') {
						this.setVariableValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Expression') {
						this.executeExpression(step['Field_ID'], {});
					} else if (step['Field_Type'] === 'Row') {
						this.setRowProperty(step['Field_ID'], step['Field_Value']);
					} else if (step['Field_Type'] === 'Message') {
						this.displayMessage(step['Field_ID']);
					}
				});
			}
		});
	}

	loadPageExpressionMVSections() {
		var keys = Object.keys(this.mvSectionColumns);
		for (var idx = 0; idx < keys.length; idx++) {
			this.mvSectionColumns[idx].fields.forEach((field) => {
				if (field.Expression !== undefined && field.Expression !== '') {
					this.mvSectionDatas[idx].forEach((rowData) => {
						this.onMVSectionValueChange(field, rowData);
					});
				}
			});
		}
	}

	onValueChange(field) {
		if (field['DataType'] === 'EMAIL') {
			let emailValue: string = this.pageValues[field['Field_ID']];
			if (emailValue !== '') {
				if (!emailValue.toLowerCase().match(this.emailPattern)) {
					this.toastr.error('Invalid Email, Please enter correct Email.', 'Error');
					this.pageValues[field['Field_ID']] = '';
				}
			}
		} else if (field['DataType'] === 'BIT') {
		}

		if (field['Expression'] !== undefined && field['Expression'] !== null && field['Expression'] !== '') {
			this.executeExpression(field['Expression'], {});
		}
	}

	onMVSectionValueChange(field, rowData) {
		if (field['DataType'] === 'EMAIL') {
			let emailValue: string = rowData[field['Field_Name']];
			if (emailValue !== '') {
				if (!emailValue.toLowerCase().match(this.emailPattern)) {
					this.toastr.error('Invalid Email, Please enter correct Email.', 'Error');
					rowData[field['Field_Name']] = '';
				}
			}
		} else if (field['DataType'] === 'BIT') {
		}

		if (field['Expression'] !== undefined && field['Expression'] !== null && field['Expression'] !== '') {
			this.executeExpression(field['Expression'], rowData);
		}
	}

	executeExpression(expressionID, rowData) {
		var retValue: boolean = true;
		var expression = [];
		var fldExpression = {};
		var variableValues = {};
		var validCondition = false;
		if (expressionID !== undefined) {
			expression = this.expressions.filter((expr) => expr.ID === expressionID);
			if (expression.length > 0) {
				fldExpression = expression[0];

				validCondition = this.evaluateCondition(fldExpression, rowData);
				if (validCondition) {
					fldExpression['Steps'].forEach((step) => {
						if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
							this.setFieldValue(step, variableValues, rowData);
						} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
							this.setMVFieldValue(step, variableValues, rowData);
						} else if (step['Field_Type'] === 'Variable') {
							this.setVariableValue(step, variableValues, rowData);
						} else if (step['Field_Type'] === 'Expression') {
							retValue = this.executeExpression(step['Field_ID'], rowData);
						} else if (step['Field_Type'] === 'Row') {
							this.setRowProperty(step['Field_ID'], step['Field_Value']);
						} else if (step['Field_Type'] === 'Message') {
							this.displayMessage(step['Field_ID']);
						} else if (step['Field_Type'] === 'DisableSave') {
							retValue = false;
						}
					});
				}
			}
		}
		return retValue;
	}

	evaluateCondition(expression, rowData) {
		var isValid = false;
		if (expression.Conditions === undefined || expression.Conditions.length === 0) {
			isValid = true;
		} else {
			var condOperator = expression.Condition_Operator;
			var conditions = JSON.parse(JSON.stringify(expression.Conditions));
			conditions.forEach((cond) => {
				if (cond.Field_Section === 'Single') {
					if (cond.DataType === 'TINY_STRING' || cond.DataType === 'PASSWORD' || cond.DataType === 'EMAIL' || cond.DataType === 'ENCRYPTED' || cond.DataType === 'MEDIUM_STRING' || cond.DataType === 'TEXT' || cond.DataType === 'ATTACHMENT' || cond.DataType === 'UPLOAD') cond['Status'] = this.checkConditionValue(cond.Operator, this.pageValues[cond.Field_ID], cond.Value_Type === 'Field' ? this.pageValues[cond.Value] : cond.Value);
					else if (cond.DataType === 'BIT') cond['Status'] = this.checkConditionValue(cond.Operator, this.pageValues[cond.Field_ID], cond.Value_Type === 'Field' ? this.pageValues[cond.Value] : cond.Value === '' || cond.Value === '0' || cond.Value === 'false' ? false : true);
					else if (cond.DataType === 'INTEGER') cond['Status'] = this.checkConditionValue(cond.Operator, parseInt(this.pageValues[cond.Field_ID]), cond.Value_Type === 'Field' ? parseInt(this.pageValues[cond.Value]) : parseInt(cond.Value));
					else if (cond.DataType === 'DECIMAL') cond['Status'] = this.checkConditionValue(cond.Operator, parseFloat(this.pageValues[cond.Field_ID]), cond.Value_Type === 'Field' ? parseFloat(this.pageValues[cond.Value]) : parseFloat(cond.Value));
					else if (cond.DataType === 'DATE') cond['Status'] = this.checkDateValue(cond.Operator, this.pageValues[cond.Field_ID], this.pageValues[cond.Value]);
				} else {
					if (cond.Field_Section === 'TINY_STRING') cond['Status'] = this.checkConditionValue(cond.Operator, rowData[cond.Field_Name], cond.Value_Type === 'Field' ? rowData[cond.Value_Field_Name] : cond.Value);
					else if (cond.DataType === 'INTEGER') cond['Status'] = this.checkConditionValue(cond.Operator, parseInt(rowData[cond.Field_Name]), cond.Value_Type === 'Field' ? parseInt(rowData[cond.Value_Field_Name]) : parseInt(cond.Value));
					else if (cond.DataType === 'DECIMAL') cond['Status'] = this.checkConditionValue(cond.Operator, parseFloat(rowData[cond.Field_Name]), cond.Value_Type === 'Field' ? parseFloat(rowData[cond.Value_Field_Name]) : parseFloat(cond.Value));
					else if (cond.DataType === 'DATE') cond['Status'] = this.checkDateValue(cond.Operator, rowData[cond.Field_Name], rowData[cond.Value_Field_Name]);
				}
			});

			if (condOperator === 'AND') {
				var failedConditions = conditions.filter((cond) => cond.Status === false);
				if (failedConditions.length === 0) {
					isValid = true;
				}
			} else if (condOperator === 'OR') {
				var successConditions = conditions.filter((cond) => cond.Status === true);
				if (successConditions.length > 0) {
					isValid = true;
				}
			}
		}
		return isValid;
	}

	checkConditionValue(operator, value1, value2) {
		var retValue = false;
		if (operator === 'LIKE') {
			retValue = value1.includes(value2);
		} else if (operator === 'EQ') {
			if ((value1 === isNaN || value1 === null) && (value2 === isNaN || value2 === '')) retValue = true;
			else retValue = value1 === value2;
		} else if (operator === 'NEQ') {
			if ((value1 === isNaN || value1 === null) && (value2 === isNaN || value2 === '')) retValue = false;
			else retValue = value1 !== value2;
		} else if (operator === 'GT') {
			retValue = value1 > value2;
		} else if (operator === 'GTE') {
			retValue = value1 >= value2;
		} else if (operator === 'LT') {
			retValue = value1 < value2;
		} else if (operator === 'LTE') {
			retValue = value1 <= value2;
		}
		return retValue;
	}

	checkDateValue(operator, val1, val2) {
		var retValue = false;

		if (operator === 'EQ' && (val1 === null || val1 === '') && (val2 === null || val2 === '')) {
			return true;
		} else if (val1 === null || val1 === '' || val2 === null || val2 === '') {
			return retValue;
		}

		var value1 = new Date(val1);
		var value2 = new Date(val2);

		if (operator === 'EQ') {
			retValue = value1 === value2;
		} else if (operator === 'NEQ') {
			retValue = value1 !== value2;
		} else if (operator === 'GT') {
			retValue = value1 > value2;
		} else if (operator === 'GTE') {
			retValue = value1 >= value2;
		} else if (operator === 'LT') {
			retValue = value1 < value2;
		} else if (operator === 'LTE') {
			retValue = value1 <= value2;
		}
		return retValue;
	}

	setVariableValue(step, variableValues, rowData) {
		if (step.Value_Type === 'Field' && step.Value_Section === 'Single') {
			variableValues[step['Field_ID']] = this.pageValues[step['Field_Value']];
		} else if (step.Value_Type === 'Field' && step.Value_Section === 'Multi') {
			variableValues[step['Field_ID']] = rowData[step['Field_Value_Name']];
		} else if (step.Value_Type === 'Variable') {
			variableValues[step['Field_ID']] = variableValues[step['Field_Value']];
		} else if (step.Value_Type === 'Value') {
			variableValues[step['Field_ID']] = step['Field_Value'];
		} else if (step.Value_Type === 'Function') {
			variableValues[step['Field_ID']] = this.getFunctionValue(step['Function'], variableValues, rowData);
		}
	}

	setRowProperty(rowId, value) {
		this.rows.forEach((row) => {
			if (row.id === rowId) {
				row.styles['hide-row-in-display'] = value === 'Show' ? false : true;
			}
		});
	}

	setFieldValue(step, variableValues, rowData) {
		if (this.checkDisableEdit(step['Field_ID']) === false) {
			if (step.Value_Type === 'Field' && step['Value_Section'] === 'Single') {
				this.pageValues[step['Field_ID']] = this.pageValues[step['Field_Value']];
			} else if (step.Value_Type === 'Field' && step['Value_Section'] === 'Multi') {
				this.pageValues[step['Field_ID']] = rowData[step['Field_Value_Name']];
			} else if (step.Value_Type === 'Variable') {
				this.pageValues[step['Field_ID']] = variableValues[step['Field_Value']];
			} else if (step.Value_Type === 'Value') {
				this.pageValues[step['Field_ID']] = step['Field_Value'];
			} else if (step.Value_Type === 'Function') {
				this.pageValues[step['Field_ID']] = this.getFunctionValue(step['Function'], variableValues, rowData);
			} else if (step.Value_Type === 'Property') {
				this.setProperty(step['Field_ID'], step['Field_Value']);
			}
		}
	}

	setMVFieldValue(step, variableValues, rowData) {
		if (step.Value_Type === 'Field' && step['Value_Section'] === 'Single') {
			rowData[step['Field_Name']] = this.pageValues[step['Field_Value']];
		} else if (step.Value_Type === 'Field' && step['Value_Section'] === 'Multi') {
			rowData[step['Field_Name']] = rowData[step['Field_Value_Name']];
		} else if (step.Value_Type === 'Variable') {
			rowData[step['Field_Name']] = variableValues[step['Field_Value']];
		} else if (step.Value_Type === 'Value') {
			rowData[step['Field_Name']] = step['Field_Value'];
		} else if (step.Value_Type === 'Function') {
			rowData[step['Field_Name']] = this.getFunctionValue(step['Function'], variableValues, rowData);
		}
	}

	getFunctionValue(fn, variableValues, rowData) {
		var steps = fn['Steps'];
		var retValue: any = null;

		if (fn['Name'] === 'Concat') {
			retValue = '';
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = retValue + this.pageValues[step['Field_ID']];
				} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
					if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') retValue = retValue + rowData[step['Field_Name']];
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = retValue + variableValues[step['Field_ID']];
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') retValue = retValue + step['Field_ID'];
				}
			});
		} else if (fn['Name'] === 'Add') {
			retValue = 0;
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = retValue + parseFloat(this.pageValues[step['Field_ID']]);
				} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
					if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') retValue = retValue + parseFloat(rowData[step['Field_Name']]);
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = retValue + parseFloat(variableValues[step['Field_ID']]);
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') retValue = retValue + parseFloat(step['Field_ID']);
				}
			});

			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Subtract') {
			retValue = 0;
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(this.pageValues[step['Field_ID']]);
						} else {
							retValue = parseFloat(this.pageValues[step['Field_ID']]);
						}
					}
				} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
					if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(rowData[step['Field_Name']]);
						} else {
							retValue = parseFloat(rowData[step['Field_Name']]);
						}
					}
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(variableValues[step['Field_ID']]);
						} else {
							retValue = parseFloat(variableValues[step['Field_ID']]);
						}
					}
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') {
						if (retValue > 0) {
							retValue = retValue - parseFloat(step['Field_ID']);
						} else {
							retValue = parseFloat(step['Field_ID']);
						}
					}
				}
			});
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Multiply') {
			retValue = 1;
			steps.forEach((step) => {
				if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
					if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = retValue * parseFloat(this.pageValues[step['Field_ID']]);
					else retValue = 0;
				} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
					if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') retValue = retValue * parseFloat(rowData[step['Field_Name']]);
					else retValue = 0;
				} else if (step['Field_Type'] === 'Variable') {
					if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = retValue * parseFloat(variableValues[step['Field_ID']]);
					else retValue = 0;
				} else if (step['Field_Type'] === 'Value') {
					if (step['Field_ID'] !== null && step['Field_ID'] !== '') retValue = retValue * parseFloat(step['Field_ID']);
					else retValue = 0;
				}
			});
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Divide') {
			retValue = 0;
			var dividend = 0;
			var divisor = 1;
			var step = steps[0];
			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') dividend = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
				if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') dividend = parseFloat(rowData[step['Field_Name']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') dividend = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') dividend = parseFloat(step['Field_ID']);
			}

			step = steps[1];
			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') divisor = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
				if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') divisor = parseFloat(rowData[step['Field_Name']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') divisor = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') divisor = parseFloat(step['Field_ID']);
			}

			retValue = dividend / (divisor === 0 ? 1 : divisor);
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Percent') {
			retValue = 0;
			var mainValue = 0;
			var percent = 0;
			var step = steps[0];
			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') mainValue = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
				if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') mainValue = parseFloat(rowData[step['Field_Name']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') mainValue = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') mainValue = parseFloat(step['Field_ID']);
			}

			step = steps[1];
			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') percent = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
				if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') percent = parseFloat(rowData[step['Field_Name']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') percent = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') percent = parseFloat(step['Field_ID']);
			}

			retValue = (mainValue * percent) / 100;
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Round') {
			retValue = 0;
			var mainValue = 0;
			var round = 0;
			var step = steps[0];

			if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') {
				mainValue = parseFloat(variableValues[step['Field_ID']]);
			}

			step = steps[1];
			if (step['Field_ID'] !== null && step['Field_ID'] !== '') {
				round = parseFloat(step['Field_ID']);
			}

			retValue = mainValue.toFixed(round);
		} else if (fn['Name'] === 'Power') {
			retValue = 0;
			var mainValue = 0;
			var powerno = 1;
			var step = steps[0];

			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') mainValue = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') mainValue = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') mainValue = parseFloat(step['Field_ID']);
			}

			step = steps[1];
			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') powerno = parseFloat(this.pageValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') powerno = parseFloat(variableValues[step['Field_ID']]);
			} else if (step['Field_Type'] === 'Value') {
				if (step['Field_ID'] !== null && step['Field_ID'] !== '') powerno = parseFloat(step['Field_ID']);
			}

			retValue = Math.pow(mainValue, powerno);
		} else if (fn['Name'] === 'Length') {
			retValue = 0;
			var mainValue = 0;
			var step = steps[0];

			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') mainValue = this.pageValues[step['Field_ID']].length;
			}

			retValue = mainValue;
		} else if (fn['Name'] === 'UpperCase') {
			retValue = '';
			var step = steps[0];

			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = this.pageValues[step['Field_ID']].toUpperCase();
			} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
				if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') retValue = rowData[step['Field_Name']].toUpperCase();
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = variableValues[step['Field_ID']].toUpperCase();
			}
		} else if (fn['Name'] === 'LowerCase') {
			retValue = '';
			var step = steps[0];

			if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Single') {
				if (this.pageValues[step['Field_ID']] !== null && this.pageValues[step['Field_ID']] !== '') retValue = this.pageValues[step['Field_ID']].toLowerCase();
			} else if (step['Field_Type'] === 'Field' && step['Field_Section'] === 'Multi') {
				if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') retValue = rowData[step['Field_Name']].toLowerCase();
			} else if (step['Field_Type'] === 'Variable') {
				if (variableValues[step['Field_ID']] !== null && variableValues[step['Field_ID']] !== '') retValue = variableValues[step['Field_ID']].toLowerCase();
			}
		} else if (fn['Name'] === 'Random') {
			var result = '';
			var chars = '0abc1def2ghi3jkl4mno5pqr6stu7vwx8yz9';
			for (var i = 16; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
			return result;
		} else if (fn['Name'] === 'Sum') {
			retValue = 0;
			var step = steps[0];
			var idx = 0;
			var displayType = 'Table';
			var mvSections = this.screen_sections.filter((section) => section.Section_Type === 'Multi');
			if (mvSections.length > 0) {
				mvSections.forEach((section) => {
					var field = section.Fields.filter((fld) => fld.Field_ID.toString() === step['Field_ID']);
					if (field.length > 0) {
						idx = section['index'];
						displayType = section['Display_Type'];
					}
				});
			}
			if (displayType === 'Table') {
				var myGrid = this.myGrid.find((_, i) => i === idx);
				var griddata = myGrid.getdatainformation();
				var row: any = 0;
				while (row < griddata.rowscount) {
					var rowData = myGrid.getrowdata(row);
					if (rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD') {
						if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') {
							retValue = retValue + parseFloat(rowData[step['Field_Name']]);
						}
					}
					row++;
				}
			} else if (displayType === 'MVSection') {
				var dataRows = this.mvSectionDatas[idx];
				dataRows.forEach((rowData) => {
					if (rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD') {
						if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') {
							retValue = retValue + parseFloat(rowData[step['Field_Name']]);
						}
					}
				});
			}
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Min') {
			retValue = -1;
			var step = steps[0];
			var idx = 0;
			var mvSections = this.screen_sections.filter((section) => section.Section_Type === 'Multi');
			if (mvSections.length > 0) {
				mvSections.forEach((section) => {
					if (section.Fields !== undefined) {
						var field = section.Fields.filter((fld) => fld.Field_ID.toString() === step['Field_ID']);
						if (field.length > 0) {
							idx = section['index'];
						}
					}
				});
			}
			var myGrid = this.myGrid.find((_, i) => i === idx);
			var griddata = myGrid.getdatainformation();
			var row: any = 0;
			while (row < griddata.rowscount) {
				var rowData = myGrid.getrowdata(row);
				if (rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD') {
					if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') {
						if (retValue === -1 || retValue > parseFloat(rowData[step['Field_Name']])) {
							retValue = parseFloat(rowData[step['Field_Name']]);
						}
					}
				}
				row++;
			}
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'Max') {
			retValue = 0;
			var step = steps[0];
			var idx = 0;
			var mvSections = this.screen_sections.filter((section) => section.Section_Type === 'Multi');
			if (mvSections.length > 0) {
				mvSections.forEach((section) => {
					if (section.Fields !== undefined) {
						var field = section.Fields.filter((fld) => fld.Field_ID.toString() === step['Field_ID']);
						if (field.length > 0) {
							idx = section['index'];
						}
					}
				});
			}
			var myGrid = this.myGrid.find((_, i) => i === idx);
			var griddata = myGrid.getdatainformation();
			var row: any = 0;
			while (row < griddata.rowscount) {
				var rowData = myGrid.getrowdata(row);
				if (rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD') {
					if (rowData[step['Field_Name']] !== null && rowData[step['Field_Name']] !== '') {
						if (retValue === -1 || retValue < parseFloat(rowData[step['Field_Name']])) {
							retValue = parseFloat(rowData[step['Field_Name']]);
						}
					}
				}
				row++;
			}
			if (isNaN(retValue)) {
				retValue = 0;
			}
		} else if (fn['Name'] === 'CurrDate') {
			var today = new Date();
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();

			var date_day = '';
			var date_month = '';

			if (dd < 10) {
				date_day = '0' + dd;
			} else {
				date_day = dd.toString();
			}
			if (mm < 10) {
				date_month = '0' + mm;
			} else {
				date_month = mm.toString();
			}

			retValue = yyyy + '-' + date_month + '-' + date_day;
		} else if (fn['Name'] === 'CurrMonth') {
			var currDate = new Date();
			var mm = currDate.getMonth() + 1;

			if (mm < 10) {
				retValue = '0' + mm;
			} else {
				retValue = mm.toString();
			}
		} else if (fn['Name'] === 'CurrMonthName') {
			var currDate = new Date();
			retValue = this.monthNames[currDate.getMonth()];
		} else if (fn['Name'] === 'CurrYear') {
			var currDate = new Date();
			retValue = currDate.getFullYear();
		} else if (fn['Name'] === 'Age') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];
			var dob = new Date(dateValue);
			var month_diff = Date.now() - dob.getTime();
			var age_dt = new Date(month_diff);
			var year = age_dt.getUTCFullYear();
			var age = Math.abs(year - 1970);

			retValue = age.toString();
		} else if (fn['Name'] === 'Day') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];
			var dd = 1;

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				dd = currDate.getDate();
			} else {
				var dateVal = new Date(dateValue);
				dd = dateVal.getDate();
			}

			if (dd < 10) {
				retValue = '0' + dd;
			} else {
				retValue = dd.toString();
			}
		} else if (fn['Name'] === 'Month') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];
			var mm = 1;

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				mm = currDate.getMonth() + 1;
			} else {
				var dateVal = new Date(dateValue);
				mm = dateVal.getMonth() + 1;
			}

			if (mm < 10) {
				retValue = '0' + mm;
			} else {
				retValue = mm.toString();
			}
		} else if (fn['Name'] === 'MonthName') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				retValue = this.monthNames[currDate.getMonth()];
			} else {
				var dateVal = new Date(dateValue);
				retValue = this.monthNames[dateVal.getMonth()];
			}
		} else if (fn['Name'] === 'Year') {
			var dateValue = this.pageValues[steps[0]['Field_ID']];

			if (dateValue === undefined || dateValue === null || dateValue === '') {
				var currDate = new Date();
				retValue = currDate.getFullYear();
			} else {
				var dateVal = new Date(dateValue);
				retValue = dateVal.getFullYear();
			}
		}

		return retValue;
	}

	checkDisableEdit(fieldID) {
		var returnValue = false;
		if (this.oper === 'EDIT') {
			var field = this.section_attributes.filter((attr) => parseInt(attr['Field_ID']) === parseInt(fieldID) && (attr['Disable_On_Edit'] === 1 || attr['is_Auto_Number'] === 1));
			returnValue = field.length > 0;
		}
		return returnValue;
	}

	getCurrentDate() {
		var curDate = new Date();
		return this.getFormattedDate('yyyy/MM/dd', curDate.getDate(), curDate.getMonth() + 1, curDate.getFullYear());
	}

	getFormattedDate(format, date_day, date_month, date_year) {
		var value = '';
		if (date_month < 10) {
			date_month = '0' + date_month;
		}
		if (format === 'dd/MM/yyyy') {
			value = date_day + '/' + date_month + '/' + date_year;
		} else if (format === 'dd-MM-yyyy') {
			value = date_day + '-' + date_month + '-' + date_year;
		} else if (format === 'MM/dd/yyyy') {
			value = date_month + '/' + date_day + '/' + date_year;
		} else if (format === 'MM-dd-yyyy') {
			value = date_month + '-' + date_day + '-' + date_year;
		} else if (format === 'yyyy/MM/dd') {
			value = date_year + '/' + date_month + '/' + date_day;
		} else if (format === 'yyyy-MM-dd') {
			value = date_year + '-' + date_month + '-' + date_day;
		}
		return value;
	}

	getCurrentDateTime() {
		var curDate = new Date();
		return this.getFormattedDateTime('yyyy/MM/dd', curDate.getDate(), curDate.getMonth() + 1, curDate.getFullYear(), curDate.getHours(), curDate.getMinutes(), curDate.getSeconds());
	}

	getFormattedDateTime(format, date_day, date_month, date_year, date_hours, date_minutes, date_seconds) {
		var value = '';
		if (date_month < 10) {
			date_month = '0' + date_month;
		}
		if (format === 'dd/MM/yyyy') {
			value = date_day + '/' + date_month + '/' + date_year;
		} else if (format === 'dd-MM-yyyy') {
			value = date_day + '-' + date_month + '-' + date_year;
		} else if (format === 'MM/dd/yyyy') {
			value = date_month + '/' + date_day + '/' + date_year;
		} else if (format === 'MM-dd-yyyy') {
			value = date_month + '-' + date_day + '-' + date_year;
		} else if (format === 'yyyy/MM/dd') {
			value = date_year + '/' + date_month + '/' + date_day;
		} else if (format === 'yyyy-MM-dd') {
			value = date_year + '-' + date_month + '-' + date_day;
		}
		value = value + ' ' + date_hours + ':' + date_minutes + ':' + date_seconds;
		return value;
	}

	displayMessage(message) {
		this.toastr.info('Information', message, { timeOut: 3000 });
	}

	setProperty(field, property) {
		this.rows.forEach((row) => {
			row.columns.forEach((column) => {
				column.contents.forEach((content) => {
					if (content.type === 'Section') {
						content['form']['fields'].forEach((fieldAttr) => {
							if (fieldAttr.Field_ID.toString() === field.toString()) {
								if (property === 'Show') {
									fieldAttr['is_Hidden'] = false;
								} else if (property === 'Hide') {
									fieldAttr['is_Hidden'] = true;
								} else if (property === 'Mandatory') {
									fieldAttr['is_Mandatory'] = true;
									this.setMandatoryInSectionAttributes(fieldAttr.Field_ID, 1);
								} else if (property === 'Optional') {
									fieldAttr['is_Mandatory'] = false;
									this.setMandatoryInSectionAttributes(fieldAttr.Field_ID, 0);
								} else if (property === 'Readonly') {
									fieldAttr['is_Read_Only'] = true;
								} else if (property === 'Editable') {
									fieldAttr['is_Read_Only'] = false;
								}
							}
						});
					}
				});
			});
		});
	}

	setMandatoryInSectionAttributes(Field_ID, Value) {
		this.section_attributes
			.filter((attr) => attr.Field_ID === Field_ID)
			.forEach((field) => {
				field['is_Mandatory'] = Value;
			});
	}

	loadPage(obj) {
		var retValue: boolean = true;
		let hasExpression = this.pageFunctions.checkNull(obj['expression']);
		if (!hasExpression) {
			this.rowsHidden.forEach((row) => {
				row.styles['hide-row-in-display'] = true;
			});
		}
		if (this.pageFunctions.checkNull(obj['url'])) {
			if (obj.screen !== undefined && obj.screen.type !== undefined && obj.screen.type !== '' && obj.screen.type !== 'Page' && obj.screen.type !== 'Listing') {
			} else {
				if (obj.navigateUrl !== undefined && obj.navigateUrl !== null && obj.navigateUrl !== '') {
					this.goToUrl(obj.navigateUrl, obj.target, obj.screen);
				} else if (obj.type !== 'Image') {
					this.goToUrl(obj.url, obj.target, obj.screen);
				}
			}
		} else if (obj.row_id !== undefined && obj.row_id !== '') {
			var elements = document.getElementById(obj.row_id);
			if (obj['menu_action'] === 'visible') {
				if (!hasExpression) {
					this.rows.forEach((r) => {
						if (obj.row_id === r.id) {
							r.styles['hide-row-in-display'] = !r.styles['hide-row-in-display'];
						}
					});
				} else {
					retValue = this.OnClickExpressions(obj['expression']);
					if (retValue) {
						this.saveTried = false;
						this.rowsHidden.forEach((row) => {
							row.styles['hide-row-in-display'] = true;
						});
						this.rows.forEach((r) => {
							if (obj.row_id === r.id) {
								r.styles['hide-row-in-display'] = !r.styles['hide-row-in-display'];
							}
						});
					} else {
						this.saveTried = true;
					}
				}
			} else if (obj['menu_action'] === 'scroll') {
				if (elements !== undefined && elements !== null) {
					elements.scrollIntoView();
				}
			} else if (hasExpression) {
				retValue = this.OnClickExpressions(obj['expression']);

				if (retValue) {
					this.saveTried = false;
				} else {
					this.saveTried = true;
				}
			}
		} else if (hasExpression && (obj.row_id === undefined || obj.row_id === '' || obj.row_id === null)) {
			retValue = this.OnClickExpressions(obj['expression']);
			if (retValue) {
				this.saveTried = false;
			} else {
				this.saveTried = true;
			}
		}
	}

	goToUrl(url, target, screen) {
		if (screen === undefined || screen.Is_Static === undefined || screen.Is_Static === '1') {
			if (url !== null && url !== undefined && url !== '') {
				if (target === undefined || target === '' || target === '_self') {
					this.router.navigate([url]);
				} else {
					window.open(url, '_blank');
				}
			}
		} else {
			var screenUrl = '';
			if (screen.type === 'Page') screenUrl = '/app/page?pageid=' + screen.ID + '&id=' + this.primaryId;
			else if (screen.type === 'Listing') screenUrl = '/app/listing?id=' + screen.ID;

			if (target === undefined || target === '' || target === '_self') {
				this.router.navigateByUrl(screenUrl);
			} else {
				window.open(screenUrl, '_blank');
			}
		}
	}

	rowsHidden: any = [];
	//elements visible only on scroll and animation set on scroll
	@HostListener('window:scroll', ['$event'])
	scrollHandler(event) {
		this.setVisibleRows('scroll');
	}
	setVisibleRows(eventType) {
		this.rows.forEach((row) => {
			if (eventType === '') {
				if (row.styles['hide-row-in-display']) {
					this.rowsHidden.push(row);
				}
			}
			var ele_row = document.getElementById(row.id);
			if (ele_row) {
				let rowTop = this.checkInView(ele_row, true);
				if (rowTop) {
					//if animation is already run for that row then do not run the animation
					this.setRowAnimation(row);
					row.columns.forEach((column) => {
						this.setColumnAnimation(column, row);
						column.contents.forEach((content) => {
							if (content.type === 'MenuGroup') {
								let menuidx = 0;
								content.menus.forEach((menu) => {
									var content_ele_row = document.getElementById(content.id + menuidx);
									if (content_ele_row) {
										if (content.styles['hover-available']) {
											content_ele_row.style.setProperty('--hover-bgcolor', content.styles['hover-bgcolor']);
											content_ele_row.style.setProperty('--hover-color', content.styles['hover-color']);
										}
									}
									menuidx++;
								});
							} else {
								var content_ele_row = document.getElementById(content.id);
								if (content_ele_row) {
									if (content.styles['hover-available']) {
										content_ele_row.style.setProperty('--hover-bgcolor', content.styles['hover-bgcolor']);
										content_ele_row.style.setProperty('--hover-color', content.styles['hover-color']);
									}
								}
							}
							if (this.pageFunctions.checkNull(content.styles.animation)) {
								var content_ele_row = document.getElementById(content.id);
								let contentVisible = this.checkInView(content_ele_row, true);
								if (contentVisible) {
									this.setContentAnimation(content, column);
								}
							}
						});
					});
				}
			}
		});
		this.rowsHidden.forEach((row) => {
			if (row.styles['hide-row-in-display']) {
				if (eventType === 'scroll') {
					if (!row.styles['persist-on-scroll']) {
						row.styles['hide-row-in-display'] = true;
					}
				} else {
					row.styles['hide-row-in-display'] = !row.styles['default-visible'];
				}
			}
		});
	}

	checkInView(elem, partial) {
		var contHeight = window.innerHeight;
		var contTop = window.scrollY;
		var contBottom = contTop + contHeight;

		var elemTop = $(elem).offset().top - window.pageYOffset;
		var elemBottom = elemTop + $(elem).height();

		var isTotal = elemTop >= 0 && elemBottom <= contHeight;
		var isPart = ((elemTop <= 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= window.innerHeight)) && partial;

		return isTotal || isPart;
	}

	setRowAnimation(row) {
		var videoHolder = $('#video_' + row.id);
		if (videoHolder.length > 0) {
			try {
				videoHolder[0].play();
			} catch (e) {}
		}

		var dataclass = row.styles.animation;
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = row.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				this.setOpacity(row, false, '');
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + row.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {});
					row.styles.animation = '';
				}
			}, 2000);
		}
	}

	setColumnAnimation(column, row) {
		var videoHolder = $('#video_' + row.id + '_' + column.id);
		if (videoHolder.length > 0) {
			try {
				videoHolder[0].play();
			} catch (e) {}
		}

		var dataclass = column.styles.animation;
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = column.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				this.setOpacity(column, false, '');
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + column.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
							if (animationInterval !== 'animated10 infinite') {
								$(this).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
							}
						});
					$('#' + row.id).addClass('h-scroll-text-container');
					column.styles.animation = '';
				}
			}, 1000);
		}
	}

	setContentAnimation(content, column) {
		var dataclass = content.styles.animation;
		var idPrefix = '';
		if (dataclass !== undefined && dataclass !== '') {
			var animationInterval = content.styles['animation-interval'];
			if (animationInterval === '' || animationInterval === undefined || animationInterval === null) {
				animationInterval = 'animated';
			}
			setTimeout(() => {
				if (content.type === 'CLIPATH' && dataclass.toLowerCase() === 'gradient') idPrefix = 'animate';
				else idPrefix = '';
				this.setOpacity(content, true, idPrefix);
				if (dataclass !== undefined && dataclass !== '') {
					$('#' + idPrefix + content.id)
						.addClass(dataclass + ' ' + animationInterval)
						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
							if (animationInterval !== 'animated10 infinite') {
								$('#' + idPrefix + content.id).removeClass(dataclass + ' ' + animationInterval + ' webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend');
							}
						});
					$('#' + content.id + column.id).addClass('h-scroll-text-container');
					content.styles.animation = '';
				}
			}, 500);
		}
	}

	stripHTML(val) {
		return val.replace(/<.*?>/g, '');
	}

	getPageStyles(obj) {
		return this.pageFunctions.getPageStyles(obj);
	}

	getRowStyles(row, obj, idx) {
		if (row['gen-style'] === undefined) {
			row['gen-style'] = this.pageFunctions.getPageRowStyles(row, obj, idx, this.isAppleDevice);
		}
		return row['gen-style'];
	}

	getRowBackgroundColor(obj) {
		return this.pageFunctions.getRowBackgroundColor(obj);
	}

	getColumnBackgroundColor(obj) {
		return this.pageFunctions.getRowBackgroundColor(obj);
	}

	getColumnStyles(column) {
		if (!this.isMobile()) {
			column['gen-style'] = this.pageFunctions.getColumnStyles(column, 1);
		} else {
			column['gen-style'] = { padding: '0px' };
		}
		return column['gen-style'];
	}

	isMobile() {
		return this.window_width < 700;
	}

	getStyles(row, column, content, obj) {
		return this.getStylesType(row, column, content, obj, '');
	}

	getImageStyles(content) {
		var style = {};
		style['width'] = '100%';
		style['height'] = '100%';
		return style;
	}

	getTextImageStyles() {
		var style = {};

		style['width'] = '100%';
		style['height'] = '100%';

		return style;
	}

	calculateWidthForResolutionWithMobile(row, column, width) {
		if (this.window_width > 700 || parseInt(this.page.styles['page-width']) != 1366) {
			return this.calculateWidthForResolution(row, column, width) + '%';
		} else {
			// for image containers in mobile view, if the width of the
			//set image is less than 200(used for icons) then set the width
			var wid = width;
			if (!isNaN(wid)) {
				wid = (wid * this.window_width) / 357;
				wid = Math.round(wid);
				//reduce scroller width 9px
				wid = wid - 9;
			}
			return wid + 'px';
		}
	}

	getStylesWithShadow(row, column, content, obj, contentType, objShadow) {
		var style: any = {};

		if (content['style-' + contentType + '-' + content.id] === undefined) {
			style = this.getStylesType(row, column, content, obj, contentType);
			this.pageFunctions.setShadow(style, objShadow);

			content['style-' + contentType + '-' + content.id] = style;
		}
		style = null;

		return content['style-' + contentType + '-' + content.id];
	}

	getSkewState(obj) {
		var rotate = '';
		if (this.pageFunctions.checkNull(obj['rotate'])) {
			rotate = 'rotate(' + obj['rotate'] + 'deg)';
		}
		if (this.pageFunctions.checkNull(obj['skew']) && obj['skew'] !== '0') {
			rotate += 'skew(' + obj['skew'] + 'deg,0deg)';
		}
		return rotate;
	}

	getMVSectionBtnStyles(obj) {
		var style = {};

		if (obj['font-weight'] !== undefined && obj['font-weight'] !== null && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}
		if (obj['font-style'] !== undefined && obj['font-style'] !== null && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}
		if (obj['text-decoration'] !== undefined && obj['text-decoration'] !== null && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}
		if (obj['font-size'] !== undefined && obj['font-size'] !== null) {
			style['font-size'] = obj['font-size'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style['text-align'] = obj['text-align'];
		}
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style['background-color'] = obj['background-color'];
		}
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}
		this.pageFunctions.setPadding(style, obj);
		this.pageFunctions.setBorder(style, obj, true);

		if (obj['border-radius'] !== undefined && obj['border-radius'] !== null && obj['border-radius'] !== '') {
			style['border-radius'] = obj['border-radius'] + 'px';
		}

		return style;
	}

	getStylesType(row, column, content, obj, contentType) {
		var style: any = {};

		if (!this.pageFunctions.checkNull(obj.animation) && (obj.opacityAlreadySet === undefined || obj.opacityAlreadySet)) {
			style['opacity'] = obj['opacity'];
		}

		if (contentType !== 'Text' && contentType !== 'TextImage') {
			if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
				style['font-family'] = obj['font-family'];
			}
			if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
				style['text-align'] = obj['text-align'];
			}
			if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
				style['justify-content'] = obj['justify-content'];
			}
		} else {
			if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
				style['text-align'] = 'left';
			}
			if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
				style['justify-content'] = 'left';
			}
		}
		if (contentType === 'Text') {
			if (obj['icon-v-align']) {
				style['display'] = 'flex';
				style['align-items'] = obj['icon-v-align'];
			}
		}
		if (contentType !== 'Forms') {
			this.pageFunctions.setShadow(style, obj);
		}

		if (obj['letter-spacing'] !== null && obj['letter-spacing'] !== undefined) {
			style['letter-spacing'] = obj['letter-spacing'];
		}
		this.pageFunctions.checkNullAndAssign(style, obj, 'clip-path', '');
		if (contentType !== 'MenuGroupPrimary') {
			this.pageFunctions.setPadding(style, obj);
		}
		if (contentType !== 'CLIPATH') {
			if (this.pageFunctions.checkNull(obj['rotate'])) {
				style['rotate'] = obj['rotate'] + 'deg';
			}
		}

		if (contentType !== 'Image' && contentType !== 'TextImage') {
			if (contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Main') {
				if (contentType === 'Shape' || contentType === 'CLIPATH') {
					if (obj['border-color'] !== undefined && obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
						this.pageFunctions.setBorder(style, obj, true);
					} else {
						this.pageFunctions.setBorder(style, obj, false);
					}
				} else {
					this.pageFunctions.setBorder(style, obj, true);
				}

				this.pageFunctions.serBorderRadius(style, obj);
			}
			if (contentType !== 'CarouselImage' && contentType !== 'Carousel-Sub' && contentType !== 'Carousel-Main' && contentType !== 'BLOG' && contentType !== 'Forms' && contentType !== 'TypeForm' && contentType !== 'Text' && contentType !== 'TextImage' && contentType !== 'MVSection') {
				if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
					style['border-radius'] = obj['border-radius'] + 'px';
				}
			}
			this.pageFunctions.setBackgroundImage(style, obj);
			this.pageFunctions.setBackground(style, obj);
			if (contentType === 'CLIPATH') {
				style['background-color'] = obj['background-color'];
			}
		}

		if (contentType === 'Line' || contentType === 'Lottie' || contentType === 'Spline' || contentType === 'Shape' || contentType === 'CLIPATH' || contentType === 'Text' || contentType === 'TextImage' || contentType === 'Image' || contentType === 'Button') {
			style['position'] = obj['position'];
		}

		if (obj['mobile-background-color'] !== null && obj['mobile-background-color'] !== undefined && contentType === 'MobileMenu') {
			style['background-color'] = obj['mobile-background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = 'block';
			style['width'] = '100%';
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style['line-height'] = obj['line-height'];
		}

		if (contentType !== 'TextImage' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub' && contentType !== 'MVSection') {
			if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== '' && obj['height'] !== 0) {
				style['height'] = this.calculateHeightForResolution(obj['height']) + 'px';
			}
		}

		if (content.type === 'MVSection') {
			row.styles['auto-height'] = true;
			style['height'] = '100%';
		}

		if (contentType !== 'TextImage' && contentType !== 'CLIPATH' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub' && contentType !== 'MVSection') {
			if (contentType === 'Lottie' ||  contentType === 'Spline' || contentType === 'Forms' || contentType === 'Line' || contentType === 'Shape' || contentType === 'Button' || contentType === 'Text' || contentType === 'TextArea' || contentType === 'Carousel') {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					var objWidth = obj['width'];
					if (contentType === 'Text') {
						if (obj['width-type'] !== null && obj['width-type'] !== undefined && obj['width-type'] !== '' && obj['width-type'] === 'px') {
							//cargo cult
						} else {
							objWidth = obj['width'] * 13.8;
							obj['width-type'] = 'px';
						}
					}
					if (this.page.styles['page-width'] === 1366) style['width'] = this.calculateWidthForResolution(row, column, objWidth) + '%';
					else style['width'] = objWidth + 'px';

					style['width-type'] = 'px';
				}
			} else if (contentType === 'Image') {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					style['width'] = this.calculateWidthForResolution(row, column, obj['width']) + '%';
				}
			} else {
				if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
					style['width'] = obj['width'] + '%';
				}
			}
		}
		if (contentType !== 'MenuGroup' && contentType !== 'CLIPATH' && contentType !== 'MobileMenu' && contentType !== 'CarouselImage' && contentType !== 'Carousel-Main' && contentType !== 'Carousel-Sub' && contentType !== 'MVSection') {
			if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== '') {
				style['margin-top'] = obj['margin-top'];
			}
			if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== '') {
				style['margin-left'] = this.getLeftMargin(obj, row);
			}
		}

		//left padding is required only for desktop view and not needed for mobile view
		if (this.window_width > 700 || this.page.styles['page-width'] !== 1366) {
		} else {
			// for image containers in mobile view, if the width of the
			//set image is less than 200(used for icons) then set the width
			//instead of 100%
			if (contentType === 'Lottie' ||  contentType === 'Spline' || contentType === 'Shape' || contentType === 'CLIPATH' || contentType === 'Text' || contentType === 'TextImage' || contentType === 'Image' || contentType === 'FAQ' || contentType === 'BLOG' || contentType === 'Forms' || contentType === 'TypeForm' || contentType === 'Button') {
				var wid = obj['width'];
				if (!isNaN(wid)) {
					if (contentType !== 'Image' || wid > 200) {
						wid = (wid * this.window_width) / 357;
						wid = Math.round(wid);
					} else {
						if (parseInt(wid) > this.window_width) {
							wid = this.window_width;
						}
					}
				}
				style['width'] = wid + 'px';
			}
		}
		content['style-' + contentType + '-' + content.id] = style;

		style = null;

		return content['style-' + contentType + '-' + content.id];
	}

	getLeftMargin(obj, row) {
		var adjustMargin = parseInt(obj['margin-left'].toString().replace('px', ''));
		if (this.window_width < 700) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 357;
		} else if (this.window_width < 1000) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 768;
		} else if (this.window_width < 1366 || (row['id'] !== undefined && row.styles['row-full-width'] === true)) {
			adjustMargin = (parseInt(obj['margin-left'].toString().replace('px', '')) * this.window_width) / 1366;
		}
		adjustMargin = Math.max(0, adjustMargin);
		return adjustMargin + 'px';
	}

	//screen width used in configuration is 1366, calculating the proportion
	//based on configured width and current screen display width
	calculateWidthForResolution(row, column, width) {
		var ww = 100;
		var col = 0;
		var column_border = 0;
		if (row.styles['is-grid-layout']) {
			if (column.styles['column-value']) {
				col = this.getTotalColumnsForGrid(column.styles['column-value']);
			} else {
				col = 1;
			}
		} else {
			if (column.class === 'column-1') {
				col = 1;
			} else if (column.class === 'column-2') {
				col = 2;
			} else if (column.class === 'column-3') {
				col = 3;
			} else if (column.class === 'column-4') {
				col = 4;
			} else if (column.class === 'column-5') {
				col = 5;
			} else if (column.class === 'column-6') {
				col = 6;
			} else if (column.class === 'column-7') {
				col = 7;
			} else if (column.class === 'column-8') {
				col = 8;
			} else if (column.class === 'column-9') {
				col = 9;
			} else if (column.class === 'column-10') {
				col = 10;
			} else if (column.class === 'column-11') {
				col = 11;
			} else if (column.class === 'column-12') {
				col = 12;
			}
		}
		if (column.styles['border-left'] !== undefined && column.styles['border-left'] !== '') {
			column_border += parseInt(column.styles['border-left']) || 0;
		}
		if (column.styles['border-right'] !== undefined && column.styles['border-right'] !== '') {
			column_border += parseInt(column.styles['border-right']) || 0;
		}

		if (this.window_width < 1000) ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (768 / (12 - col + 1)))) / this.window_width) * (12 / col);
		else {
			ww = ((100 * (((parseInt(width) + column_border) * (this.window_width / (12 - col + 1))) / (1366 / (12 - col + 1)))) / this.window_width) * (12 / col);
		}

		return ww;
	}

	getTotalColumnsForGrid(fraction) {
		return fraction;
	}

	calculateHeightForResolution(height) {
		return height;
	}

	getMarginClass(size) {
		try {
			if (parseInt(size.replace('px', '')) > 100) {
				return true;
			}
		} catch (e) {}
		return false;
	}

	getFontClass(size) {
		try {
			if (parseInt(size.replace('px', '')) > 30) {
				return true;
			}
		} catch (e) {}
		return false;
	}

	switchImage(id, enter, content) {
		var ele = document.getElementById('image' + id);

		if (!this.pageFunctions.checkNull(content.styles['hover-url'])) return;

		if (ele) {
			ele.setAttribute('src', enter ? content.styles['hover-url'] : content['url']);
		}
	}

	setOpacity(content, blnSetOpacity, idPrefix) {
		content.styles.opacityAlreadySet = true;
		try {
			var content_ele_row = document.getElementById(idPrefix + content.id);
			if (content_ele_row) {
				if (blnSetOpacity) {
					content_ele_row.style.opacity = content.styles.opacity;
				}
				if (this.pageFunctions.checkNull(content.styles['animation-offset'])) {
					content_ele_row.style.setProperty('--from-width', content.styles['animation-offset'] + 'px');
					content_ele_row.style.setProperty('overflow', 'hidden');
				}
				if (this.pageFunctions.checkNull(content.styles['animation-start-delay'])) {
					content_ele_row.style.setProperty('--animation-delay', content.styles['animation-start-delay'] + 's');
				} else {
					content_ele_row.style.setProperty('--animation-delay', '0s');
				}
				if (this.pageFunctions.checkNull(content.styles['animation-iteration'])) {
					content_ele_row.style.setProperty('--animation-iteration', content.styles['animation-iteration']);
				} else {
					content_ele_row.style.setProperty('--animation-iteration', '1');
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	getShadowStyle(obj) {
		var style = {};
		this.pageFunctions.setShadow(style, obj);
		if (obj.type !== 'Video') {
			if (!this.pageFunctions.checkNull(obj.animation) && (obj.opacityAlreadySet === undefined || obj.opacityAlreadySet)) {
				style['opacity'] = obj['opacity'];
			}
		}
		return style;
	}

	//get styles for content
	getStylesStaticType(column, obj, contentType, selected) {
		var style = {};
		if (obj['static_font-family'] !== null && obj['static_font-family'] !== undefined) {
			style['font-family'] = obj['static_font-family'];
		}
		if (obj['static_text-align'] !== null && obj['static_text-align'] !== undefined) {
			style['text-align'] = obj['static_text-align'];
		}
		if (obj['static_justify-content'] !== null && obj['static_justify-content'] !== undefined) {
			style['justify-content'] = obj['static_justify-content'];
		}

		if (obj['static_border-top-left-radius'] !== null && obj['static_border-top-left-radius'] !== undefined) {
			style['border-top-left-radius'] = obj['static_border-top-left-radius'] + 'px';
		}
		if (obj['static_border-top-right-radius'] !== null && obj['static_border-top-right-radius'] !== undefined) {
			style['border-top-right-radius'] = obj['static_border-top-right-radius'] + 'px';
		}
		if (obj['static_border-bottom-left-radius'] !== null && obj['static_border-bottom-left-radius'] !== undefined) {
			style['border-bottom-left-radius'] = obj['static_border-bottom-left-radius'] + 'px';
		}
		if (obj['static_border-bottom-right-radius'] !== null && obj['static_border-bottom-right-radius'] !== undefined) {
			style['border-bottom-right-radius'] = obj['static_border-bottom-right-radius'] + 'px';
		}

		if (obj['static_border-radius'] !== null && obj['static_border-radius'] !== undefined) {
			style['border-radius'] = obj['static_border-radius'] + 'px';
		}
		if (selected) {
			if (obj['static_selected_background-color'] !== null && obj['static_selected_background-color'] !== undefined && obj['static_selected_background-color'] !== '') {
				style['background-color'] = obj['static_selected_background-color'];
			}

			if (obj['static_selected_color'] !== null && obj['static_selected_color'] !== undefined && obj['static_selected_color'] !== '') {
				style['color'] = obj['static_selected_color'];
			}
		} else {
			if (obj['static_background-color'] !== null && obj['static_background-color'] !== undefined && obj['static_background-color'] !== '') {
				style['background-color'] = obj['static_background-color'];
			}

			if (obj['static_color'] !== null && obj['static_color'] !== undefined && obj['static_color'] !== '') {
				style['color'] = obj['static_color'];
			}
		}

		if (obj['static_font-weight'] !== null && obj['static_font-weight'] !== undefined && obj['static_font-weight'] !== 'Normal') {
			style['font-weight'] = obj['static_font-weight'];
		}

		if (obj['static_font-style'] !== null && obj['static_font-style'] !== undefined && obj['static_font-style'] !== 'Normal') {
			style['font-style'] = obj['static_font-style'];
		}

		if (obj['static_text-decoration'] !== null && obj['static_text-decoration'] !== undefined && obj['static_text-decoration'] !== '') {
			style['text-decoration'] = obj['static_text-decoration'];
		}

		if (obj['static_font-size'] !== null && obj['static_font-size'] !== undefined) {
			style['font-size'] = obj['static_font-size'];
		}

		if (contentType !== 'TextArea' && contentType !== 'ImageTextArea') {
			if (obj['static_padding-top'] !== null && obj['static_padding-top'] !== undefined) {
				style['padding-top'] = obj['static_padding-top'] + 'px ';
			}
			if (obj['static_padding-right'] !== null && obj['static_padding-right'] !== undefined) {
				style['padding-right'] = obj['static_padding-right'] + 'px ';
			}
			if (obj['static_padding-bottom'] !== null && obj['static_padding-bottom'] !== undefined) {
				style['padding-bottom'] = obj['static_padding-bottom'] + 'px ';
			}
			if (obj['static_padding-left'] !== null && obj['static_padding-left'] !== undefined) {
				style['padding-left'] = obj['static_padding-left'] + 'px';
			}
		}

		return style;
	}

	getMultiSelectStyle(content, id) {
		var style = {};
		var obj = content['input_styles'];

		if (obj === undefined) {
			obj = {
				color: '#495057',
				'background-color': '#fff',
				'font-weight': 'normal',
				'font-style': 'normal',
				'text-decoration': 'normal',
				'border-top': 0,
				'border-right': 0,
				'border-bottom': 0,
				'border-left': 0,
				'border-style': '',
				position: '',
				'border-color': '#495057',
				'border-top-left-radius': 0,
				'border-top-right-radius': 0,
				'border-bottom-left-radius': 0,
				'border-bottom-right-radius': 0,
			};
		}

		var content_ele_row = document.getElementById(id);
		if (content_ele_row) {
			if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
				content_ele_row.style.setProperty('--font-weight', obj['font-weight']);
			} else {
				content_ele_row.style.setProperty('--font-weight', 'normal');
			}

			if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
				content_ele_row.style.setProperty('--font-style', obj['font-style']);
			} else {
				content_ele_row.style.setProperty('--font-style', 'normal');
			}

			if (content['styles']['input-font-size'] !== null && content['styles']['input-font-size'] !== undefined) {
				content_ele_row.style.setProperty('--font-size', content['styles']['input-font-size']);
			} else {
				content_ele_row.style.setProperty('--font-size', '16px');
			}

			if (obj['color'] !== null && obj['color'] !== undefined) {
				content_ele_row.style.setProperty('--color', obj['color']);
			} else {
				content_ele_row.style.setProperty('--color', '#000');
			}

			if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
				content_ele_row.style.setProperty('--background-color', obj['background-color']);
			} else {
				content_ele_row.style.setProperty('--background-color', '#fff');
			}

			if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
				content_ele_row.style.setProperty('--padding-top', obj['padding-top'] + 'px');
			} else {
				content_ele_row.style.setProperty('--padding-top', '0px');
			}

			if (obj['padding-bottom'] !== null && obj['padding-bottom'] !== undefined) {
				content_ele_row.style.setProperty('--padding-bottom', obj['padding-bottom'] + 'px');
			} else {
				content_ele_row.style.setProperty('--padding-bottom', '0px');
			}

			if (obj['padding-left'] !== null && obj['padding-left'] !== undefined) {
				content_ele_row.style.setProperty('--padding-left', obj['padding-left'] + 'px');
			} else {
				content_ele_row.style.setProperty('--padding-left', '0px');
			}

			if (obj['padding-right'] !== null && obj['padding-right'] !== undefined) {
				content_ele_row.style.setProperty('--padding-right', obj['padding-right'] + 'px');
			} else {
				content_ele_row.style.setProperty('--padding-right', '0px');
			}

			if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
				content_ele_row.style.setProperty('--border-top', obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-color']);
			}
			if (obj['border-bottom'] !== null && obj['border-bottom'] !== undefined) {
				content_ele_row.style.setProperty('--border-bottom', obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-color']);
			}
			if (obj['border-left'] !== null && obj['border-left'] !== undefined) {
				content_ele_row.style.setProperty('--border-left', obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-color']);
			}
			if (obj['border-right'] !== null && obj['border-right'] !== undefined) {
				content_ele_row.style.setProperty('--border-right', obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-color']);
			}
			if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
				content_ele_row.style.setProperty('--border-top-left-radius', obj['border-top-left-radius'] + 'px');
			}
			if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
				content_ele_row.style.setProperty('--border-top-right-radius', obj['border-top-right-radius'] + 'px');
			}
			if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
				content_ele_row.style.setProperty('--border-bottom-left-radius', obj['border-bottom-left-radius'] + 'px');
			}
			if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
				content_ele_row.style.setProperty('--border-bottom-right-radius', obj['border-bottom-right-radius'] + 'px');
			}
		}

		return style;
	}

	getInputStyle(obj, ctrlType) {
		var style = {};

		if (obj === undefined) {
			obj = {
				color: '#495057',
				'background-color': '#fff',
				'font-weight': 'normal',
				'font-style': 'normal',
				'text-decoration': 'normal',
				'border-top': 0,
				'border-right': 0,
				'border-bottom': 0,
				'border-left': 0,
				'border-style': '',
				position: '',
				'border-color': '#495057',
				'border-top-left-radius': 0,
				'border-top-right-radius': 0,
				'border-bottom-left-radius': 0,
				'border-bottom-right-radius': 0,
			};
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['input-font-size'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style['background-color'] = obj['background-color'];
		}

		if (obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)') {
			this.pageFunctions.setBorder(style, obj, true);
		} else {
			this.pageFunctions.setBorder(style, obj, false);
		}

		this.pageFunctions.serBorderRadius(style, obj);

		return style;
	}

	gotoList() {
		this.router.navigateByUrl('/app/listing?id=' + this.Id);
	}

	ngOnInit(): void {
		this.formHeight = window.innerHeight;
		var ele: any = document.getElementById('page-parent-div');
		if (ele && ele?.offsetWidth) this.window_width = ele.offsetWidth;
	}

	ngAfterViewInit(): void {}

	setMVSectionData(idx, tblName, orderBy) {
		if (orderBy === undefined || orderBy === null || orderBy === '') {
			orderBy = 'ENTRY_DATE';
		}
		this.mvSectionDatas[idx] = [];
		this.dataService.getMVData(tblName, this.primaryId, orderBy).subscribe(
			(result) => {
				if (result['RESULT_CODE'] === 1) {
					this.mvSectionDatas[idx] = result['DATA'];
					this.setMVSectionMultiSelect(idx);
				}
			},
			(error) => {
				console.error(error);
			}
		);
	}

	setMVSectionMultiSelect(idx) {
		var dataFields = this.mvSectionColumns[idx].fields;
		var MultiSelectFields = dataFields.filter((field) => field['is_Static_Value'] === true && field['is_MultiSelect'] === true);
		MultiSelectFields.forEach((field) => {
			this.mvSectionDatas[idx].forEach((dataRow) => {
				if (dataRow[field['Field_Name']] !== null && dataRow[field['Field_Name']] !== '') {
					dataRow[field['Field_Name']] = dataRow[field['Field_Name']].split(',');
				} else {
					dataRow[field['Field_Name']] = [];
				}
			});
		});
	}

	setGridData(idx, tblName, orderBy) {
		var myGrid = this.myGrid.find((_, i) => i === idx);

		if (orderBy === undefined || orderBy === null || orderBy === '') {
			orderBy = 'ENTRY_DATE';
		}

		this.dataService.getMVData(tblName, this.primaryId, orderBy).subscribe(
			(result) => {
				myGrid = this.myGrid.find((_, i) => i === idx);

				myGrid.showloadelement();

				this.grid['datasource' + idx].localdata = [];
				this.grid['datasource' + idx].totalrecords = 0;
				if (result['RESULT_CODE'] === 1) {
					this.grid['datasource' + idx].localdata = result['DATA'];
					this.grid['datasource' + idx].totalrecords = result['RECORD_COUNT'];
					myGrid.updatebounddata();
				}
				myGrid.hideloadelement();
			},
			(error) => {
				console.error(error);
				myGrid.hideloadelement();
			}
		);
	}

	getGridData(idx, dataFields) {
		var myGrid: any = this.myGrid.find((_, i) => i === idx);
		var griddata = myGrid.getdatainformation();
		var rows: any = [];
		var row: any = 0;
		var slno = 0;
		while (row < griddata.rowscount) {
			var rowData = myGrid.getrowdata(row);
			var dataRow: any = { ID: rowData['ID'], RECORD_STATUS: rowData['RECORD_STATUS'] };

			if (rowData['RECORD_STATUS'] === '1' || rowData['RECORD_STATUS'] === 'A') {
				slno++;
			}

			dataFields.forEach((field) => {
				if (field['name'] !== '') {
					if (field['type'] === 'bool') {
						dataRow[field['name']] = rowData[field['name']] === true ? '1' : '0';
					} else if (field['type'] === 'date') {
						if (rowData[field['name']] !== null && rowData[field['name']] !== '') {
							var newdate = new Date(rowData[field['name']]);
							if (field['datatype'] === 'date') dataRow[field['name']] = this.getFormattedDate('yyyy-MM-dd', newdate.getDate(), newdate.getMonth() + 1, newdate.getFullYear());
							else dataRow[field['name']] = this.getFormattedDateTime('yyyy-MM-dd', newdate.getDate(), newdate.getMonth() + 1, newdate.getFullYear(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
						}
					} else if (field['SequenceNumber'] === undefined || field['SequenceNumber'] === 0 || field['SequenceNumber'] === false || field['SequenceNumber'] === null) {
						dataRow[field['name']] = rowData[field['name']];
					} else dataRow[field['name']] = slno.toString();
				}
			});

			rows.push(dataRow);
			row++;
		}

		return rows;
	}

	getMVSectionData(idx, dataFields) {
		var rows: any = [];
		var slno = 0;
		this.mvSectionDatas[idx].forEach((rowData) => {
			var dataRow: any = { ID: rowData['ID'], RECORD_STATUS: rowData['RECORD_STATUS'] };

			if (rowData['RECORD_STATUS'] === '1' || rowData['RECORD_STATUS'] === 'A') {
				slno++;
			}

			dataFields.forEach((field) => {
				if (field['name'] !== '') {
					if (field['type'] === 'bool') {
						dataRow[field['Field_Name']] = rowData[field['Field_Name']] === true ? '1' : '0';
					} else if (field['type'] === 'date') {
						if (rowData[field['Field_Name']] !== null && rowData[field['Field_Name']] !== '') {
							var newdate = new Date(rowData[field['name']]);
							dataRow[field['Field_Name']] = this.getFormattedDate('yyyy-MM-dd', newdate.getDate(), newdate.getMonth() + 1, newdate.getFullYear());
						}
					} else if ((field['is_Static_Value'] === true || field['is_Static_Value'] === 1) && (field['is_MultiSelect'] === true || field['is_MultiSelect'] === 1)) {
						var value = '';
						rowData[field['Field_Name']].forEach((data) => {
							if (value === '') {
								value = data;
							} else {
								value += ',' + data;
							}
						});
						dataRow[field['Field_Name']] = value;
					} else if (field['SequenceNumber'] === undefined || field['SequenceNumber'] === 0 || field['SequenceNumber'] === false || field['SequenceNumber'] === null) {
						dataRow[field['Field_Name']] = rowData[field['Field_Name']];
					} else dataRow[field['Field_Name']] = slno.toString();
				}
			});

			rows.push(dataRow);
		});

		return rows;
	}

	onCellclick(event, fields: any, idx: number) {
		const args = event.args;
		const dataField = args.datafield;
		var gridRef = event.owner;

		if (dataField === 'delete') {
			if (args.row.bounddata['RECORD_STATUS'] === 'AD') args.row.bounddata['RECORD_STATUS'] = 'A';
			else if (args.row.bounddata['RECORD_STATUS'] === 'A') args.row.bounddata['RECORD_STATUS'] = 'AD';
			else if (args.row.bounddata['RECORD_STATUS'] === '1') args.row.bounddata['RECORD_STATUS'] = 'D';
			else args.row.bounddata['RECORD_STATUS'] = '1';

			gridRef.updaterow(args.row.bounddata['ID'], args.row.bounddata);

			var rowData = gridRef.getrowdata(args.rowindex);
			var exprFields = fields.filter((fld) => fld.Expression !== undefined && fld.Expression !== '');
			exprFields.forEach((field) => {
				this.executeExpression(field.Expression, rowData);
			});

			this.updateGridSequenceNumber(idx);
		} else {
			var rowData = gridRef.getrowdata(args.rowindex);
			var fieldData = dataField.split('|');
			if (fieldData.length > 1) {
				var field = { Table_id: fieldData[0], Field_ID: fieldData[1], value: fieldData[2], Field_Name: fieldData[3] };
				this.loadMVAttachment(-1, rowData['ID'], field);
			} else {
				var selectedField = fields.filter((field) => field.Field_Name === dataField);
				if (selectedField.length > 0) {
					if (selectedField[0].DataType === 'TEXT' || selectedField[0].DataType === 'MEDIUM_STRING') {
						this.openTextDialog(args.rowindex, selectedField[0], rowData[selectedField[0].Field_Name], idx);
					}
				}
			}
		}
	}

	onCellvaluechanged(event: any, idx: number, fields: any): void {
		const args = event.args;
		var gridRef = event.owner;
		var rowData = gridRef.getrowdata(args.rowindex);
		var field = fields.filter((fld) => fld.Field_Name === args.datafield)[0];
		if (field.Expression !== undefined && field.Expression !== '') {
			this.executeExpression(field.Expression, rowData);
		}
	}

	addRowClick(idx) {
		var myGrid: any = this.myGrid.find((_, i) => i === idx);
		var newRow = Object.assign({}, this.addGridRow[idx]);
		newRow['ID'] = Guid.create().toString();
		myGrid.addrow(null, newRow);
		this.updateGridSequenceNumber(idx);
	}

	addMVRowClick(idx) {
		var newRow = Object.assign({}, this.addMVRow[idx]);
		newRow['ID'] = Guid.create().toString();
		if (this.mvSectionDatas[idx] === undefined) {
			this.mvSectionDatas[idx] = [];
		}
		this.mvSectionDatas[idx].push(newRow);
		this.updateMVSequenceNumber(idx);
	}

	delMVRowClick(idx, data) {
		if (data['RECORD_STATUS'] === 'AD') data['RECORD_STATUS'] = 'A';
		else if (data['RECORD_STATUS'] === 'A') data['RECORD_STATUS'] = 'AD';
		else if (data['RECORD_STATUS'] === '1') data['RECORD_STATUS'] = 'D';
		else data['RECORD_STATUS'] = '1';

		var fields: any = this.mvSectionColumns[idx].fields;
		var exprFields: any = fields.filter((fld) => fld.Expression !== undefined && fld.Expression !== '');
		exprFields.forEach((field) => {
			this.executeExpression(field.Expression, data);
		});

		this.updateMVSequenceNumber(idx);
	}

	updateMVSequenceNumber(idx) {
		var datafields = this.mvSectionColumns[idx];
		var slno = 0;
		if (datafields.length > 0) {
			this.mvSectionDatas[idx].forEach((rowData) => {
				if (rowData['RECORD_STATUS'] === '1' || rowData['RECORD_STATUS'] === 'A') {
					slno++;

					datafields.forEach((field) => {
						if (field['Field_Name'] !== '') {
							if (field['SequenceNumber'] !== undefined && (field['SequenceNumber'] === 1 || field['SequenceNumber'] === true)) {
								rowData[field['Field_Name']] = slno.toString();
							} else if (field['type'] !== undefined && field['type'] === 'bool') {
								if (rowData[field['Field_Name']] === null || rowData[field['Field_Name']] === '') {
									rowData[field['Field_Name']] = false;
								}
							}
						}
					});
				}
			});
		}
	}

	updateGridSequenceNumber(idx) {
		var myGrid: any = this.myGrid.find((_, i) => i === idx);
		var griddata = myGrid.getdatainformation();
		var datafields = this.grid['datasource' + idx]['datafields'];
		var row: any = 0;
		var slno = 0;
		if (datafields.length > 0) {
			while (row < griddata.rowscount) {
				var rowData = myGrid.getrowdata(row);
				if (rowData['RECORD_STATUS'] === '1' || rowData['RECORD_STATUS'] === 'A') {
					slno++;

					datafields.forEach((field) => {
						if (field['name'] !== '') {
							if (field['SequenceNumber'] !== undefined && (field['SequenceNumber'] === 1 || field['SequenceNumber'] === true)) {
								rowData[field['name']] = slno.toString();
							} else if (field['type'] !== undefined && field['type'] === 'bool') {
								if (rowData[field['name']] === null || rowData[field['name']] === '') {
									rowData[field['name']] = false;
								}
							}
						}
					});

					myGrid.updaterow(row, rowData);
				}
				row++;
			}
		}
	}

	cellclass = function (row, columnfield, value) {
		return 'mandatory-passed';
	};

	mandatoryCellclass = function (row, columnfield, value) {
		if (value === '' || value === null) {
			return 'mandatory-failed';
		} else {
			return 'mandatory-passed';
		}
	};

	setGridConfig(mvSectionIndex, section) {
		let columns: any = [];
		let datafields: any = [];

		datafields.push({ name: 'ID', type: 'string' });
		datafields.push({ name: 'RECORD_STATUS', type: 'string' });

		section['fields'].forEach((field) => {
			var self = this;
			if (field.DataType === 'ATTACHMENT' || field.DataType === 'MARKING' || field.DataType === 'DIGISIGN') {
				columns.push({
					text: field['value'],
					dataField: field['Table_id'] + '|' + field['Field_ID'] + '|' + field['value'] + '|' + field['Field_Name'],
					width: 80,
					filtertype: 'none',
					filterable: false,
					sortable: false,
					menu: false,
					columntype: 'button',
					cellsrenderer: function (row, column, value, defaultHtml, columnSettings, rowData) {
						return '<span class="fa fa-paperclip" style="font-size:18px;"></span>';
					},
				});
			} else if (field.is_Static_Value === true || field.is_Static_Value === 1) {
				columns.push({
					text: field['value'] + (field['is_Mandatory'] ? ' *' : ''),
					dataField: field['Field_Name'],
					width: 120,
					filtertype: 'none',
					hidden: field['is_Hidden'],
					filterable: false,
					sortable: false,
					menu: false,
					columntype: 'dropdownlist',
					editable: field['is_Read_Only'] === true ? false : true,
					cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass,
					createeditor: function (row, value, editor) {
						editor.jqxDropDownList({ source: self.dd[field['Field_ID']], displayMember: 'text', valueMember: 'value' });
					},
				});
				datafields.push({ name: field['Field_Name'], value: field['Field_Name'], type: 'string', datatype: 'string', values: { source: this.staticValues[field['Field_ID']], value: 'value', name: 'text', is_Mandatory: field['is_Mandatory'] } });
			} else if (field.DataType === 'BIT') {
				columns.push({
					text: field['value'],
					dataField: field['Field_Name'],
					width: 80,
					filtertype: 'none',
					hidden: field['is_Hidden'],
					filterable: false,
					sortable: false,
					menu: false,
					columntype: 'checkbox',
					editable: field['is_Read_Only'] === true ? false : true,
					cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass,
				});
				datafields.push({ name: field['Field_Name'], value: field['Field_Name'], type: 'bool', datatype: 'bool', is_Mandatory: field['is_Mandatory'] });
			} else if (field.DataType === 'DATE') {
				columns.push({
					text: field['value'] + (field['is_Mandatory'] ? ' *' : ''),
					dataField: field['Field_Name'],
					width: 100,
					filtertype: 'none',
					hidden: field['is_Hidden'],
					filterable: false,
					sortable: false,
					menu: false,
					editable: field['is_Read_Only'] === true ? false : true,
					columntype: 'datetimeinput',
					cellsformat: 'dd-MMM-yyyy',
					cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass,
				});
				datafields.push({ name: field['Field_Name'], value: field['Field_Name'], type: 'date', datatype: 'date', is_Mandatory: field['is_Mandatory'] });
			} else if (field.DataType === 'DATETIME') {
				columns.push({
					text: field['value'] + (field['is_Mandatory'] ? ' *' : ''),
					dataField: field['Field_Name'],
					width: 100,
					filtertype: 'none',
					hidden: field['is_Hidden'],
					filterable: false,
					sortable: false,
					menu: false,
					columntype: 'datetimeinput',
					cellsformat: 'dd-MMM-yyyy HH:mm:ss',
					editable: field['is_Read_Only'] === true ? false : true,
					cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass,
				});
				datafields.push({ name: field['Field_Name'], value: field['Field_Name'], type: 'date', datatype: 'datetime', is_Mandatory: field['is_Mandatory'] });
			} else if (field.DataType === 'INTEGER') {
				columns.push({
					text: field['value'] + (field['is_Mandatory'] ? ' *' : ''),
					dataField: field['Field_Name'],
					width: field['SequenceNumber'] === true ? 60 : 90,
					filtertype: 'none',
					hidden: field['is_Hidden'],
					filterable: false,
					sortable: false,
					menu: false,
					columntype: 'numberinput',
					cellsalign: 'right',
					cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass,
					editable: field['is_Read_Only'] === true || field['SequenceNumber'] === true ? false : true,
					initeditor: function (row, cellvalue, editor) {
						editor.jqxNumberInput({ decimalDigits: 0 });
					},
				});
				datafields.push({ name: field['Field_Name'], value: field['Field_Name'], type: 'number', datatype: 'int', SequenceNumber: field['SequenceNumber'], is_Mandatory: field['is_Mandatory'] });
			} else if (field.DataType === 'DECIMAL' || field.DataType === 'PERCENTAGE') {
				columns.push({
					text: field['value'] + (field['is_Mandatory'] ? ' *' : ''),
					dataField: field['Field_Name'],
					width: 90,
					filtertype: 'none',
					hidden: field['is_Hidden'],
					filterable: false,
					sortable: false,
					menu: false,
					columntype: 'numberinput',
					cellsalign: 'right',
					cellsformat: 'f' + field['Length'],
					editable: field['is_Read_Only'] === true ? false : true,
					cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass,
					initeditor: function (row, cellvalue, editor) {
						editor.jqxNumberInput({ decimalDigits: parseInt(field['Length']) });
					},
				});
				datafields.push({ name: field['Field_Name'], value: field['Field_Name'], type: 'number', datatype: 'decimal', is_Mandatory: field['is_Mandatory'] });
			} else {
				columns.push({ text: field['value'] + (field['is_Mandatory'] ? ' *' : ''), dataField: field['Field_Name'], hidden: field['is_Hidden'], filterable: false, sortable: false, menu: false, cellclassname: field['is_Mandatory'] === true ? this.mandatoryCellclass : this.cellclass, editable: field['is_Read_Only'] === true || field['SequenceNumber'] === true ? false : true });
				datafields.push({ name: field['Field_Name'], type: 'string', datatype: 'string', SequenceNumber: field['SequenceNumber'], is_Mandatory: field['is_Mandatory'] });
			}
		});

		columns.push({
			text: '',
			dataField: 'delete',
			width: 50,
			filtertype: 'none',
			filterable: false,
			sortable: false,
			menu: false,
			columntype: 'button',
			cellsrenderer: function (row, column, value, defaultHtml, columnSettings, rowData) {
				if (rowData['RECORD_STATUS'] === 'D' || rowData['RECORD_STATUS'] === 'AD') {
					return '<span class="fa fa-trash-alt" style="font-size:18px;color:red;"></span>';
				} else {
					return '<span class="fa fa-trash" style="font-size:18px;color:blue;"></span>';
				}
			},
		});

		this.grid['columns' + mvSectionIndex] = columns;

		this.grid['datasource' + mvSectionIndex] = {
			localdata: null,
			datatype: 'json',
			datafields: datafields,
			totalrecords: 0,
			id: 'ID',
			addrow: function (rowid, rowdata, position, commit) {
				commit(true);
			},
			updaterow: function (rowid, newdata, commit) {
				commit(true);
			},
		};

		this.grid['gridAdapter' + mvSectionIndex] = new jqx.dataAdapter(this.grid['datasource' + mvSectionIndex]);
		this.addGridRow[mvSectionIndex] = {};
		datafields.forEach((field) => {
			if (field['name'] === 'RECORD_STATUS') this.addGridRow[mvSectionIndex][field['name']] = 'A';
			else if (field['type'] === 'bool') this.addGridRow[mvSectionIndex][field['name']] = false;
			else this.addGridRow[mvSectionIndex][field['name']] = null;
		});
	}

	showSelect(form) {
		var lookupFields = form.fields.filter((field) => field.is_Lookup === true);
		var isLookupExists: boolean = false;
		isLookupExists = lookupFields.length > 0;
		return isLookupExists;
	}

	getStaticSQLData(Ctrl_Name, Select_SQL) {
		if (Select_SQL.includes('#')) {
			for (const key in this.sessionInfo) {
				var fieldName = '#' + key + '#';
				var fieldValue = this.sessionInfo[key];
				Select_SQL.replaceAll(fieldName, fieldValue);
			}
		}
		this.dataService.getSelectOptions(Select_SQL).subscribe(
			(result) => {
				if (result['RESULT_CODE'] === 1) {
					this.staticValues[Ctrl_Name] = result['DATA'];
				}
			},
			(error) => {
				console.error(error);
			}
		);
	}

	selectMVSectionLookup(lookupField, idx, dataRowID) {
		this.loadLookup([], lookupField, lookupField.Lookup_ID, 'MVSection', lookupField.Input_Params, lookupField.Outfill_Params, idx, dataRowID);
	}

	selectMVTableLookup(section, idx) {
		var lookupField = section.fields.filter((attr) => attr.is_Lookup === true)[0];
		this.loadLookup([], lookupField, lookupField.Lookup_ID, 'Table', lookupField.Input_Params, lookupField.Outfill_Params, idx, '');
	}

	showLookup(section_fields, field) {
		this.loadLookup(section_fields, field, field.Lookup_ID, 'Single', field.Input_Params, field.Outfill_Params, '', '');
	}

	getAPIData(field, fields) {
		var outfillFields = field['Outfill_Params'];
		var inputParams = [];
		var inputFields = field['Input_Params'];
		if (inputFields.length > 0) {
			inputFields.forEach((fld) => {
				inputParams.push({ Param: fld['Param'], Value: this.pageValues[fld['Value']] });
			});
		}
		var error = false;
		var status_code = 200;
		this.dataService.getAPIData(field['API_ID'], inputParams).subscribe(
			(res) => {
				status_code = res['STATUS_CODE'];
				outfillFields.forEach((fld) => {
					if (fld.Value !== null && fld.Value !== '') {
						var SVFields = fields.filter((attr) => attr['Field_ID'].toString() === fld.Value);
						if (fld['Param'].includes('.')) {
							var params = fld['Param'].split('.');
							if (res[params[0]] !== undefined) {
								if (params.length === 4) {
									this.pageValues[fld['Value']] = res[params[0]][params[1]][params[2]][params[3]];
								} else if (params.length === 3) {
									this.pageValues[fld['Value']] = res[params[0]][params[1]][params[2]];
								} else if (params.length === 2) {
									this.pageValues[fld['Value']] = res[params[0]][params[1]];
								} else if (params.length === 1) {
									this.pageValues[fld['Value']] = res[params[0]];
								}
								if (SVFields[0]['Expression'] !== undefined && SVFields[0]['Expression'] !== '') {
									this.onValueChange(SVFields[0]);
								}
							} else {
								error = true;
							}
						} else {
							if (res[fld['Param']] !== undefined) {
								if (fld['Param'] === 'STATUS_CODE' || fld['Param'] === 'status_code') {
									this.pageValues[fld['Value']] = res['STATUS_CODE'];
								} else {
									this.pageValues[fld['Value']] = res[fld['Param']];
								}
								if (SVFields[0]['Expression'] !== undefined && SVFields[0]['Expression'] !== '') {
									this.onValueChange(SVFields[0]);
								}
							} else {
								error = true;
							}
						}
					}
				});
				if (error) {
					if (res['RESULT_MESSAGE'] !== undefined) {
						this.toastr.info('Information', res['RESULT_MESSAGE'], { timeOut: 3000 });
					} else if (status_code !== 200) {
						this.toastr.info('Information', 'Status Code : ' + status_code + ' returned.', { timeOut: 3000 });
					}
				}
			},
			(error) => {
				this.toastr.error('Error', 'Something went wrong.. try after sometime!!', { timeOut: 3000 });
			}
		);
	}

	setPageValue(field, value) {
		this.pageValues[field['Field_ID']] = value;
		if (field.Expression !== undefined && field.Expression !== null && field.Expression !== '') {
			this.onValueChange(field);
		}
	}

	showReport() {
		var url = 'report_page?id=' + this.ReportId + '&pkid=' + this.primaryId;
		window.open(url, '_blank');
	}

	loadLookup(section_fields, field, lkupId, lkupType, inputParams, outfillParams, gridRef, dataRowID) {
		var cond = '';
		var fields: any = [];
		var dataRow: any = {};
		var lookupInfo = this.lookups[lkupId];
		this.lookup.Lookup_ID = lkupId;
		this.lookup.Lookup_Name = lookupInfo.Lookup_Name;
		this.lookup.Lookup_Type = lkupType;
		this.lookup.OutfillParams = outfillParams;
		this.lookup.GridRef = gridRef;
		this.lookup.ExpressionID = field.Expression;
		this.lookup.dataRowID = dataRowID;
		this.lookup.Section_Fields = section_fields;

		if (gridRef !== '' && dataRowID !== '') {
			fields = this.mvSectionColumns[gridRef].fields;
			dataRow = this.mvSectionDatas[gridRef].filter((row) => row.ID === dataRowID);
		}

		inputParams.forEach((param) => {
			var param_field_id = parseInt(param['Value']);
			if (gridRef === '' || dataRowID === '') {
				if (param['Value_Type'] === 'Parameter') {
					if (param['Operation'] === 'Like') {
						if (param['Alias'] === 'Temp') cond += ' AND `' + param['Field_Name'] + '` ' + param['Operation'] + " '%" + this.pageValues[param_field_id] + "%' ";
						else cond += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '%" + this.pageValues[param_field_id] + "%' ";
					} else {
						if (param['Alias'] === 'Temp') cond += ' AND `' + param['Field_Name'] + '` ' + param['Operation'] + " '" + this.pageValues[param_field_id] + "' ";
						else cond += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '" + this.pageValues[param_field_id] + "' ";
					}
				}
			} else {
				var field = fields.filter((field) => field.Field_ID.toString() === param_field_id.toString());
				if (field.length > 0 && dataRow.length > 0) {
					if (param['Value_Type'] === 'Parameter') {
						if (param['Operation'] === 'Like') {
							if (param['Alias'] === 'Temp') cond += ' AND `' + param['Field_Name'] + '` ' + param['Operation'] + " '%" + dataRow[0][field[0]['Field_Name']] + "%' ";
							else cond += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '%" + dataRow[0][field[0]['Field_Name']] + "%' ";
						} else {
							if (param['Alias'] === 'Temp') cond += ' AND `' + param['Field_Name'] + '` ' + param['Operation'] + " '" + dataRow[0][field[0]['Field_Name']] + "' ";
							else cond += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '" + dataRow[0][field[0]['Field_Name']] + "' ";
						}
					}
				} else {
					if (param['Value_Type'] === 'Parameter') {
						if (param['Operation'] === 'Like') {
							if (param['Alias'] === 'Temp') cond += ' AND `' + param['Field_Name'] + '` ' + param['Operation'] + " '%" + this.pageValues[param_field_id] + "%' ";
							else cond += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '%" + this.pageValues[param_field_id] + "%' ";
						} else {
							if (param['Alias'] === 'Temp') cond += ' AND `' + param['Field_Name'] + '` ' + param['Operation'] + " '" + this.pageValues[param_field_id] + "' ";
							else cond += ' AND `' + param['Alias'] + '`.`' + param['Field_Name'] + '` ' + param['Operation'] + " '" + this.pageValues[param_field_id] + "' ";
						}
					}
				}
			}
		});
		this.lookup.InputParams = cond;

		if (field['is_AutoLoad'] !== undefined && field['is_AutoLoad'] !== null && field['is_AutoLoad'] === true) {
			this.autoLoadLookup();
		} else {
			this.openLookupDialog();
		}
	}

	autoLoadLookup() {
		var dataFields = this.grid['datasource' + this.lookup['GridRef']]['datafields'];
		var gridData = this.getGridData(this.lookup['GridRef'], dataFields);
		if (gridData.length === 0) {
			this.dataService.getLookupData(this.lookup.Lookup_ID, this.lookup['InputParams'], 0, 999, '').subscribe(
				(data) => {
					this.onMVLookupSelected(data['DATA']);
				},
				(error) => {
					console.error(error);
				}
			);
		} else {
			this.toastr.info('Already selected..', 'Information');
		}
	}

	openLookupDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.hasBackdrop = true;
		dialogConfig.height = '80%';
		dialogConfig.width = '90%';

		const dialogRef = this.dialog.open(LookupComponent, dialogConfig);
		dialogRef.componentInstance.Lookup = this.lookup;

		const subMulti = dialogRef.componentInstance.MultiSelectData.subscribe((item: any) => {
			this.onMVLookupSelected(item);
		});
		const subSingle = dialogRef.componentInstance.SingleSelectData.subscribe((item: any) => {
			this.onLookupSelected(item);
			if (this.lookup.ExpressionID !== undefined && this.lookup.ExpressionID !== null && this.lookup.ExpressionID !== '') {
				this.executeExpression(this.lookup.ExpressionID, {});
			}
			dialogRef.componentInstance.close();
			dialogRef.close();
		});
	}

	onMVLookupSelected(datas) {
		var myGrid = this.myGrid.find((_, i) => i === this.lookup['GridRef']);
		var outfill = this.lookup['OutfillParams'];
		var slno = 0;
		var uuidRow = Guid.create().toString();
		var newRowFields = this.gridColumns[this.lookup['GridRef']]['fields'];
		myGrid?.showloadelement();
		datas.forEach((data) => {
			slno++;
			var newRow = {};
			newRow['ID'] = uuidRow + '-' + slno;
			newRow['RECORD_STATUS'] = 'A';
			for (var idx = 0; idx < outfill.length; idx++) {
				if (outfill[idx]['Value'] !== undefined && outfill[idx]['Value'] !== '' && outfill[idx]['Value'] !== null) {
					var outfillField = newRowFields.filter((field) => field.Field_ID.toString() === outfill[idx]['Value'].toString())[0];
					if (outfillField['Field_Name'] !== undefined && outfillField['Field_Name'] !== '' && outfill[idx]['Field_Name'] !== undefined && outfill[idx]['Field_Name'] !== '') {
						newRow[outfillField['Field_Name']] = data[outfill[idx]['Field_Name']];
					}
				}
			}
			myGrid?.addrow(null, newRow);
		});
		this.updateGridSequenceNumber(this.lookup['GridRef']);
		myGrid?.hideloadelement();
	}

	onLookupSelected(data) {
		if (this.lookup.Lookup_Type === 'Single') {
			var keys = Object.keys(data);
			for (var i = 0; i < keys.length; i++) {
				this.pageValues[keys[i]] = data[keys[i]];
				var field = this.lookup.Section_Fields.filter((field) => field.Field_ID.toString() === keys[i].toString());
				if (field.length > 0) {
					if (field[0].Expression !== undefined && field[0].Expression !== null && field[0].Expression !== '') {
						this.onValueChange(field[0]);
					}
				}
			}
		} else if (this.lookup.Lookup_Type === 'MVSection') {
			var keys = Object.keys(data);
			var fields = this.mvSectionColumns[this.lookup.GridRef].fields;
			this.mvSectionDatas[this.lookup.GridRef].forEach((row) => {
				if (row.ID === this.lookup.dataRowID) {
					for (var i = 0; i < keys.length; i++) {
						var field = fields.filter((field) => field.Field_ID.toString() === keys[i].toString());
						if (field.length > 0) {
							row[field[0]['Field_Name']] = data[keys[i]];
							if (field[0].Expression !== undefined && field[0].Expression !== null && field[0].Expression !== '') {
								this.onMVSectionValueChange(field[0], row);
							}
						}
					}
				}
			});
		}
	}

	getSectionFieldID(section_id, field_name) {
		var attribute = this.section_attributes.filter((attr) => attr.section_id === section_id && attr.Field_Name === field_name)[0];
		return attribute['Field_ID'];
	}

	initializeData(ScreenID) {
		var ddStatus = {}; //to maintain dropdown result status of multi value section
		var ddBoolStatus = false;

		var designType = 'DESKTOP';

		if (this.window_width < 700) {
			designType = 'MOBILE';
		} else if (this.window_width < 1025) {
			designType = 'TABLET';
		}

		this.dataService.getScreenAttributes(ScreenID, designType).subscribe(
			(attrs: any) => {
				this.section_attributes = attrs.DATA;
				var SVFields = this.section_attributes.filter((attr) => attr['Section_Type'] === 'Single');
				SVFields.forEach((field) => {
					if (field.DataType === 'ATTACHMENT' || field.DataType === 'MARKING') {
						this.attachments[field['Field_ID']] = 0;

						if (field.Display_Image === 1) {
							this.image_attachments[field['Field_ID']] = [{ ATTACHMENT_URL: '/assets/img/dummyImage.jpg' }];
						}
						if (this.oper === 'EDIT') {
							this.getAttachmentCount(field['Field_ID'], field['Data_Field_Name']);
							this.getImageAttachments(field.Display_Image === 1 ? true : false, field['Field_ID'], field['Data_Field_Name']);
						}
					}

					if (this.oper === 'ADD' && field.DefaultValue !== null && field.DefaultValue !== '') {
						if (field.DefaultValue === 'USER_NAME') {
							this.pageValues[field['Field_ID']] = this.sessionInfo['USER_NAME'];
						} else if (field.DefaultValue === 'USER_EMAIL' || field.DefaultValue === 'EMAIL_ADDRESS') {
							this.pageValues[field['Field_ID']] = this.sessionInfo['EMAIL_ADDRESS'];
						} else if (field.DefaultValue === 'USER_ID') {
							this.pageValues[field['Field_ID']] = this.sessionInfo['USER_ID'];
						} else if (field.DefaultValue === 'TODAY') {
							if (field.DataType === 'DATE') this.pageValues[field['Field_ID']] = this.getCurrentDate();
							else this.pageValues[field['Field_ID']] = this.getCurrentDateTime();
						} else if (field.DataType === 'BIT') {
							if (field.DefaultValue === null || field.DefaultValue === '' || field.DefaultValue === '0') {
								this.pageValues[field['Field_ID']] = false;
							} else {
								this.pageValues[field['Field_ID']] = true;
							}
						} else {
							if (this.sessionInfo[field.DefaultValue] !== undefined) {
								this.pageValues[field['Field_ID']] = this.sessionInfo[field.DefaultValue];
							} else {
								this.pageValues[field['Field_ID']] = field.DefaultValue;
							}
						}
					} else if (this.oper === 'ADD' && field['is_Static_Value'] === 1) {
						if (field['is_MultiSelect'] === 1) {
							this.pageValues[field['Field_ID']] = [];
						} else {
							this.pageValues[field['Field_ID']] = '';
						}
					} else if (this.oper === 'ADD') {
						if (field.DataType === 'BIT') {
							this.pageValues[field['Field_ID']] = true;
						} else {
							this.pageValues[field['Field_ID']] = null;
						}
					}
				});

				if (this.oper === 'EDIT') {
					this.updateSVSectionData();
				}

				attrs.DATA.filter((attr) => attr.Section_Type === 'Multi' && attr.Display_Type === 'Table' && attr['is_Static_Value'] === 1).forEach((field) => {
					ddStatus[field['Field_ID']] = 'P';
					ddBoolStatus = true;
				});

				attrs.DATA.forEach((attr) => {
					if (attr['is_Static_Value'] === 1) {
						this.staticValues[attr['Field_ID']] = [];

						if (attr['Select_SQL'] !== '') {
							if (attr['Select_SQL'].includes('#')) {
								for (const key in this.sessionInfo) {
									var fieldName = '#' + key + '#';
									var fieldValue = this.sessionInfo[key];
									attr['Select_SQL'].replaceAll(fieldName, fieldValue);
								}
							}
							this.dataService.getSelectOptions(attr['Select_SQL']).subscribe(
								(result) => {
									if (result['RESULT_CODE'] === 1) {
										if (attr['is_MultiSelect'] === 1) {
											this.staticValues[attr['Field_ID']] = this.getStaticTextArray(result['DATA']);
										} else {
											this.staticValues[attr['Field_ID']] = result['DATA'];
										}

										if (attr.Section_Type === 'Multi' && attr.Display_Type === 'Table') {
											var staticSource = {
												datatype: 'json',
												datafields: [
													{ name: 'text', type: 'string' },
													{ name: 'value', type: 'string' },
												],
												localdata: [],
											};

											staticSource.localdata = this.staticValues[attr['Field_ID']];
											this.dd[attr['Field_ID']] = new jqx.dataAdapter(staticSource);
											ddStatus[attr['Field_ID']] = 'C';

											this.checkDDStatus(ddStatus);
										} else if (attr.Section_Type === 'Single') {
											if (this.oper === 'ADD' && attr.Section_Type === 'Single' && result['DATA'].length > 0 && attr['is_Mandatory'] === 1) {
												if (attr['is_MultiSelect'] === 1) {
													var arr: any = [];
													//arr.push(result['DATA'][0]['value']);
													this.pageValues[attr['Field_ID']] = arr;
												} else {
													this.pageValues[attr['Field_ID']] = result['DATA'][0]['value'];
												}
											}
										}
									}
								},
								(error) => {
									if (attr.Section_Type === 'Multi' && attr.Display_Type === 'Table') {
										var staticSource = {
											datatype: 'json',
											datafields: [
												{ name: 'text', type: 'string' },
												{ name: 'value', type: 'string' },
											],
											localdata: [],
										};
										this.dd[attr['Field_ID']] = new jqx.dataAdapter(staticSource);
										ddStatus[attr['Field_ID']] = 'C';
										this.checkDDStatus(ddStatus);
									}
									console.error(error);
								}
							);
						} else if (attr['Static_Value_JSON'] !== '') {
							if (attr['is_MultiSelect'] === 1) {
								this.staticValues[attr['Field_ID']] = this.getStaticTextArray(JSON.parse(attr['Static_Value_JSON']));
							} else {
								this.staticValues[attr['Field_ID']] = JSON.parse(attr['Static_Value_JSON']);
							}

							if (attr.Section_Type === 'Multi' && attr.Display_Type === 'Table') {
								var staticSource = {
									datatype: 'json',
									datafields: [
										{ name: 'text', type: 'string' },
										{ name: 'value', type: 'string' },
									],
									localdata: [],
								};
								staticSource.localdata = this.staticValues[attr['Field_ID']];
								this.dd[attr['Field_ID']] = new jqx.dataAdapter(staticSource);
								ddStatus[attr['Field_ID']] = 'C';

								this.checkDDStatus(ddStatus);
							}
						}
					} else if (attr['is_Lookup'] === 1) {
						this.lookups[attr['Lookup_ID']] = {
							Lookup_ID: attr['Lookup_ID'],
							Lookup_Name: attr['Lookup_Name'],
							Lookup_Type: attr['Lookup_Type'],
							GridRef: '',
						};
					}
				});

				if (ddBoolStatus === false) {
					this.updateMVSectionIndex();
					this.updateMVSectionData();
				}

				setTimeout(() => {
					this.loadPageExpression();
					this.loadPageExpressionMVSections();
				}, 2000);
			},
			(error) => {
				console.error(error);
			}
		);
	}

	setDate(selectedDate, fieldId) {
		try {
			var curDate = new Date(selectedDate);
			if (!isNaN(curDate.getDate()) && !isNaN(curDate.getMonth())) {
				this.pageValues[fieldId] = this.getFormattedDate('yyyy/MM/dd', curDate.getDate(), curDate.getMonth() + 1, curDate.getFullYear());
			} else {
				this.pageValues[fieldId] = null;
			}
		} catch (ex) {
			this.pageValues[fieldId] = null;
		}
	}

	setDateTime(selectedDateTime, fieldId) {
		try {
			var curDate = new Date(selectedDateTime);
			if (!isNaN(curDate.getDate()) && !isNaN(curDate.getMonth())) {
				this.pageValues[fieldId] = this.getFormattedDateTime('yyyy/MM/dd', curDate.getDate(), curDate.getMonth() + 1, curDate.getFullYear(), curDate.getHours(), curDate.getMinutes(), curDate.getSeconds());
			} else {
				this.pageValues[fieldId] = null;
			}
		} catch (ex) {
			this.pageValues[fieldId] = null;
		}
	}

	getStaticTextArray(datas) {
		var retArray: any = [];
		datas.forEach((data) => {
			retArray.push(data['text']);
		});
		return retArray;
	}

	getImageSrc(field) {
		if (this.pageValues[field.Field_ID] === '' || this.pageValues[field.Field_ID] === null) return '/assets/img/dummyImage.jpg';
		else return this.pageValues[field.Field_ID];
	}

	getMVImageSrc(field, pageValues) {
		if (pageValues[field.Field_Name] === '' || pageValues[field.Field_Name] === null) return '/assets/img/dummyImage.jpg';
		else return pageValues[field.Field_Name];
	}

	checkDDStatus(ddStatus) {
		var allComplete = true;
		for (let dd in ddStatus) {
			if (ddStatus[dd] === 'P') {
				allComplete = false;
			}
		}
		if (allComplete === true) {
			this.updateMVSectionIndex();
			this.updateMVSectionData();
		}
	}

	setMVTextData(rowId, fieldName, fieldData, gridRef) {
		var myGrid = this.myGrid.find((_, i) => i === gridRef);
		myGrid.setcellvalue(rowId, fieldName, fieldData);
	}

	openTextDialog(rowId, field, fieldData, gridRef) {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.hasBackdrop = true;
		dialogConfig.height = '50%';
		dialogConfig.width = '50%';

		const dialogRef = this.dialog.open(TextDialogComponent, dialogConfig);
		dialogRef.componentInstance.FieldInfo = field;
		dialogRef.componentInstance.FieldData = fieldData;

		const closeAttachment = dialogRef.componentInstance.Close.subscribe((data: any) => {
			this.setMVTextData(rowId, field['Field_Name'], data, gridRef);
		});
	}

	getImageAttachments(Display_Image, fieldID, fieldName) {
		if (Display_Image !== undefined && Display_Image === true) {
			this.dataService.getImageAttachments(this.primaryId + fieldName).subscribe((result) => {
				if (result['RESULT_CODE'] === 1) {
					if (result['DATA'].length > 0) this.image_attachments[fieldID] = result['DATA'];
					else this.image_attachments[fieldID] = [{ ATTACHMENT_URL: '/assets/img/dummyImage.jpg' }];
				}
			});
		}
	}

	getAttachmentCount(fieldId, fieldName) {
		this.dataService.getAttachmentList(this.primaryId + fieldName).subscribe((result) => {
			if (result['RESULT_CODE'] === 1) {
				this.attachments[fieldId] = result['RECORD_COUNT'];
			}
		});
	}

	openAttachmentDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.hasBackdrop = true;
		if (this.attachment.DataType === 'UPLOAD') {
			dialogConfig.height = '170px';
			dialogConfig.width = '520px';
		} else {
			dialogConfig.height = '80%';
			dialogConfig.width = '60%';
		}

		const dialogRef = this.dialog.open(AttachmentComponent, dialogConfig);
		dialogRef.componentInstance.AttachmentInfo = this.attachment;

		const closeAttachment = dialogRef.componentInstance.Close.subscribe((item: any) => {
			this.onAttachment(item);
		});
	}

	loadMVAttachment(Idx, mvId, field): void {
		this.attachment.HeaderName = field.value;
		this.attachment.PageID = this.Id;
		this.attachment.TableID = field.Table_id;
		this.attachment.FieldID = field.Field_Name;
		this.attachment.TransID = mvId;
		this.attachment.CtrlName = field.Field_ID;
		this.attachment.DataType = field.DataType;
		this.attachment.Display_Image = false;
		this.attachment.MVSectionID = Idx;
		this.attachment.MaxWidth = 0;
		this.attachment.MaxHeight = 0;
		this.attachment.MaxSize = 0;

		this.openAttachmentDialog();
	}

	loadAttachment(field): void {
		this.attachment.HeaderName = field.value;
		this.attachment.PageID = this.Id;
		this.attachment.TableID = field.Table_id;
		this.attachment.FieldID = field.Field_Name;
		this.attachment.TransID = this.primaryId;
		this.attachment.CtrlName = field.Field_ID;
		this.attachment.DataType = field.DataType;
		this.attachment.Display_Image = field.Display_Image === undefined ? false : field.Display_Image;
		this.attachment.MaxWidth = field.MaxWidth === undefined || field.MaxWidth === null ? 0 : field.MaxWidth;
		this.attachment.MaxHeight = field.MaxHeight === undefined || field.MaxHeight === null ? 0 : field.MaxHeight;
		this.attachment.MaxSize = field.MaxSize === undefined || field.MaxSize === null ? 0 : field.MaxSize;
		this.attachment.MVSectionID = -1;

		this.openAttachmentDialog();
	}

	onAttachment(data): void {
		if (data !== '') {
			if (this.attachment.MVSectionID === -1) {
				this.attachments[this.attachment.CtrlName] = data;
				this.pageValues[this.attachment.CtrlName] = data;
				this.getImageAttachments(this.attachment.Display_Image, this.attachment.CtrlName, this.attachment.FieldID);
			} else if (this.attachment.MVSectionID >= 0) {
				this.mvSectionDatas[this.attachment.MVSectionID].forEach((tran) => {
					if (tran.ID === this.attachment.TransID) {
						tran[this.attachment.FieldID] = data;
					}
				});
			}
		}
	}

	getCFHTML(content) {
		content.htmlvalue = this.sanitizer.bypassSecurityTrustHtml(content.value);
		return content.htmlvalue;
	}

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	validatePercent(fieldId) {
		if (parseFloat(this.pageValues[fieldId]) > 100) {
			this.pageValues[fieldId] = 100;
		}
	}

	validateMVSectionPercent(fieldId, pageValues) {
		if (parseFloat(pageValues[fieldId]) > 100) {
			pageValues[fieldId] = 100;
		}
	}

	OnClickExpressions(exprId) {
		var retValue = true;
		var variableValues = {};
		var validCondition = false;

		var clickExpressions: any = this.expressions.filter((expr) => expr.ID === exprId);
		clickExpressions.forEach((expression) => {
			variableValues = {};
			validCondition = this.evaluateCondition(expression, {});
			if (validCondition) {
				expression['Steps'].forEach((step) => {
					if (step['Field_Type'] === 'Field') {
						this.setFieldValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Variable') {
						this.setVariableValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Expression') {
						retValue = this.executeExpression(step['Field_ID'], {});
					} else if (step['Field_Type'] === 'Message') {
						this.displayMessage(step['Field_ID']);
					} else if (step['Field_Type'] === 'Row') {
						this.setRowProperty(step['Field_ID'], step['Field_Value']);
					} else if (step['Field_Type'] === 'DisableSave') {
						retValue = false;
					}
				});
			}
		});

		return retValue;
	}

	OnSaveExpressions() {
		var retValue = true;

		var saveExpressions: any = [];
		var variableValues = {};
		var validCondition = false;

		saveExpressions = this.expressions.filter((expr) => expr.Type !== undefined && expr.Type === 'On Save');
		saveExpressions.forEach((expression) => {
			variableValues = {};
			validCondition = this.evaluateCondition(expression, {});
			if (validCondition) {
				expression['Steps'].forEach((step) => {
					if (step['Field_Type'] === 'Field') {
						this.setFieldValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Variable') {
						this.setVariableValue(step, variableValues, {});
					} else if (step['Field_Type'] === 'Expression') {
						retValue = this.executeExpression(step['Field_ID'], {});
					} else if (step['Field_Type'] === 'Message') {
						this.displayMessage(step['Field_ID']);
					} else if (step['Field_Type'] === 'DisableSave') {
						retValue = false;
					}
				});
			}
		});

		return retValue;
	}

	MVMandatoryCheck(tableData, dataFields) {
		var isTableDataValid = true;
		if (tableData.length > 0) {
			var mandatoryFields = dataFields.filter((field) => field.is_Mandatory === true);
			tableData.forEach((rowData) => {
				if (rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD') {
					mandatoryFields.forEach((field) => {
						if (rowData[field.name] === null || rowData[field.name] === '') {
							isTableDataValid = false;
						}
					});
				}
			});
		}
		return isTableDataValid;
	}

	MVSectionMandatoryCheck(tableData, dataFields) {
		var isTableDataValid = true;
		if (tableData.length > 0) {
			var mandatoryFields = dataFields.filter((field) => field.is_Mandatory === true);
			tableData.forEach((rowData) => {
				if (rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD') {
					mandatoryFields.forEach((field) => {
						if (rowData[field.Field_Name] === null || rowData[field.Field_Name] === '') {
							isTableDataValid = false;
						}
					});
				}
			});
		}
		return isTableDataValid;
	}

	MVMinimumRecordsCheckTable() {
		const tables = this.rows.reduce((acc, row) => {
			return acc.concat(
				row.columns.reduce((innerAcc, column) => {
					return innerAcc.concat(column.contents.filter((content) => content.type === this.TABLE_KEY && content.form.minimum_records !== undefined && content.form.minimum_records !== null && content.form.minimum_records > 0));
				}, [])
			);
		}, []);

		tables.forEach((item) => {
			var dataFields = this.grid['datasource' + item.index]['datafields'];
			var gridData = this.getGridData(item.index, dataFields);
			var validGridData = gridData.filter((rowData) => rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD');
			if (validGridData.length < item.form.minimum_records) {
				return false;
			}
		});

		return true;
	}

	MVMinimumRecordsCheckSection() {
		const tables = this.rows.reduce((acc, row) => {
			return acc.concat(
				row.columns.reduce((innerAcc, column) => {
					return innerAcc.concat(column.contents.filter((content) => content.type === 'MVSection' && content.form.minimum_records !== undefined && content.form.minimum_records !== null && content.form.minimum_records > 0));
				}, [])
			);
		}, []);

		tables.forEach((item) => {
			var dataFields = this.mvSectionColumns[item.index]['fields'];
			var mvData = this.getMVSectionData(item.index, dataFields);
			var validGridData = mvData.filter((rowData) => rowData['RECORD_STATUS'] !== 'D' && rowData['RECORD_STATUS'] !== 'AD');
			if (validGridData.length < item.form.minimum_records) {
				return false;
			}
		});

		return true;
	}

	submitForm($ev, nextPage, API_ID, Input_Fields) {
		if (this.isSaving) {
			this.toastr.info('Info', 'Saving records. Please wait...', { timeOut: 1000 });
			return;
		}
		$ev.preventDefault();
		this.saveTried = true;
		var isValid: boolean = true;
		var isExprValid: boolean = true;
		var primaryTableName = '';
		let mvcheckIdx = 0;
		let mvSectionIdx = 0;
		var encryptedFields: any = [];
		var primaryKeyFields: any = [];

		if (API_ID === undefined || API_ID === null) {
			API_ID = '';
			Input_Fields = [];
		}

		var MultiSelectFields = this.section_attributes.filter((attr) => attr['Section_Type'] === 'Single' && attr['is_Static_Value'] === 1 && attr['is_MultiSelect'] === 1);
		MultiSelectFields.forEach((field) => {
			var value = '';
			var arrValue: any = this.pageValues[field['Field_ID']];
			arrValue.forEach((element) => {
				if (value === '') {
					value = element;
				} else {
					value += ',' + element;
				}
			});
			this.pageValues[field['Field_ID']] = value;
		});

		isExprValid = this.OnSaveExpressions();

		var MandatoryFields = this.section_attributes.filter((attr) => attr['Section_Type'] === 'Single' && attr['is_Mandatory'] === 1);
		MandatoryFields.forEach((field) => {
			if (this.pageValues[field['Field_ID']] === null || this.pageValues[field['Field_ID']] === '') {
				isValid = false;
			}
		});

		this.screen_sections.forEach((section) => {
			if (section.Section_Type === 'Multi' && section.Display_Type === 'Table') {
				var dataFields = this.grid['datasource' + mvcheckIdx]['datafields'];
				var gridData = this.getGridData(mvcheckIdx, dataFields);
				var isTableDataValid = this.MVMandatoryCheck(gridData, dataFields);
				if (isTableDataValid === false) {
					isValid = false;
				}
				mvcheckIdx++;
			} else if (section.Section_Type === 'Multi' && section.Display_Type === 'MVSection') {
				var dataFields = this.mvSectionColumns[mvSectionIdx]['fields'];
				var mvData = this.getMVSectionData(mvSectionIdx, dataFields);
				var isTableDataValid = this.MVSectionMandatoryCheck(mvData, dataFields);
				if (isTableDataValid === false) {
					isValid = false;
				}
				mvSectionIdx++;
			}
		});

		if (isValid) {
			isValid = this.MVMinimumRecordsCheckTable();
			if (!isValid) {
				this.toastr.error('Error', 'Grid must have minimum records', { timeOut: 1000 });
				return;
			}
			isValid = this.MVMinimumRecordsCheckSection();
			if (!isValid) {
				this.toastr.error('Error', 'Grid must have minimum records', { timeOut: 1000 });
				return;
			}
		} else {
			this.toastr.warning('Cannot Save', 'Mandatory fields not filled out', { timeOut: 3000 });
			return;
		}

		if (isExprValid) {
			let pageDatas = {};
			let idx = 0;
			let mvIdx = 0;
			let mvSectionIdx = 0;
			var dataFields: any = [];

			var inputParams: any = [];
			if (Input_Fields.length > 0) {
				Input_Fields.forEach((fld) => {
					inputParams.push({ Param: fld['Param'], Value: this.pageValues[fld['Value']] });
				});
			}
			this.screen_sections.forEach((section) => {
				if (section.Section_Type === 'Single') {
					if (primaryTableName === '') {
						primaryTableName = section['Table_Name'];
					}

					if (pageDatas[section['Table_Name']] === undefined) {
						pageDatas[section['Table_Name']] = {};
					}

					let fields = this.section_attributes.filter((attr) => attr['Section_ID'] === section.ID);

					fields.forEach((field) => {
						if (field['DataType'] === 'BIT') {
							pageDatas[section['Table_Name']][field['Data_Field_Name']] = this.pageValues[field['Field_ID']] === true || this.pageValues[field['Field_ID']] === '1' ? '1' : '0';
						} else if (field['Field_ID'] !== '') {
							pageDatas[section['Table_Name']][field['Data_Field_Name']] = this.pageValues[field['Field_ID']];
						}

						if (field['is_Auto_Number'] === 1) {
							this.pageValues[field['Field_ID']] = this.pageValues[field['Field_ID']] === null || this.pageValues[field['Field_ID']] === undefined ? '' : this.pageValues[field['Field_ID']];
							this.autoNumberFields.push({ TABLE_NAME: section['Table_Name'], FIELD_NAME: field['Data_Field_Name'], FIELD_ID: field['Data_Field_ID'], ISM_SEQUENCE: this.pageValues[field['Field_ID']] });
						}

						if (field['DataType'] === 'PASSWORD' || field['DataType'] === 'ENCRYPTED') {
							encryptedFields.push({ TABLE_NAME: section['Table_Name'], FIELD_NAME: field['Data_Field_Name'] });
						}

						if (section['Table_Name'] === primaryTableName && field['Primary_Key'] === '1') {
							primaryKeyFields.push({ TABLE_NAME: section['Table_Name'], FIELD_NAME: field['Data_Field_Name'] });
						}
					});
				} else if (section.Section_Type === 'Multi' && section.Display_Type === 'Table') {
					dataFields = this.grid['datasource' + mvIdx]['datafields'];
					pageDatas[section['Table_Name']] = this.getGridData(mvIdx, dataFields);
					mvIdx++;
				} else if (section.Section_Type === 'Multi' && section.Display_Type === 'MVSection') {
					dataFields = this.mvSectionColumns[mvSectionIdx]['fields'];
					pageDatas[section['Table_Name']] = this.getMVSectionData(mvSectionIdx, dataFields);
					mvSectionIdx++;
				} else {
					pageDatas[section['Table_Name']] = {};
				}
				idx++;
			});

			this.isSaving = true;
			this.dataService.manageDM(this.oper, this.Id, this.primaryId, primaryTableName, this.autoNumberFields, pageDatas, encryptedFields, primaryKeyFields, this.pk_error_message, API_ID, inputParams, this.externalFunctions).subscribe(
				(result) => {
					this.isSaving = false;
					if (result['RESULT_CODE'] === 1) {
						setTimeout(() => {
							if (nextPage.screen === undefined || nextPage.screen.ID === undefined || nextPage.screen.ID === '') {
								if (nextPage.url === undefined || nextPage.url === '') {
									this.toastr.success('Success', 'Saved Successfully !!', { timeOut: 3000 });
									this.router.navigateByUrl('/app/page?pageid=' + this.Id + '&id=' + this.primaryId);
								} else {
									this.router.navigateByUrl(nextPage.url);
								}
							} else {
								if (nextPage.screen.Is_Static === '0') {
									if (nextPage.screen.Is_Report !== undefined && nextPage.screen.Is_Report === '1') this.router.navigateByUrl('/dashboard_page?id=' + nextPage.screen.ID + '&tid=' + this.primaryId);
									else this.router.navigateByUrl('/form_particulars?pageid=' + nextPage.screen.ID + '&id=' + this.primaryId);
								} else if (nextPage.screen.Is_Static === '1') {
									this.router.navigateByUrl(nextPage.url);
								}
							}
						}, 2000);
					} else if (result['RESULT_CODE'] === 0 && result['RESULT_MESSAGE'] !== undefined) {
						this.toastr.error('Error', result['RESULT_MESSAGE'], { timeOut: 3000 });
					} else {
						this.toastr.error('Error', 'Error while saving !!', { timeOut: 3000 });
					}
				},
				(error) => {
					this.isSaving = false;
					this.toastr.error('Error', 'Error while saving !!', { timeOut: 3000 });
					console.error(error);
				}
			);
		}
	}
}
