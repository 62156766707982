import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { CMSService } from '../../../services';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
	selector: 'app-site-services',
	templateUrl: './service.component.html',
	styleUrls: ['./service.component.scss']
})
export class ServiceProductComponent implements OnInit {

	@Input() content: any;
	@Input() row: any;
	@Input() siteId: any;
	window_width: any = 0;
	items: any = [];

	constructor(public pageFunctions: PageFunctions, public cmsService:CMSService, public router: Router) {
		this.window_width = window.innerWidth;
	}

	ngOnInit(): void {
		if (this.content["infotype"] !== ""){
			this.getData();
		}
		if (this.content.class === undefined){
			this.content['class'] = "col-4";
		}
	}

	getData(){
		this.cmsService.getPS(this.siteId,this.content["infotype"]).subscribe(res=>{
			if (res.RESULT_CODE===1){
				this.items = res.DATA;
			}
		});
	}

	loadPage(item){
		var PSPage = "";
		if (this.content["infotype"]==="Services"){
			PSPage = this.cmsService.AppData.ServicePage;
		}
		else{
			PSPage = this.cmsService.AppData.ProductPage;
		}
		if (item.SLUG_URL !== null && item.SLUG_URL !== "" && PSPage !== ""){
			this.router.navigate([PSPage,{ ref: item.SLUG_URL}]);
		}
	}
	
	getStylesMain(obj,objShadow){
		var style = {};
		style = this.getStyles(obj);
		this.pageFunctions.setShadow(style, objShadow);
		return style;
	}

	getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}
 
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			style["background-color"] = obj['background-color'];
		}

		this.pageFunctions.setBorder(style,obj,true);

		this.pageFunctions.serBorderRadius(style, obj);
		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		this.pageFunctions.setMargin(style,obj);

		return style;
	}

}
