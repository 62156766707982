<div class="w-100" *ngIf="categories.length > 0">
    <ng-container *ngFor="let category of categories">
        <div [style.color]="content.faq_styles.categorycolor" [style.text-align]="content.faq_styles['text-align']"
            [style.font-size]="content.faq_styles['font-size']" [style.font-family]="content.faq_styles['font-family']"
            [style.font-style]="content.faq_styles['font-style']"
            [style.font-weight]="content.faq_styles['font-weight']"
            [style.text-decoration]="content.category_styles['text-decoration']"
            [style.paddingTop.px]="content.category_styles['padding-top']"
            [style.paddingLeft.px]="content.category_styles['padding-left']"
            [style.paddingRight.px]="content.category_styles['padding-right']"
            [style.paddingBottom.px]="content.category_styles['padding-bottom']"
            *ngIf="content['CATEGORY_ID'] === '' && category.FAQ.length > 0">
            {{category.CATEGORY}}
        </div>
        <accordion [closeOthers]="true" [style.paddingTop.px]="content.faq_styles['padding-top']"
            [style.paddingLeft.px]="content.faq_styles['padding-left']"
            [style.paddingRight.px]="content.faq_styles['padding-right']"
            [style.paddingBottom.px]="content.faq_styles['padding-bottom']">
            <ng-container *ngFor="let faq of category.FAQ">
                <accordion-group panelClass="b0 mb-0" [isOpen]="faq.isOpen" (click)="faq.isOpen = !faq.isOpen"
                    [ngStyle]="getFAQStyles(content.faq_styles)">
                    <div accordion-heading>
                        <div class="row">
                            <div class="col-sm-10 col-10 col-md-11"
                                [style.paddingTop.px]="content.qst_styles['padding-top']"
                                [style.paddingLeft.px]="content.qst_styles['padding-left']"
                                [style.paddingRight.px]="content.qst_styles['padding-right']"
                                [style.paddingBottom.px]="content.qst_styles['padding-bottom']">
                                <div class="text-left mr-2" [innerHTML]="getHTML(faq.PUBLISHED_QUESTION)"></div>
                            </div>
                            <div class="col-sm-2 col-2 col-md-1" style="position:absolute;right:-10px;top:10px;"
                                [style.paddingTop.px]="content.qst_styles['padding-top']"
                                [style.paddingBottom.px]="content.qst_styles['padding-bottom']">
                                <div class="float-right">
                                    <em [class]="content.faq_styles['icon_name_pause']" *ngIf="faq.isOpen"
                                        [style.Color]="content.faq_styles['icon-color']"
                                        [style.paddingTop.px]="content.faq_styles['icon-padding-top']"
                                        [style.paddingBottom.px]="content.faq_styles['icon-padding-bottom']"
                                        [style.paddingLeft.px]="content.faq_styles['icon-padding-left']"
                                        [style.paddingRight.px]="content.faq_styles['icon-padding-right']"
                                        [style.fontSize.px]="content.faq_styles['icon-size']"></em>
                                    <em [class]="content.faq_styles['icon_name']" *ngIf="!faq.isOpen"
                                        [style.Color]="content.faq_styles['icon-color']"
                                        [style.paddingTop.px]="content.faq_styles['icon-padding-top']"
                                        [style.paddingBottom.px]="content.faq_styles['icon-padding-bottom']"
                                        [style.paddingLeft.px]="content.faq_styles['icon-padding-left']"
                                        [style.paddingRight.px]="content.faq_styles['icon-padding-right']"
                                        [style.fontSize.px]="content.faq_styles['icon-size']"></em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [style.backgroundColor]="content.faq_styles['answer_bg_color']" style="margin:-15px;"
                        [style.paddingTop.px]="content.ans_styles['padding-top']"
                        [style.paddingLeft.px]="content.ans_styles['padding-left']"
                        [style.paddingRight.px]="content.ans_styles['padding-right']"
                        [style.paddingBottom.px]="content.ans_styles['padding-bottom']">
                        <div class="text-left" [innerHTML]="getHTML(faq.PUBLISHED_ANSWER)"></div>
                    </div>
                </accordion-group>
            </ng-container>
        </accordion>
    </ng-container>
</div>