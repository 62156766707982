import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, DataService } from '../../services';
import * as moment from 'moment';

@Component({
	selector: 'app-lookup',
	templateUrl: './lookup.component.html',
	styleUrls: ['./lookup.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LookupComponent implements OnInit, AfterViewInit {
	@ViewChild('lkupGrid', { static: false }) lkupGrid: jqxGridComponent;
	@Input() Lookup: any;
	@Output() SingleSelectData: EventEmitter<any> = new EventEmitter<any>();
	@Output() MultiSelectData: EventEmitter<any> = new EventEmitter<any>();

	gridAdapter: any = [];
	Columns: any = [];
	profile: any = {};
	cond: string = '';
	orderby: string = '';
	orderdir: string = 'ASC';
	order_by_cond: string = '';
	pageno = 0;
	pagesize = 25;
	datasource: any = {};
	lookup_outfill: any = [];
	selectAll:boolean = false;
	
	constructor(private authService: AuthService, private dataService: DataService, public dialogRef: MatDialogRef<LookupComponent>) {}

	ngOnInit(): void {
		this.setGridConfig();
	}

	ngAfterViewInit(): void {
		this.lkupGrid.hideloadelement();
		this.lkupGrid.beginupdate();
		this.lkupGrid.setOptions({
			source: this.gridAdapter,
			columns: this.Columns,
			virtualmode: 'true',
			rendergridrows: this.rendergridrows,
		});
		this.lkupGrid.endupdate();
		this.setGridData();
	}

	close() {
		this.dialogRef.close();
	}

	addMVData() {
		var griddata = this.lkupGrid.getdatainformation();
		var rows = [];
		var row: any = 0;
		while (row < griddata.rowscount) {
			let item = this.lkupGrid.getrowdata(row);
			if (item !== undefined && item['SELECTED'] === true) {
				rows.push(item);
			}
			row++;
		}

		this.MultiSelectData.emit(rows);
	}

	getDefaultValue(defaultValue) {
		var returnValue;
		if (defaultValue === 'Now()') {
			returnValue = moment(new Date(), 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY hh:mm:ss');
		} else if (defaultValue === '{USER NAME}' || defaultValue === '{USER_NAME}') {
			returnValue = this.profile['UserName'];
		} else if (defaultValue === '{USER_CODE}') {
			returnValue = this.profile['UserCode'];
		} else if (defaultValue === '{USER_ID}') {
			returnValue = this.profile['UserID'];
		} else if (defaultValue === '{USER_EMAIL}') {
			returnValue = this.profile['UserEmail'];
		}
		return returnValue;
	}

	onLookupCellclick(event) {
		const args = event.args;
		const dataField = args.datafield;
		if (dataField === 'SELECT') {
			let dataSelected = {};
			this.Lookup.OutfillParams.forEach((param) => {
				if (param.Value !== '' && param.Value !== null && param.Value !== undefined) dataSelected[param.Value] = args.row.bounddata[param.Field_Name];
			});

			this.SingleSelectData.emit(dataSelected);
			this.close();
		}
	}

	setGridData() {
		this.lkupGrid.showloadelement();
		this.dataService.getLookupData(this.Lookup.Lookup_ID, this.Lookup['InputParams'] + this.cond, this.pageno, this.pagesize, this.order_by_cond).subscribe(
			(data) => {
				this.datasource.localdata = [];
				this.datasource.totalrecords = 0;
				if (data.RESULT_CODE === 1) {
					this.datasource.localdata = data['DATA'];
					this.datasource.totalrecords = data['RECORD_COUNT'];
					this.lkupGrid.updatebounddata();
				}
				this.lkupGrid.hideloadelement();
			},
			(error) => {
				console.error(error);
				this.lkupGrid.hideloadelement();
			}
		);
	}

	selectAllRows(){
		var datas = this.datasource.localdata;
		this.lkupGrid.showloadelement();
		datas.forEach(item=>{
			if (this.selectAll){
				item['SELECTED'] = true;
			}
			else{
				item['SELECTED'] = false
			}
		});
		this.datasource.localdata = datas;
		this.lkupGrid.updatebounddata();
		this.lkupGrid.hideloadelement();
	}

	setGridConfig() {
		this.cond = '';
		this.orderby = '';
		this.orderdir = 'ASC';
		this.pageno = 0;
		this.pagesize = 25;
		this.Columns = [];
		this.lookup_outfill = [];

		var Datafields = [];
		if (this.Lookup['Lookup_Type'] === 'Table') {
			Datafields.push({ name: 'SELECTED', type: 'bool' });
			this.Columns.push({ text: '', datafield: 'SELECTED', columntype: 'checkbox', width: 35, filterable: false });
		} else {
			this.Columns.push({
				text: '',
				dataField: 'SELECT',
				width: 40,
				filtertype: 'none',
				cellclassname: 'bg-primary',
				filterable: false,
				sortable: false,
				menu: false,
				columntype: 'button',
				cellsrenderer: function () {
					return '<span class="fa fa-edit"></span>';
				},
				buttonclick: function (row) {},
			});
		}

		this.Lookup.OutfillParams.forEach((param) => {
			if (param.DataType === 'INTEGER' || param.DataType === 'DECIMAL' || param.DataType === 'PERCENTAGE') Datafields.push({ name: param.Field_Name, type: 'number' });
			else if (param.DataType === 'DATE' || param.DataType === 'DATETIME') Datafields.push({ name: param.Field_Name, type: 'date' });
			else Datafields.push({ name: param.Field_Name, type: 'string' });

			if (param.Field_Name === 'ID' || param.Field_Name.includes('_ID') || param.Field_Name.includes(' ID')) {
				this.Columns.push({ text: param.Display_Name, datafield: param.Field_Name, hidden: true });
			} else {
				if (param.DataType === 'INTEGER' || param.DataType === 'DECIMAL' || param.DataType === 'PERCENTAGE') {
					this.Columns.push({ text: param.Display_Name, datafield: param.Field_Name, filterable: true, filtertype: 'number', hidden: param.hidden === undefined || param.hidden === null ? false : param.hidden });
				} else if (param.DataType === 'DATE' || param.DataType === 'DATETIME') {
					this.Columns.push({ text: param.Display_Name, datafield: param.Field_Name, filterable: true, filtertype: 'number', hidden: param.hidden === undefined || param.hidden === null ? false : param.hidden });
				} else {
					this.Columns.push({ text: param.Display_Name, datafield: param.Field_Name, filterable: true, filtertype: 'string', hidden: param.hidden === undefined || param.hidden === null ? false : param.hidden });
				}
			}
		});

		this.datasource = {
			localdata: null,
			datatype: 'json',
			datafields: Datafields,
			totalrecords: 0,
			sort: (column, direction) => {
				this.applySorting(column, direction);
			},
		};

		this.gridAdapter = new jqx.dataAdapter(this.datasource);
	}

	rendergridrows(obj: any) {
		return obj.data;
	}

	applySorting(column, direction) {
		if (typeof direction === 'string') {
			this.orderby = column;
			this.orderdir = direction == 'ascending' ? 'ASC' : 'DESC';
		} else {
			this.orderby = column;
			this.orderdir = direction == true ? 'ASC' : 'DESC';
		}
		this.order_by_cond = ' Order By `' + this.orderby + '` ' + this.orderdir;
		this.pageno = 0;
		this.setGridData();
	}

	onPageChanged(event: any): void {
		let args = event.args;
		this.pageno = args.pagenum;
		this.pagesize = args.pagesize;
		this.selectAll = false;
		this.setGridData();
	}

	onPageSizeChanged(event: any): void {
		let args = event.args;
		this.pageno = args.pagenum;
		this.pagesize = args.pagesize;
		this.selectAll = false;
		this.setGridData();
	}

	Filter(event: any): void {
		let args = event.args;
		var filterInfo = this.lkupGrid.getfilterinformation();
		var filterConds = '';
		filterInfo.forEach((filter) => {
			var stmt = '';
			var filters = filter.filter.getfilters();
			filters.forEach((element) => {
				if (stmt !== '') stmt += element.operator === 0 ? ' And ' : ' Or ';
				stmt += this.getFilterStatement(filter.filtercolumn, element.condition, element.value);
			});
			filterConds += ' And (' + stmt + ')';
		});

		this.cond = filterConds;
		this.pageno = 0;
		this.selectAll = false;
		this.setGridData();
	}

	getFilterStatement(datafield, condition, value) {
		var statement = '';
		switch (condition) {
			case 'EMPTY':
			case 'NULL':
				statement = 'IFNULL(`' + datafield + "`,'')=''";
				break;
			case 'NOT_EMPTY':
			case 'NOT_NULL':
				statement = 'IFNULL(`' + datafield + "`,'')<>''";
				break;
			case 'EQUAL':
			case 'EQUAL_CASE_SENSITIVE':
				statement = '`' + datafield + "`='" + value + "'";
				break;
			case 'NOT_EQUAL':
			case 'EMPTY':
				statement = '`' + datafield + "`<>'" + value + "'";
				break;
			case 'CONTAINS':
			case 'CONTAINS_CASE_SENSITIVE':
				statement = '`' + datafield + "` LIKE '%" + value + "%'";
				break;
			case 'STARTS_WITH':
			case 'STARTS_WITH_CASE_SENSITIVE':
				statement = '`' + datafield + "` LIKE '" + value + "%'";
				break;
			case 'ENDS_WITH':
			case 'ENDS_WITH_CASE_SENSITIVE':
				statement = '`' + datafield + "` LIKE '%" + value + "'";
				break;
			case 'LESS_THAN':
				statement = '`' + datafield + "` < '" + value + "'";
				break;
			case 'LESS_THAN_OR_EQUAL':
				statement = '`' + datafield + "` <= '" + value + "'";
				break;
			case 'GREATER_THAN':
				statement = '`' + datafield + "` > '" + value + "'";
				break;
			case 'GREATER_THAN_OR_EQUAL':
				statement = '`' + datafield + "` >= '" + value + "'";
				break;
			default:
				break;
		}
		return statement;
	}
}
