import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CMSService } from '../../../services';
import { Router } from "@angular/router";
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
    selector: 'app-site-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class SiteNEWSComponent implements OnInit {

    @Input() siteId: string;
    @Input() content: any;

    news: any = [];

    constructor(public pageFunctions: PageFunctions, public dataService:CMSService, 
		private sanitizer: DomSanitizer, public router: Router) { }

    ngOnInit() {
        this.getNews();
        if (this.content.class === undefined){
			this.content['class'] = "col-4";
		}

        if (this.content.slider_styles === undefined){
            this.content['slider_styles'] = {
              "font-family": "Roboto",
              "font-weight": "normal",
              "font-size":"16px",
              "text-align": "left",
              "text-decoration": "",
              "font-style":"normal",
              "color": "#000",
              "border-style": "solid",
              "border-width": 0,
              "border-color": "transparent",
              "border-radius": 0,
			  "margin-left":0,
			  "margin-right":0,
			  "margin-top":0,
			  "margin-bottom":0,
            }
        }
        
        if (this.content.title_styles === undefined){
            this.content['title_styles'] = {
              "font-family": "Roboto",
              "font-weight": "normal",
              "font-size":"16px",
              "text-align": "left",
              "text-decoration": "",
              "font-style":"normal",
              "color": "#000",
              "border-style": "solid",
              "border-width": 0,
              "border-color": "transparent",
              "border-radius": 0,
			  "margin-left":0,
			  "margin-right":0,
			  "margin-top":0,
			  "margin-bottom":0,
            }
        }
        if (this.content.date_styles === undefined){
            this.content['date_styles'] = {
              "font-family": "Roboto",
              "font-weight": "normal",
              "font-size":"16px",
              "text-align": "left",
              "text-decoration": "",
              "font-style":"normal",
              "color": "#000",
              "border-style": "solid",
              "border-width": 0,
              "border-color": "transparent",
              "border-radius": 0,
			  "margin-left":0,
			  "margin-right":0,
			  "margin-top":0,
			  "margin-bottom":0,
            }
        }
        if (this.content.desc_styles === undefined){
            this.content['desc_styles'] = {
              "font-family": "Roboto",
              "font-weight": "normal",
              "font-size":"16px",
              "text-align": "left",
              "text-decoration": "",
              "font-style":"normal",
              "color": "#000",
              "border-style": "solid",
              "border-width": 0,
              "border-color": "transparent",
              "border-radius": 0,
			  "margin-left":0,
			  "margin-right":0,
			  "margin-top":0,
			  "margin-bottom":0,
            }
        }
        if (this.content.shadow === undefined){
            this.content['shadow'] = {"styles":{}};
        }
    }

    ngOnChanges(changes: any) {
        if (this.content.news_styles === undefined){
            this.content['news_styles']={
                "panelbgcolor":"#fff",
                "border-style": "solid",
				"border-width": 0,
				"border-color": "transparent",
				"border-radius": 0	
            }
        }
        if (this.content.shadow === undefined){
            this.content['shadow'] = {"styles":{}};
        }
    }

    getNews(){
        this.dataService.getNEWS(this.siteId)
		.subscribe( data => {
			this.news = data.DATA;
		});
    }
    

    getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	loadPage(item){
		if (item.NEWS_URL !== null && item.NEWS_URL !== "" && this.dataService.AppData.NewsPage !== ""){
			this.router.navigate([this.dataService.AppData.NewsPage,{ ref: item.NEWS_URL}]);
		}
	}

    // getNewsStyles(obj){
    //     var style={};
    //     if (obj['panelbgcolor'] !== null && obj['panelbgcolor'] !== undefined) {
	// 		style["background-color"] = obj['panelbgcolor'];
	// 	}
        
    //     if (obj['border-width'] !== null && obj['border-width'] !== undefined) {
    //         style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
	// 	}
    //     if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
	// 		style["border-radius"] = obj['border-radius'] + "px";
	// 	}

    //     style['margin-bottom'] = "15px";

    //     style = this.getShadowStyle(this.content.shadow['styles'], style);
    //     return style;
    // }

    // getShadowStyle(obj, style){
    //     if (obj['shadow-color'] !== undefined && obj['shadow-color'] !== 'transparent' && obj['shadow--color'] !== 'rgba(255,255,255,0)' && obj['shadow--color'] !== 'rgba(255, 255, 255, 0)' ) {
	// 		style["box-shadow"] =  obj['h-size'] + 'px ' + obj['v-size'] + 'px '  + obj['blur-radius'] + 'px ' + obj['spread-radius'] + 'px ' + obj['shadow-color'];
	// 		style["-webkit-box-shadow"] =  obj['h-size'] + 'px ' + obj['v-size'] + 'px ' + obj['blur-radius'] + 'px ' + obj['spread-radius'] + 'px ' + obj['shadow-color'];
	// 		style["-moz-box-shadow"] =  obj['h-size'] + 'px ' + obj['v-size'] + 'px ' + obj['blur-radius'] + 'px ' + obj['spread-radius'] + 'px ' + obj['shadow-color'];
	// 	}
    //     return style;
    // }

    getStylesMain(obj,objShadow){
		var style = {};
		style = this.getStyles(obj);
		this.pageFunctions.setShadow(style, objShadow);
		return style;
	}

    getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}
 
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if (obj['background-color'] !== null && obj['background-color'] !== undefined ) {
			style["background-color"] = obj['background-color'];
		}

		this.pageFunctions.setBorder(style,obj,true);

		this.pageFunctions.serBorderRadius(style, obj);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		this.pageFunctions.setMargin(style,obj);

		return style;
	}
}
