<div class="row">
    <div class="col-md-12">
        <div class="card card-default mb-0">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-6">
                        <h4>{{FieldInfo.Display_Name}}</h4>
                    </div>
                    <div class="col-lg-6">
                        <div class="float-right">
                            <button type="button" class="btn btn-light float-right mr-1" (click)="close()" aria-label="Close" >
                                Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <textarea #textField id="textField" class="form-control" [name]="FieldInfo.Field_Name" [(ngModel)]="FieldData" rows="12"></textarea>
            </div>
        </div>
    </div>
</div>
