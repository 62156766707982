import { Component, OnInit, Input } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Router } from "@angular/router";
import { EcommerceService } from '../../../services/ecommerce.service';
import { MatDialog } from '@angular/material/dialog';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  @Input() props: any;
  @Input() row: any;
  wishlists: any = [];
  paged_wishlists: any = [];
  loading_wishlist: boolean = false;

  constructor(public pageFunctions: PageFunctions, public dataService: EcommerceService, 
    public router: Router, public dialog: MatDialog) { 
    this.getWishlists();
  }

  addToCart(product){
    this.dataService.addToCart({
      "ID":product.PRODUCT_ID, 
      "VARIANT_ID":product.VARIANT_ID, 
      "PRODUCT_NAME": product.PRODUCT_NAME,
      "PRODUCT_DESCRIPTION": product.PRODUCT_DESCRIPTION,
      "CART_COUNT": product.QTY,
      "PRICE": product.PRICE,
      "IMAGE_URL": product.IMAGE_URL
    });
    this.removeFromWishlist(product);
  }

  removeFromWishlist(product){
    this.dataService.removeFromWishlist(product);
    var wishlists = this.wishlists.filter(item => item.VARIANT_ID !== product.VARIANT_ID);
    this.wishlists = wishlists;
    this.paged_wishlists = this.wishlists.slice(0, 10);
  }

  getWishlists(){
    this.loading_wishlist = true;
    this.dataService.updateCustomerWishlist().subscribe((res : any)=>{
      if (res.RESULT_CODE===1){
        this.wishlists = res.DATA;
        this.paged_wishlists = this.wishlists.slice(0, 10);
        this.loading_wishlist = false;
      }
      else{
        this.loading_wishlist = false;
      }
    });
  }

  ngOnInit(): void {
    this.row.styles.height = "100%";
    if (this.props.table_styles === undefined){
      this.props['table_styles'] = {
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0
      }
    }
    if (this.props.header_styles === undefined){
      this.props['header_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props.body_styles === undefined){
      this.props['body_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props.footer_styles === undefined){
      this.props['footer_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "16px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      }
    }
    if (this.props['cart_styles'] === undefined) {
      this.props['cart_styles'] = {
        'color':'#fff',
        'background-color':'#21a240',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal'      
      };
    }
    if (this.props.paging_styles === undefined){
      this.props['paging_styles'] = {
        "color": "#000",
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "24px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "",
        "justify-content": "left"
      };
    }
  }

  pageChanged(event: PageChangedEvent): void {
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;
		this.paged_wishlists = this.wishlists.slice(startItem, endItem);
	}

  getStyles(obj) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setPadding(style,obj);

    this.pageFunctions.setBorder(style,obj,true);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

    if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
      style["width"] = obj['width'] + "%";
    }

    if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== ""){
      style['margin-left'] = (parseInt(obj['margin-left'].replace('px',''))) + "px";
    }
   
    if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== ""){
     style['margin-top'] = obj['margin-top'];
    }
    
    return style;
  }
}
