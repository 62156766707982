import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

declare var require: any;

@Component({
  selector: 'app-textdialog',
  templateUrl: './textdialog.component.html',
  styleUrls: ['./textdialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('textField') textField: ElementRef;
    @Input() FieldInfo: any;
    @Input() FieldData: string;
    @Output() Close: EventEmitter<any> = new EventEmitter<any>();

    loading: boolean=false;

    constructor(public dialogRef: MatDialogRef<TextDialogComponent>) { 
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.textField.nativeElement.focus();
    }

    close() {
        this.Close.emit(this.FieldData);
        this.dialogRef.close();
    }
}
