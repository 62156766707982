import { Component, OnInit, Input } from '@angular/core';
import { PageFunctions } from '../../../shared/directives/PageFunctions';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-dashboard-table',
	templateUrl: './dashboardtable.component.html',
	styleUrls: ['./dashboardtable.component.scss'],
})
export class DashboardTableComponent implements OnInit {
	@Input() props: any;
	@Input() row: any;
	@Input() reportData: any;
	tableData: any = [];
	isExporting: boolean = false;

	constructor(public pageFunctions: PageFunctions) {}

	ngOnInit(): void {
		this.pageFunctions.setRowAutoHeight(this.row);

		this.tableData = this.reportData[this.props.id].rows.slice(0, 10);

		if (this.props.styles.height === undefined){
			this.props.styles.height = 200;
		}
		if (this.props.table_styles === undefined) {
			this.props['table_styles'] = {
				'padding-right': 0,
				'padding-left': 0,
				'padding-top': 0,
				'padding-bottom': 0,
				'border-style': 'solid',
				'border-width': 0,
				'border-color': 'transparent',
				'border-radius': 0
			};
		}
		if (this.props.header_styles === undefined) {
			this.props['header_styles'] = {
				'text-align': 'center',
				'background-color': 'transparent',
				'color': '#000',
				'padding-right': 0,
				'padding-left': 0,
				'padding-top': 0,
				'padding-bottom': 0,
				'border-style': 'solid',
				'border-width': 0,
				'border-color': 'transparent',
				'border-radius': 0,
				'font-family': 'Roboto',
				'font-size': '16px',
				'font-weight': 'normal',
				'font-style': 'normal',
				'text-decoration': ''
			};
		}
		if (this.props.body_styles === undefined) {
			this.props['body_styles'] = {
				'text-align': 'center',
				'background-color': 'transparent',
				'color': '#000',
				'padding-right': 0,
				'padding-left': 0,
				'padding-top': 0,
				'padding-bottom': 0,
				'border-style': 'solid',
				'border-width': 0,
				'border-color': 'transparent',
				'border-radius': 0,
				'font-family': 'Roboto',
				'font-size': '16px',
				'font-weight': 'normal',
				'font-style': 'normal',
				'text-decoration': ''
			};
		}
		if (this.props.footer_styles === undefined){
			this.props['footer_styles'] = {
			  "text-align": "center",
			  "background-color": "transparent",
			  "color": "#000",
			  "padding-right": 0,
			  "padding-left": 0,
			  "padding-top": 0,
			  "padding-bottom": 0,
			  "border-style": "solid",
			  "border-width": 0,
			  "border-color": "transparent",
			  "border-radius": 0,
			  'font-family': 'Roboto',
			  "font-size": "16px",
			  "font-weight": "normal",
			  "font-style": "normal"
			}
		}
	}

	getDataTableColumns(columns){
		columns = columns.sort((a, b) => {
			if (a.Sequence < b.Sequence) {
				return -1;
			}
		});
		return columns;
	}

	pageChanged(event: PageChangedEvent): void {
		const startItem = (event.page - 1) * event.itemsPerPage;
		const endItem = event.page * event.itemsPerPage;
		this.tableData = this.reportData[this.props.id]['rows'].slice(startItem, endItem);
	}

	getStyles(obj) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style['font-family'] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style['text-align'] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style['justify-content'] = obj['justify-content'];
		}

		this.pageFunctions.setPadding(style, obj);

		this.pageFunctions.setBorder(style, obj, true);

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style['border-radius'] = obj['border-radius'] + 'px';
		}

		this.pageFunctions.setBackgroundImage(style, obj);

		if (obj['color'] !== null && obj['color'] !== undefined) {
			style['color'] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = 'block';
			style['width'] = '100%';
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== 'Normal') {
			style['font-weight'] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== 'Normal') {
			style['font-style'] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== '') {
			style['text-decoration'] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style['line-height'] = obj['line-height'];
		}

		if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== '' && obj['height'] !== 0) {
			style['height'] = obj['height'] + 'px';
		}

		if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== '' && obj['width'] !== 0) {
			style['width'] = obj['width'] + '%';
		}

		if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== '') {
			style['margin-left'] = parseInt(obj['margin-left'].replace('px', '')) + 'px';
		}

		if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== '') {
			style['margin-top'] = obj['margin-top'];
		}

		return style;
	}

	replaceLineBreaks(content) {
		if (typeof content === "string")
        	return content.replace(/\n/g, '').replace(/\r/g, '<br>');
		else
			return content;
    }

	exportExcel(){
		this.isExporting = true;
		this.tableData = this.reportData[this.props.id]['rows'].slice(0, 99999);
		setTimeout(() => {
			let element = document.getElementById('dashboardtbl');
			const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw: true, dateNF:'dd-mmm-yyyy;@',cellDates:false});
			// var rows: any = [];
			// var range = XLSX.utils.decode_range(ws['!ref']); // get the range
            // for(var R = range.s.r; R <= range.e.r; ++R) {
			// 	var row: any = [];
			// 	for(var C = range.s.c; C <= range.e.c; ++C) {
			// 		var cellref = XLSX.utils.encode_cell({c:C, r:R}); // construct A1 reference for cell
			// 		if(!ws[cellref]) continue; // if cell doesn't exist, move on
			// 		ws[cellref].s = {
			// 			alignment: {
			// 				vertical: 'top',
			// 				horizontal: 'left',
			// 				wrapText: true
			// 			}
			// 		};
			// 		row.push(ws[cellref]);
			// 	}
			// 	rows.push(row);
			// }
			let filename = `data_${(new Date().toJSON().slice(0,10))}.xlsx`
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			XLSX.writeFile(wb, filename);
			this.isExporting = false;
			this.tableData = this.reportData[this.props.id]['rows'].slice(0, 10);
		},2000);
	}

	// ConvertToCSV(objArray, headerList, aliasList) {
    //     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    //     let str = '';
    //     let row = '';
    //     for (let index in headerList) {
	// 		if (row==="")
	// 			row = headerList[index];
	// 		else
    //         	row += ", " + headerList[index];
    //     }
    //     row = row.slice(0, -1);
    //     str += row + '\r\n';
    //     for (let i = 0; i < array.length; i++) {
    //         let line = "";
    //         for (let index in aliasList) {
    //             let head = aliasList[index];
	// 			if (line === "")
	// 				line = array[i][head].replace(/\n/g, '').replace(/\r/g, '<br style="mso-data-placement:same-cell;" />');
	// 			else
    //             	line += ", " + array[i][head].replace(/\n/g, '').replace(/\r/g, '<br style="mso-data-placement:same-cell;" />');
    //         }
    //         str += line + '\r\n';
    //     }
    //     return str;
    // }

	// downloadCSVile(data, filename = 'data') {
	// 	let columns = this.getDataTableColumns(this.reportData[this.props.id]['columns']);
	// 	let headers: any = [];
	// 	let alias: any = [];
	// 	columns.forEach(element => {
	// 		headers.push(element.Display_Name);
	// 		alias.push(element.Alias);
	// 	});
    //     let csvData = this.ConvertToCSV(data, headers, alias);
    //     let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    //     let dwldLink = document.createElement("a");
    //     let url = URL.createObjectURL(blob);
    //     let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1;
    //     navigator.userAgent.indexOf('Chrome') == -1;
    //     if (isSafariBrowser) {
    //         dwldLink.setAttribute("target", "_blank");
    //     }
    //     dwldLink.setAttribute("href", url);
    //     dwldLink.setAttribute("download", filename + ".csv");
    //     dwldLink.style.visibility = "hidden";
    //     document.body.appendChild(dwldLink);
    //     dwldLink.click();
    //     document.body.removeChild(dwldLink);
    // }
}
