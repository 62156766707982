import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxTreeGridComponent } from 'jqwidgets-ng/jqxTreegrid';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { EnvService } from '../../../environments/env.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Guid } from "guid-typescript";

@Component({
  selector: 'app-rolemenus',
  templateUrl: './rolemenus.component.html',
  styleUrls: ['./rolemenus.component.scss']
})
export class RolemenusComponent implements OnInit, AfterViewInit {
    @ViewChild('menuGrid', { static: false }) menuGrid: jqxGridComponent;
    @ViewChild('hdrGrid', { static: false }) hdrGrid: jqxTreeGridComponent;

    viewAll:boolean = false;
    addAll:boolean = false;
    editAll:boolean = false;
    deleteAll:boolean = false;
    isLoading:boolean = false;
    roleCode:string = '';
    roleName:string = '';
    getConfigComplete:boolean = false;

    roleId:string = '';
    pageValues:any = {};
    menuSource:any = {};
    hdrSource:any = {};
    menuDatas:any = [];
    roleMenus:any = [];
    menuHeaders:any = [];
    menuColumns: any = [];
    hdrColumns: any = [];
    hdrAdapter:any;
    menuAdapter:any;

    apiUrl:string='';

    constructor(private environment:EnvService, private dataService : DataService,
        public toastrService: ToastrService, 
        public router: Router, private route:ActivatedRoute) { 
        this.apiUrl = environment.apiUrl;
        this.route.queryParams.subscribe(params => {
            this.roleId = params.id;
        });
        this.getConfig();
    }

    ngOnInit(): void {
        this.getRoleInfo();
        this.getMenuData();    
    }

    ngAfterViewInit(): void {
    }

    getConfig(){
        this.getConfigComplete = false;

        this.menuColumns = [
            { text: 'ID', dataField: 'ID', width: 0, hidden: true },
            { text: 'PARENT_ID', dataField: 'PARENT_ID', width: 0, hidden: true },
            { text: 'Menu Name', dataField: 'MENU_NAME', width: '50%', editable: false },
            { text: 'Can View',  dataField: 'VIEW', columntype: 'checkbox', width: '13%',
                renderer :function (value) {return '<div style="padding-bottom: 2px; overflow: hidden; text-overflow: ellipsis; text-align: center; margin-left: 4px; margin-right: 2px; line-height: 36px;"><span style="text-overflow: ellipsis; cursor: default;">' + value + '</span></div>';} 
            },
            { text: 'Can Add', dataField: 'ADD', columntype: 'checkbox', width: '12%',
            renderer :function (value) {return '<div style="padding-bottom: 2px; overflow: hidden; text-overflow: ellipsis; text-align: center; margin-left: 4px; margin-right: 2px; line-height: 36px;"><span style="text-overflow: ellipsis; cursor: default;">' + value + '</span></div>';} 
            },
            { text: 'Can Edit', dataField: 'EDIT', columntype: 'checkbox', width: '12%',
            renderer :function (value) {return '<div style="padding-bottom: 2px; overflow: hidden; text-overflow: ellipsis; text-align: center; margin-left: 4px; margin-right: 2px; line-height: 36px;"><span style="text-overflow: ellipsis; cursor: default;">' + value + '</span></div>';} 
            },
            { text: 'Can Delete', dataField: 'DELETE', columntype: 'checkbox', width: '13%',
            renderer :function (value) {return '<div style="padding-bottom: 2px; overflow: hidden; text-overflow: ellipsis; text-align: center; margin-left: 4px; margin-right: 2px; line-height: 36px;"><span style="text-overflow: ellipsis; cursor: default;">' + value + '</span></div>';} 
            },                 
        ];

        this.hdrColumns = [
            { text: 'Header Name', dataField: 'HEADER_NAME', width: '75%' },
            { text: 'Menu Count', dataField: 'MENU_COUNT', width: '25%' }
        ]

        this.menuSource = {
            dataType: "json",
            dataFields: [
                { name: 'ID', type: 'string' },
                { name: 'PARENT_ID', type: 'string' },
                { name: 'MENU_NAME', type: 'string' },
                { name: 'VIEW', type: 'bool' },
                { name: 'ADD', type: 'bool' },
                { name: 'EDIT', type: 'bool' },
                { name: 'DELETE', type: 'bool' }
            ],
            id: 'ID',
            localData: null
        };
        this.menuAdapter= new jqx.dataAdapter(this.menuSource);

        this.hdrSource = {
            dataType: "json",
            dataFields: [
                { name: 'ID', type: 'string' },
                { name: 'PARENT_ID', type: 'string' },
                { name: 'HEADER_NAME', type: 'string' },
                { name: 'MENU_COUNT', type: 'number' }
            ],
            hierarchy:
            {
                keyDataField: { name: 'ID' },
                parentDataField: { name: 'PARENT_ID' }
            },
            id: 'ID',
            localData: null
        };

        this.hdrAdapter= new jqx.dataAdapter(this.hdrSource);

        this.getRoleMenuHeaders(); 
        
    }

    getRoleMenuHeaders(){
        this.dataService.roleMenuHeaders().subscribe( result =>
        {
            if (result['RESULT_CODE']===1) {
                this.menuHeaders = result['DATA'];
                this.hdrSource.localData = this.menuHeaders; 
                this.hdrAdapter= new jqx.dataAdapter(this.hdrSource);
            }
        }, error => {
            console.error(error);
        }); 
    }

    setViewAll(){
        this.updateMenuRightsAll('VIEW',this.viewAll);
    }

    setAddAll(){
        this.updateMenuRightsAll('ADD',this.addAll);
    }

    setEditAll(){
        this.updateMenuRightsAll('EDIT',this.editAll);
    }

    setDeleteAll(){
        this.updateMenuRightsAll('DELETE',this.deleteAll);
    }

    updateMenuRightsAll(datafield, value):void{
        var griddata = this.menuGrid.getdatainformation();
        var rows = [];
        var row:any = 0; var slno = 0;
        while (row < griddata.rowscount){
            var rowData = this.menuGrid.getrowdata(row);
            var dataRow = { ID: rowData['ID'] };
            dataRow['PARENT_ID']= rowData['PARENT_ID'];
            dataRow['MENU_NAME']= rowData['MENU_NAME'];
            dataRow['VIEW']= rowData['VIEW'];
            dataRow['ADD']= rowData['ADD'];
            dataRow['EDIT']= rowData['EDIT'];
            dataRow['DELETE']= rowData['DELETE'];

            if (datafield === 'VIEW')
                dataRow['VIEW'] = value;
            else if (datafield === 'ADD')
                dataRow['ADD'] = value;
            else if (datafield === 'EDIT')
                dataRow['EDIT'] = value;
            else if (datafield === 'DELETE')
                dataRow['DELETE'] = value;

            rows.push(dataRow);
            row++;
        }
        this.menuDatas = rows;
        this.setMenuData();
    }

    onRowSelect() {
        let selected = this.hdrGrid.getSelection();
        this.updateMenuSource();
        this.menuDatas = this.getMenuSource(selected[0]['ID']);
        this.setMenuData();
        this.clearOptions();
    }

    getRoleInfo(){
        this.dataService.getRoleInfo(this.roleId).subscribe( result =>
        {
            if (result['RESULT_CODE']===1) {
                this.roleName = result['ROLE_NAME'];
                this.getConfigComplete = true;
            }
        }, error => {
            console.error(error);
        });                            
    }

    getMenuData(){
        this.dataService.menuDatas(this.roleId).subscribe( result =>
        {
            if (result['RESULT_CODE']===1) {
                this.roleMenus = result['DATA'];
                this.roleMenus.forEach(menu => {
                    menu.VIEW = menu.VIEW == "1";
                    menu.ADD = menu.ADD == "1";
                    menu.EDIT = menu.EDIT == "1";
                    menu.DELETE = menu.DELETE == "1"; 
                });
            }
        }, error => {
            console.error(error);
        });                            
    }

    clearOptions():void{
        this.viewAll = false;
        this.addAll = false;
        this.editAll = false;
        this.deleteAll = false;
    }

    setMenuData():void{
        this.menuGrid.showloadelement();
        this.menuSource.localdata = [];
        this.menuSource.localdata = this.menuDatas;
        this.menuSource.totalrecords = this.menuDatas.length;
        this.menuGrid.updatebounddata();
        this.menuGrid.hideloadelement();                      
    }

    updateMenuSource(){
        let menus = this.getGridData();
        menus.forEach(selectedmenu => {
            this.roleMenus.filter((menu)=>{
                if (menu.ID === selectedmenu.ID){
                    menu.VIEW = selectedmenu.VIEW;
                    menu.ADD = selectedmenu.ADD;
                    menu.EDIT = selectedmenu.EDIT;
                    menu.DELETE = selectedmenu.DELETE; 
                }                          
            });
        });
    }

    getMenuSource(hdrID){
        return this.roleMenus.filter((menu)=>{
            return menu.PARENT_ID === hdrID;
        });
    }

    getGridData(){
        var griddata = this.menuGrid.getdatainformation();
        var rows = [];
        var row:any = 0; var slno = 0;
        while (row < griddata.rowscount){
            var rowData = this.menuGrid.getrowdata(row);
            var dataRow = { ID: rowData['ID'] };
            dataRow['PARENT_ID']= rowData['PARENT_ID'];
            dataRow['MENU_NAME']= rowData['MENU_NAME'];
            dataRow['VIEW']= rowData['VIEW'];
            dataRow['ADD']= rowData['ADD'];
            dataRow['EDIT']= rowData['EDIT'];
            dataRow['DELETE']= rowData['DELETE'];

            rows.push(dataRow);
            row++;
        }
        return rows;                
    }

    gotoList() {
        this.router.navigateByUrl('/app/roles');
    }

    saveRoleMenus(){
        this.updateMenuSource();
        var menus = this.roleMenus.filter((menu)=>{
            return menu.VIEW === true || menu.ADD === true || menu.EDIT === true || menu.DELETE === true;;
        });

        this.isLoading = true;
        this.dataService.manageMenuRights(this.roleId,menus).subscribe( result =>
        {
            this.isLoading = false;
            if ( result['RESULT_CODE']===1) {
                this.toastrService.success("Success", 'Saved Successfully !!', {timeOut: 3000}); 
            }
            else{
                this.toastrService.error("Error", result['RESULT_MESSAGE'], {timeOut: 3000});
                this.isLoading = false;
            }
        }, error => {
            this.isLoading = false;
            console.error(error);
        });                
    }
}
