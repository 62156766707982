<div class="signature-container" [ngClass]="{'is-invalid':(saveTried===true && field['is_Mandatory'] 
    && (pageValues[field['Field_ID']]===null || pageValues[field['Field_ID']]===''))}">
    <img [src]="pageValues[field.Field_ID]" alt="banner image"
        style="display: block; width: 100%; height:160px; object-fit: contain;"
        *ngIf="pageValues[field.Field_ID]!==null && pageValues[field.Field_ID]!==''"/>
    <signature-pad #signatureCanvas [options]="signaturePadOptions" [id]="field.Field_ID"
        (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"
        *ngIf="pageValues[field.Field_ID]===null || pageValues[field.Field_ID]===''">
    </signature-pad>
</div>
<div class="buttons" *ngIf="showButtons">
    <button (click)="clearSignature()">Clear</button>
    <button [id]="'savepad'+field.Field_ID" (click)="savePad()"
    *ngIf="pageValues[field.Field_ID]===null || pageValues[field.Field_ID]===''">Ok</button>
</div>