<div class="row">
    <div class="col-md-12">
        <div class="card card-default mb-0">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-6">
                        <h4>Coupons</h4>
                    </div>
                    <div class="col-lg-6">
                        <div class="float-right">
                            <button type="button" class="btn btn-light float-right mr-1" (click)="close()" aria-label="Close" >
                                Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body bg-white">
                <table class="table table-bordered" *ngIf="Apply">
                    <tbody>
                        <tr *ngFor="let coupon of Coupons">
                            <td style="width:80%;">
                                <span style="color:red;">{{coupon.CODE}} : </span>
                                {{coupon.NAME}}.  EXPIRES ON {{coupon.EXPIRY}}.
                            </td>
                            <td style="width:20%;">
                                <btn class="btn btn-sm btn-success" (click)="applyCoupon(coupon)">Apply</btn>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered" *ngIf="!Apply">
                    <tbody>
                        <tr *ngFor="let coupon of Coupons">
                            <td>
                                <span style="color:red;">{{coupon.CODE}} : </span>
                                {{coupon.NAME}}.  EXPIRES ON {{coupon.EXPIRY}}.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>