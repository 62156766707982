import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DataService, AuthService } from '../services';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Guid } from "guid-typescript";
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from '../../environments/env.service';
import { DatePipe } from "@angular/common";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
  })
export class ReportComponent implements OnInit, AfterViewInit, OnDestroy {

	//#region "Control handles"
	@ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

	//"Toolbar config"
	public config = {
		toolbar: {
			items: [
				'heading',
				'bold',
				'italic',
				'underline',
				'|',
				'strikethrough', 'subscript', 'superscript',
				'|',
				'link',
				'bulletedList',
				'numberedList',
				'|',
				'alignment',
				'fontFamily',
				'fontSize',
				'fontColor',
				'fontBackgroundColor'
			]
		},
		fontFamily: {options: []},
		fontSize: {
			options: [
				7, 9, 10, 12, 15, 16,
				19, 20, 25, 30, 35, 40, 50,
				60, 80, 100
			],
			supportAllValues: true
		},
		alignment: {
			options: ['left', 'right', 'center', 'justify']
		}
	};
	public data: any = {};
	selectedFormField: any = {};

	lineSegment: any = { x: 0, y: 0, hproperties: false };

	hideProperties: boolean = false;
	reportName: string = "";
	reportUrl: string = "";
	Id: string = '';
	primaryId: string = '';
	design_configs: any = {};
	rows: any = [];
	page: any = {};
	formHeight: number = 0;
	showOverlay: boolean = false;
	datasources: any = [];
	formFields: any = [];
	staticValues: any = {};
	datasets: any = {};
	filterData: any = {};
	processedGroup: any = [];
	reportData: any = {};
	dsCount: number = 0;
	filterRow: any = {};
	userInfo:any = {};
	pdfData = null;
	apiUrl:string ='';
	loading: boolean = true;
	showfilter : boolean = true;
	imageCount: number = 0;
	reportGenerated: boolean = false;
	containsData: boolean = false;
	currentDate: Date = new Date();

	public style: object = {};
 
	constructor(private environment:EnvService, public router: Router, public dataService: DataService, 
		public authService: AuthService, private route: ActivatedRoute, public toastr: ToastrService, 
		private datePipe: DatePipe, private sanitizer: DomSanitizer) {

		//this.currentDate.setDate(1);

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		this.apiUrl = environment.apiUrl;
		
		this.userInfo = this.authService.currentUserValue;

		let id = "";
		let primaryId = "";

		this.route.queryParams.subscribe(params => {
			id = params.id;
			if (params.pkid) primaryId = params.pkid;
		});

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		this.page = {
			"size":"A4",
			"orientation":"portrait",
			"width":"21",
			"height":"29.7",
			"show_filter": true,
			"show_group": true,
			"styles": {
				"text-align": "center",
				"justify-content": "center",
				"background-color": "transparent",
				"background": {
					"url": "",
					"fill": 1,
					"repeat": 0,
					"center": 1
				},
				"margin-right": 0,
				"margin-left": 0,
				"margin-top": 0,
				"margin-bottom": 0,
				"font-family": "Arial"
			}
		};

		if (id !== null && id !== "") {
			this.Id = id;
			if (primaryId !== null && primaryId !== '')
				this.primaryId = primaryId;

			this.dataService.getReport(this.Id).subscribe((res: any) => {
				this.reportName = res['Report_Name'];
				this.reportUrl = res['URL'];

				if (res['DESIGN_CONFIGS']) {
					this.design_configs = JSON.parse(res['DESIGN_CONFIGS']);

					this.rows = this.design_configs['rows'];
					this.page = this.design_configs['page'];

					if (this.primaryId === "")
						this.filterRow = this.rows[0];

					if (this.primaryId === "" && this.design_configs['formfields'] !== undefined && this.design_configs['formfields'] !==null){
						this.formFields = this.design_configs['formfields'];
						this.initializeFilter();
						this.setStaticValues();
					}
	
					if (this.design_configs['datasources'] !== undefined && this.design_configs['datasources'] !==null){
						this.datasources = this.design_configs['datasources'];
						if (this.formFields.length === 0 || this.primaryId !== ""){
							this.initializeDataset();
						}
					}
				}
			});
		}
		else {
			this.toastr.error("Error", "Report Info not found.");
		}

	}

	toggleFilter(){
        this.showfilter = !this.showfilter;
    }

	applyFilter($event){
		$event.preventDefault();
		this.loading = true;
		this.imageCount = 0;
		if (this.checkMandatoryFilterFields()){
			this.initializeDataset();
		}
		else{
			this.toastr.info("* Mandatory fields not filled..  Please check.","Information")
		}
	}

	setStaticValues(){      
        this.formFields.forEach(field => {
            if (field['DATA_TYPE']==='Dropdown' && field.DROPDOWN_SQL !== ''){ 
				this.dataService.getSelectOptions(field.DROPDOWN_SQL).subscribe(
					(result) => {
						if (result['RESULT_CODE'] === 1) {
							this.staticValues[field.ID] = result['DATA'];
						}
					},
					(error) => {
						console.error(error);
					}
				);                         
            }
            else if (field['DATA_TYPE']==='Dropdown' && field.DROPDOWN_JSON !== ''){
                this.staticValues[field.ID] = JSON.parse(field['DROPDOWN_JSON']);
            }
        });                
    }

	parseDate(dateString: string): Date {
		if (dateString) {
			return new Date(dateString);
		}
		return new Date();
	  }

	initializeFilter(){
		this.formFields.forEach(fld => {
			if (fld['DATA_TYPE']==='Date')
				this.filterData[fld.FIELD_NAME] = this.currentDate;
			else
				this.filterData[fld.FIELD_NAME] = "";
		});
	}

	initializeDataset(){
		this.showOverlay = true;
		this.reportGenerated = false;
		this.dsCount = this.datasources.length;
		this.datasources.forEach(ds => {
			this.datasets[ds.ID] = [];
		});
		this.getReportData();
	}

	checkMandatoryFilterFields(){
		var isValid : boolean = true;
		this.formFields.forEach(field=>{
			if (field.Is_Mandatory !== undefined && field.Is_Mandatory === true){
				if (this.filterData[field.FIELD_NAME] === null || this.filterData[field.FIELD_NAME] === ""){
					isValid = false;
					return isValid;
				}
			}
		});
		return isValid;
	}

	getFilterDateValue(Field_Name){
		var fld = this.formFields.filter(fld=>fld.FIELD_NAME===Field_Name)[0];
		if (fld['DATA_TYPE']==='Date')
			return this.datePipe.transform(this.filterData[Field_Name],"yyyy-MM-dd");
		else
			return this.filterData[Field_Name];
	}

	getReportData(){
		this.containsData = false;
		this.dsCount = this.datasources.length;
		this.datasources.forEach(ds => {
			var paramWhere = "";
			
			if (this.primaryId === ""){
				ds.QUERY_PARAMS.forEach(param => {
					if (param.Value !== undefined && param.Value !== null && param.Value !== ''){
						if (this.filterData[param.Value] !== undefined && this.filterData[param.Value] !== null && this.filterData[param.Value] !== ""){
							if (ds.QUERY_MODE === undefined || ds.QUERY_MODE === 'Configure')
								paramWhere += " AND `" + param['Alias'] + "`.`" + param['Field_Name'] + "` " + param['Operation'] + " '" + this.getFilterDateValue(param.Value) + "'";
							else
								paramWhere += " AND `" + param['Field_Name'] + "` " + param['Operation'] + " '" + this.getFilterDateValue(param.Value) + "'";
						}
					}
				});
			}
			else{
				if (ds.QUERY_MODE === undefined || ds.QUERY_MODE === 'Configure')
					paramWhere += " AND `t1`.`ID`='" + this.primaryId + "'";
				else
					paramWhere += " AND `ID` = '" + this.primaryId + "'";
			}
			
			this.dataService.getQueryData(ds.QUERY_ID, paramWhere).subscribe(res=>{
				this.dsCount--;
				if (res.DATA !== undefined){
					this.datasets[ds.ID]=res.DATA;
					if (res.DATA.length > 0){
						if (this.rows[2].datasource !== "" && this.rows[2].datasource === ds.ID){
							this.containsData = true;
						}
						else if (this.rows[3].datasource !== "" && this.rows[3].datasource === ds.ID){
							this.containsData = true;
						}
					}
				}
				if (this.dsCount === 0){
					if (this.containsData)
						this.setImageReportFieldBlobs();
					else{
						this.loading = false;
						this.showOverlay = false;
						this.toastr.info("No Data found..", "Information");
					}
				}
			}, error=>{
				this.dsCount--;
				if (this.dsCount === 0){
					if (this.containsData)
						this.setImageReportFieldBlobs();
					else{
						this.loading = false;
						this.showOverlay = false;
						this.toastr.info("No Data found..", "Information");
					}
				}
			});
		});
	}

	setImageReportFieldBlobs(){
		this.rows.forEach(row=>{
			row.columns.forEach(column=>{
				column.contents.forEach(content=>{
					if (content.type==='DataField' && content.datafield.type !== undefined && content.datafield.type === 'image'){
						if (content.datafield.field !== undefined && content.datafield.field !== null){
							this.setImageBlob(content);
						}
					}
				});
			});
		});
		if (this.imageCount === 0){
			this.generateReport();
		}
	}

	setImageBlob(content) {
		this.datasets[content.datafield.datasource].forEach(dataRow=>{
			var url = dataRow[content.datafield.field_Alias];
			if (url !== null && url !== ''){
				this.imageCount++;
				this.dataService.getImageBlob(url).subscribe(
					(data) => {
						dataRow[content.datafield.field_Alias] = 'data:image/png;base64,' + data.DATA;
						this.imageCount--;
						if (this.imageCount === 0){
							this.generateReport();
						}
					},
					(error) => {
						this.imageCount--;
						if (this.imageCount === 0){
							this.generateReport();
						}
					}
				);
			}
		});
	}

	generateReport(){
		if (!this.reportGenerated){
			this.reportGenerated = true;
			this.showOverlay = false;
			var pageStyle = this.page.styles;
			var reportContent = this.getReportContent();
			var reportHdrHeight = this.getPT(this.rows[1].styles.height);
			var reportFtrHeight = this.getPT(this.rows[5].styles.height);
			const dd = { 
				pageSize: this.page.size,
				pageOrientation: this.page.orientation,
				pageMargins: [10,reportHdrHeight+15,10,reportFtrHeight+10],
				header: (currentPage, pageCount, pageSize) => { 
					return this.getHeaderData(currentPage, pageCount, pageSize);
				},
				footer: (currentPage, pageCount) => { 
					return this.getFooterData(currentPage, pageCount);
				},
				content: reportContent,
				styles: {
					page_header: {
						fillColor: this.rows[1].styles['background-color']
					},
					group_header: {
						fillColor: this.rows[2].styles['background-color']
					},
					page_detail:{
						fillColor: this.rows[3].styles['background-color']
					},
					group_footer: {
						fillColor: this.rows[4].styles['background-color']
					},
					page_footer:{
						fillColor: this.rows[5].styles['background-color']
					},
					tableHeader: {
						margin: [0,5,0,5],
						bold: true,
						fontSize: 10,
						color: "black",
						fillColor: "#B7E9F7"
					},
					column: {
						fontSize: 10,
						color: "black"
					}
				}
			};
			pdfMake.createPdf(dd).getDataUrl((dataUrl) =>{
				this.pdfData=this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
				this.loading = false;
			});
		}
	}

	getPT(px){
		return (parseInt(px)*3)/4;
	}

	getHeaderData(currentPage, pageCount, pageSize){
		var groupHeaderData: any = [];
		var groupColumnWidth: any = [];
		var groupBgColor = this.rows[1].styles["background-color"];
		var headerHeight = this.getPT(this.rows[1].styles["height"]);

		this.rows[1].columns.forEach(column => {
			groupColumnWidth.push(this.getWidth(column.class));
			column.contents.forEach(content => {
				var contentMargin = [10,10];
				var contentFontSize = this.getPT(content.styles['font-size']?.replace("px",""));
				var contentFont = content.styles['font-family'];
				var contentAlign = content.styles['text-align'];
				var contentColor = content.styles['color'];
				var contentUnderline = (content.styles['text-decoration'] === 'underline');
				var contentItalic = (content.styles['font-style']==='italic');
				var contentBold = (content.styles['font-weight']==='bold');
				var contentWidth = this.getPT(content.styles['width']);
				var contentHeight = this.getPT(content.styles['height']);
				var contentPosition = { x: this.getPT(content.styles['margin-left'].replace("px","")), 
					y: this.getPT(content.styles['margin-top'].replace("px",""))};

				if (content.type==="Text"){
					var textData = {text: content.value, width:contentWidth, alignment: contentAlign, color:contentColor,
						fontSize: contentFontSize, relativePosition: contentPosition
					};
					if (contentUnderline){
						textData["decoration"] = "underline";
					}
					if (contentItalic){
						textData["italics"] = contentItalic;
					}
					if (contentBold){
						textData["bold"] = contentBold;
					}
					var columnData: any = {columns: []};
					columnData.columns.push(textData);
					groupHeaderData.push(columnData);

				}
				else if (content.type==="Image"){
					if (content.blob !== undefined && content.blob !== null && content.blob !== ""){
						var imageData = {image: content.blob, width:contentWidth, height:contentHeight, relativePosition: contentPosition};
						groupHeaderData.push(imageData);
					}
				}
				else if (content.type==="DataField"){
					if (content.datafield.type === undefined || content.datafield.type === 'text'){
						var dfData = {text: this.datasets[content.datafield.datasource][0][content.datafield.field_Alias], 
							width:contentWidth, alignment: contentAlign, fontSize: contentFontSize, color:contentColor,
							relativePosition: contentPosition
						};
						if (contentUnderline){
							dfData["decoration"] = "underline";
						}
						if (contentItalic){
							dfData["italics"] = contentItalic;
						}
						if (contentBold){
							dfData["bold"] = contentBold;
						}
						var columnDataField: any = {columns: []};
						columnDataField.columns.push(dfData);
						groupHeaderData.push(columnDataField);
					}
					else{
						var blob = this.datasets[content.datafield.datasource][0][content.datafield.field_Alias];
						if (blob !== undefined && blob !== null && blob !== ""){
							var imgData = {image: blob, width:contentWidth, height:contentHeight, relativePosition: contentPosition};
							groupHeaderData.push(imgData);
						}
					}
				}
				else if (content.type==="ReportField"){
					var contentValue = this.getReportFieldData(content.field,currentPage,pageCount);
					var reportFieldData = {text: contentValue, width:contentWidth, alignment: contentAlign, 
						fontSize: contentFontSize, color:contentColor, relativePosition: contentPosition
					};
					if (contentUnderline){
						reportFieldData["decoration"] = "underline";
					}
					if (contentItalic){
						reportFieldData["italics"] = contentItalic;
					}
					if (contentBold){
						reportFieldData["bold"] = contentBold;
					}
					var columnReportField: any = {columns: []};
					columnReportField.columns.push(reportFieldData);					
					groupHeaderData.push(columnReportField);
				}
			});
		});

		var headerContent = {
		    "margin": [10,10,10,5],
			"layout": "noBorders",
		    "table":{
		        "widths":["100%"],
		        "heights":[headerHeight],
		        "border":[false,false,false,false],
		        "body":[[{stack: groupHeaderData }]]
		    }
		};

		if (groupBgColor !== "transparent" && groupBgColor !== ""){
			headerContent["style"] = "page_header";
		}
		return headerContent;
	}

	getWidth(size){
		var width = parseInt(size.replace("column-","").replace("col-",""));
		return parseFloat(((width * 100)/12).toString()).toFixed(2).toString() + "%";
	}

	getDataTableLayout(content){
		var layout = {};
		if (content.borderType === 'No Borders'){
			layout = {
				hLineWidth: function(){
					return 0;
				},
				vLineWidth: function() {
					return 0;
				}
			};
		}
		else if (content.borderType === undefined || content.borderType === ''){
			layout = {
				hLineWidth: function(){
					return 1;
				},
				vLineWidth: function() {
					return 1;
				}
			};
		}
		else if (content.borderType === 'Only Vertical'){
			layout = {
				hLineWidth: function(){
					return 0;
				},
				vLineWidth: function() {
					return 1;
				}
			};
		}
		else if (content.borderType === 'Only Horizontal'){
			layout = {
				hLineWidth: function(){
					return 1;
				},
				vLineWidth: function() {
					return 0;
				}
			};
		}
		else if (content.borderType === 'Vertical and Headers Horizontal'){
			layout = {
				hLineWidth: function(i, node){
					if (i===0 || i===1 || i===node.table.body.length)
						return 1;
					else
						return 0;
				},
				vLineWidth: function() {
					return 1;
				}
			};
		}
			
		return layout;
	}

	getDataTableData(contentId, settings){
		var data: any = [];
		var columns: any = [];
		if (settings.datasource !== undefined && settings.datasource !== ""){
			if (settings.fields.length >0){
				settings.fields.forEach(table => {
					table.Fields.forEach(field => {
						if (field.Selected === true){
							columns.push(field);
						}
					});
					if (table.Functions !== undefined){
						table.Functions.forEach(field => {
							if (field.Display_Name===undefined){
								field['Display_Name'] = field.Field_Name;
							}
							columns.push(field);
						});
					}
				});
				columns.sort(this.GetSortOrder('Sequence'));
			}
		}
		this.reportData[contentId] = {"columns":columns,"rows":this.datasets[settings.datasource]};
		return data;
	}

	getDetailData(reportContent){
		var detailData: any = [];
		var detailBgColor = this.rows[3].styles["background-color"];
		this.processedGroup = [];
		var groupDatasource = this.rows[2].datasource;
		var rowDatasource = this.rows[3].datasource;
		var groupFilterFields: any = [];
		var rowHeight = this.getPT(this.rows[3].styles['height']);
		this.rows[3].columns.forEach(column => {
			var dataTables = column.contents.filter(content=>content.type==="DataTable");
			if (dataTables.length > 0){
				dataTables.forEach(content => {
					this.getDataTableData(content.id,this.rows[3]);
					var tableLayout = this.getDataTableLayout(content);

					detailData = [];
					var columnNames: any = [];
					var columnWidth: any = [];
					var rowValues: any = [];
					
					var tableHeaderColumns = this.reportData[content.id]['columns'].filter(column=> column.Width!=='0');

					tableHeaderColumns.forEach(col => {
						columnNames.push({text: col.Display_Name, style: "tableHeader", alignment: "center"});
						columnWidth.push(col.Width + "%");
					});

					if (groupDatasource !== "" && this.datasets[groupDatasource] !== undefined && this.datasets[groupDatasource] !== null){

						this.rows[3]["fields"].forEach(table => {
							table.Fields.filter(fld=>fld.Group_Field_Alias !== "").forEach(fld => {
								groupFilterFields.push(fld);
							});
							if (table.Functions !== undefined){
								table.Functions.filter(fld=>fld.Group_Field_Alias !== "").forEach(fld => {
									groupFilterFields.push(fld);
								});
							}
						});

						this.datasets[groupDatasource].forEach(datarow => {
							
							if (this.processedGroup.length === 0 || !this.isProcessedGroup(groupFilterFields,datarow)){

								this.processedGroup.push(datarow);

								if (this.page.show_group_header){
									reportContent = this.getGroupHeaderData(reportContent, datarow);
								}
								detailData = [];
								
								var filteredData = this.reportData[content.id]['rows'];
								groupFilterFields.forEach(filter=>{
									filteredData = filteredData.filter(rd=>rd[filter['Alias']] === datarow[filter['Group_Field_Alias']]);
								});

								detailData.push(JSON.parse(JSON.stringify(columnNames)));
								filteredData.forEach(row => {
									rowValues = [];
									tableHeaderColumns.forEach(col => {
										rowValues.push({text: row[col['Alias']], style: "column"});
									});
									detailData.push(rowValues);
								});

								var detailContent = {
									margin: [0,0,0,0],
									layout: tableLayout,
									table: {
										headerRows: 1,
										widths: columnWidth,
										body: detailData
									}
								}

								if (detailBgColor !== "transparent" && detailBgColor !== ""){
									detailContent["style"] = "page_detail";
								}

								reportContent.push(detailContent);

								if (this.page.show_group_footer){
									reportContent = this.getGroupFooterData(reportContent, datarow, true);
								}
							}
						});
					}
					else{
						detailData.push(columnNames);

						this.reportData[content.id]['rows'].forEach(row => {
							rowValues = [];
							tableHeaderColumns.forEach(col => {
								rowValues.push({text: row[col['Alias']], style: "column"});
							});
							detailData.push(rowValues);
						});

						var detailContent = {
							margin: [0,0,0,0],
							layout: tableLayout,
							table: {
								headerRows: 1,
								widths: columnWidth,
								body: detailData
							}
						}

						if (detailBgColor !== "transparent" && detailBgColor !== ""){
							detailContent["style"] = "page_detail";
						}

						reportContent.push(detailContent);
					}
					if (reportContent.length > 0)
						delete reportContent[reportContent.length-1]['pageBreak'];
				});
			}
			else {
				if (groupDatasource !== "" && this.datasets[groupDatasource] !== undefined && this.datasets[groupDatasource] !== null){

					this.rows[3]["fields"].forEach(table => {
						table.Fields.filter(fld=>fld.Group_Field_Alias !== "").forEach(fld => {
							groupFilterFields.push(fld);
						});
						if (table.Functions!==undefined){
							table.Functions.filter(fld=>fld.Group_Field_Alias !== "").forEach(fld => {
								groupFilterFields.push(fld);
							});
						}
					});
					this.datasets[groupDatasource].forEach(datarow => {
						if (this.processedGroup.length === 0 || !this.isProcessedGroup(groupFilterFields,datarow)){

							this.processedGroup.push(datarow);

							if (this.page.show_group_header){
								reportContent = this.getGroupHeaderData(reportContent, datarow);
							}
							detailData = [];
							
							var filteredData = this.datasets[rowDatasource];
							groupFilterFields.forEach(filter=>{
								filteredData = filteredData.filter(rd=>rd[filter['Alias']] === datarow[filter['Group_Field_Alias']]);
							});


							filteredData.forEach(row => {
								detailData.push(this.getDetailContentData(column.contents, row));
							});

							var detailContent = {
								"margin": [0,0,0,0],
								"layout": "noBorders",
								"table":{
									"widths":["100%"],
									"heights": [rowHeight],
									"border":[false,false,false,false],
									"body":[[{stack: detailData }]]
								}
							};

							if (detailBgColor !== "transparent" && detailBgColor !== ""){
								detailContent["style"] = "page_detail";
							}

							if (!this.page.show_group_footer){
								detailContent["pageBreak"] =  'after';
							}

							reportContent.push(detailContent);

							if (this.page.show_group_footer){
								reportContent = this.getGroupFooterData(reportContent, datarow, true);
							}
						}
					});
					if (reportContent.length > 0)
						delete reportContent[reportContent.length-1]['pageBreak'];
				}
				else{
					var _self = this;
					this.datasets[rowDatasource].forEach(function(row,idx) {
						detailData = _self.getDetailContentData(column.contents, row);
						var detailContent = {
							"margin": [0,0,0,0],
							"layout": "noBorders",
							"table":{
								"widths":["100%"],
								"heights": [rowHeight],
								"border":[false,false,false,false],
								"body":[[{stack: detailData }]]
							}
						};
	
						if (detailBgColor !== "transparent" && detailBgColor !== ""){
							detailContent["style"] = "page_detail";
						}
						if (idx < _self.datasets[rowDatasource].length - 1){
							detailContent["pageBreak"] =  'after';
						}
						reportContent.push(detailContent);
					});
				}
			}
		});
		
		return reportContent;
	}

	getDetailContentData(contents, data){
		var detailData: any = [];
		contents.forEach(content => {
			var contentFontSize = this.getPT(content.styles['font-size']?.replace("px",""));
			var contentFont = content.styles['font-family'];
			var contentColor = content.styles['color'];
			var contentAlign = content.styles['text-align'];
			var contentUnderline = (content.styles['text-decoration'] === 'underline');
			var contentItalic = (content.styles['font-style']==='italic');
			var contentBold = (content.styles['font-weight']==='bold');
			var contentWidth = this.getPT(content.styles['width']);
			var contentHeight = this.getPT(content.styles['height']);
			var contentPosition = { x: this.getPT(content.styles['margin-left'].replace("px","")), 
				y: this.getPT(content.styles['margin-top'].replace("px",""))};

			if (content.type==="Text"){
				var contentDataText = {text: content.value, width:contentWidth, alignment: contentAlign, 
					color: contentColor, fontSize: contentFontSize, relativePosition: contentPosition, 
					border:[false,false,false,false]
				};
				if (contentUnderline){
					contentDataText["decoration"] = "underline";
				}
				if (contentItalic){
					contentDataText["italics"] = contentItalic;
				}
				if (contentBold){
					contentDataText["bold"] = contentBold;
				}
				var columnData: any = {columns: []};
				columnData.columns.push(contentDataText);
				detailData.push(columnData);
			}
			else if (content.type==="Image"){
				if (content.blob !== undefined && content.blob !== null && content.blob !== ""){
					var imageData = {image: content.blob, width:contentWidth, height:contentHeight, relativePosition: contentPosition};
					detailData.push(imageData);
				}
			}
			else if (content.type==="DataField"){
				if (content.datafield.type === undefined || content.datafield.type === 'text'){
					var contentDataField = {text: data[content.datafield.field_Alias], 
						width:contentWidth, alignment: contentAlign, color: contentColor, 
						fontSize: contentFontSize, relativePosition: contentPosition,
						border:[false,false,false,false]
					};
					if (contentUnderline){
						contentDataField["decoration"] = "underline";
					}
					if (contentItalic){
						contentDataField["italics"] = contentItalic;
					}
					if (contentBold){
						contentDataField["bold"] = contentBold;
					}
					var columnDataField: any = {columns: []};
					columnDataField.columns.push(contentDataField);
					detailData.push(columnDataField);
				}
				else{
					if (data[content.datafield.field_Alias] !== undefined && data[content.datafield.field_Alias] !== null && data[content.datafield.field_Alias] !== ""){
						var imgData = {image: data[content.datafield.field_Alias], width:contentWidth, height:contentHeight, relativePosition: contentPosition};
						detailData.push(imgData);
					}
				}
			}
			else if (content.type==="Line"){
				var contentLine = {canvas: [ {
					type: 'line', 
					x1: contentPosition.x, y1: contentPosition.y, 
					x2: contentPosition.x + contentWidth, y2: contentPosition.y,
					lineWidth: content.styles['border-width']
			   } ]};
			   detailData.push(contentLine);
			}
		});
		return detailData;
	}

	isProcessedGroup(groupFilterFields, newRow){
		var isProcessed = false;
		var filteredData = this.processedGroup;
		groupFilterFields.forEach(filter=>{
			filteredData = filteredData.filter(rd=>rd[filter['Group_Field_Alias']] === newRow[filter['Group_Field_Alias']]);
		});
		if (filteredData.length > 0){
			isProcessed = true;
		}

		return isProcessed;
	}

	getGroupHeaderData(reportContent, data){
		var groupHeaderData: any = [];
		var groupColumnWidth: any = [];
		var groupBgColor = this.rows[2].styles["background-color"];
		var rowHeight = this.getPT(this.rows[2].styles['height']);

		this.rows[2].columns.forEach(column => {
			groupColumnWidth.push(this.getWidth(column.class));
			column.contents.forEach(content => {
				var contentMargin = [10,10];
				var contentFontSize = this.getPT(content.styles['font-size']?.replace("px",""));
				var contentFont = content.styles['font-family'];
				var contentColor = content.styles['color'];
				var contentAlign = content.styles['text-align'];
				var contentUnderline = (content.styles['text-decoration'] === 'underline');
				var contentItalic = (content.styles['font-style']==='italic');
				var contentBold = (content.styles['font-weight']==='bold');
				var contentWidth = this.getPT(content.styles['width']);
				var contentHeight = this.getPT(content.styles['height']);
				var contentPosition = { x: this.getPT(content.styles['margin-left'].replace("px","")), 
					y: this.getPT(content.styles['margin-top'].replace("px",""))};
				if (content.type==="Text"){
					var contentDataText = {text: content.value, width:contentWidth, alignment: contentAlign, 
						color: contentColor, fontSize: contentFontSize, relativePosition: contentPosition, 
						border:[false,false,false,false]
					};
					if (contentUnderline){
						contentDataText["decoration"] = "underline";
					}
					if (contentItalic){
						contentDataText["italics"] = contentItalic;
					}
					if (contentBold){
						contentDataText["bold"] = contentBold;
					}
					var columnData: any = {columns: []};
					columnData.columns.push(contentDataText);
					groupHeaderData.push(columnData);
				}
				else if (content.type==="Image"){
					if (content.blob !== undefined && content.blob !== null && content.blob !== ""){
						var imageData = {image: content.blob, width:contentWidth, height:contentHeight, relativePosition: contentPosition};
						groupHeaderData.push(imageData);
					}
				}
				else if (content.type==="DataField"){
					if (content.datafield.type === undefined || content.datafield.type === 'text'){
						var contentDataField = {text: data[content.datafield.field_Alias], 
							width:contentWidth, alignment: contentAlign, color: contentColor, 
							fontSize: contentFontSize, relativePosition: contentPosition,
							border:[false,false,false,false]
						};
						if (contentUnderline){
							contentDataField["decoration"] = "underline";
						}
						if (contentItalic){
							contentDataField["italics"] = contentItalic;
						}
						if (contentBold){
							contentDataField["bold"] = contentBold;
						}
						var columnDataField: any = {columns: []};
						columnDataField.columns.push(contentDataField);
						groupHeaderData.push(columnDataField);
					}
					else{
						if (data[content.datafield.field_Alias] !== undefined && data[content.datafield.field_Alias] !== null && data[content.datafield.field_Alias] !== ""){
							var imgData = {image: data[content.datafield.field_Alias], width:contentWidth, height:contentHeight, relativePosition: contentPosition};
							groupHeaderData.push(imgData);
						}
					}
				} else if (content.type === 'Line') {
					var contentLine = {
						canvas: [
							{
								type: 'line',
								x1: contentPosition.x,
								y1: contentPosition.y,
								x2: contentPosition.x + contentWidth,
								y2: contentPosition.y,
								lineWidth: content.styles['border-width'],
							},
						],
					};
					groupHeaderData.push(contentLine);
				}
			});
		});

		var groupContent = {
		    "margin": [0,0,0,0],
			"layout": "noBorders",
		    "table":{
		        "widths":["100%"],
		        "heights":[rowHeight],
		        "border":[false,false,false,false],
		        "body":[[{stack: groupHeaderData }]]
		    }
		};

		if (groupBgColor !== "transparent" && groupBgColor !== ""){
			groupContent["style"] = "group_header";
		}

		reportContent.push(groupContent);

		return reportContent;
	}

	getGroupFooterData(reportContent, data, pageBreak){
		var groupFooterData: any = [];
		var groupColumnWidth: any = [];
		var groupBgColor = this.rows[4].styles["background-color"];
		var rowHeight = this.getPT(this.rows[4].styles['height']);
		this.rows[4].columns.forEach(column => {
			groupColumnWidth.push(this.getWidth(column.class));
			column.contents.forEach(content => {
				var contentMargin = [10,10];
				var contentFontSize = 10;
				if (content.styles['font-size'] !== undefined){
					contentFontSize = this.getPT(content.styles['font-size'].replace("px",""));
				}
				var contentFont = content.styles['font-family'];
				var contentColor = content.styles['color'];
				var contentAlign = content.styles['text-align'];
				var contentUnderline = (content.styles['text-decoration'] === 'underline');
				var contentItalic = (content.styles['font-style']==='italic');
				var contentBold = (content.styles['font-weight']==='bold');
				var contentWidth = this.getPT(content.styles['width']);
				var contentHeight = this.getPT(content.styles['height']);
				var contentPosition = { x: this.getPT(content.styles['margin-left'].replace("px","")), 
					y: this.getPT(content.styles['margin-top'].replace("px",""))};
				if (content.type==="Text"){
					var contentDataText = {text: content.value, width:contentWidth, alignment: contentAlign, 
						color: contentColor, fontSize: contentFontSize, relativePosition: contentPosition, 
						border:[false,false,false,false]
					};
					if (contentUnderline){
						contentDataText["decoration"] = "underline";
					}
					if (contentItalic){
						contentDataText["italics"] = contentItalic;
					}
					if (contentBold){
						contentDataText["bold"] = contentBold;
					}
					var columnData: any = {columns: []};
					columnData.columns.push(contentDataText);
					groupFooterData.push(columnData);
				}
				else if (content.type==="Image"){
					if (content.blob !== undefined && content.blob !== null && content.blob !== ""){
						var imageData = {image: content.blob, width:contentWidth, height:contentHeight, relativePosition: contentPosition};
						groupFooterData.push(imageData);
					}
				}
				else if (content.type==="DataField"){
					if (content.datafield.type === undefined || content.datafield.type === 'text'){
						var contentDataField = {text: data[content.datafield.field_Alias], 
							width:contentWidth, alignment: contentAlign, color: contentColor, 
							fontSize: contentFontSize, relativePosition: contentPosition,
							border:[false,false,false,false]
						};
						if (contentUnderline){
							contentDataField["decoration"] = "underline";
						}
						if (contentItalic){
							contentDataField["italics"] = contentItalic;
						}
						if (contentBold){
							contentDataField["bold"] = contentBold;
						}
						var columnDataField: any = {columns: []};
						columnDataField.columns.push(contentDataField);
						groupFooterData.push(columnDataField);
					}
					else {
						if (data[content.datafield.field_Alias] !== undefined && data[content.datafield.field_Alias] !== null && data[content.datafield.field_Alias] !== ""){
							var imgData = {image: data[content.datafield.field_Alias], width:contentWidth, height:contentHeight, relativePosition: contentPosition};
							groupFooterData.push(imgData);
						}
					}
				} else if (content.type === 'Line') {
					var contentLine = {
						canvas: [
							{
								type: 'line',
								x1: contentPosition.x,
								y1: contentPosition.y,
								x2: contentPosition.x + contentWidth,
								y2: contentPosition.y,
								lineWidth: content.styles['border-width'],
							},
						],
					};
					groupFooterData.push(contentLine);
				}
			});
		});

		var footerContent = {
		    "margin": [0,0,0,0],
			"layout": "noBorders",
			unbreakable: true,
		    "table":{
		        "widths":["100%"],
		        "heights":[rowHeight],
		        "border":[false,false,false,false],
		        "body":[[{stack: groupFooterData }]]
		    }
		};

		if (groupBgColor !== "transparent" && groupBgColor !== ""){
			footerContent["style"] = "group_footer";
		}

		if (pageBreak){
			footerContent["pageBreak"] =  'after';
		}

		reportContent.push(footerContent);

		return reportContent;
	}
	
	getReportContent(){
		var reportContent = [];
		
		reportContent = this.getDetailData(reportContent);
		
		return reportContent;
	}

	getFooterData(currentPage,pageCount){
		var groupFooterData: any = [];
		var groupColumnWidth: any = [];
		var groupBgColor = this.rows[5].styles["background-color"];
		var footerHeight = this.getPT(this.rows[5].styles["height"]);
		this.rows[5].columns.forEach(column => {
			groupColumnWidth.push(this.getWidth(column.class));
			column.contents.forEach(content => {
				var contentMargin = [10,10];
				var contentFontSize = this.getPT(content.styles['font-size']?.replace("px",""));
				var contentFont = content.styles['font-family'];
				var contentAlign = content.styles['text-align'];
				var contentColor = content.styles['color'];
				var contentUnderline = (content.styles['text-decoration'] === 'underline');
				var contentItalic = (content.styles['font-style']==='italic');
				var contentBold = (content.styles['font-weight']==='bold');
				var contentWidth = this.getPT(content.styles['width']);
				var contentHeight = this.getPT(content.styles['height']);
				var contentPosition = { x: this.getPT(content.styles['margin-left'].replace("px","")), 
					y: this.getPT(content.styles['margin-top'].replace("px",""))};

				if (content.type==="Text"){
					var textData = {text: content.value, width:contentWidth, alignment: contentAlign, color:contentColor,
						fontSize: contentFontSize, relativePosition: contentPosition
					};
					if (contentUnderline){
						textData["decoration"] = "underline";
					}
					if (contentItalic){
						textData["italics"] = contentItalic;
					}
					if (contentBold){
						textData["bold"] = contentBold;
					}
					var columnData: any = {columns: []};
					columnData.columns.push(textData);
					groupFooterData.push(columnData);

				}
				else if (content.type==="DataField"){
					if (content.datafield.type === undefined || content.datafield.type === 'text'){
						var dfData = {text: this.datasets[content.datafield.datasource][0][content.datafield.field_Alias], 
							width:contentWidth, alignment: contentAlign, fontSize: contentFontSize, color:contentColor,
							relativePosition: contentPosition
						};
						if (contentUnderline){
							dfData["decoration"] = "underline";
						}
						if (contentItalic){
							dfData["italics"] = contentItalic;
						}
						if (contentBold){
							dfData["bold"] = contentBold;
						}
						var columnDataField: any = {columns: []};
						columnDataField.columns.push(dfData);
						groupFooterData.push(columnDataField);
					}
					else {
						var blob = this.datasets[content.datafield.datasource][0][content.datafield.field_Alias];
						if (blob !== undefined && blob !== null && blob !== ""){
							var imgData = {image: blob, width:contentWidth, height:contentHeight, relativePosition: contentPosition};
							groupFooterData.push(imgData);
						}
					}
				}
				else if (content.type==="ReportField"){
					var contentValue = this.getReportFieldData(content.field,currentPage,pageCount);
					var reportFieldData = {text: contentValue, width:contentWidth, alignment: contentAlign, 
						fontSize: contentFontSize, color:contentColor,	relativePosition: contentPosition
					};
					if (contentUnderline){
						reportFieldData["decoration"] = "underline";
					}
					if (contentItalic){
						reportFieldData["italics"] = contentItalic;
					}
					if (contentBold){
						reportFieldData["bold"] = contentBold;
					}
					var columnReportField: any = {columns: []};
					columnReportField.columns.push(reportFieldData);
					groupFooterData.push(columnReportField);
				} else if (content.type === 'Line') {
					var contentLine = {
						canvas: [
							{
								type: 'line',
								x1: contentPosition.x,
								y1: contentPosition.y,
								x2: contentPosition.x + contentWidth,
								y2: contentPosition.y,
								lineWidth: content.styles['border-width'],
							},
						],
					};
					groupFooterData.push(contentLine);
				}
			});
		});

		var footerContent = {
		    "margin": [10,0,10,10],
			"layout": "noBorders",
		    "table":{
		        "widths":["100%"],
		        "heights":[footerHeight],
		        "border":[false,false,false,false],
		        "body":[[{stack: groupFooterData }]]
		    }
		};

		if (groupBgColor !== "transparent" && groupBgColor !== ""){
			footerContent["style"] = "page_footer";
		}

		return footerContent;

	}

	getReportFieldData(field, currentPage, pageCount){
		var value = "";
		if (field === "Date"){
			value = this.getCurrentDate();
		}
		else if (field === "Datetime"){
			value = this.getCurrentDatetime();
		}
		else if (field === "PageNo"){
			value = currentPage.toString();
		}
		else if (field === "TotalPages"){
			value = pageCount.toString();
		}
		else if (field === "Page"){
			value = "Page " + currentPage.toString() + " / " + pageCount.toString();
		}
		return value;
	}

	getCurrentDate(){
		var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();

		var date_day = "";
		var date_month = "";

        if (dd < 10) {
            date_day = '0' + dd;
        }
		else{
			date_day = dd.toString();
		}
        if (mm < 10) {
            date_month = '0' + mm;
        }
		else{
			date_month = mm.toString();
		}

        return date_day + '/' + date_month + '/' + yyyy;
	}

	getCurrentDatetime(){
		var currDate = this.getCurrentDate();
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var time_hh = "";
		var time_mm = "";

		if (h < 10) {
            time_hh = '0' + h;
        }
		else{
			time_hh = h.toString();
		}
        if (m < 10) {
            time_mm = '0' + m;
        }
		else{
			time_mm = m.toString();
		}

		return currDate + " " + time_hh + ":" + time_mm;
	}

	getFilterContent(){
		var content = {
			id: Guid.create().toString(),
			type: "Filters",
			arrowPosition:'',
			value: "Filters",
			target: "",
			url: "",
			map: {},
			styles: {},
			form: {
				title: {},
				table_id: "",
				fields: [],
				show_label: false,
				show_helptext: false,
				theme: ""
			}
		};
		return content;
	}

	setPclass(b, content, ev) {
		this.hideProperties = b;
		if (!b) {
			this.lineSegment.hproperties = false;
		}

		if (ev.srcElement !== undefined) {
			this.lineSegment.x = ev.srcElement.getBoundingClientRect().left;
			this.lineSegment.y = ev.srcElement.getBoundingClientRect().top;
		}
	}

	getHTML(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	getPageStyles(obj) {

		var style = {};

		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}

		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}

		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style["padding"] = obj['padding-top'] + "px " + obj['padding-right'] + "px " + obj['padding-bottom'] + "px " + obj['padding-left'] + "px";
		}

		if (obj['background'] !== null && obj['background'] !== undefined
			&& obj['background']['url'] !== null && obj['background']['url'] !== undefined
			&& obj['background']['url'] !== "") {

			style["background-image"] = 'url(' + obj['background']['url'] + ')';

			if (obj['background']['center'] === 1 || obj['background']['center'] === true) {
				style["background-position"] = 'center';
			}
			if (obj['background']['repeat'] === 1 || obj['background']['repeat'] === true) {
				style["background-repeat"] = 'repeat';
			}
			else {
				style["background-repeat"] = 'no-repeat';
			}
			if (obj['background']['fill'] === 1 || obj['background']['fill'] === true) {
				style["background-size"] = 'cover';
			}
		}
		else if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== "") {
			style["background-color"] = obj['background-color'];
		}

		return style;
	}

	getRowStyles(obj) {
		var style = {};
		style['z-index'] = 1000;
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}

		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style["padding"] = obj['padding-top'] + "px " + obj['padding-right'] + "px " + obj['padding-bottom'] + "px " + obj['padding-left'] + "px";
		}
		if (obj['border-style'] !== null && obj['border-style'] !== undefined) {

			if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
				style["border-top"] = obj['border-top'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-bottom"] = obj['border-bottom'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-left"] = obj['border-left'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-right"] = obj['border-right'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
			else {
				style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}
		if (obj["background-type"] !== "video" && obj['background'] !== null && obj['background'] !== undefined
			&& obj['background']['url'] !== null && obj['background']['url'] !== undefined
			&& obj['background']['url'] !== "") {

			style["background-image"] = 'url(' + obj['background']['url'] + ')';

			if (obj['background']['center'] === 1 || obj['background']['center'] === true) {
				style["background-position"] = 'center';
			}
			if (obj['background']['repeat'] === 1 || obj['background']['repeat'] === true) {
				style["background-repeat"] = 'repeat';
			}
			else {
				style["background-repeat"] = 'no-repeat';
			}
			if (obj['background']['fill'] === 1 || obj['background']['fill'] === true) {
				style["background-size"] = 'cover';
			}
		}
		else if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== "") {
			style["background-color"] = obj['background-color'];
		}

		if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0) {
			style["height"] = obj['height'] + "px";
		}

		return style;
	}

	getRowBackgroundColor(obj) {
		var style = {};
		if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== "") {
			style["background-color"] = obj['background-color'];
			style["opacity"] = obj['opacity'];
		}
		return style;
	}

	getColumnStyles(obj) {
		var style = {};
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style["padding"] = obj['padding-top'] + "px " + obj['padding-right'] + "px " + obj['padding-bottom'] + "px " + obj['padding-left'] + "px";
		}

		if (obj['border-style'] !== null && obj['border-style'] !== undefined) {

			if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
				style["border-top"] = obj['border-top'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-bottom"] = obj['border-bottom'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-left"] = obj['border-left'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-right"] = obj['border-right'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
			else {
				style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
			style["border-top-left-radius"] = obj['border-top-left-radius'] + "px";
		}
		if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
			style["border-top-right-radius"] = obj['border-top-right-radius'] + "px";
		}
		if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
			style["border-bottom-left-radius"] = obj['border-bottom-left-radius'] + "px";
		}
		if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
			style["border-bottom-right-radius"] = obj['border-bottom-right-radius'] + "px";
		}

		if (obj["background-type"] !== "video" && obj['background'] !== null && obj['background'] !== undefined
			&& obj['background']['url'] !== null && obj['background']['url'] !== undefined
			&& obj['background']['url'] !== "") {

			style["background-image"] = 'url(' + obj['background']['url'] + ')';

			if (obj['background']['center'] === 1 || obj['background']['center'] === true) {
				style["background-position"] = 'center';
			}
			if (obj['background']['repeat'] === 1 || obj['background']['repeat'] === true) {
				style["background-repeat"] = 'repeat';
			}
			else {
				style["background-repeat"] = 'no-repeat';
			}
			if (obj['background']['fill'] === 1 || obj['background']['fill'] === true) {
				style["background-size"] = 'cover';
			}
		}
		else if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== "") {
			style["background-color"] = obj['background-color'];
		}

		return style;
	}
	
	//reomve all html content frfom text
	stripHTML(val){
		return val.replace(/<.*?>/g, '');
	}

	//polymorph function for get style types
	getStyles(obj) {
		return this.getStylesType(obj, "");
	}

	//get styles for content
	getStylesType(obj, contentType) {
		var style = {};
		if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}

		if (obj['border-style'] !== null && obj['border-style'] !== undefined && contentType !== 'TextArea') {

			if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
				style["border-top"] = obj['border-top'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-bottom"] = obj['border-bottom'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-left"] = obj['border-left'] + "px " + obj['border-style'] + " " + obj['border-color'];
				style["border-right"] = obj['border-right'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
			else {
				style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
			style["border-top-left-radius"] = obj['border-top-left-radius'] + "px";
		}
		if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
			style["border-top-right-radius"] = obj['border-top-right-radius'] + "px";
		}
		if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
			style["border-bottom-left-radius"] = obj['border-bottom-left-radius'] + "px";
		}
		if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
			style["border-bottom-right-radius"] = obj['border-bottom-right-radius'] + "px";
		}	
		if(contentType !== 'Filters' && contentType !== 'Forms' && contentType !== 'Text'){
			if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
				style["border-radius"] = obj['border-radius'] + "px";
			}
		}

		if (obj['background'] !== null && obj['background'] !== undefined
			&& obj['background']['url'] !== null && obj['background']['url'] !== undefined
			&& obj['background']['url'] !== "") {

			style["background-image"] = 'url(' + obj['background']['url'] + ')';

			if (obj['background']['center'] === 1 || obj['background']['center'] === true) {
				style["background-position"] = 'center';
			}
			if (obj['background']['repeat'] === 1 || obj['background']['repeat'] === true) {
				style["background-repeat"] = 'repeat';
			}
			else {
				style["background-repeat"] = 'no-repeat';
			}
			if (obj['background']['fill'] === 1 || obj['background']['fill'] === true) {
				style["background-size"] = 'cover';
			}
		}
		else if (obj['background-color'] !== null && obj['background-color'] !== undefined && obj['background-color'] !== "") {
			style["background-color"] = obj['background-color'];
		}

		if (obj['color'] !== null && obj['color'] !== undefined && obj['color'] !== "") {
			style["color"] = obj['color'];
		}

		if (obj['full-width'] !== null && obj['full-width'] !== undefined && obj['full-width'] === true) {
			style['display'] = "block";
			style['width'] = "100%";
			obj['width'] = "100";
		}

		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
			style["line-height"] = obj['line-height'];
		}

		if ( obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0) {
			style["height"] = obj['height'] + "px";
		}

		if (contentType !== 'TextArea') {
			
			if( contentType === 'Filters' || contentType === 'ReportField' || contentType === 'DataField' || contentType === 'Text' || contentType === 'Button' || contentType === 'Image'){
				
				if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== "") {
					style['transform'] = 'translate('+obj['margin-left']+','+obj['margin-top']+')';
				}

			}else {

				if( contentType !== 'MenuGroup'){
					if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== "") {
						style['margin-left'] = obj['margin-left'] ;
					}
				}
		
				if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== "") {
					style['margin-top'] = obj['margin-top'] ;
				}
				if (obj['margin-bottom'] !== null && obj['margin-bottom'] !== undefined && obj['margin-bottom'] !== "") {
					style['margin-bottom'] = obj['margin-bottom'] ;
				}
			}

			if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
				style["padding-top"] = obj['padding-top'] + "px ";
			}
			if (obj['padding-right'] !== null && obj['padding-right'] !== undefined) {
				style["padding-right"] = obj['padding-right'] + "px ";
			}
			if (obj['padding-bottom'] !== null && obj['padding-bottom'] !== undefined) {
				style["padding-bottom"] = obj['padding-bottom'] + "px ";
			}
			if (obj['padding-left'] !== null && obj['padding-left'] !== undefined) {
				style["padding-left"] = obj['padding-left'] + "px";
			}

			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				style["min-height"] = obj['width'] + "px !important";
			}
		}

		if (contentType === 'ReportField' || contentType === 'DataField' || contentType === 'DataTable' || contentType === 'Charts' || contentType === 'Text' || contentType === 'TextArea') {
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				var objWidth = obj['width'];
				if (contentType === 'Text' || contentType === 'TextArea') {
					if (obj['width-type'] !== null && obj['width-type'] !== undefined && obj['width-type'] !== "" && obj['width-type'] === 'px') {
						//cargo cult
					} else {
						objWidth = obj['width'] * 13.8;
						obj['width-type'] = 'px';
					}
				}
				style["width"] = objWidth + "px";
				style['width-type'] = 'px';
			}
		} 
		else if (contentType === 'Filters'){
			style['width-type'] = '100%';
		}
		else {
			if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
				style["width"] = obj['width'] + "%";
			}
		}

		return style;
	}

	getMarginXY(content) {
		try {
			return content.styles['margin-top'].replace('px', '') + " x " + content.styles['margin-left'].replace('px', '');
		} catch (e) {
			return "0 x 0";
		}

	}

	GetSortOrder(prop) {
		return function (a, b) {
			if (a[prop] > b[prop]) {
				return 1;
			} else if (a[prop] < b[prop]) {
				return -1;
			}
			return 0;
		}
	}

	exportHtml() { }

	toCamelCase(str) {
		str = str.toLowerCase();
		return str
		  .replace("_"," ")
		  .replace(/^[a-z]/g, char => ` ${char.toUpperCase()}`)
		  .replace(/[A-Z]|[0-9]+/g, ' $&')
		  .replace(/(?:\s+)/, char => '');
	};
 
	getImagePosition(content){
		return content['styles']['position'] === undefined || content['styles']['position'] === '' ? '' : content['styles']['position'];
	}

	onChangeFilter(data){
		this.formFields = data;
	}

	getPositionSize(obj) {
		var style = {};
		try {

			var lineh = 0;
			if (obj['line-height'] !== null && obj['line-height'] !== undefined) {
				lineh = parseInt(obj['line-height'].replace("px", '')) > 150 ? 0 : 10;
			}

			style["bottom"] = "-" + (lineh + 20) + "px";

		} catch (error) {
			style["bottom"] = "-35px";
		}

		return style;

	}

	getMarginPositionSize(obj) {

		var style = {};
		style["font-size"] = "8px";
		style["top"] = "0px";

		return style;

	}

	ngOnInit(): void {
		this.formHeight = window.innerHeight - 60;
	}

	ngAfterViewInit(): void {}

	//clear popup window control
	ngOnDestroy() {	}

	printData(data){ }

	getInputStyles(obj){
        var style:any={};

		if (obj === undefined)
			return style;

        if (obj['background-color']!==undefined){
            style['background-color'] = obj['background-color'];
        }
        if (obj['color']!==undefined){
            style['color'] = obj['color'];
        }

        if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}

		if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
			style['border-top'] = obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-bottom'] !== null && obj['border-bottom'] !== undefined) {
			style['border-bottom'] = obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-left'] !== null && obj['border-left'] !== undefined) {
			style['border-left'] = obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-right'] !== null && obj['border-right'] !== undefined) {
			style['border-right'] = obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}

		if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
			style['border-top-left-radius'] = obj['border-top-left-radius'] + 'px';
		}
		if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
			style['border-top-right-radius'] = obj['border-top-right-radius'] + 'px';
		}
		if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
			style['border-bottom-left-radius'] = obj['border-bottom-left-radius'] + 'px';
		}
		if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
			style['border-bottom-right-radius'] = obj['border-bottom-right-radius'] + 'px';
		}
        
        return style;
    }

    getButtonStyles(obj){
        var style:any={};

		if (obj === undefined)
			return style;

        if (obj['background-color'] !== null && obj['background-color'] !== undefined){
            style['background-color'] = obj['background-color'];
        }
        if (obj['color'] !== null && obj['color'] !== undefined){
            style['color'] = obj['color'];
        }

        if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style['font-size'] = obj['font-size'];
		}
        
        if (obj['padding-top'] !== null && obj['padding-top'] !== undefined) {
			style['padding-top'] = obj['padding-top'] + 'px';
		}
		if (obj['padding-bottom'] !== null && obj['padding-bottom'] !== undefined) {
			style['padding-bottom'] = obj['padding-bottom'] + 'px';
		}
		if (obj['padding-left'] !== null && obj['padding-left'] !== undefined) {
			style['padding-left'] = obj['padding-left'] + 'px';
		}
		if (obj['padding-right'] !== null && obj['padding-right'] !== undefined) {
			style['padding-right'] = obj['padding-right'] + 'px';
		}

		if (obj['border-top'] !== null && obj['border-top'] !== undefined) {
			style['border-top'] = obj['border-top'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-bottom'] !== null && obj['border-bottom'] !== undefined) {
			style['border-bottom'] = obj['border-bottom'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-left'] !== null && obj['border-left'] !== undefined) {
			style['border-left'] = obj['border-left'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}
		if (obj['border-right'] !== null && obj['border-right'] !== undefined) {
			style['border-right'] = obj['border-right'] + 'px ' + obj['border-style'] + ' ' + obj['border-color'];
		}

		if (obj['border-top-left-radius'] !== null && obj['border-top-left-radius'] !== undefined) {
			style['border-top-left-radius'] = obj['border-top-left-radius'] + 'px';
		}
		if (obj['border-top-right-radius'] !== null && obj['border-top-right-radius'] !== undefined) {
			style['border-top-right-radius'] = obj['border-top-right-radius'] + 'px';
		}
		if (obj['border-bottom-left-radius'] !== null && obj['border-bottom-left-radius'] !== undefined) {
			style['border-bottom-left-radius'] = obj['border-bottom-left-radius'] + 'px';
		}
		if (obj['border-bottom-right-radius'] !== null && obj['border-bottom-right-radius'] !== undefined) {
			style['border-bottom-right-radius'] = obj['border-bottom-right-radius'] + 'px';
		}

        return style;
    }
}
