import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframelookup',
  templateUrl: './iframelookup.component.html',
  styleUrls: ['./iframelookup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IframeLookupComponent {
    SrcUrl: SafeResourceUrl;
    url: string = '';
    modal_height: any = "";
    showOverlay:boolean = false;

    constructor(private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<IframeLookupComponent>, 
        @Inject(MAT_DIALOG_DATA) data) {
        //this.modal_height = ((window.innerHeight * (parseInt(data['HEIGHT'].replace("%",""))/100))-10)+"px";
        this.modal_height = data['HEIGHT'];
        this.url = data['URL'];
        this.SrcUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
    }

    close() {
        this.dialogRef.close();
    }

}
