<ng-container *ngIf="content.template==='1'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-12 mb-1" [ngStyle]="getStyles(content.category_styles)">
                <span class="float-left ml-2">{{content.categoryName}}</span>
                <a class="btn btn-info float-right text-sm mr-2" (click)="showAllProducts(content)" role="button"
                *ngIf="content.button_styles===undefined">
                    View More <i class="fas fa-arrow-circle-right"></i>
                </a>
                <button class="float-right mr-2" (click)="showAllProducts(content)" 
                *ngIf="content.button_styles!==undefined"
                [ngStyle]="getFormButtonStyle(content.button_styles)"
                [style.textDecoration]="content['button_styles']['text-decoration']"
                [style.fontStyle]="content['button_styles']['font-style']"
                [style.fontWeight]="content['button_styles']['font-weight']"
                [style.color]="content['button_styles']['color']"
                [style.backgroundColor]="content['button_styles']['background-color']">
                    View More <i class="fas fa-arrow-circle-right"></i>
                </button>
            </div>
            <div class="col-12 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide *ngFor="let item of content.items; let index=index">
                        <div style="cursor:pointer;"
                            [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                            style="display:flex;flex-direction: column;" (click)="showProduct(item)">
                            <div [ngStyle]="getStyles(content.image_styles)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                                    [style.height.px]="content.image_styles.height">
                            </div>
                            <div class="mt-1" [ngStyle]="getStyles(content.product_styles)" 
                                style="cursor:pointer;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                                {{item.productName}}
                            </div>
                            <!-- <div class="mt-1" [ngStyle]="getStyles(content.price_styles)">
                                Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div> -->
                            <div class="mt-1" [ngStyle]="getStyles(content.price_styles)" 
                            *ngIf="item.price !== item.discount_Price">
                                Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1" [ngStyle]="getStyles(content.price_styles)" 
                            *ngIf="item.price === item.discount_Price">
                                Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='2'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-xs-12 col-sm-12 col-md-12 float-left" [ngStyle]="getStyles(content.category_styles)">
                {{content.categoryName}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 mb-1">
                <div style="cursor:pointer;border:0px solid transparent;margin-left:1px;margin-right:1px;"
                    (click)="showAllProducts(content)">
                    <div [ngStyle]="getStyles(content.image_styles)">
                        <img [src]="content.IMAGE_URL" alt="image slide" 
                        style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                        [style.height.px]="content.image_styles.height"/>
                    </div>
                    <div class="mt-1 mb-1">
                        <a class="btn btn-info text-white" (click)="showAllProducts(content)" role="button"
                        *ngIf="content.button_styles===undefined">
                            View More <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <button (click)="showAllProducts(content)" 
                        *ngIf="content.button_styles!==undefined"
                        [ngStyle]="getFormButtonStyle(content.button_styles)"
                        [style.textDecoration]="content['button_styles']['text-decoration']"
                        [style.fontStyle]="content['button_styles']['font-style']"
                        [style.fontWeight]="content['button_styles']['font-weight']"
                        [style.color]="content['button_styles']['color']"
                        [style.backgroundColor]="content['button_styles']['background-color']">
                            View More <i class="fas fa-arrow-circle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-9 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide *ngFor="let item of content.items; let index=index">
                        <div style="cursor:pointer;"
                            [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                            style="display:flex;flex-direction: column;" (click)="showProduct(item)">
                            <div [ngStyle]="getStyles(content.slide_styles)">
                                <div [ngStyle]="getStyles(content.image_styles)">
                                    <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                                    [style.height.px]="content.image_styles.height">
                                </div>
                                <!-- <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)">
                                    Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                                </div> -->
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                                *ngIf="item.price !== item.discount_Price">
                                    Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                                *ngIf="item.price === item.discount_Price">
                                    Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                                </div>
                                <div class="mt-1 mb-1" [ngStyle]="getStyles(content.product_styles)" 
                                    style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                                    {{item.productName}}
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='3'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-xs-12 col-sm-12 col-md-12 float-left" [ngStyle]="getStyles(content.category_styles)">
                {{content.categoryName}}
            </div>
        </div>
        <div class="row">
            <div class="col-9 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide *ngFor="let item of content.items; let index=index">
                        <div style="cursor:pointer;"
                            [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                            style="display:flex;flex-direction: column;" (click)="showProduct(item)">
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.product_styles)" 
                                style="cursor:pointer;line-height: 1.5em; height: 3em; overflow: hidden;">
                                {{item.productName}}
                            </div>
                            <div [ngStyle]="getStyles(content.image_styles)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                                    [style.height.px]="content.image_styles.height">
                            </div>
                            <!-- <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)">
                                Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div> -->
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                            *ngIf="item.price !== item.discount_Price">
                                Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div class="mt-1 mb-1" [ngStyle]="getStyles(content.price_styles)" 
                            *ngIf="item.price === item.discount_Price">
                                Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="col-3 mb-1">
                <div style="cursor:pointer;border:0px solid transparent;margin-left:1px;margin-right:1px;"
                    (click)="showAllProducts(content)">
                    <div [ngStyle]="getStyles(content.image_styles)">
                        <img [src]="content.IMAGE_URL" alt="image slide" 
                        style="cursor:pointer;display: block; width: 100%;object-fit: contain;" 
                        [style.height.px]="content.image_styles.height"/>
                    </div>
                    <div class="mt-1 mb-1">
                        <a class="btn btn-info text-white" (click)="showAllProducts(content)" role="button"
                        *ngIf="content.button_styles===undefined">
                            View More <i class="fas fa-arrow-circle-right"></i>
                        </a>
                        <button (click)="showAllProducts(content)" 
                        *ngIf="content.button_styles!==undefined"
                        [ngStyle]="getFormButtonStyle(content.button_styles)"
                        [style.textDecoration]="content['button_styles']['text-decoration']"
                        [style.fontStyle]="content['button_styles']['font-style']"
                        [style.fontWeight]="content['button_styles']['font-weight']"
                        [style.color]="content['button_styles']['color']"
                        [style.backgroundColor]="content['button_styles']['background-color']">
                            View More <i class="fas fa-arrow-circle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="content.template==='4'">
    <div class="mt-2 mb-2">
        <div class="row mb-1">
            <div class="col-12 mb-1" [ngStyle]="getStyles(content.category_styles)">
                <span class="float-left ml-2">{{content.categoryName}}</span>
                <a class="btn btn-info float-right text-sm mr-2" (click)="showAllProducts(content)" role="button"
                *ngIf="content.button_styles===undefined">
                    View More <i class="fas fa-arrow-circle-right"></i>
                </a>
                <button class="float-right mr-2" (click)="showAllProducts(content)" 
                *ngIf="content.button_styles!==undefined"
                [ngStyle]="getFormButtonStyle(content.button_styles)"
                [style.textDecoration]="content['button_styles']['text-decoration']"
                [style.fontStyle]="content['button_styles']['font-style']"
                [style.fontWeight]="content['button_styles']['font-weight']"
                [style.color]="content['button_styles']['color']"
                [style.backgroundColor]="content['button_styles']['background-color']">
                    View More <i class="fas fa-arrow-circle-right"></i>
                </button>
            </div>
            <div class="col-12 mb-1">
                <carousel noWrap=false [itemsPerSlide]="window_width > 786 ? content.itemsPerSlide : 1"
                [singleSlideOffset]="true" [interval]="false" style="width: 100%;">
                    <slide *ngFor="let item of content.items; let index=index">
                        <div style="cursor:pointer;"
                            [ngStyle]="getStylesMain(content.slide_styles,content.styles)"
                            style="display:flex;flex-direction: column;" (click)="showProduct(item)">
                            <div [ngStyle]="getStyles(content.image_styles)">
                                <img [src]="item.imageUrl" alt="image slide" 
                                    style="cursor:pointer;display: block; width: 100%;object-fit: fill;" 
                                    [style.height.px]="content.image_styles.height">
                            </div>
                            <div [ngStyle]="getStyles(content.product_styles)" 
                                style="cursor:pointer;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                                {{item.productName}}
                            </div>
                            <!-- <div [ngStyle]="getStyles(content.price_styles)">
                                Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div> -->
                            <div [ngStyle]="getStyles(content.price_styles)" 
                            *ngIf="item.price !== item.discount_Price">
                                Price : {{appData.CurrencySymbol}} {{item.discount_Price | number:'1.2-2'}} <span style="text-decoration: line-through;">{{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}</span>  
                            </div>
                            <div [ngStyle]="getStyles(content.price_styles)" 
                            *ngIf="item.price === item.discount_Price">
                                Price : {{appData.CurrencySymbol}} {{item.price | number:'1.2-2'}}
                            </div>
                            <div style="display: flex;"
                            [style.marginTop.px]="content.show_styles['margin-top']"
                            [style.marginBottom.px]="content.show_styles['margin-bottom']"
                            [style.marginLeft.px]="content.show_styles['margin-left']"
                            [style.marginRight.px]="content.show_styles['margin-right']" >
                                <button class="btn btn-info mr-2" style="cursor:pointer;"
                                *ngIf="content.show_styles===undefined">Show Item</button>
                                <button class="mr-2" *ngIf="content.show_styles!==undefined"
                                [ngStyle]="getFormButtonStyle(content.show_styles)"
                                [style.textDecoration]="content['show_styles']['text-decoration']"
                                [style.fontStyle]="content['show_styles']['font-style']"
                                [style.fontWeight]="content['show_styles']['font-weight']"
                                [style.color]="content['show_styles']['color']"
                                [style.backgroundColor]="content['show_styles']['background-color']"
                                style="cursor:pointer;">Show Item</button>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-container>