import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, EcommerceService } from '../../../services';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
  selector: 'app-customerprofile',
  templateUrl: './customerprofile.component.html',
  styleUrls: ['./customerprofile.component.scss']
})
export class CustomerprofileComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  @Input() props: any;
  @Input() designPage: string;
  @Input() row: any;
  valForm: FormGroup;
  pwdForm: FormGroup;
  userData: any = {};
  
  constructor(public router: Router, public pageFunctions: PageFunctions, 
    public authService:AuthService, public dataService:EcommerceService, 
    fb: FormBuilder, public snackBar: MatSnackBar, private toastr: ToastrService) {
    this.valForm = fb.group({
      'firstName': [null, Validators.compose([Validators.required])],
      'lastName': [null, Validators.compose([Validators.required])],
      'mobile': [null, Validators.compose([Validators.required])],
      'email': [''],
      'address1': [null, Validators.compose([Validators.required])],
      'address2': [null, Validators.compose([Validators.required])],
      'city': [null, Validators.compose([Validators.required])],
      'state': [null, Validators.compose([Validators.required])],
      'country': [''],
      'pincode': [null, Validators.compose([Validators.required])]
    });

    this.pwdForm = fb.group({
      'newPassword':[null, Validators.required],
      'confirmPassword':[null, Validators.required]
    },{validator: this.matchingPasswords('newPassword', 'confirmPassword')});

    //this.checkLoggedIn();
    this.setProfileInfo();
  }

  matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
		return (group: FormGroup) => {
			let password= group.controls[passwordKey];
			let passwordConfirmation= group.controls[passwordConfirmationKey];
			if (password.value !== passwordConfirmation.value) {
				return passwordConfirmation.setErrors({mismatchedPasswords: true})
			}
		}
	}

  ngOnInit(): void {

    if (this.props.template === undefined){
      this.props["template"]="1";
    }

    if (this.props.header_styles === undefined){
      this.props['header_styles'] = {
        "text-align": "center",
        "background-color": "transparent",
        "color": "#000",
        "padding-right": 0,
        "padding-left": 0,
        "padding-top": 0,
        "padding-bottom": 0,
        "border-style": "solid",
        "border-width": 0,
        "border-color": "transparent",
        "border-radius": 0,
        'font-family': 'Roboto',
        "font-size": "24px",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": ""
      };
    }

    if (this.props['input_styles'] === undefined) {
      this.props['input_styles'] = {
        'border-top':0,
        'border-right':0,
        'border-bottom':0,
        'border-left':0,
        'border-style':'',
        'position':'',
        'border-color':'',
        'border-top-left-radius':0,
        'border-top-right-radius':0,
        'border-bottom-left-radius':0,
        'border-bottom-right-radius':0
      };
    }

    if (this.props['btn_styles'] === undefined) {
      this.props['btn_styles'] = {
        'color':'#fff',
        'background-color':'#5d9cec',
        'font-size':'16px',
        'font-weight':'normal',
        'font-style':'normal',
        'text-decoration':'normal',
        'text-align':'center',
        'padding-top':10,
        'padding-right':10,
        'padding-bottom':10,
        'padding-left':10,
        'border-width':0,
        'border-style':'',
        'border-color':'',
        'border-radius':0          
      };
    }

    if (this.props['btn_styles']['text-align'] === undefined) {
			this.props['btn_styles']['text-align'] = "center";
		} 

    this.row.styles.height="100%";
  }

  getInputStyle(content, obj){
		var style: any = {};

    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}

    if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
		
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}

    if(obj['border-color'] !== 'transparent' && obj['border-color'] !== 'rgba(255,255,255,0)' && obj['border-color'] !== 'rgba(255, 255, 255, 0)' ){
      this.pageFunctions.setBorder(style,obj,true);
    }


    this.pageFunctions.serBorderRadius(style, obj);

		return style;
	}

  getFormButtonStyle(obj){
		var style: any = {};

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}

		this.pageFunctions.setPadding(style,obj);

		if (obj['border-width'] !== null && obj['border-width'] !== undefined) {
			if (obj['border-style'] !== null && obj['border-style'] !== undefined && obj['border-style'] !== "") {
				if (obj['border-color'] !== null && obj['border-color'] !== undefined && obj['border-color'] !== "")
					style["border"] = obj['border-width'] + "px " + obj['border-style'] + " " + obj['border-color'];
			}
		}

		if (obj['border-radius'] !== null && obj['border-radius'] !== undefined) {
			style["border-radius"] = obj['border-radius'] + "px";
		}

		return style;
	}

  gotoLogin(){
    var loginUrl = this.dataService.AppData.LoginPage;
    this.router.navigate([loginUrl]);
  }

  getStyles(obj) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
		if (obj['text-align'] !== null && obj['text-align'] !== undefined) {
			style["text-align"] = obj['text-align'];
		}
		if (obj['justify-content'] !== null && obj['justify-content'] !== undefined) {
			style["justify-content"] = obj['justify-content'];
		}
 
		if (obj['background-color'] !== null && obj['background-color'] !== undefined) {
			style["background-color"] = obj['background-color'];
		}
		
		if (obj['color'] !== null && obj['color'] !== undefined) {
			style["color"] = obj['color'];
		}
 
		if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    else{
      style["text-decoration"] = "none";
    }

		if (obj['font-size'] !== null && obj['font-size'] !== undefined) {
			style["font-size"] = obj['font-size'];
		}
    
    return style;
  }

  checkLoggedIn(){
    const customer = this.authService.currentCustomerValue;
    if (customer && customer.ID !== null && customer.ID !== ""){
      this.setProfileInfo();
    }
    else{
      this.gotoLogin();
    }
  }

  setProfileInfo(){
    this.dataService.getCustomerProfile().subscribe(res=>{
      if (res.RESULT_CODE===1){
        delete res.RESULT_CODE;
        this.userData = res;
        this.valForm.controls['firstName'].setValue(this.userData.FIRST_NAME);
        this.valForm.controls['lastName'].setValue(this.userData.LAST_NAME);
        this.valForm.controls['mobile'].setValue(this.userData.MOBILE);
        this.valForm.controls['email'].setValue(this.userData.EMAIL);
        this.valForm.controls['address1'].setValue(this.userData.ADDRESS1);
        this.valForm.controls['address2'].setValue(this.userData.ADDRESS2);
        this.valForm.controls['city'].setValue(this.userData.CITY);
        this.valForm.controls['state'].setValue(this.userData.STATE);
        if (this.userData.COUNTRY !== null)
          this.valForm.controls['country'].setValue(this.userData.COUNTRY);
        this.valForm.controls['pincode'].setValue(this.userData.PINCODE);
      }
      else{
        this.authService.customerlogout();
        this.authService.clearCustomerSession();
        this.gotoLogin();
      }
    });
    // if (localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== ""){
    //   this.userData = JSON.parse(localStorage.getItem('userData'))
    //   this.valForm.controls['firstName'].setValue(this.userData.FIRST_NAME);
    //   this.valForm.controls['lastName'].setValue(this.userData.LAST_NAME);
    //   this.valForm.controls['mobile'].setValue(this.userData.MOBILE);
    //   this.valForm.controls['email'].setValue(this.userData.EMAIL);
    //   this.valForm.controls['address1'].setValue(this.userData.ADDRESS1);
    //   this.valForm.controls['address2'].setValue(this.userData.ADDRESS2);
    //   this.valForm.controls['city'].setValue(this.userData.CITY);
    //   this.valForm.controls['state'].setValue(this.userData.STATE);
    //   if (this.userData.COUNTRY !== null)
    //     this.valForm.controls['country'].setValue(this.userData.COUNTRY);
    //   this.valForm.controls['pincode'].setValue(this.userData.PINCODE);
    // }
  }

  submitForm($event){
    $event.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      let value = this.valForm.value;

      this.authService.updateProfile(value.firstName, value.lastName, value.mobile, value.email, value.address1,
        value.address2, value.city, value.state, value.country, value.pincode)
      .subscribe(data => {
        if(data['RESULT_CODE'] === 1){
          let status = 'success';
          this.snackBar.open("Profile updated Successfully.", '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
          //localStorage.setItem('userData',JSON.stringify(data));
        }
        else
        {
          this.toastr.error('Something went wrong.. try after sometime !!','Error');
        }
      },
      error => {
        this.toastr.error('Something went wrong.. try after sometime !!','Error');
      });
    }
    else {
      this.toastr.info('Mandatory Fields not entered','Information');
    }
  }

  submitPwdForm($event){
    $event.preventDefault();
    for (let c in this.pwdForm.controls) {
      this.pwdForm.controls[c].markAsTouched();
    }
    if (this.pwdForm.valid) {
      let value = this.pwdForm.value;

      this.authService.updatePasswordCustomer(value.newPassword)
      .subscribe(data => {
        if(data['RESULT_CODE'] === 1){
          let status = 'success';
          this.snackBar.open("Password changed Successfully.", '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
          this.pwdForm.reset();
        }
        else
        {
          this.toastr.error('Something went wrong.. try after sometime !!','Error');
        }
      },
      error => {
        this.toastr.error('Something went wrong.. try after sometime !!','Error');
      });
    }
    else {
      this.toastr.info('Passwords not entered or does not match..','Information');
    }
  }
}
