<div class="card card-default mb-0">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-6">
                <h4>{{Lookup.Lookup_Name}}</h4>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button id="btnclose" type="button" class="btn btn-warning float-right mr-2" (click)="close()" aria-label="Close">
                        Close</button>
                    <button id="btnadd" type="button" class="btn btn-info float-right mr-2" *ngIf="Lookup.Lookup_Type === 'Table'"
                        (click)="addMVData()" aria-label="Add">Add</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body bg-white">
        <div class="row" *ngIf="Lookup.Lookup_Type === 'Table'">
            <div class="col-lg-12">
                <input type="checkbox" class="ml-3" [(ngModel)]="selectAll" (change)="selectAllRows()"/>
                <label class="pl-2">Select All</label>
            </div>
        </div>
        <jqxGrid #lkupGrid [width]="'100%'" [height]="'99%'" [columnsresize]="true" [filterable]="true"
            [pageable]="true" [sortable]="true" [editable]="true" [source]="gridAdapter" [columns]="Columns"
            [pagesizeoptions]="['10', '25', '50']" (onCellclick)="onLookupCellclick($event)" (onFilter)="Filter($event)"
            (onPagechanged)="onPageChanged($event)" (onPagesizechanged)="onPageSizeChanged($event)">
        </jqxGrid>
    </div>
</div>