<div [class]="'carousel-hc-button-next ' + contentStyles['classname']"
    [style.marginLeft.px]="contentStyles['margin-left']" [style.marginRight.px]="contentStyles['margin-right']"
    [style.marginTop.px]="contentStyles['margin-top']" [style.marginBottom.px]="contentStyles['margin-bottom']"
    *ngIf="contentStyles">
    <button (click)="videoPlayClick(content)" class="btn btn-info p-0"
        [style.borderTopLeftRadius.px]="contentStyles['border-top-left-radius'] === undefined ? '0' : contentStyles['border-top-left-radius']"
        [style.borderTopRightRadius.px]="contentStyles['border-top-right-radius'] === undefined ? '0' : contentStyles['border-top-right-radius']"
        [style.borderBottomLeftRadius.px]="contentStyles['border-bottom-left-radius'] === undefined ? '0' : contentStyles['border-bottom-left-radius']"
        [style.borderBottomRightRadius.px]="contentStyles['border-bottom-right-radius'] === undefined ? '0' : contentStyles['border-bottom-right-radius']"
        [style.borderWidth.px]="(contentStyles['border-width']===null||contentStyles['border-width']==='')?0:contentStyles['border-width']"
        [style.borderStyle]="contentStyles['border-style']" [style.borderColor]="contentStyles['border-color']"
        [style.Color]="contentStyles['button-color']" [style.backgroundColor]="contentStyles['button-background-color']"
        style="letter-spacing:inherit;text-align:inherit;font-weight:inherit;font-style:inherit;text-decoration:inherit;">
        <em [class]="contentStyles['icon_name_pause']" *ngIf="contentStyles['pause']"
            [style.Color]="contentStyles['icon-color']" [style.paddingTop.px]="contentStyles['icon-padding-top']"
            [style.paddingBottom.px]="contentStyles['icon-padding-bottom']"
            [style.paddingLeft.px]="contentStyles['icon-padding-left']"
            [style.paddingRight.px]="contentStyles['icon-padding-right']" style="vertical-align:middle;"
            [style.fontSize.px]="contentStyles['icon-size']"></em>
        <em [class]="contentStyles['icon_name']" *ngIf="!contentStyles['pause']"
            [style.Color]="contentStyles['icon-color']" [style.paddingTop.px]="contentStyles['icon-padding-top']"
            [style.paddingBottom.px]="contentStyles['icon-padding-bottom']"
            [style.paddingLeft.px]="contentStyles['icon-padding-left']"
            [style.paddingRight.px]="contentStyles['icon-padding-right']" style="vertical-align:middle;"
            [style.fontSize.px]="contentStyles['icon-size']"></em>
    </button>
</div>