<div class="card card-default mb-0">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-6">
                <h4>{{AttachmentInfo.HeaderName}}</h4>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button type="button" id="btnClose" class="btn btn-warning float-right mr-1" (click)="close()"
                        aria-label="Close">
                        Close</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="card-body">
        <label>File
            <input #file type="file" *ngIf="uploadReady && AttachmentInfo.DataType!=='ATTACHMENT'" id="file" (change)="handleFileUpload()"
            accept="image/*"/>
            <input #file type="file" *ngIf="uploadReady && AttachmentInfo.DataType==='ATTACHMENT'" id="file" (change)="handleFileUpload()"/>
        </label>
        <button type="button" id="btnUpload" class="btn btn-primary" (click)="uploadFile($event)"
        *ngIf="allowUpload">Upload</button>
    </div>

    <div class="card-body" *ngIf="AttachmentInfo.DataType==='MARKING'">
        <div *ngIf="markingUpload" [ngStyle]="{'margin-top':'auto','margin-bottom':'auto','text-align':'center'}">
            <img src="/assets/images/loading.gif">
        </div>
    </div>

    <div class="card-body" *ngIf="AttachmentInfo.DataType==='MARKING' && selectedFileName!==''">
        <div style="margin-top: 40px; margin-bottom:30px; width:100%;" id="markerdiv">
            <img src="" width="100%" crossorigin="anonymous" id="markerimage" #markerimage/>
        </div>
    </div>

    <div class="card-body" *ngIf="AttachmentInfo.DataType!=='UPLOAD'">
        <div *ngIf="loading" [ngStyle]="{'margin-top':'auto','margin-bottom':'auto','text-align':'center'}">
            <img src="/assets/images/loading.gif">
        </div>
        <jqxGrid #attachmentGrid [width]="'98%'" [height]="'99%'" [columnsresize]="false" [editable]="false"
            [source]="gridAdapter" [columns]="Columns" (onCellclick)="onCellclick($event)">
        </jqxGrid>
    </div>
</div>
<img #markedimage id="markedimage" src="{{imageurl}}" *ngIf="imageurl !== ''">