<div class="container-fluid" *ngIf="getConfigComplete">
    <form (keydown.enter)="$event.preventDefault()">
        <div class="card card-default">
            <div class="card-header">
                <div class="card-title text-left">
                    <button class="mr-1 btn btn-pill-left btn-warning" appNoDblClick type="button" (click)="gotoList()">Back</button>
                    Role Menu Rights
                    <button class="btn btn-sm btn-success float-right" appNoDblClick type="button" (click)="saveRoleMenus()">Save</button>
                </div>
            </div>
        
            <div class="card-body">
                <div class="form-row">
                    <div id="DIV_NAME" style="padding-bottom:10px;" class="col-md-3">
                        <label style="margin-bottom:0px;width:100%;" for="txtName">Role Name</label>

                        <input class="form-control" id="txtName" [value]="roleName" 
                        (input)="roleName=$event.target.value" readonly="true"/>
                    </div>                        
                </div>
            </div>
        </div>
               
        <div class="card card-default">
            <div class="card-body">
                <div class="card-hdr">
                    <jqxTreeGrid #hdrGrid [width]="'100%'" [height]="'500px'" [columns]="hdrColumns" 
                    [altRows]="true" [source]="hdrAdapter"(onRowSelect)="onRowSelect()"></jqxTreeGrid >
                </div>
                <div class="card-menu">
                    <div [width]="'100%'" [height]="'35px'" 
                    [style]="'border:1px solid #c7c7c7;overflow: hidden; position: relative; margin: 0px;'">
                        <div class="float-right chkbox">
                            <input type="checkbox" [style]="'margin-top:2px;margin-left:15px;'" 
                            id="cbDelete" [value]="deleteAll" (change)="setDeleteAll()"
                            (input)="deleteAll=$event.target.checked"/>
                            <label for="cbDelete" [style]="'margin:2px;'">Can Delete</label> 
                        </div>
                        <div class="float-right chkbox">
                            <input type="checkbox" [style]="'margin-top:2px;margin-left:15px;'" 
                            id="cbEdit" [value]="editAll" (change)="setEditAll()"
                            (input)="editAll=$event.target.checked"/>
                            <label for="cbEdit" [style]="'margin:2px;'">Can Edit</label>
                        </div>
                        <div class="float-right chkbox">
                            <input type="checkbox" [style]="'margin-top:2px;margin-left:15px;'" 
                            id="cbAdd" [value]="addAll" (change)="setAddAll()"
                            (input)="addAll=$event.target.checked"/>
                            <label for="cbAdd" [style]="'margin:2px;'">Can Add</label>
                        </div>
                        <div class="float-right chkbox">
                            <input type="checkbox" [style]="'margin-top:2px;margin-left:15px;'" 
                            id="cbView" [value]="viewAll" (change)="setViewAll()"
                            (input)="viewAll=$event.target.checked"/>
                            <label for="cbView" [style]="'margin:2px;'">Can View</label>
                        </div>
                    </div>                        
                    <jqxGrid #menuGrid [width]="'100%'" [height]="'500px'" [altRows]="true"
                    [source]="menuAdapter" [columns]="menuColumns" [editable]="true"></jqxGrid>
                </div>
            </div>                                
        </div>
        
        <!-- <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>              -->
    </form>
</div>