import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnvService } from '../../environments/env.service';

export class AppData {
    constructor(
        public CART_LIST: any[],
        public WISHLIST: any[],
        public TOTAL_PRICE: number,
        public TOTAL_CART_COUNT: number,
        public HomePage: string,
        public LoginPage: string,
        public RegistrationPage: string,
        public ProfilePage: string,
        public ProductsPage: string,
        public BrandProductsPage: string,
        public SearchProductsPage: string,
        public ProductPage: string,
        public OrdersPage: string,
        public WishlistPage: string,
        public CheckoutPage: string,
        public SuccessPage: string,
        public FailurePage: string,
        public CurrencyCode: string,
        public CurrencySymbol: string,
        public isLoggedin:boolean,
        public userData: any,
        public searchText: string,
        public loginCheckDone: boolean
    ) { }
}

@Injectable({ providedIn: 'root' })
export class EcommerceService {
    httpOptions : any;
    apiUrl : string;
    data: any = {};
    clientKey: string;

    public AppData = new AppData([],[],0,0,"","","","","","","","","","","","","","","",false,{},"",false);

    constructor(public environment:EnvService, private http: HttpClient, public snackBar: MatSnackBar) {
        
        this.httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json'}),withCredentials: true};
        this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        this.httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.httpOptions.headers.append('Access-Control-Allow-Headers', 'Origin, X-Auth-Token, X-Requested-With, Content-Type, Accept');
        this.httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

        this.apiUrl = environment.apiUrl;
        this.clientKey = environment.clientKey;

        if (this.isStorageExists() && localStorage.getItem('userData') !== null && localStorage.getItem('userData') !== ""){
            //this.AppData.isLogggedin = true;
            this.AppData.userData = JSON.parse(localStorage.getItem('userData'));
            //this.setDataFromStorage();
        }
        else{
            //this.AppData.isLogggedin = false;
            this.AppData.userData = [];
        }
    }

    isStorageExists(){
		try{
			if (window.localStorage !== undefined && window.localStorage !== null)
				return true;
		}
		catch(e){
			return false;
		}
	}

    getCustomerProfile():Observable<any> {
        return this.http.get(this.apiUrl + 'customerprofile',this.httpOptions).pipe(map((response: any) => response));
    }

    getVariants(Cond, From, To, OrderBy):Observable<any> {
        return this.http.post(`${this.apiUrl}variants`, 
        { Cond, From, To, OrderBy }, this.httpOptions).pipe(map((response: any) => response));
    }

    getVariant(id):Observable<any> {
        var params = "?ID=" + id;
        return this.http.get(this.apiUrl + 'variant' + params,this.httpOptions)
    }

    manageVariant(OPER, ID, WEBSITE_ID, VARIANT_TYPE, VARIANT_NAME):Observable<any> {
        return this.http.post(`${this.apiUrl}managevariant`, 
        { OPER, ID, WEBSITE_ID, VARIANT_TYPE, VARIANT_NAME }, this.httpOptions).pipe(map((response: any) => response));
    }

    getBrands(Cond, From, To, OrderBy):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}brands`, 
        { CK, Cond, From, To, OrderBy }, this.httpOptions).pipe(map((response: any) => response));
    }

    getBrand(id):Observable<any> {
        var params = "?ID=" + id;
        return this.http.get(this.apiUrl + 'brand' + params,this.httpOptions)
    }

    manageBrand(OPER, ID, WEBSITE_ID, BRAND_NAME):Observable<any> {
        return this.http.post(`${this.apiUrl}managebrand`, 
        { OPER, ID, WEBSITE_ID, BRAND_NAME }, this.httpOptions).pipe(map((response: any) => response));
    }

    getCategories(Cond, From, To, OrderBy):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}categories`, 
        { CK, Cond, From, To, OrderBy }, this.httpOptions).pipe(map((response: any) => response));
    }

    getCategory(id):Observable<any> {
        var params = "?ID=" + id;
        return this.http.get(this.apiUrl + 'category' + params,this.httpOptions)
    }

    manageCategory(OPER, ID, WEBSITE_ID, CATEGORY_NAME):Observable<any> {
        return this.http.post(`${this.apiUrl}managecategory`, 
        { OPER, ID, WEBSITE_ID, CATEGORY_NAME }, this.httpOptions).pipe(map((response: any) => response));
    }

    getProducts(Cond, From, To, OrderBy):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}products`, 
        { CK, Cond, From, To, OrderBy }, this.httpOptions).pipe(map((response: any) => response));
    }

    getProduct(id):Observable<any> {
        var params = "?CK=" + this.clientKey + "&ID=" + id;
        return this.http.get(this.apiUrl + 'product' + params,this.httpOptions)
    }

    manageProduct(OPER, ID, WEBSITE_ID, INFO, VARIANTS, INFOS):Observable<any> {
        return this.http.post(`${this.apiUrl}manageproduct`, 
        { OPER, ID, WEBSITE_ID, INFO, VARIANTS, INFOS}, this.httpOptions).pipe(map((response: any) => response));
    }

    manageProductVariantImage(OPER, ID, VARIANT_ID):Observable<any> {
         return this.http.post(`${this.apiUrl}managevariantimage`, 
         { OPER, ID, VARIANT_ID}, this.httpOptions).pipe(map((response: any) => response));
    }

    getProductReviews(PRODUCT_ID, From, To):Observable<any> {
        var CK = this.clientKey;
        return this.http.post(`${this.apiUrl}productreviews`, 
        { PRODUCT_ID, From, To }, this.httpOptions).pipe(map((response: any) => response));
    }
    

    getProductImages(id):Observable<any> {
        var params = "?CK=" + this.clientKey + "&ID=" + id;
        return this.http.get(this.apiUrl + 'variantimages' + params,this.httpOptions)
    }

    getProductVideos(id):Observable<any> {
        var params = "?CK=" + this.clientKey + "&ID=" + id;
        return this.http.get(this.apiUrl + 'variantvideos' + params,this.httpOptions)
    }

    getSearchProducts(WEBSITE_ID, SEARCH_TEXT, CATEGORIES, BRANDS):Observable<any> {
        let CK = this.clientKey;
        return this.http.post(`${this.apiUrl}searchproducts`, { CK, WEBSITE_ID, SEARCH_TEXT, CATEGORIES, BRANDS }, 
            this.httpOptions).pipe(map((response: any) => response));
    }

    getCategoryProducts(categoryId):Observable<any> {
        var params = "?CK=" + this.clientKey + "&CATEGORY_ID=" + categoryId;
        return this.http.get(this.apiUrl + 'categoryproducts' + params,this.httpOptions)
    }

    getCategoryProductsAll(categoryId):Observable<any> {
        var params = "?CK=" + this.clientKey + "&CATEGORY_ID=" + categoryId;
        return this.http.get(this.apiUrl + 'categoryproductsall' + params,this.httpOptions)
    }

    getBrandProductsAll(brandId):Observable<any> {
        var params = "?CK=" + this.clientKey + "&BRAND_ID=" + brandId;
        return this.http.get(this.apiUrl + 'brandproductsall' + params,this.httpOptions)
    }

    setLoginPage(loginPage){
        this.AppData.LoginPage = loginPage;
    }

    setRegistrationPage(registerPage){
        this.AppData.RegistrationPage = registerPage;
    }

    public setDataFromStorage(){
        if (this.AppData.isLoggedin === true){
            this.getCartlist().subscribe(res=>{
                if (res.RESULT_CODE === 1){
                    this.AppData.TOTAL_PRICE = 0;
                    this.AppData.TOTAL_CART_COUNT = 0;
                    this.AppData.CART_LIST = res.DATA;
                    this.AppData.CART_LIST.forEach(product=>{
                        this.AppData.TOTAL_PRICE = this.AppData.TOTAL_PRICE + (product.CART_COUNT * product.PRICE);
                        this.AppData.TOTAL_CART_COUNT = this.AppData.TOTAL_CART_COUNT + product.CART_COUNT;
                    });
                }
            });
            this.getWishlist().subscribe(res=>{
                if (res.RESULT_CODE === 1){
                    this.AppData.WISHLIST = res.DATA;
                }
            });
        }
        else if (localStorage.getItem('LOCAL_DATA')){
            let localData = JSON.parse(localStorage.getItem('LOCAL_DATA'));
            if (localData['CART_LIST'] && localData['CART_LIST'].length > 0 && this.AppData.CART_LIST.length === 0){
                this.AppData.CART_LIST = localData['CART_LIST'];
                this.AppData.TOTAL_PRICE = localData['TOTAL_PRICE'];
                this.AppData.TOTAL_CART_COUNT = localData['TOTAL_CART_COUNT'];
            }
            if (localData['WISHLIST'] && localData['WISHLIST'].length > 0 && this.AppData.WISHLIST.length === 0){
                this.AppData.WISHLIST = localData['WISHLIST'];
            }
        }
    }

    public updateToStorage(){
        var localData = {
            "CART_LIST": this.AppData.CART_LIST,
            "TOTAL_PRICE": this.AppData.TOTAL_PRICE,
            "TOTAL_CART_COUNT": this.AppData.TOTAL_CART_COUNT
        };

        localStorage.setItem('LOCAL_DATA',JSON.stringify(localData));
    }

    public addToWishlist(product:any){
        if(this.AppData.WISHLIST.filter(item=>item.VARIANT_ID === product.VARIANT_ID).length === 0){ 
            this.AppData.WISHLIST.push(product);
            this.updateCustomerWishlist().subscribe(res=>{});
        }
    }

    public removeFromWishlist(product:any){
        let items = this.AppData.WISHLIST.filter(item=>item.VARIANT_ID !== product.VARIANT_ID);
        this.AppData.WISHLIST = items;
        this.deleteWishlist(product.PRODUCT_ID,product.VARIANT_ID).subscribe(res=>{});
        //this.updateToStorage();
    }

    public addToCart(product:any){
        let message, status;        
       
        this.AppData.TOTAL_PRICE = 0;
        this.AppData.TOTAL_CART_COUNT = 0;

        if(this.AppData.CART_LIST.filter(item=>item.ID === product.ID && item.VARIANT_ID === product.VARIANT_ID)[0]){ 
            let item = this.AppData.CART_LIST.filter(item=>item.ID == product.ID)[0];
            item.CART_COUNT = product.CART_COUNT;  
        }
        else{           
            this.AppData.CART_LIST.push(product);
        }        
        this.AppData.CART_LIST.forEach(product=>{
            this.AppData.TOTAL_PRICE = this.AppData.TOTAL_PRICE + (product.CART_COUNT * product.PRICE);
            this.AppData.TOTAL_CART_COUNT = this.AppData.TOTAL_CART_COUNT + product.CART_COUNT;
        });

        this.updateToStorage();

        if (this.AppData.isLoggedin === true){
            this.updateCustomerCartlist().subscribe(res=>{});
        }

        message = 'The product ' + product["PRODUCT_NAME"] + ' has been added to cart.'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
    }

    public deleteFromCart(product:any){
        let message, status;        
       
        this.AppData.TOTAL_PRICE = 0;
        this.AppData.TOTAL_CART_COUNT = 0;

        let cartList = this.AppData.CART_LIST.filter(item=>item.VARIANT_ID !== product.VARIANT_ID);
          
        this.AppData.CART_LIST = cartList;
     
        this.AppData.CART_LIST.forEach(product=>{
            this.AppData.TOTAL_PRICE = this.AppData.TOTAL_PRICE + (product.CART_COUNT * product.PRICE);
            this.AppData.TOTAL_CART_COUNT = this.AppData.TOTAL_CART_COUNT + product.CART_COUNT;
        });

        this.updateToStorage();

        if (this.AppData.isLoggedin === true){
            this.deleteCartlist(product.ID, product.VARIANT_ID).subscribe(res=> {});
        }
        
        message = 'The product ' + product["PRODUCT_NAME"] + ' has been removed from cart.'; 
        status = 'danger';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
    }

    public clearCart(){
        this.AppData.TOTAL_PRICE = 0;
        this.AppData.TOTAL_CART_COUNT = 0;
        this.AppData.CART_LIST = [];
        this.updateToStorage();
    }

    getCoupon(id):Observable<any> {
        var params = "?CK=" + this.clientKey + "&ID=" + id;
        return this.http.get(this.apiUrl + 'coupon' + params,this.httpOptions)
    }

    checkoutItems(VARIANTS) {
        return this.http.post(`${this.apiUrl}checkoutproducts`, { VARIANTS }, this.httpOptions).pipe(map((response: any) => response));
    }

    createOrder(firstName:string, lastName: string, mobile: string, email: string, address1: string, 
        address2:string, city: string, state: string, country: string, pincode: string, currency: string,
        debit_number:string, coupon_id:string, item_total:number, coupon_discount:number, order_amount: number,  
        ref_number:string, items: any, payment_method: string, success_url: string, failure_url: string) {
        return this.http.post(`${this.apiUrl}createorder`, 
            { firstName, lastName, mobile, email, address1, address2, city, state, country, pincode, currency, 
            debit_number, coupon_id, item_total, coupon_discount, order_amount, ref_number, items, payment_method, success_url, failure_url}, 
            this.httpOptions).pipe(map((response: any) => response));
    }

    verifyPayment(pg: string,order_id: string,order_no:string,order_value:number,payment_id:string,signature:string,mobile_no:string,email:string,payResponse:string){
        return this.http.post(`${this.apiUrl}verify_payment`, 
        {pg,order_id,order_no,order_value,payment_id,signature,mobile_no,email,payResponse},
        this.httpOptions).pipe(map((response: any) => response));
    }

    cancelOrder(ORDER_ID){
        return this.http.post(`${this.apiUrl}cancelorder`, { ORDER_ID }, this.httpOptions).pipe(map((response: any) => response));
    }

    insertReview(ORDER_ID,PRODUCT_ID,RATING,HEADLINE,REVIEW):Observable<any> {
        return this.http.post(`${this.apiUrl}insertreview`, 
        { ORDER_ID,PRODUCT_ID,RATING,HEADLINE,REVIEW }, this.httpOptions).pipe(map((response: any) => response));
    }

    getCustomerOrders(ORDER_TYPE):Observable<any> {
        return this.http.get(this.apiUrl + 'customerorders?TYPE='+ORDER_TYPE,this.httpOptions)
    }

    getOrderDetails(orderid):Observable<any> {
        return this.http.get(this.apiUrl + 'orderdetails?ID='+orderid,this.httpOptions)
    }

    getInvoicePDF(orderid):Observable<any> {
        return this.http.get(this.apiUrl + 'invoice?ID='+orderid,this.httpOptions)
    }

    getCartlist():Observable<any> {
        return this.http.get(this.apiUrl + 'cartlist', this.httpOptions)
    }

    updateCustomerCartlist():Observable<any> {
        let localData = JSON.parse(localStorage.getItem('LOCAL_DATA'));

        if (localData && localData['CART_LIST'] !== undefined && localData['CART_LIST'] !== null && localData['CART_LIST'].length > 0){ 
            if (this.AppData.CART_LIST.length === 0){
                this.AppData.CART_LIST = localData['CART_LIST'];
                this.AppData.TOTAL_PRICE = localData['TOTAL_PRICE'];
                this.AppData.TOTAL_CART_COUNT = localData['TOTAL_CART_COUNT'];
            }
        }
        return this.http.post(`${this.apiUrl}updatecart`, {"items":this.AppData.CART_LIST}, this.httpOptions).pipe(map((response: any) => response));
    }

    deleteCartlist(PRODUCT_ID, VARIANT_ID):Observable<any> {
        return this.http.post(`${this.apiUrl}deletecart`, {"PRODUCT_ID":PRODUCT_ID, "VARIANT_ID":VARIANT_ID}, this.httpOptions).pipe(map((response: any) => response));
    }

    getWishlist():Observable<any> {
        return this.http.get(this.apiUrl + 'wishlist',this.httpOptions)
    }

    updateCustomerWishlist():Observable<any> {
        return this.http.post(`${this.apiUrl}updatewish`, {"items":this.AppData.WISHLIST}, this.httpOptions).pipe(map((response: any) => response));
    }

    deleteWishlist(PRODUCT_ID, VARIANT_ID):Observable<any> {
        return this.http.post(`${this.apiUrl}deletewish`, {"PRODUCT_ID":PRODUCT_ID, "VARIANT_ID":VARIANT_ID}, this.httpOptions).pipe(map((response: any) => response));
    }

    getPaymentOptions():Observable<any> {
        return this.http.get(`${this.apiUrl}getpgoptions`, this.httpOptions)
    }

    getGateways():Observable<any> {
        return this.http.get(`${this.apiUrl}gateways`, this.httpOptions)
    }
}