<div [style.backgroundColor]="content.styles['background-color']"
    [style.marginLeft.px]="content.styles['margin-left']"
    [style.marginRight.px]="content.styles['margin-right']"
    [style.marginTop.px]="content.styles['margin-top']"
    [style.marginBottom.px]="content.styles['margin-bottom']"
    [style.paddingLeft.px]="content.styles['padding-left']"
    [style.paddingRight.px]="content.styles['padding-right']"
    [style.paddingTop.px]="content.styles['padding-top']"
    [style.paddingBottom.px]="content.styles['padding-bottom']">
    <div class="row">
        <div class="col-12">
            <div [ngStyle]="getCategoryStyles()">
                <button style="margin-top:5px;margin-right:5px;margin-bottom:5px;" 
                    [style.paddingLeft.px]="content.category_styles['padding-left']"
                    [style.paddingRight.px]="content.category_styles['padding-right']"
                    [style.paddingTop.px]="content.category_styles['padding-top']"
                    [style.paddingBottom.px]="content.category_styles['padding-bottom']"
                    [style.backgroundColor]="content.navig_styles['background-color']"
                    [style.borderColor]="content.navig_styles['border-color']"
                    [style.color]="content.navig_styles['color']"
                    (click)="changeCategory('')">
                    All
                </button>
                <button style="margin-top:5px;margin-right:5px;margin-bottom:5px;" 
                    (click)="changeCategory(category.ID)"
                    [style.paddingLeft.px]="content.category_styles['padding-left']"
                    [style.paddingRight.px]="content.category_styles['padding-right']"
                    [style.paddingTop.px]="content.category_styles['padding-top']"
                    [style.paddingBottom.px]="content.category_styles['padding-bottom']"
                    [style.backgroundColor]="category.BG_COLOR" 
                    [style.borderColor]="category.BG_COLOR" 
                    [style.color]="content.category_styles['color']" 
                    *ngFor="let category of categories">
                    {{category.CATEGORY_NAME}}
                </button>
            </div>
        </div>
        <div class="col-12">
            <div class="fc-toolbar fc-header-toolbar">
                <div class="fc-left" [ngStyle]="getMonthStyles()">
                    {{selectedperiod}}
                </div>
                <div class="fc-center"></div>
                <div class="fc-right">
                    <div class="fc-button-group">
                        <button type="button" class="fc-prev-button fc-button fc-button-primary mr-1" aria-label="prev"
                            [style.backgroundColor]="content.navig_styles['background-color']"
                            [style.borderColor]="content.navig_styles['border-color']"
                            (click)="getPrevMonth()">
                            <span class="fc-icon fc-icon-chevron-left"
                            [style.color]="content.navig_styles['color']"></span>
                        </button>
                        <button type="button" class="fc-next-button fc-button fc-button-primary" aria-label="next"
                            [style.backgroundColor]="content.navig_styles['background-color']"
                            [style.borderColor]="content.navig_styles['border-color']"
                            (click)="getNextMonth()">
                            <span class="fc-icon fc-icon-chevron-right"
                            [style.color]="content.navig_styles['color']"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div [ngStyle]="getCalenderStyles()"
                [style.--header-border-color]="content.header_styles['border-color']"
                [style.--header-bg-color]="content.header_styles['background-color']"
                [style.--header-color]="content.header_styles['color']"
                [style.--header-font-family]="content.header_styles['font-family']"
                [style.--header-font-weight]="content.header_styles['font-weight']"
                [style.--header-font-size]="content.header_styles['font-size']"
                [style.--header-font-style]="content.header_styles['font-style']"
                [style.--body-border-color]="content.body_styles['border-color']"
                [style.--body-bg-color]="content.body_styles['background-color']"
                [style.--body-color]="content.body_styles['color']"
                [style.--body-font-family]="content.body_styles['font-family']"
                [style.--body-font-weight]="content.body_styles['font-weight']"
                [style.--body-font-size]="content.body_styles['font-size']"
                [style.--body-font-style]="content.body_styles['font-style']"
                [style.--event-font-family]="content.event_styles['font-family']"
                [style.--event-font-weight]="content.event_styles['font-weight']"
                [style.--event-font-size]="content.event_styles['font-size']"
                [style.--event-font-style]="content.event_styles['font-style']"            
                [style.--category-color]="content.category_styles['color']"
            >
                <full-calendar #fullcalendar
                    defaultView="dayGridMonth"
                    [header]="headerOption"
                    [plugins]="calendarPlugins"
                    [events]="content.items"
                    [editable]=[false]
                    [droppable]=[false]
                    deepChangeDetection="false"
                    (eventClick)="eventClick($event)"
                ></full-calendar>
            </div>
        </div>
    </div>
</div>