export class EnvService {

    // The values that are defined here are the default values that can
    // be overridden by env.js
  
    // API url
    public appUrl = '';
    public apiUrl = '';
    public s3Url = '';
    public clientKey = '';
    public websiteId = '';
    public desktopSite = false;
    public websiteTitle = '';
    public homePage = '';
    public blogPage = '';
    public googleKey = '';
    public socketUrl = '';
    public production = true;
  
    // Whether or not to enable debug mode
    public enableDebug = false;
  
    constructor() {
    }
  
  }