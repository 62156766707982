import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { EcommerceService } from '../../../services';
import { PageFunctions } from '../../directives/PageFunctions';

@Component({
  selector: 'app-searchbutton',
  templateUrl: './searchbutton.component.html',
  styleUrls: ['./searchbutton.component.scss']
})
export class SearchbuttonComponent implements OnInit {
  @Input() content: any;
  searchtext: string = '';

  constructor(public pageFunctions: PageFunctions, public router: Router, public dataService: EcommerceService) { 

  }

  ngOnInit(): void {
    this.searchtext = this.dataService.AppData.searchText;

    if (this.content.text_styles === undefined){
			this.content['text_styles'] = {
        "placeholder": "Search Product",
			  "background-color": "#fff",
			  "color": "#000",
			  "padding-right": 0,
			  "padding-left": 0,
			  "padding-top": 0,
			  "padding-bottom": 0,
			  "border-style": "solid",
			  "border-width": 0,
			  "border-color": "transparent",
			  "border-radius": 0,
			  'font-family': 'Roboto',
			  "font-size": "16px",
			  "font-weight": "normal",
			  "font-style": "normal",
			  "text-decoration": ""
			}
		}
    if (this.content.button_styles === undefined){
			this.content['button_styles'] = {
			  "text-align": "center",
			  "background-color": "#fff",
			  "color": "#000",
			  "padding-right": 0,
			  "padding-left": 0,
			  "padding-top": 0,
			  "padding-bottom": 0,
			  "border-style": "solid",
			  "border-width": 0,
			  "border-color": "transparent",
			  "border-radius": 0,
			  'font-family': 'Roboto',
			  "font-size": "16px",
			  "font-weight": "normal",
			  "font-style": "normal",
			  "text-decoration": ""
			}
		}
  }

  keyDown($event){
    if ($event.keyCode === 13){
      if (this.searchtext !== null && this.searchtext !== '') {
        $event.preventDefault();
        this.searchProducts();
      }
      else if (this.dataService.AppData.searchText !== ""){
        $event.preventDefault();
        this.searchProducts();
      }
      else{
        this.dataService.AppData.searchText = "";
      }
    }
  }

  searchProducts(){
    if (this.searchtext !== '' && this.searchtext !== null){
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.dataService.AppData.searchText = this.searchtext;
      this.router.navigate([this.dataService.AppData.SearchProductsPage]);
    }
    else if (this.dataService.AppData.searchText !== ""){
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.dataService.AppData.searchText = "";
      this.router.navigate([this.dataService.AppData.SearchProductsPage]);
    }
    else{
      this.dataService.AppData.searchText = "";
    }
  }

  getStyles(obj) {
    var style = {};
    if (obj['font-family'] !== null && obj['font-family'] !== undefined) {
			style["font-family"] = obj['font-family'];
		}
    if (obj['text-align'] !== null && obj['text-align'] !== undefined){
      style["text-align"] = obj['text-align'];
    }
    if (obj['justify-content'] !== null && obj['justify-content'] !== undefined){
      style["justify-content"] = obj['justify-content'];
    }

    this.pageFunctions.setBorder(style,obj,true);
    this.pageFunctions.setPadding(style,obj);

    if (obj['border-radius'] !== null && obj['border-radius'] !== undefined){
      style["border-radius"] = obj['border-radius'] + "px";
    }

    this.pageFunctions.setBackgroundImage(style,obj);

    if (obj['color'] !== null && obj['color'] !== undefined){
      style["color"] = obj['color'];
    }

    if (obj['full-width'] !== null && obj['full-width'] !==undefined && obj['full-width'] === true){
      style['display'] = "block";
      style['width'] = "100%";
    }

    if (obj['font-weight'] !== null && obj['font-weight'] !== undefined && obj['font-weight'] !== "Normal") {
			style["font-weight"] = obj['font-weight'];
		}

		if (obj['font-style'] !== null && obj['font-style'] !== undefined && obj['font-style'] !== "Normal") {
			style["font-style"] = obj['font-style'];
		}

		if (obj['text-decoration'] !== null && obj['text-decoration'] !== undefined && obj['text-decoration'] !== "") {
			style["text-decoration"] = obj['text-decoration'];
		}
    
    if (obj['font-size'] !== null && obj['font-size'] !== undefined){
      style["font-size"] = obj['font-size'];
    }

    if (obj['line-height'] !== null && obj['line-height'] !== undefined){
      style["line-height"] = obj['line-height'];
    }

    if (obj['height'] !== null && obj['height'] !== undefined && obj['height'] !== "" && obj['height'] !== 0){
      style["height"] = obj['height'] + "px";
    }

    if (obj['width'] !== null && obj['width'] !== undefined && obj['width'] !== "" && obj['width'] !== 0) {
      style["width"] = obj['width'] + "%";
    }

    if (obj['margin-left'] !== null && obj['margin-left'] !== undefined && obj['margin-left'] !== ""){
      style['margin-left'] = (parseInt(obj['margin-left'].replace('px',''))) + "px";
    }
   
    if (obj['margin-top'] !== null && obj['margin-top'] !== undefined && obj['margin-top'] !== ""){
      style['margin-top'] = (parseInt(obj['margin-top'].replace('px',''))) + "px";
    }
    
    return style;
  }
}
